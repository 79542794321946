import styled from 'styled-components';
import Emoji from '../Emoji/DeprecatedEmoji';

export const ButtonWrapper = styled.div<{ withEmoji: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ withEmoji }) => (withEmoji ? 'left' : 'center')};
  width: 100%;
  max-width: 360px;
  min-height: 64px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 10px;
  box-sizing: border-box;
  text-align: ${({ withEmoji }) => (withEmoji ? 'left' : 'center')};
  background: ${({ theme }) => theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px rgba(84, 60, 151, 0.25);
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }
`;

export const StyledEmoji = styled(Emoji)`
  vertical-align: top;
`;

export const EmojiBlock = styled.div`
  margin-right: 12px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
`;
