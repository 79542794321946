import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const SelectContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
`;

export const ButtonStyled = styled(ButtonComponent)`
  height: 49px;
  margin: auto auto 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;
