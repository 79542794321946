import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { AnswerNatalChartV2ScreenProps } from 'core/funnel/entities';
import {
  Container,
  Content,
  Emoji,
  EmojiBorder,
  Image,
  ImageDesktop,
  Item,
  List,
  StyledTitle,
  Text,
} from './styled';

const AnswerNatalChartV2Screen = ({
  onAnswerClick,
  onBack,
  step,
  content,
  totalSteps,
}: AnswerNatalChartV2ScreenProps) => {
  const { title, answers, backgroundImage, progressBarTitle } = content;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <Container>
        <StyledTitle dangerouslyInnerHTML={title} />
        <Content>
          <List>
            {answers.map(({ text, emoji, value }) => (
              <Item key={text} onClick={() => onAnswerClick(value)}>
                <EmojiBorder>
                  {!!emoji && <Emoji emoji={emoji} />}
                  <Text key={text}>{text}</Text>
                </EmojiBorder>
              </Item>
            ))}
          </List>
        </Content>
      </Container>
      {backgroundImage && (
        <>
          <Image
            src={backgroundImage.mobileImage.src}
            alt={backgroundImage.mobileImage.alt}
            width={297}
            height={297}
            priority
          />
          <ImageDesktop
            src={backgroundImage.desktopImage.src}
            alt={backgroundImage.mobileImage.alt}
            width={599}
            height={599}
            priority
          />
        </>
      )}
    </>
  );
};

export default AnswerNatalChartV2Screen;
