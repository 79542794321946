import { useEffect } from 'react';
import Header from 'core/common/components/Header';
import { SECOND } from 'core/common/constants/';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ReportScreenProps } from 'core/funnel/entities';
import {
  CircleSvg,
  Container,
  DesktopScanIcon,
  Image,
  ImageContainer,
  InnerCircle,
  LineOverlay,
  MobileScanIcon,
  OuterCircle,
  StyledPath,
  StyledSvg,
  StyledTitle,
  Subtitle,
} from './styled';

const timer = 10 * SECOND;

const ReportScreen = ({
  onBack,
  goNext,
  content,
  scan,
  width,
  height,
  linesCoordinates,
  fingersCoordinates,
  isMobileDevice,
}: ReportScreenProps) => {
  const { title, subtitle } = content;
  const { photo } = scan;

  useEffect(() => {
    const redirectTimeout = setTimeout(() => goNext(), timer);

    return () => {
      clearTimeout(redirectTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header onBack={onBack} />
      <Container>
        <ImageContainer>
          <Image src={photo} alt="palm photo" width={width} height={height} />
          {isMobileDevice ? <MobileScanIcon /> : <DesktopScanIcon />}
          <LineOverlay>
            <StyledSvg width={width} height={height}>
              {fingersCoordinates.map((item) => (
                <CircleSvg key={`${item.x}${item.y}`}>
                  <OuterCircle cx={item.x} cy={item.y} r="10" />
                  <InnerCircle cx={item.x} cy={item.y} r="5" />
                </CircleSvg>
              ))}
              {Object.keys(linesCoordinates).map((key, index) => (
                <StyledPath
                  key={index}
                  color={linesCoordinates[key].color}
                  d={`M ${linesCoordinates[key].coordinates
                    .map((point) => `${point.x},${point.y}`)
                    .join(' L ')}`}
                />
              ))}
            </StyledSvg>
          </LineOverlay>
        </ImageContainer>
        <StyledTitle>{title}</StyledTitle>
        <Subtitle dangerouslySetInnerHTML={prepareHtml(subtitle)} />
      </Container>
    </>
  );
};

export default ReportScreen;
