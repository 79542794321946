import { useCallback, useEffect, useState } from 'react';
import TrialPaymentPalmistryScreen from 'core/common/components/QuizScreens/TrialPaymentPalmistryV3';
import { LANDINGS } from 'core/common/constants';
import { useServices, useRouter, useCountdown } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentPalmistryV3ScreenAttributes } from 'core/funnel/entities';
import { useQuizData } from 'core/funnel/hooks';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';

type TrialPaymentPalmistryV3Props = {
  id: string;
};

const TrialPaymentPalmistryV3 = ({ id }: TrialPaymentPalmistryV3Props) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { link } = useQuizData();
  const { navigate } = useRouter();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.PALMISTRY });

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffers, defaultOffers, selectedOffer } = useOffers();
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const [offer, setOffer] = useState<Offer | null>(null);

  const onOfferChanged = (plan: Offer) => {
    setOffer(plan);
    updateOffer(plan);
  };

  const onClosePaymentModal = () => {
    analyticsService.secretDiscountOpen();
  };

  const onCloseDiscountModal = () => {
    analyticsService.secretDiscountClose();
  };

  const navigateToDiscountPage = () => {
    analyticsService.secretDiscountClick();
    navigate(joinUrls(link, LANDINGS.TRIAL_PAYMENT_WITH_DISCOUNT));
  };

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    const initialOffer = isTimeUp ? timeUpOffers[1] : defaultOffers[1];

    if (initialOffer) {
      onOfferChanged(initialOffer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOffers, timeUpOffers, isTimeUp]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentPalmistryScreen
      offer={offer}
      isTimeUp={isTimeUp}
      timeUpOffers={timeUpOffers}
      defaultOffers={defaultOffers}
      selectedOffer={selectedOffer}
      timeLeft={specialPlanCountdown}
      goNext={next}
      getReport={getReport}
      onOfferChanged={onOfferChanged}
      onClosePaymentModal={onClosePaymentModal}
      onCloseDiscountModal={onCloseDiscountModal}
      navigateToDiscountPage={navigateToDiscountPage}
      {...(screenAttributes as TrialPaymentPalmistryV3ScreenAttributes)}
    />
  );
};

export default TrialPaymentPalmistryV3;
