import styled from 'styled-components';
import SectionAdvantagesWithEmoji from 'core/common/components/QuizScreensSections/SectionAdvantagesWithEmoji';
import SectionAttachmentStyleProfile from 'core/common/components/QuizScreensSections/SectionAttachmentStyleProfile';
import SectionBannerClickable from 'core/common/components/QuizScreensSections/SectionBannerClickable';
import SectionDiscountTicket from 'core/common/components/QuizScreensSections/SectionDiscountTicket';
import SectionFaqV2 from 'core/common/components/QuizScreensSections/SectionFaqV2';
import SectionGuarantee from 'core/common/components/QuizScreensSections/SectionGuarantee';
import SectionHighlights from 'core/common/components/QuizScreensSections/SectionHighlights';
import SectionNebulaRating from 'core/common/components/QuizScreensSections/SectionNebulaRating';
import SectionPartners from 'core/common/components/QuizScreensSections/SectionPartners';
import SectionPaywallHeader from 'core/common/components/QuizScreensSections/SectionPaywallHeader';
import SectionRating from 'core/common/components/QuizScreensSections/SectionRating';
import SectionRelationshipPlan from 'core/common/components/QuizScreensSections/SectionRelationshipPlan';

const pageContainerMaxWidthPx = 450;

export const ContainerStyled = styled.main`
  z-index: 1;
  width: 100%;
  max-width: ${pageContainerMaxWidthPx}px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
  padding-bottom: 68px;
`;

export const SectionPaywallHeaderStyled = styled(SectionPaywallHeader)`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${pageContainerMaxWidthPx}px;
  transform: translateX(-50%);
`;

export const SectionRelationshipPlanStyled = styled(SectionRelationshipPlan)`
  margin-top: 34px;
`;

export const SectionDiscountTicketStyled = styled(SectionDiscountTicket)`
  margin-top: 48px;
  margin-right: auto;
  margin-left: auto;
`;

export const SectionBannerClickableStyled = styled(SectionBannerClickable)`
  margin-top: 64px;
`;

export const SectionNebulaRatingStyled = styled(SectionNebulaRating)`
  margin-top: 24px;
`;

export const SectionAttachmentStyleProfileStyled = styled(SectionAttachmentStyleProfile)`
  margin-top: 64px;
`;

export const SectionAdvantagesWithEmojiStyled = styled(SectionAdvantagesWithEmoji)`
  margin-top: 64px;
`;

export const IntroOfferWrapperStyled = styled.div`
  margin-top: 64px;
`;

export const SectionHighlightsStyled = styled(SectionHighlights)`
  margin-top: 64px;
`;

export const SectionPartnersStyled = styled(SectionPartners)`
  margin-top: 64px;
`;

export const SectionFaqV2Styled = styled(SectionFaqV2)`
  margin-top: 64px;
`;

export const ReviewsCarouselWrapperStyled = styled.div`
  margin-top: 64px;
`;

export const SectionRatingStyled = styled(SectionRating)`
  margin-top: 64px;
`;

export const SectionGuaranteeStyled = styled(SectionGuarantee)`
  margin-top: 64px;
  margin-bottom: 0;
  background-color: #fafafa;
`;
