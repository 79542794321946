import styled from 'styled-components';

export const List = styled.div`
  position: absolute;
  width: 100%;
  max-height: 265px;
  border-radius: 10px;
  overflow: auto;
  background: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-height: 285px;
  }
`;
