import styled from 'styled-components';
import { QuizButton, TextMain } from 'core/common/components/Quiz/styled';
import Title from 'core/common/components/Title';

export const Background = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    99.42deg,
    rgba(255, 161, 0, 0.3) 36.23%,
    rgba(250, 124, 18, 0.2401) 54.33%,
    rgba(245, 87, 36, 0.27) 75.61%,
    rgba(242, 61, 48, 0.27) 95.48%,
    rgba(240, 45, 56, 0.27) 113.18%,
    rgba(239, 39, 59, 0.27) 126.89%
  );
`;

export const Text = styled(TextMain)`
  margin-bottom: 20px;
  font-size: 14px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 30px;
  line-height: 24px;
  text-align: left;
`;

export const AutocompleteContainer = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const Skip = styled.button`
  margin-bottom: 44px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.typography500};
  cursor: pointer;
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const HideButton = styled.button`
  margin-left: 20px;
`;

export const HideButtonExperiment = styled(HideButton)`
  color: ${(props) => props.theme.colors.typography100};
`;

export const Button = styled(QuizButton)<{ disabled?: boolean }>`
  z-index: inherit;
  margin-bottom: 15px;
  border-radius: 16px;
  opacity: ${(props) => props.disabled && 0.5};
  cursor: ${(props) => props.disabled && 'not-allowed'};
`;

export const StyledButton = styled(Button)`
  height: 50px;
  margin-top: 50px;
  border-radius: 12px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;
