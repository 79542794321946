import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line max-len
import TrialPaymentWithDiscountPalmistryV3Screen from 'core/common/components/QuizScreens/TrialPaymentWithDiscountPalmistryV3';
import { useCountdown, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import {
  FunnelName,
  TrialPaymentWithDiscountPalmistryV3ScreenAttributes,
} from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';

type TrialPaymentWithDiscountPalmistryV3Props = {
  id: string;
};

const TrialPaymentWithDiscountPalmistryV3 = ({ id }: TrialPaymentWithDiscountPalmistryV3Props) => {
  const { next, screenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { specialPlanCountdown } = useCountdown({ key: `${FunnelName.PALMISTRY}-with-discount` });

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffers, discountDefaultOffers, selectedOffer } = useOffers();

  const [offer, setOffer] = useState<Offer | null>(null);

  const onOfferChanged = (plan: Offer) => {
    setOffer(plan);
    updateOffer(plan);
  };

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
    analyticsService.landingPageClick('trial_billing_secret_discount', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    const initialOffer = isTimeUp ? timeUpOffers[1] : discountDefaultOffers[1];

    if (initialOffer) {
      onOfferChanged(initialOffer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountDefaultOffers, timeUpOffers, isTimeUp]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing_secret_discount');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentWithDiscountPalmistryV3Screen
      offer={offer}
      isTimeUp={isTimeUp}
      timeUpOffers={timeUpOffers}
      defaultOffers={discountDefaultOffers}
      selectedOffer={selectedOffer}
      timeLeft={specialPlanCountdown}
      goNext={next}
      getReport={getReport}
      onOfferChanged={onOfferChanged}
      {...(screenAttributes as TrialPaymentWithDiscountPalmistryV3ScreenAttributes)}
    />
  );
};

export default TrialPaymentWithDiscountPalmistryV3;
