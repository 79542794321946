import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { StoryMediaContent } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  TextStyled,
  HeaderImageWrapperStyled,
  ContentStyled,
  MainImageStyled,
} from './styled';

type MediaProps = StoryMediaContent;

const Media = ({ title, text, image, headerImage }: MediaProps) => {
  return (
    <WrapperStyled>
      {headerImage && (
        <HeaderImageWrapperStyled width={375} height={130}>
          <Image src={headerImage.src} alt={headerImage.alt} fill priority />
        </HeaderImageWrapperStyled>
      )}
      <ContentStyled>
        <TitleStyled variant="h3" dangerouslySetInnerHTML={prepareHtml(title)} />
        <TextStyled variant="body-large" dangerouslySetInnerHTML={prepareHtml(text)} />
        {image && (
          <MainImageStyled src={image.src} alt={image.alt} width={257} height={243} priority />
        )}
      </ContentStyled>
    </WrapperStyled>
  );
};

export default Media;
