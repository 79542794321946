import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const SuccessBadgeStyled = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  background-color: #e6f5eb;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 60px;
    font-size: 18px;
  }
`;

export const ImageStyled = styled(Image)`
  margin-right: 8px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 28px;
    height: 28px;
  }
`;
