import { SectionInsidesAttributes } from 'core/funnel/entities';
import { DescriptionStyled, ImageStyled, TitleStyled, WrapperStyled } from './styled';

type SectionInsightsProps = {
  content: SectionInsidesAttributes['content'];
};

const SectionInsights = ({ content }: SectionInsightsProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{content.title}</TitleStyled>
      {content.description && <DescriptionStyled>{content.description}</DescriptionStyled>}
      <ImageStyled src={content.image.src} alt={content.image.alt} width={552} height={259} />
    </WrapperStyled>
  );
};

export default SectionInsights;
