import { useCallback, useEffect, useState } from 'react';
import TrialPaymentAttachmentStyleScreen from 'core/common/components/QuizScreens/TrialPaymentAttachmentStyle';
import { LANDINGS } from 'core/common/constants';
import { useCountdown, useRouter, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { FunnelName, TrialPaymentAttachmentStyleScreenAttributes } from 'core/funnel/entities';
import { useQuizData } from 'core/funnel/hooks';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';
import { ZodiacService } from 'core/zodiac/services';

type TrialPaymentAttachmentStyleProps = {
  id: string;
};

const TrialPaymentAttachmentStyle = ({ id }: TrialPaymentAttachmentStyleProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { link } = useQuizData();
  const { navigate } = useRouter();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.ATTACHMENT_STYLE });
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffers, defaultOffers, selectedOffer } = useOffers();

  const [offer, setOffer] = useState<Offer | null>(null);

  const onOfferChanged = (plan: Offer) => {
    setOffer(plan);
    updateOffer(plan);
  };

  const onClosePaymentModal = () => {
    analyticsService.secretDiscountOpen();
  };

  const onCloseDiscountModal = () => {
    analyticsService.secretDiscountClose();
  };

  const navigateToDiscountPage = () => {
    analyticsService.secretDiscountClick();
    navigate(joinUrls(link, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  const zodiac = userPreferences.zodiac;
  const zodiacIcon = ZodiacService.getZodiacSignIcon(zodiac);

  useEffect(() => {
    const initialOffer = isTimeUp ? timeUpOffers[1] : defaultOffers[1];

    if (initialOffer) {
      onOfferChanged(initialOffer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOffers, timeUpOffers, isTimeUp]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentAttachmentStyleScreen
      getReport={getReport}
      goNext={next}
      timeLeft={specialPlanCountdown}
      defaultOffers={defaultOffers}
      timeUpOffers={timeUpOffers}
      selectedOffer={selectedOffer}
      offer={offer}
      traumaScore={userPreferences.traumaScore}
      isTimeUp={isTimeUp}
      onOfferChanged={onOfferChanged}
      onClosePaymentModal={onClosePaymentModal}
      onCloseDiscountModal={onCloseDiscountModal}
      navigateToDiscountPage={navigateToDiscountPage}
      zodiacIcon={zodiacIcon}
      {...(screenAttributes as TrialPaymentAttachmentStyleScreenAttributes)}
    />
  );
};

export default TrialPaymentAttachmentStyle;
