import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 135%;
  text-align: center;
`;

export const BannerStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #3c1c7b;
  cursor: pointer;
`;

export const BannerContentStyled = styled.div`
  z-index: 1;
`;

export const BannerTitleStyled = styled.h4`
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 135%;
  color: #f6cf53;
`;

export const BannerTextStyled = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const ButtonStyled = styled.button`
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  border-radius: 50%;
  background-color: white;
`;

export const IconStyled = styled(Icon)`
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
`;

export const BackgroundImageStyled = styled(Image)`
  object-fit: cover;
`;
