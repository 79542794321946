import { HTMLAttributes } from 'react';
import { Variant } from './types';
import {
  TypographyExtraSmallBoldStyled,
  TypographyExtraSmallStyled,
  TypographyH1Styled,
  TypographyH2Styled,
  TypographyH3Styled,
  TypographyH4Styled,
  TypographyLargeBoldStyled,
  TypographyLargeStyled,
  TypographyMediumBoldStyled,
  TypographyMediumStyled,
  TypographySmallBoldStyled,
  TypographySmallStyled,
} from './styled';

type TypographyProps = {
  variant: Variant;
} & HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement>;

const Typography = ({ variant, children, ...otherProps }: TypographyProps) => {
  const renderComponent = () => {
    switch (variant) {
      case 'h1':
        return <TypographyH1Styled {...otherProps}>{children}</TypographyH1Styled>;
      case 'h2':
        return <TypographyH2Styled {...otherProps}>{children}</TypographyH2Styled>;
      case 'h3':
        return <TypographyH3Styled {...otherProps}>{children}</TypographyH3Styled>;
      case 'h4':
        return <TypographyH4Styled {...otherProps}>{children}</TypographyH4Styled>;
      case 'body-large':
        return <TypographyLargeStyled {...otherProps}>{children}</TypographyLargeStyled>;
      case 'body-large-bold':
        return <TypographyLargeBoldStyled {...otherProps}>{children}</TypographyLargeBoldStyled>;
      case 'body-medium':
        return <TypographyMediumStyled {...otherProps}>{children}</TypographyMediumStyled>;
      case 'body-medium-bold':
        return <TypographyMediumBoldStyled {...otherProps}>{children}</TypographyMediumBoldStyled>;
      case 'body-small':
        return <TypographySmallStyled {...otherProps}>{children}</TypographySmallStyled>;
      case 'body-small-bold':
        return <TypographySmallBoldStyled {...otherProps}>{children}</TypographySmallBoldStyled>;
      case 'body-extra-small':
        return <TypographyExtraSmallStyled {...otherProps}>{children}</TypographyExtraSmallStyled>;
      case 'body-extra-small-bold':
        return (
          <TypographyExtraSmallBoldStyled {...otherProps}>
            {children}
          </TypographyExtraSmallBoldStyled>
        );
    }
  };

  return renderComponent();
};

export default Typography;
