import styled from 'styled-components';
import Image from 'core/common/components/Image';
import LinearProgress from 'core/common/components/LinearProgress';

export const CardStyled = styled.div`
  z-index: 1;
  margin-top: 120px;
  padding: 16px 12px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.typography100};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const GoalLabelStyled = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(
    180deg,
    #1d1e55 0%,
    #272463 25%,
    #3b2f7b 50%,
    #4f358d 75%,
    #6939a2 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const GoalLabelImageStyled = styled(Image)`
  margin-right: 4px;
`;

export const GoalStyled = styled.h3`
  font-size: 18px;
  line-height: 24px;
`;

export const DividerStyled = styled.div`
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.typography900 + '10'};
`;

export const AnalysisTextStyled = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const LinearProgressStyled = styled(LinearProgress)`
  margin-top: 10px;
`;
