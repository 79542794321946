import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';
import {
  Header,
  TimeBlock,
  TimerDivider,
  TimerUnitCell,
  TimerUnitLabel,
  TimerUnitText,
  Title,
} from './styled';

type HeaderSectionProps = {
  timeLeft: number;
  content: { min?: string; sec?: string; title: string };
};

const HeaderSection = ({ timeLeft, content }: HeaderSectionProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);
  const { min, title, sec } = content;

  return (
    <Header>
      <Title>{title}</Title>
      <TimeBlock>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(minutes)}</TimerUnitText>
          <TimerUnitLabel>{min ?? 'min'}</TimerUnitLabel>
        </TimerUnitCell>
        <TimerDivider>:</TimerDivider>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(seconds)}</TimerUnitText>
          <TimerUnitLabel>{sec ?? 'sec'}</TimerUnitLabel>
        </TimerUnitCell>
      </TimeBlock>
    </Header>
  );
};

export default HeaderSection;
