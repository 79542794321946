import {
  Component,
  HTMLAttributes,
  FocusEvent,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import LibInput, {
  Props,
  State,
  DefaultInputComponentProps,
  Country,
  Value,
} from 'react-phone-number-input';
import { useLogger } from 'core/common/hooks';
import { isSupportedPhoneNumberCountry } from 'core/common/utils/phoneNumber';
import PhoneFieldSelect, { PhoneFieldSelectProps } from './PhoneFieldSelect/PhoneFieldSelect';
import { PhoneInput } from './styled';

type RefCallback = (
  input: Component<
    Props<DefaultInputComponentProps>,
    State<Props<DefaultInputComponentProps>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  > | null,
) => void | (() => void);

export type PhoneFieldProps = {
  onChange: (phoneNumber: Value) => void;
  value?: string;
  onCountryChange?(country: Country): void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  readOnly?: boolean;
  error?: string | null;
  placeholder?: string;
  defaultCountry?: Country;
  addInternationalOption?: boolean;
  smartCaret?: boolean;
  international?: boolean;
  limitMaxLength?: boolean;
  countryCallingCodeEditable?: boolean;
  focusInputOnCountrySelection?: boolean;
  label?: string;
  labelProps?: HTMLAttributes<HTMLSpanElement>;
  countrySelectProps?: Partial<PhoneFieldSelectProps>;
  inputProps?: Partial<{ className: string }>;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>;

const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>((props, forwardedRef) => {
  const {
    placeholder: passedPlaceholder,
    id,
    onChange,
    value,
    defaultCountry,
    onCountryChange: passedOnCountryChange,
    inputProps: passedInputProps = {},
    countrySelectProps: passedCountrySelectProps = {},
    onFocus: passedOnFocus,
    onBlur: passedOnBlur,
    focusInputOnCountrySelection = true,
    ...otherProps
  } = props;

  const logger = useLogger();

  const inputRef = useRef<HTMLInputElement>();

  const placeholder = passedPlaceholder ?? 'Mobile number';

  const onFocus = (e: FocusEvent<HTMLElement>) => {
    if (passedOnFocus) {
      passedOnFocus(e);
    }
  };

  const onBlur = (e: FocusEvent<HTMLElement>) => {
    if (passedOnBlur) {
      passedOnBlur(e);
    }
  };

  const onSetRef = (input: HTMLInputElement) => {
    inputRef.current = input;

    if (!forwardedRef) return;

    if (typeof forwardedRef === 'function') {
      forwardedRef(input);
    } else {
      forwardedRef.current = input;
    }
  };

  const onCountryChange = (country: Country) => {
    if (passedOnCountryChange) {
      passedOnCountryChange(country);
    }

    if (!focusInputOnCountrySelection) return;

    requestAnimationFrame(forceFocus);
  };

  const forceFocus = () => {
    if (!inputRef || !inputRef.current) return;

    inputRef.current.focus();
  };

  useEffect(() => {
    if (!defaultCountry) return;

    if (!isSupportedPhoneNumberCountry(defaultCountry)) {
      logger.warn('Default country not supported for phone field.', {
        data: defaultCountry,
      });
    }
  }, [defaultCountry, logger]);

  const countrySelectProps: Partial<PhoneFieldSelectProps> = {
    ...passedCountrySelectProps,
  };
  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...passedInputProps,
  };

  return (
    <LibInput
      // TODO: do better typing
      ref={onSetRef as unknown as RefCallback}
      id={id}
      onChange={onChange}
      onCountryChange={onCountryChange}
      placeholder={placeholder}
      countrySelectComponent={PhoneFieldSelect}
      countrySelectProps={countrySelectProps}
      numberInputProps={inputProps}
      value={value}
      defaultCountry={defaultCountry}
      inputComponent={PhoneInput}
      focusInputOnCountrySelection={false}
      onFocus={onFocus}
      onBlur={onBlur}
      {...otherProps}
    />
  );
});

export default PhoneField;
