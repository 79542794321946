import ScanV2Screen from 'core/common/components/QuizScreens/ScanV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ScanV2ScreenAttributes } from 'core/funnel/entities';

export type ScanV2Props = {
  id: string;
};

const ScanV2 = ({ id }: ScanV2Props) => {
  const { back, screenAttributes, next, step } = useQuizStep(id);

  return (
    <ScanV2Screen
      step={step}
      onBack={back}
      goNext={next}
      {...(screenAttributes as ScanV2ScreenAttributes)}
    />
  );
};

export default ScanV2;
