import styled from 'styled-components';
import Address from 'core/common/components/Address';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const aspectRatioInPercents = '56.25%';
const containerWidth = '383px';

export const StyledContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: ${containerWidth};
    margin-right: auto;
    margin-left: auto;
  }
`;

export const StyledAdvantagesList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 32px;
  }
`;

export const StyledAdvantagesListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.infoLight};
  border-radius: 8px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledAdvantagesTextWrapper = styled.div`
  flex-grow: 1;
  line-height: 10px;
`;

export const StyledAdvantagesTitle = styled.p`
  font-size: 8px;
  text-transform: uppercase;
`;

export const StyledAdvantagesText = styled.span`
  font-size: 10px;
  font-weight: 700;
`;

export const StyledQuizInformationListIcon = styled(Image)`
  flex-shrink: 0;
  margin-right: 4px;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: ${aspectRatioInPercents};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
`;

export const StyledQuizInformationList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const StyledQuizInformationListItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding: 4px 12px;
  border: 1px solid ${({ theme }) => theme.colors.typography900 + '12'};
  border-radius: 100px;
`;

export const StyledQuizInformationListText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const StyledTitle = styled.h2`
  margin-top: 12px;
  line-height: 32px;
`;

export const StyledDescription = styled.p`
  margin-top: 4px;
  padding-bottom: 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 0;
  }
`;

export const StyledSignInButton = styled(TextButton)`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const StyledAddress = styled(Address)`
  margin-top: 6px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 330px;
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

export const StyledAddressUS = styled.p`
  max-width: 330px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const StyledFooter = styled.footer`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 97px;
  }
`;

export const StyledButton = styled(Button)`
  height: 56px;
  margin-bottom: 12px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
