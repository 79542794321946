import { Trans } from 'react-i18next';
import { Offer } from 'core/offers/entities';
import { BoldStyled, BreakStyled, DescriptionStyled } from './styled';

export type SectionPaymentContentOtherLocationProps = {
  offer: Offer;
  trialPeriod: string;
};

const SectionPaymentContentOtherLocation = ({
  offer,
  trialPeriod,
}: SectionPaymentContentOtherLocationProps) => {
  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  return (
    <DescriptionStyled>
      <Trans
        i18nKey={`${tKeyPrefix}.charge.trial`}
        values={{
          price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
          trialPeriod,
        }}
        components={{
          bold: <BoldStyled />,
          br: <BreakStyled />,
        }}
      />
    </DescriptionStyled>
  );
};

export default SectionPaymentContentOtherLocation;
