import { ReactNode } from 'react';
import { GuaranteeIconStyled, TextStyled, WrapperStyled } from './styled';

export type GuaranteeProps = {
  className?: string;
  children: ReactNode;
};

const Guarantee = ({ className, children }: GuaranteeProps) => {
  return (
    <WrapperStyled className={className}>
      <GuaranteeIconStyled height={24} width={24} name="guarantee" />
      <TextStyled>{children}</TextStyled>
    </WrapperStyled>
  );
};

export default Guarantee;
