import { Trans } from 'react-i18next';
import { CurrencySymbol } from 'core/user/entities';
import { Bold, Break, Description } from './styled';

export type SectionPaymentContentOtherLocationProps = {
  price: number;
  currencySymbol: CurrencySymbol;
  trialPeriod: string;
};

const SectionPaymentContentOtherLocation = ({
  price,
  currencySymbol,
  trialPeriod,
}: SectionPaymentContentOtherLocationProps) => {
  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  return (
    <Description>
      <Trans
        i18nKey={`${tKeyPrefix}.charge.trial`}
        values={{
          price: `${currencySymbol}${price}`,
          trialPeriod,
        }}
        components={{
          bold: <Bold />,
          br: <Break />,
        }}
      />
    </Description>
  );
};

export default SectionPaymentContentOtherLocation;
