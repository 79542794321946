import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const BlurredReportWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const BlurredReportBody = styled.div`
  position: relative;
  padding-top: 20px;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const ReportTitle = styled.h3`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const UserInfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 16px 20px;
  border-radius: 20px;
  background: #eaeef7;
`;

export const ZodiacImageBlock = styled.div`
  flex: 1;
  text-align: center;
`;

export const ZodiacImage = styled.img``;

export const LogoImageBlock = styled.div`
  flex: 1 1 0%;
  text-align: center;
`;

export const LogoImage = styled.img`
  max-height: 140px;
  margin-top: 4px;
`;

export const Item = styled.li``;
export const NumberedList = styled.ol`
  margin-top: 12px;
  margin-bottom: 20px;
  counter-reset: item;
  list-style: none;

  & > ${Item}:before {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.typography700};
    content: counter(item) '. ';
    counter-increment: item;
  }
`;

export const ItemText = styled.p`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const ContentBlock = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 20px 20px;
`;

export const ContentTitle = styled.h5`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const ContentText = styled.p`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const BlurOuterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  padding-top: 34px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(251, 251, 255, 0) 3.34%,
    rgba(251, 251, 255, 0.8) 24.99%,
    rgba(251, 251, 255, 0.9) 45.63%,
    rgba(255, 251, 251, 0.9) 69.74%,
    ${({ theme }) => theme.colors.themeBackground} 89.93%
  );
`;

export const BlurInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  padding-bottom: 48px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(251, 251, 255, 0) 0%,
    rgba(251, 251, 255, 0.18) 22.4%,
    rgba(251, 251, 255, 0.16) 43.75%,
    rgba(251, 251, 255, 0.14) 75%,
    rgba(251, 251, 255, 0.198) 89.58%
  );
  backdrop-filter: blur(1.5px);
`;

export const AccessDescription = styled.p`
  width: 240px;
  margin: 0 auto 28px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: auto;
  }
`;

export const Button = styled(ButtonComponent)`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const BlurredReportTopPart = styled.div`
  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 540px;
  }
`;

export const BlurredReportImpulseButton = styled(ButtonComponent)`
  font-weight: 700;
  text-transform: uppercase;
`;
