import StoriesScreen from 'core/common/components/QuizScreens/Stories';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { StoriesScreenAttributes } from 'core/funnel/entities';

type StoriesProps = {
  id: string;
};

const Stories = ({ id }: StoriesProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  return <StoriesScreen goNext={next} {...(screenAttributes as StoriesScreenAttributes)} />;
};

export default Stories;
