import { useCallback } from 'react';
import LoaderScreen from 'core/common/components/QuizScreens/Loader';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ProcessingScreenAttributes } from 'core/funnel/entities';

type LoadingProps = {
  id: string;
};

const Loading = ({ id }: LoadingProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  const onLoadingFinished = useCallback(async () => {
    await next({ force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoaderScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as ProcessingScreenAttributes)}
    />
  );
};

export default Loading;
