import { useRef, useState } from 'react';
import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import Reviews from 'core/common/components/Reviews';
import { useExtendedPolicy, useIsInViewport } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentScreenProps } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import { Locales, notPaidUtmSources, paidUtmSources } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import FAQ from './FAQ';
import Partners from './Partners';
import SalesScreenHeader from './SalesScreenHeader';
import SectionBenefits from './SectionBenefits';
import SectionBlurredReport from './SectionBlurredReport';
import SectionDisclaimer from './SectionDisclaimer';
import SectionDisclaimerWithCheckbox from './SectionDisclaimerWithCheckbox';
import SectionGoal from './SectionGoal';
import SectionPayment from './SectionPayment';
import SectionSpecialOffer from './SectionSpecialOffer';
import SectionUserDetails from './SectionUserDetails';
import {
  ColoredBackgroundWrapper,
  FooterTextStyled,
  HeaderSpaceStyled,
  MainContainerWideStyled,
  PageSectionContainerStyled,
  TitleStyled,
} from './styled';

const offerScrollOffset = -80;
const offerBlockId = 'offer-block';

const TrialPaymentScreen = ({
  content,
  timeLeft,
  offer,
  relationshipType,
  timeUpOffer,
  zodiacImage,
  partnerZodiacImage,
  getReport,
  goNext,
  paymentDialogClose,
}: TrialPaymentScreenProps) => {
  const {
    header,
    userDetails,
    title,
    goal,
    specialOffer,
    report,
    reviews,
    benefits,
    featuredIn,
    faq,
    footer,
  } = content;

  const { isMobile } = useIsMobile();
  const { handlePolicyClick, setDisplayPolicyError, isPolicyAccepted, displayPolicyError } =
    useExtendedPolicy();

  const firstButtonRef = useRef<HTMLDivElement>(null);
  const secondButtonRef = useRef<HTMLDivElement>(null);
  const isFirstButtonInViewPort = useIsInViewport(firstButtonRef);
  const isSecondButtonInViewPort = useIsInViewport(secondButtonRef);
  const hideHeader = isFirstButtonInViewPort || isSecondButtonInViewPort;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const isTimeUp = timeLeft <= 0;

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const onGetReport = () => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);
      scrollToBlock(offerBlockId, offerScrollOffset);

      return;
    }

    setIsPaymentModalOpen(true);
    getReport();
  };

  if (!timeUpOffer || !offer) {
    return null;
  }

  return (
    <>
      {!isMobile && <Header buttonBack={false} />}
      <MainContainerWideStyled>
        <PageSectionContainerStyled noGutters>
          <ColoredBackgroundWrapper>
            <SalesScreenHeader
              title={header.title}
              button={header.button}
              timerMinTitle={header.min}
              timerSecTitle={header.sec}
              hide={isMobile && hideHeader}
              onScrollToBlock={moveToOffer}
              timeLeft={timeLeft}
            />
            <HeaderSpaceStyled />
            {/* TODO refactor this component */}
            <SectionUserDetails
              zodiacImage={zodiacImage}
              partnerZodiacImage={partnerZodiacImage}
              relationshipType={relationshipType}
              userDetails={userDetails}
            />
          </ColoredBackgroundWrapper>
        </PageSectionContainerStyled>
        <PageSectionContainerStyled maxWidth={540}>
          <TitleStyled>{title}</TitleStyled>
          <SectionGoal title={goal.title} relationshipGoal={goal.description} />
          <SectionSpecialOffer
            specialOffer={specialOffer}
            id={offerBlockId}
            offer={offer}
            timeUpOffer={timeUpOffer}
            onGetReport={onGetReport}
            timeLeft={timeLeft}
            ref={firstButtonRef}
          />
          <Location>
            <LocationOption option={Locales.US}>
              <MediaSourceVariant name={paidUtmSources}>
                <SectionDisclaimer
                  displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  displayPlanPrice={
                    isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT
                  }
                />
              </MediaSourceVariant>
              <MediaSourceVariant name={notPaidUtmSources}>
                <SectionDisclaimerWithCheckbox
                  displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  displayPlanPrice={
                    isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT
                  }
                  isCheckboxChecked={isPolicyAccepted}
                  isConsentErrorOccured={displayPolicyError}
                  onCheckboxClick={handlePolicyClick}
                />
              </MediaSourceVariant>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <SectionDisclaimer
                displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                displayPlanPrice={
                  isTimeUp
                    ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                    : offer.pricesWithCurrencySymbol.priceWithVAT
                }
              />
            </LocationOption>
          </Location>
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <SectionBlurredReport
            zodiacImage={zodiacImage}
            report={report}
            zodiacSign={userDetails.zodiacSign}
            gender={userDetails.gender}
            onScrollToBlock={moveToOffer}
            onGetReport={onGetReport}
          />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{reviews.title}</TitleStyled>
          <Reviews list={reviews.list} />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{benefits.title}</TitleStyled>
          <SectionBenefits benefits={benefits.list} />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{featuredIn.title}</TitleStyled>
          <Partners />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{faq.title}</TitleStyled>
          <FAQ questions={faq.list} />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled maxWidth={540} offset={20}>
          <SectionSpecialOffer
            specialOffer={specialOffer}
            id={offerBlockId}
            offer={offer}
            timeUpOffer={timeUpOffer}
            onGetReport={onGetReport}
            timeLeft={timeLeft}
            ref={firstButtonRef}
          />
          <Location>
            <LocationOption option={Locales.US}>
              <MediaSourceVariant name={paidUtmSources}>
                <SectionDisclaimer
                  displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  displayPlanPrice={
                    isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT
                  }
                />
              </MediaSourceVariant>
              <MediaSourceVariant name={notPaidUtmSources}>
                <SectionDisclaimerWithCheckbox
                  displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  displayPlanPrice={
                    isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT
                  }
                  isCheckboxChecked={isPolicyAccepted}
                  isConsentErrorOccured={displayPolicyError}
                  onCheckboxClick={handlePolicyClick}
                />
              </MediaSourceVariant>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <SectionDisclaimer
                displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                displayPlanPrice={
                  isTimeUp
                    ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                    : offer.pricesWithCurrencySymbol.priceWithVAT
                }
              />
            </LocationOption>
          </Location>
        </PageSectionContainerStyled>
        <Location>
          <LocationOption option={Locales.US}>
            <FooterTextStyled>{footer.addressUS}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.AE}>
            <FooterTextStyled>{footer.addressAE}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <FooterTextStyled>{footer.address}</FooterTextStyled>
          </LocationOption>
        </Location>
      </MainContainerWideStyled>
      <RenderOnlyOnClient>
        {/* TODO refactor this component */}
        <SectionPayment
          onPaymentSuccess={goNext}
          isTimeUp={isTimeUp}
          isOpen={isPaymentModalOpen}
          offer={offer}
          onClose={paymentDialogClose}
        />
      </RenderOnlyOnClient>
    </>
  );
};

export default TrialPaymentScreen;
