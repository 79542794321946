import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';
import { ButtonWrapper } from '../AnswerButton/styled';

export const IconStyled = styled(Icon)``;

export const RadioButtonWrapper = styled(ButtonWrapper)<{ withEmoji: boolean; isChosen: boolean }>`
  color: ${(props) => props.isChosen && props.theme.colors.typography200};
  background: ${(props) => props.isChosen && props.theme.gradients.primary};
  ${IconStyled} {
    display: ${(props) => (props.isChosen ? 'block' : 'none')};
    fill: ${({ theme }) => theme.colors.info};
  }
`;

export const Circle = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.typography100};
`;
