import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 43px;
  }
`;

export const ListStyled = styled.ul`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 28px;
  }
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ListItemTextStyled = styled.span`
  margin-left: 8px;
  font-size: 14px;
`;

export const ImageStyled = styled(Image)`
  border-radius: 16px;
  object-fit: cover;
  object-position: top;
`;
