import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  margin-right: auto;
  margin-bottom: 56px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const BreakStyled = styled.br``;

export const ContentStyled = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(153, 116, 246, 0.15) 0%,
    rgba(233, 226, 252, 0.15) 25%,
    rgba(254, 254, 254, 0.15) 52.98%,
    rgba(233, 226, 252, 0.15) 75%,
    rgba(153, 116, 246, 0.15) 100%
  );
`;

export const ImageStyled = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: auto;
    height: 370px;
  }
`;

export const UserDetailsListStyled = styled.ul`
  display: flex;
  margin-bottom: 12px;
`;

export const UserDetailsListItemStyled = styled.li`
  min-width: 140px;
`;

export const ListTitleStyled = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const ListTextStyled = styled.span`
  font-size: 14px;
`;

export const BlurredTextStyled = styled.b`
  text-shadow: 0 0 10px ${({ theme }) => theme.colors.typography900};
  color: transparent;
`;

export const GuideListStyled = styled.ul`
  margin-bottom: -12px;
`;

export const GuideListItemStyled = styled.li`
  margin-bottom: 12px;
`;

export const WrapperIconStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  backdrop-filter: blur(5px);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    height: 90px;
  }
`;

export const IconStyled = styled(Icon)`
  width: 44px;
  height: 44px;
  fill: ${({ theme }) => theme.colors.typography500};
`;
