import styled from 'styled-components';
import ButtonClose from 'core/common/components/ButtonClose';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const WrapperStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const TitleStyled = styled(Title)`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 135%;
  text-align: left;
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 72px;
  font-weight: 400;
  text-align: left;
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const ImageStyled = styled(Image)`
  margin-bottom: 52px;
  object-fit: cover;
`;

export const SkipButtonStyled = styled(TextButton)`
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
