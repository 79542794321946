import styled from 'styled-components';
import ButtonClose from 'core/common/components/ButtonClose';
import Modal from 'core/common/components/Modal';

export const StyledModal = styled(Modal)`
  height: 100%;
  padding-top: 20px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 43px 15px 104px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: #fff;
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
