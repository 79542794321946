import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const listImageOffsetPx = 10;
const listImageSizePx = {
  mobile: 64,
  desktop: 80,
};

export const ContainerStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 12px;
  font-size: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 16px;
    font-size: 32px;
  }
`;

export const DescriptionStyled = styled.p`
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-top: 60%;
`;

export const ImageStyled = styled(Image)`
  border-radius: 12px;
  object-fit: contain;
`;

export const ListWrapperStyled = styled.div`
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 32px 20px;
  border-radius: 20px;
  background: linear-gradient(270deg, #9974f6 0%, rgba(200, 200, 200, 0.1) 100%);
`;

export const ListTitleStyled = styled.h3`
  max-width: calc(100% - ${listImageSizePx.mobile}px);
  font-size: 18px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: calc(100% - ${listImageSizePx.desktop}px);
    font-size: 22px;
  }
`;

export const ListImageStyled = styled(Image)`
  position: absolute;
  top: ${listImageOffsetPx}px;
  right: ${listImageOffsetPx}px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: ${listImageSizePx.desktop}px;
    height: ${listImageSizePx.desktop}px;
  }
`;

export const ListStyled = styled.ul`
  margin-top: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 30px;
  }
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const IconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 4px;
`;

export const StickyButtonWrapperStyled = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 140px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
