import { useSelector } from 'react-redux';
import MoonPhaseScreen from 'core/common/components/QuizScreens/MoonPhase';
import { useQuizStep } from 'core/funnel/contexts';
import { MoonPhaseScreenAttributes } from 'core/funnel/entities';
import { getMoonImage } from 'core/moon-compatibility/store';

export type MoonPhaseProps = {
  id: string;
};

const MoonPhase = ({ id }: MoonPhaseProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  const onBack = () => back();

  const moonImage = useSelector(getMoonImage);

  return (
    <MoonPhaseScreen
      onBack={onBack}
      goNext={next}
      moonImage={moonImage}
      {...(screenAttributes as MoonPhaseScreenAttributes)}
    />
  );
};

export default MoonPhase;
