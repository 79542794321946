import { useEffect } from 'react';
import { useGetAuthorizationToken } from 'core/auth/hooks';
import LoadingAskScreen from 'core/common/components/QuizScreens/LoadingAsk';
import { useRedirectToAskNebula, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { LoadingAskScreenAttributes } from 'core/funnel/entities';

type LoadingAskProps = {
  id: string;
};

const LoadingAsk = ({ id }: LoadingAskProps) => {
  const { screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { token } = useGetAuthorizationToken();
  const { redirectToQuizCatalogue } = useRedirectToAskNebula();

  const onLoadingFinished = () => {
    redirectToQuizCatalogue(token);
  };

  useEffect(() => {
    analyticsService.onLoadingPageOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingAskScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as LoadingAskScreenAttributes)}
    />
  );
};

export default LoadingAsk;
