import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  width: 50%;
`;

export const WrapperRightAlign = styled(Wrapper)`
  text-align: right;
`;

export const WrapperWellGood = styled.div`
  width: 100%;
  text-align: center;
`;
