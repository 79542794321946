import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentMoonCompatibilityScreen from 'core/common/components/QuizScreens/TrialPaymentMoonCompatibility';
import { BASE_ROUTES, LANDINGS } from 'core/common/constants';
import { useServices, useDispatch, useRouter, useCountdown } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import { getMoonData } from 'core/moon-compatibility/store';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';

type TrialPaymentMoonCompatibilityProps = {
  id: string;
};

const TrialPaymentMoonCompatibility = ({ id }: TrialPaymentMoonCompatibilityProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);

  const { specialPlanCountdown } = useCountdown({ key: FunnelName.MOON_COMPATIBILITY });

  const { navigate } = useRouter();
  const dispatch = useDispatch();
  const { orderId } = useOrder();
  const { analyticsService } = useServices();
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const email = userPreferences.email;

  const { updateOffer, selectedOffer, timeUpOffer, defaultOffers } = useOffers();

  const defaultOffer = selectedOffer ?? defaultOffers[0];

  const isTimeUp = !+specialPlanCountdown;
  const offer = isTimeUp ? timeUpOffer : defaultOffer;

  const { moonImage, moonSignImage, partnerMoonImage, partnerMoonSignImage } =
    useSelector(getMoonData);

  useEffect(() => {
    if (offer) {
      analyticsService.landingPageOpened('trial_billing');
      analyticsService.addToCart({
        price: offer.prices.priceWithVAT,
        productId: offer.offerId,
        email,
        orderId,
        currency: offer.currency,
      });
    }
  }, [analyticsService, offer, email, orderId]);

  const getReport = useCallback(() => {
    if (!offer) return;

    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);

    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, dispatch, offer, email, orderId]);

  const onPaymentDialogClose = () => {
    navigate(joinUrls(BASE_ROUTES.MOON_COMPATIBILITY, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  return (
    <TrialPaymentMoonCompatibilityScreen
      offer={offer}
      timeUpOffer={timeUpOffer}
      timeLeft={specialPlanCountdown}
      moonImage={moonImage}
      moonSignImage={moonSignImage}
      partnerMoonImage={partnerMoonImage}
      partnerMoonSignImage={partnerMoonSignImage}
      getReport={getReport}
      onSucceed={next}
      onPaymentDialogClose={onPaymentDialogClose}
      {...(screenAttributes as TrialPaymentMoonCompatibilityScreenAttributes)}
    />
  );
};

export default TrialPaymentMoonCompatibility;
