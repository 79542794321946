import styled from 'styled-components';
import Image from 'core/common/components/Image';

const listItemsGap = 9;

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  text-align: center;
`;

export const PartnersListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PartnersListItemStyled = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - ${listItemsGap}px);
  height: 77px;
  margin-bottom: ${listItemsGap}px;
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  background-color: #f5f5f5;

  &:nth-child(odd) {
    margin-right: ${listItemsGap}px;
  }
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  flex-grow: 0.8;
  height: 56%;
`;
