import { useEffect } from 'react';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { EmailMarketingPaywallScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder } from 'core/payments/hooks';

type EmailMarketingPaywallProps = {
  id: string;
};

const EmailMarketingPaywall = ({ id }: EmailMarketingPaywallProps) => {
  const { order } = useGetOrder();
  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);
  const { fetchOffersByCategory } = useOffers();

  const { analyticPaywall } = screenAttributes as EmailMarketingPaywallScreenAttributes;

  useEffect(() => {
    if (!order) return;

    fetchOffersByCategory();

    // Necessary to fix the problem with synchronization of data from the redux and localstorage
    setTimeout(next, SECOND);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (analyticPaywall) {
      analyticsService.emailMarketingLpOpened(analyticPaywall);
    }
  }, [analyticsService, analyticPaywall]);

  return null;
};

export default EmailMarketingPaywall;
