import LoaderWithReviewsScreen from 'core/common/components/QuizScreens/LoaderWithReviews';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { LoaderWithReviewsScreenAttributes } from 'core/funnel/entities';

export type LoaderWithReviewsProps = {
  id: string;
};

const LoaderWithReviews = ({ id }: LoaderWithReviewsProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  return (
    <LoaderWithReviewsScreen
      onLoadingFinished={next}
      {...(screenAttributes as LoaderWithReviewsScreenAttributes)}
    />
  );
};

export default LoaderWithReviews;
