import EmailConfirmPalmistryScreen from 'core/common/components/QuizScreens/EmailConfirmPalmistry';
import { normalizeError } from 'core/common/errors';
import { useLogger, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { EmailConfirmPalmistryScreenAttributes } from 'core/funnel/entities';

type EmailConfirmPalmistryProps = {
  id: string;
};

const EmailConfirmPalmistry = ({ id }: EmailConfirmPalmistryProps) => {
  const logger = useLogger();
  const { analyticsService } = useServices();
  const { screenAttributes, back, next, updateUserSettings } = useQuizStep(id);

  const handleSubmit = async (isAgree: boolean) => {
    try {
      await updateUserSettings(isAgree);
    } catch (err) {
      const error = normalizeError(err);
      logger.error(error);
    }
    await next();
  };

  const onUserAgree = async () => {
    analyticsService.setEmailConsent('opted_in');
    await handleSubmit(true);
  };

  const onUserDisagree = async () => {
    analyticsService.setEmailConsent('refused');
    await handleSubmit(false);
  };

  return (
    <EmailConfirmPalmistryScreen
      onUserAgree={onUserAgree}
      onUserDisagree={onUserDisagree}
      onBack={back}
      {...(screenAttributes as EmailConfirmPalmistryScreenAttributes)}
    />
  );
};

export default EmailConfirmPalmistry;
