import styled from 'styled-components';
import Accordion, { AccordionDetails, AccordionSummary } from 'core/common/components/Accordion';

export const WrapperStyled = styled.section`
  width: 100%;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  text-align: center;
`;

export const AccordionWrapperStyled = styled.div`
  padding: 28px 16px;
  border-radius: 20px;
  background-color: #fafafa;
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
  background-color: transparent !important;
  box-shadow: none !important;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &::before {
    display: none;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100% !important;
  min-height: unset !important;
  padding: 0 !important;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  background-color: none;

  & .MuiAccordionSummary-expandIconWrapper {
    margin-top: 4px !important;
    margin-left: auto;
  }

  & .MuiAccordionSummary-content {
    max-width: 85%;
    margin: 0 !important;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0 !important;
  font-size: 14px;
  text-align: left;
`;
