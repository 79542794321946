import LinearProgress from '@mui/material/LinearProgress';
import { animated } from 'react-spring';
import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  max-width: 328px;
  margin-top: 36px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 135%;
`;

export const Subtitle = styled.h4`
  margin-top: 4px;
  margin-bottom: 22px;
  font-size: 18px;
  line-height: 135%;
  color: ${(props) => props.theme.colors.info};
`;

export const ImageContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  width: 100%;
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  margin-top: 12px;
`;

export const Image = styled(ImageComponent)``;

export const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
  max-width: 244px;
  height: 10px;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.themeBackgroundLight};

  & .MuiLinearProgress-bar {
    border-radius: 12px;
    background: ${(props) => props.theme.colors.primaryLeo};
  }
`;

export const LineOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const StyledSvg = styled.svg`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CircleSvg = styled(animated.svg)`
  width: 20px;
  height: 20px;
`;

export const InnerCircle = styled(animated.circle)`
  fill: ${(props) => props.theme.colors.infoLight};
`;

export const OuterCircle = styled(animated.circle)`
  fill: ${(props) => props.theme.colors.typography100};
  opacity: 0.3;
`;

export const StyledPath = styled(animated.path)<{ delay: number; color: string }>`
  fill: none;
  stroke: ${(props) => props.color};
  stroke-width: 4;
  stroke-opacity: 0.8;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: drawLineAnimation 3s linear forwards;
  animation-delay: ${(props) => `${props.delay * 3000}ms`};

  @keyframes drawLineAnimation {
    0% {
      stroke-dashoffset: 400;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
`;
