import { InnerWrapper, Progress, Ring, Wrapper } from './styled';

export type ProcessingLoaderCircleProps = {
  value: number;
  className?: string;
};

const ProcessingLoaderCircle = ({ value, className }: ProcessingLoaderCircleProps) => {
  return (
    <Wrapper className={className} viewBox="0 0 38 38">
      <InnerWrapper cx="50%" cy="50%" r="15.91549430918954" />
      <Progress cx="50%" cy="50%" r="15.91549430918954" fill="transparent" strokeWidth="2" />
      <Ring
        cx="50%"
        cy="50%"
        r="15.91549430918954"
        fill="transparent"
        opacity="1"
        strokeWidth="2"
        strokeDasharray={`${100 - value} ${value}`}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="45"
          y2="-9.5"
          x2="53.1842"
          y1="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A3AA2" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </Wrapper>
  );
};

export default ProcessingLoaderCircle;
