import { useEffect, useState } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellConsultationScreen from 'core/common/components/QuizScreens/UpsellConsultation';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellConsultationScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellConsultationSingleProps = {
  id: string;
};

const UpsellConsultationSingle = ({ id }: UpsellConsultationSingleProps) => {
  const { next, extraNext, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { consultationUpsellOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const { createAccount } = useCreateAskNebulaAccount();

  const [displayDiscountModal, setDisplayDiscountModal] = useState(false);

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getOffer = () => {
    if (!consultationUpsellOffer) return;

    analyticsService.consultationUpsellScreenClicked();
    recurringPaymentRequest(consultationUpsellOffer.offerId);
  };

  const goNext = () => {
    createAccount();
    next();
  };

  const skipOffer = () => {
    setDisplayDiscountModal(true);
  };

  const onGetSecretDiscount = () => {
    extraNext();
    createAccount();
  };

  const onCloseDiscountModal = () => {
    setDisplayDiscountModal(false);
    goNext();
  };

  useEffect(() => {
    analyticsService.consultationUpsellScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.consultationUpsellGetOffer(
        consultationUpsellOffer ? consultationUpsellOffer.offerId : '',
      );
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  const offerPrice = consultationUpsellOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '';
  const offerTrialPrice = consultationUpsellOffer?.pricesWithCurrencySymbol.trialPriceWithVAT ?? '';

  return (
    <UpsellConsultationScreen
      price={offerPrice}
      isLoading={isPurchaseLoading}
      trialPrice={offerTrialPrice}
      plan={consultationUpsellOffer}
      resignFormLoaded={isResignFormLoaded}
      displayDiscountModal={displayDiscountModal}
      onGetOffer={getOffer}
      onSkipOffer={skipOffer}
      onClearPaymentStatus={clearState}
      onGetSecretDiscount={onGetSecretDiscount}
      onCloseDiscountModal={onCloseDiscountModal}
      paymentError={error}
      {...(screenAttributes as UpsellConsultationScreenAttributes)}
    />
  );
};

export default UpsellConsultationSingle;
