import styled from 'styled-components';
import { ButtonWrapper } from 'core/common/components/AnswerButton';

export const RadioButtonWrapper = styled(ButtonWrapper)<{ isChosen: boolean }>`
  max-width: 140px;
  height: 48px;
  padding-right: 0;
  padding-left: 0;
  color: ${(props) => props.isChosen && props.theme.colors.typography100};
  background: ${(props) => props.isChosen && props.theme.colors.infoLight};
`;

export const Text = styled.span`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;

  &::first-letter {
    text-transform: uppercase;
  }
`;
