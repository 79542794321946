import { useMemo } from 'react';
import { getAppRatingPercents } from 'core/common/utils/getAppRatingPercents';
import { SectionRatingAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  RatingStyled,
  ListStyled,
  ListItemStyled,
  SupTextStyled,
  SubTextStyled,
  ImageStyled,
} from './styled';

type SectionRatingProps = {
  content: SectionRatingAttributes['content'];
  className?: string;
};

const SectionRating = ({ content, className }: SectionRatingProps) => {
  const { rating, list } = content;

  const ratingPercents = useMemo(() => getAppRatingPercents(rating), [rating]);

  return (
    <WrapperStyled className={className}>
      <RatingStyled percent={ratingPercents} />
      <ListStyled>
        {list.map(({ supText, subText, image }) => (
          <ListItemStyled key={subText}>
            {supText && <SupTextStyled>{supText}</SupTextStyled>}
            {image && <ImageStyled src={image.src} alt={image.alt} width={16} height={18} />}
            <SubTextStyled>{subText}</SubTextStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionRating;
