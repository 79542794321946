import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import TrialPaymentWithDiscountPalmistryScreen from 'core/common/components/QuizScreens/TrialPaymentWithDiscountPalmistryV2';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialPaymentWithDiscountPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { getEmail } from 'core/user/store';

type TrialPaymentWithDiscountPalmistryV2Props = {
  id: string;
};

const TrialPaymentWithDiscountPalmistryV2 = ({ id }: TrialPaymentWithDiscountPalmistryV2Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountOffer, timeUpOffer, selectedOffer } = useOffers();

  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  const attributes = screenAttributes as TrialPaymentWithDiscountPalmistryV2ScreenAttributes;

  useEffect(() => {
    if (!discountOffer) return;

    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
      trialPrice: discountOffer.prices.trialPrice,
    });
  }, [analyticsService, discountOffer, email, orderId]);

  const getReport = useCallback(() => {
    if (!discountOffer) return;

    analyticsService.initiateCheckout({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
      trialPrice: discountOffer.prices.trialPrice,
    });

    analyticsService.landingPageClick('trial_billing', discountOffer.offerId);
  }, [analyticsService, discountOffer, email, orderId]);

  const onGetReport = () => {
    getReport();
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <TrialPaymentWithDiscountPalmistryScreen
      timeUpOffer={timeUpOffer}
      selectedOffer={selectedOffer}
      discountOffer={discountOffer}
      isModalOpen={isModalOpen}
      onGetReport={onGetReport}
      paymentSucceed={next}
      onModalClose={onModalClose}
      {...attributes}
    />
  );
};

export default TrialPaymentWithDiscountPalmistryV2;
