export const pulseLoader = {
  nm: 'loading_animation',
  ddd: 0,
  h: 800,
  w: 800,
  meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
  layers: [
    {
      ty: 4,
      nm: 'Shape Layer 5',
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [20], t: 23 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 34 },
            { s: [20], t: 69 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 4,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [400, 400], t: 23 },
                  { o: { x: 0.333, y: 0 }, i: { x: 0.009, y: 1 }, s: [440, 440], t: 34 },
                  { s: [400, 400], t: 59 },
                ],
                ix: 2,
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gs',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - G-Stroke',
          nm: 'Gradient Stroke 1',
          e: { a: 0, k: [100, 0], ix: 5 },
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0, 0.29411764705882354, 0.30980392156862746, 0.6196078431372549, 0.5,
                0.4980392156862745, 0.3254901960784314, 0.9529411764705882, 1, 0.8470588235294118,
                0.2549019607843137, 0.5333333333333333,
              ],
              ix: 8,
            },
          },
          t: 1,
          a: { a: 0, k: 0 },
          h: { a: 0, k: 0 },
          s: { a: 0, k: [0, 0], ix: 4 },
          lc: 1,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100, ix: 9 },
          w: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [5], t: 23 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10], t: 34 },
              { s: [5], t: 59 },
            ],
            ix: 10,
          },
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [20], t: 16 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 27 },
            { s: [20], t: 62 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [320, 320], t: 16 },
                  { o: { x: 0.333, y: 0 }, i: { x: 0.025, y: 1 }, s: [360, 360], t: 27 },
                  { s: [320, 320], t: 52 },
                ],
                ix: 2,
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gs',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - G-Stroke',
          nm: 'Gradient Stroke 1',
          e: { a: 0, k: [100, 0], ix: 5 },
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0, 0.29411764705882354, 0.30980392156862746, 0.6196078431372549, 0.5,
                0.4980392156862745, 0.3254901960784314, 0.9529411764705882, 1, 0.8470588235294118,
                0.2549019607843137, 0.5333333333333333,
              ],
              ix: 8,
            },
          },
          t: 1,
          a: { a: 0, k: 0 },
          h: { a: 0, k: 0 },
          s: { a: 0, k: [0, 0], ix: 4 },
          lc: 1,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100, ix: 9 },
          w: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [5], t: 16 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10], t: 27 },
              { s: [5], t: 52 },
            ],
            ix: 10,
          },
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [20], t: 9 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 20 },
            { s: [20], t: 55 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [240, 240], t: 9 },
                  { o: { x: 0.333, y: 0 }, i: { x: 0.051, y: 1 }, s: [280, 280], t: 20 },
                  { s: [240, 240], t: 45 },
                ],
                ix: 2,
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gs',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - G-Stroke',
          nm: 'Gradient Stroke 1',
          e: { a: 0, k: [100, 0], ix: 5 },
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0, 0.29411764705882354, 0.30980392156862746, 0.6196078431372549, 0.5,
                0.4980392156862745, 0.3254901960784314, 0.9529411764705882, 1, 0.8470588235294118,
                0.2549019607843137, 0.5333333333333333,
              ],
              ix: 8,
            },
          },
          t: 1,
          a: { a: 0, k: 0 },
          h: { a: 0, k: 0 },
          s: { a: 0, k: [0, 0], ix: 4 },
          lc: 1,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100, ix: 9 },
          w: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [5], t: 9 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10], t: 20 },
              { s: [5], t: 45 },
            ],
            ix: 10,
          },
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [20], t: 2 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 13 },
            { s: [20], t: 48 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [160, 160], t: 2 },
                  { o: { x: 0.333, y: 0 }, i: { x: 0.034, y: 1 }, s: [200, 200], t: 13 },
                  { s: [160, 160], t: 38 },
                ],
                ix: 2,
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gs',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - G-Stroke',
          nm: 'Gradient Stroke 1',
          e: { a: 0, k: [100, 0], ix: 5 },
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0, 0.29411764705882354, 0.30980392156862746, 0.6196078431372549, 0.5,
                0.4980392156862745, 0.3254901960784314, 0.9529411764705882, 1, 0.8470588235294118,
                0.2549019607843137, 0.5333333333333333,
              ],
              ix: 8,
            },
          },
          t: 1,
          a: { a: 0, k: 0 },
          h: { a: 0, k: 0 },
          s: { a: 0, k: [0, 0], ix: 4 },
          lc: 1,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100, ix: 9 },
          w: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [5], t: 2 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10], t: 13 },
              { s: [5], t: 38 },
            ],
            ix: 10,
          },
        },
      ],
      ind: 4,
    },
    {
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      st: 0,
      op: 120,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [20], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [100], t: 11 },
            { s: [20], t: 46 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [80, 80], t: 0 },
                  { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [120, 120], t: 11 },
                  { s: [80, 80], t: 36 },
                ],
                ix: 2,
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gs',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - G-Stroke',
          nm: 'Gradient Stroke 1',
          e: { a: 0, k: [100, 0], ix: 5 },
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0, 0.29411764705882354, 0.30980392156862746, 0.6196078431372549, 0.5,
                0.4980392156862745, 0.3254901960784314, 0.9529411764705882, 1, 0.8470588235294118,
                0.2549019607843137, 0.5333333333333333,
              ],
              ix: 8,
            },
          },
          t: 1,
          a: { a: 0, k: 0 },
          h: { a: 0, k: 0 },
          s: { a: 0, k: [0, 0], ix: 4 },
          lc: 1,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100, ix: 9 },
          w: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [5], t: 0 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10], t: 11 },
              { s: [5], t: 35 },
            ],
            ix: 10,
          },
        },
      ],
      ind: 5,
    },
  ],
  v: '4.10.1',
  fr: 60,
  op: 120,
  ip: 0,
  assets: [],
};
