import { useCallback, useState } from 'react';
import { PickerVariants, WheelTimePicker } from 'core/common/components/WheelTimePicker';
import { normalizeTime, extractTimeParts } from 'core/common/utils/time';
import { PickerWrapperStyled, ButtonStyled, BlockWrapperStyled } from './styled';

type ScrollPickerProps = {
  nextLabel: string;
  userTime?: string;
  onSubmit: (time: string) => void;
  variant?: PickerVariants;
};

const ScrollPicker = ({
  nextLabel,
  onSubmit,
  userTime,
  variant = PickerVariants.DEFAULT,
}: ScrollPickerProps) => {
  const [time, setTime] = useState(extractTimeParts(userTime));

  const onClick = () => {
    onSubmit(normalizeTime(time, 'HH:mm:ss'));
  };

  const onChange = useCallback((value: Record<string, string | number>) => {
    setTime((prev) => ({ ...prev, ...value }));
  }, []);

  return (
    <BlockWrapperStyled>
      <PickerWrapperStyled>
        <WheelTimePicker variant={variant} onChange={onChange} time={time} />
        <ButtonStyled onClick={onClick}>{nextLabel}</ButtonStyled>
      </PickerWrapperStyled>
    </BlockWrapperStyled>
  );
};

export default ScrollPicker;
