import styled from 'styled-components';

export const TypographyH1Styled = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const TypographyH2Styled = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const TypographyH3Styled = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 22px;
  }
`;

export const TypographyH4Styled = styled.h4`
  font-size: 16px;
  font-weight: bold;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const TypographyLargeStyled = styled.p`
  font-size: 16px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const TypographyLargeBoldStyled = styled(TypographyLargeStyled)`
  font-weight: 600;
`;

export const TypographyMediumStyled = styled.p`
  font-size: 14px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const TypographyMediumBoldStyled = styled(TypographyMediumStyled)`
  font-weight: 600;
`;

export const TypographySmallStyled = styled.p`
  font-size: 12px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const TypographySmallBoldStyled = styled(TypographySmallStyled)`
  font-weight: 600;
`;

export const TypographyExtraSmallStyled = styled.p`
  font-size: 10px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const TypographyExtraSmallBoldStyled = styled(TypographyExtraSmallStyled)`
  font-weight: 600;
`;
