import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import { Image } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { NameScreenProps } from 'core/funnel/entities';
import { Container, Content, InputBlock, StyledButton, StyledText, StyledTitle } from './styled';

const NameScreen = ({
  content,
  initialName,
  totalSteps,
  onSubmit,
  onBack,
  step,
}: NameScreenProps) => {
  const [name, setName] = useState(initialName);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleButtonClick = () => {
    onSubmit(name);
  };

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} onBack={onBack} />
      <Container>
        <Content>
          {!!content.image && (
            <Image
              src={content.image.src}
              alt={content.image.alt || 'name'}
              width={203}
              height={203}
            />
          )}
          <StyledTitle>{content.title}</StyledTitle>
          {content.text && <StyledText>{content.text}</StyledText>}

          <InputBlock>
            <Input
              placeholder={content.placeholder ?? 'Enter your full name'}
              onChange={handleInputChange}
              value={name}
            />
          </InputBlock>
          <StyledButton onClick={handleButtonClick} disabled={!!name && !name.length}>
            {content.next}
          </StyledButton>
        </Content>
      </Container>
    </>
  );
};

export default NameScreen;
