import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import Icon from 'core/svg-sprites/ui/Icon';
import {
  Container,
  Heading,
  List,
  ListDescription,
  ListItem,
  ListTitle,
  Rating,
  StarStyled,
  Title,
} from './styled';

type WitchPowerHeaderInfoProps = {
  title: string;
  rating: number;
  guideList: Array<{
    image: FunnelImage;
    title: string;
    description: string;
  }>;
};

const WitchPowerHeaderInfo = ({ title, rating, guideList }: WitchPowerHeaderInfoProps) => {
  return (
    <Container>
      <Heading>
        <Icon name="nebula" width={80} height={12} />
        <Rating>
          <StarStyled name="star-five-points" />
          {rating}
        </Rating>
      </Heading>
      <Title dangerouslySetInnerHTML={prepareHtml(title)} />
      <List>
        {guideList.map((item) => (
          <ListItem key={item.title}>
            <Image src={item.image.src} alt={item.image.alt} width={32} height={32} priority />
            <ListTitle>{item.title}</ListTitle>
            <ListDescription>{item.description}</ListDescription>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default WitchPowerHeaderInfo;
