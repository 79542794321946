import { useEffect, useState } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellsNatalChartScreen from 'core/common/components/QuizScreens/UpsellsNatalChart';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellsNatalChartScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { getCalculatedUpsell } from 'core/offers/mappers';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellsNatalChartProps = {
  id: string;
};

const UpsellsNatalChart = ({ id }: UpsellsNatalChartProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  const attributes = screenAttributes as UpsellsNatalChartScreenAttributes;
  const { plans } = attributes.content;

  const [selectedProduct, setSelectedProduct] = useState('');

  const { createAccount } = useCreateAskNebulaAccount();

  const { analyticsService } = useServices();

  const {
    palmistryUpsellsOffer,
    numerologyUpsellsOffer,
    tarotUpsellsOffer,
    ultraPackOffer,
    selectedOffer,
    cheapestOffer,
  } = useOffers();

  const isCheapestOfferBought =
    cheapestOffer.prices.trialPriceWithVAT === selectedOffer?.prices.trialPriceWithVAT;

  // TODO think how to move to mappers
  const numerologyAnalysis = getCalculatedUpsell(plans.numerologyAnalysis, numerologyUpsellsOffer);
  const tarotReading = getCalculatedUpsell(plans.tarotReading, tarotUpsellsOffer);
  const palmistryGuide = getCalculatedUpsell(plans.palmistryGuide, palmistryUpsellsOffer);
  const ultraPack = getCalculatedUpsell(plans.ultraPack, ultraPackOffer);

  const tarotFirstUpsells = [tarotReading, numerologyAnalysis, palmistryGuide, ultraPack];
  const ultraPackFirstUpsells = [ultraPack, tarotReading, numerologyAnalysis, palmistryGuide];
  const upsells = isCheapestOfferBought ? tarotFirstUpsells : ultraPackFirstUpsells;

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    recurringPaymentRequest(selectedProduct);
  };

  const goNext = () => {
    createAccount();
    next();
  };

  const skip = () => {
    analyticsService.upsellLpSkip();
    goNext();
  };

  const selectProduct = (product: string) => {
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (!upsells[0].offerId) return;

    setSelectedProduct(upsells[0].offerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsService.upsellLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellLpGetReport(selectedProduct);
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  return (
    <UpsellsNatalChartScreen
      onGetReport={getReport}
      onSkip={skip}
      isPurchaseLoading={isPurchaseLoading}
      selectedProduct={selectedProduct}
      selectProduct={selectProduct}
      upsells={upsells}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...attributes}
    />
  );
};

export default UpsellsNatalChart;
