import { useRef } from 'react';
import { useInView } from 'core/common/hooks';

export const useObservers = () => {
  const { ref: firstOfferRef, inView: isFirstOfferInView } = useInView({
    threshold: 0.2,
  });
  const { ref: secondOfferRef, inView: isSecondOfferInView } = useInView({
    threshold: 0.2,
  });
  const { ref: deckSliderRef, inView: isDeckSliderInView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const { ref: reviewCarouselRef, inView: isReviewsCarouselInView } = useInView({
    triggerOnce: true,
  });
  const { ref: topPageRef, inView: isTopPageInView } = useInView({ initialInView: true });

  const originalFirstOfferRef = useRef<HTMLDivElement | null>(null);
  const originalSecondOfferRef = useRef<HTMLDivElement | null>(null);

  const setOffersRef = (node: HTMLDivElement) => {
    if (!node) return;

    if (!originalFirstOfferRef.current) {
      originalFirstOfferRef.current = node;
      firstOfferRef(node);

      return;
    }

    originalSecondOfferRef.current = node;
    secondOfferRef(node);
  };

  return {
    setOffersRef,
    firstOfferRef: {
      inView: isFirstOfferInView,
    },
    secondOfferRef: {
      inView: isSecondOfferInView,
    },
    deckSliderRef: {
      intersection: deckSliderRef,
      inView: isDeckSliderInView,
    },
    reviewCarouselRef: {
      intersection: reviewCarouselRef,
      inView: isReviewsCarouselInView,
    },
    topPageRef: {
      intersection: topPageRef,
      inView: isTopPageInView,
    },
  };
};
