import { OnboardingAskV2ScreenProps } from 'core/funnel/entities';
import {
  DescriptionStyled,
  ImageStyled,
  MessageStyled,
  MessageWrapperStyled,
  TitleStyled,
} from './styled';

type CreditsProps = OnboardingAskV2ScreenProps['content']['credits'];

const Credits = ({ title, description, image, message }: CreditsProps) => {
  return (
    <>
      <ImageStyled src={image.src} width={150} height={150} alt={image.alt} />
      <MessageWrapperStyled>
        <MessageStyled src={message.src} alt={message.alt} width={343} height={286} />
        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
      </MessageWrapperStyled>
    </>
  );
};

export default Credits;
