import styled from 'styled-components';
import { QuizButton } from 'core/common/components/Quiz/styled';
import Title from 'core/common/components/Title';

export const Background = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    99.42deg,
    rgba(255, 161, 0, 0.3) 36.23%,
    rgba(250, 124, 18, 0.2401) 54.33%,
    rgba(245, 87, 36, 0.27) 75.61%,
    rgba(242, 61, 48, 0.27) 95.48%,
    rgba(240, 45, 56, 0.27) 113.18%,
    rgba(239, 39, 59, 0.27) 126.89%
  );
`;

export const Text = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const StyledText = styled.p`
  max-width: 300px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  margin-top: 24px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;
export const Button = styled(QuizButton)`
  height: 49px;
  margin-top: 50px;
  border-radius: 12px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;

export const Skip = styled.button`
  z-index: 1;
  line-height: 150%;
  color: ${(props) => props.theme.colors.typography900};
  cursor: pointer;
`;
