import styled from 'styled-components';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import { QuizButton } from 'core/common/components/Quiz/styled';
import { TitleText } from 'core/common/components/Title';

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: flex-start;
  }
`;

export const Title = styled(TitleText)`
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
  font-weight: 700;
  line-height: 150%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
    font-size: 24px;
  }
`;

export const Subtitle = styled.h4`
  width: 100%;
  font-weight: 400;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography800};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

export const Text = styled.p`
  margin-top: 25px;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Policy = styled(PolicyComponent)`
  max-width: 240px;

  ${NavLink} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.infoLight};
  }
`;

export const Button = styled(QuizButton)`
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 48px;
  border-radius: 16px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
  }
`;
