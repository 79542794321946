import styled from 'styled-components';
import Reviews, { Item } from 'core/common/components/Reviews';

export const WrapperStyled = styled.div`
  margin-bottom: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
    padding: 32px 15px;
    border-radius: 20px;
    background: #9974f60d;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 29px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const ReviewsStyled = styled(Reviews)`
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;

  ${Item} {
    background: ${({ theme }) => theme.colors.themeBackgroundLight};
  }
`;
