import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionAdvantagesAttributes } from 'core/funnel/entities';
import { AdvantagesStyled, DescriptionStyled, TitleStyled } from './styled';

type SectionAdvantagesProps = {
  content: SectionAdvantagesAttributes['content'];
};

const SectionAdvantages = ({ content }: SectionAdvantagesProps) => {
  return (
    <>
      <AdvantagesStyled advantages={content.advantages} />
      <TitleStyled>{content.title}</TitleStyled>
      <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(content.description)} />
    </>
  );
};

export default SectionAdvantages;
