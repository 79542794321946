import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import InputComponent from 'core/common/components/Input';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import { TitleText } from 'core/common/components/Title';
import EmailSuggestionsList from '../../EmailSuggestionsList';

export const ContainerStyled = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const TitleStyled = styled(TitleText)`
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 135%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

export const ContentStyled = styled.div`
  position: relative;
  flex-grow: 1;
  transition: all 0.1s ease 0.3s;
`;

export const PolicyStyled = styled(PolicyComponent)`
  max-width: 214px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.typography600};
  transition: all 0.1s ease 0.3s;

  ${NavLink} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.infoLight};
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  &:focus-within {
    ${ContentStyled} {
      flex-grow: 0;
    }
    ${PolicyStyled} {
      opacity: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    &:focus-within {
      ${ContentStyled} {
        flex-grow: 1;
      }
      ${PolicyStyled} {
        opacity: 1;
      }
    }
  }
`;

export const TextStyled = styled.p`
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const ButtonStyled = styled(Button)`
  z-index: auto;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 #00000040;
`;

export const InputStyled = styled(InputComponent)`
  border: 1px solid ${({ theme }) => theme.colors.typography600};
`;

export const EmailSuggestionsListStyled = styled(EmailSuggestionsList)`
  z-index: 3;
  margin-top: 0;
`;
