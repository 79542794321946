import { forwardRef } from 'react';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { Offer } from 'core/offers/entities';
import { notPaidUtmSources, paidUtmSources } from 'core/user/entities';
import {
  ActionButton,
  BodyHeader,
  BodyHeaderPrice,
  BodyHeaderTitle,
  ButtonWrapper,
  CardBody,
  CardHeader,
  CardWrapper,
  GuaranteeBlock,
  GuaranteeIconStyled,
  GuaranteeTitle,
  HeaderTitle,
  HorizontalLine,
  SubscriptionActualPrice,
  SubscriptionBlock,
  SubscriptionDescription,
  SubscriptionOldPrice,
  SubscriptionPricesBlock,
  TitleStyled,
  TotalBlock,
  TotalPrice,
  TotalTitle,
} from './styled';

export type SpecialOfferProps = {
  id?: string;
  timeLeft: number;
  onGetReport: () => void;
  offer: Offer;
  timeUpOffer: Offer;
  specialOffer: {
    mainTitle: string;
    header: string;
    title: string;
    totalText: string;
    costPerMonthText: string;
    costPerMonthTextPaidTraffic: string;
    button: string;
    guaranteeText: string;
  };
};

const SpecialOffer = forwardRef<HTMLDivElement, SpecialOfferProps>(
  ({ id, timeLeft, onGetReport, offer, timeUpOffer, specialOffer }: SpecialOfferProps, ref) => {
    const {
      mainTitle,
      header,
      title,
      totalText,
      costPerMonthText,
      costPerMonthTextPaidTraffic,
      button,
      guaranteeText,
    } = specialOffer;

    const isTimeUp = timeLeft <= 0;
    const offerZeroPrice = 0;

    return (
      <>
        <TitleStyled>{mainTitle}</TitleStyled>
        <CardWrapper id={id}>
          <CardHeader>
            <HeaderTitle>{header}</HeaderTitle>
          </CardHeader>
          <CardBody>
            <BodyHeader>
              <BodyHeaderTitle>{title}</BodyHeaderTitle>
              <BodyHeaderPrice>
                {isTimeUp
                  ? timeUpOffer.pricesWithCurrencySymbol.trialPriceWithVAT
                  : offer.pricesWithCurrencySymbol.trialPriceWithVAT}
              </BodyHeaderPrice>
            </BodyHeader>
            <TotalBlock>
              <TotalTitle>{totalText}</TotalTitle>
              <TotalPrice>
                {isTimeUp
                  ? timeUpOffer.pricesWithCurrencySymbol.trialPriceWithVAT
                  : offer.pricesWithCurrencySymbol.trialPriceWithVAT}
              </TotalPrice>
            </TotalBlock>
            <HorizontalLine />
            <SubscriptionBlock>
              <MediaSourceVariant name={paidUtmSources}>
                <SubscriptionDescription>{costPerMonthTextPaidTraffic}</SubscriptionDescription>
                <SubscriptionPricesBlock>
                  <SubscriptionOldPrice>
                    {isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT}
                  </SubscriptionOldPrice>
                  <SubscriptionActualPrice>
                    {offer.currencySymbol}
                    {offerZeroPrice}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
              <MediaSourceVariant name={notPaidUtmSources}>
                <SubscriptionDescription dangerouslySetInnerHTML={prepareHtml(costPerMonthText)} />
                <SubscriptionPricesBlock>
                  <SubscriptionActualPrice>
                    {isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
            </SubscriptionBlock>
          </CardBody>
        </CardWrapper>
        <ButtonWrapper ref={ref}>
          <ActionButton impulseScale onClick={onGetReport}>
            {button}
          </ActionButton>
        </ButtonWrapper>
        <GuaranteeBlock>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeTitle>{guaranteeText}</GuaranteeTitle>
        </GuaranteeBlock>
      </>
    );
  },
);

export default SpecialOffer;
