import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import QuizBackground from 'core/common/components/QuizBackground';
import { NameMoonCompatibilityScreenProps } from 'core/funnel/entities';
import {
  ButtonsWrapperStyled,
  InputWrapperStyled,
  ButtonStyled,
  ContentStyled,
  FormStyled,
  InputStyled,
  SkipButtonStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

const NameMoonCompatibilityScreen = ({
  content,
  progressBarTitle,
  progressBarName,
  totalProfileSteps,
  totalPersonalitySteps,
  totalRelationshipSteps,
  initialUserName,
  onSubmit,
  onBack,
  onSkip,
  step,
}: NameMoonCompatibilityScreenProps) => {
  const { title, subtitle, placeholder, next, skipButton, backgroundImage } = content;

  const [userName, setUserName] = useState(initialUserName);

  // To update "userName" on client side when "initialUserName" from Redux will exist
  useEffect(() => {
    setUserName(initialUserName);
  }, [initialUserName]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(userName);
  };

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header onBack={onBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <ContentStyled>
        <TitleStyled>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <FormStyled onSubmit={handleSubmit}>
          <InputWrapperStyled>
            <InputStyled placeholder={placeholder} onChange={handleInputChange} value={userName} />
          </InputWrapperStyled>
          <ButtonsWrapperStyled>
            <ButtonStyled type="submit" disabled={!userName.trim()}>
              {next}
            </ButtonStyled>
            {skipButton && <SkipButtonStyled onClick={onSkip}>{skipButton}</SkipButtonStyled>}
          </ButtonsWrapperStyled>
        </FormStyled>
      </ContentStyled>
    </>
  );
};
export default NameMoonCompatibilityScreen;
