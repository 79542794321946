import { FunnelImage } from 'core/funnel/entities';
import Option from './Option';
import {
  CardStyled,
  CardHeaderStyled,
  CardTitleStyled,
  CardDescriptionStyled,
  CardImageStyled,
  CardOptionsStyled,
} from './styled';

type CardProps = {
  idx: number;
  title: string;
  description: string;
  image?: FunnelImage;
  progressBarOptions?: Array<{
    icon: string;
    label: string;
    blurred?: boolean;
    percantageRange: {
      min: number;
      max: number;
    };
  }>;
};

const Card = ({ idx, image, progressBarOptions, title, description }: CardProps) => {
  return (
    <CardStyled idx={idx}>
      <CardHeaderStyled>
        {image && (
          <CardImageStyled
            src={image.src}
            alt={image.alt}
            width={!!progressBarOptions ? 77 : 239}
            height={!!progressBarOptions ? 106 : 208}
          />
        )}
        {progressBarOptions && (
          <CardOptionsStyled>
            {progressBarOptions.map(({ icon, label, blurred, percantageRange }) => (
              <Option
                key={label}
                icon={icon}
                label={label}
                blurred={blurred}
                percantageRange={percantageRange}
              />
            ))}
          </CardOptionsStyled>
        )}
      </CardHeaderStyled>
      <CardTitleStyled>{title}</CardTitleStyled>
      <CardDescriptionStyled>{description}</CardDescriptionStyled>
    </CardStyled>
  );
};

export default Card;
