import { useEffect, useState } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellsV3Screen from 'core/common/components/QuizScreens/UpsellsV3';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import {
  SectionType,
  SectionUpsellOffersAttributes,
  UpsellsV3ScreenAttributes,
} from 'core/funnel/entities';
import { UpsellV2 } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { getCalculatedUpsellV2 } from 'core/offers/mappers';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellsV3Props = {
  id: string;
};

const UpsellsV3 = ({ id }: UpsellsV3Props) => {
  const [selectedOfferId, setSelectedOfferId] = useState('');

  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);
  const {
    palmistryUpsellsOffer,
    numerologyUpsellsOffer,
    tarotUpsellsOffer,
    ultraPackOffer,
    selectedOffer,
    cheapestOffer,
  } = useOffers();
  const { createAccount } = useCreateAskNebulaAccount();
  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isCheapestOfferBought =
    cheapestOffer?.prices.trialPriceWithVAT === selectedOffer?.prices.trialPriceWithVAT;

  const { sections } = screenAttributes as UpsellsV3ScreenAttributes;

  const offersSection = sections.find(
    ({ sectionType }) => sectionType === SectionType.UPSELL_OFFERS,
  );

  let upsells: Array<UpsellV2> = [];

  if (offersSection) {
    const content = offersSection.content as SectionUpsellOffersAttributes['content'];

    const palmistryUpsell = getCalculatedUpsellV2(
      content.offers.palmistryGuide,
      palmistryUpsellsOffer,
    );
    const numerologyUpsell = getCalculatedUpsellV2(
      content.offers.numerologyAnalysis,
      numerologyUpsellsOffer,
    );
    const tarotReadingUpsell = getCalculatedUpsellV2(
      content.offers.tarotReading,
      tarotUpsellsOffer,
    );
    const ultraPackUpsell = getCalculatedUpsellV2(content.offers.ultraPack, ultraPackOffer);

    upsells = isCheapestOfferBought
      ? [tarotReadingUpsell, palmistryUpsell, numerologyUpsell, ultraPackUpsell]
      : [ultraPackUpsell, palmistryUpsell, numerologyUpsell, tarotReadingUpsell];
  }

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    recurringPaymentRequest(selectedOfferId);
  };

  const goNext = () => {
    next();
    createAccount();
  };

  const skip = async () => {
    analyticsService.upsellLpSkip();
    goNext();
  };

  const onOfferSelect = (product: string) => {
    setSelectedOfferId(product);
  };

  useEffect(() => {
    if (!upsells.length) {
      return;
    }

    setSelectedOfferId(upsells[0].offerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsells.length]);

  useEffect(() => {
    analyticsService.upsellLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellLpGetReport(selectedOfferId);
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  return (
    <UpsellsV3Screen
      upsells={upsells}
      selectedOfferId={selectedOfferId}
      resignFormLoaded={isResignFormLoaded}
      isPurchaseLoading={isPurchaseLoading}
      onSkip={skip}
      onGetReport={getReport}
      onOfferSelect={onOfferSelect}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellsV3ScreenAttributes)}
    />
  );
};

export default UpsellsV3;
