import { FunnelImage } from 'core/funnel/entities';
import {
  CardStyled,
  GoalLabelStyled,
  GoalLabelImageStyled,
  GoalStyled,
  DividerStyled,
  AnalysisTextStyled,
  LinearProgressStyled,
} from './styled';

type UserGoalCardProps = {
  labelImage: FunnelImage;
  goalLabel: string;
  goal: string;
  analysisText: string;
  progress: number;
};

const UserGoalCard = ({
  labelImage,
  goalLabel,
  goal,
  analysisText,
  progress,
}: UserGoalCardProps) => {
  return (
    <CardStyled>
      <GoalLabelStyled>
        <GoalLabelImageStyled
          src={labelImage.src}
          alt={labelImage.alt}
          width={20}
          height={20}
          priority
        />
        {goalLabel}
      </GoalLabelStyled>
      <GoalStyled>{goal}</GoalStyled>
      <DividerStyled />
      <AnalysisTextStyled>{analysisText}</AnalysisTextStyled>
      <LinearProgressStyled value={progress} withLabel />
    </CardStyled>
  );
};

export default UserGoalCard;
