import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const wrapperHorizontalPaddingPx = 16;

export const WrapperStyled = styled.section`
  position: relative;
  padding-right: ${wrapperHorizontalPaddingPx}px;
  padding-bottom: 28px;
  padding-left: ${wrapperHorizontalPaddingPx}px;
  background-color: #f5f5f5;
`;

export const AspectRatioStyled = styled.div`
  position: relative;
  margin-right: -${wrapperHorizontalPaddingPx}px;
  margin-bottom: 16px;
  margin-left: -${wrapperHorizontalPaddingPx}px;
  padding-top: 53%;
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
  object-position: top;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const RatingStyled = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const RatingIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 4px;
`;

export const RatingTextStyled = styled.span`
  font-size: 10px;
  font-weight: 600;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const FloatingPurchaseButtonStyled = styled(Button)`
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 3;
  width: calc(100% - 30px);
  max-width: 400px;
  height: 56px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
`;
