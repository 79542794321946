import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import { Select } from 'core/common/components/Select';

import Title from 'core/common/components/Title';

export const ContainerStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 328px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  min-width: auto;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 600px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 330px;
  height: 157px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
`;

export const TitleStyled = styled(Title)`
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 135%;
  text-align: left;
`;

export const ButtonStyled = styled(ButtonComponent)`
  height: 49px;
  margin: auto auto 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;

export const ImageMobileWrapperStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ImageDesktopWrapperStyled = styled.div`
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    position: absolute;
    right: 76px;
    bottom: 48px;
    display: block;
  }
`;
