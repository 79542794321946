import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { Select } from 'core/common/components/Select';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { createDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { DatePalmistryScreenProps } from 'core/funnel/entities';
import { Button, Container, Content, SelectContainer, StyledTitle } from './styled';

const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const yearsList = generateDates(1940, greatestBirthYear, 'decrement');

const DatePalmistryScreen = ({
  step,
  onBack,
  getInitialDate,
  onSubmit,
  content,
  totalSteps,
}: DatePalmistryScreenProps) => {
  const { day, month, year } = getInitialDate();
  const { title, next } = content;

  const [date, setDate] = useState({
    month: month ? shortMonthsListEn[month] : '',
    day: day ? String(day) : '',
    year: year ? String(year) : '',
  });

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const daysList = generateMonthDays(date.month, date.year);

  const handleClick = () => {
    const dateOfBirth = createDate(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid Date') {
      const newDateOfBirth = createDate(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      return onSubmit(newDateOfBirth);
    }
    return onSubmit(dateOfBirth);
  };

  const isNextButtonDisabled = !date.day || !date.year || !date.month;

  return (
    <>
      <Header buttonBack onBack={onBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <Container>
        <Content>
          <StyledTitle>{title}</StyledTitle>
          <SelectContainer>
            <Select
              options={monthsList}
              name="month"
              value={date.month}
              onChange={handleDateChange}
              placeholder="Month"
            />
            <Select
              options={daysList}
              name="day"
              value={date.day}
              onChange={handleDateChange}
              placeholder="Day"
            />
            <Select
              options={yearsList}
              name="year"
              value={date.year}
              onChange={handleDateChange}
              placeholder="Year"
            />
          </SelectContainer>
          <Button onClick={handleClick} disabled={isNextButtonDisabled}>
            {next}
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default DatePalmistryScreen;
