import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentPalmistryScreen from 'core/common/components/QuizScreens/TrialPaymentPalmistry';
import { BASE_ROUTES, LANDINGS } from 'core/common/constants';
import { LoadingStatuses } from 'core/common/entities';
import { useCountdown, useDispatch, useRouter, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';
import { useGetActualPaymentPlans, useProduct } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';
import { CurrencySymbol, UserProfile } from 'core/user/entities';
import { getEmail, getPlan, updateUserInformation } from 'core/user/store';
import { getZodiacPersonImage } from 'core/zodiac/utils/getZodiacPersonImage';

type TrialPaymentPalmistryProps = {
  id: string;
};

const TrialPaymentPalmistry = ({ id }: TrialPaymentPalmistryProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);

  const { specialPlanCountdown } = useCountdown({ key: FunnelName.PALMISTRY });
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const { navigate } = useRouter();
  const dispatch = useDispatch();

  const { analyticsService } = useServices();
  const { timeUpPlans, currency } = useGetActualPaymentPlans();

  const plan = useSelector(getPlan);
  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  const isTimeUp = !+specialPlanCountdown;

  const getTimeUpProductPlan = () => {
    const foundedPlan = timeUpPlans.find((item) => item.displayPrice === plan.displayPrice);

    return foundedPlan || plan;
  };

  const timeUpPlan = getTimeUpProductPlan() as UserProfile['plan'];

  const productPlan = isTimeUp ? timeUpPlan : plan;

  const currencySymbol = productPlan.displayPrice.split('')[0] as CurrencySymbol;

  const { price, loadingStatus, priceWithoutVAT, vat, product } = useProduct(productPlan.productId);

  const isPriceLoaded = loadingStatus === LoadingStatuses.FULFILLED;

  const discountPlan = plan?.price ?? 19;
  const fullPlan = timeUpPlan?.price ?? 29;
  const discountAmount = fullPlan - discountPlan;

  const zodiacImage = getZodiacPersonImage(userPreferences.zodiac, userPreferences.gender);

  const defineTrialPrice = (fullPrice: number) => {
    if (product?.trialPrice === 0 && !isTimeUp) return product.trialPrice;

    return fullPrice;
  };

  const trialPrice = defineTrialPrice(price);

  useEffect(() => {
    if (productPlan && isPriceLoaded) {
      analyticsService.landingPageOpened('trial_billing');
      analyticsService.addToCart({
        price,
        productId: productPlan.productId,
        email,
        orderId,
        currency,
        trialPrice,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, productPlan, price, isPriceLoaded, email, orderId, trialPrice]);

  const getReport = useCallback(() => {
    analyticsService.initiateCheckout({
      price,
      productId: productPlan.productId,
      email,
      orderId,
      currency,
      trialPrice,
    });
    analyticsService.landingPageClick('trial_billing', productPlan.productId);
    if (plan !== productPlan) {
      dispatch(updateUserInformation('plan', productPlan));
    }
  }, [analyticsService, dispatch, plan, price, productPlan, email, orderId, currency, trialPrice]);

  const onPaymentDialogClose = () => {
    navigate(joinUrls(BASE_ROUTES.PALMISTRY, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  return (
    <TrialPaymentPalmistryScreen
      productPlan={productPlan}
      email={userPreferences.email}
      zodiacImage={zodiacImage}
      timeLeft={specialPlanCountdown}
      discountAmount={discountAmount}
      currency={currency}
      currencySymbol={currencySymbol}
      vat={vat}
      fullPlan={fullPlan}
      discountPlan={discountPlan}
      getReport={getReport}
      trialPriceWithoutVAT={defineTrialPrice(priceWithoutVAT)}
      trialPrice={trialPrice}
      onSucceed={next}
      onPaymentDialogClose={onPaymentDialogClose}
      {...(screenAttributes as TrialPaymentPalmistryScreenAttributes)}
    />
  );
};

export default TrialPaymentPalmistry;
