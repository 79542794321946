import { useEffect, useState } from 'react';
import { LoadingStatuses } from 'core/common/entities';
import { useServices } from 'core/common/hooks';
import { useDetectPalm } from 'core/palmistry/hooks';

export enum ScanProcessPopUp {
  SCAN_MAKER,
  SCAN_LOADING,
  SCAN_ERROR,
}

const useScanProcessPopUps = () => {
  const { analyticsService } = useServices();

  const [scanProcessStep, setScanProcessStep] = useState(ScanProcessPopUp.SCAN_MAKER);

  const { detectPalm, loadingStatus } = useDetectPalm();

  const tryAgain = () => {
    setScanProcessStep(ScanProcessPopUp.SCAN_MAKER);
  };

  const closePopupQuestion = (data: { popupName: string; answer: string }) => {
    analyticsService.flowProcessingPagePopupOpen(data.popupName);
    analyticsService.flowProcessingPagePopupClicked(data.answer);
  };

  useEffect(() => {
    if (loadingStatus === LoadingStatuses.FAILED) {
      setScanProcessStep(ScanProcessPopUp.SCAN_ERROR);
    }
  }, [loadingStatus]);

  return {
    scanProcessStep,
    setScanProcessStep,
    detectPalm,
    loadingStatus,
    tryAgain,
    closePopupQuestion,
  };
};

export default useScanProcessPopUps;
