import { prepareHtml } from 'core/common/utils/prepareHtml';
import { DisclaimerText } from './styled';

export type DisclaimerProps = {
  disclaimer: string;
};

const Disclaimer = ({ disclaimer }: DisclaimerProps) => {
  return <DisclaimerText dangerouslySetInnerHTML={prepareHtml(disclaimer)}></DisclaimerText>;
};
export default Disclaimer;
