import { formatPrice } from 'core/common/utils/formatPrice';
import { TrialPaymentWithDiscountPalmistryScreenAttributes } from 'core/funnel/entities';
import { CurrencySymbol } from 'core/user/entities';
import {
  Discount,
  DiscountIcon,
  DiscountOldPrice,
  DiscountPrice,
  DiscountPriceWrapper,
  DiscountText,
  DiscountTextWrapper,
  NewPrice,
  OldPrice,
  Price,
  PriceDescription,
  PriceSubtitle,
  PriceText,
  PriceTitle,
  PriceWrapper,
  Total,
  TotalPrice,
  TotalText,
  Wrapper,
} from './styled';

type SectionPriceProps = {
  oldPriceDiscount: number;
  newPriceDiscount: number;
  oldPrice: number;
  price: number;
  period: string;
  totalPrice: number;
  currencySymbol: CurrencySymbol;
  content: TrialPaymentWithDiscountPalmistryScreenAttributes['content']['offer'];
};

const SectionPrice = ({
  oldPriceDiscount,
  newPriceDiscount,
  oldPrice,
  price,
  currencySymbol,
  period,
  totalPrice,
  content,
}: SectionPriceProps) => {
  const { title, subtitle, discountApplied, cost, saveMoney, totalText } = content;

  const priceWithCurrency = `${currencySymbol}${formatPrice(oldPrice - price)}`;
  const priceText = `${cost.beforePeriod} ${period} ${cost.afterPeriod}`;
  const priceDescription = `${saveMoney.beforePrice} ${priceWithCurrency} ${saveMoney.afterPrice}`;

  return (
    <Wrapper>
      <PriceTitle>{title}</PriceTitle>
      <PriceSubtitle>{subtitle}</PriceSubtitle>
      <Discount>
        <DiscountTextWrapper>
          <DiscountIcon emoji={discountApplied.emoji} />
          <DiscountText>{discountApplied.text}</DiscountText>
        </DiscountTextWrapper>
        <DiscountPriceWrapper>
          <DiscountOldPrice>-{oldPriceDiscount}%</DiscountOldPrice>
          <DiscountPrice>-{newPriceDiscount}%</DiscountPrice>
        </DiscountPriceWrapper>
      </Discount>
      <Price>
        <PriceText>{priceText}</PriceText>
        <PriceWrapper>
          <OldPrice>
            {currencySymbol}
            {oldPrice}
          </OldPrice>
          <NewPrice>
            {currencySymbol}
            {price}
          </NewPrice>
        </PriceWrapper>
      </Price>
      <PriceDescription>{priceDescription}</PriceDescription>
      <Total>
        <TotalText>{totalText}</TotalText>
        <TotalPrice>
          {currencySymbol}
          {totalPrice}
        </TotalPrice>
      </Total>
    </Wrapper>
  );
};

export default SectionPrice;
