import { useGetTheme } from 'core/theme/hooks';
import { Circle, MobileStepper, Step, StepLabel, Stepper, Title } from './styled';

type ProgressBarHintProps = {
  currentName?: 'profile' | 'personality' | 'relationship';
  currentStep: number;
  title?: string;
  totalProfileSteps?: number;
  totalPersonalitySteps?: number;
  totalRelationshipSteps?: number;
  className?: string;
};

enum Steps {
  PROFILE,
  PERSONALITY,
  RELATIONSHIP,
  FINISHED,
}

const ProgressBarHint = ({
  currentName = 'profile',
  currentStep = 3,
  title = '',
  totalProfileSteps = 14,
  totalPersonalitySteps = 10,
  totalRelationshipSteps = 15,
  className,
}: ProgressBarHintProps) => {
  const theme = useGetTheme();

  let currentColor = theme.colors.primary500;
  let activeStep = 0;

  switch (currentName) {
    case 'profile':
      activeStep = 0;
      break;
    case 'personality':
      currentColor = '#56CCF2';
      activeStep = 1;
      break;
    case 'relationship':
      currentColor = theme.colors.tertiary500;
      activeStep = 2;
      break;
  }

  return (
    <>
      <Stepper activeStep={activeStep} connector={<></>} className={className}>
        <Step active={activeStep === Steps.PROFILE} completed>
          <Circle />
          <StepLabel color={theme.colors.primary500} />
          <MobileStepper
            color={theme.colors.primary500}
            variant="progress"
            steps={totalProfileSteps}
            position="static"
            activeStep={activeStep === Steps.PROFILE ? currentStep : totalProfileSteps - 1}
            backButton={<div />}
            nextButton={<div />}
          />
        </Step>
        <Step active={activeStep === Steps.PERSONALITY} completed={activeStep >= Steps.PERSONALITY}>
          <StepLabel color={theme.colors.primary500} />
          <MobileStepper
            color="#56CCF2"
            variant="progress"
            steps={totalPersonalitySteps}
            position="static"
            activeStep={
              activeStep === Steps.PERSONALITY
                ? currentStep
                : activeStep === Steps.PROFILE
                ? 0
                : totalPersonalitySteps - 1
            }
            backButton={<div />}
            nextButton={<div />}
          />
        </Step>
        <Step
          active={activeStep === Steps.RELATIONSHIP}
          completed={activeStep >= Steps.RELATIONSHIP}
        >
          <StepLabel color="#56CCF2" />
          <MobileStepper
            color={theme.colors.tertiary500}
            variant="progress"
            steps={totalRelationshipSteps}
            position="static"
            activeStep={activeStep < Steps.RELATIONSHIP ? 0 : currentStep}
            backButton={<div />}
            nextButton={<div />}
          />
        </Step>
        <Step active={activeStep >= Steps.FINISHED}>
          <StepLabel color={theme.colors.tertiary500} />
        </Step>
      </Stepper>
      <Title currentColor={currentColor}>{title}</Title>
    </>
  );
};

export default ProgressBarHint;
