import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { BoldStyled, BreakStyled, ContentStyled, DescriptionStyled } from './styled';

export type SectionPaymentProps = {
  isOpen: boolean;
  offer: Offer;
  onClose: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const Payment = (props: SectionPaymentProps) => {
  const { onPaymentSuccess, offer, onClose, isOpen = false } = props;
  const { domainUrl } = useConfig();

  const onPaymentDialogClose = () => {
    onClose();
  };

  return (
    <>
      <PaymentDialog
        isOpen={isOpen}
        productId={offer.offerId}
        currency={offer.currency}
        onClose={onPaymentDialogClose}
        onPaymentSuccess={onPaymentSuccess}
      >
        <ContentStyled>
          <Location>
            <LocationOption option={Locales.US}>
              <DescriptionStyled>
                You will be charge only{' '}
                <BoldStyled>
                  {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your 7-day trial.
                </BoldStyled>
                <BreakStyled /> We’ll <BoldStyled>email you a reminder</BoldStyled> before your
                trial period ends. <BoldStyled>Cancel anytime.</BoldStyled>
                The charge will appear on your bill as {domainUrl}
              </DescriptionStyled>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <DescriptionStyled>
                You will be charge only{' '}
                <BoldStyled>
                  {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your 7-day trial.
                </BoldStyled>
                <BreakStyled /> We’ll <BoldStyled>email you a reminder</BoldStyled> before your
                trial period ends. <BoldStyled>Cancel anytime.</BoldStyled>
              </DescriptionStyled>
            </LocationOption>
          </Location>
        </ContentStyled>
      </PaymentDialog>
    </>
  );
};

export default Payment;
