import { useEffect } from 'react';
import TrialCreditsPalmistryScreen from 'core/common/components/QuizScreens/TrialCreditsPalmistry';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialCreditsPalmistryScreenAttributes } from 'core/funnel/entities';
import { useGetActualPaymentPlans, useProduct } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

export type TrialCreditsEmailMarketingProps = {
  id: string;
};

const TrialCreditsEmailMarketing = ({ id }: TrialCreditsEmailMarketingProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { trialCreditsUpsellPlan: plan, currencySymbol } = useGetActualPaymentPlans();
  const { analyticsService } = useServices();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const { product } = useProduct(plan.value);

  const price = product && product.price ? `${currencySymbol}${product.price}` : '0';

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const buyCredits = () => {
    recurringPaymentRequest(plan.value);
    analyticsService.upsellCreditsLpGetReport(plan.value);
  };

  const skip = () => {
    analyticsService.upsellCreditsLpSkip();
    next();
  };

  useEffect(() => {
    analyticsService.upsellCreditsLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellCreditsLpGetReport(plan.value);
      next();
    }
  }, [paymentProcessStep]);

  return (
    <TrialCreditsPalmistryScreen
      isLoading={isPurchaseLoading}
      plan={plan}
      currencySymbol={currencySymbol}
      price={price}
      resignFormLoaded={isResignFormLoaded}
      onBuyCredits={buyCredits}
      onSkip={skip}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as TrialCreditsPalmistryScreenAttributes)}
    />
  );
};

export default TrialCreditsEmailMarketing;
