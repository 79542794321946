import message from 'core/common/assets/images/questions/message.svg';
import user1 from 'core/common/assets/images/questions/user1.png';
import user2 from 'core/common/assets/images/questions/user2.png';
import user3 from 'core/common/assets/images/questions/user3.png';
import { QuestionsPalmistryScreenProps } from 'core/funnel/entities';
import {
  Avatar,
  AvatarContainer,
  Background,
  Container,
  FirstMessageStyled,
  Item,
  List,
  SecondMessageStyled,
  SecondText,
  StyledAvatarContainer,
  Text,
  ThirdText,
  Tip,
  Title,
} from './styled';

const QuestionsPalmistryScreen = ({ content }: QuestionsPalmistryScreenProps) => {
  const { title, tip, firstMessage, secondMessage, thirdMessage } = content;

  return (
    <>
      <Background />
      <Container>
        <Title>{title}</Title>
        <List>
          <Item>
            <FirstMessageStyled src={message} alt="message" width={281} height={125} />
            <Text>{firstMessage}</Text>
            <AvatarContainer>
              <Avatar src={user1} alt="user avatar" />
            </AvatarContainer>
          </Item>
          <Item>
            <SecondMessageStyled src={message} alt="message" width={281} height={125} />{' '}
            <SecondText>{secondMessage}</SecondText>
            <StyledAvatarContainer>
              <Avatar src={user2} alt="user avatar" />
            </StyledAvatarContainer>
          </Item>
          <Item>
            <FirstMessageStyled src={message} alt="message" width={281} height={125} />
            <ThirdText>{thirdMessage}</ThirdText>
            <AvatarContainer>
              <Avatar src={user3} alt="user avatar" />
            </AvatarContainer>
          </Item>
        </List>
        <Tip>{tip}</Tip>
      </Container>
    </>
  );
};

export default QuestionsPalmistryScreen;
