import { ReactNode } from 'react';
import { CardBaseStyled } from './styled';

type ReviewsCardBaseProps = {
  children: ReactNode;
  className?: string;
};

const ReviewsCardBase = ({ children, className }: ReviewsCardBaseProps) => {
  return <CardBaseStyled className={className}>{children}</CardBaseStyled>;
};

export default ReviewsCardBase;
