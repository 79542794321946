import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  width: 100%;
  padding: 20px 16px;
  border-radius: 16px;
  background-color: #fafafa;
`;

export const TitleStyled = styled.h4`
  font-weight: 600;
  text-align: center;
`;

export const SubtitleStyled = styled.p`
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const GenderStyled = styled.span`
  margin-right: 8px;
  text-transform: capitalize;
`;

export const ZodiacStyled = styled.span`
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
`;

export const ZodiacIconStyled = styled(Image)`
  margin-right: 4px;
`;

export const ContentStyled = styled.div`
  display: flex;
  margin-top: 16px;
`;
