import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.tertiary100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0;
  }
`;

export const TitleStyled = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const TimeBlockStyled = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const TimerUnitCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerUnitLabelStyled = styled.span`
  font-size: 8px;
  font-weight: 400;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const TimerUnitTextStyled = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 28px;
  }
`;

export const TimerDividerStyled = styled(TimerUnitTextStyled)`
  margin-right: 4px;
  margin-left: 4px;
`;
