import TimeV2Screen from 'core/common/components/QuizScreens/TimeV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { TimeV2ScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type TimeV2PageProps = {
  id: string;
};

const TimeV2Page = ({ id }: TimeV2PageProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as TimeV2ScreenAttributes;

  const userTime = (userPreferences[dataKey as keyof UserProfile] as string) || '';

  const onSubmit = (time: string) => {
    updateUserPreferences(dataKey, time);
    next();
  };

  return (
    <TimeV2Screen
      onBack={back}
      onSubmit={onSubmit}
      userTime={userTime}
      step={step}
      {...(screenAttributes as TimeV2ScreenAttributes)}
    />
  );
};

export default TimeV2Page;
