import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  text-align: center;
`;

export const PalmsListStyled = styled.ul``;

export const PalmImageStyled = styled(Image)`
  margin-right: 8px;
`;

export const PalmsListItemStyled = styled.li`
  display: flex;
  align-items: flex-start;

  &:nth-child(even) {
    flex-direction: row-reverse;

    ${PalmImageStyled} {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
