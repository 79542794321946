import styled from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 12px;
    font-size: 32px;
    text-align: center;
  }
`;

export const SubtitleStyled = styled.p`
  display: none;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: block;
    margin-bottom: 40px;
  }
`;

export const ListStyled = styled.ul`
  margin-bottom: 14px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    margin-bottom: 56px;
  }
`;

export const ListItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 25%;

    &:not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

export const FooterStyled = styled.footer`
  position: sticky;
  bottom: 0;
  z-index: 2;
  max-width: 400px;
  margin-right: -16px;
  margin-left: -16px;
  padding: 12px 16px 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 30px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const SkipButtonStyled = styled(TextButton)`
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const DisclaimerStyled = styled.p`
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;
