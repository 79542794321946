import { useMemo } from 'react';
import RelationshipZodiacInfoScreen from 'core/common/components/QuizScreens/RelationshipZodiacInfo';
import { useQuizStep } from 'core/funnel/contexts';
import { RelationshipZodiacInfoScreenAttributes } from 'core/funnel/entities';
import { getZodiacPersonImage } from 'core/zodiac/utils/getZodiacPersonImage';

export interface RelationshipZodiacInfoProps {
  id: string;
}

const RelationshipZodiacInfo = ({ id }: RelationshipZodiacInfoProps) => {
  const { back, screenAttributes, next, userPreferences } = useQuizStep(id);

  const {
    content: { descriptionOptions },
  } = screenAttributes as RelationshipZodiacInfoScreenAttributes;

  const onBack = () => back();

  const zodiacImage = getZodiacPersonImage(userPreferences.zodiac, userPreferences.gender);

  const partnerZodiacImage = getZodiacPersonImage(
    userPreferences.partnerZodiac,
    userPreferences.partnerGender,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomIdx = useMemo(() => Math.floor(Math.random() * descriptionOptions.length), []);

  return (
    <RelationshipZodiacInfoScreen
      onBack={onBack}
      goNext={next}
      zodiacImage={zodiacImage}
      partnerZodiacImage={partnerZodiacImage}
      description={descriptionOptions[randomIdx]}
      {...(screenAttributes as RelationshipZodiacInfoScreenAttributes)}
    />
  );
};

export default RelationshipZodiacInfo;
