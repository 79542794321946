import DateScreen from 'core/common/components/QuizScreens/Date';
import { useServices } from 'core/common/hooks';
import { calculateAges } from 'core/common/utils/calculateAges';
import { createDate } from 'core/common/utils/date';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DateScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type DateProps = {
  id: string;
};

const Date = ({ id }: DateProps) => {
  const { analyticsService } = useServices();
  const { screenAttributes, back, next, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as DateScreenAttributes;

  const userDate = userPreferences[id as keyof UserProfile] as string;

  const getInitialDate = () => {
    if (!userDate) return { day: null, month: null, year: null };

    const formattedDate = createDate(userDate, 'MM-DD-YYYY');

    return {
      day: formattedDate.get('date'),
      month: formattedDate.get('month'),
      year: formattedDate.get('year'),
    };
  };

  const onSubmit = async (date: string) => {
    const userAge = calculateAges(date);

    analyticsService.setUserAge(userAge);

    updateUserPreferences(dataKey, date);

    await next();
  };

  return (
    <DateScreen
      onBack={back}
      onSubmit={onSubmit}
      getInitialDate={getInitialDate}
      step={step}
      {...(screenAttributes as DateScreenAttributes)}
    />
  );
};
export default Date;
