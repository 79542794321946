import Image from 'core/common/components/Image';
import { SectionDiscountModalV2Attributes } from 'core/funnel/entities';
import {
  AspectRatioStyled,
  ButtonStyled,
  CloseButtonStyled,
  FooterImageStyled,
  FooterStyled,
  FooterSubtitleStyled,
  FooterTitleStyled,
  ModalStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

type SectionDiscountModalV2Props = {
  content: SectionDiscountModalV2Attributes['content'];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const SectionDiscountModalV2 = ({
  isOpen,
  content,
  onClose,
  onConfirm,
}: SectionDiscountModalV2Props) => {
  const { title, subtitle, image, footer, buttonText } = content;

  return (
    <ModalStyled open={isOpen} onClose={onClose}>
      <>
        <CloseButtonStyled onClick={onClose} />
        <TitleStyled>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <AspectRatioStyled width={436} height={190}>
          <Image src={image.src} alt={image.alt} fill />
        </AspectRatioStyled>
        <FooterStyled>
          <FooterImageStyled src={footer.image.src} alt={footer.image.alt} width={56} height={56} />
          <FooterTitleStyled>{footer.title}</FooterTitleStyled>
          <FooterSubtitleStyled>{footer.subtitle}</FooterSubtitleStyled>
        </FooterStyled>
        <ButtonStyled fullWidth onClick={onConfirm}>
          {buttonText}
        </ButtonStyled>
      </>
    </ModalStyled>
  );
};

export default SectionDiscountModalV2;
