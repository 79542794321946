import { useMemo } from 'react';
import { SectionBannerAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import Discount from './Discount';
import { ButtonStyled, IconStyled, WrapperStyled } from './styled';

type SectionBannerProps = {
  offer?: Offer;
  content: SectionBannerAttributes['content'];
  onClick: () => void;
};

const SectionBanner = ({ offer, content, onClick }: SectionBannerProps) => {
  const leftSlot = useMemo(() => {
    if (content.specialOffer && offer) {
      return <Discount offer={offer} specialOffer={content.specialOffer} />;
    }

    return <IconStyled name="nebula" />;
  }, [content, offer]);

  return (
    <WrapperStyled>
      {leftSlot}

      <ButtonStyled impulseScale onClick={onClick}>
        {content.title}
      </ButtonStyled>
    </WrapperStyled>
  );
};

export default SectionBanner;
