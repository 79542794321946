import { useFormik } from 'formik';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { isEmailValid } from 'core/common/utils/string';
import { EmailNatalChartScreenProps } from 'core/funnel/entities';
import EmailSuggestionsList from '../../EmailSuggestionsList';
import { Button, ContainerMain, Content, Form, Policy, Subtitle, Text, Title } from './styled';

const EmailNatalChartScreen = ({
  content,
  step,
  totalSteps,
  initialValue,
  progressBarTitle,
  onSubmit,
  onBack,
}: EmailNatalChartScreenProps) => {
  const { getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue } =
    useFormik({
      initialValues: {
        email: initialValue,
      },
      validate: ({ email }) => {
        if (!email || !isEmailValid(email)) {
          return { email: content.error };
        }

        return {};
      },
      onSubmit: async (submitValues, helpers) => {
        await onSubmit(submitValues.email, helpers);
      },
    });

  const error = touched.email ? errors.email : '';

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <ContainerMain>
        <Title>{content.title}</Title>
        <Subtitle dangerouslySetInnerHTML={prepareHtml(content.subtitle)} />
        <Form onSubmit={handleSubmit}>
          <Content>
            <Input
              {...getFieldProps('email')}
              type="email"
              error={!!error}
              errorText={error}
              placeholder={content.placeholder}
            />
            <EmailSuggestionsList
              input={values.email}
              onSelect={(value) => setFieldValue('email', value)}
            />
            <Text>{content.text}</Text>
          </Content>
          <Button type="submit" disabled={isSubmitting}>
            {content.button}
          </Button>
        </Form>
        <Policy />
      </ContainerMain>
    </>
  );
};

export default EmailNatalChartScreen;
