import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';
import { InformationalPalmistryScreenProps } from 'core/funnel/entities';
import { Button, Container, ImageDesktop, ImageMobile, Text, Title } from './styled';

const InformationalPalmistryScreen = ({
  onBack,
  goNext,
  content,
}: InformationalPalmistryScreenProps) => {
  const { backgroundImage, text } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <BackgroundGradient />
      <Container>
        {backgroundImage && (
          <>
            <ImageMobile
              src={backgroundImage.mobileImage.src}
              alt={backgroundImage.mobileImage.alt}
              width={150}
              height={240}
            />
            <ImageDesktop
              src={backgroundImage.desktopImage.src}
              alt={backgroundImage.desktopImage.src}
              width={195}
              height={314}
            />
          </>
        )}
        <Title>{content.title}</Title>
        {text && <Text>{content.text}</Text>}
        <Button onClick={goNext} variant="secondary">
          {content.next}
        </Button>
      </Container>
    </>
  );
};

export default InformationalPalmistryScreen;
