import { ReactNode } from 'react';
import { BoldStyled, IconStyled, TextStyled, WrapperStyled } from './styled';

type BannerInfoProps = {
  children?: ReactNode;
};

const BannerInfo = ({ children }: BannerInfoProps) => (
  <WrapperStyled>
    {children || (
      <TextStyled>
        Last week alone <BoldStyled>2000+ people</BoldStyled> got this relationship guide
      </TextStyled>
    )}
    <IconStyled name="info" width={44} height={44} />
  </WrapperStyled>
);

export default BannerInfo;
