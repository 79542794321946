import { formatTraumaScore } from 'core/common/utils/formatTraumaScore';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import { WrapperStyled, ImageStyled, ImageWrapperStyled, TraumaScoreStyled } from './styled';

type UserTraumaScoreProps = {
  title: string;
  value: string;
  image: FunnelImage;
  traumaScore: number;
};

const UserTraumaScore = ({ image, traumaScore }: UserTraumaScoreProps) => {
  const traumaValue = formatTraumaScore(traumaScore);

  return (
    <WrapperStyled>
      <ImageWrapperStyled>
        <ImageStyled src={image.src} alt={image.alt} width={108} height={108} />
        <TraumaScoreStyled dangerouslySetInnerHTML={prepareHtml(`${traumaValue}/10`)} />
      </ImageWrapperStyled>
    </WrapperStyled>
  );
};

export default UserTraumaScore;
