import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionPlansOverviewAttributes } from 'core/funnel/entities';
import {
  TitleStyled,
  CellStyled,
  CellsStyled,
  WrapperStyled,
  DecorationImageStyled,
  PlansOverviewCardStyled,
  PeriodStyled,
  RowsStyled,
  IconStyled,
  RowStyled,
  PlanStyled,
  HeaderCellStyled,
  HeaderRowStyled,
  PeriodValueStyled,
  ActiveUsersStyled,
  UsersImageStyled,
  ActiveUsersText,
  ButtonStyled,
} from './styled';

type SectionPlansOverviewProps = {
  content: SectionPlansOverviewAttributes['content'];
  className?: string;
  onGetPlan: () => void;
};

const SectionPlansOverview = ({ content, className, onGetPlan }: SectionPlansOverviewProps) => {
  const { title, decorationImage, periods, plans, activeUsers, button } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <PlansOverviewCardStyled>
        <DecorationImageStyled
          src={decorationImage.src}
          alt={decorationImage.alt}
          width={143}
          height={143}
        />
        <RowsStyled>
          <HeaderRowStyled>
            <CellsStyled>
              {periods.map((period, idx) => {
                const [periodValue, ...periodText] = period.split(' ');

                return (
                  <HeaderCellStyled key={periodValue} highlighted={idx === 1}>
                    <PeriodStyled>
                      <PeriodValueStyled>{periodValue}</PeriodValueStyled>
                      {periodText.join(' ')}
                    </PeriodStyled>
                  </HeaderCellStyled>
                );
              })}
            </CellsStyled>
          </HeaderRowStyled>
          {plans.map((plan, planIdx) => (
            <RowStyled key={plan.title}>
              <PlanStyled>{plan.title}</PlanStyled>
              <CellsStyled>
                {periods.map((period, periodIdx) => (
                  <CellStyled
                    key={period}
                    highlighted={periodIdx === 1}
                    isLastPlan={planIdx === plans.length - 1}
                  >
                    <IconStyled
                      name={plan.activePeriods.includes(period) ? 'check-rounded' : 'block-rounded'}
                    />
                  </CellStyled>
                ))}
              </CellsStyled>
            </RowStyled>
          ))}
        </RowsStyled>
        <ActiveUsersStyled>
          <UsersImageStyled
            src={activeUsers.image.src}
            alt={activeUsers.image.alt}
            width={52}
            height={24}
          />
          <ActiveUsersText dangerouslySetInnerHTML={prepareHtml(activeUsers.text)} />
        </ActiveUsersStyled>
      </PlansOverviewCardStyled>
      <ButtonStyled onClick={onGetPlan} fullWidth>
        {button}
      </ButtonStyled>
    </WrapperStyled>
  );
};

export default SectionPlansOverview;
