import { Trans, useTranslation } from 'react-i18next';
import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { europeanCountries, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import {
  Bold,
  Content,
  Description,
  Label,
  LabelWrapper,
  TotalLabel,
  TotalWrapper,
} from './styled';

export type SectionPaymentProps = {
  isTimeUp: boolean;
  isOpen: boolean;
  offer: Offer;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const SectionPayment = (props: SectionPaymentProps) => {
  const { onClose, isTimeUp, onPaymentSuccess, isOpen, offer } = props;
  const { domainUrl } = useConfig();

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  return (
    <>
      <PaymentDialog
        productId={offer.offerId}
        currency={offer.currency}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
      >
        <Content>
          {isTimeUp ? (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />
                </Description>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.full`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />{' '}
                  The charge will appear on your bill as {domainUrl}
                </Description>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.full`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />
                </Description>
              </LocationOption>
            </Location>
          ) : (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <LabelWrapper>
                  <Label>
                    <Trans
                      i18nKey={`${tKeyPrefix}.pay.trial`}
                      values={{
                        trialPeriod: offer.trialPeriod,
                      }}
                      components={{
                        bold: <Bold />,
                      }}
                    />
                  </Label>
                  <Label>
                    {t('pay.price')} <Bold>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</Bold>
                  </Label>
                </LabelWrapper>
                <LabelWrapper>
                  <Label>{t('pay.tax')}</Label>
                  <Label>{offer.pricesWithCurrencySymbol.vatOfTrialPrice}</Label>
                </LabelWrapper>
                <TotalWrapper>
                  <TotalLabel>{t('pay.total')}:</TotalLabel>
                  <TotalLabel>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</TotalLabel>
                </TotalWrapper>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.email`}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />
                </Description>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPrice,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />{' '}
                  The charge will appear on your bill as {domainUrl}
                </Description>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <Description>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPrice,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <Bold />,
                      br: <br />,
                    }}
                  />
                </Description>
              </LocationOption>
            </Location>
          )}
        </Content>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
