import { useCallback, useEffect } from 'react';
import TrialPaymentPalmistryScreen from 'core/common/components/QuizScreens/TrialPaymentPalmistryV4';
import { LANDINGS } from 'core/common/constants';
import { useServices, useRouter, useCountdown } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentPalmistryV4ScreenAttributes } from 'core/funnel/entities';
import { useQuizData } from 'core/funnel/hooks';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';

type TrialPaymentPalmistryV4Props = {
  id: string;
};

const TrialPaymentPalmistryV4 = ({ id }: TrialPaymentPalmistryV4Props) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { link } = useQuizData();
  const { navigate } = useRouter();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.PALMISTRY });

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffer, selectedOffer } = useOffers();
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const offer = isTimeUp ? timeUpOffer : selectedOffer;

  const onClosePaymentModal = () => {
    analyticsService.secretDiscountOpen();
  };

  const navigateToDiscountPage = () => {
    navigate(joinUrls(link, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentPalmistryScreen
      offer={offer}
      isTimeUp={isTimeUp}
      timeLeft={specialPlanCountdown}
      goNext={next}
      getReport={getReport}
      onClosePaymentModal={onClosePaymentModal}
      navigateToDiscountPage={navigateToDiscountPage}
      {...(screenAttributes as TrialPaymentPalmistryV4ScreenAttributes)}
    />
  );
};

export default TrialPaymentPalmistryV4;
