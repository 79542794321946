import hands from 'core/common/assets/images/onboarding/hands.png';
import planet from 'core/common/assets/images/onboarding/planet.png';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { OnboardingAskPalmistryScreenProps } from 'core/funnel/entities';
import { Background, Container, Content, ImageContainer, Text, Title, Welcome } from './styled';

const OnboardingAskPalmistryScreen = ({ content }: OnboardingAskPalmistryScreenProps) => {
  const { welcomeText, title, text } = content;

  return (
    <>
      <Background />
      <Container>
        <ImageContainer>
          <Image src={planet} width={347} height={350} alt="planet" priority />
        </ImageContainer>
        <Content>
          <Welcome>{welcomeText}</Welcome>
          <Title>{title}</Title>
          <Text dangerouslySetInnerHTML={prepareHtml(text)} />
          <Image priority src={hands} width={360} height={200} alt="hand" />
        </Content>
      </Container>
    </>
  );
};

export default OnboardingAskPalmistryScreen;
