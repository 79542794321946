import { useRef, useState } from 'react';
import Header from 'core/common/components/Header';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { useIsInViewport } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentIntroOfferScreenProps } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import FAQ from './FAQ';
import Partners from './Partners';
import Reviews from './Reviews';
import SalesScreenHeader from './SalesScreenHeader';
import SectionBenefits from './SectionBenefits';
import SectionBlurredReport from './SectionBlurredReport';
import SectionPayment from './SectionPayment';
import SectionUserDetails from './SectionUserDetails';
import {
  ColoredBackgroundWrapperStyled,
  FooterTextStyled,
  HeaderSpaceStyled,
  MainContainerWideStyled,
  PageSectionContainerStyled,
  TitleStyled,
} from './styled';

const offerScrollOffset = -65;
const offerBlockId = 'report-block';

const TrialPaymentIntroOfferScreen = ({
  goNext,
  timeLeft,
  getReport,
  offer,
  paymentDialogClose,
  content,
  zodiacImage,
  partnerZodiacImage,
  relationshipType,
  timeUpOffer,
  defaultOffers,
  isAllOffersLoaded,
  onAllOffersLoaded,
  onOfferChanged,
  selectedOffer,
  timeUpOffers,
}: TrialPaymentIntroOfferScreenProps) => {
  const {
    header,
    userDetails,
    goal,
    specialOffer,
    report,
    reviews,
    benefits,
    featuredIn,
    faq,
    footer,
    subscriptionBlock: subscription,
  } = content;

  const { isMobile } = useIsMobile();

  const firstButtonRef = useRef<HTMLDivElement>(null);
  const secondButtonRef = useRef<HTMLDivElement>(null);

  const isFirstButtonInViewPort = useIsInViewport(firstButtonRef);

  const isSecondButtonInViewPort = useIsInViewport(secondButtonRef);

  const hideHeader = isFirstButtonInViewPort || isSecondButtonInViewPort;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const isTimeUp = timeLeft <= 0;

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const onGetReport = () => {
    setIsPaymentModalOpen(true);
    getReport();
  };

  if (!timeUpOffer || !offer) {
    return null;
  }

  return (
    <>
      {!isMobile && <Header buttonBack={false} />}
      <MainContainerWideStyled>
        <PageSectionContainerStyled noGutters>
          <ColoredBackgroundWrapperStyled>
            <SalesScreenHeader
              hide={isMobile && hideHeader}
              onScrollToBlock={moveToOffer}
              title={header.title}
              button={header.button}
              timeLeft={timeLeft}
              timerMinTitle={header.min}
              timerSecTitle={header.sec}
            />
            <HeaderSpaceStyled />
            <SectionUserDetails
              relationshipType={relationshipType}
              partnerZodiacImage={partnerZodiacImage}
              zodiacImage={zodiacImage}
              userDetails={userDetails}
            />
          </ColoredBackgroundWrapperStyled>
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <SectionBlurredReport
            isTimeUp={isTimeUp}
            onGetReport={onGetReport}
            zodiacImage={zodiacImage}
            report={report}
            zodiacSign={userDetails.zodiacSign}
            gender={userDetails.gender}
            onScrollToBlock={moveToOffer}
            goal={goal}
            subscription={subscription}
            guaranteeText={specialOffer.guaranteeText}
            chosenPlan={offer}
            defaultOffers={defaultOffers}
            isAllPlansLoaded={isAllOffersLoaded}
            onAllPlansLoaded={onAllOffersLoaded}
            onPlanChanged={onOfferChanged}
            selectedPlan={selectedOffer}
            timeUpOffers={timeUpOffers}
          />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{reviews.title}</TitleStyled>
          <Reviews list={reviews.list} reviewPolicy={reviews.reviewPolicy} />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{benefits.title}</TitleStyled>
          <SectionBenefits benefits={benefits.list} />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{featuredIn.title}</TitleStyled>
          <Partners />
        </PageSectionContainerStyled>
        <PageSectionContainerStyled>
          <TitleStyled>{faq.title}</TitleStyled>
          <FAQ questions={faq.list} />
        </PageSectionContainerStyled>
        <Location>
          <LocationOption option={Locales.US}>
            <FooterTextStyled>{footer.addressUS}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.AE}>
            <FooterTextStyled>{footer.addressAE}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <FooterTextStyled>{footer.address}</FooterTextStyled>
          </LocationOption>
        </Location>
      </MainContainerWideStyled>
      {!!offer && (
        <RenderOnlyOnClient>
          <SectionPayment
            onPaymentSuccess={goNext}
            isTimeUp={isTimeUp}
            isOpen={isPaymentModalOpen}
            offer={offer}
            onClose={paymentDialogClose}
          />
        </RenderOnlyOnClient>
      )}
    </>
  );
};

export default TrialPaymentIntroOfferScreen;
