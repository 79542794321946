import { ReactNode, useEffect, useState } from 'react';
import { PriceTimerContainer, PriceTimerTime } from './styled';

type PriceResolvedTimerProps = {
  initialValue: number;
  children: ReactNode;
  className?: string;
  element?: () => ReactNode;
  onTimeUpdate?: (value: number) => void;
};

const PriceResolvedTimer = ({
  initialValue,
  children,
  className,
  onTimeUpdate,
  element,
}: PriceResolvedTimerProps) => {
  const [timerValue, setTimerValue] = useState(initialValue);

  function convertToTimeFormat(time: number) {
    const minutes = Math.floor(time / (1000 * 60)).toString();
    let seconds = ((time % (1000 * 60)) / 1000).toString();
    if (seconds.length === 1) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (timerValue > 0) {
      timer = setTimeout(() => {
        setTimerValue((prev) => prev - 1000);
        if (onTimeUpdate) onTimeUpdate(timerValue - 1000);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue]);

  return (
    <PriceTimerContainer className={className}>
      {children}
      <PriceTimerTime>{convertToTimeFormat(timerValue)}</PriceTimerTime>
      {element && element()}
    </PriceTimerContainer>
  );
};

export default PriceResolvedTimer;
