import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionAttachmentStyleProfileAttributes } from 'core/funnel/entities';
import { IconNames } from 'core/svg-sprites/entities';
import Profile from './Profile';
import ProfileShort from './ProfileShort';
import { WrapperStyled, TitleStyled } from './styled';

type SectionAttachmentStyleProfileProps = {
  content: SectionAttachmentStyleProfileAttributes['content'];
  traumaScore: number;
  zodiacIcon: IconNames;
  className?: string;
};

const SectionAttachmentStyleProfile = ({
  content,
  traumaScore,
  zodiacIcon,
  className,
}: SectionAttachmentStyleProfileProps) => {
  const { title, ...cardContent } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      {cardContent.zodiac ? (
        <Profile {...cardContent} traumaScore={traumaScore} zodiacIcon={zodiacIcon} />
      ) : (
        <ProfileShort {...cardContent} traumaScore={traumaScore} />
      )}
    </WrapperStyled>
  );
};

export default SectionAttachmentStyleProfile;
