import steps from 'core/common/assets/images/upsells/steps.svg';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialCreditsNatalChartScreenAttributes } from 'core/funnel/entities';
import { Progress, Step1, Step2, Step3, StepsImageStyled, Subtitle, Title } from './styled';

type SectionHeaderProps = {
  content: TrialCreditsNatalChartScreenAttributes['content']['header'];
};

const SectionHeader = ({ content }: SectionHeaderProps) => {
  const { buyStep, consultationStep, accessProductStep, title, subtitle } = content;

  return (
    <>
      <Progress>
        <Step1 dangerouslySetInnerHTML={prepareHtml(buyStep)} />
        <Step2 dangerouslySetInnerHTML={prepareHtml(consultationStep)} />
        <Step3 dangerouslySetInnerHTML={prepareHtml(accessProductStep)} />
      </Progress>
      <StepsImageStyled src={steps} alt="progress step" width={330} height={31} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </>
  );
};

export default SectionHeader;
