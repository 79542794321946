import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import Icon from 'core/svg-sprites/ui/Icon';

export const Container = styled.div`
  margin-top: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 24px;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const Discount = styled.div`
  display: inline-block;
  margin-left: 4px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.primary100};
`;

export const DiscountText = styled.span`
  font-size: 12px;
  line-height: 170%;
  background: linear-gradient(
    165.54deg,
    #141333 -33.39%,
    #202261 15.89%,
    #543c97 55.84%,
    #6939a2 74.96%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PrimaryDiscountText = styled.p`
  margin-left: 12px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 170%;
  color: ${(props) => props.theme.colors.typography100};
  background: ${(props) => props.theme.colors.tertiary400};
`;

export const PriceBlock = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const OldPrice = styled.span`
  text-decoration: line-through;
`;

export const Price = styled.span`
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const Image = styled.img`
  margin-right: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 62px;
    height: 76px;
  }
`;

export const Emoji = styled(EmojiComponent)`
  width: 24px;
  height: 24px;
  margin-left: 3px;
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.typography100};
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #d4c6e5;
  border-radius: 50%;
`;

export const RadioLabel = styled.label<{ isChosen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  padding: 12px 16px;
  border: ${(props) => (props.isChosen ? `2px solid ${props.theme.colors.tertiary300}` : 'unset')};
  border-radius: 12px;
  box-sizing: border-box;
  color: #380d44;
  background: ${(props) =>
    props.isChosen
      ? 'linear-gradient(125.02deg, rgba(100,43,115, 0.2) 18.39%, rgba(198,66,110,0.2) 81.83%)'
      : '#fff'};
  box-shadow: 2px 2px 6px rgba(84, 60, 151, 0.25);
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }

  ${Circle} {
    background: ${(props) =>
      props.isChosen
        ? 'linear-gradient(125.02deg, #642B73 18.39%, #C6426E 81.83%)'
        : props.theme.colors.typography100};
  }

  ${IconStyled} {
    display: ${(props) => (props.isChosen ? 'block' : 'none')};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 16px;
`;

export const PrimaryText = styled.span`
  color: ${(props) => props.theme.colors.info};
`;

export const Description = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 135%;
  color: #380d44;
`;
