import Image from 'core/common/components/Image';
import { FunnelImage } from 'core/funnel/entities';
import { PartnersBlock, Title } from './styled';

type PartnersSectionProps = {
  title: string;
  image: FunnelImage;
};

const PartnersSection = ({ title, image }: PartnersSectionProps) => {
  return (
    <PartnersBlock>
      <Title>{title}</Title>
      <Image alt={image.alt} src={image.src} height={190} width={325} quality={100} />
    </PartnersBlock>
  );
};

export default PartnersSection;
