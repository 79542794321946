import hands from 'core/common/assets/images/hands-with-heart.svg';
import message from 'core/common/assets/images/message.svg';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TalkWithExpertScreenProps } from 'core/funnel/entities';
import { Background, Container, ImageStyled, Text, TextWrapper } from './styled';

const TalkWithExpertScreen = ({ content }: TalkWithExpertScreenProps) => {
  const { text } = content;

  return (
    <>
      <Background />
      <Container>
        <TextWrapper>
          <ImageStyled src={message} alt="message" width={334} height={300} />
          <Text dangerouslySetInnerHTML={prepareHtml(text)} />
        </TextWrapper>
        <Image src={hands} alt="hands-with-heart" width={225} height={225} />
      </Container>
    </>
  );
};

export default TalkWithExpertScreen;
