import AspectRatio from 'core/common/components/AspectRatio';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { StoryIntroContent } from 'core/funnel/entities';
import {
  WrapperStyled,
  ImageStyled,
  ContentStyled,
  TitleStyled,
  TextStyled,
  CaptionStyled,
  FooterIconStyled,
} from './styled';

type IntroProps = StoryIntroContent;

const Intro = ({ image, title, text, caption, footerIcon }: IntroProps) => {
  return (
    <WrapperStyled>
      <AspectRatio width={375} height={494}>
        <ImageStyled src={image.src} alt={image.alt} fill priority />
      </AspectRatio>
      <ContentStyled>
        <TitleStyled variant="h1" dangerouslySetInnerHTML={prepareHtml(title)} />
        <TextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
        {caption && (
          <CaptionStyled variant="body-small" dangerouslySetInnerHTML={prepareHtml(caption)} />
        )}
        {footerIcon && (
          <FooterIconStyled src={footerIcon.src} alt={footerIcon.alt} width={24} height={24} />
        )}
      </ContentStyled>
    </WrapperStyled>
  );
};

export default Intro;
