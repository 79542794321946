import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';

type TimerProps = {
  timeLeft: number;
};

const Timer = ({ timeLeft }: TimerProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);

  return (
    <>
      {addLeadingZero(minutes)}:{addLeadingZero(seconds)}
    </>
  );
};

export default Timer;
