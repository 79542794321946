import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

export const ImageWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 70%;
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;

export const ContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 12px;
  padding-bottom: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 40px;
    padding-bottom: 80px;
  }
`;

export const TitleStyled = styled.h1`
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 22px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 28px;
    margin-bottom: 16px;
    font-size: 32px;
  }
`;

export const UserTypeStyled = styled.h3`
  font-weight: 400;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const DescriptionStyled = styled.p`
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const FooterStyled = styled.footer`
  width: 100%;
  margin-top: auto;
`;

export const CTATextStyled = styled.p`
  width: 100%;
  padding: 12px 8px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  font-size: 12px;
  background-color: #f5f5f5;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const ButtonStyled = styled(Button)`
  position: sticky;
  bottom: 20px;
  height: 56px;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
