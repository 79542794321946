import { SectionPalmAnalysisAttributes } from 'core/funnel/entities';
import ExpertMessage from './ExpertMessage';
import LineFacts from './LineFacts';
import {
  WrapperStyled,
  TitleStyled,
  PalmsListStyled,
  PalmsListItemStyled,
  PalmImageStyled,
} from './styled';

type SectionPalmAnalysisProps = {
  content: SectionPalmAnalysisAttributes['content'];
  className?: string;
};

const SectionPalmAnalysis = ({ content, className }: SectionPalmAnalysisProps) => {
  const { title, expertMessage, palmAnalysis } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      {expertMessage && <ExpertMessage {...expertMessage} />}
      <PalmsListStyled>
        {palmAnalysis.map(({ image, points, line }) => (
          <PalmsListItemStyled key={image.src}>
            <PalmImageStyled src={image.src} alt={image.alt} width={88} height={88} />
            <LineFacts points={points} line={line} />
          </PalmsListItemStyled>
        ))}
      </PalmsListStyled>
    </WrapperStyled>
  );
};

export default SectionPalmAnalysis;
