import { useEffect } from 'react';
import TalkWithExpertNatalChartScreen from 'core/common/components/QuizScreens/TalkWithExpertNatalChart';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TalkWithExpertNatalChartScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type TalkWithExpertNatalChartProps = {
  id: string;
};

const TalkWithExpertNatalChart = ({ id }: TalkWithExpertNatalChartProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onExpertPageOpened();
  }, [analyticsService]);

  return (
    <TalkWithExpertNatalChartScreen
      {...(screenAttributes as TalkWithExpertNatalChartScreenAttributes)}
    />
  );
};

export default TalkWithExpertNatalChart;
