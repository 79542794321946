import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionHighlightsAttributes } from 'core/funnel/entities';
import {
  ListStyled,
  TitleStyled,
  ListItemStyled,
  ListItemBackgroundStyled,
  AdvantageTitleStyled,
  AdvantageTextStyled,
  WrapperStyled,
} from './styled';

type SectionHighlightsProps = {
  content: SectionHighlightsAttributes['content'];
  className?: string;
};

const SectionHighlights = ({ content, className }: SectionHighlightsProps) => {
  const { title, list } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <ListStyled>
        {list.map((item) => (
          <ListItemStyled key={item.title}>
            <ListItemBackgroundStyled src={item.backgroundImage.src} alt="" fill />
            <AdvantageTitleStyled dangerouslySetInnerHTML={prepareHtml(item.title)} />
            <AdvantageTextStyled dangerouslySetInnerHTML={prepareHtml(item.text)} />
          </ListItemStyled>
        ))}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionHighlights;
