import styled from 'styled-components';
import Title from 'core/common/components/Title';

export const ContainerStyled = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 16px 8px 4px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.typography800};
  background: ${({ theme }) => `${theme.colors.primaryLeo}40`};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 20px 12px 16px;
  }
`;

export const TitleStyled = styled(Title)`
  margin-bottom: 18px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`;

export const DetailStyled = styled.div`
  display: flex;
  margin-bottom: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
  }
`;

export const IconWrapperStyled = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 40px;
  height: 40px;
  margin-right: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 64px;
    height: 64px;
    margin-right: 8px;
  }
`;

export const DetailContentStyled = styled.div``;

export const DetailTitleStyled = styled.h5`
  margin-bottom: 4px;
  line-height: 150%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const DetailDescriptionStyled = styled.p`
  font-size: 12px;
  line-height: 150%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;
