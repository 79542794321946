import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'core/common/components/Header';

import { BASE_ROUTES } from 'core/common/constants/';
import SectionPayment from './SectionPayment';
import { useTrialPaymentWithDiscountIntroOffer } from './useTrialPaymentWithDiscountIntroOffer';
import {
  BackgroundStyled,
  BorderStyled,
  ButtonStyled,
  DescriptionStyled,
  DiscountTitleStyled,
  EmojiStyled,
  HighLightPriceStyled,
  InsteadStyled,
  LinkStyled,
  MainContainerStyled,
  OldPriceStyled,
  PartyEmojiStyled,
  PriceStyled,
  PriceBlockStyled,
  PriceBlockTextStyled,
  SpecialCodeStyled,
  SubtitleStyled,
  TitleStyled,
  TotalStyled,
  TotalTextStyled,
} from './styled';

type TrialPaymentWithDiscountIntroOfferProps = {
  goNext: () => void;
};

const TrialPaymentWithDiscountIntroOffer = ({
  goNext,
}: TrialPaymentWithDiscountIntroOfferProps) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { getReport, paymentSucceed, discountOffer } =
    useTrialPaymentWithDiscountIntroOffer(goNext);

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.trialPaymentWithDiscount';

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  const onGetReport = () => {
    getReport();
    setIsPaymentModalOpen((prev) => !prev);
  };

  return (
    <>
      <BackgroundStyled />
      <Header buttonBack={false} />
      <MainContainerStyled>
        <PartyEmojiStyled emoji="party_popper" />
        <DiscountTitleStyled>You’ve got a secret discount!</DiscountTitleStyled>
        <TitleStyled>Start your 7-day trial</TitleStyled>
        <SubtitleStyled>{t('subtitle')}</SubtitleStyled>
        <PriceBlockStyled offset={8} withGradient>
          <SpecialCodeStyled>
            <EmojiStyled emoji="present" />
            {t('specialCode')}
          </SpecialCodeStyled>

          <PriceStyled>-40%</PriceStyled>
        </PriceBlockStyled>
        <PriceBlockStyled>
          <PriceBlockTextStyled>Your cost per month after trial:</PriceBlockTextStyled>
          <PriceBlockTextStyled>
            <OldPriceStyled>{discountOffer?.currencySymbol}39.99</OldPriceStyled>
            <PriceStyled>{discountOffer?.pricesWithCurrencySymbol.priceWithVAT}</PriceStyled>
          </PriceBlockTextStyled>
        </PriceBlockStyled>
        <InsteadStyled>You save $16 every period</InsteadStyled>
        <BorderStyled />
        <TotalStyled>
          <TotalTextStyled>{t('total')}</TotalTextStyled>
          <HighLightPriceStyled>
            {discountOffer?.pricesWithCurrencySymbol.trialPriceWithVAT}
          </HighLightPriceStyled>
        </TotalStyled>

        <ButtonStyled onClick={onGetReport} variant="success" fullWidth>
          Start 7-Day Trial
        </ButtonStyled>
        <DescriptionStyled>
          By continuing you agree that if you don&apos;t cancel prior to the end of the 7-days
          trial, you will automatically be charged {discountOffer?.pricesWithCurrency.priceWithVAT}{' '}
          every 4 weeks until you cancel in settings. Learn more about cancellation and refund
          policy in <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>
          .
        </DescriptionStyled>
      </MainContainerStyled>
      {!!discountOffer && (
        <SectionPayment
          onPaymentSuccess={paymentSucceed}
          isOpen={isPaymentModalOpen}
          offer={discountOffer}
          onClose={() => setIsPaymentModalOpen(false)}
          isTimeUp
        />
      )}
    </>
  );
};

export default TrialPaymentWithDiscountIntroOffer;
