import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import Image from 'core/common/components/Image';
import Typography from 'core/common/components/Typography';

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 96px;
  padding-right: 16px;
  padding-left: 16px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
  background-color: white;
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 8px;
`;

export const TextStyled = styled(Typography)`
  margin-bottom: auto;
`;

export const AspectRatioStyled = styled(AspectRatio)`
  margin-top: 16px;
  margin-right: -16px;
  margin-left: -16px;
`;

export const ImageStyled = styled(Image)`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
`;
