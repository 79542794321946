import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 43px;
    text-align: center;
  }
`;

export const BenefitsListStyled = styled.ul``;

export const BenefitsListItemStyled = styled.li`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const BenefitIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 28px;
    height: 28px;
  }
`;

export const BenefitInfoWrapperStyled = styled.div``;

export const BenefitTitleStyled = styled.h4`
  margin-bottom: 4px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const BenefitDescriptionStyled = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 21px;
  }
`;
