import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 28px 15px 24px;
  background: ${(props) => props.theme.colors.infoLight};
`;

export const ContentStyled = styled.div``;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.typography100};
`;

export const TitleStyled = styled.h4`
  max-width: 230px;
  margin-top: 22px;
  font-size: 18px;
  line-height: 145%;
  color: ${(props) => props.theme.colors.typography100};
`;
