import { useState, ChangeEvent } from 'react';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import { AnswerImageMultipleScreenProps } from 'core/funnel/entities';
import {
  ContainerStyled,
  TitleStyled,
  DescriptionStyled,
  AnswersListStyled,
  AnswersListItemStyled,
  AnswerImageStyled,
  AspectRatioStyled,
  AnswerTextStyled,
  AnswerButtonStyled,
  DefaultInputStyled,
  CustomCheckboxStyled,
  ButtonStyled,
  CheckmarkStyled,
  IconStyled,
} from './styled';

const AnswerImageMultipleScreen = ({
  step,
  content,
  hasButtonBack = true,
  defaultAnswers,
  progressBar,
  goBack,
  onContinue,
}: AnswerImageMultipleScreenProps) => {
  const { title, description, answers, button } = content;

  const [checkedAnswers, setCheckedAnswers] = useState(defaultAnswers);

  const onAnswerClick = (e: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = e.target.value;

    setCheckedAnswers((prev) => {
      if (prev.includes(checkedValue)) {
        return prev.filter((item) => item !== checkedValue);
      }

      return [...prev, checkedValue];
    });

    e.target.blur();
  };

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={goBack} />
      <ContainerStyled>
        {progressBar && <ProgressBar step={step} config={progressBar} onBack={goBack} />}
        <TitleStyled>{title}</TitleStyled>
        {description && <DescriptionStyled>{description}</DescriptionStyled>}
        <AnswersListStyled>
          {answers.map(({ text, value, image }) => (
            <AnswersListItemStyled key={value}>
              <AnswerButtonStyled>
                <DefaultInputStyled
                  type="checkbox"
                  value={value}
                  checked={checkedAnswers.includes(value)}
                  onChange={onAnswerClick}
                />
                <CheckmarkStyled>
                  <IconStyled name="check" width={12} height={12} />
                </CheckmarkStyled>
                <CustomCheckboxStyled />
                <AspectRatioStyled>
                  <AnswerImageStyled src={image.src} alt={image.alt} fill />
                </AspectRatioStyled>
              </AnswerButtonStyled>
              {text && <AnswerTextStyled>{text}</AnswerTextStyled>}
            </AnswersListItemStyled>
          ))}
        </AnswersListStyled>
        <ButtonStyled
          fullWidth
          disabled={!checkedAnswers.length}
          onClick={() => onContinue(checkedAnswers)}
        >
          {button}
        </ButtonStyled>
      </ContainerStyled>
    </>
  );
};

export default AnswerImageMultipleScreen;
