import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import SectionDiscountBanner from 'core/common/components/QuizScreensSections/SectionDiscountBanner';
import UpsellsStepper from 'core/common/components/UpsellsStepper';
import {
  SectionAttributesUnionType,
  SectionType,
  UpsellReadingsPackWithDiscountScreenProps,
} from 'core/funnel/entities';
import {
  ContainerStyled,
  SectionReadingsPackOfferStyled,
  SectionReadingsPackSliderStyled,
} from './styled';

const UpsellReadingsPackWithDiscountScreen = ({
  price,
  sections,
  isLoading,
  trialPrice,
  upsellsSteps,
  resignFormLoaded,
  onGetOffer,
  onSkipOffer,
  paymentError,
  onClearPaymentStatus,
}: UpsellReadingsPackWithDiscountScreenProps) => {
  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.DISCOUNT_BANNER:
        return (
          <SectionDiscountBanner key={section.sectionType + index} content={section.content} />
        );
      case SectionType.READINGS_PACK_SLIDER:
        return (
          <SectionReadingsPackSliderStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.UPSELL_READINGS_PACK_OFFER:
        return (
          <SectionReadingsPackOfferStyled
            key={section.sectionType + index}
            content={section.content}
            price={price}
            isLoading={isLoading}
            trialPrice={trialPrice}
            onGetOffer={onGetOffer}
            onSkipOffer={onSkipOffer}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContainerStyled>
      <Header buttonBack={false} />
      {upsellsSteps && <UpsellsStepper steps={upsellsSteps} />}
      {sections.map(renderSection)}
      <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
      <PaymentDialogFailed
        paymentError={null}
        onTryAgain={onGetOffer}
        onClose={onClearPaymentStatus}
        isOpen={!!paymentError}
      />
    </ContainerStyled>
  );
};

export default UpsellReadingsPackWithDiscountScreen;
