import { FunnelImage } from 'core/funnel/entities';
import {
  ListStyled,
  TitleStyled,
  ListItemStyled,
  ListItemBackgroundStyled,
  AdvantageTitleStyled,
  AdvantageTextStyled,
} from './styled';

type HighlightsProps = {
  title: string;
  list: Array<{
    backgroundImage: FunnelImage;
    title: string;
    text: string;
  }>;
};

const Highlights = ({ title, list }: HighlightsProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <ListStyled>
        {list.map((item) => (
          <ListItemStyled key={item.title}>
            <ListItemBackgroundStyled src={item.backgroundImage.src} alt="" fill />
            <AdvantageTitleStyled>{item.title}</AdvantageTitleStyled>
            <AdvantageTextStyled>{item.text}</AdvantageTextStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
    </>
  );
};

export default Highlights;
