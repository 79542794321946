import { ReactNode } from 'react';
import { CardHeaderStyled } from './styled';

type ReviewsCardHeaderProps = {
  children?: ReactNode;
  className?: string;
};

const ReviewsCardHeader = ({ children, className }: ReviewsCardHeaderProps) => {
  return <CardHeaderStyled className={className}>{children}</CardHeaderStyled>;
};

export default ReviewsCardHeader;
