import { BASE_ROUTES } from 'core/common/constants';
import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';
import { Link, SubscriptionDisclaimer } from './styled';

export type SectionSubscriptionDisclaimerProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['disclaimer'];
  displayPrice: string;
  className?: string;
};

const SectionSubscriptionDisclaimer = ({
  content,
  displayPrice,
  className = '',
}: SectionSubscriptionDisclaimerProps) => {
  const { beforePriceText, afterPriceText } = content;

  const disclaimer = `${beforePriceText} ${displayPrice} ${afterPriceText}`;

  return (
    <SubscriptionDisclaimer className={className}>
      {disclaimer} <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>Subscription policy</Link>
    </SubscriptionDisclaimer>
  );
};

export default SectionSubscriptionDisclaimer;
