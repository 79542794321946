import styled, { keyframes } from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const criticalRealtionshipValue = 60;

const renderProgressAnimation = (min: number, max: number) => {
  return keyframes`
    0% {
      left: ${min}%;
      opacity: 0;
    } 100% {
      left: ${max}%;
      opacity: 1;
    }
  `;
};

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 48px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const CardStyled = styled.div`
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f7f7f7;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 16px;
  }
`;

export const CardTitleStyled = styled.h4`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 135%;
  color: #606060;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ProgressStyled = styled.div`
  position: relative;
  height: 10px;
  margin-bottom: 60px;
  border-radius: 100px;
  background: linear-gradient(90deg, #70c68e 0%, #f6cf53 49.78%, #eb5757 99.99%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const ProgressIndicatorStyled = styled.div<{
  progress: number;
  defaultProgress?: number;
  shouldAnimate?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: ${({ progress }) => progress}%;
  width: 14px;
  height: 14px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  text-align: center;
  animation-name: ${({ shouldAnimate, progress, defaultProgress = 15 }) =>
    shouldAnimate ? renderProgressAnimation(defaultProgress, progress) : ''};
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 2px 4px rgba(22, 19, 60, 0.2));

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  &::before {
    bottom: -12px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: ${({ progress }) => (progress >= criticalRealtionshipValue ? '#F0483F' : '#27ae5f')};
    content: '${({ progress }) => progress + '%'}';

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: 16px;
    }
  }

  &::after {
    bottom: -6px;
    display: inline-block;
    width: 2px;
    height: 6px;
    border-radius: 100px;
    background-color: ${({ progress }) =>
      progress >= criticalRealtionshipValue ? '#F0483F' : '#27ae5f'};
    content: '';
  }
`;

export const ProgressIndicatorCaptionStyled = styled.span`
  position: absolute;
  bottom: -32px;
  left: 50%;
  font-size: 12px;
  font-weight: 600;
  line-height: 135%;
  white-space: nowrap;
  color: #606060;
  transform: translate(-50%, 100%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    bottom: -36px;
    font-size: 14px;
  }
`;

export const DisclaimerWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  background-color: #fcced4;
`;

export const DisclaimerStyled = styled.p`
  font-size: 12px;
  line-height: 135%;
  color: #2f2f2f;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const DisclaimerIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;
`;

export const InfoCardStyled = styled(CardStyled)`
  position: relative;
  display: flex;
  margin-bottom: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ListStyled = styled.ul`
  margin-right: auto;
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const ListIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 12px;
`;

export const InfoWrapperStyled = styled.div``;

export const InfoTitleStyled = styled.p`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 135%;
  color: #606060;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const InfoValueStyled = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const InfoImageWrapperStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 55%;
  margin-top: -20px;
  margin-right: -16px;
  margin-bottom: -20px;
  background-color: #dbe2ea;
`;

export const InfoImageStyled = styled(Image)`
  object-fit: cover;
`;

export const FooterStyled = styled.footer`
  position: sticky;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 120px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
