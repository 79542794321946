import styled from 'styled-components';

export const WrapperStyled = styled.div``;

export const TitleWrapperStyled = styled.p`
  margin-right: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;

export const TitleStyled = styled.span`
  margin-right: 6px;
`;

export const PriceStyled = styled.span`
  padding: 2px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.error};
  background: #d8020a1a;
`;

export const TextStyled = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const OldPriceStyled = styled.span`
  text-decoration: line-through;
`;
