import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import { MultipleAnswersNatalChartScreenProps } from 'core/funnel/entities';
import {
  ButtonBlockSpace,
  Container,
  Content,
  FixedButtonBlock,
  Item,
  List,
  MultipleAnswerButton,
  StyledButton,
  StyledTitle,
  Subtitle,
} from './styled';

const MultipleAnswersNatalChartScreen = ({
  onBack,
  onAnswerClick,
  onContinue,
  chosenList,
  step,
  content,
  totalSteps,
}: MultipleAnswersNatalChartScreenProps) => {
  const isButtonDisabled = !chosenList.length;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} onBack={onBack} totalSteps={totalSteps} />
      <Container>
        <StyledTitle>{content.title}</StyledTitle>
        <Subtitle>{content.subtitle}</Subtitle>
        <Content>
          <List>
            {content.answers.map((answer) => (
              <Item key={answer.text}>
                <MultipleAnswerButton
                  isChosen={chosenList.includes(answer.value)}
                  text={answer.text}
                  onClick={() => onAnswerClick(answer.value)}
                />
              </Item>
            ))}
          </List>
        </Content>
      </Container>
      <ButtonBlockSpace />
      <FixedButtonBlock>
        <StyledButton onClick={onContinue} disabled={isButtonDisabled}>
          {content.next}
        </StyledButton>
      </FixedButtonBlock>
    </>
  );
};

export default MultipleAnswersNatalChartScreen;
