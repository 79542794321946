import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import { PartnersBlockStyled, TitleStyled } from './styled';

type PartnersSectionProps = {
  title: string;
  image: FunnelImage;
};

const PartnersSection = ({ title, image }: PartnersSectionProps) => {
  return (
    <PartnersBlockStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <Image alt={image.alt} src={image.src} height={190} width={325} quality={100} />
    </PartnersBlockStyled>
  );
};

export default PartnersSection;
