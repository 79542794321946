import beebom from 'core/common/assets/images/productOverview/beebom.png';
import bustle from 'core/common/assets/images/productOverview/bustleDark.png';
import hypebae from 'core/common/assets/images/productOverview/hypebae.png';
import lifewire from 'core/common/assets/images/productOverview/lifewire.png';
import newYorkTimes from 'core/common/assets/images/productOverview/new-york-times.png';
import refinery from 'core/common/assets/images/productOverview/refinery.png';
import urbanList from 'core/common/assets/images/productOverview/urban-list.png';
import wellGood from 'core/common/assets/images/productOverview/well-good.png';
import {
  Container,
  Content,
  Image,
  Title,
  Wrapper,
  WrapperRightAlign,
  WrapperWellGood,
} from './styled';

type SectionPartnersProps = {
  title: string;
};

const SectionPartners = ({ title }: SectionPartnersProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <Wrapper>
          <Image src={newYorkTimes} alt="new-york-times" width={212} height={30} />
        </Wrapper>
        <WrapperRightAlign>
          <Image src={lifewire} alt="lifewire" width={90} height={20} />
        </WrapperRightAlign>
        <Wrapper>
          <Image src={refinery} alt="refinery" width={162} height={32} />
        </Wrapper>
        <WrapperRightAlign>
          <Image src={bustle} alt="bustle" width={90} height={24} />
        </WrapperRightAlign>
        <WrapperWellGood>
          <Image src={wellGood} alt="well-good" width={288} height={16} />
        </WrapperWellGood>
        <Image src={urbanList} alt="urban-list" width={80} height={29} />
        <Image src={beebom} alt="beebom" width={112} height={22} />
        <Image src={hypebae} alt="hypebae" width={90} height={24} />
      </Content>
    </Container>
  );
};

export default SectionPartners;
