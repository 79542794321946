import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';
import { salesScreenHeaderHeight } from './SalesScreenHeader/styled';

export const HeaderSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: ${salesScreenHeaderHeight}px;
`;

export const EmailAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 50%;
  text-transform: capitalize;
  background: ${(props) => props.theme.colors.primaryLeo};
`;

export const UserEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
  line-height: 130%;
`;

export const ContainerStyled = styled.div`
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  padding-top: calc(${salesScreenHeaderHeight}px + 24px);
  padding-bottom: 24px;
`;

export const Title = styled(TitleComponent)`
  max-width: 300px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
`;

export const ColoredBackgroundWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #eaeef7;
`;

export const FooterText = styled.p`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;
