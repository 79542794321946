import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import Image from 'core/common/components/Image';

export const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 708px;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
`;

export const StepsImage = styled(Image)`
  width: 100%;
  height: 31px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

const Step = styled.p`
  font-size: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const Step1 = styled(Step)`
  color: ${(props) => props.theme.colors.typography600};
`;

export const Step2 = styled(Step)`
  margin-left: 10px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};
`;

export const Step3 = styled(Step)`
  text-align: right;
  color: ${(props) => props.theme.colors.typography600};
`;

export const SmileEmoji = styled(EmojiComponent)`
  width: 35px;
  height: 35px;
  margin-right: 20px;
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  max-width: 708px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  padding: 6px 12px;
  border-radius: 10px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.typography100};
  background: ${(props) => props.theme.colors.primary400};

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const FireEmoji = styled(EmojiComponent)`
  width: 24px;
  height: 24px;
  margin-left: 5px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 32px;
    height: 32px;
  }
`;

export const BannerContent = styled.div``;

export const BannerTitle = styled.p`
  font-size: 13px;
  font-weight: 700;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const BannerText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 175%;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
    font-weight: 600;
  }
`;
