import styled from 'styled-components';
import Image from '../Image';

export const Badge = styled.p`
  display: flex;
  align-items: center;
  max-width: 225px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.success100};
`;

export const Text = styled.span`
  margin-left: 4px;
  font-size: 10px;
  line-height: 10%;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const StyledImage = styled(Image)`
  flex-shrink: 0;
`;
