import questionInPhone from 'core/common/assets/images/palmistryQuiz/phone_with-question-mark.png';
import Image from 'core/common/components/Image';
import { CloseButtonStyled } from '../ScanProcessPopUps/styled';
import { StyledButton, StyledTitle, Subtitle, Wrapper } from './styled';

export type ScanErrorProps = {
  title: string;
  subtitle: string;
  button: string;
  tryAgain: () => void;
  onClose: () => void;
};

const ScanError = ({ subtitle, button, tryAgain, title, onClose }: ScanErrorProps) => {
  return (
    <>
      <CloseButtonStyled onClick={onClose} />
      <Wrapper>
        <Image
          src={questionInPhone}
          width={126}
          height={194}
          alt="mobile device with question mark on the screen"
        />
        <StyledTitle>{title}</StyledTitle>
        <Subtitle>{subtitle}</Subtitle>
        <StyledButton onClick={tryAgain}>{button}</StyledButton>
      </Wrapper>
    </>
  );
};

export default ScanError;
