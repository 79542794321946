import styled from 'styled-components';
import { ZodiacWheelCircle } from './ZodiacWheelCircle';
import { ZodiacWheelHighlighter } from './ZodiacWheelHighlighter';

export const WheelWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  width: 100%;
  max-width: 300px;
  transform: translate(-50%, -50%);
`;

export const WheelCircleStyled = styled(ZodiacWheelCircle)`
  position: absolute;
  top: 41%;
  left: 50%;
  z-index: 0;
  width: 100px;
  height: 60px;
  transform: translate(-50%, -50%);
`;

export const WheelArrowStyled = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
  width: 0;
  height: 0;
  border-width: 9px 5px 0;
  border-style: solid;
  border-color: #606060 transparent transparent;
  transform: translate(-50%, 0%);
`;

export const WheelStyled = styled.svg<{ angle: number }>`
  fill: rgba(15, 15, 15, 0.3);
  transition: 0.8s transform;
  transform: rotate(${({ angle }) => angle}deg);
`;

export const ZodiacElementStyled = styled.path<{ active: boolean }>`
  transition: all 0.2s 0.55s;
  fill: ${({ active }) => (active ? '#3C1C7B' : 'rgba(15, 15, 15, 0.3)')};
`;

export const WheelHighlighterStyled = styled(ZodiacWheelHighlighter)`
  position: absolute;
  left: 50%;
  width: 100px;
  height: 150px;
  transform: translateX(-50%);
  fill: none;
`;
