import plus from 'core/common/assets/images/heavy-plus.svg';
import Image from 'core/common/components/Image';
import { AspectRatio, ImagesWrapper, StyledPlusImage } from './styled';

interface ZodiacImagesProps {
  image: string;
  partnerImage: string;
}

const ZodiacImages = ({ image, partnerImage }: ZodiacImagesProps) => {
  return (
    <ImagesWrapper>
      <AspectRatio>
        <Image src={image} alt="Zodiac" layout="fill" objectFit="contain" />
      </AspectRatio>
      <StyledPlusImage src={plus} alt="plus" width={48} height={48} />
      <AspectRatio>
        <Image src={partnerImage} alt="Zodiac" layout="fill" objectFit="contain" />
      </AspectRatio>
    </ImagesWrapper>
  );
};

export default ZodiacImages;
