import { SectionSuccessOrderAttributes } from 'core/funnel/entities';
import { ImageStyled, SuccessBadgeStyled } from './styled';

export type SectionSuccessOrderProps = {
  content: SectionSuccessOrderAttributes['content'];
  className?: string;
};

const SectionSuccessOrder = ({ content, className }: SectionSuccessOrderProps) => {
  const { text, icon } = content;
  return (
    <SuccessBadgeStyled className={className}>
      <ImageStyled src={icon.src} alt={icon.alt} width={20} height={20} />
      {text}
    </SuccessBadgeStyled>
  );
};

export default SectionSuccessOrder;
