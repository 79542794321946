import styled, { keyframes } from 'styled-components';
import Image from 'core/common/components/Image';

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Background = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 334px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: white;
  animation-name: ${hiddenText};
  animation-duration: 6s;
`;

export const TextWrapper = styled.div`
  height: 300px;
  margin-bottom: 100px;
  padding: 25px;
`;

export const ImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

export const Text = styled.p`
  font-size: 22px;
  font-weight: 600;
  line-height: 130%;

  span {
    color: ${(props) => props.theme.colors.primary400};
  }
`;
