import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';
import { TextButton } from 'core/common/components/ButtonDeprecated';
import DataSecuredBadgeComponent from 'core/common/components/DataSecuredBadge';
import ImageComponent from 'core/common/components/Image';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 706px;
  margin-right: auto;
  margin-left: auto;
`;

export const Title = styled(TitleComponent)`
  max-width: 328px;
  margin-top: 20px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    line-height: 28px;
  }
`;

export const Subtitle = styled.p`
  max-width: 708px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
  color: #4e4e4e;
`;

export const GenderSection = styled.div`
  margin-bottom: 48px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 60px;
  }
`;

export const GenderSectionTitle = styled.h4`
  margin-bottom: 40px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const GenderList = styled.ul`
  display: flex;
  align-items: flex-end;
  margin-right: -20px;
`;

export const GenderListItem = styled.li`
  padding-right: 20px;
`;

export const MaleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 52px;
  margin-top: -6px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.typography100};
  background: #454895;
`;

export const FemaleButton = styled(MaleButton)`
  background: linear-gradient(125.02deg, #642b73 18.39%, #c6426e 81.83%);
`;

export const Arrow = styled.div`
  display: inline-block;
  padding: 5px;
  border-right: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  border-bottom: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  transform: rotate(-45deg);
`;

export const SignIn = styled(TextButton)`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const Disclaimer = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const Policy = styled(PolicyComponent)`
  z-index: 1;
  max-width: 330px;
  margin: 0;
  font-size: 12px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 330px;
  }

  ${NavLink} {
    font-weight: normal;
  }
`;

export const Image = styled(ImageComponent)``;

export const Address = styled(AddressComponent)`
  z-index: 1;
  margin-top: 6px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 330px;
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

export const AddressUS = styled.p`
  max-width: 330px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const HeaderBackground = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
  }
`;

export const DataSecuredBadge = styled(DataSecuredBadgeComponent)`
  margin-top: 6px;
`;
