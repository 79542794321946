import styled from 'styled-components';
import { ColorVariants } from 'core/theme/entities';

export const InputContainer = styled.input<{ error?: boolean; variant?: ColorVariants }>`
  width: 100%;
  padding: 10px 15px;
  border: 1px solid
    ${(props) =>
      (props.error && props.theme.colors.error) ||
      (props.variant === 'secondary' && props.theme.colors.typography100) ||
      props.theme.colors.typography700};
  border-radius: 25px;
  line-height: 24px;
  color: ${(props) =>
    (props.error && props.theme.colors.error) ||
    (props.variant === 'secondary' && props.theme.colors.typography100) ||
    props.theme.colors.typography700};
  background: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) =>
      (props.variant === 'secondary' && props.theme.colors.typography100) ||
      props.theme.colors.typography700};
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
`;

export const ErrorText = styled.p`
  margin-top: 5px;
  margin-left: 15px;
  font-size: 12px;
  text-align: left;
  color: ${(props) => props.theme.colors.error};
`;
