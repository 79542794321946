import styled from 'styled-components';

export const Wrapper = styled.svg`
  display: block;
  width: 185px;
  height: 185px;
  margin: 0 auto;
  transform: rotate(-90deg) scaleY(-1);
`;

export const InnerWrapper = styled.circle`
  fill: transparent;
`;

export const Progress = styled.circle`
  stroke: ${(props) => props.theme.colors.info};
`;

export const Ring = styled.circle`
  stroke: ${(props) => props.theme.colors.typography100};
`;
