import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import QuizBackground from 'core/common/components/QuizBackground';
import { config } from 'core/config';
import { MentionedInScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import {
  Address,
  AddressUS,
  ButtonsWrapper,
  Container,
  MentionedInBlock,
  MentionedInText,
  NextButton,
  ScreenFooterWrapper,
  Subtitle,
  TestimonialBlock,
  TestimonialText,
  Title,
} from './styled';

const MentionedInScreen = ({ onBack, goNext, content }: MentionedInScreenProps) => {
  const {
    title,
    subtitle,
    quotesImage,
    testimonial,
    testifierImage,
    mentionedInText,
    mentionedInImage,
    next,
  } = content;

  return (
    <>
      <QuizBackground />
      <Header onBack={onBack} />
      <Container>
        <MentionedInBlock>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <TestimonialBlock>
            <Image src={quotesImage.src} height={16} width={16} alt={quotesImage.alt || 'quotes'} />
            <TestimonialText>{testimonial}</TestimonialText>
            <Image
              src={testifierImage.src}
              width={134}
              height={19}
              alt={testifierImage.alt || 'testifier'}
            />
          </TestimonialBlock>
          <MentionedInText>{mentionedInText}</MentionedInText>
          <Image
            src={mentionedInImage.src}
            width={304}
            height={156}
            alt={mentionedInImage.alt || 'mentioned in'}
          />
        </MentionedInBlock>
        <ScreenFooterWrapper>
          <ButtonsWrapper>
            <NextButton onClick={goNext} variant="primary">
              {next}
            </NextButton>
          </ButtonsWrapper>
          <Location>
            <LocationOption option={Locales.US}>
              <AddressUS>{config.addressUS}</AddressUS>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>{<Address />}</LocationOption>
          </Location>
        </ScreenFooterWrapper>
      </Container>
    </>
  );
};

export default MentionedInScreen;
