import image from 'core/common/assets/images/trialPayment/question.svg';
import {
  AnswerText,
  FAQBlock,
  ItemContentBlock,
  ItemImageStyled,
  List,
  ListItem,
  QuestionText,
  Title,
} from './styled';

type FAQProps = {
  title: string;
  list: Array<{ question: string; answer: string }>;
};

const FAQ = ({ title, list }: FAQProps) => {
  return (
    <FAQBlock>
      <Title>{title}</Title>
      <List>
        {list.map(({ question, answer }) => (
          <ListItem key={question}>
            <ItemImageStyled src={image} alt="question" width={24} height={25} />
            <ItemContentBlock>
              <QuestionText>{question}</QuestionText>
              <AnswerText>{answer}</AnswerText>
            </ItemContentBlock>
          </ListItem>
        ))}
      </List>
    </FAQBlock>
  );
};

export default FAQ;
