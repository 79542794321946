import { useCallback, useEffect } from 'react';
import OnboardingIntroOfferScreen from 'core/common/components/QuizScreens/Onboarding';
import { SECOND } from 'core/common/constants/';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { OnboardingScreenAttributes } from 'core/funnel/entities';
import { Languages } from 'core/localization/entities';
import { useChangeLanguage } from 'core/localization/hooks';

type OnboardingIntroOfferProps = {
  id: string;
};

const defaultAnimationDuration = 5 * SECOND;

const OnboardingIntroOffer = ({ id }: OnboardingIntroOfferProps) => {
  const { screenAttributes, next } = useQuizStep(id);
  const changeLanguage = useChangeLanguage();

  const { content, animationDuration } = screenAttributes as OnboardingScreenAttributes;

  const timer = content.list.length * (animationDuration ?? defaultAnimationDuration);

  const redirectToLandings = useCallback(() => {
    changeLanguage(Languages.EN);
    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next]);

  useEffect(() => {
    const redirectWithTimer = setTimeout(redirectToLandings, timer);

    return () => {
      clearTimeout(redirectWithTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToLandings]);

  return <OnboardingIntroOfferScreen {...(screenAttributes as OnboardingScreenAttributes)} />;
};

export default OnboardingIntroOffer;
