import arrow from 'core/common/assets/images/billing/curlyArrow.svg';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { LoadingStatuses } from 'core/common/entities';
import { TrialChoiceScreenProps } from 'core/funnel/entities';
import SectionAnimatedInformation from './SectionAnimatedInformation';
import {
  Background,
  Button,
  Container,
  Note,
  Price,
  PriceBlock,
  Text,
  TextBold,
  TextColored,
  Tip,
  TipText,
} from './styled';

const initialPerPage = 4;
const initialSkeletonsArray = new Array(initialPerPage).fill('-');

const TrialChoiceScreen = ({
  offers,
  selectedOffer,
  loadingStatus,
  content,
  onSubmit,
  changeSelectedOffer,
}: TrialChoiceScreenProps) => {
  const { helpedPeopleText, chooseAmountText, costText, tipText, information, button, note } =
    content;

  const isLoading = loadingStatus !== LoadingStatuses.FULFILLED;

  // TODO think about error and loading pages
  if (loadingStatus === LoadingStatuses.FAILED) {
    return null;
  }

  // TODO more efficient and fix rerender (when user changed new plan)
  const userList = isLoading ? [] : information.list;

  return (
    <>
      <Background />
      <Header buttonBack={false} />
      <Container>
        <Text>{helpedPeopleText}</Text>
        <TextBold>{chooseAmountText}</TextBold>
        <TextColored>{costText}</TextColored>
        <PriceBlock>
          {isLoading
            ? initialSkeletonsArray.map((item, index) => <Price key={index}>{item}</Price>)
            : offers.map((offer) => {
                return (
                  <Price
                    key={offer.offerId}
                    onClick={() => changeSelectedOffer(offer)}
                    isActive={selectedOffer && selectedOffer.offerId === offer.offerId}
                  >
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  </Price>
                );
              })}
        </PriceBlock>
        <Tip>
          <TipText>{tipText}</TipText>
          <Image src={arrow} alt="arrow" width={26} height={33} />
        </Tip>
        <SectionAnimatedInformation list={userList} title={information.title} />
        <Button onClick={onSubmit} disabled={!selectedOffer}>
          {button}
        </Button>
        <Note>{note}</Note>
      </Container>
    </>
  );
};

export default TrialChoiceScreen;
