import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import { AnswerImageScreenProps } from 'core/funnel/entities';
import {
  ContainerStyled,
  TitleStyled,
  DescriptionStyled,
  AnswersListStyled,
  AnswersListItemStyled,
  AnswerImageStyled,
  AspectRatioStyled,
  AnswerTextStyled,
  AnswerButtonStyled,
} from './styled';

const AnswerImageScreen = ({
  step,
  content,
  progressBar,
  hasButtonBack = true,
  goBack,
  onAnswerClick,
}: AnswerImageScreenProps) => {
  const { title, description, answers } = content;

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={goBack} />
      <ContainerStyled>
        {progressBar && <ProgressBar step={step} config={progressBar} onBack={goBack} />}
        <TitleStyled>{title}</TitleStyled>
        {description && <DescriptionStyled>{description}</DescriptionStyled>}
        <AnswersListStyled>
          {answers.map(({ text, value, image }) => (
            <AnswersListItemStyled key={value}>
              <AnswerButtonStyled onClick={() => onAnswerClick(value)}>
                <AspectRatioStyled>
                  <AnswerImageStyled src={image.src} alt={image.alt} fill />
                </AspectRatioStyled>
                {text && <AnswerTextStyled>{text}</AnswerTextStyled>}
              </AnswerButtonStyled>
            </AnswersListItemStyled>
          ))}
        </AnswersListStyled>
      </ContainerStyled>
    </>
  );
};

export default AnswerImageScreen;
