import styled from 'styled-components';
import Emoji from 'core/common/components/Emoji/DeprecatedEmoji';

export const List = styled.ul`
  max-width: 426px;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 12px;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

export const StyledEmoji = styled(Emoji)`
  position: relative;
  top: 2px;
  flex-shrink: 0;
  margin-right: 8px;
`;
