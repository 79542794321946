import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import { UpsellsNatalChartScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import SectionPayment from './SectionPayment';
import { Button, ButtonWrapper, Content, Root, RouteLink } from './styled';

const UpsellsNatalChartScreen = ({
  content,
  onGetReport,
  onSkip,
  isPurchaseLoading,
  selectedProduct,
  selectProduct,
  upsells,
  resignFormLoaded,
  paymentError,
  onClearPaymentStatus,
}: UpsellsNatalChartScreenProps) => {
  const { header, footer, button, skipButton } = content;

  return (
    <Root>
      <Header buttonBack={false} />
      <Content>
        <SectionHeader content={header} />
        <SectionPayment
          selectedProduct={selectedProduct}
          selectProduct={selectProduct}
          upsells={upsells}
        />
        <SectionFooter disclaimer={footer.disclaimer} addressUS={footer.addressUS} />
        <ButtonWrapper>
          <Button onClick={onGetReport} disabled={isPurchaseLoading} loading={isPurchaseLoading}>
            {button}
          </Button>
          <RouteLink disabled={isPurchaseLoading} onClick={onSkip}>
            {skipButton}
          </RouteLink>
        </ButtonWrapper>
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onGetReport}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </Content>
    </Root>
  );
};

export default UpsellsNatalChartScreen;
