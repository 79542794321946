import { Description, InfoContainer, Title } from './styled';

interface ZodiacInfoProps {
  title: string;
  description: string;
}

const ZodiacInfo = ({ title, description }: ZodiacInfoProps) => {
  return (
    <InfoContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </InfoContainer>
  );
};

export default ZodiacInfo;
