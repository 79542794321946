import nebulaApp from 'core/common/assets/images/nebulaApp.png';
import appleLogo from 'core/common/assets/images/quiz/appleLogo.svg';
import greyWing from 'core/common/assets/images/quiz/greyWing.png';
import smallMessage from 'core/common/assets/images/smallMessage.png';
import Image from 'core/common/components/Image';
import { LoadingStatuses } from 'core/common/entities';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { AppDownloadScreenProps } from 'core/funnel/entities';
import {
  BackgroundStyled,
  DownloadButtonStyled,
  FooterStyled,
  FooterTextStyled,
  MobileAppImageStyled,
  IosContainerStyled,
  IosContentStyled,
  RatingAmountStyled,
  RatingBlockStyled,
  RatingBodyStyled,
  RatingContentBlockStyled,
  RatingFooterBlockStyled,
  RatingFooterTextStyled,
  RatingStarsStyled,
  RightWingImageStyled,
  SkipBlockStyled,
  CornerSkipButtonStyled,
  SmallIosTextStyled,
  CornerSkipIosTextStyled,
  AppstoreTextStyled,
} from './styled';

const AppDownloadScreen = ({
  onOpenLink,
  onSkip,
  linkLoadingStatus,
  content,
}: AppDownloadScreenProps) => {
  const isLoading = linkLoadingStatus !== LoadingStatuses.FULFILLED;

  return (
    <>
      <BackgroundStyled />
      <SkipBlockStyled>
        <CornerSkipButtonStyled onClick={onSkip}>{content.skipButtonText}</CornerSkipButtonStyled>
      </SkipBlockStyled>
      <IosContainerStyled>
        <IosContentStyled>
          <Image priority src={smallMessage} fill alt="" />
          <CornerSkipIosTextStyled dangerouslySetInnerHTML={prepareHtml(content.iosSkipText)} />
          <SmallIosTextStyled>{content.iosSmallText}</SmallIosTextStyled>
        </IosContentStyled>
      </IosContainerStyled>
      <FooterStyled>
        <MobileAppImageStyled
          src={nebulaApp}
          width={60}
          height={60}
          quality={100}
          alt="nebula app logo"
        />
        <RatingBlockStyled>
          <Image src={greyWing} height={48} width={22} alt="" />
          <RatingContentBlockStyled>
            <RatingBodyStyled>
              <RatingAmountStyled>{content.ratingAmount}</RatingAmountStyled>
              <RatingStarsStyled />
            </RatingBodyStyled>
            <RatingFooterBlockStyled>
              <RatingFooterTextStyled>{content.ratingFooterText}</RatingFooterTextStyled>
              <Image src={appleLogo} height={14} width={14} alt="apple logo" />
              <AppstoreTextStyled>{content.appStoreText}</AppstoreTextStyled>
            </RatingFooterBlockStyled>
          </RatingContentBlockStyled>
          <RightWingImageStyled src={greyWing} height={48} width={22} alt="" />
        </RatingBlockStyled>
        <FooterTextStyled dangerouslySetInnerHTML={prepareHtml(content.footerText)} />
        <DownloadButtonStyled disabled={isLoading} onClick={onOpenLink}>
          {content.download}
        </DownloadButtonStyled>
      </FooterStyled>
    </>
  );
};

export default AppDownloadScreen;
