import styled from 'styled-components';
import { TextButton } from '../ButtonDeprecated';

export const TextStyled = styled.p<{ height: number; expanded: boolean }>`
  display: -webkit-box;
  max-width: 100%;
  height: ${({ height }) => height}px;
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  transition: height 0.3s ease;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? 'unset' : 2)};
`;

export const ButtonStyled = styled(TextButton)`
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.info};
`;
