import { memo } from 'react';
import WheelPicker from 'core/common/components/WheelPicker';
import { shortMonthsListEn } from 'core/common/constants';
import { DateObject } from '../types';
import { DefaultWrapperStyled, PickerItemStyled } from './styled';

type DefaultDatePickerProps = {
  onChange: (value: Partial<DateObject>) => void;
  className?: string;
  daysList: string[];
  yearsList: string[];
  date: DateObject;
};

export const DefaultDatePicker = memo(
  ({ onChange, className, daysList, yearsList, date }: DefaultDatePickerProps) => {
    const config = [
      {
        pickerProps: {
          name: 'month',
          wheelSize: 35,
          slidesPerView: 3,
          values: shortMonthsListEn,
          initIdx: shortMonthsListEn.indexOf(date.month.substring(0, 3)),
          onChange: (value: string) => {
            onChange({ month: value });
          },
        },
        ContainerStyled: PickerItemStyled,
      },
      {
        pickerProps: {
          name: 'day',
          wheelSize: 35,
          slidesPerView: 3,
          values: daysList,
          speedMultiplier: 1.5,
          initIdx: daysList.indexOf(date.day),
          onChange: (value: string) => onChange({ day: value }),
        },
        ContainerStyled: PickerItemStyled,
      },
      {
        pickerProps: {
          name: 'year',
          wheelSize: 35,
          slidesPerView: 3,
          values: yearsList,
          initIdx: yearsList.indexOf(date.year),
          onChange: (value: string) => onChange({ year: value }),
        },
        ContainerStyled: PickerItemStyled,
      },
    ];

    return (
      <DefaultWrapperStyled className={className}>
        {config.map(({ ContainerStyled, pickerProps }, index) => (
          <ContainerStyled key={index + pickerProps.name}>
            <WheelPicker {...pickerProps} />
          </ContainerStyled>
        ))}
      </DefaultWrapperStyled>
    );
  },
);
