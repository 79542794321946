import MobileStepperLib from '@mui/material/MobileStepper';
import StepLib from '@mui/material/Step';
import StepLabelLib from '@mui/material/StepLabel';
import StepperLib from '@mui/material/Stepper';
import styled from 'styled-components';

export const Stepper = styled(StepperLib)`
  z-index: 1;
  justify-content: center;
  padding: 0 !important;
  background: transparent !important;
`;

export const Title = styled.p<{ currentColor: string }>`
  position: relative;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.currentColor};
`;

export const Step = styled(StepLib)`
  position: relative;
  display: flex;
  padding: 0 !important;
`;

export const StepLabel = styled(StepLabelLib)<{ color: string }>`
  & .MuiStepLabel-iconContainer {
    padding-right: 0;
  }

  & .MuiStepIcon-text {
    fill: ${(props) => props.color};
  }

  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.color};
    border-radius: 50%;
    color: white;
  }

  & .MuiStepIcon-root.MuiStepIcon-active {
    border: none;
    color: ${(props) => props.color};
  }

  & .MuiStepIcon-root.Mui-completed {
    border: none;
    color: ${(props) => props.color};
  }
`;

export const MobileStepper = styled(MobileStepperLib)<{ color: string }>`
  width: 88px;
  padding: 0 !important;
  background: none !important;

  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.color};
  }

  & .MuiMobileStepper-progress {
    width: 100%;
    height: 6px;
    background: ${(props) => props.theme.colors.primary200};
  }
`;

export const Circle = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary500};
`;
