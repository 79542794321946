import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';

export const ContainerStyled = styled.div``;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
`;
