import AnswerNatalChartScreen from 'core/common/components/QuizScreens/AnswerNatalChart';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerNatalChartScreenAttributes } from 'core/funnel/entities';

type AnswerNatalChartProps = {
  id: string;
};

const AnswerNatalChart = ({ id }: AnswerNatalChartProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerNatalChartScreenAttributes;

  const onAnswerClick = async (value: string) => {
    updateUserPreferences(dataKey, value);

    await next({ value });
  };

  return (
    <AnswerNatalChartScreen
      onBack={back}
      onAnswerClick={onAnswerClick}
      step={step}
      {...(screenAttributes as AnswerNatalChartScreenAttributes)}
    />
  );
};

export default AnswerNatalChart;
