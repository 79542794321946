import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 706px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleStyled = styled(Title)`
  margin-top: 40px;
  font-size: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ContentStyled = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const IconImageStyled = styled(ImageComponent)`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 64px;
    height: 64px;
  }
`;

export const BackgroundImageStyled = styled(ImageComponent)`
  position: absolute;
  bottom: 0;
  left: 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const BackgroundImageDesktopStyled = styled(BackgroundImageStyled)`
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
  }
`;

const desktopItemWidthPx = 150;
const desktopItemMarginPx = 24;

export const ListStyled = styled.ul<{ itemsInRow?: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 280px;
  margin-right: auto;
  margin-bottom: 72px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: ${(props) =>
      props.itemsInRow
        ? `${props.itemsInRow * (desktopItemMarginPx + desktopItemWidthPx)}px`
        : 'inherit'};
  }
`;

export const ListItemStyled = styled.li`
  width: 120px;
  height: 120px;
  margin-right: 12px;
  margin-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: ${desktopItemWidthPx}px;
    height: 150px;
    margin-right: ${desktopItemMarginPx}px;
    margin-bottom: ${desktopItemMarginPx}px;
  }
`;
