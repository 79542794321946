import styled from 'styled-components';
import Image from 'core/common/components/Image';
import { InfoButton } from 'core/common/components/Quiz/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 450px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ImageMobile = styled(Image)`
  align-self: center;
  margin-bottom: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ImageDesktop = styled(Image)`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: block;
    align-self: center;
    margin-bottom: 50px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 145%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 24px;
  }
`;

export const Text = styled.p`
  margin-bottom: 20px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 40px;
    font-size: 18px;
  }
`;

export const Button = styled(InfoButton)`
  width: 100%;
  max-width: 330px;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 400px;
    margin-top: 0;
  }
`;
