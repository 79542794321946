import styled, { keyframes } from 'styled-components';
import { ReactComponent as DesktopIcon } from 'core/common/assets/images/palmistryQuiz/palm_scan_process_desktop.svg';
import { ReactComponent as MobileIcon } from 'core/common/assets/images/palmistryQuiz/palm_scan_process_mobile.svg';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  max-width: 328px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 135%;
  text-align: left;
`;

export const Subtitle = styled.h4`
  font-weight: 400;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography900};
`;

export const Image = styled(ImageComponent)`
  border-radius: 12px;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
`;

const moveUp = keyframes`
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(-90px);
  }
`;

const moveUpMobile = keyframes`
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(-115px);
  }
`;

export const DesktopScanIcon = styled(DesktopIcon)`
  position: absolute;
  top: -6px;
  left: 0;
  z-index: 1;

  #path2_group {
    animation: ${moveUp} 3s infinite alternate;
  }
`;

export const MobileScanIcon = styled(MobileIcon)`
  position: absolute;
  top: -6px;
  left: 0;
  z-index: 1;

  #path1_group {
    animation: ${moveUpMobile} 3s infinite alternate;
  }
`;

export const LineOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const StyledSvg = styled.svg`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CircleSvg = styled.svg`
  width: 20px;
  height: 20px;
`;

export const InnerCircle = styled.circle`
  fill: ${(props) => props.theme.colors.infoLight};
`;

export const OuterCircle = styled.circle`
  fill: ${(props) => props.theme.colors.typography100};
  opacity: 0.3;
`;

export const StyledPath = styled.path<{ color: string }>`
  fill: none;
  stroke: ${(props) => props.color};
  stroke-width: 4;
  stroke-opacity: 0.8;
  stroke-dasharray: 0;
  stroke-dashoffset: 400;
`;
