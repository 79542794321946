import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address/Address';
import { InfoButton } from 'core/common/components/Quiz/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MentionedInBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 328px;
  margin-top: 12px;
  padding: 28px 12px;
  border: 1px solid rgba(153, 116, 246, 0.5);
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.5);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 40px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  color: ${({ theme }) => theme.colors.info};
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography800};
`;

export const TestimonialBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 24px 21px 28px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const TestimonialText = styled.p`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const MentionedInText = styled.span`
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.typography800};
`;

export const ScreenFooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: 24px;
  padding-top: 8px;
  background-color: #fff;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
    padding-top: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
`;

export const NextButton = styled(InfoButton)`
  flex-grow: 1;
  height: 50px;
  margin-bottom: 0;
`;

export const Address = styled(AddressComponent)`
  margin-top: 6px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 330px;
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

export const AddressUS = styled.p`
  max-width: 330px;
  margin: 8px auto 5px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;
