import { LoadingStatuses } from 'core/common/entities';
import { ScanScreenProps } from 'core/funnel/entities';
import ScanError from '../../components/ScanError';
import ScanLoading from '../../components/ScanLoading';
import ScanMaker from '../../components/ScanMaker';
import useScanProcessPopUps, { ScanProcessPopUp } from './useScanProcessPopUps';
import { ContainerStyled, ModalStyled } from './styled';

type ScanProcessPopUpsProps = {
  isOpen: boolean;
  content: ScanScreenProps['content'];
  onClosePopUp: () => void;
  goNext: () => void;
  onSkipError: () => void;
};

const ScanProcessPopUps = ({
  isOpen,
  goNext,
  content,
  onClosePopUp,
  onSkipError,
}: ScanProcessPopUpsProps) => {
  const {
    detectPalm,
    loadingStatus,
    scanProcessStep,
    setScanProcessStep,
    tryAgain,
    closePopupQuestion,
  } = useScanProcessPopUps();

  const { loading, scanMaker, error } = content;

  const finishLoading = () => {
    if (loadingStatus === LoadingStatuses.FULFILLED) {
      goNext();
      return;
    }

    onSkipError();
  };

  const onClose = () => {
    onClosePopUp();
    setScanProcessStep(ScanProcessPopUp.SCAN_MAKER);
  };

  const onClosePopupQuestion = (data: { popupName: string; answer: string }) => {
    closePopupQuestion(data);
  };

  const getPopUp = () => {
    switch (scanProcessStep) {
      case ScanProcessPopUp.SCAN_MAKER:
        return (
          <ScanMaker
            updatePopUp={setScanProcessStep}
            button={scanMaker.button}
            detectPalm={detectPalm}
            onClose={onClose}
          />
        );
      case ScanProcessPopUp.SCAN_LOADING:
        return (
          <ScanLoading
            onFinished={finishLoading}
            title={loading.title}
            list={loading.list}
            question={loading.question}
            onClose={onClose}
            onClosePopupQuestion={onClosePopupQuestion}
          />
        );
      case ScanProcessPopUp.SCAN_ERROR:
        return (
          <ScanError
            title={error.title}
            subtitle={error.subtitle}
            button={error.button}
            tryAgain={tryAgain}
            onClose={onClose}
          />
        );
      default:
        return (
          <ScanMaker
            updatePopUp={setScanProcessStep}
            button={scanMaker.button}
            detectPalm={detectPalm}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <ModalStyled open={isOpen} onClose={onClosePopUp}>
      <ContainerStyled>{getPopUp()}</ContainerStyled>
    </ModalStyled>
  );
};

export default ScanProcessPopUps;
