import { capitalize } from 'core/common/utils/capitalize';
import { formatTraumaScore } from 'core/common/utils/formatTraumaScore';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import { ListItemContent } from '../ProfileShort';
import {
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  ListItemTitleStyled,
  ListItemValueStyled,
  ListItemTextWrapperStyled,
  ListItemValueTraumaStyled,
} from './styled';

type UserDataProps = {
  birthDate?: ListItemContent;
  traumaListItem: ListItemContent & { image: FunnelImage };
  gender: ListItemContent;
  traumaScore: number;
};

const UserData = ({ birthDate, traumaListItem, gender, traumaScore }: UserDataProps) => {
  const traumaValue = formatTraumaScore(traumaScore);

  return (
    <ListStyled>
      <ListItemStyled>
        <ListIconStyled name={traumaListItem.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(traumaListItem.title)} />
          <ListItemValueTraumaStyled
            dangerouslySetInnerHTML={prepareHtml(`${traumaListItem.value} ${traumaValue}/10`)}
          />
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      <ListItemStyled>
        <ListIconStyled name={gender.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(gender.title)} />
          <ListItemValueStyled dangerouslySetInnerHTML={prepareHtml(capitalize(gender.value))} />
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      {birthDate && (
        <ListItemStyled>
          <ListIconStyled name={birthDate.icon} width={24} height={24} />
          <ListItemTextWrapperStyled>
            <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(birthDate.title)} />
            <ListItemValueStyled dangerouslySetInnerHTML={prepareHtml(birthDate.value)} />
          </ListItemTextWrapperStyled>
        </ListItemStyled>
      )}
    </ListStyled>
  );
};

export default UserData;
