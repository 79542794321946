import { SectionUpsellConsultationGuaranteeAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  ImageStyled,
  TextStyled,
  ListStyled,
  ListItemStyled,
  FooterTextStyled,
} from './styled';

export type SectionUpsellConsultationGuaranteeProps = {
  content: SectionUpsellConsultationGuaranteeAttributes['content'];
  className?: string;
};

const SectionUpsellConsultationGuarantee = ({
  content,
  className,
}: SectionUpsellConsultationGuaranteeProps) => {
  const { title, icon, topListText, list, footerText } = content;

  return (
    <WrapperStyled className={className}>
      <ImageStyled src={icon.src} alt={icon.alt} width={56} height={56} />
      <TitleStyled>{title}</TitleStyled>
      <TextStyled>{topListText}</TextStyled>
      <ListStyled>
        {list.map((item) => (
          <ListItemStyled key={item}>{item}</ListItemStyled>
        ))}
      </ListStyled>
      <FooterTextStyled>{footerText}</FooterTextStyled>
    </WrapperStyled>
  );
};

export default SectionUpsellConsultationGuarantee;
