import ScanPreviewScreen from 'core/common/components/QuizScreens/ScanPreview';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ScanPreviewScreenAttributes } from 'core/funnel/entities';

export type ScanPreviewProps = {
  id: string;
};

const ScanPreview = ({ id }: ScanPreviewProps) => {
  const { next, back, step, screenAttributes } = useQuizStep(id);

  const tryScan = () => next();

  return (
    <ScanPreviewScreen
      step={step}
      tryScan={tryScan}
      onBack={back}
      {...(screenAttributes as ScanPreviewScreenAttributes)}
    />
  );
};

export default ScanPreview;
