import { useEffect } from 'react';
import UpsellReadingsPackWithDiscountScreen from 'core/common/components/QuizScreens/UpsellReadingsPackWithDiscount';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellReadingsPackWithDiscountScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellReadingsPackWithDiscountSingleProps = {
  id: string;
};

const UpsellReadingsPackWithDiscountSingle = ({
  id,
}: UpsellReadingsPackWithDiscountSingleProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountUltraPackOffer, ultraPackOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getOffer = () => {
    if (!discountUltraPackOffer) return;

    analyticsService.readingsPackUpsellScreenClicked();
    recurringPaymentRequest(discountUltraPackOffer.offerId);
  };

  const skip = () => {
    next();
  };

  useEffect(() => {
    analyticsService.readingsPackUpsellWithDiscountScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.readingsPackUpsellWithDiscountGetOffer(
        discountUltraPackOffer ? discountUltraPackOffer.offerId : '',
      );
      next();
    }
  }, [paymentProcessStep]);

  const offerPrice = ultraPackOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '';
  const offerTrialPrice = discountUltraPackOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '';

  return (
    <UpsellReadingsPackWithDiscountScreen
      price={offerPrice}
      isLoading={isPurchaseLoading}
      trialPrice={offerTrialPrice}
      onGetOffer={getOffer}
      onSkipOffer={skip}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellReadingsPackWithDiscountScreenAttributes)}
    />
  );
};

export default UpsellReadingsPackWithDiscountSingle;
