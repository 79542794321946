import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 706px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 16px;
`;

export const StyledTitle = styled(Title)`
  margin-top: 20px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    line-height: 28px;
  }
`;

export const Subtitle = styled.p`
  max-width: 708px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
`;

export const GenderSection = styled.div`
  margin-top: 60px;
  margin-bottom: 32px;
`;

export const GenderList = styled.ul`
  display: flex;
  align-items: flex-end;
  margin-right: -20px;
`;

export const GenderListItem = styled.li`
  padding-right: 20px;
`;

export const MaleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 52px;
  margin-top: -6px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.typography100};
  background: #454895;
`;

export const FemaleButton = styled(MaleButton)`
  background: linear-gradient(125.02deg, #642b73 18.39%, #c6426e 81.83%);
`;

export const Arrow = styled.div`
  display: inline-block;
  padding: 5px;
  border-right: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  border-bottom: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  transform: rotate(-45deg);
`;

export const Image = styled(ImageComponent)``;

export const FestiveBackgroundMobile = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const FestiveLeftImage = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
`;

export const FestiveRightImage = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
  }
`;

export const HeaderBackground = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
  }
`;
