import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
`;

export const Title = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 44px;
`;

export const ImageContainer = styled.div`
  margin-bottom: 20px;
`;

export const ImageBackgroundContainer = styled.div`
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 666px;
  transform: translateX(-50%);
`;

export const List = styled.ul`
  max-width: 262px;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Emoji = styled(EmojiComponent)`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
`;

export const Text = styled.p`
  margin-left: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`;

export const Price = styled.p`
  margin-bottom: 10px;
`;

export const Highlight = styled.span`
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary500};
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.tertiary500};
  box-shadow: 0 4px 4px 0 #00000040;
`;
