import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';
import { PlanVariant } from './types';

const pinkGradient = 'linear-gradient(180deg, #642b73 0%, #c6426e 100%)';
const bgColor = '#fafafa';

export const LabelStyled = styled.span`
  font-size: 14px;
`;

export const ContentWrapperStyled = styled.div`
  margin-top: 12px;
  border-radius: 16px;
  overflow: hidden;
  background: ${bgColor};
`;

export const KeyPointsStyled = styled.ul`
  margin-top: 12px;
  padding: 12px;
  border-radius: 16px;
`;

export const KeyPointItemStyled = styled.li<{ variant?: PlanVariant }>`
  display: flex;
  align-items: center;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    fill: ${({ variant = 'default', theme }) =>
      variant === 'gradient' ? theme.colors.white : '#2F2F2F'};
  }
`;

export const KeyPointImageStyled = styled(Icon)`
  margin-right: 8px;
`;

export const WrapperStyled = styled.div<{ variant?: PlanVariant }>`
  width: 50%;
  color: ${({ theme, variant }) =>
    variant === 'default' ? theme.colors.typography900 : theme.colors.typography100};

  &:not(:last-child) {
    margin-right: 16px;
  }

  ${LabelStyled} {
    font-weight: ${({ variant }) => (variant === 'default' ? 600 : 700)};
    color: ${({ theme, variant }) =>
      variant === 'default' ? theme.colors.typography500 : '#D84188'};
  }

  ${ContentWrapperStyled} {
    background: ${({ variant }) => (variant === 'default' ? bgColor : pinkGradient)};
  }

  ${KeyPointsStyled} {
    background: ${({ variant }) => (variant === 'default' ? bgColor : pinkGradient)};
  }
`;

export const TitleStyled = styled.h5`
  flex-grow: 2;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 12px;
`;

export const IconStyled = styled(Image)`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const AspectRatioImageWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  padding-top: 98%;
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;
