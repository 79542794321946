import ReviewScreen from 'core/common/components/QuizScreens/Review';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ReviewScreenAttributes } from 'core/funnel/entities';

type ReviewProps = {
  id: string;
};

const Review = ({ id }: ReviewProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <ReviewScreen onBack={back} goNext={next} {...(screenAttributes as ReviewScreenAttributes)} />
  );
};

export default Review;
