import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { GoalSetScreenAttributes } from 'core/funnel/entities';
import GoalSetScreen from '../../QuizScreens/GoalSet';

export type GoalSetProps = {
  id: string;
};

const GoalSet = ({ id }: GoalSetProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <GoalSetScreen onBack={back} goNext={next} {...(screenAttributes as GoalSetScreenAttributes)} />
  );
};

export default GoalSet;
