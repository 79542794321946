import palm from 'core/common/assets/images/trialPayment/palmistry/palm_analysis.png';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ContentStyled, IconStyled, TitleStyled, WrapperStyled } from './styled';

type PalmistryHeaderInfoProps = {
  title: string;
};

const PalmistryHeaderInfo = ({ title }: PalmistryHeaderInfoProps) => {
  return (
    <WrapperStyled>
      <ContentStyled>
        <IconStyled name="nebula" width={170} height={24} />
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      </ContentStyled>
      <Image src={palm} alt="palm analysis" width={81} height={125} />
    </WrapperStyled>
  );
};

export default PalmistryHeaderInfo;
