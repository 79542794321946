import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Modal from 'core/common/components/Modal';

export const ModalStyled = styled(Modal)`
  position: relative;
  width: 100%;
  max-width: 90%;
  padding: 40px 15px 32px;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.typography900};
  background: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 520px;
    padding: 40px 45px 56px;
    color: ${(props) => props.theme.colors.typography100};
    background: ${(props) => props.theme.colors.infoLight};
  }
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  right: 20px;

  ${CrossIconStyled} {
    fill: ${({ theme }) => theme.colors.typography900};

    @media ${({ theme }) => theme.breakpoints.desktop} {
      fill: ${({ theme }) => theme.colors.typography100};
    }
  }
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyled = styled.h3`
  margin-bottom: 8px;
  font-size: 18px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const TextStyled = styled.p`
  margin-bottom: 16px;
  font-size: 12px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const CaptionStyled = styled(TextStyled)`
  margin-bottom: 32px;
`;

export const TopImageStyled = styled(Image)`
  margin-bottom: 16px;
  object-fit: cover;
`;

export const MainImageStyled = styled(Image)`
  object-fit: cover;
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);
  }
`;

export const AspectRatioStyled = styled(AspectRatio)`
  width: 100%;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 80%;
  }
`;
