import { useCallback } from 'react';
import { useDispatch, useSelector } from 'core/common/hooks';
import { fetchHumanDesignReport, getHumanDesignReport } from 'core/human-design/store';

export const useHumanDesign = () => {
  const report = useSelector(getHumanDesignReport);
  const dispatch = useDispatch();

  const doFetchHumanDesignReport = useCallback(() => {
    dispatch(fetchHumanDesignReport());
  }, [dispatch]);

  return { report, fetchHumanDesignReport: doFetchHumanDesignReport };
};
