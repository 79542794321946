import { scroller as scroll, animateScroll } from 'react-scroll';

export const isElementOnTop = (el: HTMLElement | null): boolean => {
  if (!el) {
    // eslint-disable-next-line no-console
    console.warn("The element doesn't exist");
    return false;
  }
  const rect = el.getBoundingClientRect();

  return rect.top <= 0;
};

export const scrollToBlock = (blockID: string, offset = 0): void => {
  scroll.scrollTo(blockID, {
    duration: 500,
    delay: 100,
    smooth: true,
    offset,
  });
};

export const scrollToBottom = (options?: Record<string, unknown>): void => {
  animateScroll.scrollToBottom(options);
};
