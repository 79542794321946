import { memo } from 'react';
import { Time } from 'core/common/entities';
import { CylinderTimePicker } from './CylinderTimePicker';
import { DefaultTimePicker } from './DefaultTimePicker';
import { PickerVariants } from './types';

export type WheelTimePickerProps = {
  onChange: (value: Partial<Time>) => void;
  className?: string;
  variant: PickerVariants;
  time: Time;
};

export const WheelTimePicker = memo(
  ({ onChange, className, variant, time }: WheelTimePickerProps) => {
    if (variant === PickerVariants.DEFAULT) {
      return <DefaultTimePicker onChange={onChange} className={className} time={time} />;
    }

    return <CylinderTimePicker onChange={onChange} className={className} time={time} />;
  },
);
