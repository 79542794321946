import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BASE_ROUTES } from 'core/common/constants';
import { Offer } from 'core/offers/entities';
import {
  Button,
  Description,
  PurchaseBlock,
  TotalBlock,
  TotalText,
  Bold,
  Link,
  ButtonWrapper,
} from './styled';

export type PurchaseSectionProps = {
  id?: string;
  isTimeUp: boolean;
  onGetReport: () => void;
  offer: Offer;
  timeUpOffer: Offer;
  title: string;
  button: string;
};

const PurchaseSection = forwardRef<HTMLDivElement, PurchaseSectionProps>(
  ({ id, isTimeUp, onGetReport, offer, timeUpOffer, title, button }: PurchaseSectionProps, ref) => {
    const tKeyPrefix = 'natal-chart.billingScreen';

    const { t } = useTranslation('translation', {
      keyPrefix: tKeyPrefix,
    });

    return (
      <PurchaseBlock id={id}>
        <TotalBlock>
          <TotalText>{title}</TotalText>
          <TotalText>
            {isTimeUp
              ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
              : offer.pricesWithCurrencySymbol.trialPriceWithVAT}
          </TotalText>
        </TotalBlock>
        <ButtonWrapper ref={ref}>
          <Button onClick={onGetReport}>{button}</Button>
        </ButtonWrapper>
        {isTimeUp ? (
          <Description>
            <Trans
              i18nKey={`${tKeyPrefix}.description`}
              values={{ price: offer.pricesWithCurrencySymbol.priceWithVAT }}
            />{' '}
            <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</Link>.
          </Description>
        ) : (
          <Description>
            <Trans
              i18nKey={`${tKeyPrefix}.trialDescription`}
              values={{
                price: offer.pricesWithCurrencySymbol.priceWithVAT,
                trialPrice: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
              }}
              components={{ bold: <Bold /> }}
            />{' '}
            <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</Link>.
          </Description>
        )}
      </PurchaseBlock>
    );
  },
);

export default PurchaseSection;
