import Address from 'core/common/components/Address';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import PolicyExtended from 'core/common/components/PolicyExtended';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { GenderV3ScreenProps as GenderV3Props } from 'core/funnel/entities';
import { Locales, notPaidUtmSources, paidUtmSources, Regions } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  ContainerStyled,
  AspectRatioStyled,
  TitleStyled,
  SubtitleStyled,
  SelectGenderCaptionStyled,
  ButtonsWrapperStyled,
  MaleButtonStyled,
  FemaleButtonStyled,
  TextButtonStyled,
  PolicyStyled,
  FooterStyled,
  GenderIconStyled,
} from './styled';

const GenderV3 = ({
  content,
  hasButtonBack,
  isPolicyAccepted,
  displayPolicyError,
  onPolicyClick,
  onGoBack,
  onSelectGender,
}: GenderV3Props) => {
  const {
    image,
    title,
    subtitle,
    selectGenderCaption,
    maleButton,
    femaleButton,
    otherGenderButton,
  } = content;

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={onGoBack} />
      <ContainerStyled>
        <AspectRatioStyled width={343} height={180}>
          <Image src={image.src} alt={image.alt} fill />
        </AspectRatioStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        <SelectGenderCaptionStyled dangerouslySetInnerHTML={prepareHtml(selectGenderCaption)} />
        <ButtonsWrapperStyled>
          <MaleButtonStyled onClick={() => onSelectGender(maleButton.value)}>
            {maleButton.icon && (
              <GenderIconStyled
                src={maleButton.icon.src}
                alt={maleButton.icon.alt}
                width={22}
                height={22}
                priority
              />
            )}
            {maleButton.text}
          </MaleButtonStyled>
          <FemaleButtonStyled onClick={() => onSelectGender(femaleButton.value)}>
            {femaleButton.icon && (
              <GenderIconStyled
                src={femaleButton.icon.src}
                alt={femaleButton.icon.alt}
                width={22}
                height={22}
                priority
              />
            )}
            {femaleButton.text}
          </FemaleButtonStyled>
        </ButtonsWrapperStyled>
        <TextButtonStyled onClick={() => onSelectGender(otherGenderButton.value)}>
          {otherGenderButton.text}
        </TextButtonStyled>
        <FooterStyled>
          <MediaSourceVariant name={paidUtmSources}>
            <LocationRegion>
              <LocationRegionOption option={Regions.CALIFORNIA}>
                <PolicyExtended
                  onPolicyClick={onPolicyClick}
                  isPolicyAccepted={isPolicyAccepted}
                  withoutCheckbox
                />
              </LocationRegionOption>
              <LocationRegionOption option={Regions.OTHER}>
                <PolicyStyled />
              </LocationRegionOption>
            </LocationRegion>
          </MediaSourceVariant>
          <MediaSourceVariant name={notPaidUtmSources}>
            <Location>
              <LocationOption option={Locales.US}>
                <PolicyExtended
                  onPolicyClick={onPolicyClick}
                  isPolicyAccepted={isPolicyAccepted}
                  isError={displayPolicyError}
                />
              </LocationOption>
            </Location>
          </MediaSourceVariant>
          <Address />
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default GenderV3;
