import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import SectionDiscountModalV2 from 'core/common/components/QuizScreensSections/SectionDiscountModalV2';
import SectionSuccessOrder from 'core/common/components/QuizScreensSections/SectionSuccessOrder';
import { ReviewsCardAvatar } from 'core/common/components/ReviewsCards';
import UpsellsStepper from 'core/common/components/UpsellsStepper';
import {
  SectionAttributesUnionType,
  SectionType,
  UpsellConsultationScreenProps,
} from 'core/funnel/entities';
import { useObservers } from './useObservers';
import {
  ContainerStyled,
  ReviewsCarouselWrapperStyled,
  SectionExpertsStyled,
  SectionFaqV2Styled,
  SectionReviewsCarouselStyled,
  SectionUpsellConsultationBenefitsStyled,
  SectionUpsellConsultationGuaranteeStyled,
  SectionUpsellConsultationInsightsStyled,
  SectionUpsellConsultationOfferStyled,
  SectionUpsellGuidanceStyled,
  OfferWrapperStyled,
} from './styled';

const UpsellConsultationScreen = ({
  price,
  sections,
  isLoading,
  trialPrice,
  upsellsSteps,
  resignFormLoaded,
  displayDiscountModal,
  onGetOffer,
  onSkipOffer,
  onGetSecretDiscount,
  onCloseDiscountModal,
  paymentError,
  onClearPaymentStatus,
}: UpsellConsultationScreenProps) => {
  const { setOffersRef, firstOfferRef, secondOfferRef, reviewCarouselRef } = useObservers();

  const shouldDisplayFloatingPurchaseButton = !firstOfferRef.inView && !secondOfferRef.inView;

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.SUCCESS_ORDER:
        return <SectionSuccessOrder key={section.sectionType + index} content={section.content} />;
      case SectionType.UPSELL_GUIDANCE:
        return (
          <SectionUpsellGuidanceStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.UPSELL_CONSULTATION_BENEFITS:
        return (
          <SectionUpsellConsultationBenefitsStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.UPSELL_CONSULTATION_INSIGHTS:
        return (
          <SectionUpsellConsultationInsightsStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.UPSELL_CONSULTATION_OFFER:
        return (
          <OfferWrapperStyled ref={setOffersRef}>
            <SectionUpsellConsultationOfferStyled
              key={section.sectionType + index}
              content={section.content}
              isLoading={isLoading}
              price={price}
              trialPrice={trialPrice}
              isFloatingButtonVisible={shouldDisplayFloatingPurchaseButton}
              onGetOffer={onGetOffer}
              onSkipOffer={onSkipOffer}
            />
          </OfferWrapperStyled>
        );
      case SectionType.EXPERTS:
        return <SectionExpertsStyled key={section.sectionType + index} content={section.content} />;
      case SectionType.REVIEWS_CAROUSEL:
        return (
          <ReviewsCarouselWrapperStyled
            key={section.sectionType + index}
            ref={reviewCarouselRef.intersection}
          >
            <SectionReviewsCarouselStyled
              content={section.content}
              shouldPlayAnimation={reviewCarouselRef.inView}
              renderReviewCard={({ date, review, userName, userAvatar }) => {
                if (!userAvatar) {
                  return null;
                }

                return (
                  <ReviewsCardAvatar
                    date={date}
                    review={review}
                    userName={userName}
                    userAvatar={userAvatar}
                  />
                );
              }}
            />
          </ReviewsCarouselWrapperStyled>
        );
      case SectionType.FAQ_V2:
        return <SectionFaqV2Styled key={section.sectionType + index} content={section.content} />;
      case SectionType.UPSELL_CONSULTATION_GUARANTEE:
        return (
          <SectionUpsellConsultationGuaranteeStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.DISCOUNT_MODAL_V2:
        return (
          <SectionDiscountModalV2
            key={section.sectionType + index}
            content={section.content}
            isOpen={displayDiscountModal}
            onClose={onCloseDiscountModal}
            onConfirm={onGetSecretDiscount}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContainerStyled>
      <Header buttonBack={false} />
      {upsellsSteps && <UpsellsStepper steps={upsellsSteps} />}
      {sections.map(renderSection)}
      <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
      <PaymentDialogFailed
        paymentError={null}
        onTryAgain={onGetOffer}
        onClose={onClearPaymentStatus}
        isOpen={!!paymentError}
      />
    </ContainerStyled>
  );
};

export default UpsellConsultationScreen;
