import { ChangeEvent, useState } from 'react';
import Checkbox from 'core/common/components/Checkbox';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { StyledContainerMain } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import Select from 'core/common/components/Select/Select';
import { hoursList, minutesList, partOfDay } from 'core/common/constants';
import { TimeNatalChartScreenProps } from 'core/funnel/entities';
import { Button, Content, SelectContainer, Skip, StyledText, StyledTitle } from './styled';

const TimeNatalChartScreen = ({
  onContinue,
  onSkip,
  onBack,
  setTime,
  time,
  step,
  totalSteps,
  content,
  hasCheckbox,
  progressBarTitle,
}: TimeNatalChartScreenProps) => {
  const [checked, setChecked] = useState(false);

  const { title, text, next, label, backgroundImage, skip } = content;

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTime({
      ...time,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <StyledContainerMain>
        <Content>
          <StyledTitle>{title}</StyledTitle>
          <StyledText>{text}</StyledText>
          <SelectContainer>
            <Select
              options={hoursList}
              name="hours"
              value={time.hours}
              onChange={handleTimeChange}
            />
            <Select
              options={minutesList}
              name="minutes"
              value={time.minutes}
              onChange={handleTimeChange}
            />
            <Select options={partOfDay} name="part" value={time.part} onChange={handleTimeChange} />
          </SelectContainer>
          {hasCheckbox && (
            <Checkbox
              name="checkbox"
              label={label}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          )}
        </Content>
      </StyledContainerMain>
      <Button onClick={onContinue}>{next}</Button>
      <Skip onClick={onSkip}>{skip ?? `I don’t know ${String.fromCharCode(62)}`}</Skip>
    </>
  );
};

export default TimeNatalChartScreen;
