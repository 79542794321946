import { SUGGESTED_DOMAINS } from 'core/common/constants';
import { Container, Item, List } from './styled';

type EmailSuggestionsListProps = {
  input: string;
  onSelect: (value: string) => void;
};

const EmailSuggestionsList = ({ input, onSelect }: EmailSuggestionsListProps) => {
  if (!input.length || input.includes('@')) {
    return null;
  }

  const handleClick = (value: string) => () => onSelect(value);

  return (
    <Container>
      <List>
        {SUGGESTED_DOMAINS.map((domain) => (
          <Item key={domain} onClick={handleClick(input + domain)}>
            {input + domain}
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default EmailSuggestionsList;
