import PhoneNumberScreen from 'core/common/components/QuizScreens/PhoneNumber';
import { useQuizStep } from 'core/funnel/contexts';
import { PhoneNumberScreenAttributes } from 'core/funnel/entities';

type PhoneNumberProps = {
  id: string;
};

const PhoneNumber = ({ id }: PhoneNumberProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  return <PhoneNumberScreen goNext={next} {...(screenAttributes as PhoneNumberScreenAttributes)} />;
};

export default PhoneNumber;
