import styled from 'styled-components';

export const ContainerStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  width: 360px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  padding: 20px;
  background: ${(props) => props.theme.colors.primary500};
`;

export const ContentStyled = styled.div`
  margin-left: 10px;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: ${(props) => props.theme.colors.primary100};
`;

export const TitleStyled = styled(TextStyled)`
  margin-bottom: 3px;
  font-size: 24px;
  line-height: 29px;
`;
