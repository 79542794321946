import { ChangeEvent, useState } from 'react';
import Checkbox from 'core/common/components/Checkbox';
import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import { Skip, StyledContainerMain } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import Select from 'core/common/components/Select/Select';
import { hoursList, minutesList, partOfDay } from 'core/common/constants';
import { TimeScreenProps } from 'core/funnel/entities';
import { Button, Content, SelectContainer, StyledText, StyledTitle } from './styled';

const TimeScreen = ({
  onContinue,
  onBack,
  setTime,
  time,
  step,
  skipData,
  content,
  hasCheckbox,
  progressBarName,
  progressBarTitle,
  totalPersonalitySteps,
  totalProfileSteps,
  totalRelationshipSteps,
}: TimeScreenProps) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTime({
      ...time,
      [e.target.name]: e.target.value,
    });
  };

  const { title, text, label } = content;

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack onBack={onBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <StyledContainerMain>
        <Content>
          <StyledTitle>{title}</StyledTitle>
          <StyledText>{text}</StyledText>
          <SelectContainer>
            <Select
              options={hoursList}
              name="hours"
              value={time.hours}
              onChange={handleTimeChange}
            />
            <Select
              options={minutesList}
              name="minutes"
              value={time.minutes}
              onChange={handleTimeChange}
            />
            <Select options={partOfDay} name="part" value={time.part} onChange={handleTimeChange} />
          </SelectContainer>
          {hasCheckbox && (
            <Checkbox
              name="checkbox"
              label={label}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          )}
        </Content>
      </StyledContainerMain>
      <Button onClick={onContinue}>{content.next}</Button>
      {!!skipData && <Skip onClick={onContinue}>Add later</Skip>}
    </>
  );
};

export default TimeScreen;
