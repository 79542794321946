import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  position: relative;

  &:not(:first-child) {
    padding-top: 20px;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(251, 251, 255, 0) 0%,
      rgba(230, 230, 233, 0.5) 25%,
      rgba(197, 197, 197, 0.5) 50%,
      rgba(230, 230, 233, 0.5) 75%,
      rgba(251, 251, 255, 0) 100%
    );
    content: '';
  }
`;

export const TitleStyled = styled.h2`
  font-size: 22px;
  text-align: center;
`;

export const MoonDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
  padding-bottom: 20px;
`;

export const MoonPhaseStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding-left: 12px;
`;

export const MoonPhaseTitleStyled = styled.p`
  margin-top: 12px;
  font-size: 14px;
  text-align: center;
`;

export const ImageStyled = styled(Image)`
  flex-shrink: 0;
`;

export const UserDetailsStyled = styled.ul`
  flex-grow: 1;
  margin-left: 30px;
`;

export const UserDetailsItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const UserDetailsTitleStyled = styled.span`
  margin-bottom: 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const UserDetailsValueStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const MoonSignTextStyled = styled.span`
  margin-left: 4px;
`;
