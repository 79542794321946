import { useContext } from 'react';
import SupportBotButton from 'core/common/components/SupportBotButton';
import { useLogger } from 'core/common/hooks';
import { QuizContext } from 'core/funnel/contexts/FlowContext';
import { QuizStepTypes } from 'core/funnel/entities';
import AdditionalDiscount from './AdditionalDiscount';
import AdditionalDiscountIntroOffer from './AdditionalDiscountIntroOffer';
import AdditionalDiscountPalmistry from './AdditionalDiscountPalmistry';
import AdditionalDiscountPalmistryV2 from './AdditionalDiscountPalmistryV2';
import Answer from './Answer';
import AnswerHorizonalOrientation from './AnswerHorizontalOrientaion';
import AnswerHorizontalOrientationV2 from './AnswerHorizontalOrientationV2';
import AnswerHorizontalOrientationV3 from './AnswerHorizontalOrientationV3';
import AnswerImage from './AnswerImage';
import AnswerImageMultiple from './AnswerImageMultiple';
import AnswerNatalChart from './AnswerNatalChart';
import AnswerNatalChartV2 from './AnswerNatalChartV2';
import AnswerPalmistry from './AnswerPalmistry';
import AppDownload from './AppDownload';
import CompoundedTitleInformational from './CompoundedTitleInformational';
import Credits from './Credits';
import CreditsNatalChart from './CreditsNatalChart';
import CreditsPalmistry from './CreditsPalmistry';
import Date from './Date';
import DateNatalChart from './DateNatalChart';
import DateNatalChartV2 from './DateNatalChartV2';
import DatePalmistry from './DatePalmistry';
import DatePicker from './DatePicker';
import DatePickerV2 from './DatePickerV2';
import DateSimple from './DateSimple';
import DynamicAnswers from './DynamicAnswer';
import DynamicAnswerV2 from './DynamicAnswerV2';
import Email from './Email';
import EmailBlurred from './EmailBlurred';
import EmailConfirm from './EmailConfirm';
import EmailConfirmNatalChart from './EmailConfirmNatalChart';
import EmailConfirmPalmistry from './EmailConfirmPalmistry';
import EmailMarketingCompatibility from './EmailMarketingCompatibility';
import EmailMarketingNatalChartPage from './EmailMarketingNatalChart';
import EmailMarketingPalmistry from './EmailMarketingPalmistry';
import EmailMarketingPaywall from './EmailMarketingPaywall';
import EmailMarketingWitchPowerPage from './EmailMarketingWitchPower';
import EmailNatalChart from './EmailNatalChart';
import EmailPalmistry from './EmailPalmistry';
import EmailSecured from './EmailSecured';
import EmailSecuredPalmistry from './EmailSecuredPalmistry';
import Gender from './Gender';
import GenderMoonCompatibility from './GenderMoonCompatibility';
import GenderPalmistry from './GenderPalmistry';
import GenderDynamicImages from './GenderV2';
import GenderV3 from './GenderV3';
import Goal from './Goal';
import GoalSet from './GoalSet';
import HelloMoonCompatibility from './HelloMoonCompatibility';
import ImageInformational from './ImageInformational';
import ImagesPreloader from './ImagesPreloader';
import Informational from './Informational';
import InformationalNatalChart from './InformationalNatalChart';
import InformationalPalmistry from './InformationalPalmistry';
import InformationalV2 from './InformationalV2';
import LimitedChoice from './LimitedChoice';
import LoaderWithReviews from './LoaderWithReviews';
import Loader from './Loading';
import LoadingAsk from './LoadingAsk';
import LoadingAskNatalChart from './LoadingAskNatalChart';
import LoadingAskPalmistry from './LoadingAskPalmistry';
import LoadingMoonCompatibility from './LoadingMoonCompatibility';
import LoadingNatalChart from './LoadingNatalChart';
import LoadingPalmistry from './LoadingPalmistry';
import MentionedIn from './MentionedIn';
import MoonPhase from './MoonPhase';
import MultipleAnswers from './MultipleAnswers';
import MultipleAnswersNatalChart from './MultipleAnswersNatalChart';
import MultipleAnswersNatalChartV2 from './MultipleAnswersNatalChartV2';
import Name from './Name';
import NameHumanDesign from './NameHumanDesign';
import NameMoonCompatibility from './NameMoonCompatibility';
import NameMoonCompatibilityV2 from './NameMoonCompatibilityV2';
import Onboarding from './Onboarding';
import OnboardingAsk from './OnboardingAsk';
import OnboardingAskNatalChart from './OnboardingAskNatalChart';
import OnboardingAskPalmistry from './OnboardingAskPalmistry';
import OnboardingAskPalmistryV2 from './OnboardingAskPalmistryV2';
import OnboardingAskV2 from './OnboardingAskV2';
import OnboardingIntroOffer from './OnboardingIntroOffer';
import OnboardingPalmistry from './OnboardingPalmistry';
import OnboardingReading from './OnboardingReading';
import PartnerMoonPhase from './PartnerMoonPhase';
import PhoneNumber from './PhoneNumber';
import PhoneNumberEmailMarketing from './PhoneNumberEmailMarketing';
import PhoneNumberPalmistry from './PhoneNumberPalmistry';
import Place from './Place';
import PlaceHumanDesign from './PlaceHumanDesign';
import PlaceNatalChart from './PlaceNatalChart';
import PrelandingMoonCompatibility from './PrelandingMoonCompatibility';
import PrelandingPalmistry from './PrelandingPalmistry';
import Problem from './Problem';
import ProcessingLoader from './ProcessingLoader';
import Questions from './Questions';
import QuestionsNatalChart from './QuestionsNatalChart';
import QuestionsPalmistry from './QuestionsPalmistry';
import RelationshipProgress from './RelationshipProgress';
import RelationshipZodiacInfo from './RelationshipZodiacInfo';
import Report from './Report';
import Review from './Review';
import { Scan, ScanWithScreenError } from './Scan';
import ScanAnalysing from './ScanAnalysing';
import ScanPreview from './ScanPreview';
import ScanV2 from './ScanV2';
import SingleZodiacInfo from './SingleZodiacInfo';
import SocialProof from './SocialProof';
import Split from './Split';
import Stories from './Stories';
import Summary from './Summary';
import SummaryV2 from './SummaryV2';
import TalkWithExpert from './TalkWithExpert';
import TalkWithExpertNatalChart from './TalkWithExpertNatalChart';
import TalkWithExpertPalmistry from './TalkWithExpertPalmistry';
import ThankYou from './ThankYou';
import ThankYouWithRedirect from './ThankYouWithRedirect';
import Time from './Time';
import TimeNatalChart from './TimeNatalChart';
import TimeV2 from './TimeV2';
import TimeV3 from './TimeV3';
import Timeline from './Timeline';
import TrialChoice from './TrialChoice';
import TrialChoiceNatalChart from './TrialChoiceNatalChart';
import TrialChoicePalmistryV2 from './TrialChoicePalmistryV2';
import TrialCredits from './TrialCredits';
import TrialCreditsEmailMarketing from './TrialCreditsEmailMarketing';
import TrialCreditsNatalChart from './TrialCreditsNatalChart';
import TrialCreditsPalmistry from './TrialCreditsPalmistry';
import TrialCreditsPalmistryV2 from './TrialCreditsPalmistryV2';
import TrialCreditsV2 from './TrialCreditsV2';
import TrialPayment from './TrialPayment';
import TrialPaymentAttachmentStyle from './TrialPaymentAttachmentStyle';
import TrialPaymentAttachmentStyleV2 from './TrialPaymentAttachmentStyleV2';
import TrialPaymentAttachmentStyleV3 from './TrialPaymentAttachmentStyleV3';
import TrialPaymentAttachmentStyleWithDiscount from './TrialPaymentAttachmentStyleWithDiscount';
import TrialPaymentAttachmentStyleWithDiscountV2 from './TrialPaymentAttachmentStyleWithDiscountV2';
import TrialPaymentAttachmentStyleWithDiscountV3 from './TrialPaymentAttachmentStyleWithDiscountV3';
import TrialPaymentHumanDesign from './TrialPaymentHumanDesign';
import TrialPaymentHumanDesignV2 from './TrialPaymentHumanDesignV2';
import TrialPaymentIntroOffer from './TrialPaymentIntroOffer';
import TrialPaymentIntroOfferV2 from './TrialPaymentIntroOfferV2';
import TrialPaymentMoonCompatibility from './TrialPaymentMoonCompatibility';
import TrialPaymentNatalChart from './TrialPaymentNatalChart';
import TrialPaymentNatalChartV2 from './TrialPaymentNatalChartV2';
import TrialPaymentNatalChartV3 from './TrialPaymentNatalChartV3';
import TrialPaymentOneTimeOfferHumanDesign from './TrialPaymentOneTimeOfferHumanDesign';
import TrialPaymentPalmistry from './TrialPaymentPalmistry';
import TrialPaymentPalmistryV2 from './TrialPaymentPalmistryV2';
import TrialPaymentPalmistryV3 from './TrialPaymentPalmistryV3';
import TrialPaymentPalmistryV4 from './TrialPaymentPalmistryV4';
import TrialPaymentWitchPower from './TrialPaymentWitchPower';
import TrialPaymentWithDiscount from './TrialPaymentWithDiscount';
import TrialPaymentWithDiscountIntroOffer from './TrialPaymentWithDiscountIntroOffer';
import TrialPaymentWithDiscountPalmistry from './TrialPaymentWithDiscountPalmistry';
import TrialPaymentWithDiscountPalmistryV2 from './TrialPaymentWithDiscountPalmistryV2';
import TrialPaymentWithDiscountPalmistryV3 from './TrialPaymentWithDiscountPalmistryV3';
import TrialPaymentWithDiscountPalmistryV4 from './TrialPaymentWithDiscountPalmistryV4';
import UnlimitedReadings from './UnlimitedReadings';
import UnlimitedReadingsEmailMarketing from './UnlimitedReadingsEmailMarketing';
import UnlimitedReadingsPalmistry from './UnlimitedReadingsPalmistry';
import UnlimitedReadingsPalmistryV2 from './UnlimitedReadingsPalmistryV2';
import UpsellBlackMoonSingle from './UpsellBlackMoonSingle';
import UpsellConsultation from './UpsellConsultation';
import UpsellConsultationWithDiscount from './UpsellConsultationWithDiscount';
import UpsellNatalChartSingle from './UpsellNatalChartSingle';
import UpsellReadingsPack from './UpsellReadingsPack';
import UpsellReadingsPackWithDiscount from './UpsellReadingsPackWithDiscount';
import Upsells from './Upsells';
import UpsellsEmailMarketing from './UpsellsEmailMarketing';
import UpsellsNatalChart from './UpsellsNatalChart';
import UpsellsPalmistry from './UpsellsPalmistry';
import UpsellsPalmistryV2 from './UpsellsPalmistryV2';
import UpsellsV2 from './UpsellsV2';
import UpsellsV3 from './UpsellsV3';
import UserAnswer from './UserAnswer';
import UserTypeHumanDesign from './UserTypeHumanDesign';
import W2APromotion from './W2APromotion';
import WhichFigure from './WhichFigure';

type QuizStepsProps = {
  pageId: string;
};

const QuizSteps = ({ pageId }: QuizStepsProps) => {
  const logger = useLogger();
  const { getScreenAttributes } = useContext(QuizContext);

  const screenAttributes = getScreenAttributes(pageId);

  if (!screenAttributes) return null;

  const getScreen = () => {
    const { screenType } = screenAttributes;

    switch (screenType) {
      case QuizStepTypes.TIME_V2:
        return <TimeV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TIME_V3:
        return <TimeV3 id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_PICKER:
        return <DatePicker id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_PICKER_V2:
        return <DatePickerV2 id={pageId} key={pageId} />;
      case QuizStepTypes.GENDER:
        return <Gender id={pageId} key={pageId} />;
      case QuizStepTypes.GENDER_PALMISTRY:
        return <GenderPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.GENDER_MOON_COMPATIBILITY:
        return <GenderMoonCompatibility id={pageId} key={pageId} />;
      case QuizStepTypes.PRELANDING_MOON_COMPATIBILITY:
        return <PrelandingMoonCompatibility id={pageId} key={pageId} />;
      case QuizStepTypes.PRELANDING_PALMISTRY:
        return <PrelandingPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.GOAL:
        return <Goal id={pageId} key={pageId} />;
      case QuizStepTypes.GOAL_SET:
        return <GoalSet id={pageId} key={pageId} />;
      case QuizStepTypes.INFORMATIONAL:
        return <Informational id={pageId} key={pageId} />;
      case QuizStepTypes.INFORMATIONAL_PALMISTRY:
        return <InformationalPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.INFORMATIONAL_NATAL_CHART:
        return <InformationalNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.IMAGE_INFORMATIONAL:
        return <ImageInformational id={pageId} key={pageId} />;
      case QuizStepTypes.DATE:
        return <Date id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_PALMISTRY:
        return <DatePalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_NATAL_CHART:
        return <DateNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_NATAL_CHART_V2:
        return <DateNatalChartV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TIME:
        return <Time id={pageId} key={pageId} />;
      case QuizStepTypes.TIME_NATAL_CHART:
        return <TimeNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.PLACE:
        return <Place id={pageId} key={pageId} />;
      case QuizStepTypes.PLACE_NATAL_CHART:
        return <PlaceNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.NAME:
        return <Name id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER:
        return <Answer id={pageId} key={pageId} />;
      case QuizStepTypes.DYNAMIC_ANSWER:
        return <DynamicAnswers id={pageId} key={pageId} />;
      case QuizStepTypes.DYNAMIC_ANSWER_V2:
        return <DynamicAnswerV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_PALMISTRY:
        return <AnswerPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_NATAL_CHART:
        return <AnswerNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_NATAL_CHART_V2:
        return <AnswerNatalChartV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION:
        return <AnswerHorizonalOrientation id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION_V2:
        return <AnswerHorizontalOrientationV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION_V3:
        return <AnswerHorizontalOrientationV3 id={pageId} key={pageId} />;
      case QuizStepTypes.MULTIPLE_ANSWERS:
        return <MultipleAnswers id={pageId} key={pageId} />;
      case QuizStepTypes.MULTIPLE_ANSWERS_NATAL_CHART:
        return <MultipleAnswersNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.MULTIPLE_ANSWERS_NATAL_CHART_V2:
        return <MultipleAnswersNatalChartV2 id={pageId} key={pageId} />;
      case QuizStepTypes.PROCESSING:
        return <Loader id={pageId} key={pageId} />;
      case QuizStepTypes.PROCESSING_PALMISTRY:
        return <LoadingPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.PROCESSING_NATAL_CHART:
        return <LoadingNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.PROBLEM:
        return <Problem id={pageId} key={pageId} />;
      case QuizStepTypes.COMPOUNDED_TITLE_INFORMATIONAL:
        return <CompoundedTitleInformational id={pageId} key={pageId} />;
      case QuizStepTypes.REVIEW:
        return <Review id={pageId} key={pageId} />;
      case QuizStepTypes.PROCESSING_LOADER:
        return <ProcessingLoader id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL:
        return <Email id={pageId} key={pageId} />;
      case QuizStepTypes.USER_ANSWER:
        return <UserAnswer id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_SECURED:
        return <EmailSecured id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_PALMISTRY:
        return <EmailPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_SECURED_PALMISTRY:
        return <EmailSecuredPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_NATAL_CHART:
        return <EmailNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_CONFIRM:
        return <EmailConfirm id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_CONFIRM_PALMISTRY:
        return <EmailConfirmPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_CONFIRM_NATAL_CHART:
        return <EmailConfirmNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING:
        return <Onboarding id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_INTRO_OFFER:
        return <OnboardingIntroOffer id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_PALMISTRY:
        return <OnboardingPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_READING:
        return <OnboardingReading id={pageId} key={pageId} />;
      case QuizStepTypes.SCAN_PREVIEW:
        return <ScanPreview id={pageId} key={pageId} />;
      case QuizStepTypes.SCAN:
        return <Scan id={pageId} key={pageId} />;
      case QuizStepTypes.SCAN_WITH_SKIP_ERROR:
        return <ScanWithScreenError id={pageId} key={pageId} />;
      case QuizStepTypes.SCAN_V2:
        return <ScanV2 id={pageId} key={pageId} />;
      case QuizStepTypes.SCAN_ANALYSING:
        return <ScanAnalysing id={pageId} key={pageId} />;
      case QuizStepTypes.REPORT:
        return <Report id={pageId} key={pageId} />;
      case QuizStepTypes.SINGLE_ZODIAC_INFO:
        return <SingleZodiacInfo id={pageId} key={pageId} />;
      case QuizStepTypes.RELATIONSHIP_ZODIAC_INFO:
        return <RelationshipZodiacInfo id={pageId} key={pageId} />;
      case QuizStepTypes.LIMITED_CHOICE:
        return <LimitedChoice id={pageId} key={pageId} />;
      case QuizStepTypes.MENTIONED_IN:
        return <MentionedIn id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CHOICE:
        return <TrialChoice id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CHOICE_NATAL_CHART:
        return <TrialChoiceNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CHOICE_PALMISTRY_V2:
        return <TrialChoicePalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT:
        return <TrialPayment id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_NATAL_CHART:
        return <TrialPaymentNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_NATAL_CHART_V2:
        return <TrialPaymentNatalChartV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_NATAL_CHART_V3:
        return <TrialPaymentNatalChartV3 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_PALMISTRY:
        return <TrialPaymentPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITCH_POWER:
        return <TrialPaymentWitchPower id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V2:
        return <TrialPaymentPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V3:
        return <TrialPaymentPalmistryV3 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_INTRO_OFFER:
        return <TrialPaymentIntroOffer id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_INTRO_OFFER_V2:
        return <TrialPaymentIntroOfferV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ADDITIONAL_DISCOUNT:
        return <AdditionalDiscount id={pageId} key={pageId} />;
      case QuizStepTypes.ADDITIONAL_DISCOUNT_PALMISTRY:
        return <AdditionalDiscountPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.ADDITIONAL_DISCOUNT_PALMISTRY_V2:
        return <AdditionalDiscountPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ADDITIONAL_DISCOUNT_INTRO_OFFER:
        return <AdditionalDiscountIntroOffer id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT:
        return <TrialPaymentWithDiscount id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY:
        return <TrialPaymentWithDiscountPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V2:
        return <TrialPaymentWithDiscountPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_INTRO_OFFER:
        return <TrialPaymentWithDiscountIntroOffer id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS:
        return <Upsells id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_V2:
        return <UpsellsV2 id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_NATAL_CHART:
        return <UpsellsNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_PALMISTRY:
        return <UpsellsPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_PALMISTRY_V2:
        return <UpsellsPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_EMAIL_MARKETING:
        return <UpsellsEmailMarketing key={pageId} id={pageId} />;
      case QuizStepTypes.UNLIMITED_READINGS:
        return <UnlimitedReadings id={pageId} key={pageId} />;
      case QuizStepTypes.UNLIMITED_READINGS_PALMISTRY:
        return <UnlimitedReadingsPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.UNLIMITED_READINGS_PALMISTRY_V2:
        return <UnlimitedReadingsPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.UNLIMITED_READINGS_EMAIL_MARKETING:
        return <UnlimitedReadingsEmailMarketing id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS:
        return <TrialCredits id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS_NATAL_CHART:
        return <TrialCreditsNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS_PALMISTRY:
        return <TrialCreditsPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS_PALMISTRY_V2:
        return <TrialCreditsPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS_EMAIL_MARKETING:
        return <TrialCreditsEmailMarketing id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_ASK:
        return <OnboardingAsk id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_ASK_V2:
        return <OnboardingAskV2 id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_ASK_NATAL_CHART:
        return <OnboardingAskNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_ASK_PALMISTRY:
        return <OnboardingAskPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.ONBOARDING_ASK_PALMISTRY_V2:
        return <OnboardingAskPalmistryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TALK_WITH_EXPERT:
        return <TalkWithExpert id={pageId} key={pageId} />;
      case QuizStepTypes.TALK_WITH_EXPERT_NATAL_CHART:
        return <TalkWithExpertNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.TALK_WITH_EXPERT_PALMISTRY:
        return <TalkWithExpertPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.QUESTIONS:
        return <Questions id={pageId} key={pageId} />;
      case QuizStepTypes.QUESTIONS_NATAL_CHART:
        return <QuestionsNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.QUESTIONS_PALMISTRY:
        return <QuestionsPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.APP_DOWNLOAD:
        return <AppDownload id={pageId} key={pageId} />;
      case QuizStepTypes.CREDITS:
        return <Credits id={pageId} key={pageId} />;
      case QuizStepTypes.CREDITS_NATAL_CHART:
        return <CreditsNatalChart id={pageId} key={pageId} />;
      case QuizStepTypes.CREDITS_PALMISTRY:
        return <CreditsPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.LOADING_ASK:
        return <LoadingAsk key={pageId} id={pageId} />;
      case QuizStepTypes.LOADING_ASK_NATAL_CHART:
        return <LoadingAskNatalChart key={pageId} id={pageId} />;
      case QuizStepTypes.LOADING_ASK_PALMISTRY:
        return <LoadingAskPalmistry key={pageId} id={pageId} />;
      case QuizStepTypes.PHONE_NUMBER:
        return <PhoneNumber key={pageId} id={pageId} />;
      case QuizStepTypes.PHONE_NUMBER_PALMISTRY:
        return <PhoneNumberPalmistry key={pageId} id={pageId} />;
      case QuizStepTypes.PHONE_NUMBER_EMAIL_MARKETING:
        return <PhoneNumberEmailMarketing key={pageId} id={pageId} />;
      case QuizStepTypes.WHICH_FIGURE:
        return <WhichFigure key={pageId} id={pageId} />;
      case QuizStepTypes.MOON_PHASE:
        return <MoonPhase key={pageId} id={pageId} />;
      case QuizStepTypes.PARTNER_MOON_PHASE:
        return <PartnerMoonPhase key={pageId} id={pageId} />;
      case QuizStepTypes.THANK_YOU:
        return <ThankYou key={pageId} id={pageId} />;
      case QuizStepTypes.THANK_YOU_WITH_REDIRECT:
        return <ThankYouWithRedirect key={pageId} id={pageId} />;
      case QuizStepTypes.NAME_MOON_COMPATIBILITY:
        return <NameMoonCompatibility key={pageId} id={pageId} />;
      case QuizStepTypes.NAME_MOON_COMPATIBILITY_V2:
        return <NameMoonCompatibilityV2 key={pageId} id={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_MOON_COMPATIBILITY:
        return <TrialPaymentMoonCompatibility key={pageId} id={pageId} />;
      case QuizStepTypes.LOADING_MOON_COMPATIBILITY:
        return <LoadingMoonCompatibility key={pageId} id={pageId} />;
      case QuizStepTypes.HELLO_MOON_COMPATIBILITY:
        return <HelloMoonCompatibility key={pageId} id={pageId} />;
      case QuizStepTypes.UPSELL_NATAL_CHART_SINGLE:
        return <UpsellNatalChartSingle key={pageId} id={pageId} />;
      case QuizStepTypes.UPSELL_BLACK_MOON_SINGLE:
        return <UpsellBlackMoonSingle key={pageId} id={pageId} />;
      case QuizStepTypes.TRIAL_CREDITS_V2:
        return <TrialCreditsV2 key={pageId} id={pageId} />;
      case QuizStepTypes.EMAIL_BLURRED:
        return <EmailBlurred key={pageId} id={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_HUMAN_DESIGN:
        return <TrialPaymentHumanDesign key={pageId} id={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ONE_TIME_OFFER_HUMAN_DESIGN:
        return <TrialPaymentOneTimeOfferHumanDesign key={pageId} id={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_HUMAN_DESIGN_V2:
        return <TrialPaymentHumanDesignV2 key={pageId} id={pageId} />;
      case QuizStepTypes.USER_TYPE_HUMAN_DESIGN:
        return <UserTypeHumanDesign key={pageId} id={pageId} />;
      case QuizStepTypes.PLACE_HUMAN_DESIGN:
        return <PlaceHumanDesign key={pageId} id={pageId} />;
      case QuizStepTypes.EMAIL_MARKETING_WITCH_POWER:
        return <EmailMarketingWitchPowerPage id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_MARKETING_NATAL_CHART:
        return <EmailMarketingNatalChartPage id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_MARKETING_PALMISTRY:
        return <EmailMarketingPalmistry id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_MARKETING_COMPATIBILITY:
        return <EmailMarketingCompatibility id={pageId} key={pageId} />;
      case QuizStepTypes.NAME_HUMAN_DESIGN:
        return <NameHumanDesign id={pageId} key={pageId} />;
      case QuizStepTypes.SPLIT:
        return <Split id={pageId} key={pageId} />;
      case QuizStepTypes.RELATIONSHIP_PROGRESS:
        return <RelationshipProgress id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE:
        return <TrialPaymentAttachmentStyle id={pageId} key={pageId} />;
      case QuizStepTypes.GENDER_V2:
        return <GenderDynamicImages id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT:
        return <TrialPaymentAttachmentStyleWithDiscount id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V3:
        return <TrialPaymentWithDiscountPalmistryV3 id={pageId} key={pageId} />;
      case QuizStepTypes.INFORMATIONAL_V2:
        return <InformationalV2 id={pageId} key={pageId} />;
      case QuizStepTypes.SOCIAL_PROOF:
        return <SocialProof id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_IMAGE:
        return <AnswerImage id={pageId} key={pageId} />;
      case QuizStepTypes.ANSWER_IMAGE_MULTIPLE:
        return <AnswerImageMultiple id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELL_CONSULTATION:
        return <UpsellConsultation id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELL_CONSULTATION_WITH_DISCOUNT:
        return <UpsellConsultationWithDiscount id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELL_READINGS_PACK:
        return <UpsellReadingsPack id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELL_READINGS_PACK_WITH_DISCOUNT:
        return <UpsellReadingsPackWithDiscount id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V4:
        return <TrialPaymentPalmistryV4 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V4:
        return <TrialPaymentWithDiscountPalmistryV4 id={pageId} key={pageId} />;
      case QuizStepTypes.UPSELLS_V3:
        return <UpsellsV3 id={pageId} key={pageId} />;
      case QuizStepTypes.SUMMARY:
        return <Summary id={pageId} key={pageId} />;
      case QuizStepTypes.SUMMARY_V2:
        return <SummaryV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TIMELINE:
        return <Timeline id={pageId} key={pageId} />;
      case QuizStepTypes.LOADER_WITH_REVIEWS:
        return <LoaderWithReviews id={pageId} key={pageId} />;
      case QuizStepTypes.STORIES:
        return <Stories id={pageId} key={pageId} />;
      case QuizStepTypes.EMAIL_MARKETING_PAYWALL:
        return <EmailMarketingPaywall id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_V2:
        return <TrialPaymentAttachmentStyleV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_V3:
        return <TrialPaymentAttachmentStyleV3 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V2:
        return <TrialPaymentAttachmentStyleWithDiscountV2 id={pageId} key={pageId} />;
      case QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V3:
        return <TrialPaymentAttachmentStyleWithDiscountV3 id={pageId} key={pageId} />;
      case QuizStepTypes.W2A_PROMOTION:
        return <W2APromotion id={pageId} key={pageId} />;
      case QuizStepTypes.GENDER_V3:
        return <GenderV3 id={pageId} key={pageId} />;
      case QuizStepTypes.DATE_SIMPLE:
        return <DateSimple id={pageId} key={pageId} />;
      default: {
        logger.error(new TypeError(`Unknown step data: ${JSON.stringify(screenType)}`));
        return null;
      }
    }
  };

  return (
    <>
      {getScreen()}
      <ImagesPreloader nextScreenId={screenAttributes.next} />
      {screenAttributes.supportWidget && (
        <SupportBotButton variant="fixed" buttonText={screenAttributes.supportWidget.text} />
      )}
    </>
  );
};

export default QuizSteps;
