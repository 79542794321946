import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
  margin-right: 5px;
`;
