import styled from 'styled-components';
import Carousel from 'core/common/components/Carousel';
import Image from 'core/common/components/Image';
import Rating from 'core/common/components/Rating';

export const ListStyled = styled.ul`
  width: 100%;
`;

export const CarouselStyled = styled(Carousel)``;

export const ItemStyled = styled.li`
  padding: 12px 16px;
  border-radius: 16px;
  background: #0000000d;
`;

export const RatingStyled = styled(Rating)`
  display: block;
  margin-bottom: 6px;
  font-size: 24px;
`;

export const DateStyled = styled.span`
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.typography400};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const TextStyled = styled.p`
  margin-bottom: 12px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.typography900};

  span {
    color: ${({ theme }) => theme.colors.info};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 36px;
  }
`;

export const InfoStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 50%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 38px;
    height: 38px;
    margin-right: 12px;
  }
`;

export const NameStyled = styled.span`
  font-size: 14px;
  color: #0f0f0f61;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 22px;
  }
`;
