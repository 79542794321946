import EmailConfirmNatalChartScreen from 'core/common/components/QuizScreens/EmailConfirmNatalChart';
import { normalizeApiError } from 'core/common/errors';
import { useLogger, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { EmailConfirmNatalChartScreenAttributes } from 'core/funnel/entities';

type EmailConfirmNatalChartProps = {
  id: string;
};

const EmailConfirmNatalChart = ({ id }: EmailConfirmNatalChartProps) => {
  const logger = useLogger();
  const { analyticsService } = useServices();
  const { screenAttributes, back, next, updateUserSettings } = useQuizStep(id);

  const handleSubmit = async (isAgree: boolean) => {
    try {
      await updateUserSettings(isAgree);
    } catch (err) {
      const error = normalizeApiError(err);
      logger.error(error);
    }
    await next();
  };

  const onUserAgree = async () => {
    analyticsService.setEmailConsent('opted_in');
    await handleSubmit(true);
  };

  const onUserDisagree = async () => {
    analyticsService.setEmailConsent('refused');
    await handleSubmit(false);
  };

  return (
    <EmailConfirmNatalChartScreen
      onUserAgree={onUserAgree}
      onUserDisagree={onUserDisagree}
      onBack={back}
      {...(screenAttributes as EmailConfirmNatalChartScreenAttributes)}
    />
  );
};

export default EmailConfirmNatalChart;
