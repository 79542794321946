import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const TitleStyled = styled.h2`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
`;

export const ListStyled = styled.ul`
  width: 100%;
  margin-top: 32px;
`;

export const ListItemStyled = styled.li`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 46px 12px 12px;
  border-radius: 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const ListItemBackgroundStyled = styled(Image)`
  z-index: -1;
  object-fit: cover;
  object-position: bottom;
`;

export const AdvantageTitleStyled = styled.h3`
  font-size: 16px;
`;

export const AdvantageTextStyled = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography200};
`;

export const WrapperStyled = styled.section`
  text-align: left;
`;
