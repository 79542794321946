import guarantee from 'core/common/assets/images/guarantee_medal.png';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionGuaranteeAttributes } from 'core/funnel/entities';
import { ContainerStyled, DescriptionStyled, TitleStyled } from './styled';

type SectionGuaranteeProps = {
  content: SectionGuaranteeAttributes['content'];
  className?: string;
};

const SectionGuarantee = ({ content, className }: SectionGuaranteeProps) => {
  const { title, description } = content;

  return (
    <ContainerStyled className={className}>
      <Image src={guarantee} width={54} height={70} alt="Guarantee medal" />
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(description)} />
    </ContainerStyled>
  );
};

export default SectionGuarantee;
