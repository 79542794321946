import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import DataSecuredBadgeComponent from 'core/common/components/DataSecuredBadge';
import ImageComponent from 'core/common/components/Image';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const AdvantagesList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-right: -10px;
  margin-bottom: 32px;
`;

export const AdvantagesListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.infoLight};
  border-radius: 8px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const AdvantagesTextWrapper = styled.div`
  flex-grow: 1;
  line-height: 10px;
`;

export const AdvantagesTitle = styled.p`
  font-size: 8px;
  text-transform: uppercase;
`;

export const AdvantagesText = styled.p`
  font-size: 10px;
  font-weight: bold;
`;

export const MainImage = styled(ImageComponent)`
  margin-bottom: 24px;
`;

export const Title = styled(TitleComponent)`
  margin-bottom: 16px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    line-height: 28px;
  }
`;

export const AnimatedTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.infoLight};
`;

export const QuizInformationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -7px;
`;

export const QuizInformationListItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 7px;
  padding: 4px;
  border-radius: 8px;
  background: #f4f4fb;
`;

export const QuizInformationListIcon = styled(ImageComponent)`
  flex-shrink: 0;
  margin-right: 4px;
`;

export const QuizInformationListText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.infoLight};
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 21px;
  box-shadow: 0 12px 23px rgba(84, 60, 151, 0.31);
`;

export const Policy = styled(PolicyComponent)`
  width: 100%;
  max-width: none;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};

  ${NavLink} {
    font-weight: normal;
  }
`;

export const Address = styled(AddressComponent)`
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};
`;

export const AddressUS = styled.p`
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const DataSecuredBadge = styled(DataSecuredBadgeComponent)`
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;
`;
