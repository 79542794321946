import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const TitleStyled = styled.h2`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
`;

export const AspectRatioImageWrapperStyled = styled.div`
  position: relative;
  margin-top: 32px;
  margin-bottom: 8px;
  padding-top: 69%;
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;

export const CaptionStyled = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;
