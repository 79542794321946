import Icon from 'core/svg-sprites/ui/Icon';
import {
  WrapperStyled,
  TitleStyled,
  AccordionWrapperStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
} from './styled';

type FaqProps = {
  title: string;
  questions: Array<{
    title: string;
    answer: string;
  }>;
};

const Faq = ({ title, questions }: FaqProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <AccordionWrapperStyled>
        {questions.map((question) => (
          <AccordionStyled key={question.title}>
            <AccordionSummaryStyled expandIcon={<Icon name="arrow-simple" />}>
              {question.title}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>{question.answer}</AccordionDetailsStyled>
          </AccordionStyled>
        ))}
      </AccordionWrapperStyled>
    </WrapperStyled>
  );
};

export default Faq;
