import { useEffect } from 'react';
import QuestionsScreen from 'core/common/components/QuizScreens/Questions';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { QuestionsScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type QuestionsProps = {
  id: string;
};

const Questions = ({ id }: QuestionsProps) => {
  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onQuestionsPageOpened();
  }, [analyticsService]);

  return <QuestionsScreen {...(screenAttributes as QuestionsScreenAttributes)} />;
};

export default Questions;
