import { prepareHtml } from 'core/common/utils/prepareHtml';
import { StoryBasicContent } from 'core/funnel/entities';
import { WrapperStyled, TitleStyled, TextStyled, ImageStyled, AspectRatioStyled } from './styled';

type BasicProps = StoryBasicContent;

const Basic = ({ title, text, image }: BasicProps) => {
  return (
    <WrapperStyled>
      <TitleStyled variant="h3" dangerouslySetInnerHTML={prepareHtml(title)} />
      <TextStyled variant="body-large" dangerouslySetInnerHTML={prepareHtml(text)} />
      <AspectRatioStyled width={375} height={330}>
        <ImageStyled src={image.src} alt={image.alt} fill priority />
      </AspectRatioStyled>
    </WrapperStyled>
  );
};

export default Basic;
