import styled from 'styled-components';
import CloseButton from 'core/common/components/ButtonClose';
import LinearProgress, { muiLinearProgressClassNames } from 'core/common/components/LinearProgress';
import { StoryColorVariant } from 'core/funnel/entities';

const topOffsetPx = 8;

export const ContainerStyled = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  max-width: 500px;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: ${topOffsetPx}px;
  cursor: pointer;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    max-height: 85%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0;
  }
`;

export const HeaderStyled = styled.header`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  margin-top: ${topOffsetPx}px;
  padding: 12px 16px;
`;

export const DurationProgressStyled = styled(LinearProgress)<{ colorVariant?: StoryColorVariant }>`
  flex-grow: 1;

  ${muiLinearProgressClassNames.mainProgress} {
    height: 4px !important;
    background: ${({ colorVariant }) =>
      colorVariant === 'LIGHT' ? 'rgba(234, 238, 247, 0.2)' : 'rgba(0, 0, 0, 0.2)'} !important;
  }

  ${muiLinearProgressClassNames.innerProgress} {
    background: ${({ theme, colorVariant }) =>
      colorVariant === 'LIGHT' ? theme.colors.typography100 : '#2f2f2f '} !important;
    transition: ${({ value }) => (value === 0 ? 'none' : 'transform .4s linear')};
  }

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const CloseButtonStyled = styled(CloseButton)<{ colorVariant?: StoryColorVariant }>`
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  transform: translate(-16px, calc(100% + 4px));
  fill: ${({ colorVariant }) => (colorVariant === 'LIGHT' ? '#eaeef7' : 'black')};
`;

export const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

const NavigationButtonStyled = styled.button`
  position: absolute;
  top: 50%;
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: inline-block;
  }
`;

export const NavigationPrevButtonStyled = styled(NavigationButtonStyled)`
  left: -72px;
  transform: rotate(90deg);
`;

export const NavigationNextButtonStyled = styled(NavigationButtonStyled)`
  right: -72px;
  transform: rotate(-90deg);
`;

export const ContentStyled = styled.div`
  height: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: #dedede;
`;
