import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Modal from 'core/common/components/Modal';

export const ModalStyled = styled(Modal)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80%;
  margin-top: auto;
  padding: 28px 16px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 436px;
    min-height: 60%;
    margin-top: unset;
    padding: 36px 32px 32px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 16px;
  right: 16px;

  ${CrossIconStyled} {
    fill: ${({ theme }) => theme.colors.typography400};
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 43px;
  }
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const AspectRatioStyled = styled(AspectRatio)`
  margin-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;
`;

export const FooterStyled = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding: 12px;
  padding-right: 56px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fafafa;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-right: 77px;
  }
`;

export const FooterTitleStyled = styled.h3`
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const FooterSubtitleStyled = styled.p`
  font-size: 14px;
  line-height: 18px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const FooterImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 77px;
    height: 77px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-top: auto;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
