import { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { useLogger } from 'core/common/hooks';
import { AnimatedImages } from 'core/funnel/entities';
import { Wrapper } from './styled';

const Cometa = dynamic(() => import('./Cometa').then((mod) => mod.Cometa));
const Clock = dynamic(() => import('./Clock').then((mod) => mod.Clock));
const Key = dynamic(() => import('./Key').then((mod) => mod.Key));
const Umbrella = dynamic(() => import('./Umbrella').then((mod) => mod.Umbrella));
const Sun = dynamic(() => import('./Sun').then((mod) => mod.Sun));
const Lupa = dynamic(() => import('./Lupa').then((mod) => mod.Lupa));
const Aim = dynamic(() => import('./Aim').then((mod) => mod.Aim));
const Cloud = dynamic(() => import('./Cloud').then((mod) => mod.Cloud));
const ScalesBrain = dynamic(() => import('./ScalesBrain').then((mod) => mod.ScalesBrain));
const Compass = dynamic(() => import('./Compass').then((mod) => mod.Compass));
const ScalesHeart = dynamic(() => import('./ScalesHeart').then((mod) => mod.ScalesHeart));
const HeartBrainEqual = dynamic(() =>
  import('./HeartBrainEqual').then((mod) => mod.HeartBrainEqual),
);
const Confetti = dynamic(() => import('./Confetti').then((mod) => mod.Confetti));
const ConfettiWithFirework = dynamic(() =>
  import('./ConfettiWithFirework').then((mod) => mod.ConfettiWithFirework),
);
const Hello = dynamic(() => import('./Hello').then((mod) => mod.Hello));
const Clarity = dynamic(() => import('./Clarity').then((mod) => mod.Clarity));
const Communication = dynamic(() => import('./Communication').then((mod) => mod.Communication));

const animatedImagesMap: Record<AnimatedImages, ReactNode> = {
  [AnimatedImages.COMETA]: <Cometa />,
  [AnimatedImages.CLOCK]: <Clock />,
  [AnimatedImages.KEY]: <Key />,
  [AnimatedImages.UMBRELLA]: <Umbrella />,
  [AnimatedImages.SUN]: <Sun />,
  [AnimatedImages.LUPA]: <Lupa />,
  [AnimatedImages.AIM]: <Aim />,
  [AnimatedImages.CLOUD]: <Cloud />,
  [AnimatedImages.SCALES_BRAIN]: <ScalesBrain />,
  [AnimatedImages.COMPASS]: <Compass />,
  [AnimatedImages.SCALES_HEART]: <ScalesHeart />,
  [AnimatedImages.HEART_BRAIN_EQUAL]: <HeartBrainEqual />,
  [AnimatedImages.CONFETTI]: <Confetti />,
  [AnimatedImages.CONFETTI_WITH_FIREWORK]: <ConfettiWithFirework />,
  [AnimatedImages.HELLO]: <Hello />,
  [AnimatedImages.CLARITY]: <Clarity />,
  [AnimatedImages.COMMUNICATION]: <Communication />,
};

type AnimatedImageProps = {
  variant: AnimatedImages;
  className?: string;
};

const AnimatedImage = ({ variant, className = '' }: AnimatedImageProps) => {
  const logger = useLogger();

  const image = animatedImagesMap[variant];

  if (!image) {
    logger.error('Animated image not found');
    return null;
  }

  return <Wrapper className={className}>{image}</Wrapper>;
};

export default AnimatedImage;
