import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';

export const Content = styled.div`
  width: 100%;
  max-width: 708px;
  margin: 0 auto;
`;

export const Subtitle = styled.h2`
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;

  strong {
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  align-self: flex-start;
  text-align: left;
`;

export const StyledTitle = styled.h2`
  align-self: flex-start;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 708px;
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Overtitle = styled.h6`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 135%;
  text-align: start;
  color: #686868;
`;

export const Break = styled.br``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 466px;
  margin: 0 auto;
`;

export const StyledList = styled(List)`
  margin-top: 10px;
`;

export const Item = styled.li``;

export const EmojiBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 15px;
  padding: 0 16px;
  border: ${(props) => `1px solid ${props.theme.colors.typography200}`};
  border-radius: 16px;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px 0 rgba(84, 60, 151, 0.25);
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }
`;

export const HintEmojiBorder = styled(EmojiBorder)`
  justify-content: center;
  margin-bottom: 20px;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 20px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Emoji = styled(EmojiComponent)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 52px;
    height: 52px;
  }
`;
