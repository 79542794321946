import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

const rowsGap = 18;
const cellWidth = 63;

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  font-size: 22px;
  text-align: center;
`;

export const PlansOverviewCardStyled = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 20px 12px;
  border-radius: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  background-color: #3c1c7b;
`;

export const DecorationImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const CellsStyled = styled.div`
  display: flex;
  width: 50%;
  margin-left: auto;
`;

export const HeaderCellsStyled = styled.div`
  position: absolute;
  top: -50px;
  right: -2px;
  display: flex;
`;

export const CellStyled = styled.div<{ highlighted: boolean; isLastPlan: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: ${cellWidth}px;
  padding-bottom: ${rowsGap}px;
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.white : theme.colors.white + '7'};

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ highlighted }) => (highlighted ? 'block' : 'none')};
    border-bottom-right-radius: ${({ highlighted, isLastPlan }) =>
      highlighted && isLastPlan ? '12px' : 'none'};
    border-bottom-left-radius: ${({ highlighted, isLastPlan }) =>
      highlighted && isLastPlan ? '12px' : 'none'};
    background-color: rgba(234, 238, 247, 0.2);
    content: '';
  }
`;

export const HeaderCellStyled = styled.div<{ highlighted: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: ${cellWidth}px;
  padding-top: 10px;
  padding-bottom: 14px;
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.white : theme.colors.white + '7'};

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ highlighted }) => (highlighted ? 'block' : 'none')};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: rgba(234, 238, 247, 0.2);
    content: '';
  }
`;

export const PeriodStyled = styled.span`
  width: min-content;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

export const PeriodValueStyled = styled.strong`
  display: block;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 16px;
`;

export const RowsStyled = styled.div`
  position: relative;
  margin-top: 80px;
`;

export const RowStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  &:not(:last-child)::after {
    position: absolute;
    bottom: 8px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.12);
    content: '';
  }
`;

export const HeaderRowStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
`;

export const IconStyled = styled(Icon)`
  fill: currentColor;
`;

export const PlanStyled = styled.span`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
`;

export const ActiveUsersStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const ActiveUsersText = styled.p`
  font-size: 14px;
`;

export const UsersImageStyled = styled(Image)`
  margin-right: 8px;
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(
    180deg,
    #1d1e55 0%,
    #272463 25%,
    #3b2f7b 50%,
    #4f358d 75%,
    #6939a2 100%
  );
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
