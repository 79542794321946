import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const ListStyled = styled.ul`
  width: 50%;
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const ListItemTextWrapperStyled = styled.div`
  text-align: left;
`;

export const ListIconStyled = styled(Icon)`
  margin-right: 8px;
`;

export const ListItemTitleStyled = styled.h5`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const ListItemValueTraumaStyled = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 143%;
  color: #b32425;
`;

export const ListItemValueStyled = styled.span`
  font-size: 14px;
  line-height: 20px;
`;
