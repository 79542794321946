import { useEffect } from 'react';
import ScanAnalysingScreen from 'core/common/components/QuizScreens/ScanAnalysing';
import { useServices } from 'core/common/hooks';
import { config } from 'core/config';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ScanAnalysingScreenAttributes } from 'core/funnel/entities';
import { useGetPalmCoordinates } from 'core/palmistry/hooks';

export type ScanAnalysingProps = {
  id: string;
};

const ScanAnalysing = ({ id }: ScanAnalysingProps) => {
  const { next, back, screenAttributes, userPreferences } = useQuizStep(id);
  const { appEnvironment } = useServices();
  const { scan } = userPreferences;
  const { coordinates, photo } = scan;

  const { linesCoordinates, fingersCoordinates } = useGetPalmCoordinates(coordinates);

  const { palmPhotoLongSide, palmPhotoShortSide } = config;

  const width = appEnvironment.isMobileDevice() ? palmPhotoShortSide : palmPhotoLongSide;

  const height = appEnvironment.isMobileDevice() ? palmPhotoLongSide : palmPhotoShortSide;

  const goNext = () => next({ force: true });

  useEffect(() => {
    if (!scan.photo) back();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScanAnalysingScreen
      goNext={goNext}
      onBack={back}
      photo={photo}
      linesCoordinates={linesCoordinates}
      fingersCoordinates={fingersCoordinates}
      width={width}
      height={height}
      {...(screenAttributes as ScanAnalysingScreenAttributes)}
    />
  );
};

export default ScanAnalysing;
