import { SectionLegacyListAttributes } from 'core/funnel/entities';
import {
  ContainerStyled,
  IconStyled,
  ItemStyled,
  ItemTextStyled,
  ListStyled,
  TitleStyled,
} from './styled';

type SectionListProps = {
  content: SectionLegacyListAttributes['content'];
  className?: string;
};

const SectionList = ({ content, className }: SectionListProps) => {
  return (
    <ContainerStyled className={className}>
      <TitleStyled>{content.title}</TitleStyled>
      <ListStyled>
        {content.list.map((item) => {
          return (
            <ItemStyled key={item.text}>
              <IconStyled name="check" />
              <ItemTextStyled>{item.text}</ItemTextStyled>
            </ItemStyled>
          );
        })}
      </ListStyled>
    </ContainerStyled>
  );
};

export default SectionList;
