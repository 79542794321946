import palmIcon from 'core/common/assets/images/palmistryQuiz/palmIcon.png';
import { PalmPhoto } from 'core/palmistry/entities';
import { ScanProcessPopUp } from '../ScanProcessPopUps';
import { CloseButtonStyled } from '../ScanProcessPopUps/styled';
import { useScanMaker } from './useScanMaker';
import { Button, Hand, PageContent, Video, VideoContainer } from './styled';

type ScanMakerProps = {
  updatePopUp: (popUp: ScanProcessPopUp) => void;
  detectPalm: (imageBlob: Blob) => Promise<PalmPhoto | void>;
  button: string;
  onClose: () => void;
};

const ScanMaker = ({ updatePopUp, button, detectPalm, onClose }: ScanMakerProps) => {
  const { takeUserPhoto, getWebcamAccess, stopVideoStream } = useScanMaker();

  const onTakePhoto = async () => {
    updatePopUp(ScanProcessPopUp.SCAN_LOADING);
    const photo = await takeUserPhoto();

    if (!photo) return updatePopUp(ScanProcessPopUp.SCAN_ERROR);

    await detectPalm(photo);
  };

  const closePopUp = () => {
    stopVideoStream();
    onClose();
  };

  return (
    <>
      <CloseButtonStyled onClick={closePopUp} />
      <PageContent>
        <VideoContainer>
          <Video ref={getWebcamAccess} autoPlay playsInline height={480} />
          <Hand src={palmIcon} alt="palm skeleton" />
        </VideoContainer>
        <Button onClick={onTakePhoto}>{button}</Button>
      </PageContent>
    </>
  );
};

export default ScanMaker;
