import Header from 'core/common/components/Header';
import { TrialCreditsV2ScreenProps } from 'core/funnel/entities';
import { PaymentDialogFailed, PaymentResignDialog } from '../../Payment';
import UpsellsStepper from '../../UpsellsStepper';
import SectionFooter from './SectionFooter';
import SectionPayment from './SectionPayment';
import {
  Button,
  ButtonWrapper,
  Content,
  Root,
  RouteLink,
  SubtitleStyled,
  TitleStyled,
} from './styled';

const TrialCreditsV2Screen = ({
  content,
  trialCreditsUpsell,
  isLoading,
  resignFormLoaded,
  onBuyCredits,
  onSkip,
  paymentError,
  onClearPaymentStatus,
}: TrialCreditsV2ScreenProps) => {
  const { title, subtitle, offer, button, link, footer, upsellsSteps } = content;

  return (
    <Root>
      <Header buttonBack={false} />
      <Content>
        <UpsellsStepper steps={upsellsSteps} />
        <TitleStyled>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <SectionPayment upsell={trialCreditsUpsell} content={offer} />
        <ButtonWrapper>
          <Button onClick={onBuyCredits} disabled={isLoading} loading={isLoading}>
            {button}
          </Button>
          <RouteLink disabled={isLoading} onClick={onSkip}>
            {link}
          </RouteLink>
        </ButtonWrapper>
        <SectionFooter content={footer} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onBuyCredits}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </Content>
    </Root>
  );
};

export default TrialCreditsV2Screen;
