import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  margin-bottom: 64px;
  padding: 16px 12px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: flex-start;
`;

export const ExpertImageWrapperStyled = styled.div`
  position: relative;

  &::after {
    position: absolute;
    top: -4px;
    right: -4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.error300};
    content: '1';
  }
`;

export const ExpertProfileStyled = styled.div`
  margin-left: 16px;
`;

export const TitleStyled = styled.h4`
  font-size: 18px;
`;

export const SubtitleStyled = styled.p`
  font-size: 14px;
`;

export const ExpertRatingStyled = styled.span`
  display: flex;
  flex-shrink: 0;
  font-weight: 700;
`;

export const StarImageStyled = styled(Image)`
  margin-left: 4px;
`;

export const MessageStyled = styled.p`
  margin-top: 12px;
  padding: 8px 12px;
  border: 1px solid #ededed;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  line-height: 21px;
  background-color: #fafafa;
`;
