import styled from 'styled-components';
import Checkbox from 'core/common/components/Checkbox';
import { DisclaimerText } from '../SectionDisclaimer';

export const CheckboxStyled = styled(Checkbox)<{ isConsentErrorOccured: boolean }>`
  display: flex;
  margin-top: 26px;

  &::before {
    border-color: ${({ theme, isConsentErrorOccured }) =>
      isConsentErrorOccured ? theme.colors.error : 'initial'};
  }

  ${DisclaimerText} {
    margin-top: 0;
    text-align: left;
    color: ${({ theme, isConsentErrorOccured }) =>
      isConsentErrorOccured ? theme.colors.error : theme.colors.typography700};
  }
`;
