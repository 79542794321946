import styled from 'styled-components';
import { InfoButton } from 'core/common/components/Quiz/styled';
import TitleComponent from 'core/common/components/Title';
import Image from '../../Image';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 410px;
  margin-top: 18px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
  }
`;

export const MobileImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const DesktopImageWrapper = styled.div`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
  }
`;

export const Title = styled(TitleComponent)`
  width: 100%;
  margin-top: 28px;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const Text = styled.p`
  width: 100%;
  margin-bottom: 48px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ButtonsWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
  }
`;

export const StyledButton = styled(InfoButton)`
  width: 50%;
  height: 49px;
  margin: 0;
  margin-right: 10px;
  border: ${(props) => `1px solid ${props.theme.colors.dark}`};
  border-radius: 16px;
  color: ${(props) => props.theme.colors.dark};
  background: transparent;
`;

export const StyledImage = styled(Image)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
  }
`;
