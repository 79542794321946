import styled from 'styled-components';
import InputComponent from 'core/common/components/Input';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import { QuizButton } from 'core/common/components/Quiz/styled';
import { TitleText } from 'core/common/components/Title';
import EmailSuggestionsList from '../../EmailSuggestionsList';

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: flex-start;
  }
`;

export const Title = styled(TitleText)`
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

export const Subtitle = styled.p`
  margin-top: -2px;
  margin-left: 4px;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
`;

export const Policy = styled(PolicyComponent)`
  max-width: 214px;
  margin-top: 60px;
  color: ${({ theme }) => theme.colors.typography600};

  ${NavLink} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.infoLight};
  }
`;

export const Button = styled(QuizButton)`
  height: 50px;
  margin-top: 8px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 #00000040;
`;

export const Input = styled(InputComponent)`
  border: 1px solid ${({ theme }) => theme.colors.typography600};
`;

export const StyledEmailSuggestionsList = styled(EmailSuggestionsList)`
  z-index: 3;
`;

export const InputCaption = styled.p`
  margin-top: 12px;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography500};
`;
