import { useState } from 'react';
import Header from 'core/common/components/Header';
import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SummaryScreenProps as SummaryProps } from 'core/funnel/entities';
import {
  ContainerStyled,
  TitleStyled,
  CardStyled,
  CardTitleStyled,
  ProgressStyled,
  ProgressIndicatorStyled,
  ProgressIndicatorCaptionStyled,
  DisclaimerWrapperStyled,
  DisclaimerStyled,
  DisclaimerIconStyled,
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  InfoWrapperStyled,
  InfoTitleStyled,
  InfoValueStyled,
  InfoCardStyled,
  InfoImageWrapperStyled,
  InfoImageStyled,
  FooterStyled,
  ButtonStyled,
} from './styled';

const Summary = ({ content, hasButtonBack, goBack, goNext }: SummaryProps) => {
  const { title, toxicLevel, generalInfo, buttonText } = content;

  const [toxicRelationshipValue] = useState(() => getRandomNumberWithinRange(60, 95));

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={goBack} />
      <ContainerStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <CardStyled>
          <CardTitleStyled dangerouslySetInnerHTML={prepareHtml(toxicLevel.title)} />
          <ProgressStyled>
            {toxicLevel.defaultProgress && (
              <ProgressIndicatorStyled progress={toxicLevel.defaultProgress.value}>
                <ProgressIndicatorCaptionStyled
                  dangerouslySetInnerHTML={prepareHtml(toxicLevel.defaultProgress.caption)}
                />
              </ProgressIndicatorStyled>
            )}
            <ProgressIndicatorStyled
              progress={toxicRelationshipValue}
              defaultProgress={toxicLevel.defaultProgress?.value}
              shouldAnimate
            >
              <ProgressIndicatorCaptionStyled
                dangerouslySetInnerHTML={prepareHtml(toxicLevel.progress.caption)}
              />
            </ProgressIndicatorStyled>
          </ProgressStyled>
          <DisclaimerWrapperStyled>
            <DisclaimerIconStyled
              src={toxicLevel.disclaimer.icon.src}
              alt={toxicLevel.disclaimer.icon.alt}
              width={20}
              height={20}
            />
            <DisclaimerStyled dangerouslySetInnerHTML={prepareHtml(toxicLevel.disclaimer.text)} />
          </DisclaimerWrapperStyled>
        </CardStyled>
        <InfoCardStyled>
          <ListStyled>
            {generalInfo.items.map((item) => (
              <ListItemStyled key={item.value}>
                <ListIconStyled src={item.icon.src} alt={item.icon.alt} width={24} height={24} />
                <InfoWrapperStyled>
                  <InfoTitleStyled dangerouslySetInnerHTML={prepareHtml(item.title)} />
                  <InfoValueStyled dangerouslySetInnerHTML={prepareHtml(item.value)} />
                </InfoWrapperStyled>
              </ListItemStyled>
            ))}
          </ListStyled>
          <InfoImageWrapperStyled>
            <InfoImageStyled src={generalInfo.image.src} alt={generalInfo.image.src} fill />
          </InfoImageWrapperStyled>
        </InfoCardStyled>
        <FooterStyled>
          <ButtonStyled fullWidth onClick={goNext}>
            {buttonText}
          </ButtonStyled>
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default Summary;
