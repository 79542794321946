import styled from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import InputComponent from 'core/common/components/Input';

const transitionValue = 'all 0.6s ease 0.3s';

export const TitleStyled = styled.h2`
  margin-top: 32px;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 135%;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
    font-size: 26px;
  }
`;

export const SubtitleStyled = styled.p`
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 135%;
  text-align: left;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const InputStyled = styled(InputComponent)`
  border: 1px solid ${({ theme }) => theme.colors.typography300};
  font-size: 16px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.typography300};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 16px 15px;
    border-radius: 30px;
    font-size: 20px;
  }
`;

export const ContainerStyled = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 400px;
  }
`;
export const ContentStyled = styled.div`
  position: relative;
  flex-grow: 1;
  transition: ${transitionValue};
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  transition: ${transitionValue};

  &:focus-within {
    flex-grow: 0;
    ${ContentStyled} {
      flex-grow: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    &:focus-within {
      ${ContentStyled} {
        flex-grow: 1;
      }
    }
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 5px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 #00000040;
`;

export const SkipStyled = styled(TextButton)`
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
  cursor: pointer;
`;
