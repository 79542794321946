import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellsScreen from 'core/common/components/QuizScreens/Upsells';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellsScreenAttributes } from 'core/funnel/entities';
import { Upsell } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { getCalculatedUpsell } from 'core/offers/mappers';
import { getSelectedOffer } from 'core/offers/store';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellsProps = {
  id: string;
};

const Upsells = ({ id }: UpsellsProps) => {
  const [selectedProduct, setSelectedProduct] = useState('');

  const selectedSubscription = useSelector(getSelectedOffer);

  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { palmistryUpsellsOffer, numerologyUpsellsOffer, tarotUpsellsOffer, ultraPackOffer } =
    useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const { createAccount } = useCreateAskNebulaAccount();

  const isCheapestPlanSelected = selectedSubscription?.prices.trialPrice === 1;

  const attributes = screenAttributes as UpsellsScreenAttributes;

  const upsellsBasedOnSelectedPlan = useMemo(() => {
    // TODO: use 1 map of upsells in config
    const { tarotFirstUpsells, ultraPackFirstUpsells } = attributes.content;

    if (isCheapestPlanSelected) {
      return tarotFirstUpsells;
    }

    return ultraPackFirstUpsells;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheapestPlanSelected]);

  // TODO think how to move to mappers
  const numerologyAnalysis = getCalculatedUpsell(
    upsellsBasedOnSelectedPlan.numerologyAnalysis,
    numerologyUpsellsOffer,
  );

  const tarotReading = getCalculatedUpsell(
    upsellsBasedOnSelectedPlan.tarotReading,
    tarotUpsellsOffer,
  );

  const palmistryGuide = getCalculatedUpsell(
    upsellsBasedOnSelectedPlan.palmistryGuide,
    palmistryUpsellsOffer,
  );

  const ultraPack = getCalculatedUpsell(upsellsBasedOnSelectedPlan.ultraPack, ultraPackOffer);

  const upsells: Upsell[] = [numerologyAnalysis, tarotReading, palmistryGuide, ultraPack];
  const sortedUpsells = upsells.sort((a, b) => a.order - b.order);

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    recurringPaymentRequest(selectedProduct);
  };

  const goNext = () => {
    createAccount();
    next();
  };

  const skip = () => {
    analyticsService.upsellLpSkip();
    goNext();
  };

  const selectProduct = (product: string) => {
    setSelectedProduct(product);
  };

  useEffect(() => {
    const [firstUpsell] = upsells.filter(({ order }) => order === 1);

    if (!firstUpsell.offerId) {
      return;
    }

    setSelectedProduct(firstUpsell.offerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsells.length]);

  useEffect(() => {
    analyticsService.upsellLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellLpGetReport(selectedProduct);
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  return (
    <UpsellsScreen
      isPurchaseLoading={isPurchaseLoading}
      selectedProduct={selectedProduct}
      upsells={sortedUpsells}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      onGetReport={getReport}
      onSkip={skip}
      selectProduct={selectProduct}
      paymentError={error}
      {...(screenAttributes as UpsellsScreenAttributes)}
    />
  );
};

export default Upsells;
