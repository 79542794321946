import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import Button from 'core/common/components/ButtonDeprecated';
import Carousel, { carouselClasses } from 'core/common/components/Carousel';
import CircularProgressV2, {
  ProgressBarStyled,
  TextStyled,
} from 'core/common/components/CircularProgressV2';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';
import { getColorByProgress } from './utils';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
`;

export const HeadingStyled = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const TitleStyled = styled.h2`
  margin-right: 16px;
  font-size: 24px;
  line-height: 135%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const CircularProgressV2Styled = styled(CircularProgressV2)`
  flex-shrink: 0;

  ${ProgressBarStyled} {
    transition: stroke 0.3s ease;
    stroke: ${({ progress }) => getColorByProgress(progress)};
  }

  ${TextStyled} {
    font-size: 18px;
    fill: ${({ progress }) => getColorByProgress(progress)};
  }
`;

export const AspectRatioStyled = styled(AspectRatio)`
  margin-right: -16px;
  margin-left: -16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
`;

export const FooterStyled = styled.footer`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  margin-right: -16px;
  margin-left: -16px;
  padding: 20px 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    position: static;
    margin-top: unset;
    margin-top: -30px;
    padding-top: 0;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const CarouselStyled = styled(Carousel)`
  margin-right: -16px;
  margin-bottom: 24px;

  ${carouselClasses.track} {
    display: flex;
    padding: 10px 0;
  }

  ${carouselClasses.slide} {
    height: inherit;
    padding-right: 12px;

    & > div {
      height: 100%;
    }
  }

  ${carouselClasses.dots} {
    bottom: -25px;

    li {
      margin: 0;
    }

    button::before {
      font-size: 10px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: -12px;
    margin-bottom: 76px;
    margin-left: -12px;

    ${carouselClasses.slide} {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
`;

export const ArrowIconStyled = styled(Icon)`
  width: 14px;
  height: 14px;
  fill: #818181;
`;

export const SliderPrevButtonStyled = styled.button`
  top: 50%;
  left: -40px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f7f7f7;

  ${ArrowIconStyled} {
    transform: rotate(90deg);
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.typography200};
  }

  &::before {
    display: none;
  }
`;

export const SliderNextButtonStyled = styled(SliderPrevButtonStyled)`
  right: -40px;
  left: unset;

  ${ArrowIconStyled} {
    transform: rotate(-90deg);
  }

  &::before {
    transform: rotate(-90deg);
  }
`;
