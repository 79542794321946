import { AnswerScreenAttributes, ScreenAttributes } from 'core/funnel/entities';
import { StyledEmoji } from './styled';

export type EmojisProps = {
  attributes: ScreenAttributes[];
};

type Answer = AnswerScreenAttributes['content']['answers'];

const Emojis = ({ attributes }: EmojisProps) => {
  const answers: Answer = [];

  attributes.forEach((attr) => {
    if ('content' in attr) {
      const nextScreenAnswers = attr.content?.answers as Answer;

      if (nextScreenAnswers) {
        answers.push(...nextScreenAnswers);
      }
    }
  });

  const emojis = answers.map((answer, i) => {
    const emoji = answer?.emoji;

    if (emoji) {
      return <StyledEmoji key={emoji + i} emoji={emoji} />;
    }
  });

  return <>{emojis}</>;
};

export default Emojis;
