import { ChangeEvent, useState } from 'react';
import Select from 'core/common/components/Select';
import { hoursList, minutesList, partOfDay } from 'core/common/constants';
import { extractTimeParts, normalizeTime } from 'core/common/utils/time';
import { ButtonStyled, SelectContainerStyled } from './styled';

type SelectTimePickerProps = {
  labels: {
    next: string;
  };
  userTime?: string;
  onSubmit: (date: string) => void;
};

export const SelectTimePicker = ({ labels, onSubmit, userTime }: SelectTimePickerProps) => {
  const [time, setTime] = useState(extractTimeParts(userTime));

  const handleTimeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onClick = () => {
    onSubmit(normalizeTime(time, 'HH:mm:ss'));
  };

  return (
    <>
      <SelectContainerStyled>
        <Select options={hoursList} name="hours" value={time.hours} onChange={handleTimeChange} />
        <Select
          options={minutesList}
          name="minutes"
          value={time.minutes}
          onChange={handleTimeChange}
        />
        <Select options={partOfDay} name="part" value={time.part} onChange={handleTimeChange} />
      </SelectContainerStyled>
      <ButtonStyled onClick={onClick}>{labels.next}</ButtonStyled>
    </>
  );
};
