import { MINUTE, SECOND } from '../constants';

export const convertTimeToMinutesAndSeconds = (
  time: number,
): { minutes: number; seconds: number } => {
  const minutes = Math.floor(time / MINUTE);
  const seconds = (time % MINUTE) / SECOND;

  return { minutes, seconds };
};
