import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const PickerWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding-top: 20px;
  border: 1px solid rgba(15, 15, 15, 0.12);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fafafa;
`;

export const BlockWrapperStyled = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: calc(100% - 32px);
  max-width: 450px;
  height: 56px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
`;
