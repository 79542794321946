import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;

export const AspectRatioImageWrapper = styled.div`
  position: relative;
  margin-top: 24px;
  padding-top: 58%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
`;

export const CardsWrapperStyled = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  margin-top: -15px;
`;

export const ListIconStyled = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 6px;
  fill: ${({ theme }) => theme.colors.white};
`;

export const CardStyled = styled.div<{ variant?: 'default' | 'gradient' }>`
  flex-shrink: 0;
  width: 50%;
  padding: 20px 12px;
  border-radius: 20px;

  ${({ theme, variant = 'default' }) => {
    switch (variant) {
      case 'gradient':
        return `
          color: ${theme.colors.white};
          background: linear-gradient(180deg, #1D1E55 0%, #272463 25%, #3B2F7B 50%, #4F358D 75%, #6939A2 100%);
        `;
      default:
        return `
          color: ${theme.colors.typography900};
          background: ${theme.colors.themeBackgroundSemiLight};
        `;
    }
  }}
  &:last-child {
    width: 55%;
    margin-left: -5%;
  }

  ${ListIconStyled} {
    fill: ${({ theme, variant = 'default' }) =>
      variant === 'gradient' ? theme.colors.white : theme.colors.black};
  }
`;

export const CardTitleStyled = styled.h4`
  font-size: 18px;
`;

export const OptionsListStyled = styled.ul`
  margin-top: 16px;
`;

export const OptionsListItemStyled = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const ListOptionStyled = styled.span`
  font-size: 12px;
`;
