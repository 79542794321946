import { useEffect, useRef, useState } from 'react';
import { config, useTransition } from 'react-spring';
import Header from 'core/common/components/Header';
import { config as appConfig } from 'core/config';
import { ScanAnalysingScreenProps } from 'core/funnel/entities';
import {
  CircleSvg,
  Container,
  Image,
  ImageContainer,
  InnerCircle,
  LineOverlay,
  OuterCircle,
  StyledLinearProgress,
  StyledPath,
  StyledSvg,
  StyledTitle,
  Subtitle,
} from './styled';

const ScanAnalysingScreen = ({
  onBack,
  content,
  goNext,
  photo,
  width,
  height,
  fingersCoordinates,
  linesCoordinates,
}: ScanAnalysingScreenProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isAnimatingCircles, setIsAnimatingCircles] = useState(true);
  const animatedFingers = useRef(0);

  const { steps, title } = content;
  const { palmAnimationDuration } = appConfig;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => {
        if (prevStep === content.steps.length - 1) {
          clearInterval(interval);
          setTimeout(() => goNext(), palmAnimationDuration);
          return prevStep;
        }
        return prevStep + 1;
      });
    }, palmAnimationDuration);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const circleTransitions = useTransition(fingersCoordinates, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: config.molasses,
    trail: palmAnimationDuration,
    onRest: () => {
      animatedFingers.current++;

      if (animatedFingers.current !== fingersCoordinates.length) return;
      setIsAnimatingCircles(false);
    },
  });

  return (
    <>
      <Header onBack={onBack} />
      <Container>
        <StyledTitle>{title}</StyledTitle>
        <Subtitle>{steps[activeStep]}</Subtitle>
        <StyledLinearProgress
          variant="determinate"
          value={((activeStep + 1) / content.steps.length) * 100}
        />
        <ImageContainer width={width} height={height}>
          <Image src={photo} alt="palm photo" width={width} height={height} />
          <LineOverlay>
            <StyledSvg width={width} height={height}>
              {circleTransitions(({ opacity }, item) => (
                <CircleSvg key={`${item.x}${item.y}`} style={{ opacity }} data-testid="circleSvg">
                  <OuterCircle cx={item.x} cy={item.y} r="10" />
                  <InnerCircle cx={item.x} cy={item.y} r="5" />
                </CircleSvg>
              ))}
              {!isAnimatingCircles &&
                Object.keys(linesCoordinates).map((key, index) => (
                  <StyledPath
                    key={index}
                    delay={index}
                    color={linesCoordinates[key].color}
                    d={`M ${linesCoordinates[key].coordinates
                      .map((point) => `${point.x},${point.y}`)
                      .join(' L ')}`}
                    data-testid="lineSvg"
                  />
                ))}
            </StyledSvg>
          </LineOverlay>
        </ImageContainer>
      </Container>
    </>
  );
};

export default ScanAnalysingScreen;
