import styled from 'styled-components';
import BackgroundGradient from 'core/common/components/BackgroundGradient';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import TitleComponent from 'core/common/components/Title';

export const Background = styled(BackgroundGradient)`
  background: linear-gradient(0deg, #eaeef7 9.74%, #d3c3fb 87.02%);
`;
export const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 21px;
  }
`;

export const PartyIcon = styled(EmojiComponent)`
  width: 60px;
  height: 60px;
  margin-right: auto;
  margin-bottom: 9px;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 24px;
  }
`;

export const Title = styled(TitleComponent)`
  font-weight: 800;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  max-width: 360px;
  height: 50px;
  margin-bottom: 25px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600;
  background: ${(props) => props.theme.colors.infoLight};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 28px;
  }
`;

export const SectionSubscriptionDisclaimer = styled.p`
  max-width: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const Underline = styled.span`
  font-weight: 600;
`;

export const Bold = styled.b`
  font-weight: 600;
`;
