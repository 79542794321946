import { HTMLAttributes } from 'react';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionBannerClickableAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  BannerStyled,
  BannerTitleStyled,
  BannerTextStyled,
  BannerContentStyled,
  ButtonStyled,
  IconStyled,
  BackgroundImageStyled,
} from './styled';

type SectionBannerClickableProps = {
  content: SectionBannerClickableAttributes['content'];
} & Omit<HTMLAttributes<HTMLDivElement>, 'content'>;

const SectionBannerClickable = ({ content, className, onClick }: SectionBannerClickableProps) => {
  const { title, bannerTitle, bannerText, backgroundImage } = content;

  return (
    <WrapperStyled className={className} role="button" onClick={onClick}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <BannerStyled>
        {backgroundImage && (
          <BackgroundImageStyled src={backgroundImage.src} alt={backgroundImage.alt} fill />
        )}
        <BannerContentStyled>
          <BannerTitleStyled dangerouslySetInnerHTML={prepareHtml(bannerTitle)} />
          <BannerTextStyled dangerouslySetInnerHTML={prepareHtml(bannerText)} />
        </BannerContentStyled>
        <ButtonStyled>
          <IconStyled name="arrow-simple" />
        </ButtonStyled>
      </BannerStyled>
    </WrapperStyled>
  );
};

export default SectionBannerClickable;
