import styled, { keyframes } from 'styled-components';

const loaderFadeOut = keyframes`
  0% {
    opacity: 1;
  } 50% {
    opacity: 1;
  } 100% {
    opacity: 0
  }
`;

export const PageLoaderStyled = styled.div<{
  animationDurationInSec: number;
  hideLoaderDelayInSec: number;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.typography100};
  opacity: 0;
  animation-name: ${loaderFadeOut};
  animation-duration: ${({ animationDurationInSec, hideLoaderDelayInSec }) =>
    animationDurationInSec + hideLoaderDelayInSec}s;
`;

export const LottieWrapperStyled = styled.div`
  width: 240px;
  height: 240px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 320px;
    height: 320px;
  }
`;

export const TextStyled = styled.p`
  margin-top: -25px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: -50px;
  }
`;
