import { useEffect } from 'react';
import { useGetAuthorizationToken } from 'core/auth/hooks';
import LoadingAskNatalChartScreen from 'core/common/components/QuizScreens/LoadingAskNatalChart';
import { useRedirectToAskNebula, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { LoadingAskNatalChartScreenAttributes } from 'core/funnel/entities';

type LoadingAskNatalChartProps = {
  id: string;
};

const LoadingAskNatalChart = ({ id }: LoadingAskNatalChartProps) => {
  const { screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { token } = useGetAuthorizationToken();
  const { redirectToQuizCatalogue } = useRedirectToAskNebula();

  const onLoadingFinished = () => {
    redirectToQuizCatalogue(token);
  };

  useEffect(() => {
    analyticsService.onLoadingPageOpened();
  }, [analyticsService]);

  return (
    <LoadingAskNatalChartScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as LoadingAskNatalChartScreenAttributes)}
    />
  );
};

export default LoadingAskNatalChart;
