import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';
import {
  ColonStyled,
  MinWrapperStyled,
  SecWrapperStyled,
  TimeValueStyled,
  WrapperStyled,
  TimeLabelStyled,
} from './styled';

export type TimerProps = {
  timeLeft: number;
  minLabel: string;
  secLabel: string;
};

const Timer = ({ timeLeft, minLabel, secLabel }: TimerProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);

  const formattedMinutes = addLeadingZero(minutes);
  const formattedSeconds = addLeadingZero(seconds);

  return (
    <WrapperStyled>
      <MinWrapperStyled>
        <TimeValueStyled>{formattedMinutes}</TimeValueStyled>
        <TimeLabelStyled>{minLabel}</TimeLabelStyled>
      </MinWrapperStyled>
      <ColonStyled>:</ColonStyled>
      <SecWrapperStyled>
        <TimeValueStyled>{formattedSeconds}</TimeValueStyled>
        <TimeLabelStyled>{secLabel}</TimeLabelStyled>
      </SecWrapperStyled>
    </WrapperStyled>
  );
};

export default Timer;
