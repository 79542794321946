import SocialProofScreen from 'core/common/components/QuizScreens/SocialProof';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { SocialProofScreenAttributes } from 'core/funnel/entities';

type SocialProofProps = {
  id: string;
};

const SocialProof = ({ id }: SocialProofProps) => {
  const { next, back, screenAttributes } = useQuizStep(id);

  return (
    <SocialProofScreen
      goNext={next}
      goBack={back}
      {...(screenAttributes as SocialProofScreenAttributes)}
    />
  );
};

export default SocialProof;
