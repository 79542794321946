import WhichFigureScreen from 'core/common/components/QuizScreens/WhichFigure';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { WhichFigureScreenAttributes } from 'core/funnel/entities';

export type WhichFigureProps = {
  id: string;
};

const WhichFigure = ({ id }: WhichFigureProps) => {
  const { back, screenAttributes, next, updateUserPreferences } = useQuizStep(id);

  const { dataKey } = screenAttributes as WhichFigureScreenAttributes;

  const selectFigureAndContinue = (selectedFigure: string) => {
    updateUserPreferences(dataKey, selectedFigure);
    next({ value: selectedFigure });
  };

  return (
    <WhichFigureScreen
      onBack={back}
      goNext={selectFigureAndContinue}
      {...(screenAttributes as WhichFigureScreenAttributes)}
    />
  );
};

export default WhichFigure;
