import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialPaymentWitchPowerScreenProps } from 'core/funnel/entities';
import {
  BlurredWrapper,
  Container,
  GuideContent,
  GuideHeader,
  GuideHeaderListItem,
  ItemText,
  ItemTitle,
  ListItem,
  LockImage,
  Title,
} from './styled';

type SectionPersonalizedGuideProps = {
  content: TrialPaymentWitchPowerScreenProps['content']['personalizedGuide'];
};

const SectionPersonalizedGuide = ({ content }: SectionPersonalizedGuideProps) => {
  const { title, image, guideHeaderContent, guideContent, lockImage } = content;

  return (
    <Container>
      <Title>{title}</Title>
      <BlurredWrapper>
        <Image src={image.src} alt={image.alt} width={306} height={266} />
        <GuideHeader>
          {guideHeaderContent.map((item) => (
            <GuideHeaderListItem key={item.title}>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemText dangerouslySetInnerHTML={prepareHtml(item.text)} />
            </GuideHeaderListItem>
          ))}
        </GuideHeader>
        <GuideContent>
          {guideContent.map((item) => (
            <ListItem key={item.title}>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemText dangerouslySetInnerHTML={prepareHtml(item.text)} />
            </ListItem>
          ))}
        </GuideContent>
        <LockImage src={lockImage.src} alt={lockImage.alt} width={44} height={44} />
      </BlurredWrapper>
    </Container>
  );
};

export default SectionPersonalizedGuide;
