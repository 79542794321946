import { useCallback, useEffect } from 'react';
// eslint-disable-next-line max-len
import TrialPaymentWithDiscountPalmistryV4Screen from 'core/common/components/QuizScreens/TrialPaymentWithDiscountPalmistryV4';
import { useCountdown, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import {
  FunnelName,
  TrialPaymentWithDiscountPalmistryV4ScreenAttributes,
} from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';

type TrialPaymentWithDiscountPalmistryV4Props = {
  id: string;
};

const TrialPaymentWithDiscountPalmistryV4 = ({ id }: TrialPaymentWithDiscountPalmistryV4Props) => {
  const { next, screenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { specialPlanCountdown } = useCountdown({ key: `${FunnelName.PALMISTRY}-with-discount` });

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffer, selectedOffer } = useOffers();

  const offer = isTimeUp ? timeUpOffer : selectedOffer;

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
    analyticsService.landingPageClick('trial_billing_secret_discount', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing_secret_discount');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
      trialPrice: offer.prices.trialPrice,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentWithDiscountPalmistryV4Screen
      offer={offer}
      isTimeUp={isTimeUp}
      timeLeft={specialPlanCountdown}
      goNext={next}
      getReport={getReport}
      {...(screenAttributes as TrialPaymentWithDiscountPalmistryV4ScreenAttributes)}
    />
  );
};

export default TrialPaymentWithDiscountPalmistryV4;
