import styled from 'styled-components';
import { ContainerInnerStyled, SlideStyled } from 'core/common/components/WheelPicker';

export const DefaultWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  ${ContainerInnerStyled} {
    border-top: 1px solid ${({ theme }) => theme.colors.typography200};
    border-bottom: 1px solid ${({ theme }) => theme.colors.typography200};
  }
`;

export const PickerItemStyled = styled.div`
  width: 76px;
  height: 450px;
  margin-right: 10px;
  margin-left: 10px;

  ${SlideStyled} {
    justify-content: center;
  }
`;
