import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';
import Image from '../Image';
import NavLink from '../Link';
import Title, { TitleText } from '../Title';

export const WrapperStyled = styled.div`
  position: relative;
  padding: 30px 15px 20px;
  border-radius: 20px;
  background: ${(props) => props.theme.gradients.primary};

  & ${TitleText} {
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    text-align: start;
    color: ${(props) => props.theme.colors.typography100};
  }
`;

export const TitleStyled = styled(Title)`
  font-size: 20px;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.typography100};
`;

export const LinkStyled = styled(NavLink)`
  font-weight: bold;
  text-decoration: underline;
`;

export const GuaranteeImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    right: 30px;
  }
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
  margin-right: 8px;
`;

export const GuaranteeBadgeStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
