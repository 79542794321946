import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionAdvantagesWithEmojiAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  ListStyled,
  ListItemStyled,
  IconStyled,
  TextStyled,
  EmojiStyled,
} from './styled';

type SectionAdvantagesWithEmojiProps = {
  content: SectionAdvantagesWithEmojiAttributes['content'];
  className?: string;
};

const SectionAdvantagesWithEmoji = ({ content, className }: SectionAdvantagesWithEmojiProps) => {
  const { title, list, listDecorationEmoji } = content;

  return (
    <WrapperStyled className={className}>
      {title && <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />}
      <ListStyled>
        {list.map(({ icon, text }) => (
          <ListItemStyled key={text}>
            <IconStyled src={icon.src} alt={icon.alt} width={24} height={24} />
            <TextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
          </ListItemStyled>
        ))}
        {listDecorationEmoji && <EmojiStyled>{listDecorationEmoji}</EmojiStyled>}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionAdvantagesWithEmoji;
