import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-left: 12px;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.themeBackgroundLight};
`;

export const Title = styled.h4`
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 135%;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const ImageStyled = styled(Image)`
  align-self: center;
`;
