import { BASE_ROUTES } from 'core/common/constants';
import { SectionPaywallFooterAttributes } from 'core/funnel/entities';
import { FooterStyled, LinkStyled, DividerStyled } from './styled';

type SectionPaywallFooterProps = {
  content: SectionPaywallFooterAttributes['content'];
};

const SectionPaywallFooter = ({ content }: SectionPaywallFooterProps) => {
  const { privacyTitle, termsTitle } = content;

  return (
    <FooterStyled>
      <LinkStyled href={BASE_ROUTES.TECH_PRIVACY_POLICY}>{privacyTitle}</LinkStyled>
      <DividerStyled />
      <LinkStyled href={BASE_ROUTES.TECH_TERMS}>{termsTitle}</LinkStyled>
    </FooterStyled>
  );
};

export default SectionPaywallFooter;
