import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { BASE_ROUTES } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { RelationshipProgressScreenProps } from 'core/funnel/entities';
import CustomersProgress from './CustomersProgress';
import Highlights from './Highlights';
import RelationshipChart from './RelationshipChart';
import {
  TitleStyled,
  ContentStyled,
  SubtitleStyled,
  FooterStyled,
  FooterLinkStyled,
  LinksSeparatorStyled,
  StickyButtonStyled,
  AdvantagesStyled,
} from './styled';

const RelationshipProgressScreen = ({
  content,
  goNext,
  goBack,
}: RelationshipProgressScreenProps) => {
  const { title, subtitle, chart, advantages, highlights, progress, footer, fixedButton } = content;

  return (
    <>
      <QuizBackground variant="light" />
      <Header onBack={goBack} />
      <ContentStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <RelationshipChart {...chart} />
        <AdvantagesStyled {...advantages} />
        <Highlights {...highlights} />
        <CustomersProgress {...progress} />
        <StickyButtonStyled fullWidth onClick={goNext}>
          {fixedButton}
        </StickyButtonStyled>
        <FooterStyled>
          <FooterLinkStyled href={BASE_ROUTES.TECH_PRIVACY_POLICY}>
            {footer.privacyButton}
          </FooterLinkStyled>
          <LinksSeparatorStyled>|</LinksSeparatorStyled>
          <FooterLinkStyled href={BASE_ROUTES.TECH_TERMS}>{footer.termsButton}</FooterLinkStyled>
        </FooterStyled>
      </ContentStyled>
    </>
  );
};

export default RelationshipProgressScreen;
