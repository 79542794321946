import { FunnelImage } from 'core/funnel/entities';
import {
  StrategyTitleStyled,
  TitleStyled,
  WithoutStrategyWrapperStyled,
  EmojiStyled,
  StrategyListStyled,
  StrategyListItemStyled,
  ListItemTextStyled,
  ListItemIconStyled,
  FooterImageStyled,
  FooterStyled,
  WithStrategyWrapperStyled,
  CardsWrapperStyled,
  ActionButtonStyled,
} from './styled';

type StrategyContent = {
  title: string;
  emoji: string;
  list: Array<{
    text: string;
    icon: FunnelImage;
  }>;
  image: FunnelImage;
};

type LifeStrategyProps = {
  title: string;
  withoutStrategy: StrategyContent;
  withStrategy: StrategyContent;
  button: string;
  onMoveToOffer: () => void;
};

const LifeStrategy = ({
  title,
  withoutStrategy,
  withStrategy,
  button,
  onMoveToOffer,
}: LifeStrategyProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <CardsWrapperStyled>
        <WithoutStrategyWrapperStyled>
          <StrategyTitleStyled>
            <EmojiStyled>{withoutStrategy.emoji}</EmojiStyled>
            {withoutStrategy.title}
          </StrategyTitleStyled>
          <StrategyListStyled>
            {withoutStrategy.list.map(({ text, icon }) => (
              <StrategyListItemStyled key={text}>
                <ListItemIconStyled src={icon.src} alt={icon.alt} width={12} height={12} />
                <ListItemTextStyled>{text}</ListItemTextStyled>
              </StrategyListItemStyled>
            ))}
          </StrategyListStyled>
          <FooterStyled>
            <FooterImageStyled
              src={withoutStrategy.image.src}
              alt={withoutStrategy.image.alt}
              fill
            />
          </FooterStyled>
        </WithoutStrategyWrapperStyled>
        <WithStrategyWrapperStyled>
          <StrategyTitleStyled>
            <EmojiStyled>{withStrategy.emoji}</EmojiStyled>
            {withStrategy.title}
          </StrategyTitleStyled>
          <StrategyListStyled>
            {withStrategy.list.map(({ text, icon }) => (
              <StrategyListItemStyled key={text}>
                <ListItemIconStyled src={icon.src} alt={icon.alt} width={12} height={12} />
                <ListItemTextStyled>{text}</ListItemTextStyled>
              </StrategyListItemStyled>
            ))}
          </StrategyListStyled>
          <FooterStyled variant="blue">
            <FooterImageStyled src={withStrategy.image.src} alt={withStrategy.image.alt} fill />
          </FooterStyled>
        </WithStrategyWrapperStyled>
      </CardsWrapperStyled>
      <ActionButtonStyled impulseScale onClick={onMoveToOffer}>
        {button}
      </ActionButtonStyled>
    </>
  );
};

export default LifeStrategy;
