import question from 'core/common/assets/images/hintQuiz/question.png';
import {
  AgreeButton,
  ButtonWrapper,
  CloseButtonStyled,
  DisagreeButton,
  Image,
  ImageContainer,
  QuestionWrapper,
  Title,
  Wrapper,
} from './styled';

export type ScanQuestionProps = {
  title: string;
  confirmButtonText: string;
  discardButtonText: string;
  onClose: (value: 'closed' | 'yes' | 'no') => void;
};

const ScanQuestion = ({
  title,
  confirmButtonText,
  discardButtonText,
  onClose,
}: ScanQuestionProps) => {
  return (
    <Wrapper>
      <CloseButtonStyled onClick={() => onClose('closed')} />
      <ImageContainer>
        <Image src={question} alt="question" width={134} height={137} />
      </ImageContainer>
      <QuestionWrapper>
        <Title>{title}</Title>
        <ButtonWrapper>
          <DisagreeButton onClick={() => onClose('no')}>{discardButtonText}</DisagreeButton>
          <AgreeButton onClick={() => onClose('yes')}>{confirmButtonText}</AgreeButton>
        </ButtonWrapper>
      </QuestionWrapper>
    </Wrapper>
  );
};

export default ScanQuestion;
