import { Scan as ScanScreen } from 'core/common/components/QuizScreens/Scan';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ScanScreenAttributes } from 'core/funnel/entities';

export type ScanProps = {
  id: string;
};

const Scan = ({ id }: ScanProps) => {
  const { back, screenAttributes, next, step } = useQuizStep(id);

  return (
    <ScanScreen
      step={step}
      onBack={back}
      goNext={next}
      {...(screenAttributes as ScanScreenAttributes)}
    />
  );
};

export default Scan;
