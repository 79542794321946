import { CarouselSettings } from 'core/common/components/Carousel';
import { SECOND } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import {
  CarouselStyled,
  DateStyled,
  ImageStyled,
  InfoStyled,
  ItemStyled,
  ListStyled,
  NameStyled,
  RatingStyled,
  TextStyled,
} from './styled';

type ReviewsProps = {
  list: Array<{
    name: string;
    date: string;
    text: string;
    image: string;
  }>;
  className?: string;
};

const settings: CarouselSettings = {
  speed: 600,
  autoplay: true,
  slidesToShow: 1,
  arrows: false,
  pauseOnHover: false,
  autoplaySpeed: 4 * SECOND,
};

const Reviews = ({ list, className }: ReviewsProps) => {
  return (
    <ListStyled className={className}>
      <CarouselStyled {...settings}>
        {list.map(({ name, date, text, image }) => (
          <ItemStyled key={name}>
            <RatingStyled />
            <DateStyled>{date}</DateStyled>
            <TextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
            <InfoStyled>
              <ImageStyled src={image} height={24} width={24} alt={name} />
              <NameStyled>{name}</NameStyled>
            </InfoStyled>
          </ItemStyled>
        ))}
      </CarouselStyled>
    </ListStyled>
  );
};

export default Reviews;
