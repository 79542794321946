import { SectionFaqAttributes } from 'core/funnel/entities';
import FaqList from './FaqList';
import { FaqSectionStyled, TitleStyled } from './styled';

type SectionFaqProps = {
  content: SectionFaqAttributes['content'];
};

const SectionFaq = ({ content }: SectionFaqProps) => {
  return (
    <FaqSectionStyled>
      <TitleStyled>{content.title}</TitleStyled>
      <FaqList list={content.list} />
    </FaqSectionStyled>
  );
};

export default SectionFaq;
