import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import UpsellsHeader from 'core/common/components/UpsellsHeader';
import { TrialCreditsScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionPayment from './SectionPayment';
import {
  ButtonStyled,
  ButtonWrapperStyled,
  ContentStyled,
  RootStyled,
  RouteLinkStyled,
} from './styled';

const TrialCreditsScreen = ({
  content,
  trialCreditsUpsell,
  isLoading,
  resignFormLoaded,
  onBuyCredits,
  onSkip,
  onClearPaymentStatus,
  paymentError,
}: TrialCreditsScreenProps) => {
  const { title, subtitle, offer, button, link, footer } = content;

  return (
    <RootStyled>
      <Header buttonBack={false} />
      <ContentStyled>
        <UpsellsHeader title={title} subtitle={subtitle} />
        <SectionPayment upsell={trialCreditsUpsell} content={offer} />
        <ButtonWrapperStyled>
          <ButtonStyled onClick={onBuyCredits} disabled={isLoading} loading={isLoading}>
            {button}
          </ButtonStyled>
          <RouteLinkStyled disabled={isLoading} onClick={onSkip}>
            {link}
          </RouteLinkStyled>
        </ButtonWrapperStyled>
        <SectionFooter content={footer} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onBuyCredits}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </ContentStyled>
    </RootStyled>
  );
};

export default TrialCreditsScreen;
