import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import Image from 'core/common/components/Image';
import Typography from 'core/common/components/Typography';

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 130px;
  padding-right: 16px;
  padding-left: 16px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
  background-color: white;
`;

export const HeaderImageWrapperStyled = styled(AspectRatio)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 8px;
`;

export const TextStyled = styled(Typography)`
  margin-bottom: 60px;
`;

export const ImageStyled = styled(Image)`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
`;

export const ContentStyled = styled.div`
  z-index: 1;
`;

export const MainImageStyled = styled(Image)`
  display: block;
  margin-right: auto;
  margin-left: auto;
  object-fit: cover;
`;
