import heart from 'core/common/assets/images/upsells/gradientHeartWithHeands.svg';
import { TrialCreditsNatalChartScreenAttributes } from 'core/funnel/entities';
import { Upsell } from 'core/offers/entities';
import {
  Break,
  ConsultationText,
  ConsultationWrapper,
  Container,
  Description,
  Discount,
  DivideLine,
  HighlightText,
  ImageStyled,
  OfferBlock,
  OfferDescriptionWrapper,
  OfferPrice,
  OfferPriceTitle,
  OfferTitle,
  OriginalPrice,
  OriginalPriceTitle,
  PriceWrapper,
} from './styled';

type SectionPaymentProps = {
  plan: Upsell;
  content: TrialCreditsNatalChartScreenAttributes['content']['offer'];
};

const SectionPayment = ({ plan, content }: SectionPaymentProps) => {
  const { title, consultationText, description, priceText, originalPriceText, saveMoneyText } =
    content;

  return (
    <Container>
      <OfferBlock>
        <OfferTitle>{title}</OfferTitle>
        <DivideLine />
        <OfferDescriptionWrapper>
          <ConsultationWrapper>
            <ImageStyled
              src={heart}
              alt="pictogram of hands holding a heart"
              width={44}
              height={44}
            />
            <ConsultationText>
              {consultationText.beforeHighlighted}
              <Break />
              <HighlightText>{consultationText.highlighted}</HighlightText>{' '}
              {consultationText.afterHighlighted}
            </ConsultationText>
          </ConsultationWrapper>
          <Description>{description}</Description>
        </OfferDescriptionWrapper>
        <DivideLine />
        <PriceWrapper>
          <OfferPriceTitle>{priceText} </OfferPriceTitle>
          <OfferPrice>{plan.price}</OfferPrice>
          <br />
          <OriginalPriceTitle>
            {originalPriceText} <OriginalPrice>{plan.oldPrice}</OriginalPrice>
            <Discount>
              {' '}
              {saveMoneyText} {plan.discount}
            </Discount>
          </OriginalPriceTitle>
        </PriceWrapper>
      </OfferBlock>
    </Container>
  );
};

export default SectionPayment;
