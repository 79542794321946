import styled from 'styled-components';
import Advantages from 'core/common/components/Advantages';
import SectionGuarantee from 'core/common/components/QuizScreensSections/SectionGuarantee';
import Highlights from 'core/common/components/QuizScreensSections/SectionHighlights';
import Partners from 'core/common/components/QuizScreensSections/SectionPartners';
import SectionRating from './SectionRating';

export const ContainerMainStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  text-align: center;
`;

export const TitleStyled = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
`;

export const AdvantagesStyled = styled(Advantages)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const PartnersStyled = styled(Partners)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const HighlightsStyled = styled(Highlights)`
  margin-top: 64px;
`;

export const ReviewsCarouselWrapperStyled = styled.div`
  max-width: 100%;
`;

export const IntroOfferWrapperStyled = styled.div``;

export const SectionGuaranteeStyled = styled(SectionGuarantee)`
  background-color: #fafafa;
`;

export const SectionPaymentStyled = styled(SectionRating)`
  margin-top: 64px;
  margin-bottom: 64px;
`;
