import styled, { keyframes } from 'styled-components';
import Image from 'core/common/components/Image';

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Background = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 330px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: white;
  animation-name: ${hiddenText};
  animation-duration: 6s;
`;

export const Title = styled.p`
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 145%;
`;

export const List = styled.ul`
  margin-bottom: -16px;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const FirstMessageStyled = styled(Image)`
  position: absolute;
  left: 30px;
`;

export const SecondMessageStyled = styled(FirstMessageStyled)`
  transform: scaleX(-1);
`;

export const Text = styled.p`
  max-width: 240px;
  min-height: 50px;
  margin-top: 25px;
  margin-bottom: 35px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  color: ${(props) => props.theme.colors.primary300};
`;

export const SecondText = styled(Text)`
  align-self: center;
  margin-left: -10px;
`;

export const ThirdText = styled(Text)`
  max-width: 230px;
  margin-top: 15px;
  margin-left: 60px;
`;

export const AvatarContainer = styled.span`
  align-self: start;
`;

export const Avatar = styled(Image)`
  width: 44px;
  height: 44px;
`;

export const StyledAvatarContainer = styled.span`
  align-self: end;
`;

export const Tip = styled.p`
  margin-top: 27px;
  font-size: 12px;
  line-height: 16px;
`;
