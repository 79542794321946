import AnswerScreen from 'core/common/components/QuizScreens/Answer';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerScreenAttributes } from 'core/funnel/entities';

type AnswerProps = {
  id: string;
};

const Answer = ({ id }: AnswerProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerScreenAttributes;

  const onAnswerClick = async (value: string) => {
    updateUserPreferences(dataKey, value);

    await next({ value });
  };

  return (
    <AnswerScreen
      onBack={back}
      onAnswerClick={onAnswerClick}
      step={step}
      {...(screenAttributes as AnswerScreenAttributes)}
    />
  );
};

export default Answer;
