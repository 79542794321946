import styled from 'styled-components';

export const WrapperStyled = styled.section`
  text-align: center;
`;

export const TitleStyled = styled.h3`
  margin-top: 48px;
  font-size: 24px;
  line-height: 32px;
`;

export const SubtitleStyled = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  line-height: 24px;
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
`;
