import GenderScreen from 'core/common/components/QuizScreens/Gender';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { Gender as GenderTypes, GenderScreenAttributes } from 'core/funnel/entities';

export type GenderProps = {
  id: string;
};

const Gender = ({ id }: GenderProps) => {
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { back, next, screenAttributes, updateUserPreferences, step, signIn } = useQuizStep(id);

  const { dataKey } = screenAttributes as GenderScreenAttributes;

  const onGenderClick = async (value: GenderTypes) => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    await next({ value });
  };

  return (
    <GenderScreen
      onBack={back}
      onGenderClick={onGenderClick}
      onPolicyClick={handlePolicyClick}
      step={step}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      signIn={signIn}
      {...(screenAttributes as GenderScreenAttributes)}
    />
  );
};

export default Gender;
