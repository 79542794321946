import { Time, TimePart } from '../entities';
import { addLeadingZero } from './addLeadingZero';
import { createDate } from './date';

function convertToMeridiemTime(hours: number): string {
  if (hours === 0) return '12';

  const isPM = hours > 12;
  const formatedTime = isPM ? hours - 12 : hours;

  return String(formatedTime);
}

export const extractTimeParts = (time = '12:00:00'): Time => {
  const formattedTime = createDate(`1990-01-01${time}`, 'YYYY-MM-DDTh:mm:ss A');
  const defaultDate = {
    hours: 12,
    minutes: 0,
    part: TimePart.AM,
  };

  const hours = formattedTime.get('hours') || defaultDate.hours;
  const minutes = formattedTime.get('minutes') || defaultDate.minutes;
  const part = hours > 12 ? TimePart.PM : TimePart.AM;

  return {
    hours: convertToMeridiemTime(hours),
    minutes: addLeadingZero(minutes),
    part,
  };
};

export const normalizeTime = (time: Time, timePattern: string) => {
  const isPM = time.part === TimePart.PM;
  const hours = Number(time.hours);

  let formatedHours = isPM ? hours + 12 : hours;

  if (time.hours === '12' && !isPM) {
    formatedHours = 0;
  }

  const newTime = createDate()
    .set('h', formatedHours)
    .set('m', Number(time.minutes))
    .set('s', 0)
    .format(timePattern);

  if (newTime === 'Invalid Date') {
    return '12:00:00';
  }

  return newTime;
};
