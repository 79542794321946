import correctScan from 'core/common/assets/images/palmistryQuiz/correct_scan.png';
import wrongScan from 'core/common/assets/images/palmistryQuiz/wrong_scan.png';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ScanWithSkipErrorScreenProps as ScanWithSkipErrorProps } from 'core/funnel/entities';
import { PopUpTypes } from 'core/popUp/entities';
import { usePopUps } from 'core/popUp/hooks';
import CameraPermissionsAlert from '../components/CameraPermissionsAlert';
import ScanProcessPopUps from './ScanProcessPopUps';
import {
  ButtonStyled,
  ContainerStyled,
  GuaranteeContentStyled,
  GuaranteeIconStyledStyled,
  GuaranteeStyled,
  TitleStyled,
  WrongScanImageStyled,
} from './styled';

const ScanWithSkipError = ({
  onBack,
  goNext,
  step,
  content,
  totalSteps,
  onSkipError,
}: ScanWithSkipErrorProps) => {
  const { activePopUpType, openScanProcessPopUp, closePopUp } = usePopUps();

  const { title, take, guarantee } = content;

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <ContainerStyled>
        <TitleStyled variant="h3" dangerouslySetInnerHTML={prepareHtml(title)} />
        <Image src={correctScan} width={320} height={190} alt="example of correctly scanned palm" />
        <WrongScanImageStyled
          src={wrongScan}
          width={320}
          height={110}
          alt="example of incorrectly scanned palm"
        />
        <ButtonStyled onClick={openScanProcessPopUp}>{take}</ButtonStyled>
        <GuaranteeStyled>
          <GuaranteeIconStyledStyled height={24} width={24} name="guarantee" />
          <GuaranteeContentStyled dangerouslySetInnerHTML={prepareHtml(guarantee)} />
        </GuaranteeStyled>
        <ScanProcessPopUps
          isOpen={activePopUpType === PopUpTypes.SCAN_PROCESS}
          goNext={goNext}
          content={content}
          onClosePopUp={closePopUp}
          onSkipError={onSkipError}
        />
        <CameraPermissionsAlert
          isOpen={activePopUpType === PopUpTypes.CAMERA_ACCESS_ALERT}
          onClose={closePopUp}
          onConfirm={closePopUp}
        />
      </ContainerStyled>
    </>
  );
};

export default ScanWithSkipError;
