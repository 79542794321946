import styled from 'styled-components';
import Image from 'core/common/components/Image';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.section`
  margin-top: 52px;
  margin-bottom: 52px;
`;

export const Title = styled(TitleComponent)`
  font-size: 20px;
  line-height: 140%;
  text-align: left;
`;

export const BlurredWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 12px;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(153, 116, 246, 0.15) 0%,
    rgba(233, 226, 252, 0.15) 25%,
    rgba(254, 254, 254, 0.15) 50%,
    rgba(233, 226, 252, 0.15) 75%,
    rgba(153, 116, 246, 0.15) 100%
  );

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 17%;
    background: linear-gradient(
      0deg,
      #f7f3fe 0.4%,
      rgba(247, 243, 254, 0.96) 27.78%,
      rgba(253, 253, 254, 0) 99.59%,
      #f7f3fe 99.6%
    );
    content: '';
    backdrop-filter: blur(2px);

    @media ${(props) => props.theme.breakpoints.desktop} {
      height: 15%;
    }
  }
`;

export const GuideHeader = styled.ul`
  display: flex;
  width: 100%;
  margin-top: 8px;
`;

export const GuideContent = styled.ul``;

export const ListItem = styled.li`
  margin-top: 12px;
`;

export const GuideHeaderListItem = styled.li`
  width: 50%;
`;

export const ItemTitle = styled.h3`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
`;

export const ItemText = styled.p`
  font-size: 14px;
`;

export const LockImage = styled(Image)`
  position: absolute;
  bottom: 4%;
  z-index: 2;
`;
