import styled from 'styled-components';

export const WrapperStyled = styled.div`
  margin-bottom: 32px;
  padding: 24px;
  border: 1px solid #0000001a;
  border-radius: 16px;
  text-align: center;
`;

export const TextStyled = styled.p`
  width: 100%;
  max-width: 260px;
  margin-right: auto;
  margin-bottom: 28px;
  margin-left: auto;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;
