import { useState, useEffect, useRef } from 'react';
import Header from 'core/common/components/Header';
import QuestionModal from 'core/common/components/QuestionModal';
import { ReviewsCardVerified } from 'core/common/components/ReviewsCards';
import { SECOND } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { LoaderWithReviewsScreenProps as LoaderWithReviewsProps } from 'core/funnel/entities';
import {
  ContainerStyled,
  ListWrapperStyled,
  CircularProgressV2Styled,
  ListStyled,
  ListItemStyled,
  ReviewsCarouselStyled,
} from './styled';

const LoaderWithReviews = ({ content, onLoadingFinished }: LoaderWithReviewsProps) => {
  const { progress: progressContent, reviews, questions, carouselSpeed } = content;

  const [progress, setProgress] = useState(0);
  const [activeTitleIndex, setActiveTitleIndex] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(-1);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const lastModalThreshold = useRef(0);

  const progressAnimationIntervalMs = 10;
  const progressAnimationDurationMs = progressContent.duration || 6 * SECOND;
  const titleSwitchIntervalMs = 100 / progressContent.items.length;

  useEffect(() => {
    if (shouldShowModal) return;

    const displayModalInterval = Math.floor(100 / (questions?.length || 100));

    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress =
          prevProgress + (progressAnimationIntervalMs / progressAnimationDurationMs) * 100;

        if (newProgress < 100) {
          const newActiveTitleIndex = Math.floor(newProgress / titleSwitchIntervalMs);
          setActiveTitleIndex(newActiveTitleIndex);

          const modalThreshold =
            Math.floor(newProgress / displayModalInterval) * displayModalInterval;
          const isModalThresholdExceeded = modalThreshold > lastModalThreshold.current;
          const isModalDisplayIntervalReached = modalThreshold % displayModalInterval === 0;

          if (questions && isModalThresholdExceeded && isModalDisplayIntervalReached) {
            lastModalThreshold.current = modalThreshold;
            setActiveQuestionIndex((prevQuestion) => prevQuestion + 1);
            setShouldShowModal(true);
            clearInterval(intervalId);
          }

          return newProgress;
        }

        setActiveTitleIndex(progressContent.items.length - 1);
        clearInterval(intervalId);
        onLoadingFinished();

        return 100;
      });
    }, progressAnimationIntervalMs);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowModal, questions]);

  const closeModal = () => {
    setShouldShowModal(false);
  };

  return (
    <>
      <Header buttonBack={false} />
      <ContainerStyled>
        <CircularProgressV2Styled progress={progress} />
        <ListWrapperStyled>
          <ListStyled
            activeTitleIndex={activeTitleIndex}
            translateFraction={Math.floor(titleSwitchIntervalMs)}
          >
            {progressContent.items.map((item, idx) => (
              <ListItemStyled
                key={item}
                isActive={idx === activeTitleIndex}
                dangerouslySetInnerHTML={prepareHtml(item)}
              />
            ))}
          </ListStyled>
        </ListWrapperStyled>
        <ReviewsCarouselStyled
          content={{ list: reviews }}
          settings={{ autoplay: true, autoplaySpeed: carouselSpeed }}
          renderReviewCard={({ date, review, userName, verified }) => {
            if (!verified) {
              return null;
            }

            return (
              <ReviewsCardVerified
                date={date}
                review={review}
                userName={userName}
                verified={verified}
              />
            );
          }}
        />
      </ContainerStyled>
      {questions && activeQuestionIndex >= 0 && (
        <QuestionModal
          image={questions[activeQuestionIndex].image}
          question={questions[activeQuestionIndex].text}
          denyButtonText={questions[activeQuestionIndex].denyButtonText}
          confirmButtonText={questions[activeQuestionIndex].confirmButtonText}
          isOpen={shouldShowModal}
          onDeny={closeModal}
          onConfirm={closeModal}
        />
      )}
    </>
  );
};

export default LoaderWithReviews;
