import styled from 'styled-components';
import Advantages from 'core/common/components/Advantages';
import Button from 'core/common/components/ButtonDeprecated';
import Link from 'core/common/components/Link';

const contentMaxWidth = 350;

export const TitleStyled = styled.h1`
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  span {
    color: #6b3aa2;
  }
`;

export const SubtitleStyled = styled.p`
  text-align: center;
`;

export const ContentStyled = styled.section`
  z-index: 1;
  max-width: ${contentMaxWidth}px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 64px;
`;

export const FooterStyled = styled.footer`
  margin-top: 56px;
  text-align: center;
`;

export const FooterLinkStyled = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const LinksSeparatorStyled = styled.span`
  margin-right: 8px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const StickyButtonStyled = styled(Button)`
  position: sticky;
  bottom: 8px;
  height: 56px;
  margin-top: 36px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const AdvantagesStyled = styled(Advantages)`
  margin-top: 56px;
  margin-bottom: 56px;
`;
