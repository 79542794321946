import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';

export const Address = styled(AddressComponent)`
  margin-top: auto;
`;

export const Content = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.p`
  font-size: 12px;
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const Break = styled.br``;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-top: 7px;
  border-top: 1px solid #d9d9d9;
`;

export const TotalLabel = styled(Label)`
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const StyledDescriptionExperiment = styled(Description)`
  padding-top: 10px;
  padding-bottom: 10px;
`;
