import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { europeanCountries, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { BoldStyled, ContentStyled, DescriptionStyled } from './styled';

export type SectionPaymentProps = {
  isTimeUp: boolean;
  isOpen: boolean;
  offer: Offer;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const trialPeriod = '7-day';

const trialPeriodMap = {
  '7 days': '1 week',
  '28 days': '4 weeks',
  '84 days': '12 weeks',
} as const;

type TrialPeriod = (typeof trialPeriodMap)[keyof typeof trialPeriodMap];

const trialWordMap: Record<TrialPeriod, string> = {
  '1 week': 'trial',
  '4 weeks': 'introductory plan',
  '12 weeks': 'introductory plan',
};

const SectionPayment = (props: SectionPaymentProps) => {
  const { onClose, isTimeUp, onPaymentSuccess, offer, isOpen } = props;
  const { domainUrl } = useConfig();

  const mappedTrialPeriod =
    trialPeriodMap[(offer.trialPeriod as keyof typeof trialPeriodMap) ?? '28 days'];
  const mappedTrialWord = trialWordMap[mappedTrialPeriod];

  if (!offer) {
    return null;
  }

  return (
    <>
      <PaymentDialog
        productId={offer.offerId}
        currency={offer.currency}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
      >
        <ContentStyled>
          {isTimeUp ? (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {trialPeriod} trial.
                  </BoldStyled>
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {trialPeriod} trial.
                  </BoldStyled>{' '}
                  The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {trialPeriod} trial.
                  </BoldStyled>{' '}
                  The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
            </Location>
          ) : (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {mappedTrialPeriod}{' '}
                    {mappedTrialWord}.
                  </BoldStyled>
                  <br /> + We’ll <BoldStyled>email you a reminder</BoldStyled> before your trial
                  period ends. Cancel anytime.
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {mappedTrialPeriod}{' '}
                    {mappedTrialWord}.
                  </BoldStyled>
                  <br /> + We’ll <BoldStyled>email you a reminder</BoldStyled> before your trial
                  period ends. Cancel anytime. The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {mappedTrialPeriod}{' '}
                    {mappedTrialWord}.
                  </BoldStyled>
                  <br /> + We’ll <BoldStyled>email you a reminder</BoldStyled> before your trial
                  period ends. Cancel anytime.
                </DescriptionStyled>
              </LocationOption>
            </Location>
          )}
        </ContentStyled>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
