import { UnlimitedReadingsPalmistryScreenAttributes } from 'core/funnel/entities';
import { List, ListItem, StyledEmoji } from './styled';

type BenefitsProps = {
  benefits: UnlimitedReadingsPalmistryScreenAttributes['content']['benefits'];
};

const Benefits = ({ benefits }: BenefitsProps) => {
  return (
    <List>
      {benefits.map(({ text, emoji }) => (
        <ListItem key={text}>
          <StyledEmoji emoji={emoji} width={20} height={20} desktopLarge={false} />
          {text}
        </ListItem>
      ))}
    </List>
  );
};

export default Benefits;
