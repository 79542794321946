import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  ListTextStyled,
  DiscountStyled,
  PriceWrapperStyled,
  PriceStyled,
  PriceWithDiscountStyled,
  DisclaimerStyled,
  ButtonStyled,
  SkipButtonStyled,
} from './styled';

type OfferProps = {
  discount: string;
  list: Array<{
    icon: FunnelImage;
    text: string;
  }>;
  price: string;
  priceWithDiscount: string;
  disclaimer: string;
  buttonText: string;
  isLoading: boolean;
  skipButtonText: string;
  onGetOffer: () => void;
  onSkipOffer: () => void;
};

const Offer = ({
  list,
  price,
  discount,
  isLoading,
  disclaimer,
  buttonText,
  skipButtonText,
  priceWithDiscount,
  onGetOffer,
  onSkipOffer,
}: OfferProps) => {
  return (
    <WrapperStyled>
      <DiscountStyled>{discount}</DiscountStyled>
      <ListStyled>
        {list.map((item) => (
          <ListItemStyled key={item.text}>
            <ListIconStyled src={item.icon.src} alt={item.icon.alt} width={20} height={20} />
            <ListTextStyled>{item.text}</ListTextStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
      <PriceWrapperStyled>
        <PriceStyled>{price}</PriceStyled>
        <PriceWithDiscountStyled>{priceWithDiscount}</PriceWithDiscountStyled>
      </PriceWrapperStyled>
      <DisclaimerStyled>{disclaimer}</DisclaimerStyled>
      <ButtonStyled fullWidth loading={isLoading} onClick={onGetOffer}>
        {buttonText}
      </ButtonStyled>
      <SkipButtonStyled onClick={onSkipOffer}>{skipButtonText}</SkipButtonStyled>
    </WrapperStyled>
  );
};

export default Offer;
