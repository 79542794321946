import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const HeaderStyled = styled.header`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.typography100};
  background: linear-gradient(
    180deg,
    #1d1e55 0%,
    #272463 25%,
    #3b2f7b 50%,
    #4f358d 75%,
    #6939a2 100%
  );
`;

export const DiscountWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiscountTextStyled = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  text-align: center;
  color: #f3b913;
  text-wrap: nowrap;
`;

export const GetPlanButtonStyled = styled(Button)`
  max-width: 240px;
  height: 40px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 143%;
  color: ${({ theme }) => theme.colors.typography900};
  background: ${({ theme }) => theme.colors.typography100};
  box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);
`;
