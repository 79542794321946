import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionBadgeAttributes } from 'core/funnel/entities';
import { TitleStyled, WrapperStyled } from './styled';

type SectionBadgeProps = {
  content: SectionBadgeAttributes['content'];
};

const SectionBadge = ({ content, ...props }: SectionBadgeProps) => {
  return (
    <WrapperStyled {...props}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(content.title)} />
    </WrapperStyled>
  );
};

export default SectionBadge;
