import { useState } from 'react';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import { scrollToBottom } from 'core/common/utils/scroll';
import { DynamicAnswerV2ScreenProps } from 'core/funnel/entities';

import {
  ContainerMainStyled,
  ItemStyled,
  StyledButton,
  ListStyled,
  TitleStyled,
  RadioButtonStyled,
  RadioButtonTitleStyled,
  AnswerDescriptionContainerStyled,
  EmojiStyled,
  AnswerDescriptionTitleStyled,
  AnswerDescriptionTextStyled,
  AnswerDescriptionEmojiStyled,
} from './styled';

const DynamicAnswerV2Screen = ({
  hasButtonBack = true,
  onContinue,
  step,
  content,
  onBack,
  progressBar,
}: DynamicAnswerV2ScreenProps) => {
  const [answer, setAnswer] = useState<string>('');

  const onAnswerClick = (value: string) => {
    setAnswer(value);
  };

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      {progressBar && <ProgressBar step={step} config={progressBar} onBack={onBack} />}
      <ContainerMainStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <ListStyled>
          {content.answers.map(
            ({ text, value, emoji, description, descriptionTitle, descriptionTitleEmoji }) => (
              <ItemStyled key={value}>
                <RadioButtonStyled
                  name="answers"
                  value={value}
                  id={value}
                  checked={answer === value}
                  onChange={(event) => {
                    onAnswerClick(event.target.value);
                    scrollToBottom({ duration: 1500 });
                  }}
                >
                  <EmojiStyled>{emoji}</EmojiStyled>
                  <RadioButtonTitleStyled>{text}</RadioButtonTitleStyled>
                </RadioButtonStyled>

                <AnswerDescriptionContainerStyled>
                  <AnswerDescriptionEmojiStyled>
                    {descriptionTitleEmoji}
                  </AnswerDescriptionEmojiStyled>
                  <AnswerDescriptionTitleStyled>{descriptionTitle}</AnswerDescriptionTitleStyled>
                  <AnswerDescriptionTextStyled>{description}</AnswerDescriptionTextStyled>
                </AnswerDescriptionContainerStyled>
              </ItemStyled>
            ),
          )}
        </ListStyled>
        <StyledButton isButtonVisible={!!answer} onClick={() => onContinue(answer)}>
          {content.next}
        </StyledButton>
      </ContainerMainStyled>
    </>
  );
};

export default DynamicAnswerV2Screen;
