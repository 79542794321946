import { useEffect } from 'react';
import TalkWithExpertScreen from 'core/common/components/QuizScreens/TalkWithExpert';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TalkWithExpertScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type TalkWithExpertProps = {
  id: string;
};

const TalkWithExpert = ({ id }: TalkWithExpertProps) => {
  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onExpertPageOpened();
  }, [analyticsService]);

  return <TalkWithExpertScreen {...(screenAttributes as TalkWithExpertScreenAttributes)} />;
};

export default TalkWithExpert;
