import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Checkbox from 'core/common/components/Checkbox';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import NavLink from 'core/common/components/Link/Link';

export const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: ${({ theme }) => theme.colors.themeBackground};
`;

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 440px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
`;
export const EmojiStyled = styled(EmojiComponent)`
  width: 32px;
  height: 32px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 60px;
    height: 60px;
  }
`;

export const TrialTitle = styled.h2`
  margin-top: 11px;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  background: ${(props) => props.theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 24px;
    font-size: 32px;
    line-height: 44px;
  }
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;
  max-width: 360px;
  height: 50px;
  margin-top: 32px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
`;

export const SubscriptionDescriptionStyled = styled.p`
  max-width: 400px;
  margin-top: 28px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 40px;
  }
`;

export const BoldStyled = styled.span`
  font-weight: 600;
`;

export const LinkStyled = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;

export const CheckboxStyled = styled(Checkbox)<{ isConsentErrorOccured: boolean }>`
  display: flex;
  margin-top: 26px;

  &::before {
    border-color: ${({ theme, isConsentErrorOccured }) =>
      isConsentErrorOccured ? theme.colors.error : 'initial'};
  }

  ${SubscriptionDescriptionStyled} {
    margin-top: 0;
    text-align: left;
    color: ${({ theme, isConsentErrorOccured }) =>
      isConsentErrorOccured ? theme.colors.error : theme.colors.typography700};
  }
`;
