import Timer from '../Timer';
import {
  HeaderStyled,
  DiscountWrapperStyled,
  DiscountTextStyled,
  GetPlanButtonStyled,
} from './styled';

type DiscountHeaderProps = {
  discountValue?: string;
  buttonText: string;
  timeLeft: number;
  minLabel: string;
  isTimeUp: boolean;
  secLabel: string;
  onGetPlan: () => void;
};

const DiscountHeader = ({
  discountValue,
  buttonText,
  timeLeft,
  minLabel,
  isTimeUp,
  secLabel,
  onGetPlan,
}: DiscountHeaderProps) => {
  return (
    <HeaderStyled>
      <DiscountWrapperStyled>
        {!isTimeUp && discountValue && <DiscountTextStyled>{discountValue}</DiscountTextStyled>}
        <Timer timeLeft={timeLeft} minLabel={minLabel} secLabel={secLabel} />
      </DiscountWrapperStyled>
      <GetPlanButtonStyled onClick={onGetPlan}>{buttonText}</GetPlanButtonStyled>
    </HeaderStyled>
  );
};

export default DiscountHeader;
