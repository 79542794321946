import Image from 'core/common/components/Image';
import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';
import {
  Break,
  Container,
  FingersContainer,
  FingersContent,
  HeadLineContainer,
  HeadLineContent,
  LifeLineContainer,
  LifeLineContent,
  MarriageLineContent,
  LineTitle,
  LoveFateContainer,
  LoveFateContent,
  LoveFateWrapper,
  MarriageLineContainer,
  Subtitle,
  TitleDescription,
} from './styled';

type PalmistryDescriptionProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['palmGuide'];
};

const PalmistryDescription = ({ content }: PalmistryDescriptionProps) => {
  const { title, description, lifeLine, headLine, marriageLine, loveLine, fateLine, fingers } =
    content;

  return (
    <Container>
      <TitleDescription>{title}</TitleDescription>
      <Subtitle>{description}</Subtitle>
      <LifeLineContainer>
        <Image src={lifeLine.image.src} width="60" height="72" alt={lifeLine.image.alt} />
        <LifeLineContent>
          <LineTitle>{lifeLine.line}</LineTitle> {lifeLine.description}
        </LifeLineContent>
      </LifeLineContainer>
      <HeadLineContainer>
        <Image src={headLine.image.src} width="60" height="72" alt={headLine.image.alt} />
        <HeadLineContent>
          <LineTitle>{headLine.line}</LineTitle> {headLine.description}
        </HeadLineContent>
      </HeadLineContainer>
      <MarriageLineContainer>
        <Image src={marriageLine.image.src} width="60" height="72" alt={marriageLine.image.alt} />
        <MarriageLineContent>
          <LineTitle>{marriageLine.line}</LineTitle> {marriageLine.description}
        </MarriageLineContent>
      </MarriageLineContainer>
      <LoveFateContainer>
        <LoveFateWrapper>
          <Image src={loveLine.image.src} width="60" height="72" alt={loveLine.image.alt} />
          <LoveFateContent>
            <LineTitle>{loveLine.line}</LineTitle>
            <Break />
            {loveLine.description}
          </LoveFateContent>
        </LoveFateWrapper>
        <LoveFateWrapper>
          <Image src={fateLine.image.src} width="60" height="72" alt={fateLine.image.alt} />
          <LoveFateContent>
            <LineTitle>{fateLine.line}</LineTitle>
            <Break />
            {fateLine.description}
          </LoveFateContent>
        </LoveFateWrapper>
      </LoveFateContainer>
      <FingersContainer>
        <Image src={fingers.image.src} width="60" height="72" alt={fingers.image.alt} />
        <FingersContent>{fingers.description}</FingersContent>
      </FingersContainer>
    </Container>
  );
};

export default PalmistryDescription;
