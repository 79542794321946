import styled from 'styled-components';
import SectionMentionedIn from 'core/common/components/QuizScreensSections/SectionMentionedIn';
import SectionInfoPanel from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionInfoPanel';
import SectionMoneyBack from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionMoneyBackGuarantee';
import SectionPricePanel from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionPrice';
import SectionReviews from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionReviews';

export const ContentStyled = styled.div`
  z-index: 1;
  padding-bottom: 70px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 54px;
  }
`;

export const ContainerStyled = styled.div`
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const DescriptionStyled = styled.p`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

export const SectionMentionedInStyled = styled(SectionMentionedIn)`
  margin-top: 40px;
`;

export const SectionReviewsStyled = styled(SectionReviews)`
  margin-top: 30px;
`;

export const SectionInfoPanelStyled = styled(SectionInfoPanel)`
  margin-top: 40px;
`;

export const SectionMoneyBackStyled = styled(SectionMoneyBack)`
  margin-bottom: 30px;
`;

export const SectionPricePanelStyled = styled(SectionPricePanel)`
  margin-top: 36px;
  margin-bottom: 36px;
`;

export const SectionEndStyled = styled.div`
  height: 1px;
  overflow: hidden;
`;
