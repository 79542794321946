import PhoneNumberScreen from 'core/common/components/QuizScreens/PhoneNumber';
import { useQuizStep } from 'core/funnel/contexts';
import { PhoneNumberScreenAttributes } from 'core/funnel/entities';

export type PhoneNumberEmailMarketingProps = {
  id: string;
};

const PhoneNumberEmailMarketing = ({ id }: PhoneNumberEmailMarketingProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  return <PhoneNumberScreen goNext={next} {...(screenAttributes as PhoneNumberScreenAttributes)} />;
};

export default PhoneNumberEmailMarketing;
