import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { GoalSetScreenProps } from 'core/funnel/entities';
import { Button, Container, Title, Subtitle, StyledImage } from './styled';

const GoalSetScreen = ({ content, goNext, onBack }: GoalSetScreenProps) => {
  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <QuizBackground backgroundImage={content.backgroundImage} variant="darkGradient" />
      <Container>
        <StyledImage src={content.image.src} alt={content.image.alt} width={150} height={150} />
        <Title dangerouslySetInnerHTML={prepareHtml(content.title)} />
        <Subtitle>{content.subtitle}</Subtitle>
        <Button onClick={goNext} variant="secondary">
          {content.button}
        </Button>
      </Container>
    </>
  );
};

export default GoalSetScreen;
