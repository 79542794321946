import { useEffect } from 'react';
import { useGetAuthorizationToken } from 'core/auth/hooks';
import AppDownloadScreen from 'core/common/components/QuizScreens/AppDownload';
import { useAppDownloadLink, useRedirectToAskNebula, useServices } from 'core/common/hooks';
import { redirectToUrl } from 'core/common/utils/redirectToUrl';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AppDownloadScreenAttributes } from 'core/funnel/entities';

type AppDownloadProps = {
  id: string;
};

const AppDownload = ({ id }: AppDownloadProps) => {
  const { analyticsService } = useServices();
  const { screenAttributes } = useQuizStep(id);
  const { token } = useGetAuthorizationToken();
  const { redirectToQuizCatalogue } = useRedirectToAskNebula();

  const { downloadAppLinkLoadingStatus, fetchAppDownloadLink, downloadAppLink } =
    useAppDownloadLink();

  const onSkip = () => {
    analyticsService.w2aPromoScreenClick('skip');
    redirectToQuizCatalogue(token);
  };

  const onOpenLink = () => {
    analyticsService.w2aPromoScreenClick('download');

    setTimeout(() => {
      redirectToUrl(downloadAppLink);
    }, 0);
  };

  useEffect(() => {
    analyticsService.w2aPromoScreenOpen();
    fetchAppDownloadLink();
  }, [analyticsService, fetchAppDownloadLink]);

  return (
    <AppDownloadScreen
      onSkip={onSkip}
      linkLoadingStatus={downloadAppLinkLoadingStatus}
      onOpenLink={onOpenLink}
      {...(screenAttributes as AppDownloadScreenAttributes)}
    />
  );
};

export default AppDownload;
