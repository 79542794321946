import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import {
  GuaranteePaymentBlockStyled,
  PaymentImagesBlockStyled,
  TitleStyled,
  ImageStyled,
} from './styled';

type GuaranteePaymentSectionProps = {
  title: string;
  image: FunnelImage;
};

const GuaranteePaymentSection = ({ title, image }: GuaranteePaymentSectionProps) => {
  return (
    <GuaranteePaymentBlockStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <PaymentImagesBlockStyled>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </PaymentImagesBlockStyled>
    </GuaranteePaymentBlockStyled>
  );
};

export default GuaranteePaymentSection;
