import guarantee from 'core/common/assets/images/guarantee_medal.png';
import Image from 'core/common/components/Image';
import { Container, Description, Title } from './styled';

type GuaranteeProps = {
  title: string;
  description: string;
};

const Guarantee = ({ title, description }: GuaranteeProps) => {
  return (
    <Container>
      <Image src={guarantee} width="54" height="70" alt="guarantee medal" />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default Guarantee;
