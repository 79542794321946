import Modal from 'core/common/components/Modal';
import { QuestionSteps } from '../ScanLoading';
import ScanQuestion from '../ScanQuestion';

type ScanQuestionStepsProps = {
  progress: QuestionSteps;
  onClosePopupQuestion: (data: { popupName: string; answer: string }) => void;
  confirmButtonText: string;
  discardButtonText: string;
  question: {
    adventurous: string;
    alone: string;
    remedies: string;
  };
};

const ScanQuestionSteps = ({
  progress,
  confirmButtonText,
  discardButtonText,
  onClosePopupQuestion,
  question,
}: ScanQuestionStepsProps) => {
  const modalContent = {
    [QuestionSteps.ADVENTUROUS]: (
      <ScanQuestion
        confirmButtonText={confirmButtonText}
        discardButtonText={discardButtonText}
        title={question.adventurous}
        onClose={(answer) => {
          onClosePopupQuestion({ answer, popupName: 'adventurous' });
        }}
      />
    ),
    [QuestionSteps.ALONE]: (
      <ScanQuestion
        confirmButtonText={confirmButtonText}
        discardButtonText={discardButtonText}
        title={question.alone}
        onClose={(answer) => {
          onClosePopupQuestion({ answer, popupName: 'alone' });
        }}
      />
    ),
    [QuestionSteps.REMEDIES]: (
      <ScanQuestion
        confirmButtonText={confirmButtonText}
        discardButtonText={discardButtonText}
        title={question.remedies}
        onClose={(answer) => {
          onClosePopupQuestion({ answer, popupName: 'remedies' });
        }}
      />
    ),
  };

  return (
    <Modal
      BackdropProps={{ style: { background: 'rgba(40, 40, 97, 0.5)' } }}
      open={!!QuestionSteps[progress]}
    >
      {modalContent[progress]}
    </Modal>
  );
};

export default ScanQuestionSteps;
