import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TextWithEmoji } from 'core/funnel/entities';
import {
  EmojiStyled,
  EmojiCircleStyled,
  ListStyled,
  ListItemStyled,
  ListItemTextStyled,
  TitleStyled,
  BenefitsBlockStyled,
} from './styled';

type BenefitsSectionProps = {
  title: string;
  benefits: Array<TextWithEmoji>;
};

const BenefitsSection = ({ title, benefits }: BenefitsSectionProps) => {
  return (
    <BenefitsBlockStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <ListStyled>
        {benefits.map(({ text, emoji }) => (
          <ListItemStyled key={text}>
            <EmojiCircleStyled>
              <EmojiStyled>{emoji}</EmojiStyled>
            </EmojiCircleStyled>
            <ListItemTextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
          </ListItemStyled>
        ))}
      </ListStyled>
    </BenefitsBlockStyled>
  );
};

export default BenefitsSection;
