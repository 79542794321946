import { useConfig, useServices } from 'core/common/hooks';
import { useTakePhoto } from 'core/palmistry/hooks';

export const useScanMaker = () => {
  const { takePhoto, getWebcamAccess, stopVideoStream } = useTakePhoto();

  const { appEnvironment } = useServices();

  const { palmPhotoLongSide, palmPhotoShortSide } = useConfig();

  const isMobile = appEnvironment.isMobileDevice();

  const width = isMobile ? palmPhotoShortSide : palmPhotoLongSide;

  const height = isMobile ? palmPhotoLongSide : palmPhotoShortSide;

  const takeUserPhoto = () => {
    return takePhoto(width, height);
  };

  return {
    takeUserPhoto,
    getWebcamAccess,
    stopVideoStream,
  };
};
