import female from 'core/common/assets/images/hintQuiz/female.png';
import male from 'core/common/assets/images/hintQuiz/male.png';
import Address from 'core/common/components/Address';
import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import PolicyExtended from 'core/common/components/PolicyExtended';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { GenderPalmistryScreenProps } from 'core/funnel/entities';
import { Locales, Regions, paidUtmSources, notPaidUtmSources } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  Arrow,
  Container,
  FemaleButton,
  GenderList,
  GenderListItem,
  GenderSection,
  Image,
  MaleButton,
  StyledTitle,
  Subtitle,
} from './styled';

const GenderPalmistryScreen = ({
  onGenderClick,
  onPolicyClick,
  onBack,
  isPolicyAccepted,
  displayPolicyError,
  step,
  hasButtonBack,
  content,
  totalSteps,
}: GenderPalmistryScreenProps) => {
  return (
    <>
      <Header onBack={onBack} buttonBack={hasButtonBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <Container>
        <StyledTitle>{content.title}</StyledTitle>
        <Subtitle>{content.subtitle}</Subtitle>

        <GenderSection>
          <GenderList>
            <GenderListItem onClick={() => onGenderClick(content.male.value)}>
              <Image src={male} alt="a man" width={150} height={174} />
              <MaleButton>
                {content.male.text} <Arrow />
              </MaleButton>
            </GenderListItem>
            <GenderListItem onClick={() => onGenderClick(content.female.value)}>
              <Image src={female} alt="a woman" width={150} height={174} />
              <FemaleButton>
                {content.female.text} <Arrow />
              </FemaleButton>
            </GenderListItem>
          </GenderList>
        </GenderSection>
        {/* Terms for paid trafic (start) */}
        <MediaSourceVariant name={paidUtmSources}>
          <LocationRegion>
            <LocationRegionOption option={Regions.CALIFORNIA}>
              <PolicyExtended
                isPolicyAccepted={isPolicyAccepted}
                onPolicyClick={onPolicyClick}
                withoutCheckbox
              />
              <Address />
            </LocationRegionOption>
          </LocationRegion>
        </MediaSourceVariant>
        {/* Terms for paid trafic (end) */}
        {/* Terms for organic trafic (start) */}
        <MediaSourceVariant name={notPaidUtmSources}>
          <Location>
            <LocationOption option={Locales.US}>
              <PolicyExtended
                isPolicyAccepted={isPolicyAccepted}
                onPolicyClick={onPolicyClick}
                isError={displayPolicyError}
              />
              <Address />
            </LocationOption>
          </Location>
        </MediaSourceVariant>
        {/* Terms for organic trafic (end) */}
      </Container>
    </>
  );
};

export default GenderPalmistryScreen;
