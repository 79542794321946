import styled from 'styled-components';
import AnswerButtonComponent from 'core/common/components/AnswerButton';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 708px;
  margin: 0 auto;
`;

export const Subtitle = styled.h2`
  align-self: flex-start;
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;

  strong {
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
`;

export const Title = styled.h2`
  align-self: flex-start;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 708px;
    font-size: 32px;
    line-height: 44px;
  }
`;

export const ContainerMain = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Overtitle = styled.h6`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 135%;
  text-align: start;
  color: #686868;
`;

export const Break = styled.br``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Item = styled.li``;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const AnswerButton = styled(AnswerButtonComponent)`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 80px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0;
  }
`;

export const Label = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;

export const Button = styled(ButtonComponent)`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: initial;
    margin-top: 40px;
  }
`;
