import { SectionMentionedInAttributes } from 'core/funnel/entities';
import { ContentStyled, ImageStyled, TitleStyled, WrapperStyled } from './styled';

type SectionMentionedInProps = {
  content: SectionMentionedInAttributes['content'];
};

const SectionMentionedIn = ({ content, ...props }: SectionMentionedInProps) => {
  return (
    <WrapperStyled {...props}>
      <TitleStyled>{content.title}</TitleStyled>
      <ContentStyled>
        <ImageStyled src={content.image.src} alt={content.image.alt} width={304} height={156} />
      </ContentStyled>
    </WrapperStyled>
  );
};

export default SectionMentionedIn;
