import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import UpsellsHeaderExperiment from 'core/common/components/UpsellsHeader/UpsellsHeaderExperiment';
import { UpsellsV2ScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionPayment from './SectionPayment';
import {
  ButtonStyled,
  ButtonWrapperStyled,
  ContentStyled,
  RootStyled,
  RouteLinkStyled,
} from './styled';

const UpsellsV2Screen = ({
  content,
  isPurchaseLoading,
  selectedProduct,
  upsells,
  resignFormLoaded,
  onGetReport,
  onSkip,
  selectProduct,
  paymentError,
  onClearPaymentStatus,
}: UpsellsV2ScreenProps) => {
  const { title, subtitle, banner, footerText, button, link } = content;

  return (
    <RootStyled>
      <Header buttonBack={false} />
      <ContentStyled>
        <UpsellsHeaderExperiment title={title} subtitle={subtitle} banner={banner} />
        {!!upsells.length && (
          <RenderOnlyOnClient>
            <SectionPayment
              selectedProduct={selectedProduct}
              selectProduct={selectProduct}
              upsells={upsells}
            />
          </RenderOnlyOnClient>
        )}
        <ButtonWrapperStyled>
          <ButtonStyled
            onClick={onGetReport}
            disabled={isPurchaseLoading}
            loading={isPurchaseLoading}
          >
            {button}
          </ButtonStyled>
          <RouteLinkStyled disabled={isPurchaseLoading} onClick={onSkip}>
            {link}
          </RouteLinkStyled>
        </ButtonWrapperStyled>
        <SectionFooter description={footerText} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onGetReport}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </ContentStyled>
    </RootStyled>
  );
};

export default UpsellsV2Screen;
