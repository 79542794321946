import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import QuizBackground from 'core/common/components/QuizBackground';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { AnswerScreenProps } from 'core/funnel/entities';
import {
  AnswerButton,
  ContainerMain,
  Content,
  Item,
  List,
  Overtitle,
  Subtitle,
  Title,
} from './styled';

const AnswerScreen = ({
  content,
  onAnswerClick,
  onBack,
  step,
  progressBarTitle,
  progressBarName,
  totalProfileSteps,
  totalPersonalitySteps,
  totalRelationshipSteps,
  hasProgressBar = true,
}: AnswerScreenProps) => {
  const { title, overtitle, subtitle, answers, backgroundImage } = content;

  return (
    <RenderOnlyOnClient>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header onBack={onBack} />
      {hasProgressBar && (
        <ProgressBarHint
          currentStep={step}
          currentName={progressBarName}
          title={progressBarTitle}
          totalPersonalitySteps={totalPersonalitySteps}
          totalProfileSteps={totalProfileSteps}
          totalRelationshipSteps={totalRelationshipSteps}
        />
      )}
      <ContainerMain>
        {!!overtitle && <Overtitle>{overtitle}</Overtitle>}
        <Title dangerouslySetInnerHTML={prepareHtml(title)} />
        {!!subtitle && <Subtitle dangerouslySetInnerHTML={prepareHtml(subtitle)} />}
        <Content>
          <List>
            {answers.map(({ text, emoji, value }) => (
              <Item key={text}>
                <AnswerButton text={text} onClick={() => onAnswerClick(value)} emoji={emoji} />
              </Item>
            ))}
          </List>
        </Content>
      </ContainerMain>
    </RenderOnlyOnClient>
  );
};

export default AnswerScreen;
