// eslint-disable-next-line max-len
import TrialPaymentWithDiscountIntroOfferScreen from 'core/common/components/QuizScreens/TrialPaymentWithDiscountIntroOffer';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';

type TrialPaymentWithDiscountIntroOfferProps = {
  id: string;
};

//!
const TrialPaymentWithDiscountIntroOffer = ({ id }: TrialPaymentWithDiscountIntroOfferProps) => {
  const { next } = useQuizStep(id);

  return <TrialPaymentWithDiscountIntroOfferScreen goNext={next} />;
};

export default TrialPaymentWithDiscountIntroOffer;
