import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const WrapperStyled = styled.section`
  padding: 20px 12px;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${({ theme }) => theme.colors.black};
`;

export const PreviewTextStyled = styled.p`
  margin-top: 20px;
`;

export const PreviewSecondaryTextStyled = styled.p`
  margin-top: 8px;
`;

export const ButtonStyled = styled(Button)`
  width: 95%;
  height: 48px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const ButtonTextStyled = styled.span`
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(
    166deg,
    #141333 -33.39%,
    #202261 15.89%,
    #543c97 55.84%,
    #6939a1 74.96%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;
