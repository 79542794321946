import styled from 'styled-components';
import Carousel, { carouselClasses } from 'core/common/components/Carousel';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.section`
  width: 100%;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 43px;
    text-align: center;
  }
`;

export const CarouselStyled = styled(Carousel)`
  margin-right: -16px;

  ${carouselClasses.buttonDisabled} {
    background-color: ${({ theme }) => theme.colors.typography200};
    opacity: 0.3;
  }

  ${carouselClasses.track} {
    display: flex;
  }

  ${carouselClasses.dots} {
    bottom: -35px;

    li {
      margin: 0;
    }

    button::before {
      font-size: 10px;
    }
  }

  ${carouselClasses.slide} {
    height: inherit;
    padding-right: 12px;

    & > div {
      height: 100%;
    }
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: 0;

    ${carouselClasses.slide} {
      padding-right: 20px;
    }
  }
`;

export const CardStyled = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.typography300};
  border-radius: 20px;
  overflow: hidden;
`;

export const CardHeaderStyled = styled.header`
  position: relative;
  padding-top: 47%;
`;

export const CardBodyStyled = styled.div`
  padding: 16px;
`;

export const CardImageStyled = styled(Image)`
  object-fit: cover;
`;

export const LabelStyled = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const CardTitleStyled = styled.h3`
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 22px;
    line-height: 30px;
  }
`;

export const DetailsListStyled = styled.ul``;

export const DetailsListItemStyled = styled.li`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 19px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const DetailsIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 2px;
  }
`;

export const ArrowIconStyled = styled(Icon)`
  width: 14px;
  height: 14px;
`;

export const SliderPrevButtonStyled = styled.button`
  left: -64px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.typography200};
  transform: translateY(25%);

  ${ArrowIconStyled} {
    transform: rotate(90deg);
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.typography200};
  }

  &::before {
    display: none;
  }
`;

export const SliderNextButtonStyled = styled(SliderPrevButtonStyled)`
  right: -64px;
  left: unset;

  ${ArrowIconStyled} {
    transform: rotate(-90deg);
  }

  &::before {
    transform: rotate(-90deg);
  }
`;
