import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ReviewScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Feedback,
  FeedbackName,
  FeedbackRating,
  FeedbackText,
  FeedbackWrapper,
  HintTitle,
  IconStyled,
  ImageContainer,
  Logo,
  Subtitle,
  Text,
  Title,
  Tooltip,
} from './styled';

const ReviewScreen = ({ content, onBack, goNext }: ReviewScreenProps) => {
  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <QuizBackground backgroundImage={content.backgroundImage} variant="darkGradient" />
      <Container>
        {!!content.logo && (
          <ImageContainer>
            <Logo
              src={content.logo.src}
              alt={content.logo.alt || 'couple'}
              width={100}
              height={100}
            />
          </ImageContainer>
        )}
        <HintTitle dangerouslySetInnerHTML={prepareHtml(content.title)} />
        {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
        {content.description && (
          <Title dangerouslySetInnerHTML={prepareHtml(content.description)} />
        )}
        {content.feedback && (
          <Feedback>
            <FeedbackWrapper>
              <FeedbackName>{content.feedback.name}</FeedbackName>
              <FeedbackRating />
            </FeedbackWrapper>
            <FeedbackText>{content.feedback.text}</FeedbackText>
          </Feedback>
        )}
        <Text>
          {content.text}
          {!!content.tooltip && (
            <Tooltip text={content.tooltip}>
              <IconStyled name="info" />
            </Tooltip>
          )}
        </Text>
        <Button onClick={goNext} variant="secondary">
          {content.next}
        </Button>
      </Container>
    </>
  );
};

export default ReviewScreen;
