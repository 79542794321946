import Image from 'core/common/components/Image';
import { FunnelImage } from 'core/funnel/entities';

import { GuaranteePaymentBlock, PaymentImagesBlock, Title } from './styled';

type GuaranteePaymentSectionProps = {
  title: string;
  image: FunnelImage;
};

const GuaranteePaymentSection = ({ title, image }: GuaranteePaymentSectionProps) => {
  return (
    <GuaranteePaymentBlock>
      <Title>{title}</Title>
      <PaymentImagesBlock>
        <Image src={image.src} alt={image.alt} layout="fill" objectFit="contain" />
      </PaymentImagesBlock>
    </GuaranteePaymentBlock>
  );
};

export default GuaranteePaymentSection;
