import styled from 'styled-components';
import { ContainerMain } from 'core/common/components/Quiz/styled';

export const ContainerMainStyled = styled(ContainerMain)`
  position: relative;
  flex-grow: 1;
  max-width: 450px;
  line-height: 135%;
  text-align: left;
`;

export const TitleStyled = styled.h2`
  width: 100%;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 135%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    text-align: center;
  }
`;

export const SubtitleStyled = styled.p`
  width: 100%;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 40px;
    text-align: center;
  }
`;
