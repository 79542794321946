import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import QuizBackground from 'core/common/components/QuizBackground';
import { NameMoonCompatibilityV2ScreenProps } from 'core/funnel/entities';
import {
  ButtonsWrapperStyled,
  ButtonStyled,
  ContentStyled,
  FormStyled,
  InputStyled,
  InputWrapperStyled,
  SkipButtonStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

const NameMoonCompatibilityV2Screen = ({
  content,
  initialUserName,
  onSubmit,
  onBack,
  onSkip,
  step,
  totalSteps,
  progressBarTitle,
}: NameMoonCompatibilityV2ScreenProps) => {
  const { title, subtitle, placeholder, next, skipButton, backgroundImage } = content;

  const [userName, setUserName] = useState(initialUserName);
  // To update "userName" on client side when "initialUserName" from Redux will exist
  useEffect(() => {
    setUserName(initialUserName);
  }, [initialUserName]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(userName);
  };

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header onBack={onBack} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <ContentStyled>
        <TitleStyled>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <FormStyled onSubmit={handleSubmit}>
          <InputWrapperStyled>
            <InputStyled placeholder={placeholder} onChange={handleInputChange} value={userName} />
          </InputWrapperStyled>
          <ButtonsWrapperStyled>
            <ButtonStyled type="submit" disabled={!userName.trim()}>
              {next}
            </ButtonStyled>
            {skipButton && <SkipButtonStyled onClick={onSkip}>{skipButton}</SkipButtonStyled>}
          </ButtonsWrapperStyled>
        </FormStyled>
      </ContentStyled>
    </>
  );
};
export default NameMoonCompatibilityV2Screen;
