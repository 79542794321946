import { FunnelImage } from 'core/funnel/entities';
import { AspectRatioImageWrapperStyled, CaptionStyled, ImageStyled, TitleStyled } from './styled';

type CustomersProgressProps = {
  title: string;
  caption: string;
  image: FunnelImage;
};

const CustomersProgress = ({ title, caption, image }: CustomersProgressProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <AspectRatioImageWrapperStyled>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </AspectRatioImageWrapperStyled>
      <CaptionStyled>{caption}</CaptionStyled>
    </>
  );
};

export default CustomersProgress;
