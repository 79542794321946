import styled, { keyframes } from 'styled-components';
import background from 'core/common/assets/images/thankYou/background.png';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(${background.src}) center center;
  background-size: cover;
`;

const scale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10%, 50%, 100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const StyledConfettiWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${scale} 3s both;
`;

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 540px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 100px;
  color: ${(props) => props.theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 0;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
}
  70% {
    transform: rotate(360deg);
}
  100% {
    transform: rotate(420deg);
}
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  text-align: center;
  animation: ${scale} 3s both;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
  }
`;

export const StyledImage = styled(Image)`
  animation: ${rotate} 30s linear infinite;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 120px;
    height: 120px;
  }
`;

export const StyledImageDone = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 50px;
    height: 38px;
  }
`;

export const StyledTitle = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 56px;
    line-height: 76px;
  }
`;

export const StyledDescription = styled.p`
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 44px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const StyledList = styled.ul`
  position: relative;

  &::before {
    position: absolute;
    left: -4px;
    width: 32px;
    height: 100%;
    border-radius: 100px;
    background: linear-gradient(180deg, rgba(129, 231, 194, 0.3) 0%, rgba(117, 121, 169, 0) 100%);
    content: '';
  }
`;

export const StyledItem = styled.li`
  display: flex;
  padding-top: 20px;
`;

export const StyledIcon = styled(Icon)<{ isDone?: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 18px;
  fill: ${({ isDone }) => (isDone ? '#6FDFB4' : '#dedede80')};
`;

export const StyledListWrapper = styled.div``;

export const StyledListTitle = styled.p`
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledListText = styled.p`
  font-size: 14px;

  b {
    font-weight: 600;
  }
`;

export const StyledWrapperButton = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  text-align: center;
  background: linear-gradient(180deg, rgba(102, 102, 158, 0) 0%, #463d83 100%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    position: static;
    margin-top: 40px;
    padding: 0;
    background: none;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 56px;
  color: ${({ theme }) => theme.colors.info};
  background: ${({ theme }) => theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 462px;
  }
`;
