import styled from 'styled-components';

export const SquareButton = styled.button<{ isChoosen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 8px;
  background: ${(props) =>
    props.isChoosen ? props.theme.colors.infoLight : `${props.theme.colors.primary100}50`};

  & img {
    filter: ${(props) => (props.isChoosen ? 'brightness(0) invert(1)' : 'auto')};
  }
`;

export const IconWrapper = styled.span`
  display: block;
  margin-bottom: 12px;

  & > .square-button-icon {
    @media ${(props) => props.theme.breakpoints.desktop} {
      width: 64px;
      height: 64px;
    }
  }
`;

export const Text = styled.span<{ isChoosen: boolean }>`
  font-size: 14px;
  text-transform: capitalize;
  color: ${(props) =>
    props.isChoosen ? props.theme.colors.typography100 : props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;
