import GoalScreen from 'core/common/components/QuizScreens/Goal';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { GoalScreenAttributes } from 'core/funnel/entities';

type GoalProps = {
  id: string;
};

const Goal = ({ id }: GoalProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return <GoalScreen onBack={back} goNext={next} {...(screenAttributes as GoalScreenAttributes)} />;
};

export default Goal;
