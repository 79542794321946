import MentionedInScreen from 'core/common/components/QuizScreens/MentionedIn';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { MentionedInScreenAttributes } from 'core/funnel/entities';

type MentionedInProps = {
  id: string;
};

const MentionedIn = ({ id }: MentionedInProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <MentionedInScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as MentionedInScreenAttributes)}
    />
  );
};

export default MentionedIn;
