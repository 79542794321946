import DatePickerV2Screen from 'core/common/components/QuizScreens/DatePickerV2';
import { useServices } from 'core/common/hooks';
import { calculateAges } from 'core/common/utils/calculateAges';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DatePickerV2ScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type DatePickerV2PageProps = {
  id: string;
};

const DatePickerV2Page = ({ id }: DatePickerV2PageProps) => {
  const { back, screenAttributes, updateUserPreferences, userPreferences, step, next } =
    useQuizStep(id);
  const { analyticsService } = useServices();

  const { dataKey } = screenAttributes as DatePickerV2ScreenAttributes;
  const userDate = userPreferences[dataKey as keyof UserProfile] as string;

  const onSubmit = async (date: string) => {
    const userAge = calculateAges(date);
    analyticsService.setUserAge(userAge);
    updateUserPreferences(dataKey, date);
    next();
  };

  return (
    <DatePickerV2Screen
      userDate={userDate}
      onBack={back}
      onSubmit={onSubmit}
      step={step}
      {...(screenAttributes as DatePickerV2ScreenAttributes)}
    />
  );
};

export default DatePickerV2Page;
