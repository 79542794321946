import QuizBackground from 'core/common/components/QuizBackground';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { PrelandingMoonCompatibilityScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import DataSecuredBadge from '../../DataSecuredBadge';
import Header from '../../Header';
import {
  StyledAdvantagesList,
  StyledAdvantagesListItem,
  StyledAdvantagesText,
  StyledAdvantagesTextWrapper,
  StyledAdvantagesTitle,
  StyledQuizInformationListIcon,
  StyledImageWrapper,
  StyledImage,
  StyledQuizInformationList,
  StyledQuizInformationListItem,
  StyledQuizInformationListText,
  StyledTitle,
  StyledDescription,
  StyledSignInButton,
  StyledAddressUS,
  StyledAddress,
  StyledFooter,
  StyledButton,
  StyledContainer,
} from './styled';

const PrelandingMoonCompatibilityScreen = ({
  onStart,
  signIn,
  content,
}: PrelandingMoonCompatibilityScreenProps) => {
  const {
    image,
    advantagesList,
    quizList,
    title,
    description,
    signInButton,
    dataSecuredBadge,
    button,
  } = content;

  return (
    <RenderOnlyOnClient>
      <QuizBackground />
      <StyledContainer>
        <Header buttonBack={false} />
        <StyledAdvantagesList>
          {advantagesList.map((advantage) => (
            <StyledAdvantagesListItem key={advantage.title}>
              <StyledQuizInformationListIcon src={advantage.icon} width={20} height={20} alt="" />
              <StyledAdvantagesTextWrapper>
                <StyledAdvantagesTitle>{advantage.title}</StyledAdvantagesTitle>
                <StyledAdvantagesText>{advantage.text}</StyledAdvantagesText>
              </StyledAdvantagesTextWrapper>
            </StyledAdvantagesListItem>
          ))}
        </StyledAdvantagesList>
        <StyledImageWrapper>
          <StyledImage fill src={image.src} alt={image.alt} />
        </StyledImageWrapper>
        <StyledQuizInformationList>
          {quizList.map(({ text, icon }) => (
            <StyledQuizInformationListItem key={text}>
              <StyledQuizInformationListIcon src={icon} width={16} height={16} alt="" />
              <StyledQuizInformationListText>{text}</StyledQuizInformationListText>
            </StyledQuizInformationListItem>
          ))}
        </StyledQuizInformationList>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledFooter>
          <StyledButton onClick={onStart} variant="primary" fullWidth>
            {button}
          </StyledButton>
          {signInButton && <StyledSignInButton onClick={signIn}>{signInButton}</StyledSignInButton>}
          {dataSecuredBadge && (
            <DataSecuredBadge title={dataSecuredBadge.title} icon={dataSecuredBadge.icon} />
          )}
          <Location>
            <LocationOption option={Locales.US}>
              <StyledAddressUS>Nevada, USA</StyledAddressUS>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <StyledAddress />
            </LocationOption>
          </Location>
        </StyledFooter>
      </StyledContainer>
    </RenderOnlyOnClient>
  );
};

export default PrelandingMoonCompatibilityScreen;
