import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const GuaranteePaymentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const TitleStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const PaymentImagesBlockStyled = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 60px;
  }
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;
