import { useState } from 'react';
import { DeckSliderCard, SectionDeckSliderAttributes } from 'core/funnel/entities';
import Card from './Card';
import { SwipeAnimationDirection, useSectionDeckSlider } from './useSectionDeckSlider';
import {
  TitleStyled,
  WrapperStyled,
  CardsListStyled,
  SwipeFingerStyled,
  ListWrapperStyled,
  CardsListItemStyled,
  NextCardButtonStyled,
  SwipeDisclaimerStyled,
  SwipeDisclaimerIconStyled,
} from './styled';

type SectionDeckSliderProps = {
  content: SectionDeckSliderAttributes['content'];
  blurred?: boolean;
  className?: string;
};

const SectionDeckSlider = ({ content, className, blurred = false }: SectionDeckSliderProps) => {
  const [cards, setCards] = useState<DeckSliderCard[]>(() => {
    return [...content.cards].sort((a, b) => a.position - b.position);
  });

  const reorderCards = () => {
    const shallowCards = cards.map((card) => {
      if (card.position === cards.length - 1) {
        return { ...card, position: 0 };
      }

      return { ...card, position: card.position + 1 };
    });

    setCards(shallowCards);
  };

  const { swipeHandlers, swipeAnimationDirection, handleCardsReorder } = useSectionDeckSlider({
    onSwipe: reorderCards,
  });

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{content.title}</TitleStyled>
      <ListWrapperStyled>
        <CardsListStyled {...swipeHandlers} blurred={blurred}>
          {cards.map((card, idx) => (
            <CardsListItemStyled
              idx={idx}
              key={card.title}
              innerCardPosition={card.position}
              isTop={card.position === cards.length - 1}
              swipeAnimationDirection={swipeAnimationDirection}
            >
              <Card {...card} idx={idx} />
            </CardsListItemStyled>
          ))}
        </CardsListStyled>
        {blurred && (
          <SwipeFingerStyled
            src={content.swipeImage.src}
            alt={content.swipeImage.alt}
            width={93}
            height={93}
          />
        )}
        <SwipeDisclaimerStyled>
          <SwipeDisclaimerIconStyled
            src={content.disclaimer.image.src}
            alt={content.disclaimer.image.alt}
            width={16}
            height={16}
            quality={100}
          />
          {content.disclaimer.text}
        </SwipeDisclaimerStyled>
        <NextCardButtonStyled onClick={() => handleCardsReorder(SwipeAnimationDirection.RIGHT)}>
          {content.nextCardButtonText}
        </NextCardButtonStyled>
      </ListWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionDeckSlider;
