import styled from 'styled-components';

import CheckmarckIcon from 'core/common/assets/images/acceptMark.svg';
import priceTagImage from 'core/common/assets/images/price-tag.svg';

const chekedWrapperHorizontalGutter = 8;
const chekedWrapperBottomGutter = 18;

export const MostPopularTitle = styled.span`
  display: block;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.typography100};
`;

export const CheckedWrapper = styled.div<{ checked?: boolean }>`
  position: absolute;
  top: ${(props) => (props.checked ? '-4px' : '0')};
  left: ${(props) => (props.checked ? '-4px' : '0')};
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.checked ? `calc(100% + ${chekedWrapperHorizontalGutter}px)` : '100%')};
  height: ${(props) =>
    props.checked
      ? `calc(100% + ${chekedWrapperBottomGutter}px + ${chekedWrapperHorizontalGutter / 2}px)`
      : `calc(100% + ${chekedWrapperBottomGutter}px)`};
  border-radius: 16px;
  background: linear-gradient(
    166deg,
    #141333 -33.39%,
    #202261 15.89%,
    #543c97 55.84%,
    #6939a1 74.96%
  );
`;

export const Label = styled.label<{ isMostPopular?: boolean; checked?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.isMostPopular ? '40px' : '16px')};
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 12px;
  color: ${(props) => props.theme.colors.typography900};
  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  outline: ${(props) => (props.checked && !props.isMostPopular ? '1px solid #6939A1' : 'none')};
`;

export const Radio = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Checkmark = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.typography100};

  &::before {
    display: none;
    width: 50%;
    height: 50%;
    background-image: url(${CheckmarckIcon});
    background-size: cover;
    background-position: center;
    content: '';
    fill: ${({ theme }) => theme.colors.info};
  }
`;

export const HiddenRadio = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);

  &:checked + ${Radio} ${Checkmark}::before {
    display: inline-block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PlanTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const Subtitle = styled.span<{ variant: 'dark' | 'light' }>`
  font-size: 14px;
  color: ${(props) => props.theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 12px;
  }
`;

export const OldPrice = styled.span`
  text-decoration: line-through;
`;

export const PricePerDayFloatingPart = styled.span`
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  color: ${(props) => props.theme.colors.typography900};
`;

export const PricePerDayFloatingPartText = styled.span`
  font-size: 8px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: rgba(15, 15, 15, 0.6);
`;

export const PricePerDayCurrency = styled.span`
  margin-right: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 12px;
  }
`;

export const PricePerDayFloatingPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2px;
`;

export const PricePerDayIntegerPart = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 0.75;
  text-align: center;
  color: ${(props) => props.theme.colors.typography900};
`;

export const MainWrapper = styled.div`
  position: relative;
`;

export const PricePerDayWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  padding: 8px 16px 8px 26px;
  background-image: url(${priceTagImage});
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OldPriceWrapper = styled.div`
  align-self: flex-end;
  height: 17px;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: rgba(15, 15, 15, 0.38);
`;
