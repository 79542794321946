import { useCallback, useEffect } from 'react';
import EmailMarketingNatalChart from 'core/common/components/QuizScreens/EmailMarketingNatalChart';
import { useInView, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { EmailMarketingNatalChartScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder } from 'core/payments/hooks';
import { updateOrderId } from 'core/payments/store/actions';
import { useUserLocation } from 'core/user/hooks';
import { SectionEndStyled } from './styled';

type NatalChartEmailMarketingProps = {
  id: string;
};

const NatalChartEmailMarketing = ({ id }: NatalChartEmailMarketingProps) => {
  const { next, updateUserPreferences, getScreenAttributes } = useQuizStep(id);
  const { order } = useGetOrder();
  const { analyticsService } = useServices();
  const { defaultOffers, fetchOffersByCategory, updateOffer } = useOffers();
  const { isEuropean } = useUserLocation();
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const offer = defaultOffers[0];
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)
  const { analyticEmailMarketing } = screenAttributes as EmailMarketingNatalChartScreenAttributes;

  useEffect(() => {
    fetchOffersByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEuropean]);

  useEffect(() => {
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEuropean, offer]);

  useEffect(() => {
    if (inView) {
      analyticsService.emailMarketingLpScrolledToEnd({
        page: analyticEmailMarketing?.page ?? 'natal_chart',
        view: analyticEmailMarketing?.view ?? 'general_landing',
        source: analyticEmailMarketing?.scrolledSource ?? 'natal_chart_email_land_updated',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, analyticEmailMarketing]);

  useEffect(() => {
    analyticsService.emailMarketingLpOpened({
      page: analyticEmailMarketing?.page ?? 'natal_chart',
      view: analyticEmailMarketing?.view ?? 'general_landing',
      source: analyticEmailMarketing?.source ?? 'natal_chart_email_land_first',
    });
  }, [analyticsService, analyticEmailMarketing]);

  const getReport = useCallback(
    (eventName: string) => {
      analyticsService.emailMarketingLpGetProduct({
        page: analyticEmailMarketing?.page ?? 'natal_chart',
        button: eventName,
        source: analyticEmailMarketing?.source ?? 'natal_chart_email_land_first',
        productId: offer.id,
      });

      if (order) {
        updateOrderId(order.order_id);
      }

      updateUserPreferences('plan', { productId: offer.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsService, order],
  );

  if (!('sections' in screenAttributes)) return null;

  return (
    <EmailMarketingNatalChart
      offer={offer}
      sectionEnd={<SectionEndStyled ref={ref} />}
      onGetReport={getReport}
      paymentSucceed={next}
      {...(screenAttributes as EmailMarketingNatalChartScreenAttributes)}
    />
  );
};

export default NatalChartEmailMarketing;
