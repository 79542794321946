import { useEffect } from 'react';
import { useLocalStorage } from 'core/common/hooks';
import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import {
  OptionStyled,
  OptionLabelStyled,
  IconStyled,
  LinearProgressStyled,
  ProgressValueStyled,
} from './styled';

type OptionProps = {
  icon: string;
  label: string;
  blurred?: boolean;
  percantageRange: {
    min: number;
    max: number;
  };
};

const Option = ({ icon, label, percantageRange, blurred = false }: OptionProps) => {
  const [progressValue, setProgressValue] = useLocalStorage(`slider${label}Value`, 0);

  useEffect(() => {
    if (progressValue) return;

    setProgressValue(getRandomNumberWithinRange(percantageRange.min, percantageRange.max));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptionStyled blurred={blurred}>
      <OptionLabelStyled>
        <IconStyled name={icon} />
        {label}
        <ProgressValueStyled>{progressValue}%</ProgressValueStyled>
      </OptionLabelStyled>
      <LinearProgressStyled value={blurred ? 0 : progressValue} />
    </OptionStyled>
  );
};

export default Option;
