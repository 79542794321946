import { FunnelImage } from 'core/funnel/entities';
import { GuaranteeBlock, GuaranteeContent, MedalImage, Text, Title } from './styled';

type SectionGuaranteeProps = {
  title: string;
  firstText: string;
  secondText: string;
  image: FunnelImage;
};

const SectionGuarantee = ({ title, firstText, secondText, image }: SectionGuaranteeProps) => {
  return (
    <GuaranteeBlock>
      <MedalImage src={image.src} alt={image.alt} width={54} height={57} quality={100} />
      <GuaranteeContent>
        <Title>{title}</Title>
        <Text>{firstText}</Text>
        <Text>{secondText}</Text>
      </GuaranteeContent>
    </GuaranteeBlock>
  );
};

export default SectionGuarantee;
