import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const GuaranteeBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 16px 8px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const GuaranteeContent = styled.div`
  margin-top: 24px;
`;

export const MedalImage = styled(Image)`
  margin-right: auto;
  margin-left: auto;
`;

export const Text = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`;
