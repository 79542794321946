import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Typography from 'core/common/components/Typography';

export const WrapperStyled = styled.div`
  position: relative;
  height: 100%;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
`;

export const ContentStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 330px;
  padding: 20px 16px 12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${({ theme }) => theme.colors.typography100};
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 12px;
`;

export const TextStyled = styled.p`
  margin-bottom: auto;
  line-height: 150%;
  color: #2f2f2f;
`;

export const CaptionStyled = styled(Typography)`
  margin-bottom: 4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const FooterIconStyled = styled(Image)`
  margin-right: auto;
  margin-left: auto;
`;
