import styled, { keyframes } from 'styled-components';
import background from 'core/common/assets/images/thankYou/background.png';
import Image from 'core/common/components/Image';

export const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(${background.src}) center center;
  background-size: cover;
`;

const scale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10%, 50%, 100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const StyledConfettiWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${scale} 3s both;
`;

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 470px;
  margin-right: auto;
  margin-left: auto;
  color: ${(props) => props.theme.colors.typography100};
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
}
  70% {
    transform: rotate(360deg);
}
  100% {
    transform: rotate(420deg);
}
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  animation: ${scale} 3s both;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 56px;
  }
`;

export const StyledImage = styled(Image)`
  animation: ${rotate} 30s linear infinite;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 150px;
    height: 150px;
  }
`;

export const StyledImageDone = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 50px;
    height: 38px;
  }
`;

export const StyledTitle = styled.h2`
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 60px;
  }
`;

export const StyledDescription = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;

  b {
    font-weight: 600;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
    line-height: 30px;
  }
`;
