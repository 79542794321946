import { useEffect } from 'react';
import UnlimitedReadingsPalmistryScreen from 'core/common/components/QuizScreens/UnlimitedReadingsPalmistry';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UnlimitedReadingsPalmistryScreenAttributes } from 'core/funnel/entities';
import { useGetActualPaymentPlans } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

export type UnlimitedReadingsEmailMarketingProps = {
  id: string;
};

const UnlimitedReadingsEmailMarketing = ({ id }: UnlimitedReadingsEmailMarketingProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  const { premiumUpsellPlan: plan } = useGetActualPaymentPlans();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    analyticsService.upsellPremiumScreenClicked();

    recurringPaymentRequest(plan.value);
  };

  const skip = () => {
    next();
  };

  useEffect(() => {
    analyticsService.upsellPremiumScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellPremiumScreenGetReport(plan.value);
      next();
    }
  }, [paymentProcessStep]);

  return (
    <UnlimitedReadingsPalmistryScreen
      plan={plan}
      onGetReport={getReport}
      onSkip={skip}
      isLoading={isPurchaseLoading}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UnlimitedReadingsPalmistryScreenAttributes)}
    />
  );
};

export default UnlimitedReadingsEmailMarketing;
