import styled, { keyframes } from 'styled-components';
import Image from 'core/common/components/Image';

const animationDuration = 3;

export const Container = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Logo = styled(Image)`
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: ${animationDuration}s;
`;

export const LogoWrapper = styled.div`
  transform: translateY(-130px);
`;

export const Break = styled.br``;

export const Text = styled.p<{ value: number }>`
  position: absolute;
  width: 330px;
  margin-top: 25px;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: white;
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: ${animationDuration}s;
  animation-delay: ${(props) => props.value * animationDuration}s;

  span {
    display: block;
    text-align: center;
  }
`;
