import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import ImageComponent from 'core/common/components/Image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 472px;
  margin-top: 68px;
  margin-right: auto;
  margin-left: auto;
`;

export const Image = styled(ImageComponent)`
  width: 150px;
  height: 150px;
  margin-bottom: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

export const OldPrice = styled.span`
  margin-right: 8px;
  text-decoration: line-through;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Price = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.typography100};
  background: ${(props) => props.theme.colors.error300};
`;

export const List = styled.ul`
  margin-bottom: -8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: -12px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 12px;
  }
`;

export const EmojiWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  padding: 8px;
  border-radius: 50%;
  background: rgba(228, 131, 45, 0.1);
`;

export const Emoji = styled(EmojiComponent)`
  width: 16px;
  height: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.p`
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  margin-top: 24px;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.infoLight};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
  }
`;
