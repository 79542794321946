import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const salesScreenHeaderHeight = 62;

export const Header = styled.header<{ hide: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  align-self: center;
  width: 100%;
  height: ${salesScreenHeaderHeight}px;
  padding: 8px 15px;
  background-color: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    padding: 10px 40px;
  }
`;

export const HeaderCell = styled.div`
  display: flex;
  width: 50%;

  &.hidden-on-mobile {
    display: none;

    @media ${(props) => props.theme.breakpoints.desktop} {
      display: flex;
    }
  }

  &.justify {
    justify-content: flex-start;

    @media ${(props) => props.theme.breakpoints.desktop} {
      justify-content: center;
    }
  }

  &.justify-end {
    justify-content: flex-end;
  }
`;

export const ButtonContainer = styled.div`
  width: 140px;
  height: 32px;
  background: ${(props) => props.theme.colors.white};
  backface-visibility: hidden;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 240px;
  }
`;

export const GetReadingButton = styled(Button)`
  font-weight: 600;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;
