import question from 'core/common/assets/images/hintQuiz/question.png';
import Modal from 'core/common/components/Modal';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import {
  AgreeButton,
  ButtonWrapper,
  CloseButtonStyled,
  DisagreeButton,
  Image,
  ImageContainer,
  QuestionWrapper,
  Title,
  Wrapper,
} from './styled';

export type QuestionProps = {
  title: string;
  isModalOpen: boolean;
  onClose: (value: 'closed' | 'yes' | 'no') => void;
  accept: string;
  decline: string;
};

const Question = ({ title, isModalOpen, onClose, accept, decline }: QuestionProps) => {
  return (
    <Modal
      BackdropProps={{ style: { background: 'rgba(40, 40, 97, 0.5)' } }}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          onClose('closed');
        }
      }}
      open={isModalOpen}
    >
      <Wrapper>
        <CloseButtonStyled onClick={() => onClose('closed')} />
        <ImageContainer>
          <Image src={question} alt="question" width={134} height={137} />
        </ImageContainer>
        <QuestionWrapper>
          <Title dangerouslySetInnerHTML={prepareHtml(title)} />
          <ButtonWrapper>
            <DisagreeButton onClick={() => onClose('no')}>{decline}</DisagreeButton>
            <AgreeButton onClick={() => onClose('yes')}>{accept}</AgreeButton>
          </ButtonWrapper>
        </QuestionWrapper>
      </Wrapper>
    </Modal>
  );
};

export default Question;
