import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import {
  HeaderStyled,
  TimeBlockStyled,
  TimerDividerStyled,
  TimerUnitCellStyled,
  TimerUnitLabelStyled,
  TimerUnitTextStyled,
  TitleStyled,
} from './styled';

type HeaderSectionProps = {
  timeLeft: number;
  content: { min: string; sec: string; title: string };
};

const HeaderSection = ({ timeLeft, content }: HeaderSectionProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);
  const { min, title, sec } = content;

  return (
    <HeaderStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <TimeBlockStyled>
        <TimerUnitCellStyled>
          <TimerUnitTextStyled>{addLeadingZero(minutes)}</TimerUnitTextStyled>
          <TimerUnitLabelStyled>{min}</TimerUnitLabelStyled>
        </TimerUnitCellStyled>
        <TimerDividerStyled>:</TimerDividerStyled>
        <TimerUnitCellStyled>
          <TimerUnitTextStyled>{addLeadingZero(seconds)}</TimerUnitTextStyled>
          <TimerUnitLabelStyled>{sec}</TimerUnitLabelStyled>
        </TimerUnitCellStyled>
      </TimeBlockStyled>
    </HeaderStyled>
  );
};

export default HeaderSection;
