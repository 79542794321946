import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';

export const Title = styled.h2`
  display: inline;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};

  & > b {
    font-weight: inherit;
    color: ${(props) => props.theme.colors.info};
  }
`;

export const Description = styled.p`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: left;
`;

export const HighlightText = styled.b`
  color: ${(props) => props.theme.colors.info};
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const Subtitle = styled.h4`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};
`;

export const Skip = styled.button`
  margin-bottom: 20px;
  font-weight: 600;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.typography500};
  background: transparent;
`;

export const Image = styled(ImageComponent)``;

export const ImageContainer = styled.div`
  margin-top: 20px;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  max-width: 330px;
  height: 50px;
  margin-top: auto;
  margin-bottom: 15px;
  border-radius: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 100px;
  }
`;
