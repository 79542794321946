import { Description, InfoContainer, Title } from './styled';

type CompatibilityProps = {
  title: string;
  description: string;
};

const Compatibility = ({ title, description }: CompatibilityProps) => {
  return (
    <InfoContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </InfoContainer>
  );
};

export default Compatibility;
