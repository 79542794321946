import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionInfoAttributes } from 'core/funnel/entities';
import { Image, DescriptionStyled, TitleStyled } from './styled';

type SectionAdvantagesProps = {
  content: SectionInfoAttributes['content'];
};

const SectionAdvantages = ({ content }: SectionAdvantagesProps) => {
  return (
    <>
      {content.image?.src && (
        <Image width={1000} height={700} src={content.image.src} alt={content.image.alt} />
      )}
      <TitleStyled>{content.title}</TitleStyled>
      <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(content.description)} />
    </>
  );
};

export default SectionAdvantages;
