import { Fragment } from 'react';
import { UpsellsPlan } from 'core/offers/entities';
import {
  Circle,
  Container,
  Description,
  Discount,
  DiscountText,
  Emoji,
  IconStyled,
  Input,
  OldPrice,
  Price,
  PriceBlock,
  PrimaryText,
  RadioLabel,
  TextContent,
  Title,
} from './styled';

export type SectionPaymentProps = {
  selectedProduct: string;
  selectProduct: (value: string) => void;
  plans: Record<string, UpsellsPlan>;
};

const SectionPayment = ({ selectedProduct, selectProduct, plans }: SectionPaymentProps) => {
  return (
    <Container>
      {Object.values(plans)
        .sort((a, b) => a.order - b.order)
        .map((plan) => (
          <Fragment key={plan.value}>
            <Input
              type="radio"
              name="advisory-plan"
              id={plan.value}
              value={plan.value}
              checked={selectedProduct === plan.value}
              onChange={(e) => selectProduct(e.target.value)}
            />
            <RadioLabel htmlFor={plan.value} isChosen={selectedProduct === plan.value}>
              <Circle>
                <IconStyled name="check" width={20} height={20} />
              </Circle>
              <TextContent>
                <Title>{plan.title}</Title>
                {!!plan.description && <Description>{plan.description}</Description>}
                <PriceBlock>
                  <Price>{plan.price}</Price> ( {plan.oldPriceDescription}{' '}
                  <PrimaryText>
                    <OldPrice>{plan.oldPrice}</OldPrice>)
                  </PrimaryText>
                  <Discount>
                    <DiscountText>{plan.discount}</DiscountText>
                  </Discount>
                </PriceBlock>
              </TextContent>
              <Emoji emoji={plan.emoji} />
            </RadioLabel>
          </Fragment>
        ))}
    </Container>
  );
};

export default SectionPayment;
