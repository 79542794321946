import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const BackgroundImageStyled = styled(Image)`
  object-fit: contain;
  object-position: top;
`;
