import { formatTraumaScore } from 'core/common/utils/formatTraumaScore';
import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled,
  ImageStyled,
  ImageWrapperStyled,
  TraumaScoreStyled,
} from './styled';

type UserTraumaScoreProps = {
  title: string;
  value: string;
  image: FunnelImage;
  traumaScore: number;
};

const UserTraumaScore = ({ title, value, image, traumaScore }: UserTraumaScoreProps) => {
  const traumaValue = formatTraumaScore(traumaScore);

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>{value}</SubtitleStyled>
      <ImageWrapperStyled>
        <ImageStyled src={image.src} alt={image.alt} width={80} height={80} />
        <TraumaScoreStyled>{traumaValue}/10</TraumaScoreStyled>
      </ImageWrapperStyled>
    </WrapperStyled>
  );
};

export default UserTraumaScore;
