import { FunnelImage } from 'core/funnel/entities';
import { Badge, Text, StyledImage } from './styled';

type DataSecuredBadgeProps = {
  title: string;
  icon: FunnelImage;
  className?: string;
};

const DataSecuredBadge = ({ title, icon, className }: DataSecuredBadgeProps) => {
  return (
    <Badge className={className}>
      <StyledImage src={icon.src} alt={icon.alt} width={16} height={16} />
      <Text>{title}</Text>
    </Badge>
  );
};

export default DataSecuredBadge;
