import Picker from 'react-mobile-picker';
import styled from 'styled-components';
import { PickerVariants } from './types';

export const PickerItemLabelStyled = styled.div<{ selected: boolean; variant: PickerVariants }>`
  font-weight: 500;
  line-height: inherit;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.typography900 : theme.colors.typography300};
  transition: 0.1s all;
  transform: ${({ selected, variant }) => {
    const isCylinder = variant === PickerVariants.CYLINDER;
    if (isCylinder && !selected) {
      return 'rotateX(45deg) scale(1)';
    }
    if (isCylinder && selected) {
      return 'rotateX(0deg) scale(1.1)';
    }
    return 'rotateX(0deg) scale(1)';
  }};
`;

export const PickerStyled = styled(Picker)`
  width: 100%;
  max-width: 260px;
  margin-bottom: 20px;
  line-height: inherit;
  perspective: 100px;
`;
