import goalImage from 'core/common/assets/images/trialPayment/goal_full.svg';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';

import { GoalInfo, GoalLabel, GoalText, GoalWrapper } from './styled';

type SectionGoalProps = {
  title: string;
  relationshipGoal: string;
};

const SectionGoal = ({ relationshipGoal, title }: SectionGoalProps) => {
  return (
    <GoalWrapper>
      <Image src={goalImage} width={38} height={38} alt="Goal" />
      <GoalInfo>
        <GoalLabel dangerouslySetInnerHTML={prepareHtml(title)} />
        <GoalText dangerouslySetInnerHTML={prepareHtml(relationshipGoal)} />
      </GoalInfo>
    </GoalWrapper>
  );
};

export default SectionGoal;
