import secretDiscount from 'core/common/assets/images/additional-discount/secret_discount.png';
import Header from 'core/common/components/Header';
import { useAdditionalDiscountIntroOffer } from './useAdditionalDiscountIntroOffer';
import {
  Background,
  BoldText,
  Button,
  Container,
  CrossedPrice,
  Emoji,
  EmojiBackground,
  Highlight,
  ImageContainer,
  Item,
  List,
  Price,
  StyledImage,
  Text,
  Title,
} from './styled';

type AdditionalDiscountIntroOfferProps = {
  goNext: () => void;
};

const AdditionalDiscountIntroOffer = ({ goNext }: AdditionalDiscountIntroOfferProps) => {
  const { discountOffer, onGetReport, goBack } = useAdditionalDiscountIntroOffer(goNext);

  if (!discountOffer) {
    return null;
  }

  return (
    <>
      <Header onBack={goBack} />
      <Background />
      <Container>
        <ImageContainer>
          <StyledImage src={secretDiscount} alt="friends" />
        </ImageContainer>
        <Title>Save 40% off!</Title>
        <Price>
          <CrossedPrice>{discountOffer.currencySymbol}39.99</CrossedPrice>{' '}
          <Highlight>{discountOffer.pricesWithCurrencySymbol.priceWithVAT}</Highlight>
        </Price>
        <List>
          <Item>
            <EmojiBackground variant="orange">
              <Emoji emoji="present" />
            </EmojiBackground>
            <Text>
              7-day trial for just {discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT}
            </Text>
          </Item>
          <Item>
            <EmojiBackground variant="gray">
              <Emoji emoji="fire" />
            </EmojiBackground>
            <Text>
              <BoldText>40% off</BoldText> on your reading
            </Text>
          </Item>
        </List>
        <Button onClick={onGetReport} fullWidth>
          Get secret discount
        </Button>
      </Container>
    </>
  );
};

export default AdditionalDiscountIntroOffer;
