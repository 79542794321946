import { SectionGuaranteeV2Attributes } from 'core/funnel/entities';
import { ContainerStyled, DescriptionStyled, TitleStyled, ImageStyled } from './styled';

type SectionGuaranteeV2Props = {
  content: SectionGuaranteeV2Attributes['content'];
  className?: string;
};

const SectionGuaranteeV2 = ({ content, className }: SectionGuaranteeV2Props) => {
  const { title, description, image } = content;
  const { src, alt } = image;

  return (
    <ContainerStyled className={className}>
      <ImageStyled src={src} width="54" height="70" alt={alt} />
      <TitleStyled>{title}</TitleStyled>
      <DescriptionStyled>{description}</DescriptionStyled>
    </ContainerStyled>
  );
};

export default SectionGuaranteeV2;
