import styled from 'styled-components';
import BackgroundGradient from 'core/common/components/BackgroundGradient';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const Background = styled(BackgroundGradient)`
  background: ${(props) => props.theme.colors.themeBackground};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const Title = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.infoLight};
`;

export const List = styled.ul`
  margin-bottom: -16px;
`;

export const ListItem = styled.li`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const Subtitle = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 150%;
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 18px;
`;

export const Tip = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const TipText = styled.p`
  width: 272px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const Price = styled.button<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 12px;
  font-weight: 600;
  color: ${(props) =>
    props.isActive ? props.theme.colors.typography100 : props.theme.colors.typography800};
  background: ${(props) =>
    props.isActive ? props.theme.gradients.primary : props.theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px rgba(84, 60, 151, 0.25);
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
  border-radius: 12px;

  :disabled {
    background: ${(props) => props.theme.colors.typography200};
    opacity: 1;
  }
`;

export const Note = styled.p`
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;
