import styled from 'styled-components';
import AnswerButton from 'core/common/components/AnswerButton';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import Title from 'core/common/components/Title';
import { ThemeTypes } from 'core/theme/entities';

export const Background = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    99.42deg,
    rgba(255, 161, 0, 0.3) 36.23%,
    rgba(250, 124, 18, 0.2401) 54.33%,
    rgba(245, 87, 36, 0.27) 75.61%,
    rgba(242, 61, 48, 0.27) 95.48%,
    rgba(240, 45, 56, 0.27) 113.18%,
    rgba(239, 39, 59, 0.27) 126.89%
  );
`;

export const Content = styled.div`
  width: 100%;
  max-width: 708px;
  margin: 0 auto;
`;

export const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 466px;
  margin: 0 auto;
`;

export const StyledList = styled(List)`
  margin-top: 10px;
`;

export const Item = styled.li``;

export const EmojiBorder = styled.div<{ themeType: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 20px;
  padding: 0 16px;
  border: ${(props) =>
    (props.themeType === ThemeTypes.DEFAULT && `1px solid ${props.theme.colors.typography200}`) ||
    `2px solid ${props.theme.colors.info}`};
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) =>
    props.themeType === ThemeTypes.DEFAULT && props.theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px rgba(84, 60, 151, 0.25);
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const Emoji = styled(EmojiComponent)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 52px;
    height: 52px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const StyledAnswerButton = styled(AnswerButton)`
  margin-bottom: 20px;
`;
