import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';

export const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;

export const Title = styled.h2`
  margin-top: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  text-align: left;
  color: ${(props) => props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
    font-size: 24px;
  }
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const Subtitle = styled.h4`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 16px;
    font-size: 18px;
  }
`;

export const Skip = styled.button`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.typography500};
  background: transparent;
`;

export const Image = styled(ImageComponent)``;

export const ImageContainerDesktop = styled.div`
  display: none;
  margin-top: 28px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: block;
  }
`;

export const ImageContainerMobile = styled.div`
  display: block;
  margin-top: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 32px;
  margin-bottom: 15px;
  border-radius: 16px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
  }
`;

export const FooterText = styled.p`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;
