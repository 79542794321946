import styled from 'styled-components';
import UnstyledButton from 'core/common/components/ButtonDeprecated';
import NavLink from 'core/common/components/Link/Link';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.section`
  max-width: 420px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  align-self: center;
  width: 100%;
`;

export const NebulaAnalyticsText = styled.p`
  margin-left: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.typography600};
`;

export const NebulaAnalyticsCaption = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
`;

export const SubscriptionDisclaimer = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid #ededed;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: rgba(15, 15, 15, 0.6);
  background: #f7f7f7;
`;

export const Emphasize = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: rgba(15, 15, 15, 0.6);
`;

export const Link = styled(NavLink)`
  text-decoration: underline;
`;

export const Button = styled(UnstyledButton)`
  width: 100%;
  height: unset;
  padding: 0.8em;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const GuaranteeBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const GuaranteeTitle = styled.h4`
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;

export const PaymentMethodsListStyled = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export const PaymentMethodsListItemStyled = styled.li`
  &:not(:last-child) {
    margin-right: 4px;
  }
`;
