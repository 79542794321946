import Image from 'core/common/components/Image';
import { SectionQuoteAttributes } from 'core/funnel/entities';
import { TextStyled, WrapperStyled } from './styled';

type SectionQuoteProps = {
  content: SectionQuoteAttributes['content'];
};

const SectionQuote = ({ content }: SectionQuoteProps) => {
  return (
    <WrapperStyled>
      <Image src={content.quotesImage.src} alt={content.quotesImage.alt} width={16} height={16} />
      <TextStyled>{content.title}</TextStyled>
      <Image
        src={content.testifierImage.src}
        alt={content.testifierImage.alt}
        width={134}
        height={20}
      />
    </WrapperStyled>
  );
};

export default SectionQuote;
