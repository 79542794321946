import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialCreditsPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { AddressStyled, ContainerStyled, ReportDescriptionStyled } from './styled';

type SectionFooterProps = {
  content: TrialCreditsPalmistryV2ScreenAttributes['content']['footer'];
};

const SectionFooter = ({ content }: SectionFooterProps) => {
  const { description, addressUS, address } = content;

  return (
    <ContainerStyled>
      <ReportDescriptionStyled dangerouslySetInnerHTML={prepareHtml(description)} />
      <Location>
        <LocationOption option={Locales.US}>
          <AddressStyled>{addressUS}</AddressStyled>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <AddressStyled>{address}</AddressStyled>
        </LocationOption>
      </Location>
    </ContainerStyled>
  );
};

export default SectionFooter;
