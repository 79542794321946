import { Trans } from 'react-i18next';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { Bold, Break, Description } from './styled';

export type SectionPaymentContentUSLocationProps = {
  offer: Offer;
  trialPeriod: string;
};

const SectionPaymentContentUSLocation = ({
  offer,
  trialPeriod,
}: SectionPaymentContentUSLocationProps) => {
  const { domainUrl } = useConfig();

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  return (
    <Description>
      <Trans
        i18nKey={`${tKeyPrefix}.charge.trialUS`}
        values={{
          price: offer.pricesWithCurrencySymbol.trialPrice,
          trialPeriod,
          domainUrl: domainUrl,
        }}
        components={{
          bold: <Bold />,
          br: <Break />,
        }}
      />
    </Description>
  );
};

export default SectionPaymentContentUSLocation;
