import { useState } from 'react';
import TimeNatalChartScreen from 'core/common/components/QuizScreens/TimeNatalChart';
import { createDate } from 'core/common/utils/date';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { TimeNatalChartScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

export const getInitialTime = (initialTime: string) => {
  if (!initialTime) return { hours: '12', minutes: '00', part: 'AM' };

  const defaultDate = '1990-01-01';
  const time = createDate(`${defaultDate}${initialTime}`, 'YYYY-MM-DDTh:mm:ss A');
  const isPM = time.get('hours') > 12;

  if (time.get('hours') === 0) {
    return {
      hours: '12',
      minutes: String(time.get('minutes')),
      part: 'AM',
    };
  }

  return {
    hours: isPM ? String(time.get('hours') - 12) : String(time.get('hours')),
    minutes: String(time.get('minutes')),
    part: isPM ? 'PM' : 'AM',
  };
};

type TimeNatalChartProps = {
  id: string;
};

const TimeNatalChart = ({ id }: TimeNatalChartProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const initialTime = (userPreferences[id as keyof UserProfile] as string) || '';

  const [time, setTime] = useState(getInitialTime(initialTime));

  const { dataKey } = screenAttributes as TimeNatalChartScreenAttributes;

  const onContinue = async () => {
    let hours = time.part === 'PM' ? +time.hours + 12 : +time.hours;
    if (time.hours === '12' && time.part === 'AM') {
      hours = 0;
    }

    if (time.hours === '12' && time.part === 'PM') {
      hours = 12;
    }

    const min = +time.minutes;
    const sec = 0;

    const timeOfBirth = createDate().set('h', hours).set('m', min).set('s', sec).format(`HH:mm:ss`);

    updateUserPreferences(dataKey, timeOfBirth);
    await next();
  };

  const onSkip = async () => {
    updateUserPreferences(dataKey, null);
    await next();
  };

  return (
    <TimeNatalChartScreen
      onBack={back}
      onContinue={onContinue}
      onSkip={onSkip}
      setTime={setTime}
      time={time}
      step={step}
      {...(screenAttributes as TimeNatalChartScreenAttributes)}
    />
  );
};

export default TimeNatalChart;
