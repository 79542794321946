import { useEffect, useState } from 'react';
import Header from 'core/common/components/Header';
import { SECOND } from 'core/common/constants/';
import { floorPercent, floorPercentByThreePercentStep } from 'core/common/utils/floorPercents';
import { roundToNearestDecimal } from 'core/common/utils/math';
import { LoadingNatalChartScreenProps } from 'core/funnel/entities';
import {
  Circle,
  CircleContainer,
  IconStyled,
  List,
  ListItem,
  ProcessingLoaderCircle,
  Progress,
  Text,
  Wrapper,
} from './styled';

export enum LoadingSteps {
  STEP_1 = 20,
  STEP_2 = 40,
  STEP_3 = 60,
}

// TODO maybe better to use MUI CircularProgress
const timeout = SECOND * 0.015;
const threeDecimalPercents = 0.3;
const oneDecimalPercent = 0.1;

const LoadingNatalChartScreen = ({
  onLoadingFinished,
  content,
  hasButtonBack,
}: LoadingNatalChartScreenProps) => {
  const [progress, setProgress] = useState(0);
  const [showedList, setShowedList] = useState<number[]>([progress]);

  const { list } = content;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const setStepValue = (step: number) =>
      setInterval(() => {
        setProgress((prev) => {
          const next = roundToNearestDecimal(prev + step);
          return next <= 100 ? next : 100;
        });
      }, timeout);

    if (LoadingSteps[progress]) {
      setShowedList((prev) => [...prev, progress]);
    }

    if (progress < 100) {
      timer = progress >= 67 ? setStepValue(threeDecimalPercents) : setStepValue(oneDecimalPercent);
    } else {
      onLoadingFinished();
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const displayValue =
    progress <= 67 ? floorPercent(progress) : floorPercentByThreePercentStep(progress);

  return (
    <>
      <Header buttonBack={hasButtonBack} />
      <Wrapper>
        <CircleContainer>
          <ProcessingLoaderCircle value={progress} />
          <Progress>{displayValue}%</Progress>
        </CircleContainer>
        <List>
          {list.map((item, index) => {
            const isLoaded = Number.isFinite(showedList[index]);

            return (
              <ListItem key={item} isLoaded={isLoaded}>
                <Circle isLoaded={isLoaded}>
                  <IconStyled name="check" isLoaded={isLoaded} />
                </Circle>
                <Text>{item}</Text>
              </ListItem>
            );
          })}
        </List>
      </Wrapper>
    </>
  );
};

export default LoadingNatalChartScreen;
