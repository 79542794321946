import styled from 'styled-components';
import Rating from 'core/common/components/Rating';
import ReviewsReal from 'core/common/components/ReviewsReal';
import { Item, Text } from 'core/common/components/ReviewsReal/styled';
import TitleComponent from 'core/common/components/Title';

export const Wrapper = styled.div`
  margin-bottom: 40px;
`;

export const StyledReviewsReal = styled(ReviewsReal)`
  margin: 10px auto -20px;

  ${Item} {
    border: none;
    background: ${(props) => props.theme.colors.themeBackgroundLight};
  }

  ${Text} {
    font-size: 14px;
    font-style: normal;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled(TitleComponent)`
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 135%;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary200};
`;

export const StyledRating = styled(Rating)`
  margin-top: 5px;
`;
