import { BASE_ROUTES } from 'core/common/constants';
import { TrialPaymentIntroOfferScreenAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import SubscriptionRadioGroup from '../SubscriptionRadioGroup';
import {
  Button,
  ContentWrapper,
  Emphasize,
  GuaranteeBlock,
  GuaranteeIconStyled,
  GuaranteeTitle,
  Link,
  MainWrapper,
  SubscriptionDisclaimer,
} from './styled';

type SubscriptionBlockProps = {
  isTimeUp: boolean;
  onGetReport: VoidFunction;
  subscription: TrialPaymentIntroOfferScreenAttributes['content']['subscriptionBlock'];
  guaranteeText: string;
  onPlanChanged: (plan: Offer) => void;
  onAllPlansLoaded: VoidFunction;
  isAllPlansLoaded: boolean;
  chosenPlan: Offer;
  selectedPlan: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offerNodeId?: string;
};

const trialPeriodMap: Record<string, string> = {
  '7 days': '1 week',
  '28 days': '4 weeks',
  '84 days': '12 weeks',
};

const SubscriptionBlock = ({
  isTimeUp,
  onGetReport,
  subscription,
  guaranteeText,
  onPlanChanged,
  onAllPlansLoaded,
  isAllPlansLoaded,
  chosenPlan,
  selectedPlan,
  defaultOffers,
  timeUpOffers,
  offerNodeId,
}: SubscriptionBlockProps) => {
  const trialPeriod = trialPeriodMap[selectedPlan?.trialPeriod ?? '28 days'];

  return (
    <MainWrapper id={offerNodeId}>
      <SubscriptionRadioGroup
        isTimeUp={isTimeUp}
        choosedPlan={chosenPlan}
        onPlanChanged={onPlanChanged}
        onAllPlansLoaded={onAllPlansLoaded}
        defaultOffers={defaultOffers}
        timeUpOffers={timeUpOffers}
      />
      <ContentWrapper>
        <SubscriptionDisclaimer>
          By clicking GET MY PLAN you agree that if you don&apos;t cancel prior to the end of the{' '}
          <Emphasize>{trialPeriod}</Emphasize> trial to <Emphasize>NEBULA</Emphasize> service for{' '}
          <Emphasize>{selectedPlan?.pricesWithCurrency.trialPriceWithVAT}</Emphasize>, you will
          automatically be charged{' '}
          <Emphasize>{selectedPlan?.pricesWithCurrency.priceWithVAT}</Emphasize> every{' '}
          {selectedPlan?.period} until you cancel in settings. Learn more about cancellation and
          refund policy in{' '}
          <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{subscription.terms}</Link>.
        </SubscriptionDisclaimer>
        <Button fullWidth onClick={onGetReport} disabled={!isAllPlansLoaded} impulseScale>
          {subscription.getAccess}
        </Button>
        <GuaranteeBlock>
          <GuaranteeIconStyled name="guarantee" width={24} height={24} />
          <GuaranteeTitle>{guaranteeText}</GuaranteeTitle>
        </GuaranteeBlock>
      </ContentWrapper>
    </MainWrapper>
  );
};

export default SubscriptionBlock;
