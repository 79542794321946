import { LoadingStatuses } from 'core/common/entities';
import { ScanScreenProps } from 'core/funnel/entities';
import ScanError from '../ScanError';
import ScanLoading from '../ScanLoading';
import ScanMaker from '../ScanMaker';
import useScanProcessPopUps, { ScanProcessPopUp } from './useScanProcessPopUps';
import { Container, StyledModal } from './styled';

type ScanProcessPopUpsProps = {
  isOpen: boolean;
  content: ScanScreenProps['content'];
  onClosePopUp: () => void;
  goNext: () => void;
};

const ScanProcessPopUps = ({ isOpen, goNext, content, onClosePopUp }: ScanProcessPopUpsProps) => {
  const {
    detectPalm,
    loadingStatus,
    scanProcessStep,
    setScanProcessStep,
    tryAgain,
    closePopupQuestion,
  } = useScanProcessPopUps();

  const { loading, scanMaker, error } = content;

  const finishLoading = () => {
    if (loadingStatus === LoadingStatuses.FULFILLED) {
      return goNext();
    }
    setScanProcessStep(ScanProcessPopUp.SCAN_ERROR);
  };

  const onClose = () => {
    onClosePopUp();
    setScanProcessStep(ScanProcessPopUp.SCAN_MAKER);
  };

  const onClosePopupQuestion = (data: { popupName: string; answer: string }) => {
    closePopupQuestion(data);
  };

  const getPopUp = () => {
    switch (scanProcessStep) {
      case ScanProcessPopUp.SCAN_MAKER:
        return (
          <ScanMaker
            updatePopUp={setScanProcessStep}
            button={scanMaker.button}
            detectPalm={detectPalm}
            onClose={onClose}
          />
        );
      case ScanProcessPopUp.SCAN_LOADING:
        return (
          <ScanLoading
            onFinished={finishLoading}
            title={loading.title}
            list={loading.list}
            question={loading.question}
            onClose={onClose}
            onClosePopupQuestion={onClosePopupQuestion}
          />
        );
      case ScanProcessPopUp.SCAN_ERROR:
        return (
          <ScanError
            title={error.title}
            subtitle={error.subtitle}
            button={error.button}
            tryAgain={tryAgain}
            onClose={onClose}
          />
        );
      default:
        return (
          <ScanMaker
            updatePopUp={setScanProcessStep}
            button={scanMaker.button}
            detectPalm={detectPalm}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <StyledModal open={isOpen} onClose={onClosePopUp}>
      <Container>{getPopUp()}</Container>
    </StyledModal>
  );
};

export default ScanProcessPopUps;
