import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import InputComponent from 'core/common/components/Input';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';

const mainContentZIndex = 1;
const blurredOverlayZIndex = 2;

export const ContainerStyled = styled.div`
  z-index: ${mainContentZIndex};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const TitleStyled = styled.h1`
  margin-top: 36px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-grow: 0;
  }
`;

export const TextStyled = styled.p`
  max-width: 85%;
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const PolicyStyled = styled(PolicyComponent)`
  color: ${({ theme }) => theme.colors.typography600};

  ${NavLink} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.infoLight};
  }
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 #00000040;
`;

export const InputStyled = styled(InputComponent)`
  border: 1px solid ${({ theme }) => theme.colors.typography300};
`;

export const InputWrapperStyled = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const FooterStyled = styled.footer`
  margin-top: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 67px;
  }
`;

export const MainImageInnerWrapperStyled = styled.div<{ blurred?: boolean }>`
  position: relative;
  padding-top: 56%;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 24px;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${blurredOverlayZIndex};
    display: ${({ blurred = true }) => (blurred ? 'block' : 'none')};
    content: '';
    backdrop-filter: blur(2px);
  }
`;

export const MainImageStyled = styled(Image)`
  object-fit: cover;
`;

export const EmailCaptionStyled = styled.p`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 8px;
  font-size: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const EmailCaptionIconStyled = styled(Image)`
  margin-right: 2px;
`;

export const ArrowImageStyled = styled(Image)`
  position: absolute;
  right: -16px;
  bottom: 20px;
  z-index: ${blurredOverlayZIndex + 1};
  transform: translateY(90%);
`;

export const MainImageOuterWrapperStyled = styled.div`
  position: relative;
`;

export const ReportTypeIconWrapperStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: ${blurredOverlayZIndex + 1};
  display: flex;
  padding: 6px 14px;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-top-left-radius: 24px;
  background-color: ${({ theme }) => theme.colors.typography100};
`;

export const ReportTypeIconStyled = styled(Image)``;
