import nebulaDark from 'core/common/assets/images/nebulaDark.png';
import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { ProcessingLoaderScreenProps } from 'core/funnel/entities';
import Question from './Question';
import {
  Content,
  Dot,
  IconStyled,
  Logo,
  PersonalityBlock,
  PersonalityDone,
  ProfileBlock,
  ProfileText,
  ProgressPersonality,
  ProgressProfile,
  ProgressRelationship,
  ProgressWrapper,
  RelationshipBlock,
  RelationshipDone,
  Text,
  Title,
  WrapperDots,
} from './styled';

const ProcessingLoaderScreen = ({
  title,
  onClose,
  personalityValue,
  relationshipValue,
  profileValue,
  shouldShowModal,
  question,
  content,
}: ProcessingLoaderScreenProps) => {
  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <Content>
        <Logo height={86} width={86} src={nebulaDark} alt="Nebula" />
        <Title>{title}</Title>
        <ProgressWrapper>
          <ProfileBlock>
            <ProfileText>{content.profileText}</ProfileText>
            <ProfileText>{profileValue}%</ProfileText>
          </ProfileBlock>
          <ProgressProfile variant="determinate" value={profileValue} />
        </ProgressWrapper>
        <ProgressWrapper>
          <PersonalityBlock>
            <ProfileText>{content.personalityText}</ProfileText>
            <ProfileText>{personalityValue}%</ProfileText>
          </PersonalityBlock>
          <ProgressPersonality variant="determinate" value={personalityValue} />
        </ProgressWrapper>
        <ProgressWrapper>
          <RelationshipBlock>
            <ProfileText>{content.relationshipText}</ProfileText>
            <ProfileText>{relationshipValue}%</ProfileText>
          </RelationshipBlock>
          <ProgressRelationship variant="determinate" value={relationshipValue} />
        </ProgressWrapper>
        <WrapperDots>
          {profileValue === 100 ? <IconStyled name="check" /> : <Dot />}
          {personalityValue === 100 ? <PersonalityDone name="check" /> : <Dot />}
          {relationshipValue === 100 ? <RelationshipDone name="check" /> : <Dot />}
        </WrapperDots>
        <Text>{content.text}</Text>
      </Content>
      {shouldShowModal && (
        <Question
          onClose={onClose}
          isModalOpen={shouldShowModal}
          title={question}
          accept={content.yes}
          decline={content.no}
        />
      )}
    </>
  );
};

export default ProcessingLoaderScreen;
