import { useState } from 'react';
import MobileScrollPicker, { PickerVariants } from 'core/common/components/MobileScrollPicker';
import { hoursList, minutesList } from 'core/common/constants';
import { TimePart } from 'core/common/entities';
import { useDisabledScroll } from 'core/common/hooks';
import { extractTimeParts, normalizeTime } from 'core/common/utils/time';
import { PickerWrapperStyled, ButtonStyled, BlockWrapperStyled } from './styled';

type ScrollPickerProps = {
  nextLabel: string;
  userTime?: string;
  onSubmit: (date: string) => void;
  variant: PickerVariants;
};

const ScrollPicker = ({ userTime, nextLabel, onSubmit, variant }: ScrollPickerProps) => {
  // native behavior IOS "overscroll" on the mobile make worse UX for usage scroll picker
  // this hack disable "overscroll"
  useDisabledScroll();

  const [time, setTime] = useState(extractTimeParts(userTime));

  const selections = {
    hours: hoursList,
    minutes: minutesList,
    part: [TimePart.AM, TimePart.PM] as Array<string>,
  } as const;

  const onClick = () => {
    onSubmit(normalizeTime(time, 'HH:mm:ss'));
  };

  const onChange = (value: Record<string, string | number>) => {
    setTime((prev) => ({ ...prev, ...value }));
  };

  return (
    <BlockWrapperStyled>
      <PickerWrapperStyled>
        <MobileScrollPicker
          variant={variant}
          value={time}
          onChange={onChange}
          selections={selections}
        />
        <ButtonStyled onClick={onClick}>{nextLabel}</ButtonStyled>
      </PickerWrapperStyled>
    </BlockWrapperStyled>
  );
};

export default ScrollPicker;
