import styled from 'styled-components';

import DeprecatedEmoji from 'core/common/components/Emoji/DeprecatedEmoji';

export const StyledEmoji = styled(DeprecatedEmoji)`
  position: absolute;
  z-index: -1000;
  opacity: 0;
  visibility: hidden;
`;
