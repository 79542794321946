import { ChangeEvent } from 'react';
import SectionDisclaimer from '../SectionDisclaimer';
import { CheckboxStyled } from './styled';

export type SectionDisclaimerWithCheckboxProps = {
  displayTrialPrice: string;
  displayPlanPrice: string;
  isCheckboxChecked: boolean;
  isConsentErrorOccured: boolean;
  onCheckboxClick: (checked: boolean) => void;
};

const SectionDisclaimerWithCheckbox = ({
  displayTrialPrice,
  displayPlanPrice,
  isCheckboxChecked,
  isConsentErrorOccured,
  onCheckboxClick,
}: SectionDisclaimerWithCheckboxProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onCheckboxClick(event.target.checked);
  };

  return (
    <CheckboxStyled
      name="consent"
      checked={isCheckboxChecked}
      onChange={onChange}
      isConsentErrorOccured={isConsentErrorOccured}
    >
      <SectionDisclaimer
        displayTrialPrice={displayTrialPrice}
        displayPlanPrice={displayPlanPrice}
      />
    </CheckboxStyled>
  );
};
export default SectionDisclaimerWithCheckbox;
