import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  overflow: hidden;
`;

export const Video = styled.video`
  width: 100%;
  object-fit: fill;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 100%;
  }
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  height: 50px;
  margin-top: 24px;
  border-radius: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 210px;
  }
`;

export const Hand = styled(Image)`
  position: absolute;
  width: 65%;
  height: 75%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 45%;
    height: 88%;
  }
`;
