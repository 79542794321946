import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ImageInformationalScreenProps } from 'core/funnel/entities';
import { Container, Button, StyledTitle, Text, Image, HorizontalImage } from './styled';

const ImageInformationalScreen = ({
  onBack,
  goNext,
  content,
  hasButtonBack,
}: ImageInformationalScreenProps) => {
  const { title, text, image, isHorizontalImage, next: buttonNext } = content;

  const renderImage = () => {
    if (isHorizontalImage) {
      return (
        <HorizontalImage
          src={image.src}
          alt={image.alt || 'informational image'}
          width={330}
          height={150}
        />
      );
    }

    return (
      <Image src={image.src} alt={image.alt || 'informational image'} width={150} height={150} />
    );
  };

  return (
    <>
      <Header onBack={onBack} variant="primary" buttonBack={hasButtonBack} />
      <Container>
        {renderImage()}
        <StyledTitle>{title}</StyledTitle>
        {!!text && <Text dangerouslySetInnerHTML={prepareHtml(text)} />}
        <Button onClick={goNext} variant="secondary">
          {buttonNext}
        </Button>
      </Container>
    </>
  );
};

export default ImageInformationalScreen;
