import { useState } from 'react';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import { scrollToBottom } from 'core/common/utils/scroll';
import { DynamicAnswerScreenProps } from 'core/funnel/entities';
import {
  ContainerMainStyled,
  ItemStyled,
  StyledButton,
  ListStyled,
  TitleStyled,
  RadioButtonStyled,
  AnswerDescriptionContainerStyled,
  EmojiStyled,
  AnswerDescriptionTitleStyled,
  AnswerDescriptionTextStyled,
  AnswerDescriptionEmojiStyled,
  ButtonWrapperStyled,
  RadioButtonTitleStyled,
} from './styled';

const overlapedAnswers = 2;

const DynamicAnswerScreen = ({
  hasButtonBack = true,
  onContinue,
  step,
  content,
  onBack,
  progressBar,
}: DynamicAnswerScreenProps) => {
  const [answer, setAnswer] = useState<string>('');

  const onAnswerClick = (value: string) => {
    setAnswer(value);
  };

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      {progressBar && <ProgressBar step={step} config={progressBar} onBack={onBack} />}
      <ContainerMainStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <ListStyled>
          {content.answers.map(
            ({ text, value, emoji, description, descriptionTitle, descriptionTitleEmoji }, i) => (
              <ItemStyled key={value}>
                <RadioButtonStyled
                  value={value}
                  id={value}
                  name="answers"
                  checked={answer === value}
                  onChange={(event) => {
                    if (i >= overlapedAnswers) scrollToBottom({ duration: 1500 });
                    onAnswerClick(event.target.value);
                  }}
                >
                  <EmojiStyled>{emoji}</EmojiStyled>
                  <RadioButtonTitleStyled>{text}</RadioButtonTitleStyled>
                </RadioButtonStyled>
                <AnswerDescriptionContainerStyled>
                  <AnswerDescriptionEmojiStyled>
                    {descriptionTitleEmoji}
                  </AnswerDescriptionEmojiStyled>
                  <AnswerDescriptionTitleStyled>{descriptionTitle}</AnswerDescriptionTitleStyled>
                  <AnswerDescriptionTextStyled>{description}</AnswerDescriptionTextStyled>
                </AnswerDescriptionContainerStyled>
              </ItemStyled>
            ),
          )}
        </ListStyled>
        <ButtonWrapperStyled isButtonVisible={!!answer}>
          <StyledButton onClick={() => onContinue(answer)}>{content.next}</StyledButton>
        </ButtonWrapperStyled>
      </ContainerMainStyled>
    </>
  );
};

export default DynamicAnswerScreen;
