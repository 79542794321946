import PrelandingPalmistryScreen from 'core/common/components/QuizScreens/PrelandingPalmistry';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { PrelandingScreenPalmistryAttributes } from 'core/funnel/entities';

type PrelandingPalmistryProps = {
  id: string;
};

const PrelandingPalmistry = ({ id }: PrelandingPalmistryProps) => {
  const { back, next, screenAttributes } = useQuizStep(id);

  const onStart = async () => {
    await next();
  };

  const onBack = () => back();

  return (
    <PrelandingPalmistryScreen
      onBack={onBack}
      onStart={onStart}
      {...(screenAttributes as PrelandingScreenPalmistryAttributes)}
    />
  );
};

export default PrelandingPalmistry;
