import { forwardRef } from 'react';
import { TrialPaymentOneTimeOfferHumanDesignScreenProps } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import {
  ActionButtonStyled,
  ActualPriceStyled,
  BodyHeaderStyled,
  BodyHeaderTitleStyled,
  ButtonWrapperStyled,
  CardBodyStyled,
  CardHeaderStyled,
  CardWrapperStyled,
  GuaranteeBlockStyled,
  GuaranteeIconStyled,
  GuaranteeTitleStyled,
  HeaderTitleStyled,
  HorizontalLineStyled,
  OldPriceStyled,
  OneTimeOfferBlockStyled,
  OneTimeOfferDescriptionStyled,
  PriceWrapperStyled,
  TitleStyled,
} from './styled';

export type OneTimeOfferProps = {
  id?: string;
  timeLeft: number;
  onGetReport: () => void;
  offer: Offer;
  timeUpOffer: Offer;
  oneTimeOffer: TrialPaymentOneTimeOfferHumanDesignScreenProps['content']['oneTimeOffer'];
};

const OneTimeOffer = forwardRef<HTMLDivElement, OneTimeOfferProps>(
  ({ id, timeLeft, onGetReport, offer, timeUpOffer, oneTimeOffer }: OneTimeOfferProps, ref) => {
    const { mainTitle, header, title, button, guaranteeText, oneTimeOfferDescription } =
      oneTimeOffer;

    const isTimeUp = timeLeft <= 0;

    return (
      <>
        <TitleStyled>{mainTitle}</TitleStyled>
        <CardWrapperStyled id={id}>
          <CardHeaderStyled>
            <HeaderTitleStyled>{header}</HeaderTitleStyled>
          </CardHeaderStyled>
          <CardBodyStyled>
            <BodyHeaderStyled>
              <BodyHeaderTitleStyled>{title}</BodyHeaderTitleStyled>
              <PriceWrapperStyled>
                {!isTimeUp && (
                  <OldPriceStyled>{offer.pricesWithCurrencySymbol.priceWithVAT}</OldPriceStyled>
                )}
                <ActualPriceStyled>
                  {isTimeUp
                    ? timeUpOffer.pricesWithCurrencySymbol.trialPriceWithVAT
                    : offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                </ActualPriceStyled>
              </PriceWrapperStyled>
            </BodyHeaderStyled>
            <HorizontalLineStyled />
            <OneTimeOfferBlockStyled>
              <OneTimeOfferDescriptionStyled>
                {oneTimeOfferDescription}
              </OneTimeOfferDescriptionStyled>
            </OneTimeOfferBlockStyled>
          </CardBodyStyled>
        </CardWrapperStyled>
        <ButtonWrapperStyled ref={ref}>
          <ActionButtonStyled impulseScale onClick={onGetReport}>
            {button}
          </ActionButtonStyled>
        </ButtonWrapperStyled>
        <GuaranteeBlockStyled>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeTitleStyled>{guaranteeText}</GuaranteeTitleStyled>
        </GuaranteeBlockStyled>
      </>
    );
  },
);

export default OneTimeOffer;
