import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { GoalScreenProps } from 'core/funnel/entities';
import {
  ButtonsWrapper,
  Container,
  DesktopImageWrapper,
  MobileImageWrapper,
  NextButton,
  PrevButton,
  StyledAnimatedImage,
  Text,
  Title,
} from './styled';

const GoalScreen = ({ onBack, content, goNext }: GoalScreenProps) => {
  const { backgroundImage } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <BackgroundGradient />
      {backgroundImage && (
        <>
          <MobileImageWrapper>
            <Image
              width={163}
              height={489}
              src={backgroundImage.mobileImage.src}
              alt={backgroundImage.mobileImage.alt || 'background'}
            />
          </MobileImageWrapper>
          <DesktopImageWrapper>
            <Image
              width={642}
              height={856}
              src={backgroundImage.desktopImage.src}
              alt={backgroundImage.desktopImage.alt || 'background'}
            />
          </DesktopImageWrapper>
        </>
      )}
      <Container>
        {!!content.animatedImage && <StyledAnimatedImage variant={content.animatedImage} />}
        <Title variant="secondary">{content.title}</Title>
        <Text>{content.text}</Text>
        <ButtonsWrapper>
          <PrevButton onClick={onBack}>{content.back}</PrevButton>
          <NextButton onClick={goNext} variant="secondary">
            {content.next}
          </NextButton>
        </ButtonsWrapper>
      </Container>
    </>
  );
};

export default GoalScreen;
