import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: center;
  }
`;

export const List = styled.div`
  width: 100%;
  max-height: 265px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: scroll;
  background: ${(props) => props.theme.colors.typography100};

  /* TODO: Create mixin */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 380px;
    max-height: 285px;
    margin: 10px auto;
  }
`;

export const Item = styled.div`
  padding: 10px 15px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.primary400}`};
  text-align: left;
  color: ${(props) => props.theme.colors.textPrimary};
  cursor: pointer;

  &:hover {
    /* TODO added to colors theme #E2E4E7 */
    background: #e2e4e7;
  }
`;
