import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.infoLight};
`;

export const ListStyled = styled.ul`
  margin-bottom: 40px;
`;

export const ListItemStyled = styled.li`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const SubtitleStyled = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
`;

export const TextStyled = styled.p`
  font-size: 12px;
  line-height: 150%;
`;

export const PriceBlockStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const TipStyled = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const TipTextStyled = styled.p`
  width: 272px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const PriceStyled = styled.button<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid
    ${(props) => (props.isActive ? props.theme.colors.infoLight : props.theme.colors.typography200)};
  border-radius: 12px;
  font-weight: 600;
  color: ${(props) =>
    props.isActive ? props.theme.colors.typography100 : props.theme.colors.typography800};
  background: ${(props) =>
    props.isActive ? props.theme.colors.infoLight : props.theme.colors.themeBackgroundLight};
  box-shadow: ${(props) =>
    props.isActive ? '0 4px 4px rgba(0, 0, 0, 0.25)' : '2px 2px 6px rgba(84, 60, 151, 0.25)'};
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.infoLight};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  :disabled {
    background: ${(props) => props.theme.colors.typography200};
    box-shadow: none;
    opacity: 1;
  }
`;

export const NoteStyled = styled.p`
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;
