import { useEffect } from 'react';
import AdditionalDiscountPalmistryScreen from 'core/common/components/QuizScreens/AdditionalDiscountPalmistry';
import { useSelector, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AdditionalDiscountPalmistryScreenAttributes } from 'core/funnel/entities';
import { europeanCountries, firstTierCountries, Locales } from 'core/user/entities';
import { useUserCurrency, useUserLocation } from 'core/user/hooks';
import { getPlan } from 'core/user/store';

type AdditionalDiscountPalmistryProps = {
  id: string;
};

const AdditionalDiscountPalmistry = ({ id }: AdditionalDiscountPalmistryProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { locale } = useUserLocation();
  const plan = useSelector(getPlan);
  const { currencySymbol } = useUserCurrency();

  const getPriceAndDiscount = () => {
    if (locale === Locales.US) {
      return { price: 19, oldPrice: plan.oldPrice ?? 65, discountPercent: 70 };
    }

    if (europeanCountries.includes(locale)) {
      return { price: 19, oldPrice: plan.oldPrice ?? 55, discountPercent: 65 };
    }

    if (firstTierCountries.includes(locale)) {
      return { price: 19, oldPrice: plan.oldPrice ?? 55, discountPercent: 65 };
    }

    return { price: 9, oldPrice: plan.oldPrice ?? 39, discountPercent: 77 };
  };
  const onGetReport = () => {
    analyticsService.discountLpGetReport();
    next();
  };

  useEffect(() => {
    analyticsService.discountLpOpened();
  }, [analyticsService]);

  return (
    <AdditionalDiscountPalmistryScreen
      currencySymbol={currencySymbol}
      onGetReport={onGetReport}
      priceData={getPriceAndDiscount()}
      {...(screenAttributes as AdditionalDiscountPalmistryScreenAttributes)}
    />
  );
};

export default AdditionalDiscountPalmistry;
