import { useEffect } from 'react';
import AdditionalDiscountPalmistryScreen from 'core/common/components/QuizScreens/AdditionalDiscountPalmistryV2';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AdditionalDiscountPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';

type AdditionalDiscountPalmistryV2Props = {
  id: string;
};

const AdditionalDiscountPalmistryV2 = ({ id }: AdditionalDiscountPalmistryV2Props) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountOffer, timeUpOffer } = useOffers();

  const onGetReport = () => {
    analyticsService.discountLpGetReport();
    next();
  };

  useEffect(() => {
    analyticsService.discountLpOpened();
  }, [analyticsService]);

  return (
    <AdditionalDiscountPalmistryScreen
      discountOffer={discountOffer}
      timeUpOffer={timeUpOffer}
      onGetReport={onGetReport}
      {...(screenAttributes as AdditionalDiscountPalmistryV2ScreenAttributes)}
    />
  );
};

export default AdditionalDiscountPalmistryV2;
