import ReviewsList from 'core/common/components/Reviews';
import { TitleStyled } from './styled';

type ReviewsProps = {
  title: string;
  list: Array<{
    id: number;
    date: string;
    text: string;
    nickname: string;
    initials: string;
    percent?: number | undefined;
  }>;
};

const Reviews = ({ title, list }: ReviewsProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <ReviewsList list={list} />
    </>
  );
};

export default Reviews;
