import styled from 'styled-components';
import Image from 'core/common/components/Image';

const cardVariants = [
  { bg: '#B89EF8', color: '#000' },
  { bg: '#EDE7F3', color: '#000' },
  { bg: '#63359C', color: '#fff' },
];

export const CardStyled = styled.div<{ idx: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 287px;
  height: 100%;
  padding: 24px;
  border-radius: 24px;
  color: ${({ idx }) => cardVariants[idx].color};
  background: ${({ idx }) => cardVariants[idx].bg};
`;

export const CardHeaderStyled = styled.header`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

export const CardTitleStyled = styled.h4`
  font-size: 18px;
`;

export const CardDescriptionStyled = styled.p`
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const CardImageStyled = styled(Image)`
  min-width: 77px;
  margin-right: 16px;
  object-fit: contain;
`;

export const CardOptionsStyled = styled.ul`
  flex-grow: 1;
`;
