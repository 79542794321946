import styled from 'styled-components';
import Address from 'core/common/components/Address';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Carousel, { carouselClasses } from 'core/common/components/Carousel';
import Image from 'core/common/components/Image';

export const ContentStyled = styled.div`
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const CarouselStyled = styled(Carousel)`
  ${carouselClasses.dots} li {
    width: 5px;
  }

  ${carouselClasses.dots} button::before, ${carouselClasses.dots} button {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const TitleStyled = styled.h1`
  margin-top: 40px;
  margin-bottom: 8px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 50px;
    font-size: 32px;
  }
`;

export const DescriptionStyled = styled.p`
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const ListStyled = styled.ul`
  margin-top: 20px;
  margin-bottom: 32px;
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ListItemImageStyled = styled(Image)`
  margin-top: 2px;
  margin-right: 8px;
`;

export const ActionButtonStyled = styled(Button)`
  width: 100%;
  height: 56px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const SkipButtonStyled = styled(TextButton)`
  width: 100%;
  margin-top: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const DisclaimerStyled = styled.p`
  margin-top: 21px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const AddressStyled = styled(Address)`
  margin-top: 20px;
  text-align: center;
`;
