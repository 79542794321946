import { useEffect } from 'react';
import QuestionsPalmistryScreen from 'core/common/components/QuizScreens/QuestionsPalmistry';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { QuestionsPalmistryScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type QuestionsPalmistryProps = {
  id: string;
};

const QuestionsPalmistry = ({ id }: QuestionsPalmistryProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onQuestionsPageOpened();
  }, [analyticsService]);

  return <QuestionsPalmistryScreen {...(screenAttributes as QuestionsPalmistryScreenAttributes)} />;
};

export default QuestionsPalmistry;
