import activeMark from 'core/common/assets/images/upsells/gradient_active_mark.svg';
import checkMark from 'core/common/assets/images/upsells/gradient_checkmark.svg';
import lightActiveMark from 'core/common/assets/images/upsells/light_active_mark.svg';
import lightCheckMark from 'core/common/assets/images/upsells/light_checkmark.svg';
import { useRouter } from 'core/common/hooks';
import Image from '../Image';
import { StepperVariant } from './types';
import {
  LineStyled,
  ProgressLineStyled,
  StepLabelStyled,
  StepStyled,
  StepsWrapperStyled,
} from './styled';

type Step = Array<string> | string;

type UpsellsStepperProps = {
  steps: Array<{ step: Step; title: string }>;
  variant?: StepperVariant;
  className?: string;
};

const UpsellsStepper = ({ steps, variant = 'default' }: UpsellsStepperProps) => {
  const { query } = useRouter();

  const getCurrentPageId = () => {
    if (query.id) {
      return query.id as string;
    }

    if (query.options) {
      return query.options[query.options.length - 1] as string;
    }

    return '';
  };

  const matchStepWithPageId = (step: Step, pageId: string) => {
    return Array.isArray(step) ? step.includes(pageId) : step === pageId;
  };

  const pageId = getCurrentPageId();

  const currentScreenIdx = steps.findIndex(({ step }) => matchStepWithPageId(step, pageId));
  const currentScreenProgress = (currentScreenIdx / (steps.length - 1)) * 100;

  const checkMarkSrc = variant === 'light' ? lightCheckMark : checkMark;
  const activeMarkSrc = variant === 'light' ? lightActiveMark : activeMark;

  return (
    <StepsWrapperStyled variant={variant}>
      {steps.map(({ title }, idx) => {
        const isActive = idx === currentScreenIdx;
        const isCompleted = idx < currentScreenIdx;

        return (
          <StepStyled key={title} active={isActive} completed={isCompleted}>
            <StepLabelStyled>{title}</StepLabelStyled>

            {isCompleted && <Image src={checkMarkSrc} alt="mark" width={30} height={30} />}
            {isActive && <Image src={activeMarkSrc} alt="mark" width={30} height={30} />}
          </StepStyled>
        );
      })}

      <LineStyled />
      <ProgressLineStyled progress={currentScreenProgress} />
    </StepsWrapperStyled>
  );
};

export default UpsellsStepper;
