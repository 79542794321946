import { useEffect } from 'react';
import { useGetAuthorizationToken } from 'core/auth/hooks';
import LoadingAskPalmistryScreen from 'core/common/components/QuizScreens/LoadingAskPalmistry';
import { useRedirectToAskNebula, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { LoadingAskPalmistryScreenAttributes } from 'core/funnel/entities';

type LoadingAskPalmistryProps = {
  id: string;
};

const LoadingAskPalmistry = ({ id }: LoadingAskPalmistryProps) => {
  const { screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { token } = useGetAuthorizationToken();
  const { redirectToQuizCatalogue } = useRedirectToAskNebula();

  const onLoadingFinished = () => {
    redirectToQuizCatalogue(token);
  };

  useEffect(() => {
    analyticsService.onLoadingPageOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingAskPalmistryScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as LoadingAskPalmistryScreenAttributes)}
    />
  );
};

export default LoadingAskPalmistry;
