import Reviews from 'core/common/components/Reviews';
import { FunnelReview } from 'core/funnel/entities';
import { ReviewsBlock, Title } from './styled';

type SectionReviewsProps = {
  title: string;
  reviews: Array<FunnelReview>;
};

const SectionReviews = ({ title, reviews }: SectionReviewsProps) => {
  return (
    <ReviewsBlock>
      <Title>{title}</Title>
      <Reviews list={reviews} />
    </ReviewsBlock>
  );
};

export default SectionReviews;
