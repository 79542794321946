import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const BannerStyled = styled.section`
  position: relative;
  padding: 16px 20px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #d5c5fa;
`;

export const DiscountStyled = styled.h3`
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
`;

export const OldDiscountStyled = styled.span`
  margin-right: 4px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const CaptionStyled = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

export const OverlayImageStyled = styled(Image)`
  object-fit: cover;
`;
