import styled from 'styled-components';

import Emoji from 'core/common/components/Emoji/DeprecatedEmoji';
import Image from 'core/common/components/Image';

export const HalfRowImage = styled(Image)`
  width: calc(100% / 2 - 8px);
  height: 189px;
  object-fit: contain;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: calc(100% / 2 - 20px);
    height: 252px;
  }
`;

export const SlideDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 0;
  }
`;

export const TextWithEmoji = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const TextEmoji = styled(Emoji)`
  position: relative;
  top: -4px;
  flex-shrink: 0;
  margin-right: 6px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: 0;
  }
`;

export const DescriptionFooter = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: auto;
`;

export const FooterImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

export const FooterImage = styled(Image)`
  flex-shrink: 0;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

export const Price = styled.p`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typography100};
  background: ${({ theme }) => theme.colors.secondary500};
`;

export const Sup = styled.sup`
  position: relative;
  top: -10px;
  font-size: 14px;
`;
