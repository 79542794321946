import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import QuizBackground from 'core/common/components/QuizBackground';
import { PickerVariants } from 'core/common/components/WheelDatePicker';
import { useIsApple } from 'core/common/hooks';
import { DatePickerV2ScreenProps } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import ScrollPicker from './ScrollPicker';
import { SelectDatePicker } from './SelectDatePicker';
import { ContainerMainStyled, SubtitleStyled, TitleStyled } from './styled';

const DatePickerV2Screen = ({
  hasButtonBack = true,
  hasZodiacWheel = true,
  onSubmit,
  userDate,
  step,
  content,
  onBack,
  progressBar,
}: DatePickerV2ScreenProps) => {
  const { isMobile } = useIsMobile();
  const { isAppleMobile } = useIsApple();
  const { day, month, next, year, subtitle, title, backgroundImage } = content;

  return (
    <>
      {!isMobile && <QuizBackground backgroundImage={backgroundImage} />}
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      {progressBar && <ProgressBar step={step} config={progressBar} onBack={onBack} />}
      <ContainerMainStyled>
        <TitleStyled>{title}</TitleStyled>
        {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        {isMobile ? (
          <ScrollPicker
            onSubmit={onSubmit}
            userDate={userDate}
            nextLabel={next}
            hasZodiacWheel={hasZodiacWheel}
            variant={isAppleMobile ? PickerVariants.CYLINDER : PickerVariants.DEFAULT}
          />
        ) : (
          <SelectDatePicker
            onSubmit={onSubmit}
            userDate={userDate}
            labels={{ next, day, month, year }}
          />
        )}
      </ContainerMainStyled>
    </>
  );
};

export default DatePickerV2Screen;
