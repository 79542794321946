import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { UtmSource } from 'core/user/entities';
import { getUtmSource } from 'core/user/store';

type MediaSourceVariantProps = {
  name: UtmSource | UtmSource[];
  children: ReactNode;
};

const MediaSourceVariant = ({ children, name }: MediaSourceVariantProps) => {
  const utmSource = useSelector(getUtmSource);

  if (!utmSource) return null;

  const getVariantFromArray = () => {
    if (!name.includes(utmSource)) return null;

    return children;
  };

  const getVariantFromString = () => {
    if (utmSource !== name) return null;

    return children;
  };

  const child = Array.isArray(name) ? getVariantFromArray() : getVariantFromString();

  return <>{child}</>;
};

export default MediaSourceVariant;
