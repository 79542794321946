import styled from 'styled-components';

export const WrapperStyled = styled.div<{ width: number; height: number }>`
  position: relative;
  aspect-ratio: ${({ width, height }) => width / height};

  @supports not (aspect-ratio: 1) {
    &::before {
      display: inline-block;
      padding-top: ${({ width, height }) => (height / width) * 100}%;
      vertical-align: top;
      content: '';
    }
  }
`;
