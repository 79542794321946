import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const ListStyled = styled.ul`
  max-width: 466px;
`;

export const ItemStyled = styled.li`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    align-items: stretch;
    margin-bottom: 16px;
    font-size: 18px;
  }
`;

export const ItemTextStyled = styled.span``;

export const IconStyled = styled(Icon)`
  flex-shrink: 0;
  margin-right: 7px;
  fill: ${({ theme }) => theme.colors.primary500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 24px;
    height: 24px;
  }
`;
