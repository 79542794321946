import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  flex-grow: 1;
`;

export const TitleStyled = styled.h4`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const SubtitleStyled = styled.p`
  margin-top: 2px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #b32425;
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageStyled = styled(Image)``;

export const TraumaScoreStyled = styled.span`
  position: absolute;
  font-size: 14px;
  font-weight: 700;
`;
