import { useRef } from 'react';
import Header from 'core/common/components/Header';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentNatalChartScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import BenefitsSection from './BenefitsSection';
import FAQ from './FAQ';
import GuaranteePaymentSection from './GuaranteePaymentSection';
import HeaderSection from './HeaderSection';
import PartnersSection from './PartnersSection';
import PurchaseSection from './PurchaseSection';
import SectionBlurredReport from './SectionBlurredReport';
import SectionDetails from './SectionDetails';
import SectionGuarantee from './SectionGuarantee';
import SectionPayment from './SectionPayment';
import SectionReviews from './SectionReviews';
import { FooterTextStyled, MainContainerStyled, NatalChartLogoStyled } from './styled';

const offerScrollOffset = -15;
const offerBlockId = 'disclaimer-block';

const TrialPaymentNatalChartScreen = ({
  content,
  onGetReport,
  onSucceed,
  onClose,
  timeLeft,
  offer,
  timeUpOffer,
  isPaymentModalOpen,
}: TrialPaymentNatalChartScreenProps) => {
  const {
    header,
    benefits,
    purchase,
    guarantee,
    details,
    report,
    faq,
    reviews,
    moneyBack,
    featuredIn,
    footer,
  } = content;

  const secondButtonRef = useRef<HTMLDivElement>(null);

  const isTimeUp = timeLeft <= 0;

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  if (!offer || !timeUpOffer) return null;

  return (
    <>
      <Header buttonBack={false} />
      <MainContainerStyled>
        <HeaderSection content={header} timeLeft={timeLeft} />
        <NatalChartLogoStyled
          src={header.image.src}
          alt={header.image.alt}
          width={150}
          height={150}
          id={offerBlockId}
        />
        <BenefitsSection title={benefits.title} benefits={benefits.list} />
        <PurchaseSection
          title={purchase.title}
          button={purchase.button}
          offer={offer}
          timeUpOffer={timeUpOffer}
          onGetReport={onGetReport}
          isTimeUp={isTimeUp}
          ref={secondButtonRef}
        />
        <GuaranteePaymentSection title={guarantee.title} image={guarantee.image} />
        <SectionDetails title={details.title} details={details.list} />
        <SectionBlurredReport content={report} onScrollToBlock={moveToOffer} />
        <FAQ title={faq.title} list={faq.list} />
        <SectionReviews title={reviews.title} reviews={reviews.list} />
        <SectionGuarantee
          image={moneyBack.image}
          title={moneyBack.title}
          firstText={moneyBack.firstText}
          secondText={moneyBack.secondText}
        />
        <PartnersSection title={featuredIn.title} image={featuredIn.image} />
        <Location>
          <LocationOption option={Locales.US}>
            <FooterTextStyled>{footer.addressUS}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.AE}>
            <FooterTextStyled>{footer.addressAE}</FooterTextStyled>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <FooterTextStyled>{footer.address}</FooterTextStyled>
          </LocationOption>
        </Location>
      </MainContainerStyled>
      <RenderOnlyOnClient>
        <SectionPayment
          onPaymentSuccess={onSucceed}
          isOpen={isPaymentModalOpen}
          offer={offer}
          onClose={onClose}
        />
      </RenderOnlyOnClient>
    </>
  );
};

export default TrialPaymentNatalChartScreen;
