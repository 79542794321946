import image from 'core/common/assets/images/trialPayment/question.svg';
import { AnswerText, HeaderBlock, ItemImageStyled, List, ListItem, QuestionText } from './styled';

export type FAQProps = {
  questions: Array<{
    question: string;
    answer: string;
  }>;
};

const FAQ = ({ questions }: FAQProps) => {
  return (
    <List>
      {questions.map(({ question, answer }) => (
        <ListItem key={question}>
          <HeaderBlock>
            <ItemImageStyled src={image} alt="question" width={24} height={25} />
            <QuestionText>{question}</QuestionText>
          </HeaderBlock>
          <AnswerText>{answer}</AnswerText>
        </ListItem>
      ))}
    </List>
  );
};

export default FAQ;
