import { SectionAdvantagesAttributes } from 'core/funnel/entities';
import {
  IconStyled,
  ItemStyled,
  ListStyled,
  TextStyled,
  TextWrapperStyled,
  TitleStyled,
} from './styled';

type AdvantagesProps = {
  className?: string;
  advantages: SectionAdvantagesAttributes['content']['advantages'];
};

const Advantages = ({ className, advantages }: AdvantagesProps) => (
  <ListStyled className={className}>
    {advantages.map(({ title, text, image }) => (
      <ItemStyled key={title}>
        <IconStyled src={image.src} alt={image.alt} width={20} height={20} />
        <TextWrapperStyled>
          <TitleStyled>{title}</TitleStyled>
          <TextStyled>{text}</TextStyled>
        </TextWrapperStyled>
      </ItemStyled>
    ))}
  </ListStyled>
);

export default Advantages;
