import { MouseEvent, useEffect, useRef, useState } from 'react';
import {
  CollaspsibleSectionStyled,
  HeaderStyled,
  CaptionStyled,
  TitleStyled,
  TextStyled,
  ButtonStyled,
  TextBlurredOverlayStyled,
  IconStyled,
  ImageStyled,
} from './styled';

type CollapsibleSectionProps = {
  caption: string;
  title: string;
  text: string;
  button: string;
  image: string;
  onMoveToOffer: () => void;
};

const defaultSectionHeight = 110;

const CollapsibleSection = ({
  caption,
  title,
  text,
  button,
  image,
  onMoveToOffer,
}: CollapsibleSectionProps) => {
  const collapsibleSectionRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(defaultSectionHeight);

  const toggleCollapsibleSection = () => {
    setExpanded((prev) => !prev);
  };

  const handleCTAButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onMoveToOffer();
  };

  useEffect(() => {
    if (!collapsibleSectionRef.current) return;

    if (expanded) {
      setHeight(collapsibleSectionRef.current.scrollHeight);

      return;
    }

    setHeight(defaultSectionHeight);
  }, [expanded]);

  return (
    <CollaspsibleSectionStyled
      ref={collapsibleSectionRef}
      expanded={expanded}
      height={height}
      onClick={toggleCollapsibleSection}
      role="button"
      aria-expanded={expanded}
    >
      <ImageStyled src={image} alt="" height={84} width={84} />
      <HeaderStyled>
        <CaptionStyled>{caption}</CaptionStyled>
        <IconStyled name="arrow-simple" />
      </HeaderStyled>
      <TitleStyled>{title}</TitleStyled>
      <TextStyled>
        {text}
        {expanded && <TextBlurredOverlayStyled />}
      </TextStyled>
      {expanded && <ButtonStyled onClick={handleCTAButtonClick}>{button}</ButtonStyled>}
    </CollaspsibleSectionStyled>
  );
};

export default CollapsibleSection;
