import { useEffect } from 'react';
import ThankYouWithRedirectScreen from 'core/common/components/QuizScreens/ThankYouWithRedirect';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ThankYouWithRedirectScreenAttributes } from 'core/funnel/entities';

type ThankYouWithRedirectProps = {
  id: string;
};

const ThankYouWithRedirect = ({ id }: ThankYouWithRedirectProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  const { content } = screenAttributes as ThankYouWithRedirectScreenAttributes;

  useEffect(() => {
    const timeout = setTimeout(() => {
      next();
    }, content.timer);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next]);

  return (
    <ThankYouWithRedirectScreen {...(screenAttributes as ThankYouWithRedirectScreenAttributes)} />
  );
};

export default ThankYouWithRedirect;
