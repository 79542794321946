import { FunnelImage } from 'core/funnel/entities';
import CollapsibleText from '../CollapsibleText';
import {
  CardStyled,
  AvatarStyled,
  BioWrapperStyled,
  NameStyled,
  ExpertBioStyled,
  DetailsListStyled,
  DetailsItemStyled,
  DetailsTitleStyled,
  DetailsSubtitleStyled,
  TitleIconStyled,
  FieldsTitleStyled,
  FieldsListStyled,
  FieldsListItemStyled,
} from './styled';

type ExpertCardProps = {
  name: string;
  description: string;
  avatar: FunnelImage;
  readMoreButtonText: string;
  readLessButtonText: string;
  expertFields: {
    title: string;
    items: Array<string>;
  };
  details: Array<{ title: string; subtitle: string; titleIcon?: FunnelImage }>;
};

const ExpertCard = ({
  name,
  avatar,
  details,
  description,
  expertFields,
  readLessButtonText,
  readMoreButtonText,
}: ExpertCardProps) => {
  return (
    <CardStyled>
      <BioWrapperStyled>
        <AvatarStyled src={avatar.src} alt={avatar.alt} width={80} height={80} />
        <ExpertBioStyled>
          <NameStyled>{name}</NameStyled>
          <CollapsibleText
            readMoreButtonText={readMoreButtonText}
            readLessButtonText={readLessButtonText}
          >
            {description}
          </CollapsibleText>
        </ExpertBioStyled>
      </BioWrapperStyled>
      <DetailsListStyled>
        {details.map((item) => (
          <DetailsItemStyled key={item.title}>
            <DetailsTitleStyled>
              {item.title}
              {item.titleIcon && (
                <TitleIconStyled
                  src={item.titleIcon.src}
                  alt={item.titleIcon.alt}
                  width={16}
                  height={16}
                />
              )}
            </DetailsTitleStyled>
            <DetailsSubtitleStyled>{item.subtitle}</DetailsSubtitleStyled>
          </DetailsItemStyled>
        ))}
      </DetailsListStyled>
      <FieldsTitleStyled>{expertFields.title}</FieldsTitleStyled>
      <FieldsListStyled>
        {expertFields.items.map((field) => (
          <FieldsListItemStyled key={field}>{field}</FieldsListItemStyled>
        ))}
      </FieldsListStyled>
    </CardStyled>
  );
};

export default ExpertCard;
