import { ScreenAttributes } from 'core/funnel/entities';
import { StyledImage } from './styled';

type ImagesProps = {
  attributes: ScreenAttributes[];
};

enum Extension {
  PNG = '.png',
  JPG = '.jpg',
  JPEG = '.jpeg',
  SVG = '.svg',
}

const Images = ({ attributes }: ImagesProps) => {
  // Recursive go through content, find images and return all founded urls
  const extractImageUrls = (data: Record<string, unknown>) => {
    const urls: string[] = [];

    const traverse = (obj: Record<string, unknown>) => {
      for (const key in obj) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          // Prevent collecting background images, they are processed in the Backgrounds component
          if (key === 'backgroundImage') return;

          traverse(value as Record<string, unknown>);
        } else if (typeof value === 'string') {
          if (
            value.endsWith(Extension.PNG) ||
            value.endsWith(Extension.SVG) ||
            value.endsWith(Extension.JPG) ||
            value.endsWith(Extension.JPEG)
          ) {
            urls.push(value);
          }
        }
      }
    };

    traverse(data);

    return urls;
  };

  const urls: string[] = [];

  attributes.forEach((attr) => {
    if ('content' in attr) {
      const imageUrls = extractImageUrls(attr.content);

      if (imageUrls.length) {
        urls.push(...imageUrls);
      }
    }
  });

  const images = urls.map((url: string) => {
    return url ? <StyledImage key={url} src={url} alt="" fill /> : null;
  });

  return <>{images}</>;
};

export default Images;
