import { useEffect, useState } from 'react';
import UpsellReadingsPackScreen from 'core/common/components/QuizScreens/UpsellReadingsPack';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { getAttractivePrice } from 'core/common/utils/getAttractivePrice';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellReadingsPackScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellReadingsPackSingleProps = {
  id: string;
};

const discountFraction = 0.5;

const UpsellReadingsPackSingle = ({ id }: UpsellReadingsPackSingleProps) => {
  const { next, extraNext, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { ultraPackOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const [displayDiscountModal, setDisplayDiscountModal] = useState(false);

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    if (!ultraPackOffer) return;

    analyticsService.readingsPackUpsellScreenClicked();
    recurringPaymentRequest(ultraPackOffer.offerId);
  };

  const skipOffer = () => {
    setDisplayDiscountModal(true);
  };

  const onGetSecretDiscount = () => {
    extraNext();
  };

  const onCloseDiscountModal = () => {
    setDisplayDiscountModal(false);
    next();
  };

  useEffect(() => {
    analyticsService.readingsPackUpsellScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.readingsPackUpsellGetOffer(ultraPackOffer ? ultraPackOffer.offerId : '');
      next();
    }
  }, [paymentProcessStep]);

  const offerPrice = ultraPackOffer
    ? ultraPackOffer.currencySymbol +
      getAttractivePrice(ultraPackOffer.prices.priceWithVAT / discountFraction)
    : '';
  const offerTrialPrice = ultraPackOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '';

  return (
    <UpsellReadingsPackScreen
      price={offerPrice}
      isLoading={isPurchaseLoading}
      plan={ultraPackOffer}
      trialPrice={offerTrialPrice}
      resignFormLoaded={isResignFormLoaded}
      displayDiscountModal={displayDiscountModal}
      onGetOffer={getReport}
      onSkipOffer={skipOffer}
      onGetSecretDiscount={onGetSecretDiscount}
      onCloseDiscountModal={onCloseDiscountModal}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellReadingsPackScreenAttributes)}
    />
  );
};

export default UpsellReadingsPackSingle;
