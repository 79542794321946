import { useEffect } from 'react';
import { useDispatch, useServices } from 'core/common/hooks';
import { isValidPhoneNumber } from 'core/common/utils/phoneNumber';
import { useUserLocation } from 'core/user/hooks';
import { updateUser } from 'core/user/store';

// TODO if experiment wins, maybe move some logic to PhoneSteps
const usePhoneNumber = (goNext: () => void) => {
  const dispatch = useDispatch();

  const { country } = useUserLocation();
  const { analyticsService } = useServices();

  const handleGetPersonalizedUpdatesClick = (
    enteredPhoneNumber: string,
    { validationError }: { validationError?: () => void } = {},
  ) => {
    if (!isValidPhoneNumber(enteredPhoneNumber)) {
      if (validationError) {
        validationError();
      }
      return;
    }

    dispatch(updateUser({ phone_number: enteredPhoneNumber }));
    analyticsService.phoneNumberScreenAdd();
    goNext();
  };

  const skip = () => {
    goNext();
    analyticsService.phoneNumberScreenSkip();
  };

  useEffect(() => {
    analyticsService.phoneNumberScreenOpen();
  }, [analyticsService]);

  return {
    handleGetPersonalizedUpdatesClick,
    skip,
    country,
  };
};

export default usePhoneNumber;
