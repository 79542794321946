import styled from 'styled-components';

export const Item = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.typography100};
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #e2e4e7;
  }
`;

export const City = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${(props) => props.theme.colors.textPrimary};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const Region = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const Country = styled.span``;
