import styled from 'styled-components';

export const InfoContainer = styled.div`
  margin-top: 20px;
  padding: 10px 14px;
  border-radius: 12px;
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(95deg, #cf8bf3 -16.49%, #a770ef -15.14%, #fdb99b 115.23%);
`;

export const Description = styled.p`
  margin-top: 4px;
`;

export const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;

  &::first-letter {
    text-transform: uppercase;
  }
`;
