import Header from 'core/common/components/Header';
import { LoaderList } from 'core/common/components/ProcessingLoader';
import QuizBackground from 'core/common/components/QuizBackground';
import { LoadingScreenProps } from 'core/funnel/entities';
import { StyledProcessingLoader, StyledTitle, Subtitle } from './styled';

const LoadingScreen = ({ onLoadingFinished, content }: LoadingScreenProps) => {
  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} variant="darkGradient" />
      <Header buttonBack={false} variant="secondary" />
      <StyledTitle variant="secondary" dangerouslyInnerHTML={content.title} />
      <Subtitle>{content.subtitle}</Subtitle>
      <StyledProcessingLoader onFinished={onLoadingFinished} />
      <LoaderList list={content.list} />
    </>
  );
};

export default LoadingScreen;
