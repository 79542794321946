import { forwardRef } from 'react';
import { BASE_ROUTES } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionOfferAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import {
  ActionButtonStyled,
  DisclaimerStyled,
  GoalImageStyled,
  GoalStyled,
  GoalTextStyled,
  GoalTitleStyled,
  GoalWrapperStyled,
  GuaranteeStyled,
  HighlightStyled,
  LinkStyled,
  OfferContentStyled,
  OfferHeaderStyled,
  OfferNewPriceStyled,
  OfferOldPriceStyled,
  OfferPriceDescriptionStyled,
  OfferPriceStyled,
  OfferPriceTitleStyled,
  OfferPriceWrapperStyled,
  OfferTitleStyled,
  OfferWrapperStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

export type SpecialOfferProps = {
  goal?: string;
  offer: Offer | null;
  className?: string;
  content: SectionOfferAttributes['content'];
  onGetReport: () => void;
};

const SectionOffer = forwardRef<HTMLDivElement, SpecialOfferProps>(
  ({ onGetReport, offer, className, content, goal }, ref) => {
    if (!offer) return null;

    const fullPrice = `${offer.currencySymbol}${content.offerData.fullPrice}`;

    return (
      <WrapperStyled ref={ref} className={className}>
        <TitleStyled>{content.title}</TitleStyled>
        {goal && (
          <GoalWrapperStyled>
            <GoalImageStyled
              alt={content.goal.image.alt}
              src={content.goal.image.src}
              width={38}
              height={38}
            />
            <GoalStyled>
              <GoalTitleStyled>{content.goal.title}</GoalTitleStyled>
              <GoalTextStyled>{goal}</GoalTextStyled>
            </GoalStyled>
          </GoalWrapperStyled>
        )}
        <OfferWrapperStyled>
          <OfferHeaderStyled>{content.offerData.title}</OfferHeaderStyled>
          <OfferContentStyled>
            <OfferTitleStyled>
              {content.offerData.description}{' '}
              <HighlightStyled>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</HighlightStyled>
            </OfferTitleStyled>
            <OfferPriceWrapperStyled>
              <OfferPriceTitleStyled>{content.offerData.totalToday.title}:</OfferPriceTitleStyled>
              <OfferPriceStyled>
                <OfferOldPriceStyled>
                  {offer.currencySymbol}
                  {content.offerData.totalToday.price}
                </OfferOldPriceStyled>
                <OfferNewPriceStyled>
                  {offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                </OfferNewPriceStyled>
              </OfferPriceStyled>
            </OfferPriceWrapperStyled>
            <OfferPriceDescriptionStyled>
              <OfferPriceTitleStyled
                dangerouslySetInnerHTML={prepareHtml(content.offerData.priceTitle)}
              />
              <OfferPriceStyled>
                <OfferOldPriceStyled>{fullPrice}</OfferOldPriceStyled>
                <OfferNewPriceStyled>
                  {offer.pricesWithCurrencySymbol.priceWithVAT}
                </OfferNewPriceStyled>
              </OfferPriceStyled>
            </OfferPriceDescriptionStyled>
          </OfferContentStyled>
        </OfferWrapperStyled>
        <ActionButtonStyled impulseScale onClick={onGetReport}>
          {content.offerData.button}
        </ActionButtonStyled>
        <GuaranteeStyled>{content.offerData.guaranteedText}</GuaranteeStyled>
        <DisclaimerStyled dangerouslySetInnerHTML={prepareHtml(content.offerData.disclaimer)} />
        <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>
          {content.offerData.linkSubscriptionPolicy}
        </LinkStyled>
      </WrapperStyled>
    );
  },
);

export default SectionOffer;
