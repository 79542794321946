import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import ReviewsCardBody from '../ReviewsCardBody';
import ReviewsCardHeader from '../ReviewsCardHeader';
import {
  RatingStarsStyled,
  DateStyled,
  TextStyled,
  AvatarStyled,
  FooterTextStyled,
  ReviewsCardFooterStyled,
  ReviewsCardBaseStyled,
} from './styled';

type ReviewsCardAvatarProps = {
  date: string;
  review: string;
  userAvatar: FunnelImage;
  userName: string;
};

const ReviewsCardAvatar = ({ date, review, userAvatar, userName }: ReviewsCardAvatarProps) => {
  return (
    <ReviewsCardBaseStyled>
      <ReviewsCardHeader>
        <RatingStarsStyled />
        <DateStyled dangerouslySetInnerHTML={prepareHtml(date)} />
      </ReviewsCardHeader>
      <ReviewsCardBody>
        <TextStyled dangerouslySetInnerHTML={prepareHtml(review)} />
      </ReviewsCardBody>
      <ReviewsCardFooterStyled>
        <AvatarStyled src={userAvatar.src} alt={userAvatar.alt} width={32} height={32} />
        <FooterTextStyled dangerouslySetInnerHTML={prepareHtml(userName)} />
      </ReviewsCardFooterStyled>
    </ReviewsCardBaseStyled>
  );
};

export default ReviewsCardAvatar;
