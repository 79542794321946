import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 85%;
    font-size: 20px;
  }
`;

export const BoldStyled = styled.b``;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.typography100};
`;
