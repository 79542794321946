import styled from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';

export const WrapperStyled = styled.section`
  position: sticky;
  bottom: 0;
  margin-right: -16px;
  margin-left: -16px;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  background-color: #f5f5f5;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    padding: 24px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const DiscountStyled = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border-bottom-left-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  background-color: #f3ac0d;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const PriceStyled = styled.p`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const OldPriceStyled = styled.span`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const DisclaimerStyled = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    width: 400px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const SkipButtonStyled = styled(TextButton)`
  width: 100%;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};
`;
