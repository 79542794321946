import { useFormik } from 'formik';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { isEmailValid } from 'core/common/utils/string';
import { EmailPalmistryScreenProps } from 'core/funnel/entities';
import EmailSuggestionsList from '../../EmailSuggestionsList';
import {
  ButtonStyled,
  ContainerStyled,
  ContentStyled,
  FormStyled,
  PolicyStyled,
  SubtitleStyled,
  TextStyled,
  TitleStyled,
} from './styled';

const EmailPalmistryScreen = ({
  content,
  initialValue,
  onSubmit,
  onBack,
}: EmailPalmistryScreenProps) => {
  const { getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue } =
    useFormik({
      initialValues: {
        email: initialValue,
      },
      validate: ({ email }) => {
        if (!email || !isEmailValid(email)) {
          return { email: content.error };
        }

        return {};
      },
      onSubmit: async (submitValues, helpers) => {
        await onSubmit(submitValues.email, helpers);
      },
    });

  const error = touched.email ? errors.email : '';

  return (
    <>
      <Header onBack={onBack} />
      <ContainerStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(content.subtitle)} />
        <FormStyled onSubmit={handleSubmit}>
          <ContentStyled>
            <Input
              {...getFieldProps('email')}
              error={!!error}
              errorText={error}
              placeholder={content.placeholder}
            />
            <EmailSuggestionsList
              input={values.email}
              onSelect={(value) => setFieldValue('email', value)}
            />
            <TextStyled>{content.text}</TextStyled>
          </ContentStyled>
          <ButtonStyled type="submit" disabled={isSubmitting}>
            {content.button}
          </ButtonStyled>
          <PolicyStyled />
        </FormStyled>
      </ContainerStyled>
    </>
  );
};

export default EmailPalmistryScreen;
