import { SectionBenefitsAttributes } from 'core/funnel/entities';
import { IconStyled, ItemStyled, ListStyled, TitleStyled, WrapperStyled } from './styled';

type SectionBenefitsProps = {
  content: SectionBenefitsAttributes['content'];
  className?: string;
};

const SectionBenefits = ({ className, content }: SectionBenefitsProps) => {
  return (
    <WrapperStyled className={className}>
      <TitleStyled>{content.title}</TitleStyled>
      <ListStyled>
        {content.list.map((item, i) => (
          <ItemStyled key={item.title + i}>
            <IconStyled width={24} height={24} src={item.image.src} alt={item.image.alt} />
            <span>{item.title}</span>
          </ItemStyled>
        ))}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionBenefits;
