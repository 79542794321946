import { useFormik } from 'formik';
import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';

import { UserAnswerScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerStyled,
  ContentStyled,
  FormStyled,
  InputStyled,
  SkipStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

const UserAnswerScreen = ({
  content,
  initialValue,
  onSubmit,
  onSkip,
  onBack,
  step,
  progressBar,
  hasButtonBack = true,
}: UserAnswerScreenProps) => {
  const { getFieldProps, errors, touched, isSubmitting, handleSubmit } = useFormik({
    initialValues: { userAnswer: initialValue },
    validate: ({ userAnswer }) => {
      return !userAnswer ? { userAnswer: content.error } : {};
    },
    onSubmit: (submitValues) => {
      onSubmit(submitValues.userAnswer);
    },
  });

  const error = touched.userAnswer ? errors.userAnswer : '';
  const { title, subtitle, placeholder, button, skip } = content;
  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      {progressBar && <ProgressBar step={step} config={progressBar} onBack={onBack} />}
      <ContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        <FormStyled onSubmit={handleSubmit}>
          <ContentStyled>
            <InputStyled
              {...getFieldProps('userAnswer')}
              error={!!error}
              errorText={error}
              placeholder={placeholder}
            />
          </ContentStyled>
          <ButtonStyled type="submit" disabled={isSubmitting}>
            {button}
          </ButtonStyled>
        </FormStyled>
        {skip && (
          <SkipStyled type="button" onClick={onSkip}>
            {skip}
          </SkipStyled>
        )}
      </ContainerStyled>
    </>
  );
};

export default UserAnswerScreen;
