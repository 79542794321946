import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 43px;
  }
`;

export const ExpertsListStyled = styled.ul`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
  }
`;

export const ExpertsListItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    &:not(:last-child) {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;

export const ExpertsImageStyled = styled(Image)`
  display: block;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 550px;
    height: 100px;
  }
`;
