import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import { FunnelImage } from 'core/funnel/entities';

type Point = {
  icon: FunnelImage;
  title: string;
  bad: string;
  neutral: string;
  good: string;
  randomizeRange?: {
    min: number;
    max: number;
  };
};

type PointWithValue = Pick<Point, 'icon' | 'title'> & { value: number; text: string };

const progressStepsMap = {
  good: 0,
  neutral: 34,
  bad: 67,
};

export const getColorByProgress = (progress: number) => {
  if (progress < progressStepsMap.neutral) {
    return '#73C78D';
  }

  if (progress < progressStepsMap.bad) {
    return '#F16A02';
  }

  return '#f0483f';
};

export const mapPointsToValues = (points: Array<Point>): Array<PointWithValue> => {
  return points.map((point) => {
    const minRandomizeValue = point.randomizeRange ? point.randomizeRange.min : 7;
    const maxRandomizeValue = point.randomizeRange ? point.randomizeRange.max : 95;
    const value = getRandomNumberWithinRange(minRandomizeValue, maxRandomizeValue);

    if (value < progressStepsMap.neutral) {
      return {
        value,
        text: point.good,
        icon: point.icon,
        title: point.title,
      };
    }

    if (value < progressStepsMap.bad) {
      return {
        value,
        text: point.neutral,
        icon: point.icon,
        title: point.title,
      };
    }

    return {
      value,
      text: point.bad,
      icon: point.icon,
      title: point.title,
    };
  });
};
