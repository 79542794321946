import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';
import { salesScreenHeaderHeight } from './SalesScreenHeader/styled';

const horizontalPadding = 15;

export const MainContainerStyled = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const HeaderSpaceStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: ${salesScreenHeaderHeight}px;
`;

export const EmailAvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 50%;
  text-transform: capitalize;
  background: ${(props) => props.theme.colors.primaryLeo};
`;

export const UserEmailStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
  line-height: 130%;
`;

export const MainContainerWideStyled = styled(MainContainerStyled)`
  align-self: center;
  width: calc(100% + ${horizontalPadding * 2}px);
  max-width: 420px;
  margin-right: -${horizontalPadding}px;
  margin-left: -${horizontalPadding}px;
`;

export const TitleStyled = styled(TitleComponent)`
  max-width: 300px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
`;

export const ColoredBackgroundWrapperStyled = styled.div`
  width: 100%;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #eaeef7;
`;

export const CommonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
`;

export const FooterTextStyled = styled.p`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;
