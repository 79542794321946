import { useContext } from 'react';
import { useFunnelData } from 'core/funnel/contexts';
import { QuizContext } from 'core/funnel/contexts/FlowContext';
import { QuizStepTypes, ScreenAttributes } from 'core/funnel/entities';

export const useNextScreensAttributes = (nextScreenId: string) => {
  const funnel = useFunnelData();
  const { getScreenAttributes } = useContext(QuizContext);

  const nextAttributes = getScreenAttributes(nextScreenId);

  if (nextAttributes.screenType !== QuizStepTypes.SPLIT) {
    return [nextAttributes];
  }

  const nextScreensBySplit = funnel.flow?.screens?.find((screen) => screen.id === nextScreenId);

  const nextAttributesBySplit = nextScreensBySplit?.attributes?.flatMap((screen) => {
    const screenAttrs = getScreenAttributes(screen.next);

    return screenAttrs ? [screenAttrs] : [];
  });

  return nextAttributesBySplit as ScreenAttributes[];
};
