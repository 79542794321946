import { prepareHtml } from 'core/common/utils/prepareHtml';
import {
  AnswerTextStyled,
  FAQBlockStyled,
  ItemContentBlockStyled,
  ItemIconBlockStyled,
  ListStyled,
  ListItemStyled,
  QuestionIconStyled,
  QuestionTextStyled,
  TitleStyled,
} from './styled';

type FAQProps = {
  title: string;
  list: Array<{ question: string; answer: string }>;
};

const FAQ = ({ title, list }: FAQProps) => {
  return (
    <FAQBlockStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <ListStyled>
        {list.map(({ question, answer }) => (
          <ListItemStyled key={question}>
            <ItemIconBlockStyled>
              <QuestionIconStyled />
            </ItemIconBlockStyled>

            <ItemContentBlockStyled>
              <QuestionTextStyled dangerouslySetInnerHTML={prepareHtml(question)} />
              <AnswerTextStyled dangerouslySetInnerHTML={prepareHtml(answer)} />
            </ItemContentBlockStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
    </FAQBlockStyled>
  );
};

export default FAQ;
