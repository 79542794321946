import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { ScanV2ScreenProps } from 'core/funnel/entities';
import { PopUpTypes } from 'core/popUp/entities';
import { usePopUps } from 'core/popUp/hooks';
import CameraPermissionsAlert from './CameraPermissionsAlert';
import ScanProcessPopUps from './ScanProcessPopUps';
import {
  ButtonStyled,
  ContainerStyled,
  GuaranteeContentStyled,
  GuaranteeIconStyled,
  GuaranteeStyled,
  ImageStyled,
  TitleStyled,
  WrongScanImageStyled,
} from './styled';

const ScanV2Screen = ({ onBack, goNext, step, content, totalSteps }: ScanV2ScreenProps) => {
  const { activePopUpType, openScanProcessPopUp, closePopUp } = usePopUps();

  const { title, take, guarantee, cameraPermission, wrongScanImage, correctScanImage } = content;

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <ContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        <ImageStyled
          src={correctScanImage.src}
          width={320}
          height={190}
          alt={correctScanImage.src}
        />
        <WrongScanImageStyled
          src={wrongScanImage.src}
          width={320}
          height={110}
          alt={wrongScanImage.alt}
        />
        <ButtonStyled onClick={openScanProcessPopUp}>{take}</ButtonStyled>
        <GuaranteeStyled>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeContentStyled>{guarantee}</GuaranteeContentStyled>
        </GuaranteeStyled>
        <ScanProcessPopUps
          isOpen={activePopUpType === PopUpTypes.SCAN_PROCESS}
          content={content}
          goNext={goNext}
          onClosePopUp={closePopUp}
        />
        <CameraPermissionsAlert
          isOpen={activePopUpType === PopUpTypes.CAMERA_ACCESS_ALERT}
          title={cameraPermission.title}
          subtitle={cameraPermission.subtitle}
          caption={cameraPermission.caption}
          buttonText={cameraPermission.buttonText}
          mainImage={cameraPermission.mainImage}
          topImage={cameraPermission.topImage}
          onClose={closePopUp}
          onConfirm={closePopUp}
        />
      </ContainerStyled>
    </>
  );
};

export default ScanV2Screen;
