import { FunnelImage } from 'core/funnel/entities';
import {
  ButtonStyled,
  TitleStyled,
  SubtitleStyled,
  WrapperStyled,
  ImageStyled,
  SkipButtonStyled,
  CloseButtonStyled,
} from './styled';

export type ScanErrorProps = {
  title: string;
  subtitle: string;
  button: string;
  skipButton?: string;
  image: FunnelImage;
  tryAgain: () => void;
  onClose: () => void;
  onSkip: () => void;
};

const ScanError = ({
  subtitle,
  button,
  image,
  skipButton,
  tryAgain,
  title,
  onClose,
  onSkip,
}: ScanErrorProps) => {
  return (
    <>
      <CloseButtonStyled onClick={onClose} />
      <WrapperStyled>
        <ImageStyled src={image.src} width={126} height={194} alt={image.alt} />
        <TitleStyled>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <ButtonStyled onClick={tryAgain}>{button}</ButtonStyled>
        {skipButton && <SkipButtonStyled onClick={onSkip}>{skipButton}</SkipButtonStyled>}
      </WrapperStyled>
    </>
  );
};

export default ScanError;
