import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff0f0;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 90px;
`;

export const Text = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 180%;
`;

export const TextBold = styled(Text)`
  font-weight: 600;
`;

export const TextColored = styled(Text)`
  margin-bottom: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.info};
`;

export const PriceBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 440px;
  margin-right: auto;
  margin-left: auto;
`;

export const Tip = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const TipText = styled.p`
  width: 272px;
  font-size: 12px;
  line-height: 16px;
  color: #343434;
`;

export const Price = styled.button<{ isActive?: boolean | null }>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 12px;
  font-weight: 600;
  color: ${(props) =>
    props.isActive ? props.theme.colors.typography100 : props.theme.colors.typography700};
  background: ${(props) =>
    props.isActive ? props.theme.gradients.primary : props.theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px rgba(84, 60, 151, 0.25);
`;

export const Button = styled(ButtonComponent)`
  position: fixed;
  bottom: 20px;
  height: 50px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: relative;
    bottom: 0;
    margin-bottom: 20px;
  }
`;

export const Note = styled.p`
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.typography700};
`;
