import { BASE_ROUTES } from 'core/common/constants';
import { SectionMoneyBackGuaranteeAttributes } from 'core/funnel/entities';
import {
  GuaranteeWrapperStyled,
  ImageStyled,
  LinkStyled,
  TextStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

type SectionMoneyBackGuaranteeProps = {
  content: SectionMoneyBackGuaranteeAttributes['content'];
};

const SectionMoneyBackGuarantee = ({ content }: SectionMoneyBackGuaranteeProps) => {
  return (
    <WrapperStyled>
      <ImageStyled
        src={content.imageAppRating.src}
        alt={content.imageAppRating.alt}
        height={134}
        width={250}
      />
      <GuaranteeWrapperStyled>
        <ImageStyled
          src={content.imageGuarantee.src}
          alt={content.imageGuarantee.alt}
          width={60}
          height={60}
        />
        <TitleStyled>{content.title}</TitleStyled>
        <TextStyled>
          {content.text}{' '}
          <LinkStyled href={BASE_ROUTES.TECH_MONEY_BACK_POLICY}> {content.linkPolicy}</LinkStyled>
        </TextStyled>
      </GuaranteeWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionMoneyBackGuarantee;
