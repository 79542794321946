import { useEffect } from 'react';
import { LANDINGS } from 'core/common/constants';
import { useRouter, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizData } from 'core/funnel/hooks';
import { useOffers } from 'core/offers/hooks';

export function useAdditionalDiscountIntroOffer(goNext: () => void) {
  const { analyticsService } = useServices();
  const { navigate } = useRouter();

  const { link } = useQuizData();

  const { discountOffer } = useOffers();

  const onGetReport = () => {
    analyticsService.discountLpGetReport();
    goNext();
  };

  const goBack = () => {
    navigate(joinUrls(link, LANDINGS.TRIAL_PAYMENT));
  };

  useEffect(() => {
    analyticsService.discountLpOpened();
  }, [analyticsService]);

  return {
    discountOffer,
    onGetReport,
    goBack,
  };
}
