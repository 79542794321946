import styled from 'styled-components';

const progressLineColor = '#ecc8bc';
const stepCircleZIndex = 2;
const stepCircleBorder = `2px solid ${progressLineColor}`;

export const StepsWrapper = styled.ul`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 708px;
  margin: 35px auto 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 56px;
    margin-bottom: 38px;
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: ${progressLineColor};
  transform: translateY(-50%);
`;

export const StepLabel = styled.span`
  position: absolute;
  top: -5px;
  left: 50%;
  min-width: 60px;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
  transform: translate(-50%, -100%);

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-width: 75px;
    font-size: 14px;
  }
`;

export const Step = styled.li<{ active: boolean; completed: boolean }>`
  position: relative;
  z-index: ${stepCircleZIndex};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: ${({ completed }) => (completed ? '-2px' : '0')};
  border: ${({ completed, active }) => (active || completed ? 'none' : stepCircleBorder)};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.themeBackground};

  ${StepLabel} {
    font-weight: ${({ completed, active }) => (completed || active ? '600' : '400')};
  }

  &:first-of-type ${StepLabel} {
    left: 0;
    text-align: left;
    transform: translate(0, -100%);
  }

  &:last-of-type ${StepLabel} {
    right: 0;
    left: unset;
    text-align: right;
    transform: translate(0, -100%);
  }
`;

export const ProgressLine = styled.div<{ progress: number }>`
  position: absolute;
  top: 50%;
  width: ${({ progress }) => progress}%;
  height: 2px;
  background: ${({ theme }) => theme.gradients.secondaryLight + ' border-box'};
  transform: translateY(-50%);
`;
