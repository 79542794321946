import getOnAppStoreImage from 'core/common/assets/images/get_on_appstore.png';
import getOnGooglePlayImage from 'core/common/assets/images/get_on_google_play.png';
import premiumIntroImage from 'core/common/assets/images/upsells/premium_intro.png';
import Image from 'core/common/components/Image';
import { UnlimitedReadingsPalmistryScreenAttributes } from 'core/funnel/entities';

import {
  DescriptionFooter,
  FooterImages,
  HalfRowImage,
  Price,
  SlideDescription,
  Sup,
  TextEmoji,
  TextWithEmoji,
} from './styled';

type MainIntroSlideProps = {
  displayPrice: string;
  content: UnlimitedReadingsPalmistryScreenAttributes['content']['infoSlide'];
};

const MainIntroSlide = ({ displayPrice, content }: MainIntroSlideProps) => {
  const [mainAmount, coinsAmount] = displayPrice.split('.');

  const { benefits } = content;

  return (
    <>
      <HalfRowImage src={premiumIntroImage} alt="Premium intro" priority />
      <SlideDescription>
        {benefits.map(({ text, emoji }) => (
          <TextWithEmoji key={text}>
            <TextEmoji emoji={emoji} height={18} width={18} desktopLarge={false} />
            {text}
          </TextWithEmoji>
        ))}
        <DescriptionFooter>
          <FooterImages>
            <Image src={getOnAppStoreImage} alt="Get on AppStore" width={54} height={16} />
            <Image src={getOnGooglePlayImage} alt="Get on GooglePlay" width={54} height={15} />
          </FooterImages>
          <Price>
            {mainAmount}
            <Sup>{coinsAmount}</Sup>
          </Price>
        </DescriptionFooter>
      </SlideDescription>
    </>
  );
};

export default MainIntroSlide;
