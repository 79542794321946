import styled from 'styled-components';

export const GoalWrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const GoalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const GoalLabel = styled.span`
  font-size: 12px;
  line-height: 1.3;
`;

export const GoalText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;

  &::first-letter {
    text-transform: capitalize;
  }
`;
