import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { InformationalScreenProps } from 'core/funnel/entities';
import {
  ButtonsWrapper,
  Container,
  NextButton,
  PrevButton,
  StyledAnimatedImage,
  StyledTitle,
  Text,
} from './styled';

const InformationalScreen = ({ onBack, goNext, content }: InformationalScreenProps) => {
  const {
    title,
    text,
    optionalText,
    animatedImage,
    next: buttonNext,
    back: buttonBack,
    backgroundImage,
  } = content;

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} variant="darkGradient" />
      <Header onBack={onBack} variant="secondary" />
      <Container>
        {!!animatedImage && <StyledAnimatedImage variant={animatedImage} />}
        <StyledTitle dangerouslySetInnerHTML={prepareHtml(title)} />
        {!!text && <Text dangerouslySetInnerHTML={prepareHtml(text)} />}
        {!!optionalText && <Text dangerouslySetInnerHTML={prepareHtml(optionalText)} />}
      </Container>
      <ButtonsWrapper>
        {!!buttonBack && <PrevButton onClick={onBack}>{buttonBack}</PrevButton>}
        <NextButton onClick={goNext} variant="secondary">
          {buttonNext}
        </NextButton>
      </ButtonsWrapper>
    </>
  );
};

export default InformationalScreen;
