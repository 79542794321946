import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  HeaderStyled,
  TitleStyled,
  IconStyled,
  ValueStyled,
  LinearProgressStyled,
  TextStyled,
} from './styled';

type PointCardProps = {
  icon: FunnelImage;
  title: string;
  value: number;
  text: string;
};

const PointCard = ({ icon, title, value, text }: PointCardProps) => {
  return (
    <WrapperStyled>
      <HeaderStyled>
        <IconStyled src={icon.src} alt={icon.alt} width={24} height={24} />
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <ValueStyled dangerouslySetInnerHTML={prepareHtml(`${value}%`)} value={value} />
      </HeaderStyled>
      <LinearProgressStyled value={value} />
      <TextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
    </WrapperStyled>
  );
};

export default PointCard;
