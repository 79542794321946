import { FunnelImage } from 'core/funnel/entities';
import PlanSubject from './PlanSubject';
import { WrapperStyled, TitleStyled, SubtitleStyled, ContentWrapperStyled } from './styled';

export type PlanSubjectContent = {
  label: string;
  title: string;
  icon: FunnelImage;
  image: FunnelImage;
  keyPoints: Array<{
    text: string;
    icon: string;
  }>;
};

type PlanProps = {
  title: string;
  subtitle: string;
  withoutPlan: PlanSubjectContent;
  withPlan: PlanSubjectContent;
};

const Plan = ({ title, subtitle, withoutPlan, withPlan }: PlanProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
      <ContentWrapperStyled>
        <PlanSubject {...withoutPlan} />
        <PlanSubject {...withPlan} variant="gradient" />
      </ContentWrapperStyled>
    </WrapperStyled>
  );
};

export default Plan;
