import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Rating from 'core/common/components/Rating';

export const WrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RatingStyled = styled(Rating)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const ListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 250px;
`;

export const SupTextStyled = styled.span`
  font-weight: 700;
  line-height: 20px;
`;

export const SubTextStyled = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const ListItemStyled = styled.li`
  position: relative;
  width: 33%;
  margin-bottom: 16px;
  text-align: center;

  &:nth-child(2) {
    ${SupTextStyled} {
      font-size: 24px;
    }

    &::after,
    &::before {
      position: absolute;
      top: 12px;
      display: inline-block;
      width: 1px;
      height: 18px;
      background-color: ${({ theme }) => theme.colors.typography200};
      content: '';
    }

    &::after {
      right: -4px;
    }

    &::before {
      left: -4px;
    }
  }

  &:nth-child(4)::after {
    position: absolute;
    top: 12px;
    right: 0;
    display: inline-block;
    width: 1px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.typography200};
    content: '';
  }
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
`;
