import styled from 'styled-components';
import Checkbox from '../Checkbox';
import Error from '../Error';
import Link from '../Link';

export const Container = styled.div`
  max-width: 330px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const NavLink = styled(Link)`
  text-decoration: underline;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
  font-size: inherit;

  &::before {
    width: 16px;
    height: 16px;
    color: inherit;
    background-color: ${({ theme }) => theme.colors.typography100};
  }
`;

export const CheckboxText = styled.span<{ centered?: boolean }>`
  display: inline-block;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const StyledError = styled(Error)`
  width: 100%;
`;
