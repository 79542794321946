import { ButtonWrapper, EmojiBlock, StyledEmoji, Text } from './styled';

export type AnswerButtonProps = {
  emoji?: string;
  className?: string;
  text: string;
  onClick: () => void;
};

const AnswerButton = ({ className, emoji, text, onClick }: AnswerButtonProps) => {
  return (
    <ButtonWrapper withEmoji={!!emoji} className={className} onClick={onClick}>
      {!!emoji && (
        <EmojiBlock>
          <StyledEmoji height={38} width={38} emoji={emoji} />{' '}
        </EmojiBlock>
      )}
      <Text>{text}</Text>
    </ButtonWrapper>
  );
};

export default AnswerButton;
