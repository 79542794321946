import styled from 'styled-components';
import {
  ContainerInnerStyled,
  SlideStyled,
  SlidesStyled,
} from 'core/common/components/WheelPicker';

export const CylinderWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 30px;

  &::before {
    position: absolute;
    top: calc(50% + 4px);
    left: calc(50% - 12px);
    display: flex;
    width: 300px;
    height: 37px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.typography500};
    opacity: 0.15;
    transform: translate(-50%, -50%);
    user-select: none;
    content: '';
  }

  ${ContainerInnerStyled} {
    height: 35px;
  }

  ${SlidesStyled} {
    perspective: 700px;
  }

  ${SlideStyled} {
    font-size: 18px;
  }
`;

export const PickerItemStyled = styled.div`
  width: 50px;
  height: 170px;
`;
