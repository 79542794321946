import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import UpsellsHeader from 'core/common/components/UpsellsHeader';
import { UpsellsScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionPayment from './SectionPayment';
import {
  ButtonStyled,
  ButtonWrapperStyled,
  ContentStyled,
  RootStyled,
  RouteLinkStyled,
} from './styled';

const UpsellsScreen = ({
  content,
  isPurchaseLoading,
  selectedProduct,
  upsells,
  resignFormLoaded,
  onGetReport,
  onSkip,
  selectProduct,
  paymentError,
  onClearPaymentStatus,
}: UpsellsScreenProps) => {
  const { title, subtitle, bannerTitle, bannerText, bannerEmoji, footerText, button, link } =
    content;

  return (
    <RootStyled>
      <Header buttonBack={false} />
      <ContentStyled>
        <UpsellsHeader
          title={title}
          subtitle={subtitle}
          bannerTitle={bannerTitle}
          bannerText={bannerText}
          bannerEmoji={bannerEmoji}
          shouldDisplayBanner
        />
        {!!upsells.length && (
          <RenderOnlyOnClient>
            <SectionPayment
              selectedProduct={selectedProduct}
              selectProduct={selectProduct}
              upsells={upsells}
            />
          </RenderOnlyOnClient>
        )}
        <ButtonWrapperStyled>
          <ButtonStyled
            onClick={onGetReport}
            disabled={isPurchaseLoading}
            loading={isPurchaseLoading}
          >
            {button}
          </ButtonStyled>
          <RouteLinkStyled disabled={isPurchaseLoading} onClick={onSkip}>
            {link}
          </RouteLinkStyled>
        </ButtonWrapperStyled>
        <SectionFooter description={footerText} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onGetReport}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </ContentStyled>
    </RootStyled>
  );
};

export default UpsellsScreen;
