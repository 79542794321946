import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentNatalChartV2Screen from 'core/common/components/QuizScreens/TrialPaymentNatalChartV2';
import { useCountdown, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentNatalChartV2ScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { getEmail } from 'core/user/store';

type TrialPaymentNatalChartV2Props = {
  id: string;
};

const TrialPaymentNatalChartV2 = ({ id }: TrialPaymentNatalChartV2Props) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.NATAL_CHART });

  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  const { updateOffer, selectedOffer, timeUpOffer, defaultOffers } = useOffers();

  const defaultOffer = selectedOffer ?? defaultOffers[0];

  const { analyticsService } = useServices();

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const isTimeUp = !+specialPlanCountdown;

  const offer = isTimeUp ? timeUpOffer : defaultOffer;

  useEffect(() => {
    if (!offer) return;

    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      trialPrice: offer.prices.trialPrice,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  const getReport = useCallback(() => {
    if (!offer) return;

    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      trialPrice: offer.prices.trialPrice,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  const paymentSucceed = useCallback(() => {
    next();
  }, [next]);

  const onGetReport = () => {
    setIsPaymentModalOpen(true);
    getReport();
  };

  const onClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <TrialPaymentNatalChartV2Screen
      onSucceed={paymentSucceed}
      onClose={onClose}
      onGetReport={onGetReport}
      isPaymentModalOpen={isPaymentModalOpen}
      timeLeft={specialPlanCountdown}
      offer={offer}
      timeUpOffer={timeUpOffer}
      {...(screenAttributes as TrialPaymentNatalChartV2ScreenAttributes)}
    />
  );
};

export default TrialPaymentNatalChartV2;
