import styled from 'styled-components';
import { StepperVariant } from './types';

export const StepsWrapperStyled = styled.ul<{ variant?: StepperVariant }>`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 708px;
  margin: 35px auto 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 56px;
    margin-bottom: 38px;
  }

  ${({ theme, variant = 'default' }) => `
    ${LineStyled} {
      background-color: ${
        variant === 'light' ? theme.colors.typography400 : theme.colors.typography200
      }
    }

    ${StepLabelStyled} {
      color: ${variant === 'light' ? theme.colors.typography100 : theme.colors.typography600};
    }

    ${StepStyled} {
      border-color: ${
        variant === 'light' ? theme.colors.typography400 : theme.colors.typography300
      };
      background: ${variant === 'light' ? theme.colors.black : theme.colors.themeBackground}
    }

    ${ProgressLineStyled} {
      background: ${
        variant === 'light' ? theme.colors.typography100 : theme.gradients.secondaryLight
      }
    }
  `}
`;

export const LineStyled = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.typography200};
  transform: translateY(-50%);
`;

export const StepLabelStyled = styled.span`
  position: absolute;
  top: -5px;
  left: 50%;
  min-width: 60px;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
  transform: translate(-50%, -100%);

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-width: 80px;
    font-size: 14px;
  }
`;

export const StepStyled = styled.li<{ active: boolean; completed: boolean }>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: ${({ completed }) => (completed ? '-2px' : '0')};
  border: ${({ completed, active, theme }) =>
    active || completed ? 'none' : `2px solid ${theme.colors.typography300}`};
  border-radius: 50%;

  ${StepLabelStyled} {
    font-weight: ${({ completed, active }) => (completed || active ? '600' : '400')};
  }

  &:first-of-type ${StepLabelStyled} {
    left: 0;
    text-align: left;
    transform: translate(0, -100%);
  }

  &:last-of-type ${StepLabelStyled} {
    right: 0;
    left: unset;
    text-align: right;
    transform: translate(0, -100%);
  }
`;

export const ProgressLineStyled = styled.div<{ progress: number }>`
  position: absolute;
  top: 50%;
  width: ${({ progress }) => progress}%;
  height: 2px;
  background: ${({ theme }) => theme.gradients.secondaryLight};
  transform: translateY(-50%);
`;
