import HelloMoonCompatibilityScreen from 'core/common/components/QuizScreens/HelloMoonCompatibility';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { HelloMoonCompatibilityScreenAttributes } from 'core/funnel/entities';

type HelloMoonCompatibilityProps = {
  id: string;
};

const HelloMoonCompatibility = ({ id }: HelloMoonCompatibilityProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <HelloMoonCompatibilityScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as HelloMoonCompatibilityScreenAttributes)}
    />
  );
};

export default HelloMoonCompatibility;
