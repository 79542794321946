import { useEffect, useState } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellsPalmistryScreen from 'core/common/components/QuizScreens/UpsellsPalmistry';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellsPalmistryScreenAttributes } from 'core/funnel/entities';
import { useGetActualPaymentPlans } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';
import { useUserLocation } from 'core/user/hooks';

type UpsellsPalmistryProps = {
  id: string;
};

const UpsellsPalmistry = ({ id }: UpsellsPalmistryProps) => {
  const [selectedProduct, setSelectedProduct] = useState('');

  const { next, screenAttributes } = useQuizStep(id);
  const { palmistryUpsellsPlan: plans } = useGetActualPaymentPlans();
  const { isEuropean } = useUserLocation();
  const { createAccount } = useCreateAskNebulaAccount();
  const { analyticsService } = useServices();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    recurringPaymentRequest(selectedProduct);
  };

  const goNext = () => {
    createAccount();
    next();
  };

  const skip = () => {
    analyticsService.upsellLpSkip();
    goNext();
  };

  const selectProduct = (product: string) => {
    setSelectedProduct(product);
  };

  useEffect(() => {
    const [firstUpsell] = Object.values(plans).filter(({ order }) => order === 1);

    if (!firstUpsell.value) {
      return;
    }

    setSelectedProduct(firstUpsell.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEuropean]);

  useEffect(() => {
    analyticsService.upsellLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellLpGetReport(selectedProduct);
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  return (
    <UpsellsPalmistryScreen
      onGetReport={getReport}
      onSkip={skip}
      isLoading={isPurchaseLoading}
      selectedProduct={selectedProduct}
      selectProduct={selectProduct}
      plans={plans}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellsPalmistryScreenAttributes)}
    />
  );
};

export default UpsellsPalmistry;
