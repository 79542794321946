import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import {
  GuaranteeBlockStyled,
  GuaranteeContentStyled,
  MedalImageStyled,
  TextStyled,
  TitleStyled,
} from './styled';

type SectionGuaranteeProps = {
  title: string;
  firstText: string;
  secondText: string;
  image: FunnelImage;
};

const SectionGuarantee = ({ title, firstText, secondText, image }: SectionGuaranteeProps) => {
  return (
    <GuaranteeBlockStyled>
      <MedalImageStyled src={image.src} alt={image.alt} width={54} height={57} quality={100} />
      <GuaranteeContentStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <TextStyled dangerouslySetInnerHTML={prepareHtml(firstText)} />
        <TextStyled dangerouslySetInnerHTML={prepareHtml(secondText)} />
      </GuaranteeContentStyled>
    </GuaranteeBlockStyled>
  );
};

export default SectionGuarantee;
