import { TextWithEmoji } from 'core/funnel/entities';
import { Emoji, EmojiCircle, List, ListItem, ListItemText, Title, BenefitsBlock } from './styled';

type BenefitsSectionProps = {
  title: string;
  benefits: Array<TextWithEmoji>;
};

const BenefitsSection = ({ title, benefits }: BenefitsSectionProps) => {
  return (
    <BenefitsBlock>
      <Title>{title}</Title>
      <List>
        {benefits.map(({ text, emoji }) => (
          <ListItem key={text}>
            <EmojiCircle>
              <Emoji emoji={emoji} />
            </EmojiCircle>
            <ListItemText>{text}</ListItemText>
          </ListItem>
        ))}
      </List>
    </BenefitsBlock>
  );
};

export default BenefitsSection;
