import Backgrounds from './Backgrounds';
import Emojis from './Emojis';
import Images from './Images';
import { useNextScreensAttributes } from './useNextScreensAttributes';

type ImagesPreloaderProps = {
  nextScreenId: string;
};

const ImagesPreloader = ({ nextScreenId }: ImagesPreloaderProps) => {
  const attributes = useNextScreensAttributes(nextScreenId);

  return (
    <>
      <Emojis attributes={attributes} />
      <Images attributes={attributes} />
      <Backgrounds attributes={attributes} />
    </>
  );
};

export default ImagesPreloader;
