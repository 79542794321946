import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Title from 'core/common/components/Title';
import MultipleAnswerButtonComponent from './MultipleAnswerButton';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 596px;
  margin-right: auto;
  margin-left: auto;
`;

export const FixedButtonBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: ${({ theme }) => theme.colors.themeBackground};

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: static;
    padding-top: 48px;
  }
`;

export const ButtonBlockSpace = styled.div`
  height: 112px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-bottom: 27px;
  border-radius: 16px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 708px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  max-width: 330px;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 360px;
    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Subtitle = styled.p`
  max-width: 330px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 464px;
    font-size: 18px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: center;
    margin-top: 32px;
  }
`;

export const Text = styled.p`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
`;

export const Item = styled.li`
  width: 100%;
  max-width: calc(50% - 8px);
  margin-right: 8px;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: calc(50% - 12px);
    max-width: 140px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
`;

export const MultipleAnswerButton = styled(MultipleAnswerButtonComponent)`
  max-width: 100%;
  box-shadow: unset;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 140px;
  }
`;
