import PlaceScreen from 'core/common/components/QuizScreens/Place';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { PlaceScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type PlaceProps = {
  id: string;
};

const Place = ({ id }: PlaceProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { locationDataKey, dataKey } = screenAttributes as PlaceScreenAttributes;

  const initialLocation =
    typeof userPreferences[locationDataKey as keyof UserProfile] === 'string'
      ? (userPreferences[locationDataKey as keyof UserProfile] as string)
      : '';

  const initialCoordinates = Array.isArray(userPreferences[dataKey as keyof UserProfile])
    ? (userPreferences[dataKey as keyof UserProfile] as Array<number>)
    : [];

  const handleSubmit = async (coordinates: number[], location: string) => {
    updateUserPreferences(locationDataKey, location);
    updateUserPreferences(dataKey, coordinates);
    await next();
  };

  return (
    <PlaceScreen
      onBack={back}
      onSubmit={handleSubmit}
      initialLocation={initialLocation}
      initialCoordinates={initialCoordinates}
      step={step}
      {...(screenAttributes as PlaceScreenAttributes)}
    />
  );
};

export default Place;
