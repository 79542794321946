import styled from 'styled-components';

export const PartnersBlock = styled.div`
  margin-top: 32px;
`;

export const Title = styled.h3`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;
