import { SectionWhatYouGetAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  ListStyled,
  ListItemStyled,
  IconStyled,
  TextStyled,
  EmojiStyled,
} from './styled';

type SectionWhatYouGetProps = {
  content: SectionWhatYouGetAttributes['content'];
  className?: string;
};

const SectionWhatYouGet = ({ content, className }: SectionWhatYouGetProps) => {
  const { title, list, listDecorationEmoji } = content;

  return (
    <WrapperStyled className={className}>
      {title && <TitleStyled>{title}</TitleStyled>}
      <ListStyled>
        {list.map(({ icon, text }) => (
          <ListItemStyled key={text}>
            <IconStyled name={icon} width={24} height={24} />
            <TextStyled>{text}</TextStyled>
          </ListItemStyled>
        ))}
        {listDecorationEmoji && <EmojiStyled>{listDecorationEmoji}</EmojiStyled>}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionWhatYouGet;
