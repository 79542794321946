import { ReactNode, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { CarouselSettings } from 'core/common/components/Carousel';
import { SECOND } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage, SectionReviewsCarouselAttributes } from 'core/funnel/entities';
import { WrapperStyled, TitleStyled, CarouselSlideStyled, CarouselStyled } from './styled';

type SectionReviewsCarouselProps = {
  content: SectionReviewsCarouselAttributes['content'];
  settings?: CarouselSettings;
  shouldPlayAnimation?: boolean;
  className?: string;
  renderReviewCard: (props: {
    date: string;
    review: string;
    userName: string;
    userAvatar?: FunnelImage;
    verified?: {
      icon: FunnelImage;
      text: string;
    };
  }) => ReactNode;
};

const defaultCarouselSettings: CarouselSettings = {
  speed: 600,
  infinite: false,
  slidesToShow: 1.1,
  arrows: false,
  pauseOnHover: false,
  autoplaySpeed: 5 * SECOND,
};

const SectionReviewsCarousel = ({
  content,
  className,
  shouldPlayAnimation = false,
  settings = defaultCarouselSettings,
  renderReviewCard,
}: SectionReviewsCarouselProps) => {
  const { title, list } = content;

  const mergedSettings = { ...defaultCarouselSettings, ...settings };

  const ref = useRef<Slider>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (shouldPlayAnimation) {
      ref.current.slickPlay();
      return;
    }

    ref.current.slickPause();
  }, [shouldPlayAnimation, ref]);

  return (
    <WrapperStyled className={className}>
      {title && <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />}
      <CarouselStyled {...mergedSettings} ref={ref}>
        {list.map((review) => (
          <CarouselSlideStyled key={review.userName}>
            {renderReviewCard(review)}
          </CarouselSlideStyled>
        ))}
      </CarouselStyled>
    </WrapperStyled>
  );
};

export default SectionReviewsCarousel;
