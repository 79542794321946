import styled from 'styled-components';

export const Wrapper = styled.div`
  .countryMenuWrapper {
    max-height: 245px !important;
    margin-top: 6px !important;
    border-radius: 10px !important;
    background: var(--typography-100) !important;
  }

  .countryMenu {
    padding: 20px 15px !important;
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 90px;
  height: 100%;
  margin-right: 8px;
  padding: 8px 12px;
  border-radius: 32px;
  background-color: rgba(224, 224, 224, 0.4);
  cursor: pointer;

  .countryFlag {
    margin-right: 5px;
  }
`;

export const CountryMenuItem = styled.li`
  padding-bottom: 8px;
  cursor: pointer;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const CountryOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 135%;
  color: var(--typography-900);

  .countryFlag {
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 28px;
  }
`;

export const CountryCallingCode = styled.span`
  flex-shrink: 0;
  min-width: 44px;
  margin-right: 6px;
`;
