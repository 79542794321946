import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import { AnswerNatalChartScreenProps } from 'core/funnel/entities';
import {
  Container,
  Content,
  Emoji,
  EmojiBorder,
  Image,
  ImageDesktop,
  Item,
  List,
  StyledTitle,
  Text,
} from './styled';

const AnswerNatalChartScreen = ({
  onAnswerClick,
  onBack,
  step,
  content,
  totalSteps,
}: AnswerNatalChartScreenProps) => {
  const { title, answers, backgroundImage } = content;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} onBack={onBack} />
      <Container>
        <StyledTitle dangerouslyInnerHTML={title} />
        <Content>
          <List>
            {answers.map(({ text, emoji, value }) => (
              <Item key={text} onClick={() => onAnswerClick(value)}>
                <EmojiBorder>
                  {!!emoji && <Emoji emoji={emoji} />}
                  <Text key={text}>{text}</Text>
                </EmojiBorder>
              </Item>
            ))}
          </List>
        </Content>
      </Container>
      {backgroundImage && (
        <>
          <Image
            src={backgroundImage.mobileImage.src}
            alt={backgroundImage.mobileImage.alt}
            width={297}
            height={297}
            priority
          />
          <ImageDesktop
            src={backgroundImage.desktopImage.src}
            alt={backgroundImage.mobileImage.alt}
            width={599}
            height={599}
            priority
          />
        </>
      )}
    </>
  );
};

export default AnswerNatalChartScreen;
