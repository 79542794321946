import styled from 'styled-components';

export const PhoneInput = styled.input`
  flex-grow: 1;
  height: 100%;
  padding: 8px 12px;
  border-radius: 32px;
  font-size: 16px;
  line-height: 171%;
  color: ${({ theme }) => theme.colors.typography900};
  background-color: rgba(224, 224, 224, 0.4);

  &::placeholder {
    line-height: 171%;
    color: ${({ theme }) => theme.colors.typography500};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.typography500};
    background-color: ${({ theme }) => theme.colors.typography200};
  }
`;
