import styled from 'styled-components';
import Image from 'core/common/components/Image';

const slideBorderRadius = 32;
const slideBorderWidth = 2;

export const CaptionWrapperStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 8px 16px;
  border-top-left-radius: ${slideBorderRadius}px;
  border-bottom-right-radius: ${slideBorderRadius - slideBorderWidth}px;
`;

export const WrapperStyled = styled.div<{ index: number }>`
  position: relative;
  width: 100%;
  padding-top: 74%;
  border: ${slideBorderWidth}px solid transparent;
  border-radius: ${slideBorderRadius}px;
  background-clip: padding-box;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -${slideBorderWidth}px;
    border-radius: inherit;
    background: ${({ theme, index }) => {
      switch (index) {
        case 1:
          return theme.gradients.geminiGradient;
        case 2:
          return theme.colors.primarySagitarius;
        case 3:
          return theme.gradients.aquarius;
        default:
          return theme.gradients.primary;
      }
    }};
    content: '';
  }

  ${CaptionWrapperStyled} {
    background: ${({ theme, index }) => {
      switch (index) {
        case 1:
          return theme.gradients.geminiGradient;
        case 2:
          return theme.colors.primarySagitarius;
        case 3:
          return theme.gradients.aquarius;
        default:
          return theme.gradients.primary;
      }
    }};
  }
`;

export const ImageStyled = styled(Image)`
  border-radius: ${slideBorderRadius - slideBorderWidth}px;
  object-fit: cover;
  object-position: top;
`;

export const CaptionStyled = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const StrikethroughCaptionStyled = styled.span`
  margin-right: 4px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography200};
`;
