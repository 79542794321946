import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 706px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  margin-top: 40px;
  font-size: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  width: 100%;
  max-width: 708px;
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const Image = styled(ImageComponent)`
  position: absolute;
  bottom: 0;
  left: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ImageDesktop = styled(Image)`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 330px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
`;

export const Item = styled.li``;

export const EmojiBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 20px;
  padding: 0 16px;
  border: ${(props) => `1px solid ${props.theme.colors.typography200}`};
  border-radius: 16px;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.themeBackgroundLight};
  box-shadow: 2px 2px 6px 0 rgba(84, 60, 151, 0.25);
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }
`;

export const Text = styled.span`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Emoji = styled(EmojiComponent)`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;
