import GenderV3Screen from 'core/common/components/QuizScreens/GenderV3';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { GenderV3ScreenAttributes } from 'core/funnel/entities';

type GenderV3Props = {
  id: string;
};

const GenderV3 = ({ id }: GenderV3Props) => {
  const { next, back, updateUserPreferences, screenAttributes } = useQuizStep(id);
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { dataKey } = screenAttributes as GenderV3ScreenAttributes;

  const onSelectGender = (value: string) => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    next({ value });
  };

  return (
    <GenderV3Screen
      onGoBack={back}
      onPolicyClick={handlePolicyClick}
      onSelectGender={onSelectGender}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      {...(screenAttributes as GenderV3ScreenAttributes)}
    />
  );
};

export default GenderV3;
