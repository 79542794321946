import heart from 'core/common/assets/images/upsells/gradientHeartWithHeands.svg';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialCreditsPalmistryScreenAttributes } from 'core/funnel/entities';
import {
  ConsultationText,
  ConsultationWrapper,
  Container,
  Description,
  Discount,
  DivideLine,
  ImageStyled,
  OfferBlock,
  OfferDescriptionWrapper,
  OfferPrice,
  OfferPriceTitle,
  OfferTitle,
  OriginalPrice,
  OriginalPriceTitle,
  PriceWrapper,
} from './styled';

type SectionPaymentProps = {
  plan: {
    oldPrice: string;
    discount: string;
    value: string;
  };
  price: string;
  content: TrialCreditsPalmistryScreenAttributes['content']['offer'];
};

const SectionPayment = ({ plan, price, content }: SectionPaymentProps) => {
  const { title, consultation, description, priceTitle, originalPriceTitle, discountTitle } =
    content;

  return (
    <Container>
      <OfferBlock>
        <OfferTitle>{title}</OfferTitle>
        <DivideLine />
        <OfferDescriptionWrapper>
          <ConsultationWrapper>
            <ImageStyled
              src={heart}
              alt="pictogram of hands holding a heart"
              width={44}
              height={44}
            />
            <ConsultationText dangerouslySetInnerHTML={prepareHtml(consultation)} />
          </ConsultationWrapper>
          <Description>{description}</Description>
        </OfferDescriptionWrapper>
        <DivideLine />
        <PriceWrapper>
          <OfferPriceTitle>{priceTitle} </OfferPriceTitle>
          <OfferPrice>{price}</OfferPrice>
          <br />
          <OriginalPriceTitle>
            {originalPriceTitle} <OriginalPrice>{plan.oldPrice}</OriginalPrice>{' '}
            <Discount>{discountTitle}</Discount>
          </OriginalPriceTitle>
        </PriceWrapper>
      </OfferBlock>
    </Container>
  );
};

export default SectionPayment;
