import accept from 'core/common/assets/images/acceptMark.svg';
import checkmark from 'core/common/assets/images/thankYou/checkmark-background.png';
import AnimatedImage from 'core/common/components/AnimatedImages';
import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { AnimatedImages, ThankYouScreenProps } from 'core/funnel/entities';
import {
  StyledBackground,
  StyledButton,
  StyledConfettiWrapper,
  StyledContainer,
  StyledDescription,
  StyledIcon,
  StyledImage,
  StyledImageDone,
  StyledImageWrapper,
  StyledItem,
  StyledList,
  StyledListText,
  StyledListTitle,
  StyledListWrapper,
  StyledTitle,
  StyledWrapperButton,
} from './styled';

const ThankYouScreen = ({ content, goNext }: ThankYouScreenProps) => {
  return (
    <>
      <Header buttonBack={false} variant="secondary" />
      <StyledBackground />
      {content.confetti && (
        <>
          <StyledConfettiWrapper>
            <AnimatedImage variant={AnimatedImages.CONFETTI} />
          </StyledConfettiWrapper>
          <StyledConfettiWrapper>
            <AnimatedImage variant={AnimatedImages.CONFETTI_WITH_FIREWORK} />
          </StyledConfettiWrapper>
        </>
      )}
      <StyledContainer>
        <StyledImageWrapper>
          <StyledImage src={checkmark} width={70} height={70} alt="" />
          <StyledImageDone src={accept} width={30} height={22} alt="accept" />
        </StyledImageWrapper>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledDescription>{content.description}</StyledDescription>
        <StyledList>
          {content.list.map(({ title, text, icon }, index) => (
            <StyledItem key={title}>
              <StyledIcon name={icon} isDone={index === 0} />
              <StyledListWrapper>
                <StyledListTitle>{title}</StyledListTitle>
                <StyledListText dangerouslySetInnerHTML={prepareHtml(text)} />
              </StyledListWrapper>
            </StyledItem>
          ))}
        </StyledList>
        <StyledWrapperButton>
          <StyledButton onClick={goNext}>{content.button}</StyledButton>
        </StyledWrapperButton>
      </StyledContainer>
    </>
  );
};

export default ThankYouScreen;
