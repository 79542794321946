import styled from 'styled-components';

export const BoldStyled = styled.b`
  font-weight: 700;
`;

export const BreakStyled = styled.br``;

export const DescriptionStyled = styled.p`
  font-size: 12px;
  text-align: center;
`;
