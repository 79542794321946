import discount from 'core/common/assets/images/additional-discount/palmistry-discount.png';
import { AdditionalDiscountPalmistryScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Emoji,
  EmojiWrapper,
  Image,
  Item,
  List,
  OldPrice,
  Price,
  PriceWrapper,
  Text,
  Title,
} from './styled';

const AdditionalDiscountPalmistryScreen = ({
  content,
  priceData,
  currencySymbol,
  onGetReport,
}: AdditionalDiscountPalmistryScreenProps) => {
  const { title, list, button } = content;

  const titleWithDiscountPercent = `${title.beforePercent} ${priceData.discountPercent} ${title.afterPercent}`;

  return (
    <>
      <Container>
        <Image src={discount} alt="big discount percent" />
        <Title>{titleWithDiscountPercent}</Title>
        <PriceWrapper>
          <OldPrice>
            {currencySymbol}
            {priceData.oldPrice}
          </OldPrice>
          <Price>
            {currencySymbol}
            {priceData.price}
          </Price>
        </PriceWrapper>
        <List>
          {list.map(({ text, emoji, includePercent }) => {
            const formattedText = includePercent ? `${priceData.discountPercent}${text}` : text;

            return (
              <Item key={text}>
                <EmojiWrapper>
                  <Emoji emoji={emoji} />
                </EmojiWrapper>
                <Text>{formattedText}</Text>
              </Item>
            );
          })}
        </List>
        <Button onClick={onGetReport}>{button}</Button>
      </Container>
    </>
  );
};

export default AdditionalDiscountPalmistryScreen;
