import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { W2APromotionScreenProps } from 'core/funnel/entities';
import {
  ContainerStyled,
  ImageStyled,
  ContentWrapperStyled,
  TitleStyled,
  PromotionListStyled,
  PromotionListWrapperStyled,
  PromotionListTitleStyled,
  EmojiStyled,
  PromotionListItemStyled,
  PromotionListItemBubbleStyled,
  PromotionListItemTextStyled,
  PromotionButtonStyled,
  ScreenBackground,
} from './styled';

const W2APromotionScreen = ({
  content,
  goNext,
  downloadDisabled: loadingLink,
}: W2APromotionScreenProps) => {
  const { image, title, promotionList: list, nextButtonText } = content;

  return (
    <>
      <ScreenBackground />
      <Header buttonBack={false} variant="secondary" />
      <ContainerStyled>
        <ContentWrapperStyled>
          <ImageStyled src={image.src} alt={image.alt} width={image.width} height={image.height} />
          <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
          <PromotionListWrapperStyled>
            <PromotionListTitleStyled dangerouslySetInnerHTML={prepareHtml(list.title)} />
            <PromotionListStyled>
              {list.emoji && <EmojiStyled>{list.emoji}</EmojiStyled>}
              {list.points.map((point, index) => (
                <PromotionListItemStyled key={point}>
                  <PromotionListItemBubbleStyled>{index + 1}</PromotionListItemBubbleStyled>
                  <PromotionListItemTextStyled dangerouslySetInnerHTML={prepareHtml(point)} />
                </PromotionListItemStyled>
              ))}
            </PromotionListStyled>
          </PromotionListWrapperStyled>
        </ContentWrapperStyled>
        <PromotionButtonStyled disabled={loadingLink} onClick={goNext}>
          {nextButtonText}
        </PromotionButtonStyled>
      </ContainerStyled>
    </>
  );
};

export default W2APromotionScreen;
