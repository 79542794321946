import { useState } from 'react';
import Header from 'core/common/components/Header';
import { PaymentDialog } from 'core/common/components/Payment';
import SectionAdvantages from 'core/common/components/QuizScreensSections/SectionAdvantages';
import Banner from 'core/common/components/QuizScreensSections/SectionBanner';
import SectionFaq from 'core/common/components/QuizScreensSections/SectionFaq';
import SectionInfo from 'core/common/components/QuizScreensSections/SectionInfo';
import SectionInsights from 'core/common/components/QuizScreensSections/SectionInsights';
import SectionMentionedIn from 'core/common/components/QuizScreensSections/SectionMentionedIn';
import SectionMoneyBackGuarantee from 'core/common/components/QuizScreensSections/SectionMoneyBackGuarantee';
import SectionQuote from 'core/common/components/QuizScreensSections/SectionQuote';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailMarketingPalmistryScreenProps } from 'core/funnel/entities';
import { SectionType, SectionAttributesUnionType } from 'core/funnel/entities/Section';
import {
  BackgroundStyled,
  ContentStyled,
  DescriptionStyled,
  SectionBenefitsStyled,
  SectionOfferStyled,
  SectionReviewsStyled,
  SectionBadgeStyled,
} from './styled';

const EmailMarketingPalmistryScreen = ({
  offer,
  sectionEnd,
  sections,
  onGetReport,
  paymentSucceed,
}: EmailMarketingPalmistryScreenProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getReport = (analyticsKey?: string) => {
    setIsModalOpen(true);

    if (analyticsKey) {
      return onGetReport(analyticsKey);
    }
  };

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.BANNER:
        return (
          <Banner
            key={section.sectionType + index}
            content={section.content}
            offer={offer}
            onClick={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.ADVANTAGES:
        return <SectionAdvantages key={section.sectionType + index} content={section.content} />;
      case SectionType.INFO:
        return <SectionInfo key={section.sectionType + index} content={section.content} />;
      case SectionType.BADGE:
        return <SectionBadgeStyled key={section.sectionType + index} content={section.content} />;
      case SectionType.BENEFITS:
        return (
          <SectionBenefitsStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.OFFER:
        return (
          <SectionOfferStyled
            key={section.sectionType + index}
            offer={offer}
            content={section.content}
            onGetReport={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.REVIEWS:
        return <SectionReviewsStyled key={section.sectionType + index} content={section.content} />;
      case SectionType.QUOTE:
        return <SectionQuote key={section.sectionType + index} content={section.content} />;
      case SectionType.MENTIONED_IN:
        return <SectionMentionedIn key={section.sectionType + index} content={section.content} />;
      case SectionType.INSIDES:
        return <SectionInsights key={section.sectionType + index} content={section.content} />;
      case SectionType.FAQ: {
        return <SectionFaq key={section.sectionType + index} content={section.content} />;
      }
      case SectionType.MONEY_BACK_GUARANTEE: {
        return (
          <SectionMoneyBackGuarantee key={section.sectionType + index} content={section.content} />
        );
      }
      case SectionType.PAYMENT:
        return (
          offer?.offerId && (
            <PaymentDialog
              key={section.sectionType + index}
              isOpen={isModalOpen}
              productId={offer.offerId}
              currency={offer.currency}
              onClose={() => setIsModalOpen((prev) => !prev)}
              onPaymentSuccess={paymentSucceed}
            >
              <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(section.content.text)} />
            </PaymentDialog>
          )
        );

      default:
        return null;
    }
  };

  return (
    <RenderOnlyOnClient>
      <BackgroundStyled />
      <Header buttonBack={false} />
      <ContentStyled>{sections.map((item, index) => renderSection(item, index))}</ContentStyled>
      {sectionEnd}
    </RenderOnlyOnClient>
  );
};

export default EmailMarketingPalmistryScreen;
