import { useMemo } from 'react';
import { useServices } from 'core/common/hooks';
import { normalizeDate } from 'core/common/utils/date';
import { ZodiacWheelShape } from './ZodiacWheelShape';
import { zodiacList } from './data';
import {
  WheelArrowStyled,
  WheelCircleStyled,
  WheelHighlighterStyled,
  WheelStyled,
  WheelWrapperStyled,
  ZodiacElementStyled,
} from './styled';

type ZodiacWheelProps = {
  date: {
    month: string;
    day: string;
    year: string;
  };
};

export const ZodiacWheel = ({ date }: ZodiacWheelProps) => {
  const { zodiacService } = useServices();

  const currentZodiac = zodiacService.getZodiacSignByDate(normalizeDate(date, 'MMM D YYYY'));

  const activeWheelElement = useMemo(() => {
    return zodiacList.find(({ zodiacType }) => zodiacType === currentZodiac) || zodiacList[0];
  }, [currentZodiac]);

  return (
    <WheelWrapperStyled>
      <WheelHighlighterStyled />
      <WheelCircleStyled />
      <WheelArrowStyled />
      <WheelStyled
        xmlns="http://www.w3.org/2000/svg"
        viewBox="55.32 53.43 294.38 294.89"
        angle={activeWheelElement.rotateAngle}
      >
        <ZodiacWheelShape />
        {zodiacList.map(({ zodiacType, path }) => (
          <ZodiacElementStyled
            key={zodiacType}
            d={path}
            active={zodiacType === activeWheelElement.zodiacType}
          />
        ))}
      </WheelStyled>
    </WheelWrapperStyled>
  );
};
