import { useState } from 'react';
import fallingStarImage from 'core/common/assets/images/humanDesign/fallingStar.png';
import lightningImage from 'core/common/assets/images/humanDesign/lightning.png';
import scalesImage from 'core/common/assets/images/humanDesign/scales.png';
import starsImage from 'core/common/assets/images/humanDesign/stars.png';
import sunImage from 'core/common/assets/images/humanDesign/sun.png';
import tornadoImage from 'core/common/assets/images/humanDesign/tornado.png';
import QuizBackground from 'core/common/components/QuizBackground';
import SectionGuaranteeV2 from 'core/common/components/QuizScreensSections/SectionGuaranteeV2';
import { SECOND } from 'core/common/constants';
import { useInView } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentOneTimeOfferHumanDesignScreenProps } from 'core/funnel/entities';
import RenderOnlyOnClient from '../../RenderOnlyOnClient';
import CollapsibleSection from './CollapsibleSection';
import Disclaimer from './Disclaimer';
import Footer from './Footer';
import Header from './Header';
import HeroSection from './HeroSection';
import HumanDesignDetails from './HumanDesignDetails';
import { DesignDataListItemStyled, DesignDataListStyled } from './HumanDesignDetails/styled';
import LifeStrategy from './LifeStrategy';
import OneTimeOffer from './OneTimeOffer';
import PageLoader from './PageLoader';
import Partners from './Partners';
import Reviews from './Reviews';
import SectionPayment from './SectionPayment';
import {
  ContentStyled,
  FloatingButtonWrapperStyled,
  FloatingCTAButtonStyled,
  RootStyled,
} from './styled';

const headerHeightPx = 48;
const offerScrollOffsetPx = -140;
const animationDurationSec = 2;
const hideLoaderDelaySec = 3.5;
const offerBlockId = 'offer-block';
const topPageBlockId = 'top-page-block';

const collapsibleSectionsImages = [
  sunImage,
  starsImage,
  scalesImage,
  lightningImage,
  tornadoImage,
  fallingStarImage,
];

const TrialPaymentOneTimeOfferHumanDesignScreen = ({
  content,
  timeLeft,
  offer,
  report,
  timeUpOffer,
  getReport,
  goNext,
}: TrialPaymentOneTimeOfferHumanDesignScreenProps) => {
  const {
    header,
    heroSection,
    userProfile,
    buttonText,
    oneTimeOffer,
    disclaimer,
    lifeStrategy,
    reviews,
    partners,
    moneyBackGuarantee,
    footer,
    loaderText,
  } = content;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(true);

  const { ref: paymentButtonRef, inView: isPaymentButtonInView } = useInView({
    threshold: 0,
  });

  const onGetReport = () => {
    setIsPaymentModalOpen(true);
    getReport();
  };

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffsetPx);
  };

  const onPaymentDialogClose = () => {
    setIsPaymentModalOpen(false);
  };

  const onChartLoaded = () => {
    setTimeout(() => {
      scrollToBlock(topPageBlockId);
    }, animationDurationSec * SECOND);

    setTimeout(() => {
      setShouldDisplayLoader(false);
    }, (animationDurationSec + hideLoaderDelaySec) * SECOND);
  };

  const isTimeUp = timeLeft <= 0;

  if (!timeUpOffer || !offer || !report) {
    return null;
  }

  return (
    <RootStyled id={topPageBlockId}>
      <QuizBackground variant="semiLight" />
      {shouldDisplayLoader && (
        <PageLoader
          animationDurationInSec={animationDurationSec}
          hideLoaderDelayInSec={hideLoaderDelaySec}
          text={loaderText}
        />
      )}
      <Header
        height={headerHeightPx}
        discountValue={header.discountValue}
        discountExpiresIn={header.discountExpiresIn}
        timeLeft={timeLeft}
      />
      <ContentStyled offsetTop={headerHeightPx}>
        <HeroSection
          title={heroSection.title}
          text={heroSection.text}
          rating={heroSection.rating}
        />
        <HumanDesignDetails
          {...userProfile}
          chartUrl={report.chartUrl}
          subtitle={report.chartData.type.option}
          onChartLoaded={onChartLoaded}
        />
        <DesignDataListStyled>
          {Object.values(report.chartData).map(({ id, name, option, description }, idx) => (
            <DesignDataListItemStyled key={id}>
              <CollapsibleSection
                caption={name}
                title={option}
                text={description}
                button={buttonText}
                onMoveToOffer={moveToOffer}
                image={collapsibleSectionsImages[idx]}
              />
            </DesignDataListItemStyled>
          ))}
        </DesignDataListStyled>
        <OneTimeOffer
          oneTimeOffer={oneTimeOffer}
          id={offerBlockId}
          offer={offer}
          timeUpOffer={timeUpOffer}
          onGetReport={onGetReport}
          timeLeft={timeLeft}
          ref={paymentButtonRef}
        />
        <Disclaimer disclaimer={disclaimer} />
        <LifeStrategy
          title={lifeStrategy.title}
          withStrategy={lifeStrategy.withStrategy}
          withoutStrategy={lifeStrategy.withoutStrategy}
          button={lifeStrategy.button}
          onMoveToOffer={moveToOffer}
        />
        <Reviews title={reviews.title} list={reviews.list} />
        <Partners title={partners.title} />
        <SectionGuaranteeV2 content={moneyBackGuarantee} />
        <Footer {...footer} />
        {!isPaymentButtonInView && (
          <FloatingButtonWrapperStyled>
            <FloatingCTAButtonStyled onClick={moveToOffer}>{buttonText}</FloatingCTAButtonStyled>
          </FloatingButtonWrapperStyled>
        )}
      </ContentStyled>
      <RenderOnlyOnClient>
        <SectionPayment
          onClose={onPaymentDialogClose}
          onPaymentSuccess={goNext}
          isTimeUp={isTimeUp}
          isOpen={isPaymentModalOpen}
          offer={offer}
        />
      </RenderOnlyOnClient>
    </RootStyled>
  );
};

export default TrialPaymentOneTimeOfferHumanDesignScreen;
