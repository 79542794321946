import Image from 'core/common/components/Image';
import { ZodiacContainer } from './styled';

export type ZodiacImagesBlockProps = {
  image: string;
  zodiac: string;
};

const ZodiacImagesBlock = ({ image, zodiac }: ZodiacImagesBlockProps) => {
  return (
    <ZodiacContainer>
      <Image height={220} width={115} src={image} alt={zodiac} objectFit="contain" />
    </ZodiacContainer>
  );
};

export default ZodiacImagesBlock;
