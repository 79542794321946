import logoPng from 'core/common/assets/images/trialPayment/nebulaLogoBig.png';
import { TrialPaymentIntroOfferScreenAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import SectionGoal from '../SectionGoal';
import SubscriptionBlock from '../SubscriptionBlock';
import {
  AccessDescription,
  BlurInnerContainer,
  BlurOuterContainer,
  BlurredReportBody,
  BlurredReportImpulseButton,
  BlurredReportTopPart,
  BlurredReportWrapper,
  ContentBlock,
  ContentText,
  ContentTitle,
  Item,
  ItemText,
  LogoImage,
  LogoImageBlock,
  NumberedList,
  ReportTitle,
  UserInfoBlock,
  ZodiacImage,
  ZodiacImageBlock,
} from './styled';

export type SectionBlurredReportProps = {
  isTimeUp: boolean;
  zodiacSign: string;
  goal: TrialPaymentIntroOfferScreenAttributes['content']['goal'];
  report: TrialPaymentIntroOfferScreenAttributes['content']['report'];
  subscription: TrialPaymentIntroOfferScreenAttributes['content']['subscriptionBlock'];
  zodiacImage: string;
  guaranteeText: string;
  onGetReport: () => void;
  onPlanChanged: (plan: Offer) => void;
  onAllPlansLoaded: VoidFunction;
  isAllPlansLoaded: boolean;
  chosenPlan: Offer;
  selectedPlan: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offerNodeId?: string;
};

const SectionBlurredReport = ({
  report,
  zodiacImage,
  zodiacSign,
  isTimeUp,
  onGetReport,
  goal,
  subscription,
  guaranteeText,
  onPlanChanged,
  onAllPlansLoaded,
  isAllPlansLoaded,
  chosenPlan,
  selectedPlan,
  defaultOffers,
  timeUpOffers,
  offerNodeId,
}: SectionBlurredReportProps) => {
  const {
    title,
    contentTitle,
    contentPoints,
    personalityTitle,
    personalityDescription,
    accessDescription,
    getAccess,
  } = report;

  return (
    <BlurredReportWrapper>
      <BlurredReportBody>
        <BlurredReportTopPart>
          <ReportTitle>{title}</ReportTitle>
          <SectionGoal title={goal.title} relationshipGoal={goal.description} />
          <SubscriptionBlock
            isTimeUp={isTimeUp}
            onGetReport={onGetReport}
            subscription={subscription}
            guaranteeText={guaranteeText}
            onPlanChanged={onPlanChanged}
            onAllPlansLoaded={onAllPlansLoaded}
            isAllPlansLoaded={isAllPlansLoaded}
            chosenPlan={chosenPlan}
            selectedPlan={selectedPlan}
            defaultOffers={defaultOffers}
            timeUpOffers={timeUpOffers}
            offerNodeId={offerNodeId}
          />
        </BlurredReportTopPart>
        <UserInfoBlock>
          <LogoImageBlock>
            <LogoImage alt="Nebula logo" src={logoPng.src} />
          </LogoImageBlock>
          <ZodiacImageBlock>
            <ZodiacImage height={146} src={zodiacImage} alt={zodiacSign} />
          </ZodiacImageBlock>
        </UserInfoBlock>
        <ContentBlock>
          <ContentTitle>{contentTitle}</ContentTitle>
          <NumberedList>
            {contentPoints.map((text) => (
              <Item key={text}>
                <ItemText>{text}</ItemText>
              </Item>
            ))}
          </NumberedList>
          <ContentTitle>{personalityTitle}</ContentTitle>
          <ContentText>{personalityDescription}</ContentText>
        </ContentBlock>
        <BlurOuterContainer>
          <BlurInnerContainer>
            <AccessDescription>{accessDescription}</AccessDescription>
            <BlurredReportImpulseButton impulseScale onClick={onGetReport}>
              {getAccess}
            </BlurredReportImpulseButton>
          </BlurInnerContainer>
        </BlurOuterContainer>
      </BlurredReportBody>
    </BlurredReportWrapper>
  );
};

export default SectionBlurredReport;
