import { useTranslation } from 'react-i18next';
import { BASE_ROUTES } from 'core/common/constants';
import { Container, NavLink } from './styled';

const Policy = ({ className = '' }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'agreement',
  });

  return (
    <Container className={className}>
      {t('text')} <NavLink href={BASE_ROUTES.TECH_PRIVACY_POLICY}>{t('policy')}</NavLink>{' '}
      {t('separator')} <NavLink href={BASE_ROUTES.TECH_TERMS}>{t('terms')}</NavLink>
    </Container>
  );
};

export default Policy;
