import styled from 'styled-components';

export const TitleStyled = styled.h1`
  font-size: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const HeroSectionStyled = styled.section`
  margin-right: -15px;
  margin-left: -15px;
  padding: 24px 24px 141px;
  background-color: ${({ theme }) => theme.colors.vibrant500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`;
