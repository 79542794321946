import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 56px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled.h3`
  margin-bottom: 33px;
  font-size: 22px;
  line-height: 145%;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const ImageStyled = styled(Image)`
  margin-bottom: 25px;
`;

export const WrapperWellGoodStyled = styled.div`
  width: 100%;
  text-align: center;
`;
