import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ListItemContent } from '../Profile';
import {
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  ListItemTitleStyled,
  ListItemValueStyled,
  ListItemTextWrapperStyled,
} from './styled';

type UserDataProps = {
  birthDate?: ListItemContent;
  birthTime: ListItemContent;
  birthPlace: ListItemContent;
};

const UserData = ({ birthDate, birthTime, birthPlace }: UserDataProps) => {
  const getFormattedCity = (location: string) => {
    return location.split(',')[0];
  };

  const birthTimeValue = birthTime.value || '00:00:00';

  return (
    <ListStyled>
      {birthDate && (
        <ListItemStyled>
          <ListIconStyled name={birthDate.icon} width={24} height={24} />
          <ListItemTextWrapperStyled>
            <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(birthDate.title)} />
            <ListItemValueStyled dangerouslySetInnerHTML={prepareHtml(birthDate.value)} />
          </ListItemTextWrapperStyled>
        </ListItemStyled>
      )}
      <ListItemStyled>
        <ListIconStyled name={birthTime.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(birthTime.title)} />
          <ListItemValueStyled dangerouslySetInnerHTML={prepareHtml(birthTimeValue)} />
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      <ListItemStyled>
        <ListIconStyled name={birthPlace.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled dangerouslySetInnerHTML={prepareHtml(birthPlace.title)} />
          <ListItemValueStyled
            dangerouslySetInnerHTML={prepareHtml(getFormattedCity(birthPlace.value))}
          />
        </ListItemTextWrapperStyled>
      </ListItemStyled>
    </ListStyled>
  );
};

export default UserData;
