import gragientActiveMarkImage from 'core/common/assets/images/upsells/gradient_active_mark.svg';
import gragientCheckmarkImage from 'core/common/assets/images/upsells/gradient_checkmark.svg';
import Image from 'core/common/components/Image';
import { LANDINGS } from 'core/common/constants';
import { useRouter } from 'core/common/hooks';
import { t } from 'core/localization/utils/getCurrentLanguage';
import { Line, ProgressLine, Step, StepLabel, StepsWrapper } from './styled';

const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.upsells.headerNew';

export const upsellScreens = [
  { title: t(`${tKeyPrefix}.addReport`), screen: LANDINGS.UPSELLS },
  { title: t(`${tKeyPrefix}.unlimitedReadings`), screen: LANDINGS.UNLIMITED_READINGS },
  { title: t(`${tKeyPrefix}.addConsultation`), screen: LANDINGS.TRIAL_CREDITS },
  { title: t(`${tKeyPrefix}.accessProduct`), screen: '' },
];

const Steps = () => {
  const { query } = useRouter();

  const pageId = query.id as string;

  const currentScreenIdx = upsellScreens.findIndex(({ screen }) =>
    pageId?.includes(screen.replace(/^\/+/, '')),
  );

  const currentScreenProgress = (currentScreenIdx / (upsellScreens.length - 1)) * 100;

  return (
    <StepsWrapper>
      {upsellScreens.map(({ title }, idx) => {
        const isActive = idx === currentScreenIdx;
        const isCompleted = idx < currentScreenIdx;

        return (
          <Step key={title} active={isActive} completed={isCompleted}>
            <StepLabel>{title}</StepLabel>

            {isCompleted && <Image src={gragientCheckmarkImage} alt="Completed mark" fill />}
            {isActive && <Image src={gragientActiveMarkImage} alt="Active mark" fill />}
          </Step>
        );
      })}

      <Line />
      <ProgressLine progress={currentScreenProgress} />
    </StepsWrapper>
  );
};

export default Steps;
