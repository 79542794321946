/* eslint max-len: 0 */
export const ZodiacWheelCircle = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 102 61" fill="none">
      <g filter="url(#filter0_d_2551_14434)">
        <ellipse
          cx="51.0562"
          cy="60.8906"
          rx="29.7424"
          ry="29.6426"
          fill="url(#paint0_linear_2551_14434)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2551_14434"
          x="0.836199"
          y="0.531627"
          width="100.44"
          height="100.24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-10.2388" />
          <feGaussianBlur stdDeviation="10.2388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.791845 0 0 0 0 0.774109 0 0 0 0 0.929295 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2551_14434" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2551_14434"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2551_14434"
          x1="51.092"
          y1="31.1955"
          x2="51.092"
          y2="90.4807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1E55" />
          <stop offset="0.25" stopColor="#272463" />
          <stop offset="0.5" stopColor="#3B2F7B" />
          <stop offset="0.75" stopColor="#4F358D" />
          <stop offset={1} stopColor="#6939A2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
