import AspectRatio from 'core/common/components/AspectRatio';
import Image from 'core/common/components/Image';
import { SectionUpsellGuidanceAttributes } from 'core/funnel/entities';
import {
  ImageStyled,
  WrapperStyled,
  TitleStyled,
  ListStyled,
  ListItemStyled,
  ListItemTextStyled,
} from './styled';

export type SectionUpsellGuidanceProps = {
  content: SectionUpsellGuidanceAttributes['content'];
  className?: string;
};

const SectionUpsellGuidance = ({ content, className }: SectionUpsellGuidanceProps) => {
  const { title, list, image } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <ListStyled>
        {list.map(({ icon, text }) => (
          <ListItemStyled key={text}>
            <Image src={icon.src} alt={icon.alt} width={20} height={20} />
            <ListItemTextStyled>{text}</ListItemTextStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
      <AspectRatio width={700} height={375}>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </AspectRatio>
    </WrapperStyled>
  );
};

export default SectionUpsellGuidance;
