import { InputHTMLAttributes } from 'react';
import { generateUUID } from 'core/common/utils/generateUUID';
import { Offer } from 'core/offers/entities';
import { Currency, currencyToCurrencySymbol } from 'core/user/entities';
import {
  CheckedWrapper,
  Checkmark,
  Column,
  HiddenRadio,
  Label,
  MainWrapper,
  MostPopularTitle,
  OldPrice,
  OldPriceWrapper,
  PlanTitle,
  PricePerDayCurrency,
  PricePerDayFloatingPart,
  PricePerDayFloatingPartText,
  PricePerDayFloatingPartWrapper,
  PricePerDayIntegerPart,
  PricePerDayWrapper,
  Radio,
  Subtitle,
  Wrapper,
} from './styled';

type SubscriptionRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  offer: Offer | null;
  planTitle: string;
  priceWithoutDiscount: string;
  isMostPopular?: boolean;
  onPlanChanged: (plan: Offer) => void;
};

// TODO: replace with data from server (when it'll be ready)
enum Period {
  '7 days' = 7,
  '1 week' = 7,
  '28 days' = 30,
  '4 weeks' = 30,
  '12 weeks' = 90,
  '84 days' = 90,
}

type PeriodKeys = keyof typeof Period;

const SubscriptionRadio = ({
  planTitle,
  offer,
  checked,
  priceWithoutDiscount,
  isMostPopular = false,
  onPlanChanged,
  ...restProps
}: SubscriptionRadioProps) => {
  const handleRadioChange = () => {
    if (!offer) return;

    onPlanChanged(offer);
  };

  const calcPricePerDay = () => {
    if (!offer) return '-';

    const price = offer.prices.trialPriceWithVAT;
    const period = offer.trialPeriod;

    return (price / Period[period as PeriodKeys]).toFixed(2);
  };

  const pricePerDay = calcPricePerDay();
  const pricePerDayInteger = Math.floor(+pricePerDay);
  const [, pricePerDayFloat] = pricePerDay.split('.');

  const calcOldPricePerDay = () => {
    if (!offer) return '-';

    const [stringPrice, currency] = priceWithoutDiscount.split(' ');
    const price = +stringPrice;
    const period = offer.trialPeriod;

    return `${(price / Period[period as PeriodKeys]).toFixed(2)} ${currency}`;
  };

  const planPrice = offer?.pricesWithCurrency.trialPriceWithVAT ?? '-';

  return (
    <MainWrapper>
      {isMostPopular && (
        <CheckedWrapper checked={checked}>
          {isMostPopular && <MostPopularTitle>most popular</MostPopularTitle>}
        </CheckedWrapper>
      )}

      <Label isMostPopular={isMostPopular} checked={checked}>
        <HiddenRadio
          type="radio"
          name={`radio-${generateUUID()}`}
          onChange={handleRadioChange}
          checked={checked}
          {...restProps}
        />
        <Radio>
          <Wrapper>
            <Checkmark />
            <Column>
              <PlanTitle>{planTitle}</PlanTitle>
              <Subtitle variant={checked && !isMostPopular ? 'light' : 'dark'}>
                {priceWithoutDiscount && <OldPrice>{priceWithoutDiscount}</OldPrice>} {planPrice}
              </Subtitle>
            </Column>
          </Wrapper>
          <Wrapper>
            <Column>
              <PricePerDayWrapper>
                <PricePerDayCurrency>
                  {currencyToCurrencySymbol[offer?.currency ?? Currency.USD]}
                </PricePerDayCurrency>
                <PricePerDayIntegerPart>{pricePerDayInteger}</PricePerDayIntegerPart>
                <PricePerDayFloatingPartWrapper>
                  <PricePerDayFloatingPart>{pricePerDayFloat}</PricePerDayFloatingPart>
                  <PricePerDayFloatingPartText>per day</PricePerDayFloatingPartText>
                </PricePerDayFloatingPartWrapper>
              </PricePerDayWrapper>
              <OldPriceWrapper>
                {priceWithoutDiscount && <OldPrice>{calcOldPricePerDay()}</OldPrice>}
              </OldPriceWrapper>
            </Column>
          </Wrapper>
        </Radio>
      </Label>
    </MainWrapper>
  );
};

export default SubscriptionRadio;
