import { useState } from 'react';
import { Country } from 'react-phone-number-input';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import PhoneField from 'core/common/components/PhoneField';
import { PhoneNumberPalmistryScreenProps } from 'core/funnel/entities';
import usePhoneNumberPalmistry from './usePhoneNumberPalmistry';
import {
  ButtonsWrapper,
  CompatibilityHeader,
  ConfirmationCheckbox,
  ConfirmationLabelWrapper,
  DesktopImage,
  DoNotMissText,
  GetPersonalizedUpdatesButton,
  GuaranteeText,
  GuaranteeWrapper,
  MainContentWrapper,
  MobileImage,
  NoUpdatesNeededButton,
  PageWrapper,
  PhoneInputError,
  PhoneInputWrapper,
} from './styled';

const PhoneNumberPalmistryScreen = ({ goNext, content }: PhoneNumberPalmistryScreenProps) => {
  const { handleGetPersonalizedUpdatesClick, skip, country } = usePhoneNumberPalmistry(goNext);

  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState<string | null>(null);
  const [approvedReceivingMessages, setApprovedReceivingMessages] = useState(false);

  const handlePhoneNumberChange = (phone: string) => {
    setEnteredPhoneNumber(phone);
    setPhoneValidationError(null);
  };

  const toggleUserMessageReceivingApproval = () => {
    setApprovedReceivingMessages((approved) => !approved);
  };

  return (
    <PageWrapper>
      <Header buttonBack={false} />
      <MobileImage>
        <Image
          src={content.image.mobile.src}
          alt={content.image.mobile.alt}
          width={328}
          height={100}
        />
      </MobileImage>
      <DesktopImage>
        <Image
          src={content.image.desktop.src}
          alt={content.image.desktop.alt}
          width={382}
          height={238}
        />
      </DesktopImage>
      <MainContentWrapper>
        <CompatibilityHeader>{content.title}</CompatibilityHeader>
        <DoNotMissText>{content.subtitle}</DoNotMissText>
        <GuaranteeWrapper>
          <Image src={content.secureIcon.src} alt={content.secureIcon.alt} width={16} height={16} />
          <GuaranteeText>{content.guarantee}</GuaranteeText>
        </GuaranteeWrapper>
        <PhoneInputWrapper error={phoneValidationError}>
          <PhoneField
            onChange={handlePhoneNumberChange}
            defaultCountry={country as Country}
            value={enteredPhoneNumber}
          />
          {phoneValidationError && <PhoneInputError>{phoneValidationError}</PhoneInputError>}
        </PhoneInputWrapper>
        <ConfirmationLabelWrapper>
          <ConfirmationCheckbox
            name="User Receiving Message Approval"
            variant="tertiary"
            checked={approvedReceivingMessages}
            onChange={toggleUserMessageReceivingApproval}
          >
            {content.approve}
          </ConfirmationCheckbox>
        </ConfirmationLabelWrapper>
      </MainContentWrapper>
      <ButtonsWrapper>
        <GetPersonalizedUpdatesButton
          disabled={!enteredPhoneNumber || !approvedReceivingMessages}
          onClick={() =>
            handleGetPersonalizedUpdatesClick(enteredPhoneNumber, {
              validationError: () => setPhoneValidationError(content.notValidNumberMessage),
            })
          }
        >
          {content.accept}
        </GetPersonalizedUpdatesButton>
        <NoUpdatesNeededButton onClick={skip}>{content.decline}</NoUpdatesNeededButton>
      </ButtonsWrapper>
    </PageWrapper>
  );
};

export default PhoneNumberPalmistryScreen;
