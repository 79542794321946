import natalChart from 'core/common/assets/images/natal-chart/natalChart.png';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionBlurredContentAttributes } from 'core/funnel/entities';
import {
  ItemStyled,
  ListStyled,
  TitleStyled,
  ImageStyled,
  WrapperStyled,
  OverlayTextStyled,
  ContentTitleStyled,
  OverlayButtonStyled,
  OverlayWrapperStyled,
} from './styled';

export type SectionBlurredContentProps = {
  content: SectionBlurredContentAttributes['content'];
  onGetReport: () => void;
};

const SectionBlurredContent = ({ onGetReport, content }: SectionBlurredContentProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{content.title}</TitleStyled>
      <ImageStyled src={natalChart} alt="natal chart" width={120} height={120} />
      <ContentTitleStyled>{content.subTitle}</ContentTitleStyled>

      {content.list && (
        <ListStyled>
          {content.list.map((text) => (
            <ItemStyled key={text}>{text}</ItemStyled>
          ))}
        </ListStyled>
      )}

      <OverlayWrapperStyled>
        {content.overlayText && (
          <OverlayTextStyled dangerouslySetInnerHTML={prepareHtml(content.overlayText)} />
        )}
        {content.overlayButton && (
          <OverlayButtonStyled onClick={onGetReport}>{content.overlayButton}</OverlayButtonStyled>
        )}
      </OverlayWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionBlurredContent;
