import { prepareHtml } from 'core/common/utils/prepareHtml';
import { IconNames } from 'core/svg-sprites/entities/sprite';
import Icon from 'core/svg-sprites/ui/Icon';
import {
  BenefitsColumnStyled,
  BenefitsLargeColumnStyled,
  BenefitsListItemStyled,
  BenefitsListStyled,
  BenefitsStyled,
  ButtonStyled,
  CloseButtonStyled,
  ColumnTitleStyled,
  FooterCaptionStyled,
  FooterStyled,
  IconsListItemStyled,
  IconsListStyled,
  ModalStyled,
  TitleStyled,
} from './styled';

type DiscountModalProps = {
  isOpen: boolean;
  title: string;
  benefitList: string[];
  withNebulaTitle: string;
  withNebulaIcons: string[];
  withoutNebulaTitle: string;
  withoutNebulaIcons: string[];
  footer: string;
  ctaButtonText: string;
  onClose: () => void;
  onConfirm: () => void;
};

const DiscountModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  benefitList,
  withNebulaTitle,
  withNebulaIcons,
  withoutNebulaTitle,
  withoutNebulaIcons,
  footer,
  ctaButtonText,
}: DiscountModalProps) => {
  return (
    <ModalStyled open={isOpen} onClose={onClose}>
      <>
        <CloseButtonStyled onClick={onClose} />
        <TitleStyled>{title}</TitleStyled>
        <BenefitsStyled>
          <BenefitsLargeColumnStyled>
            <BenefitsListStyled>
              {benefitList.map((benefit) => (
                <BenefitsListItemStyled key={benefit}>{benefit}</BenefitsListItemStyled>
              ))}
            </BenefitsListStyled>
          </BenefitsLargeColumnStyled>
          <BenefitsColumnStyled variant="gradient">
            <ColumnTitleStyled>{withNebulaTitle}</ColumnTitleStyled>
            <IconsListStyled>
              {withNebulaIcons.map((icon) => (
                <IconsListItemStyled key={icon} variant="benefit">
                  <Icon name={icon as IconNames} width={18} height={18} />
                </IconsListItemStyled>
              ))}
            </IconsListStyled>
          </BenefitsColumnStyled>
          <BenefitsColumnStyled>
            <ColumnTitleStyled>{withoutNebulaTitle}</ColumnTitleStyled>
            <IconsListStyled>
              {withoutNebulaIcons.map((icon) => (
                <IconsListItemStyled key={icon} variant="drawback">
                  <Icon name={icon as IconNames} width={14} height={14} />
                </IconsListItemStyled>
              ))}
            </IconsListStyled>
          </BenefitsColumnStyled>
        </BenefitsStyled>
        <FooterStyled>
          <FooterCaptionStyled dangerouslySetInnerHTML={prepareHtml(footer)} />
          <ButtonStyled fullWidth onClick={onConfirm}>
            {ctaButtonText}
          </ButtonStyled>
        </FooterStyled>
      </>
    </ModalStyled>
  );
};

export default DiscountModal;
