import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji/DeprecatedEmoji';

export const MainBlock = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 16px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 24px;
    padding: 24px 20px;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const Annotation = styled.p`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 8px;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const DiscountBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 3px 9px;
  border-radius: 6px;
  background: linear-gradient(95.17deg, #cf8bf3 -16.49%, #a770ef -15.14%, #fdb99b 115.23%);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 24px;
    padding: 6px 10px;
  }
`;

export const DiscountText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const DiscountEmoji = styled(EmojiComponent)`
  width: 20px;
  height: 20px;
  margin-right: 5px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;

export const DiscountPercentagesBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
`;

const OldPriceBase = styled.span`
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    right: -2px;
    left: -2px;
    border-color: ${({ theme }) => theme.colors.error};
    border-top: 1px solid;
    transform: rotate(8deg);
    content: '';
  }
`;

export const OldDiscountPercentage = styled(OldPriceBase)`
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 6px;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const ActualDiscountPercentage = styled.strong`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SubscriptionDetailsBlock = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const SubscriptionDetailsText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const PricesBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
`;

export const OldPrice = styled(OldPriceBase)`
  margin-right: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 8px;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const ActualPrice = styled.strong`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 21px;
  }
`;
export const SaveMoneyText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 4px;
    font-size: 16px;
  }
`;

export const DividerLine = styled.hr`
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  border: none;
  background-color: ${({ theme }) => theme.colors.infoLight};
`;

export const TotalBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const TrialPrice = styled.strong`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  background: ${(props) => props.theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;
