import { useGetAuthorizationToken } from 'core/auth/hooks';
import OnboardingAskV2Screen from 'core/common/components/QuizScreens/OnboardingAskV2';
import { useRedirectToAskNebula } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { OnboardingAskV2ScreenAttributes } from 'core/funnel/entities';

type OnboardingAskV2Props = {
  id: string;
};

const OnboardingAskV2 = ({ id }: OnboardingAskV2Props) => {
  const { screenAttributes, next } = useQuizStep(id);
  const { token } = useGetAuthorizationToken();
  const { redirectToExternalSignIn } = useRedirectToAskNebula();

  const { shouldRedirectToNext = false } = screenAttributes as OnboardingAskV2ScreenAttributes;

  const onLoadingFinished = () => {
    if (shouldRedirectToNext) {
      return next();
    }
    redirectToExternalSignIn(token, '/readings');
  };

  return (
    <OnboardingAskV2Screen
      goNext={onLoadingFinished}
      {...(screenAttributes as OnboardingAskV2ScreenAttributes)}
    />
  );
};

export default OnboardingAskV2;
