import styled, { keyframes } from 'styled-components';

const hiddenText = keyframes`
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Background = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 360px;
  margin-top: 68px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: white;
  animation-name: ${hiddenText};
  animation-duration: 6s;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 128px;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const Welcome = styled.p`
  font-size: 22px;
`;

export const Title = styled.h2`
  margin-bottom: 142px;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  background: ${(props) => props.theme.gradients.secondaryLight};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Text = styled.p`
  max-width: 316px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 145%;

  span {
    background: ${(props) => props.theme.gradients.secondaryLight};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 120px;
  right: 0;
  bottom: 0;
  left: 0;
`;
