import AnswerRadioButton from 'core/common/components/AnswerRadioButton/AnswerRadioButton';
import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import { ContainerMain } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { MultipleAnswersScreenProps } from 'core/funnel/entities';
import { Content, Item, StyledButton, StyledList, StyledTitle } from './styled';

const MultipleAnswersScreen = ({
  onBack,
  onAnswerClick,
  onContinue,
  chosenList,
  step,
  content,
  progressBarTitle,
  progressBarName,
  totalPersonalitySteps,
  totalProfileSteps,
  totalRelationshipSteps,
}: MultipleAnswersScreenProps) => {
  const isButtonDisabled = !chosenList.length;

  return (
    <>
      <Header onBack={onBack} />
      <QuizBackground backgroundImage={content.backgroundImage} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <ContainerMain>
        <StyledTitle>{content.title}</StyledTitle>
        <Content>
          <StyledList>
            {content.answers.map(({ text, value, emoji }) => (
              <Item key={text}>
                <AnswerRadioButton
                  isChosen={chosenList.includes(value)}
                  text={text}
                  onClick={() => onAnswerClick(value)}
                  emoji={emoji}
                />
              </Item>
            ))}
          </StyledList>
        </Content>
      </ContainerMain>
      <StyledButton onClick={onContinue} disabled={isButtonDisabled}>
        {content.next}
      </StyledButton>
    </>
  );
};

export default MultipleAnswersScreen;
