import styled from 'styled-components';

export const InfoContainer = styled.div`
  padding: 10px 14px;
  border-radius: 12px;
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  background: rgba(234, 238, 247, 0.15);
`;

export const Description = styled.p`
  margin-top: 4px;
`;

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;

  &::first-letter {
    text-transform: uppercase;
  }
`;
