/* eslint-disable max-len */
import styled from 'styled-components';
import SectionExperts from 'core/common/components/QuizScreensSections/SectionExperts';
import SectionFaqV2, {
  TitleStyled as FaqTitleStyled,
} from 'core/common/components/QuizScreensSections/SectionFaqV2';
import SectionReviewsCarousel, {
  TitleStyled as CarouselTitleStyled,
} from 'core/common/components/QuizScreensSections/SectionReviewsCarousel';
import SectionUpsellConsultationBenefits from 'core/common/components/QuizScreensSections/SectionUpsellConsultationBenefits';
import SectionUpsellConsultationGuarantee from 'core/common/components/QuizScreensSections/SectionUpsellConsultationGuarantee';
import SectionUpsellConsultationInsights from 'core/common/components/QuizScreensSections/SectionUpsellConsultationInsights';
import SectionUpsellConsultationOffer from 'core/common/components/QuizScreensSections/SectionUpsellConsultationOffer';
import SectionUpsellGuidance from 'core/common/components/QuizScreensSections/SectionUpsellGuidance';

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 90px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 80px;
  }
`;

export const SectionUpsellGuidanceStyled = styled(SectionUpsellGuidance)`
  margin-top: 28px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
  }
`;

export const SectionUpsellConsultationBenefitsStyled = styled(SectionUpsellConsultationBenefits)`
  margin-top: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
  }
`;

export const SectionUpsellConsultationInsightsStyled = styled(SectionUpsellConsultationInsights)`
  margin-top: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
  }
`;

export const SectionUpsellConsultationOfferStyled = styled(SectionUpsellConsultationOffer)`
  margin-top: 56px;
  margin-right: -16px;
  margin-left: -16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const SectionExpertsStyled = styled(SectionExperts)`
  margin-top: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
  }
`;

export const ReviewsCarouselWrapperStyled = styled.div``;

export const SectionReviewsCarouselStyled = styled(SectionReviewsCarousel)`
  ${CarouselTitleStyled} {
    margin-bottom: 24px;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;

    ${CarouselTitleStyled} {
      font-size: 32px;
      line-height: 45px;
    }
  }
`;

export const SectionFaqV2Styled = styled(SectionFaqV2)`
  margin-top: 56px;

  ${FaqTitleStyled} {
    font-size: 24px;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;

    ${FaqTitleStyled} {
      font-size: 32px;
      line-height: 45px;
      text-align: center;
    }
  }
`;

export const SectionUpsellConsultationGuaranteeStyled = styled(SectionUpsellConsultationGuarantee)`
  margin-top: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 80px;
  }
`;

export const OfferWrapperStyled = styled.div``;
