import { TrialPaymentWithDiscountPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import {
  Discount,
  DiscountIcon,
  DiscountOldPrice,
  DiscountPrice,
  DiscountPriceWrapper,
  DiscountText,
  DiscountTextWrapper,
  NewPrice,
  OldPrice,
  Price,
  PriceDescription,
  PriceSubtitle,
  PriceText,
  PriceTitle,
  PriceWrapper,
  Total,
  TotalPrice,
  TotalText,
  Wrapper,
} from './styled';

type SectionPriceProps = {
  fullPrice: string;
  discountPrice: string;
  discountTrialPrice: string;
  saveAmount: string;
  content: TrialPaymentWithDiscountPalmistryV2ScreenAttributes['content']['offer'];
};

const SectionPrice = ({
  fullPrice,
  discountPrice,
  discountTrialPrice,
  saveAmount,
  content,
}: SectionPriceProps) => {
  const {
    title,
    subtitle,
    discountApplied,
    saveMoney,
    totalText,
    oldDiscountPercent,
    discountPercent,
    perMonthCostText,
  } = content;

  return (
    <Wrapper>
      <PriceTitle>{title}</PriceTitle>
      <PriceSubtitle>{subtitle}</PriceSubtitle>
      <Discount>
        <DiscountTextWrapper>
          <DiscountIcon emoji={discountApplied.emoji} />
          <DiscountText>{discountApplied.text}</DiscountText>
        </DiscountTextWrapper>
        <DiscountPriceWrapper>
          <DiscountOldPrice>{oldDiscountPercent}</DiscountOldPrice>
          <DiscountPrice>{discountPercent}</DiscountPrice>
        </DiscountPriceWrapper>
      </Discount>
      <Price>
        <PriceText>{perMonthCostText}</PriceText>
        <PriceWrapper>
          <OldPrice>{fullPrice}</OldPrice>
          <NewPrice>{discountPrice}</NewPrice>
        </PriceWrapper>
      </Price>
      <PriceDescription>
        {saveMoney.beforePrice} {saveAmount} {saveMoney.afterPrice}
      </PriceDescription>
      <Total>
        <TotalText>{totalText}</TotalText>
        <TotalPrice>{discountTrialPrice}</TotalPrice>
      </Total>
    </Wrapper>
  );
};

export default SectionPrice;
