import { memo, SelectHTMLAttributes } from 'react';
import { HintContainer, Option, SelectBlock } from './styled';

interface HintSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: string[];
  placeholder: string;
}

const HintSelect = ({
  name,
  value,
  onChange,
  options,
  placeholder,
  className,
}: HintSelectProps) => (
  <HintContainer className={className}>
    <SelectBlock required name={name} id={name} value={value} onChange={onChange}>
      <Option value="" selected hidden>
        {placeholder}
      </Option>
      {options.map((item) => (
        <Option value={item} key={item}>
          {item}
        </Option>
      ))}
    </SelectBlock>
  </HintContainer>
);

export default memo(HintSelect);
