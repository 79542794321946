import styled from 'styled-components';
import LinearProgress, { muiLinearProgressClassNames } from 'core/common/components/LinearProgress';
import Icon from 'core/svg-sprites/ui/Icon';

export const ProgressValueStyled = styled.span`
  margin-left: auto;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const LinearProgressStyled = styled(LinearProgress)`
  ${muiLinearProgressClassNames.mainProgress} {
    height: 6px !important;
  }

  ${muiLinearProgressClassNames.innerProgress} {
    background: linear-gradient(to right, #f01313, #ff5c00) !important;
  }
`;

export const OptionStyled = styled.li<{ blurred: boolean }>`
  margin-bottom: 12px;

  ${ProgressValueStyled}, ${LinearProgressStyled} {
    filter: ${({ blurred }) => (blurred ? 'blur(5px)' : 'unset')};
  }
`;

export const OptionLabelStyled = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
  color: currentColor;
`;

export const OptionProgressStyled = styled.span``;

export const IconStyled = styled(Icon)`
  fill: currentColor;
  margin-right: 4px;
`;
