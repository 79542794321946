import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Rating from 'core/common/components/Rating';
import ReviewsCardBase from '../ReviewsCardBase';
import ReviewsCardFooter from '../ReviewsCardFooter';

export const RatingStarsStyled = styled(Rating)`
  display: block;
  font-size: 20px;
`;

export const DateStyled = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const TextStyled = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

export const AvatarStyled = styled(Image)`
  border-radius: 100%;
  object-position: top;
`;

export const FooterTextStyled = styled.span`
  margin-left: 8px;
  font-weight: 700;
`;

export const ReviewsCardFooterStyled = styled(ReviewsCardFooter)`
  align-items: center;
  margin-top: auto;
`;

export const ReviewsCardBaseStyled = styled(ReviewsCardBase)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
