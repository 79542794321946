import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';
import { TitleText } from 'core/common/components/Title';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: flex-start;
  }
`;

export const TitleStyled = styled(TitleText)`
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 135%;
`;

export const SubtitleStyled = styled.h4`
  width: 100%;
  margin-bottom: 24px;
  font-weight: 400;
  line-height: 135%;
  text-align: left;
  color: ${(props) => props.theme.colors.typography900};
`;

export const ContentStyled = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  transition: all 0.1s ease 0.3s;
`;

export const TextStyled = styled.p`
  margin-top: 30px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

export const PolicyStyled = styled(PolicyComponent)`
  max-width: 240px;
  transition: all 0.1s ease 0.3s;
  ${NavLink} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.infoLight};
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:focus-within {
    ${ContentStyled} {
      flex-grow: 0;
    }
    ${PolicyStyled} {
      opacity: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    &:focus-within {
      ${ContentStyled} {
        flex-grow: 1;
      }
      ${PolicyStyled} {
        opacity: 1;
      }
    }
  }
`;

export const ButtonStyled = styled(Button)`
  z-index: auto;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 #00000040;
`;
