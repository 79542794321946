import styled from 'styled-components';

export const List = styled.ul`
  z-index: 5;
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.typography100};
`;

export const Item = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 15px;
  }
`;

export const Span = styled.span`
  margin-left: 10px;
`;
