/* eslint-disable max-len */
import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Emoji from 'core/common/components/Emoji';
import Image from 'core/common/components/Image';

const cardWrapperHorizontalPadding = 8;
const blueGradient =
  'linear-gradient(180deg, #4B7CBA 0%, rgba(75, 124, 186, 0.91) 14.5%, rgba(75, 124, 186, 0.81) 28%, rgba(75, 124, 186, 0.65) 45.5%, rgba(75, 124, 186, 0.45) 63.5%, rgba(148, 173, 202, 0.22) 80.5%, rgba(216, 219, 216, 0.00) 100%)';
const whiteGradient =
  'linear-gradient(180deg,#f0f0f0 0%,rgba(240, 240, 240, 0.91) 19.73%,rgba(240, 240, 240, 0.81) 34.99%,rgba(240, 240, 240, 0.65) 54.05%,rgba(240, 240, 240, 0.45) 68.86%,rgba(240, 240, 240, 0) 92.5%)';

export const TitleStyled = styled.h2`
  max-width: 350px;
  margin: 56px auto 20px;
  font-size: 22px;
  text-align: center;
`;

export const WithoutStrategyWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 12px;
  padding-top: 16px;
  padding-right: ${cardWrapperHorizontalPadding}px;
  padding-left: ${cardWrapperHorizontalPadding}px;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 12px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const StrategyTitleStyled = styled.h3`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`;

export const EmojiStyled = styled(Emoji)`
  margin-right: 6px;
  font-size: 18px;
`;

export const StrategyListStyled = styled.ul`
  margin-top: 12px;
`;

export const StrategyListItemStyled = styled.li`
  position: relative;
  margin-bottom: 8px;
  padding-left: 18px;
`;

export const ListItemTextStyled = styled.p`
  font-size: 12px;
  line-height: 130%;
`;

export const ListItemIconStyled = styled(Image)`
  position: absolute;
  top: 2px;
  left: 0;
  margin-right: 4px;
`;

export const FooterStyled = styled.footer<{ variant?: 'white' | 'blue' }>`
  position: relative;
  flex-grow: 1;
  height: 86px;
  margin-top: 30px;
  margin-right: -${cardWrapperHorizontalPadding}px;
  margin-left: -${cardWrapperHorizontalPadding}px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50px;
    background: ${({ variant }) => (variant === 'blue' ? blueGradient : whiteGradient)};
    content: '';
  }
`;

export const FooterImageStyled = styled(Image)`
  object-fit: cover;
`;

export const WithStrategyWrapperStyled = styled(WithoutStrategyWrapperStyled)`
  margin-right: 0;
  border-color: #2633a0;
  color: ${({ theme }) => theme.colors.typography100};
  background: linear-gradient(180deg, #2633a0 0%, #4169b3 50%, #5da0c6 100%);
`;

export const CardsWrapperStyled = styled.div`
  display: flex;
`;

export const ActionButtonStyled = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 36px;
  border-radius: 1000px;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  background: ${({ theme }) => theme.colors.lunarViolet};
`;
