import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentWithDiscountScreen from 'core/common/components/QuizScreens/TrialPaymentWithDiscount';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialPaymentWithDiscountScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { getEmail } from 'core/user/store';

type TrialPaymentWithDiscountProps = {
  id: string;
};

const TrialPaymentWithDiscount = ({ id }: TrialPaymentWithDiscountProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountOffer, updateOffer, timeUpOffer, selectedOffer } = useOffers();

  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  useEffect(() => {
    if (!discountOffer) return;

    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
    });
  }, [analyticsService, discountOffer, email, orderId]);

  const getReport = useCallback(() => {
    if (!discountOffer) return;

    analyticsService.initiateCheckout({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
    });

    analyticsService.landingPageClick('trial_billing', discountOffer.offerId);
    updateOffer(discountOffer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, discountOffer, email, orderId]);

  return (
    <TrialPaymentWithDiscountScreen
      timeUpOffer={timeUpOffer}
      selectedOffer={selectedOffer}
      discountOffer={discountOffer}
      goNext={next}
      getReport={getReport}
      {...(screenAttributes as TrialPaymentWithDiscountScreenAttributes)}
    />
  );
};

export default TrialPaymentWithDiscount;
