import { Item, List, Span } from './styled';

export interface LoaderListProps {
  list: string[];
}

const LoaderList = ({ list }: LoaderListProps) => (
  <List>
    {list.map((item) => (
      <Item key={item}>
        <Span>{item}</Span>
      </Item>
    ))}
  </List>
);

export default LoaderList;
