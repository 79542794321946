import { useEffect } from 'react';
import OnboardingScreen from 'core/common/components/QuizScreens/Onboarding';
import { SECOND } from 'core/common/constants/';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { OnboardingScreenAttributes } from 'core/funnel/entities';

type OnboardingProps = {
  id: string;
};

const defaultAnimationDuration = 5 * SECOND;

const Onboarding = ({ id }: OnboardingProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  const { content, animationDuration } = screenAttributes as OnboardingScreenAttributes;

  const timer = content.list.length * (animationDuration ?? defaultAnimationDuration);

  useEffect(() => {
    const redirectWithTimer = setTimeout(next, timer);

    return () => {
      clearTimeout(redirectWithTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next]);

  return <OnboardingScreen {...(screenAttributes as OnboardingScreenAttributes)} />;
};

export default Onboarding;
