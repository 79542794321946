import { Circle, CircleActive, MobileStepper, Wrapper } from './styled';

type ProgressBarPalmistryProps = {
  currentStep: number;
  totalSteps?: number;
  className?: string;
};

const ProgressBarPalmistry = ({
  currentStep,
  totalSteps = 15,
  className,
}: ProgressBarPalmistryProps) => {
  return (
    <Wrapper className={className}>
      <CircleActive />
      <MobileStepper
        variant="progress"
        steps={totalSteps}
        activeStep={currentStep}
        position="static"
        backButton={null}
        nextButton={null}
      />
      <Circle />
    </Wrapper>
  );
};

export default ProgressBarPalmistry;
