import PartnersList from 'core/common/components/Partners';
import { TitleStyled } from './styled';

type PartnersProps = {
  title: string;
};

const Partners = ({ title }: PartnersProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <PartnersList />
    </>
  );
};

export default Partners;
