import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionPaywallHeaderAttributes } from 'core/funnel/entities';
import Timer from './Timer';
import {
  HeaderStyled,
  DiscountWrapperStyled,
  DiscountTextStyled,
  GetPlanButtonStyled,
} from './styled';

type SectionPaywallHeaderProps = {
  content: SectionPaywallHeaderAttributes['content'];
  isTimeUp: boolean;
  timeLeft: number;
  className?: string;
  onGetPlan: () => void;
};

const SectionPaywallHeader = ({
  content,
  isTimeUp,
  timeLeft,
  className,
  onGetPlan,
}: SectionPaywallHeaderProps) => {
  return (
    <HeaderStyled className={className}>
      <DiscountWrapperStyled>
        {!isTimeUp && content.discountText && (
          <DiscountTextStyled dangerouslySetInnerHTML={prepareHtml(content.discountText)} />
        )}
        <Timer timeLeft={timeLeft} minLabel={content.minLabel} secLabel={content.secLabel} />
      </DiscountWrapperStyled>
      <GetPlanButtonStyled onClick={onGetPlan}>{content.buttonText}</GetPlanButtonStyled>
    </HeaderStyled>
  );
};

export default SectionPaywallHeader;
