import ticketMedal from 'core/common/assets/images/ticketMedal.png';
import Image from 'core/common/components/Image';
import { SectionSubscriptionDiscountAttributes } from 'core/funnel/entities';
import {
  TicketCoreStyled,
  DiscountLabelStyled,
  DiscountTextStyled,
  DiscountTextTipWrapperStyled,
  DiscountTextTipStyled,
  DiscountImageWrapperStyled,
} from './styled';

export type SectionSubscriptionDiscountProps = {
  content: SectionSubscriptionDiscountAttributes['content'];
  className?: string;
  onClick: () => void;
};

const SectionSubscriptionDiscount = ({
  content,
  className,
  onClick,
}: SectionSubscriptionDiscountProps) => {
  const { discountValue, discountText } = content;

  return (
    <TicketCoreStyled onClick={onClick} className={className}>
      <DiscountLabelStyled>
        <DiscountImageWrapperStyled>
          <Image width={72} height={72} alt="" src={ticketMedal} priority />
        </DiscountImageWrapperStyled>
        <DiscountTextStyled>{discountValue}</DiscountTextStyled>
      </DiscountLabelStyled>
      <DiscountTextTipWrapperStyled>
        <DiscountTextTipStyled>{discountText}</DiscountTextTipStyled>
      </DiscountTextTipWrapperStyled>
    </TicketCoreStyled>
  );
};

export default SectionSubscriptionDiscount;
