import { useEffect } from 'react';
import CreditsNatalChartScreen from 'core/common/components/QuizScreens/CreditsNatalChart';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { CreditsNatalChartScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type CreditsNatalChartProps = {
  id: string;
};

const CreditsNatalChart = ({ id }: CreditsNatalChartProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onCreditsPageOpened();
  }, [analyticsService]);

  return <CreditsNatalChartScreen {...(screenAttributes as CreditsNatalChartScreenAttributes)} />;
};

export default CreditsNatalChart;
