import { ForwardedRef, forwardRef } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type CarouselSettings = Settings;

export const carouselClasses = {
  slide: '.slick-slide',
  list: '.slick-list',
  track: '.slick-track',
  buttonDisabled: '.slick-disabled',
  centerSlide: '.slick-center',
  dots: '.slick-dots',
};

const Carousel = forwardRef(
  ({ children, ...props }: CarouselSettings, ref: ForwardedRef<Slider>) => (
    <Slider ref={ref} {...props}>
      {children}
    </Slider>
  ),
);

export default Carousel;
