import CompoundedTitleInformationalScreen from 'core/common/components/QuizScreens/CompoundedTitleInformational';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { CompoundedTitleInformationalScreenAttributes } from 'core/funnel/entities';

type CompoundedTitleInformationalProps = {
  id: string;
};

const CompoundedTitleInformational = ({ id }: CompoundedTitleInformationalProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <CompoundedTitleInformationalScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as CompoundedTitleInformationalScreenAttributes)}
    />
  );
};

export default CompoundedTitleInformational;
