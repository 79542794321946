import Image from 'core/common/components/Image';
import { OnboardingAskV2ScreenProps } from 'core/funnel/entities';
import { DescriptionStyled, MessageStyled, MessageWrapperStyled, TitleStyled } from './styled';

type ReadingProps = OnboardingAskV2ScreenProps['content']['reading'];

const Reading = ({ title, description, image, message }: ReadingProps) => {
  return (
    <>
      <MessageWrapperStyled>
        <MessageStyled src={message.src} alt={message.alt} width={343} height={286} />
        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
      </MessageWrapperStyled>
      <Image src={image.src} width={150} height={150} alt={image.alt} />
    </>
  );
};

export default Reading;
