import { memo } from 'react';
import WheelPicker from 'core/common/components/WheelPicker';
import { monthsListEn, shortMonthsListEn } from 'core/common/constants';
import { DateObject } from '../types';
import {
  CylinderWrapperStyled,
  DayInputBlockStyled,
  MonthInputBlockStyled,
  YearInputBlockStyled,
} from './styled';

type CylinderDatePickerProps = {
  onChange: (value: Partial<DateObject>) => void;
  className?: string;
  daysList: string[];
  yearsList: string[];
  date: DateObject;
};

export const CylinderDatePicker = memo(
  ({ onChange, className, daysList, yearsList, date }: CylinderDatePickerProps) => {
    const config = [
      {
        pickerProps: {
          name: 'month',
          wheelSize: 20,
          values: monthsListEn,
          initIdx: shortMonthsListEn.indexOf(date.month),
          onChange: (value: string) => {
            onChange({ month: value.substring(0, 3) });
          },
        },
        ContainerStyled: MonthInputBlockStyled,
      },
      {
        pickerProps: {
          name: 'day',
          wheelSize: 20,
          values: daysList,
          initIdx: daysList.indexOf(date.day),
          onChange: (value: string) => onChange({ day: value }),
        },
        ContainerStyled: DayInputBlockStyled,
      },
      {
        pickerProps: {
          name: 'year',
          wheelSize: 22,
          values: yearsList,
          initIdx: yearsList.indexOf(date.year),
          onChange: (value: string) => onChange({ year: value }),
        },
        ContainerStyled: YearInputBlockStyled,
      },
    ];

    return (
      <CylinderWrapperStyled className={className}>
        {config.map(({ ContainerStyled, pickerProps }, index) => (
          <ContainerStyled key={index + pickerProps.name}>
            <WheelPicker {...pickerProps} />
          </ContainerStyled>
        ))}
      </CylinderWrapperStyled>
    );
  },
);
