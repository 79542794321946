import quotes from 'core/common/assets/images/reviews/quotes.svg';
import {
  Avatar,
  Date,
  DateWrapper,
  HeaderWrapper,
  Image,
  Item,
  List,
  Nickname,
  Rating,
  ReviewPolicy,
  Text,
  TextWrapper,
} from './styled';

type ReviewsProps = {
  list: Array<{
    id: number;
    nickname: string;
    date: string;
    text: string;
    initials: string;
    percent?: number;
  }>;
  className?: string;
  reviewPolicy: string;
};

const Reviews = ({ list, className, reviewPolicy }: ReviewsProps) => {
  return (
    <>
      <List className={className}>
        {list.map(({ id, nickname, date, text, initials, percent }) => (
          <Item key={id}>
            <HeaderWrapper>
              <Avatar>{initials}</Avatar>
              <DateWrapper>
                <Nickname>{nickname}</Nickname>
                <Date>{date}</Date>
              </DateWrapper>
              <Rating percent={percent} />
            </HeaderWrapper>
            <TextWrapper>
              <Image src={quotes} width={24} height={24} alt="quote" />
              <Text>{text}</Text>
            </TextWrapper>
          </Item>
        ))}
      </List>
      <ReviewPolicy>{reviewPolicy}</ReviewPolicy>
    </>
  );
};

export default Reviews;
