import styled from 'styled-components';
import Image from 'core/common/components/Image';
import LinearProgress, { muiLinearProgressClassNames } from 'core/common/components/LinearProgress';
import { getColorByProgress } from '../utils';

export const WrapperStyled = styled.div`
  padding: 16px 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TitleStyled = styled.h3`
  margin-right: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;

export const IconStyled = styled(Image)`
  margin-right: 8px;
`;

export const ValueStyled = styled.span<{ value: number }>`
  font-weight: 600;
  color: ${({ value }) => getColorByProgress(value)};
`;

export const LinearProgressStyled = styled(LinearProgress)`
  margin-bottom: 12px;

  ${muiLinearProgressClassNames.mainProgress} {
    background-color: #f2f2f2 !important;
  }

  ${muiLinearProgressClassNames.innerProgress} {
    background: ${({ value = 5 }) => getColorByProgress(value)};
  }
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography500};
`;
