import { InputHTMLAttributes } from 'react';
import { FunnelImage } from 'core/funnel/entities';
import {
  CardBodyStyled,
  CardFooterStyled,
  BodyBackgroundImageStyled,
  TitleStyled,
  ListStyled,
  ListItemStyled,
  PriceStyled,
  OldPirceStyled,
  DiscountStyled,
  CheckmarkStyled,
  RadioButtonStyled,
  IconStyled,
} from './styled';

type ProductCardRadioButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  price: string;
  oldPrice: string;
  discount: string;
  details: Array<string>;
  backgroundImage?: FunnelImage;
};

const ProductCardRadioButton = ({
  title,
  price,
  details,
  oldPrice,
  discount,
  backgroundImage,
  ...inputProps
}: ProductCardRadioButtonProps) => {
  return (
    <RadioButtonStyled {...inputProps}>
      <CardBodyStyled>
        {backgroundImage && (
          <BodyBackgroundImageStyled src={backgroundImage.src} alt={backgroundImage.alt} fill />
        )}
        <TitleStyled>{title}</TitleStyled>
        <ListStyled>
          {details.map((item) => (
            <ListItemStyled key={item}>{item}</ListItemStyled>
          ))}
        </ListStyled>
      </CardBodyStyled>
      <CardFooterStyled>
        <PriceStyled>{price}</PriceStyled>
        <OldPirceStyled>{oldPrice}</OldPirceStyled>
        <DiscountStyled>{discount}</DiscountStyled>
        <CheckmarkStyled>
          <IconStyled name="check" />
        </CheckmarkStyled>
      </CardFooterStyled>
    </RadioButtonStyled>
  );
};

export default ProductCardRadioButton;
