import NameMoonCompatibilityScreenV2 from 'core/common/components/QuizScreens/NameMoonCompatibilityV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { NameMoonCompatibilityV2ScreenAttributes } from 'core/funnel/entities';

type NameMoonCompatibilityV2Props = {
  id: string;
};

const NameMoonCompatibilityV2 = ({ id }: NameMoonCompatibilityV2Props) => {
  const { back, next, screenAttributes, updateUserPreferences, step, userPreferences } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as NameMoonCompatibilityV2ScreenAttributes;

  const handleSubmit = (userName: string) => {
    const trimmedUserName = userName.trim();

    if (!trimmedUserName) {
      return;
    }

    updateUserPreferences(dataKey, trimmedUserName);
    next({ value: trimmedUserName });
  };

  return (
    <NameMoonCompatibilityScreenV2
      onBack={back}
      onSkip={next}
      onSubmit={handleSubmit}
      step={step}
      initialUserName={userPreferences.name}
      {...(screenAttributes as NameMoonCompatibilityV2ScreenAttributes)}
    />
  );
};

export default NameMoonCompatibilityV2;
