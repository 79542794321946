import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  flex-grow: 1;
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ImageStyled = styled(Image)``;

export const TraumaScoreStyled = styled.span`
  position: absolute;
  font-size: 14px;
  font-weight: 700;
`;
