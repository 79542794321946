import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

const PREFIX = 'MuiLinearProgress';

const classes = {
  colorPrimary: `.${PREFIX}-colorPrimary`,
  root: `.${PREFIX}-root`,
  bar: `.${PREFIX}-bar`,
};

export const LinearProgressStyled = styled(LinearProgress)`
  border-radius: 15px;

  & ${classes.bar} {
    background: ${({ theme }) => theme.colors.primary500};
  }
`;
