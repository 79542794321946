import { useState } from 'react';
import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import SectionDiscountModal from 'core/common/components/QuizScreensSections/SectionDiscountModal';
import SectionIntroOfferV2 from 'core/common/components/QuizScreensSections/SectionIntroOfferV2';
import SectionPayment from 'core/common/components/QuizScreensSections/SectionPayment';
import SectionReviewsCarousel from 'core/common/components/QuizScreensSections/SectionReviewsCarousel';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { ReviewsCardAvatar } from 'core/common/components/ReviewsCards';
import { useInView } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import {
  SectionAttributesUnionType,
  SectionType,
  TrialPaymentAttachmentStyleV3ScreenProps,
} from 'core/funnel/entities';
import {
  ContainerStyled,
  SectionPaywallHeaderStyled,
  SectionRelationshipPlanStyled,
  SectionAttachmentStyleProfileStyled,
  SectionAdvantagesWithEmojiStyled,
  IntroOfferWrapperStyled,
  SectionHighlightsStyled,
  SectionPartnersStyled,
  SectionFaqV2Styled,
  ReviewsCarouselWrapperStyled,
  SectionRatingStyled,
  SectionGuaranteeStyled,
  SectionBannerClickableStyled,
  SectionNebulaRatingStyled,
} from './styled';

const subscriptionBlockId = 'subscription-block-attachment-style';
const scrollOffsetPx = -100;

const TrialPaymentAttachmentStyleV3Screen = ({
  sections,
  timeLeft,
  traumaScore,
  defaultOffers,
  timeUpOffers,
  selectedOffer,
  offer,
  isTimeUp,
  goNext,
  onClosePaymentModal,
  onOfferChanged,
  getReport,
  onCloseDiscountModal,
  navigateToDiscountPage,
  zodiacIcon,
}: TrialPaymentAttachmentStyleV3ScreenProps) => {
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [displayDiscountModal, setDisplayDiscountModal] = useState(false);
  const { ref: reviewsSectionRef, inView: reviewsSectionVisible } = useInView();

  const onPaymentModalClose = () => {
    setDisplayPaymentModal(false);
    setDisplayDiscountModal(true);
    onClosePaymentModal();
  };

  const onDiscountModalClose = () => {
    setDisplayDiscountModal(false);
    onCloseDiscountModal();
  };

  const onGetReport = () => {
    setDisplayPaymentModal(true);
    getReport();
  };

  if (!timeUpOffers || !defaultOffers || !offer) {
    return null;
  }

  const moveToOffers = () => {
    scrollToBlock(subscriptionBlockId, scrollOffsetPx);
  };

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.PAYWALL_HEADER:
        return (
          <SectionPaywallHeaderStyled
            key={section.sectionType + index}
            content={section.content}
            timeLeft={timeLeft}
            isTimeUp={isTimeUp}
            onGetPlan={moveToOffers}
          />
        );
      case SectionType.NEBULA_RATING:
        return (
          <SectionNebulaRatingStyled
            key={section.sectionType + index}
            content={section.content}
            variant="dark"
          />
        );
      case SectionType.RELATIONSHIP_PLAN:
        return (
          <SectionRelationshipPlanStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.BANNER_CLICKABLE:
        return (
          <SectionBannerClickableStyled
            key={section.sectionType + index}
            content={section.content}
            onClick={moveToOffers}
          />
        );
      case SectionType.ATTACHMENT_STYLE_PROFILE:
        return (
          <SectionAttachmentStyleProfileStyled
            key={section.sectionType + index}
            content={section.content}
            traumaScore={traumaScore}
            zodiacIcon={zodiacIcon}
          />
        );
      case SectionType.ADVANTAGES_WITH_EMOJI:
        return (
          <SectionAdvantagesWithEmojiStyled
            key={section.sectionType + index}
            content={section.content}
          />
        );
      case SectionType.INTRO_OFFER_V2:
        return (
          <IntroOfferWrapperStyled id={subscriptionBlockId}>
            <SectionIntroOfferV2
              content={section.content}
              isTimeUp={isTimeUp}
              chosenPlan={offer}
              selectedPlan={selectedOffer}
              defaultOffers={defaultOffers}
              timeUpOffers={timeUpOffers}
              onGetReport={onGetReport}
              onPlanChanged={onOfferChanged}
            />
          </IntroOfferWrapperStyled>
        );
      case SectionType.HIGHLIGHTS:
        return (
          <SectionHighlightsStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.PARTNERS:
        return (
          <SectionPartnersStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.FAQ_V2:
        return <SectionFaqV2Styled key={section.sectionType + index} content={section.content} />;
      case SectionType.REVIEWS_CAROUSEL:
        return (
          <ReviewsCarouselWrapperStyled ref={reviewsSectionRef} key={section.sectionType + index}>
            <SectionReviewsCarousel
              content={section.content}
              shouldPlayAnimation={reviewsSectionVisible}
              renderReviewCard={({ date, review, userName, userAvatar }) => {
                if (!userAvatar) {
                  return null;
                }

                return (
                  <ReviewsCardAvatar
                    date={date}
                    review={review}
                    userName={userName}
                    userAvatar={userAvatar}
                  />
                );
              }}
            />
          </ReviewsCarouselWrapperStyled>
        );
      case SectionType.RATING:
        return <SectionRatingStyled key={section.sectionType + index} content={section.content} />;
      case SectionType.GUARANTEE:
        return (
          <SectionGuaranteeStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.DISCOUNT_MODAL:
        return (
          <SectionDiscountModal
            key={section.sectionType + index}
            content={section.content}
            isOpen={displayDiscountModal}
            onClose={onDiscountModalClose}
            onConfirm={navigateToDiscountPage}
          />
        );
      case SectionType.PAYMENT_V2:
        return (
          !!offer && (
            <RenderOnlyOnClient key={section.sectionType + index}>
              <SectionPayment
                offer={offer}
                isTimeUp={isTimeUp}
                isOpen={displayPaymentModal}
                content={section.content}
                onPaymentSuccess={goNext}
                onClose={onPaymentModalClose}
              />
            </RenderOnlyOnClient>
          )
        );
      default:
        return null;
    }
  };

  return (
    <>
      <QuizBackground variant="light" />
      <ContainerStyled>
        <Header buttonBack={false} />
        {sections.map(renderSection)}
      </ContainerStyled>
    </>
  );
};

export default TrialPaymentAttachmentStyleV3Screen;
