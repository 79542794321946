import { ReactNode, useState, useRef, useEffect } from 'react';
import { TextStyled, ButtonStyled } from './styled';

type CollapsibleTextProps = {
  children: ReactNode;
  readMoreButtonText: string;
  readLessButtonText: string;
  initialTextHeightPx?: number;
};

const CollapsibleText = ({
  children,
  readMoreButtonText,
  readLessButtonText,
  initialTextHeightPx = 42,
}: CollapsibleTextProps) => {
  const ref = useRef<HTMLParagraphElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(initialTextHeightPx);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (!ref.current) return;

    if (expanded) {
      setHeight(ref.current.scrollHeight);

      return;
    }

    setHeight(initialTextHeightPx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, expanded]);

  return (
    <>
      <TextStyled ref={ref} expanded={expanded} height={height}>
        {children}
      </TextStyled>
      <ButtonStyled onClick={toggleExpanded}>
        {expanded ? readLessButtonText : readMoreButtonText}
      </ButtonStyled>
    </>
  );
};

export default CollapsibleText;
