import styled from 'styled-components';
import Address from 'core/common/components/Address';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const paddingHackValue = 70;
const paddingHackValueDesktop = 60;
const offerBorderRadius = 12;
const offerBorderWidth = 2;

export const BackgroundImageWrapperStyled = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: -1;
  padding-top: ${paddingHackValue}%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: relative;
    top: 0;
    padding-top: ${paddingHackValueDesktop}%;
  }
`;

export const BackgroundImageStyled = styled(Image)`
  display: flex;
  object-fit: cover;
`;

export const ContentStyled = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 630px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const ThinContainerStyled = styled.div`
  max-width: 426px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleStyled = styled.h1`
  margin-bottom: 8px;
  padding-top: ${paddingHackValue / 2}%;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 0;
    padding-top: 20px;
    font-size: 48px;
  }
`;

export const DescriptionStyled = styled.p`
  color: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const ListStyled = styled.ul`
  margin-top: 20px;
  margin-bottom: 32px;
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography300};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ListItemImageStyled = styled(Image)`
  margin-top: 2px;
  margin-right: 8px;
`;

export const ActionButtonStyled = styled(Button)`
  width: 100%;
  height: 56px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.info};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);
`;

export const SkipButtonStyled = styled(TextButton)`
  width: 100%;
  margin-top: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const DisclaimerStyled = styled.p`
  margin-top: 21px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.typography400};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const OfferStyled = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  padding: 16px 12px 12px;
  border: ${offerBorderWidth}px solid transparent;
  border-radius: ${offerBorderRadius}px;
  color: ${({ theme }) => theme.colors.typography100};
  background: ${({ theme }) => theme.colors.typography800};
  background-clip: padding-box;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -4px;
    border-radius: inherit;
    background: ${({ theme }) => theme.gradients.primary};
    content: '';
  }
`;

export const OfferTitleStyled = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

export const PriceStyled = styled.span`
  font-weight: 600;
`;

export const OldPriceStyled = styled(PriceStyled)`
  margin-right: 4px;
  font-size: 12px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography400};
`;

export const DiscountStyled = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  padding: 8px 12px;
  border-top-right-radius: ${offerBorderRadius}px;
  border-bottom-left-radius: ${offerBorderRadius}px;
  font-size: 12px;
  font-weight: 600;
  background: ${({ theme }) => theme.gradients.primary};
`;

export const AddressStyled = styled(Address)`
  margin-top: 20px;
  text-align: center;
`;
