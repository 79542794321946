import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import { TrialCreditsNatalChartScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import SectionPayment from './SectionPayment';
import { Button, ButtonWrapper, Content, Root, RouteLink } from './styled';

const TrialCreditsNatalChartScreen = ({
  content,
  trialCreditsUpsell,
  onBuyCredits,
  isLoading,
  onSkip,
  resignFormLoaded,
  paymentError,
  onClearPaymentStatus,
}: TrialCreditsNatalChartScreenProps) => {
  const { header, offer, button, skipButton, footer } = content;

  return (
    <Root>
      <Header buttonBack={false} />
      <Content>
        <SectionHeader content={header} />
        <SectionPayment plan={trialCreditsUpsell} content={offer} />
        <ButtonWrapper>
          <Button onClick={onBuyCredits} disabled={isLoading} loading={isLoading}>
            {button}
          </Button>
          <RouteLink disabled={isLoading} onClick={onSkip}>
            {skipButton}
          </RouteLink>
        </ButtonWrapper>
        <SectionFooter disclaimer={footer.disclaimer} addressUS={footer.addressUS} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onBuyCredits}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </Content>
    </Root>
  );
};

export default TrialCreditsNatalChartScreen;
