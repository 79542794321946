import { useEffect } from 'react';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';

export type SplitProps = {
  id: string;
};

const Split = ({ id }: SplitProps) => {
  const { next } = useQuizStep(id);

  useEffect(() => {
    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Split;
