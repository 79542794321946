import { useRef, useState } from 'react';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentWitchPowerScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import Guarantee from './Guarantee';
import SalesScreenHeader from './SalesScreenHeader';
import SectionDisclaimer from './SectionDisclaimer';
import SectionPartners from './SectionPartners';
import SectionPayment from './SectionPayment';
import SectionPersonalizedGuide from './SectionPersonalizedGuide';
import SectionReviews from './SectionReviews';
import SectionSpecialOffer from './SectionSpecialOffer';
import WitchPowerDescription from './WitchPowerDescription';
import WitchPowerHeaderInfo from './WitchPowerHeaderInfo';
import {
  ColoredBackgroundWrapperStyled,
  CommonContainerStyled,
  EmailAvatarStyled,
  FooterTextStyled,
  HeaderSpaceStyled,
  MainContainerWideStyled,
  TitleStyled,
  UserEmailStyled,
} from './styled';

const offerScrollOffset = -130;

const offerBlockId = 'disclaimer-block';

const TrialPaymentWitchPowerScreen = ({
  content,
  email,
  timeLeft,
  offer,
  timeUpOffer,
  getReport,
  onSucceed,
  onPaymentDialogClose,
}: TrialPaymentWitchPowerScreenProps) => {
  const {
    header,
    hero,
    title,
    specialOffer,
    personalizedGuide,
    moneyBackGuarantee,
    palmGuide,
    reviews,
    featuredIn,
    footer,
  } = content;

  const firstButtonRef = useRef<HTMLDivElement>(null);

  const onMoveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const [isOpen, setIsOpen] = useState(false);

  const isTimeUp = timeLeft <= 0;

  const onGetReport = () => {
    setIsOpen(true);
    getReport();
  };

  if (!timeUpOffer || !offer) {
    return null;
  }

  return (
    <>
      <SalesScreenHeader content={header} onScrollToBlock={onMoveToOffer} timeLeft={timeLeft} />
      <MainContainerWideStyled>
        <ColoredBackgroundWrapperStyled>
          <HeaderSpaceStyled />
          <UserEmailStyled>
            {email}
            <EmailAvatarStyled>{email[0]}</EmailAvatarStyled>
          </UserEmailStyled>
        </ColoredBackgroundWrapperStyled>
        <WitchPowerHeaderInfo title={hero.title} rating={hero.rating} guideList={hero.guideList} />
        <CommonContainerStyled>
          <TitleStyled>{title}</TitleStyled>
          <SectionSpecialOffer
            content={specialOffer}
            id={offerBlockId}
            offer={offer}
            timeUpOffer={timeUpOffer}
            onGetReport={onGetReport}
            timeLeft={timeLeft}
            ref={firstButtonRef}
          />
          <SectionDisclaimer
            displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
            displayPlanPrice={
              isTimeUp
                ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                : offer.pricesWithCurrencySymbol.priceWithVAT
            }
          />
          <SectionPersonalizedGuide content={personalizedGuide} />
          <WitchPowerDescription content={palmGuide} />
          <SectionReviews content={reviews} />
          <SectionPartners title={featuredIn.title} />
          <Guarantee
            title={moneyBackGuarantee.title}
            description={moneyBackGuarantee.description}
          />
          <Location>
            <LocationOption option={Locales.US}>
              <FooterTextStyled>{footer.addressUS}</FooterTextStyled>
            </LocationOption>
            <LocationOption option={Locales.AE}>
              <FooterTextStyled>{footer.addressAE}</FooterTextStyled>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <FooterTextStyled>{footer.address}</FooterTextStyled>
            </LocationOption>
          </Location>
        </CommonContainerStyled>
      </MainContainerWideStyled>
      <SectionPayment
        onPaymentSuccess={onSucceed}
        isTimeUp={isTimeUp}
        isOpen={isOpen}
        offer={offer}
        onClose={onPaymentDialogClose}
      />
    </>
  );
};

export default TrialPaymentWitchPowerScreen;
