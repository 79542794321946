import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
`;

export const TitleStyled = styled.h2`
  margin-bottom: 21px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

export const ChartAspectRatioWrapperStyled = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

export const ChartImageStyled = styled(Image)`
  object-fit: contain;
`;

export const CurrentMonthLabelStyled = styled.span`
  position: absolute;
  bottom: 6px;
  left: 5%;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.typography400};
`;

export const FutureMonthLabelStyled = styled(CurrentMonthLabelStyled)`
  right: 8%;
  left: unset;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const CaptionStyled = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography500};
`;
