import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BASE_ROUTES } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { Offer } from 'core/offers/entities';
import {
  ButtonStyled,
  DescriptionStyled,
  PurchaseBlockStyled,
  TotalBlockStyled,
  TotalTextStyled,
  BoldStyled,
  LinkStyled,
  ButtonWrapperStyled,
  SpecialOfferBlockStyled,
  SpecialOfferPriceBlockTextStyled,
  SpecialOfferOldPriceStyled,
  SpecialOfferPriceBlockStyled,
  SpecialOfferPriceWrapperStyled,
} from './styled';

export type PurchaseSectionProps = {
  id?: string;
  isTimeUp: boolean;
  onGetReport: () => void;
  offer: Offer;
  specialOffer: {
    header: string;
    title: string;
    totalText: string;
    costText: string;
    costTextOrganic: string;
    button: string;
    guranteeText: string;
  };
  timeUpOffer: Offer;
  title: string;
  button: string;
};

const PurchaseSection = forwardRef<HTMLDivElement, PurchaseSectionProps>(
  (
    {
      id,
      isTimeUp,
      onGetReport,
      offer,
      specialOffer,
      timeUpOffer,
      title,
      button,
    }: PurchaseSectionProps,
    ref,
  ) => {
    const tKeyPrefix = 'natal-chart.billingScreen';

    const { t } = useTranslation('translation', {
      keyPrefix: tKeyPrefix,
    });

    return (
      <PurchaseBlockStyled id={id}>
        <TotalBlockStyled>
          <TotalTextStyled dangerouslySetInnerHTML={prepareHtml(title)} />
          <TotalTextStyled>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</TotalTextStyled>
        </TotalBlockStyled>
        <SpecialOfferBlockStyled>
          <SpecialOfferPriceBlockTextStyled
            dangerouslySetInnerHTML={prepareHtml(specialOffer?.costText)}
          />
          <SpecialOfferPriceWrapperStyled>
            {!isTimeUp && (
              <SpecialOfferOldPriceStyled>
                {timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}
              </SpecialOfferOldPriceStyled>
            )}
            <SpecialOfferPriceBlockStyled>
              {offer.pricesWithCurrencySymbol.priceWithVAT}
            </SpecialOfferPriceBlockStyled>
          </SpecialOfferPriceWrapperStyled>
        </SpecialOfferBlockStyled>
        <ButtonWrapperStyled ref={ref}>
          <ButtonStyled onClick={onGetReport}>{button}</ButtonStyled>
        </ButtonWrapperStyled>
        {isTimeUp ? (
          <DescriptionStyled>
            <Trans
              i18nKey={`${tKeyPrefix}.description`}
              values={{ price: offer.pricesWithCurrencySymbol.priceWithVAT }}
            />{' '}
            <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
          </DescriptionStyled>
        ) : (
          <DescriptionStyled>
            <Trans
              i18nKey={`${tKeyPrefix}.trialDescription`}
              values={{
                price: offer.pricesWithCurrencySymbol.priceWithVAT,
                trialPrice: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
              }}
              components={{ bold: <BoldStyled /> }}
            />{' '}
            <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
          </DescriptionStyled>
        )}
      </PurchaseBlockStyled>
    );
  },
);

export default PurchaseSection;
