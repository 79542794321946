import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Icon from 'core/svg-sprites/ui/Icon';

export const CardWrapper = styled.div`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const CardHeader = styled.div`
  padding: 4px 0;
  border-radius: 20px 20px 0 0;
  background: ${({ theme }) => theme.colors.lunarViolet};
`;

export const HeaderTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const CardBody = styled.div`
  padding: 9px 20px 16px;
  border: ${(props) => `1px solid ${props.theme.colors.lunarViolet}`};
  border-radius: 0 0 20px 20px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const BodyHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const BodyHeaderPrice = styled.h3`
  margin-top: 2px;
  margin-left: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: ${(props) => props.theme.colors.primary600};
`;

export const TotalBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const TotalTitle = styled.h5`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const TotalPrice = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const HorizontalLine = styled.hr`
  margin-top: 8px;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
`;

export const DiscountBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TimerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 25px;
  border-radius: 6px;
  background: #eaeef7;
`;

export const TimerText = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.error};
`;

export const TimerDevider = styled(TimerText)`
  margin: 0 8px;
`;

export const SubscriptionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

export const SubscriptionDescription = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const SubscriptionPricesBlock = styled.div`
  display: flex;
`;

export const SubscriptionOldPrice = styled.span`
  align-self: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const SubscriptionActualPrice = styled.span`
  margin-left: 4px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 140px;
  height: 32px;
  background: #fff;
  perspective: 1000px;
  backface-visibility: hidden;
`;

export const GuaranteeBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 26px;
`;

export const SecondaryGuaranteeBlockStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 26px;
`;

export const GuaranteeText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;

export const ActionButton = styled(ButtonComponent)`
  width: 100%;
  height: 48px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
`;
