import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import NavLink from 'core/common/components/Link';

export const WrapperStyled = styled.div`
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  padding: 32px 15px;
  background: #9974f60d;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 20px;
  }
`;

export const GuaranteeWrapperStyled = styled.div`
  padding: 12px 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 12px;
    font-size: 26px;
    line-height: 39px;
  }
`;

export const ImageStyled = styled(ImageComponent)`
  display: block;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 12px;
    font-size: 26px;
    line-height: 39px;
  }
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 21px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const LinkStyled = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;
