import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const LifeLineContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 10px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.themeBackgroundLight};
`;

export const LineTitle = styled.span`
  font-weight: 600;
  color: #642b73;
`;

export const LifeLineContent = styled.p`
  max-width: 212px;
  margin-left: 16px;
  font-size: 14px;
  line-height: 130%;
`;

export const HeadLineContainer = styled(LifeLineContainer)`
  margin-top: 10px;
  padding-left: 8px;
  background: transparent;
`;

export const HeadLineContent = styled(LifeLineContent)`
  margin-left: 8px;
`;

export const MarriageLineContainer = styled(HeadLineContainer)`
  margin-top: 0;
  margin-bottom: 10px;
  padding-right: 0;
`;

export const MarriageLineContent = styled(HeadLineContent)`
  max-width: 254px;
`;

export const LoveFateContainer = styled(LifeLineContainer)`
  justify-content: space-between;
`;

export const LoveFateWrapper = styled.div`
  max-width: 146px;
`;

export const LoveFateContent = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
`;

export const Break = styled.br``;

export const FingersContainer = styled(HeadLineContainer)`
  margin-top: 20px;
`;

export const FingersContent = styled(HeadLineContent)`
  max-width: 224px;
`;

export const TitleDescription = styled(TitleComponent)`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
`;
