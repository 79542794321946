import { useSelector } from 'react-redux';
import MoonPhaseScreen from 'core/common/components/QuizScreens/MoonPhase';
import { useQuizStep } from 'core/funnel/contexts';
import { MoonPhaseScreenAttributes } from 'core/funnel/entities';
import { getPartnerMoonImage } from 'core/moon-compatibility/store';

export type PartnerMoonPhaseProps = {
  id: string;
};

const PartnerMoonPhase = ({ id }: PartnerMoonPhaseProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  const onBack = () => back();

  const moonImage = useSelector(getPartnerMoonImage);

  return (
    <MoonPhaseScreen
      onBack={onBack}
      goNext={next}
      moonImage={moonImage}
      {...(screenAttributes as MoonPhaseScreenAttributes)}
    />
  );
};

export default PartnerMoonPhase;
