import styled from 'styled-components';
import Button from 'core/common/components/Button';

export const WrapperStyled = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 3;
  margin-right: -15px;
  margin-left: -15px;
  padding: 32px 15px 16px;
  text-align: center;
  background: linear-gradient(0deg, #fbfbff 73.98%, rgba(255, 255, 255, 0) 100%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 24px;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 56px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.infoLight};
  box-shadow: 0 4px 4px 0 #00000040;
`;
