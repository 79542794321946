import { LinearProgressProps as MuiLinearProgressProps } from '@mui/material/LinearProgress';
import { WrapperStyled, LinearProgressStyled, ValueStyled } from './styled';

export const muiLinearProgressClassNames = {
  mainProgress: '.MuiLinearProgress-root',
  innerProgress: '.MuiLinearProgress-bar',
};

type LinearProgressProps = {
  withLabel?: boolean;
} & MuiLinearProgressProps;

const LinearProgress = ({
  className,
  value,
  withLabel = false,
  ...restProps
}: LinearProgressProps) => {
  return (
    <WrapperStyled className={className}>
      <LinearProgressStyled variant="determinate" value={value} {...restProps} />
      {withLabel && <ValueStyled>{value}%</ValueStyled>}
    </WrapperStyled>
  );
};

export default LinearProgress;
