import styled from 'styled-components';
import AnimatedImage from 'core/common/components/AnimatedImages/AnimatedImage';
import { InfoButton } from 'core/common/components/Quiz/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  max-width: 322px;
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  span {
    line-height: 180%;
  }

  strong {
    font-size: 24px;
    line-height: 28px;
  }

  b {
    color: #56ccf2;
  }
`;

export const StyledTitle = styled.h2`
  max-width: 328px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  strong {
    font-size: 18px;
    font-weight: 700;
  }

  b {
    font-weight: 700;
    color: #bb6cd9;
  }

  span {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;

    & b {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
        linear-gradient(90.6deg, #6a4dbc 0.47%, #f2994a 137.94%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 0;
  }
`;

export const NextButton = styled(InfoButton)`
  flex-grow: 1;
  height: 49px;
  margin: 0 0 0 10px;
  color: ${(props) => props.theme.colors.info};
  background: ${(props) => props.theme.colors.typography100};
`;

export const PrevButton = styled(InfoButton)`
  height: 49px;
  margin: 0;
  border: ${(props) => `1px solid ${props.theme.colors.dark}`};
  color: ${(props) => props.theme.colors.dark};
  background: transparent;
`;

export const StyledAnimatedImage = styled(AnimatedImage)`
  width: 250px;
  height: 250px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
