import { Trans, useTranslation } from 'react-i18next';
import Header from 'core/common/components/Header';
import Link from 'core/common/components/Link';
import { BASE_ROUTES } from 'core/common/constants';
import { formatPrice } from 'core/common/utils/formatPrice';
import { TrialPaymentWithDiscountPalmistryV2ScreenProps } from 'core/funnel/entities';
import SectionPayment from './SectionPayment';
import SectionPrice from './SectionPrice/SectionPrice';
import {
  Background,
  Button,
  Container,
  PartyIcon,
  SectionSubscriptionDisclaimer,
  Title,
  Underline,
  Bold,
} from './styled';

const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.trialPaymentWithDiscount';

const TrialPaymentWithDiscountPalmistryV2Screen = ({
  content,
  timeUpOffer,
  selectedOffer,
  discountOffer,
  isModalOpen,
  onGetReport,
  paymentSucceed,
  onModalClose,
}: TrialPaymentWithDiscountPalmistryV2ScreenProps) => {
  const { title, offer, button } = content;

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  if (!discountOffer || !timeUpOffer || !selectedOffer) return null;

  const saveAmount = `${timeUpOffer.currencySymbol}${formatPrice(
    timeUpOffer.prices.priceWithVAT - discountOffer.prices.priceWithVAT,
  )}`;

  return (
    <>
      <Background />
      <Header buttonBack={false} />
      <Container>
        <PartyIcon emoji={title.emoji} />
        <Title>{title.text}</Title>
        <SectionPrice
          fullPrice={timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}
          discountPrice={discountOffer.pricesWithCurrencySymbol.priceWithVAT}
          discountTrialPrice={discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT}
          saveAmount={saveAmount}
          content={offer}
        />
        <Button onClick={onGetReport}>{button}</Button>
        <SectionSubscriptionDisclaimer>
          <Trans
            i18nKey={`${tKeyPrefix}.descriptionExperiment`}
            components={{ bold: <Bold />, lnk: <Underline /> }}
            values={{
              fullPrice: selectedOffer.pricesWithCurrencySymbol.priceWithVAT,
              discountPrice: discountOffer.pricesWithCurrencySymbol.priceWithVAT,
              trialPrice: discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT,
            }}
          />
          <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</Link>.
        </SectionSubscriptionDisclaimer>
      </Container>
      {!!discountOffer && (
        <SectionPayment
          onPaymentSuccess={paymentSucceed}
          isOpen={isModalOpen}
          offer={discountOffer}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default TrialPaymentWithDiscountPalmistryV2Screen;
