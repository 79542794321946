import styled from 'styled-components';
import {
  ContainerInnerStyled,
  SlideStyled,
  SlidesStyled,
} from 'core/common/components/WheelPicker';

const sliderHeightPx = 170;

export const CylinderWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;

  &::before {
    position: absolute;
    top: calc(50% + 4px);
    left: calc(50% - 12px);
    display: flex;
    width: calc(100% + 40px);
    height: 33px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.typography500};
    opacity: 0.15;
    transform: translate(-50%, -50%);
    user-select: none;
    content: '';
  }

  ${ContainerInnerStyled} {
    height: 35px;
  }

  ${SlidesStyled} {
    perspective: 700px;
  }

  ${SlideStyled} {
    font-size: 18px;
  }
`;

export const MonthInputBlockStyled = styled.div`
  width: 140px;
  height: ${sliderHeightPx}px;
`;

export const DayInputBlockStyled = styled.div`
  width: 55px;
  height: ${sliderHeightPx}px;
`;

export const YearInputBlockStyled = styled.div`
  width: 60px;
  height: ${sliderHeightPx}px;
`;
