import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const salesScreenHeaderHeight = 62;

export const Header = styled.header<{ hide: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  align-self: center;
  width: 100%;
  height: ${salesScreenHeaderHeight}px;
  padding: 8px 15px;
  background-color: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    padding: 10px 40px;
  }
`;

export const HeaderCell = styled.div<{ justify?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  justify-content: ${({ justify = 'center' }) => justify};
  width: 50%;
`;

export const ButtonContainer = styled.div`
  min-width: 140px;
  height: 32px;
  background: #fff;
  backface-visibility: hidden;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 240px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;

export const ActionButton = styled(Button)`
  font-weight: 700;
`;
