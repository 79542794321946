import getOnAppStoreImage from 'core/common/assets/images/get_on_appstore.png';
import getOnGooglePlayImage from 'core/common/assets/images/get_on_google_play.png';
import { UnlimitedReadingsScreenAttributes } from 'core/funnel/entities';
import {
  DescriptionFooter,
  FooterImagesWrapper,
  HalfRowImage,
  Price,
  SlideDescription,
  Sup,
  TextEmoji,
  TextWithEmoji,
  FooterImage,
} from './styled';

type MainIntroSlideProps = {
  displayPrice: string;
  content: UnlimitedReadingsScreenAttributes['content']['infoSlide'];
};

const MainIntroSlide = ({ displayPrice, content }: MainIntroSlideProps) => {
  const { benefits, image } = content;

  const [mainAmount, coinsAmount] = displayPrice.split('.');

  return (
    <>
      <HalfRowImage width={239} height={252} src={image.src} alt={image.alt} priority />
      <SlideDescription>
        {benefits.map(({ text, emoji }) => (
          <TextWithEmoji key={text}>
            <TextEmoji emoji={emoji} height={18} width={18} desktopLarge={false} />
            {text}
          </TextWithEmoji>
        ))}
        <DescriptionFooter>
          <FooterImagesWrapper>
            <FooterImage src={getOnAppStoreImage} alt="Get on AppStore" width={54} height={16} />
            <FooterImage
              src={getOnGooglePlayImage}
              alt="Get on GooglePlay"
              width={54}
              height={15}
            />
          </FooterImagesWrapper>
          <Price>
            {mainAmount}
            <Sup>{coinsAmount}</Sup>
          </Price>
        </DescriptionFooter>
      </SlideDescription>
    </>
  );
};

export default MainIntroSlide;
