import styled from 'styled-components';
import Image from 'core/common/components/Image';
import RadioButton, { LabelStyled } from 'core/common/components/RadioButton';
import Icon from 'core/svg-sprites/ui/Icon';

export const CheckmarkStyled = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 2px solid #c2c2c2;
  border-radius: 50%;
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
`;

export const CardBodyStyled = styled.div`
  padding: 24px 12px;
`;

export const TitleStyled = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 18px;
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 16px;
  padding-left: 32px;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
`;

export const DividerStyled = styled.div`
  height: 1px;
  margin-bottom: 16px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const BillingBlockStyled = styled.div`
  margin-bottom: 16px;
`;

export const PricesWrapperStyled = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PriceStyled = styled.span`
  margin-right: 4px;
  font-size: 18px;
  font-weight: 600;
`;

export const OldPriceStyled = styled.span`
  margin-bottom: 3px;
  font-size: 14px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography400};
`;

export const BillingCaptionStyled = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const DiscountStyled = styled.span`
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #b32425;
  background-color: #feebef;
`;

export const ListStyled = styled.ul``;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;
  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &::before {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #3c1c7b;
    content: '';
  }
`;

export const RadioButtonStyled = styled(RadioButton)`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);

  & + ${LabelStyled} {
    display: block;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &:checked + ${LabelStyled} {
    box-shadow: none;
    outline: 2px solid #1d1e55;

    ${CheckmarkStyled} {
      border: none;
      background: linear-gradient(
        180deg,
        #1d1e55 0%,
        #272463 25%,
        #3b2f7b 50%,
        #4f358d 75%,
        #6939a2 100%
      );
    }
  }
`;
