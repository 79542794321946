import { useCallback } from 'react';
import LoaderPalmistryScreen from 'core/common/components/QuizScreens/LoaderPalmistry';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ProcessingPalmistryScreenAttributes } from 'core/funnel/entities';

type LoadingPalmistryProps = {
  id: string;
};

const LoadingPalmistry = ({ id }: LoadingPalmistryProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  const onLoadingFinished = useCallback(async () => {
    await next({ force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoaderPalmistryScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as ProcessingPalmistryScreenAttributes)}
    />
  );
};

export default LoadingPalmistry;
