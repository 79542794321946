import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Title from 'core/common/components/Title';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const StyledTitle = styled(Title)`
  margin-top: 52px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 135%;
  text-align: left;
`;

export const Subtitle = styled.p`
  margin-bottom: 72px;
  font-weight: 400;
  text-align: left;
`;

export const StyledButton = styled(Button)``;
