import { PaymentDialog } from 'core/common/components/Payment';
import { PaymentOrderDescription } from 'core/payments/entities';
import { Currency, CurrencySymbol, europeanCountries, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import SectionPaymentContentOtherLocation from './SectionPaymentContentOtherLocation';
import SectionPaymentContentUSLocation from './SectionPaymentContentUSLocation';
import { Content } from './styled';

export type SectionPaymentProps = {
  price?: number;
  currencySymbol?: CurrencySymbol;
  currency: Currency;
  productPlan: string;
  isOpen: boolean;
  priceWithoutVAT: number;
  vat: number;
  onClose: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

// TODO get dynamic data
const trialPeriod = '7-days';

const SectionPayment = (props: SectionPaymentProps) => {
  const {
    onPaymentSuccess,
    productPlan,
    price = 0,
    onClose,
    currencySymbol = CurrencySymbol.USD,
    isOpen,
    currency = Currency.USD,
  } = props;

  return (
    <>
      <PaymentDialog
        productId={productPlan}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
        currency={currency}
      >
        <Content>
          <Location>
            <LocationOption option={[...europeanCountries]}>
              <SectionPaymentContentOtherLocation
                trialPeriod={trialPeriod}
                price={price}
                currencySymbol={currencySymbol}
              />
            </LocationOption>
            <LocationOption option={Locales.US}>
              <SectionPaymentContentUSLocation
                trialPeriod={trialPeriod}
                price={price}
                currencySymbol={currencySymbol}
              />
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <SectionPaymentContentOtherLocation
                trialPeriod={trialPeriod}
                price={price}
                currencySymbol={currencySymbol}
              />
            </LocationOption>
          </Location>
        </Content>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
