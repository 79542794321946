import { FunnelImage } from 'core/funnel/entities';
import {
  ImageStyled,
  WrapperStyled,
  CaptionWrapperStyled,
  StrikethroughCaptionStyled,
  CaptionStyled,
} from './styled';

type NatalChartPreviewItemProps = {
  image: FunnelImage;
  caption: string;
  strikethroughCaption?: string;
  index: number;
};

const NatalChartPreviewItem = ({
  image,
  caption,
  strikethroughCaption,
  index,
}: NatalChartPreviewItemProps) => {
  return (
    <WrapperStyled index={index}>
      <ImageStyled src={image.src} alt={image.alt} fill />
      <CaptionWrapperStyled>
        <StrikethroughCaptionStyled>{strikethroughCaption}</StrikethroughCaptionStyled>
        <CaptionStyled>{caption}</CaptionStyled>
      </CaptionWrapperStyled>
    </WrapperStyled>
  );
};

export default NatalChartPreviewItem;
