import { ReactNode } from 'react';
import { CardFooterStyled } from './styled';

type ReviewsCardFooterProps = {
  children: ReactNode;
  className?: string;
};

const ReviewsCardFooter = ({ children, className }: ReviewsCardFooterProps) => {
  return <CardFooterStyled className={className}>{children}</CardFooterStyled>;
};

export default ReviewsCardFooter;
