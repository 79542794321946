import { SectionLegacyMediaPanelAttributes } from 'core/funnel/entities';
import { ContainerStyled, SubtitleStyled, TitleStyled, ImageStyled } from './styled';

type SectionMediaPanelProps = {
  content: SectionLegacyMediaPanelAttributes['content'];
  className?: string;
};

const SectionMediaPanel = ({ content, className }: SectionMediaPanelProps) => (
  <ContainerStyled className={className}>
    {content.title && <TitleStyled>{content.title}</TitleStyled>}
    {content.subTitle && <SubtitleStyled>{content.subTitle}</SubtitleStyled>}
    <ImageStyled src={content.image.src} alt={content.image.alt} width={330} height={0} />
  </ContainerStyled>
);

export default SectionMediaPanel;
