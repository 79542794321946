import NameMoonCompatibilityScreen from 'core/common/components/QuizScreens/NameMoonCompatibility';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { NameMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type NameMoonCompatibilityProps = {
  id: string;
};

const NameMoonCompatibility = ({ id }: NameMoonCompatibilityProps) => {
  const { back, next, screenAttributes, updateUserPreferences, step, userPreferences } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as NameMoonCompatibilityScreenAttributes;

  const initialUserName =
    typeof userPreferences[dataKey as keyof UserProfile] === 'string'
      ? (userPreferences[dataKey as keyof UserProfile] as string)
      : '';

  const handleSubmit = (userName: string) => {
    const trimmedUserName = userName.trim();

    if (!trimmedUserName) {
      return;
    }

    updateUserPreferences(dataKey, trimmedUserName);
    next({ value: trimmedUserName });
  };

  return (
    <NameMoonCompatibilityScreen
      onBack={back}
      onSkip={next}
      onSubmit={handleSubmit}
      step={step}
      initialUserName={initialUserName}
      {...(screenAttributes as NameMoonCompatibilityScreenAttributes)}
    />
  );
};

export default NameMoonCompatibility;
