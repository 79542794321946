import StepperProgress from 'core/common/components/ProgressBarHint';
import NavigationProgress from 'core/common/components/ProgressBarNatalChartV2';
import DefaultProgress from 'core/common/components/ProgressBarPalmistry';
import { ProgressBarUnion, ProgressBarVariants } from 'core/funnel/entities';

type ProgressBarProps = {
  step: number;
  config: ProgressBarUnion;
  className?: string;
  onBack?: () => void;
};

const ProgressBar = ({ step, config, className, onBack = () => {} }: ProgressBarProps) => {
  if (config.variant === ProgressBarVariants.STEPPER) {
    return (
      <StepperProgress
        currentStep={step}
        title={config.title}
        currentName={config.progressBarName}
        totalProfileSteps={config.totalProfileSteps}
        totalPersonalitySteps={config.totalPersonalitySteps}
        totalRelationshipSteps={config.totalRelationshipSteps}
        className={className}
      />
    );
  }

  if (config.variant === ProgressBarVariants.NAVIGATION) {
    return (
      <NavigationProgress
        currentStep={step}
        title={config.title}
        totalSteps={config.totalSteps}
        onBack={onBack}
        className={className}
      />
    );
  }

  return (
    <DefaultProgress currentStep={step} totalSteps={config.totalSteps} className={className} />
  );
};

export default ProgressBar;
