import { ReactNode } from 'react';
import { CardBodyStyled } from './styled';

type ReviewsCardBodyProps = {
  children: ReactNode;
  className?: string;
};

const ReviewsCardBody = ({ children, className }: ReviewsCardBodyProps) => {
  return <CardBodyStyled className={className}>{children}</CardBodyStyled>;
};

export default ReviewsCardBody;
