import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';
import { RelationshipZodiacInfoScreenProps } from 'core/funnel/entities';
import Compatibility from './Compatibility';
import ZodiacImages from './ZodiacImages';
import ZodiacInfo from './ZodiacInfo';
import { ContentWrapper, CustomButton } from './styled';

const RelationshipZodiacInfoScreen = ({
  onBack,
  goNext,
  content,
  zodiacImage,
  description,
  partnerZodiacImage,
}: RelationshipZodiacInfoScreenProps) => {
  const { title, next, captionTitle, captionDescription } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <BackgroundGradient />
      <ContentWrapper>
        <ZodiacImages image={zodiacImage} partnerImage={partnerZodiacImage} />
        <ZodiacInfo title={title} description={description} />
        <Compatibility title={captionTitle} description={captionDescription} />
        <CustomButton variant="secondary" onClick={goNext}>
          {next}
        </CustomButton>
      </ContentWrapper>
    </>
  );
};

export default RelationshipZodiacInfoScreen;
