import Header from 'core/common/components/Header';
import { SectionPaywallHeroAttributes } from 'core/funnel/entities';
import UserGoalCard from './UserGoalCard';
import { BackgroundImageStyled, WrapperStyled } from './styled';

type SectionPaywallHeroProps = {
  content: SectionPaywallHeroAttributes['content'];
};

const SectionPaywallHero = ({ content }: SectionPaywallHeroProps) => {
  return (
    <WrapperStyled>
      <Header buttonBack={false} variant="secondary" />
      <BackgroundImageStyled
        src={content.backgroundImage.src}
        alt={content.backgroundImage.alt}
        quality={100}
        priority
        fill
      />
      <UserGoalCard {...content.userGoal} />
    </WrapperStyled>
  );
};

export default SectionPaywallHero;
