import styled from 'styled-components';

export const ContainerStyled = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 15px;
  padding-bottom: 16px;
  padding-left: 15px;
  border-radius: 12px;
  text-align: center;
  background: ${(props) => props.theme.colors.themeBackgroundLight};
`;

export const TitleStyled = styled.h4`
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 135%;
  text-align: left;
`;

export const DescriptionStyled = styled.p`
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  text-align: left;
  color: ${(props) => props.theme.colors.typography600};
`;
