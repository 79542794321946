import styled from 'styled-components';
import SectionBenefits from 'core/common/components/QuizScreensSections/SectionBenefits';
import SectionOffer from 'core/common/components/QuizScreensSections/SectionOffer';
import SectionReviews from 'core/common/components/QuizScreensSections/SectionReviews';
import { Item } from 'core/common/components/Reviews';

export const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const ContentStyled = styled.div`
  z-index: 1;
  padding-bottom: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 54px;
  }
`;

export const SectionOfferStyled = styled(SectionOffer)`
  margin-right: -15px;
  margin-bottom: 56px;
  margin-left: -15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const SectionBenefitsStyled = styled(SectionBenefits)`
  margin-bottom: 56px;
  border-radius: 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const DescriptionStyled = styled.p`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

export const SectionReviewsStyled = styled(SectionReviews)`
  padding: 32px 15px;
  border-radius: 20px;
  background-color: #9974f60d;

  ${Item} {
    background: ${({ theme }) => theme.colors.white};
  }
`;
