import { useCountdown } from 'core/common/hooks';
import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { Align, ColorVariants } from './types';
import {
  TimerBodyStyled,
  TimerTitleStyled,
  TimerDividerStyled,
  TimerContainerStyled,
  TimerUnitCellStyled,
  TimerUnitTextStyled,
  TimerUnitLabelStyled,
} from './styled';

type TimerV2Props = {
  storageKey: string;
  duration?: number;
  stop?: number;
  title?: string;
  minLabel?: string;
  secLabel?: string;
  hideTitleOnDesktop?: boolean;
  timerVariant?: ColorVariants;
  timerAlign?: Align;
  titleAlign?: Align;
};

const TimerV2 = ({
  storageKey,
  duration,
  title,
  minLabel,
  secLabel,
  stop,
  hideTitleOnDesktop = false,
  timerVariant = 'primary',
  timerAlign = 'left',
  titleAlign = 'left',
}: TimerV2Props) => {
  const { specialPlanCountdown } = useCountdown({ key: storageKey, duration, stop });
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(specialPlanCountdown);

  return (
    <TimerContainerStyled>
      {title && (
        <TimerTitleStyled
          dangerouslySetInnerHTML={prepareHtml(title)}
          hideOnDesktop={hideTitleOnDesktop}
          align={titleAlign}
        />
      )}
      <TimerBodyStyled align={timerAlign}>
        <TimerUnitCellStyled>
          <TimerUnitTextStyled variant={timerVariant} isFontSizeSmallOnMobile={!!title}>
            {addLeadingZero(minutes)}
          </TimerUnitTextStyled>
          {minLabel && <TimerUnitLabelStyled dangerouslySetInnerHTML={prepareHtml(minLabel)} />}
        </TimerUnitCellStyled>
        <TimerDividerStyled variant={timerVariant} isFontSizeSmallOnMobile={!!title}>
          :
        </TimerDividerStyled>
        <TimerUnitCellStyled>
          <TimerUnitTextStyled variant={timerVariant} isFontSizeSmallOnMobile={!!title}>
            {addLeadingZero(seconds)}
          </TimerUnitTextStyled>
          {secLabel && <TimerUnitLabelStyled dangerouslySetInnerHTML={prepareHtml(secLabel)} />}
        </TimerUnitCellStyled>
      </TimerBodyStyled>
    </TimerContainerStyled>
  );
};

export default TimerV2;
