import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionReviewsAttributes } from 'core/funnel/entities';
import { ReviewsStyled, TitleStyled, WrapperStyled } from './styled';

type SectionReviewsProps = {
  content: SectionReviewsAttributes['content'];
};

const SectionReviews = ({ content, ...props }: SectionReviewsProps) => {
  return (
    <WrapperStyled {...props}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(content.title)} />
      <ReviewsStyled list={content.list} />
    </WrapperStyled>
  );
};

export default SectionReviews;
