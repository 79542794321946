import { PaymentDialog } from 'core/common/components/Payment';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import SectionPaymentContentOtherLocation from './SectionPaymentContentOtherLocation';
import SectionPaymentContentUSLocation from './SectionPaymentContentUSLocation';
import { ContentStyled } from './styled';

export type SectionPaymentProps = {
  isOpen: boolean;
  offer: Offer;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

// TODO get dynamic data
const trialPeriod = '7-days';

const SectionPayment = (props: SectionPaymentProps) => {
  const { onClose, onPaymentSuccess, offer, isOpen } = props;

  return (
    <>
      <PaymentDialog
        productId={offer.offerId}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
        currency={offer.currency}
      >
        <ContentStyled>
          <Location>
            <LocationOption option={Locales.US}>
              <SectionPaymentContentUSLocation trialPeriod={trialPeriod} offer={offer} />
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <SectionPaymentContentOtherLocation trialPeriod={trialPeriod} offer={offer} />
            </LocationOption>
          </Location>
        </ContentStyled>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
