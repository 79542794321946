import { useEffect, useState } from 'react';
import { LineCoordinates, PalmCoordinates, PalmPhoto } from '../entities';

export const useGetPalmCoordinates = (coordinates: PalmPhoto['coordinates']) => {
  const [fingersCoordinates, setFingersCoordinates] = useState<PalmCoordinates[]>([]);
  const [linesCoordinates, setLinesCoordinates] = useState<LineCoordinates>({});

  useEffect(() => {
    if (!coordinates) return;

    const {
      pinkyFingerTip,
      ringFingerTip,
      middleFingerTip,
      indexFingerTip,
      thumbFingerTip,
      lifeLinePts,
      headLinePts,
      heartLinePts,
      marriageLinePts,
      moneyLinePts,
    } = coordinates;

    const fingersTipsCoordinates = [
      thumbFingerTip,
      indexFingerTip,
      middleFingerTip,
      ringFingerTip,
      pinkyFingerTip,
    ];

    const palmLineCoordinates = {
      lifeLinePts: { coordinates: lifeLinePts, color: '#016F5B' },
      headLinePts: { coordinates: headLinePts, color: '#2104D1' },
      heartLinePts: { coordinates: heartLinePts, color: '#C20549' },
      marriageLinePts: { coordinates: marriageLinePts, color: '#BC06A5' },
      moneyLinePts: { coordinates: moneyLinePts, color: '#FFC226' },
    };

    setFingersCoordinates(fingersTipsCoordinates);
    setLinesCoordinates(palmLineCoordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fingersCoordinates,
    linesCoordinates,
  };
};
