import styled, { keyframes } from 'styled-components';
import CircularProgress from '../../CircularProgress';
import Image from '../../Image';

const moonAnimationDuration = 2;

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const lastQuarterMoonSlide = keyframes`
  0% {
    transform: translateX(-10%);
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;

  }
`;

const quarterMoonSlide = keyframes`
  0% {
    transform: translateX(10%);
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ContainerStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const AnimatedTextListStyled = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 80px;
`;

export const AnimatedTextListItemStyled = styled.li`
  position: absolute;
  width: 100%;
`;

export const TextStyled = styled.h2<{ delayIndex: number; animationDuration: number }>`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: ${({ animationDuration }) => animationDuration}s;
  animation-delay: ${({ delayIndex, animationDuration }) => delayIndex * animationDuration}s;
`;

export const CaptionStyled = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const MoonImagesWrapperStyled = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 24px;
`;

export const LastQuarterMoonImageStyled = styled(Image)`
  opacity: 0;
  animation-name: ${lastQuarterMoonSlide};
  animation-duration: ${moonAnimationDuration}s;
  transform: translateX(10%);
`;

export const QuarterMoonImageStyled = styled(Image)`
  opacity: 0;
  animation-name: ${quarterMoonSlide};
  animation-duration: ${moonAnimationDuration}s;
  transform: translateX(-10%);
`;

export const FullMoonImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  animation-name: ${fadeIn};
  animation-duration: ${moonAnimationDuration}s;
  transform: translateX(-50%);
`;

export const LoaderWrapperStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin-top: -30px;
  margin-left: -30px;
  animation-name: ${fadeIn};
  animation-duration: 2s;
`;

export const LoaderProgressStyled = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  transform: translateX(-50%) translateY(-60%);
`;

export const CircularProgressStyled = styled(CircularProgress)`
  circle {
    stroke: ${({ theme }) => theme.colors.typography900};
  }
`;
