import cn from 'classnames';
import { useLogger } from 'core/common/hooks';
import { CountryFlagSpriteWrapper } from './styled';

export type PhoneCountryFlagProps = {
  className?: string;
  code?: string;
};

const PhoneCountryFlag = ({ code = '', className = '' }: PhoneCountryFlagProps) => {
  const normalizedCode = code.trim().toLowerCase();

  const particularFlagClass = `flag_${normalizedCode}`;

  const logger = useLogger();

  if (!particularFlagClass) {
    logger.warn(`Flag ${code} not found`);
  }

  return (
    <CountryFlagSpriteWrapper className={className}>
      <span className="flagPlaceholder" />
      <span className={cn('flagImage', particularFlagClass)} />
    </CountryFlagSpriteWrapper>
  );
};

export default PhoneCountryFlag;
