import styled from 'styled-components';
import SectionUpsellOffers from 'core/common/components/QuizScreensSections/SectionUpsellOffers';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 1152px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 132px;
  }
`;

export const SectionUpsellOffersStyled = styled(SectionUpsellOffers)`
  margin-top: 28px;
`;
