import styled from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Input from 'core/common/components/Input';

export const ContentStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 328px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.typography700};
`;

export const InputStyled = styled(Input)`
  padding: 12px 16px;
  border-color: ${({ theme }) => theme.colors.typography300};
  color: ${({ theme }) => theme.colors.typography600};
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 0;
  }
`;

export const InputWrapperStyled = styled.div`
  flex-grow: 1;
  transition: flex-grow 0.1s ease 0.3s;
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:focus-within {
    ${InputWrapperStyled} {
      flex-grow: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    &:focus-within {
      ${InputWrapperStyled} {
        flex-grow: 1;
      }
    }
  }
`;

export const SkipButtonStyled = styled(TextButton)`
  margin-top: 13px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
  cursor: pointer;
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-top: 30px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
