import styled from 'styled-components';
import { ContainerMain } from 'core/common/components/Quiz/styled';

export const ContainerMainStyled = styled(ContainerMain)`
  position: relative;
  flex-grow: 1;
  max-width: 450px;
  line-height: 135%;
  text-align: left;
`;

export const TitleStyled = styled.h2`
  align-self: stretch;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 135%;
`;

export const SubtitleStyled = styled.p`
  width: 100%;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography900};
`;
