import { prepareHtml } from 'core/common/utils/prepareHtml';
import { PalmLine } from 'core/palmistry/entities';
import { ListStyled, ListItemStyled } from './styled';

type LineFactsProps = {
  line: PalmLine;
  points: Array<string>;
};

const LineFacts = ({ points, line }: LineFactsProps) => {
  return (
    <ListStyled>
      {points.map((point) => (
        <ListItemStyled key={point} line={line} dangerouslySetInnerHTML={prepareHtml(point)} />
      ))}
    </ListStyled>
  );
};

export default LineFacts;
