import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section`
  margin-top: 52px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 22px;
  text-align: center;
`;

export const ContentStyled = styled.div`
  margin-bottom: 24px;
  padding: 24px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.themeBackground};
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.1);
`;

export const QuestionsListStyled = styled.ul``;

export const QuestionsListItemStyled = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ItemTitleStyled = styled.h4`
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ItemDescriptionStyled = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const QuestionsListIconStyled = styled(Image)`
  margin-top: 2px;
  margin-right: 8px;
`;

export const QuestionsListItemContentStyled = styled.div``;

export const ActionButtomStyled = styled(Button)`
  width: 95%;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: 700;
`;
