import AdditionalDiscountIntroOfferScreen from 'core/common/components/QuizScreens/AdditionalDiscountIntroOffer';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';

type AdditionalDiscountIntroOfferProps = {
  id: string;
};

const AdditionalDiscountIntroOffer = ({ id }: AdditionalDiscountIntroOfferProps) => {
  const { next } = useQuizStep(id);

  return <AdditionalDiscountIntroOfferScreen goNext={next} />;
};

export default AdditionalDiscountIntroOffer;
