import Icon from 'core/svg-sprites/ui/Icon';
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  ItemStyled,
  ListStyled,
  TextStyled,
} from './styled';

type FaqListProps = {
  list: Array<{ title: string; text: string }>;
  className?: string;
};

const FaqList = ({ list, className }: FaqListProps) => {
  return (
    <ListStyled className={className}>
      {list.map(({ title, text }) => (
        <ItemStyled key={title}>
          <AccordionStyled key={title}>
            <AccordionSummaryStyled expandIcon={<Icon name="arrow-simple" />}>
              <TextStyled>{title}</TextStyled>
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>{text}</AccordionDetailsStyled>
          </AccordionStyled>
        </ItemStyled>
      ))}
    </ListStyled>
  );
};

export default FaqList;
