import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import { ContainerMain } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { ProblemScreenProps } from 'core/funnel/entities';
import { Content, Item, StyledAnswerButton, StyledList, StyledTitle } from './styled';

const ProblemScreen = ({
  content,
  progressBarTitle,
  progressBarName,
  totalPersonalitySteps,
  totalProfileSteps,
  totalRelationshipSteps,
  onProblemClick,
  onBack,
  step,
}: ProblemScreenProps) => {
  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header onBack={onBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <ContainerMain>
        <StyledTitle>{content.title}</StyledTitle>
        <Content>
          <StyledList>
            {content.answers.map(({ text, value, emoji }) => (
              <Item key={text}>
                <StyledAnswerButton
                  text={text}
                  onClick={() => onProblemClick(value)}
                  emoji={emoji}
                />
              </Item>
            ))}
          </StyledList>
        </Content>
      </ContainerMain>
    </>
  );
};

export default ProblemScreen;
