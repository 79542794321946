import advisors from 'core/common/assets/images/advisors-light.png';
import handsWithHeart from 'core/common/assets/images/hands-with-heart.svg';
import bow from 'core/common/assets/images/quiz/bow.png';
import wing from 'core/common/assets/images/wing-gradient.png';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { OnboardingReadingScreenProps } from 'core/funnel/entities';
import {
  AppRatingContentStyled,
  AppRatingLeftWingStyled,
  AppRatingReviewsStyled,
  AppRatingRightWingStyled,
  AppRatingWrapperStyled,
  BackgroundStyled,
  BonusIconStyled,
  BonusStyled,
  BonusTextStyled,
  BonusTitleStyled,
  BonusWrapperStyled,
  ButtonStyled,
  DescriptionStyled,
  DescriptionTextStyled,
  FooterStyled,
  HeaderStyled,
  IconStyled,
  ImageAdvisorsStyled,
  ImageStyled,
  QuestionImageStyled,
  QuestionTitleStyled,
  RatingStyled,
  RatingTextStyled,
  RatingWrapperStyled,
  ReadingTextStyled,
  ReadingTitleStyled,
  ReadingTopStyled,
  ReadingWrapperStyled,
  ReviewsStyled,
  ReviewsTitleStyled,
  SubtitleStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

const OnboardingReadingScreen = ({ content, goNext }: OnboardingReadingScreenProps) => {
  return (
    <>
      <BackgroundStyled />
      <HeaderStyled buttonBack={false} />
      <WrapperStyled>
        <ReadingTopStyled>
          <IconStyled name="clock" />
          <ReadingWrapperStyled>
            <ReadingTitleStyled>{content.reading.title}</ReadingTitleStyled>
            <ReadingTextStyled dangerouslySetInnerHTML={prepareHtml(content.reading.text)} />
          </ReadingWrapperStyled>
        </ReadingTopStyled>
        <DescriptionStyled>
          <DescriptionTextStyled>{content.description}</DescriptionTextStyled>
          <ImageStyled fill src={bow} alt="bow" />
        </DescriptionStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(content.subtitle)} />
        <ImageAdvisorsStyled src={advisors} alt="advisors" width={260} />
        <QuestionTitleStyled>{content.questions.title}</QuestionTitleStyled>
        <QuestionImageStyled
          src={content.questions.image.src}
          alt={content.questions.image.alt}
          width={content.questions.image.width}
          height={content.questions.image.height}
        />
        <BonusWrapperStyled>
          <BonusStyled>
            <BonusIconStyled src={handsWithHeart} width={36} height={36} alt="hands with heart" />
            <ReadingWrapperStyled>
              <BonusTitleStyled>{content.bonus.title}</BonusTitleStyled>
              <BonusTextStyled dangerouslySetInnerHTML={prepareHtml(content.bonus.text)} />
            </ReadingWrapperStyled>
          </BonusStyled>
        </BonusWrapperStyled>
        <AppRatingWrapperStyled>
          <AppRatingLeftWingStyled src={wing} alt="wings" width={30} height={55} />
          <AppRatingContentStyled>
            <AppRatingReviewsStyled>{content.appRating.reviews}</AppRatingReviewsStyled>
            <RatingWrapperStyled>
              <RatingStyled percent={100} />
              <RatingTextStyled>{content.appRating.rating}</RatingTextStyled>
            </RatingWrapperStyled>
          </AppRatingContentStyled>
          <AppRatingRightWingStyled src={wing} alt="wings" width={30} height={55} />
        </AppRatingWrapperStyled>
        <ReviewsTitleStyled dangerouslySetInnerHTML={prepareHtml(content.reviews.title)} />
        <ReviewsStyled list={content.reviews.list} />
        <FooterStyled>{content.footer}</FooterStyled>
        <ButtonStyled impulseScale onClick={goNext}>
          {content.button}
        </ButtonStyled>
      </WrapperStyled>
    </>
  );
};

export default OnboardingReadingScreen;
