import { useEffect } from 'react';
import OnboardingAskScreen from 'core/common/components/QuizScreens/OnboardingAsk';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { OnboardingAskScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type OnboardingAskProps = {
  id: string;
};

const OnboardingAsk = ({ id }: OnboardingAskProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onIntroPageOpened();
  }, [analyticsService]);

  return <OnboardingAskScreen {...(screenAttributes as OnboardingAskScreenAttributes)} />;
};

export default OnboardingAsk;
