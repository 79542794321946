import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Checkbox from 'core/common/components/Checkbox';

export const PageWrapperStyled = styled.div`
  max-width: 446px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 24px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.white};
    content: '';
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-bottom: 160px;
  }
`;

export const PageContentStyled = styled.div`
  position: relative;
  z-index: 1;
`;

export const MobileImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 36px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const DesktopImageStyled = styled.div`
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const MainContentWrapperStyled = styled.div`
  width: 100%;
  margin-bottom: 52px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 72px;
  }
`;

export const CompatibilityHeaderStyled = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 134%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 150%;
  }
`;

export const DoNotMissTextStyled = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 171%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 150%;
  }
`;

export const GuaranteeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 48px;
  }
`;

export const GuaranteeTextStyled = styled.span`
  margin-left: 1px;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-left: 4px;
    font-size: 14px;
  }
`;

export type PhoneInputWrapperProps = {
  error?: unknown;
};

export const PhoneInputWrapperStyled = styled.div<PhoneInputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 44px;
  margin-bottom: 8px;

  .PhoneInput {
    display: flex;
    flex-grow: 1;
  }

  input {
    border: ${(props) => (props.error ? `1.5px solid ${props.theme.colors.error}` : 'none')};
    outline: none;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 12px;
  }
`;

export const PhoneInputErrorStyled = styled.div`
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.error};
`;

export const PhoneInputStyled = styled.input`
  flex-grow: 1;
  height: 100%;
  padding: 8px 12px;
  border-radius: 32px;
  font-size: 16px;
  line-height: 171%;
  color: ${({ theme }) => theme.colors.typography900};
  background-color: rgba(224, 224, 224, 0.4);

  &::placeholder {
    line-height: 171%;
    color: ${({ theme }) => theme.colors.typography500};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.typography500};
    background-color: ${({ theme }) => theme.colors.typography200};
  }
`;

export const ConfirmationLabelWrapperStyled = styled.div``;

export const ConfirmationCheckboxStyled = styled(Checkbox)`
  align-items: center;
  font-size: 12px;
  line-height: 130%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GetPersonalizedUpdatesButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
  line-height: 134%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    height: 56px;
  }
`;

export const NoUpdatesNeededButtonStyled = styled.button`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;
