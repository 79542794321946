import { Trans, useTranslation } from 'react-i18next';
import Header from 'core/common/components/Header';
import { BASE_ROUTES } from 'core/common/constants';
import { TrialPaymentWithDiscountPalmistryScreenProps } from 'core/funnel/entities';
import SectionPayment from './SectionPayment';
import SectionPrice from './SectionPrice/SectionPrice';
import {
  BackgroundStyled,
  ButtonStyled,
  ContainerStyled,
  LinkStyled,
  PartyIconStyled,
  SectionSubscriptionDisclaimerStyled,
  TitleStyled,
  BoldStyled,
} from './styled';

const oldPriceDiscount = 30;

const TrialPaymentWithDiscountPalmistryScreen = ({
  content,
  currencySymbol,
  currency,
  oldPrice,
  secretPrice,
  price,
  priceWithoutVAT,
  vat,
  period,
  productPlan,
  isModalOpen,
  onGetReport,
  paymentSucceed,
  onModalClose,
}: TrialPaymentWithDiscountPalmistryScreenProps) => {
  const { title, offer, button } = content;

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.trialPaymentWithDiscount';

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  const displayPrice = `${currencySymbol}${secretPrice}`;
  const displayFullPrice = `${currencySymbol}${oldPrice}`;

  return (
    <>
      <BackgroundStyled />
      <Header buttonBack={false} />
      <ContainerStyled>
        <PartyIconStyled emoji={title.emoji} />
        <TitleStyled>{title.text}</TitleStyled>
        <SectionPrice
          content={offer}
          oldPriceDiscount={oldPriceDiscount}
          newPriceDiscount={productPlan.discountPercent}
          oldPrice={oldPrice}
          price={secretPrice}
          totalPrice={price}
          period={period}
          currencySymbol={currencySymbol}
        />
        <ButtonStyled onClick={onGetReport}>{button}</ButtonStyled>
        <SectionSubscriptionDisclaimerStyled>
          <Trans
            i18nKey={`${tKeyPrefix}.descriptionExperiment`}
            components={{ bold: <BoldStyled /> }}
            values={{
              fullPrice: displayFullPrice,
              discountPrice: displayPrice,
            }}
          />{' '}
          <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
        </SectionSubscriptionDisclaimerStyled>
      </ContainerStyled>
      <SectionPayment
        productPlan={productPlan.productId}
        currency={currency}
        onPaymentSuccess={paymentSucceed}
        isOpen={isModalOpen}
        price={price}
        priceWithoutVAT={priceWithoutVAT}
        vat={vat}
        currencySymbol={currencySymbol}
        onClose={onModalClose}
      />
    </>
  );
};

export default TrialPaymentWithDiscountPalmistryScreen;
