import { useRef } from 'react';
import Header from 'core/common/components/Header';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentNatalChartV3ScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import BenefitsSection from './BenefitsSection';
import FAQ from './FAQ';
import HeaderSection from './HeaderSection';
import PartnersSection from './PartnersSection';
import SectionBlurredReport from './SectionBlurredReport';
import SectionDetails from './SectionDetails';
import SectionGoal from './SectionGoal';
import SectionGuarantee from './SectionGuarantee';
import SectionPayment from './SectionPayment';
import SectionReviews from './SectionReviews';
import SectionSpecialOffer from './SectionSpecialOffer';
import {
  FooterTextStyled,
  MainContainerStyled,
  NatalChartLogoStyled,
  SectionDisclaimerStyled,
  TitleStyled,
} from './styled';

const offerScrollOffset = -15;
const offerBlockId = 'disclaimer-block';

const TrialPaymentNatalChartV3Screen = ({
  content,
  onGetReport,
  onSucceed,
  onClose,
  timeLeft,
  offer,
  timeUpOffer,
  isPaymentModalOpen,
}: TrialPaymentNatalChartV3ScreenProps) => {
  const {
    header,
    benefits,
    details,
    report,
    faq,
    reviews,
    moneyBack,
    specialOffer,
    featuredIn,
    footer,
    goal,
    title,
  } = content;

  const firstButtonRef = useRef<HTMLDivElement>(null);

  const isTimeUp = timeLeft <= 0;

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  if (!offer || !timeUpOffer) return null;

  return (
    <>
      <Header buttonBack={false} />
      <MainContainerStyled>
        <HeaderSection content={header} timeLeft={timeLeft} />
        <NatalChartLogoStyled
          src={header.image.src}
          alt={header.image.alt}
          width={150}
          height={150}
          id={offerBlockId}
        />
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <SectionGoal title={goal.title} relationshipGoal={goal.description} />
        {benefits && <BenefitsSection title={benefits.title} benefits={benefits.list} />}
        <SectionSpecialOffer
          specialOffer={specialOffer}
          id={offerBlockId}
          offer={offer}
          timeUpOffer={timeUpOffer}
          onGetReport={onGetReport}
          timeLeft={timeLeft}
          ref={firstButtonRef}
        />
        <SectionDisclaimerStyled
          displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
          displayPlanPrice={
            isTimeUp
              ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
              : offer.pricesWithCurrencySymbol.priceWithVAT
          }
        />
        <SectionDetails title={details.title} details={details.list} />
        <SectionBlurredReport content={report} onScrollToBlock={moveToOffer} />
        <FAQ title={faq.title} list={faq.list} />
        <SectionReviews title={reviews.title} reviews={reviews.list} />
        <SectionGuarantee
          image={moneyBack.image}
          title={moneyBack.title}
          firstText={moneyBack.firstText}
          secondText={moneyBack.secondText}
        />
        <PartnersSection title={featuredIn.title} image={featuredIn.image} />
        <Location>
          <LocationOption option={Locales.US}>
            <FooterTextStyled dangerouslySetInnerHTML={prepareHtml(footer.addressUS)} />
          </LocationOption>
          <LocationOption option={Locales.AE}>
            <FooterTextStyled dangerouslySetInnerHTML={prepareHtml(footer.addressAE)} />
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <FooterTextStyled dangerouslySetInnerHTML={prepareHtml(footer.address)} />
          </LocationOption>
        </Location>
      </MainContainerStyled>
      <RenderOnlyOnClient>
        <SectionPayment
          onPaymentSuccess={onSucceed}
          isOpen={isPaymentModalOpen}
          offer={offer}
          onClose={onClose}
        />
      </RenderOnlyOnClient>
    </>
  );
};

export default TrialPaymentNatalChartV3Screen;
