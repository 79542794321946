import styled from 'styled-components';
import Link from 'core/common/components/Link';

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkStyled = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const DividerStyled = styled.div`
  width: 1px;
  height: 16px;
  margin-top: 4px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.typography600};
`;
