import logoPng from 'core/common/assets/images/trialPayment/nebulaLogoBig.png';
import {
  AccessDescription,
  ActionButton,
  BlurInnerContainer,
  BlurOuterContainer,
  BlurredReportBody,
  BlurredReportWrapper,
  ContentBlock,
  ContentText,
  ContentTitle,
  Item,
  ItemText,
  LogoImage,
  LogoImageBlock,
  NumberedList,
  ReportTitle,
  UserInfoBlock,
  ZodiacImage,
  ZodiacImageBlock,
} from './styled';

export type SectionBlurredReportProps = {
  zodiacSign: string;
  gender: string;
  report: {
    title: string;
    contentTitle: string;
    contentPoints: Array<string>;
    personalityTitle: string;
    personalityDescription: string;
    accessDescription: string;
    getAccess: string;
  };
  zodiacImage: string;
  onScrollToBlock: () => void;
  onGetReport: () => void;
};

const SectionBlurredReport = ({
  report,
  zodiacImage,
  onScrollToBlock,
}: SectionBlurredReportProps) => {
  const {
    title,
    contentTitle,
    contentPoints,
    personalityTitle,
    personalityDescription,
    accessDescription,
    getAccess,
  } = report;

  return (
    <BlurredReportWrapper>
      <BlurredReportBody>
        <ReportTitle>{title}</ReportTitle>
        <UserInfoBlock>
          <LogoImageBlock>
            <LogoImage alt="Nebula logo" src={logoPng.src} />
          </LogoImageBlock>
          <ZodiacImageBlock>
            <ZodiacImage height={146} src={zodiacImage} alt="Zodiac sign" />
          </ZodiacImageBlock>
        </UserInfoBlock>
        <ContentBlock>
          <ContentTitle>{contentTitle}</ContentTitle>
          <NumberedList>
            {contentPoints.map((text) => (
              <Item key={text}>
                <ItemText>{text}</ItemText>
              </Item>
            ))}
          </NumberedList>
          <ContentTitle>{personalityTitle}</ContentTitle>
          <ContentText>{personalityDescription}</ContentText>
        </ContentBlock>
        <BlurOuterContainer>
          <BlurInnerContainer>
            <AccessDescription>{accessDescription}</AccessDescription>
            <ActionButton impulseScale onClick={onScrollToBlock}>
              {getAccess}
            </ActionButton>
          </BlurInnerContainer>
        </BlurOuterContainer>
      </BlurredReportBody>
    </BlurredReportWrapper>
  );
};

export default SectionBlurredReport;
