import { prepareHtml } from 'core/common/utils/prepareHtml';
import { LoadingAskScreenProps } from 'core/funnel/entities';
import {
  Background,
  Circle,
  Container,
  IconStyled,
  List,
  ListItem,
  Loader,
  LoaderBackground,
  LoaderWrapper,
  Text,
  Title,
} from './styled';

const LoadingAskScreen = ({ content, onLoadingFinished }: LoadingAskScreenProps) => {
  const { title, list } = content;

  return (
    <>
      <Background />
      <Container>
        <Title dangerouslySetInnerHTML={prepareHtml(title)} />
        <LoaderWrapper>
          <LoaderBackground />
          <Loader onFinished={onLoadingFinished} />
        </LoaderWrapper>
        <List>
          {list.map((item, index) => (
            <ListItem key={item} value={index}>
              <Circle value={index}>
                <IconStyled name="check" value={index} />
              </Circle>
              <Text>{item}</Text>
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  );
};

export default LoadingAskScreen;
