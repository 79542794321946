import { useRef } from 'react';
import { useServices } from 'core/common/hooks';
import { usePopUps } from 'core/popUp/hooks';

export const useTakePhoto = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { analyticsService } = useServices();
  const { openCameraAccessAlertPopUp } = usePopUps();

  const startVideoStream = async (video: HTMLVideoElement) => {
    try {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: { ideal: 'environment' },
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      video.srcObject = stream;
      await video.play();

      analyticsService.cameraLaunched();
    } catch (err) {
      openCameraAccessAlertPopUp();
    }
  };

  const stopVideoStream = () => {
    if (!videoRef.current) return;
    const stream = videoRef.current.srcObject as MediaStream;

    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const getWebcamAccess = (videoHTMLElement: HTMLVideoElement | null) => {
    videoRef.current = videoHTMLElement;
    if (!videoRef.current) return;
    const video = videoRef.current;
    startVideoStream(video);
  };

  const takePhoto = async (width: number, height: number): Promise<Blob | void> => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return;

    canvas.width = width;
    canvas.height = height;

    const initialXcoordinate = 0;
    const initialYcoordinate = 0;

    context.drawImage(video, initialXcoordinate, initialYcoordinate, width, height);

    await stopVideoStream();

    const imageBlob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    });

    if (!imageBlob) return;

    return imageBlob;
  };

  return {
    getWebcamAccess,
    takePhoto,
    stopVideoStream,
  };
};
