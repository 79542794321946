import Image from 'core/common/components/Image';
import { formatDateByLocale } from 'core/common/utils/date';
import { FunnelImage } from 'core/funnel/entities';
import { useLanguage } from 'core/localization/hooks';
import {
  TitleStyled,
  MoonDetailsStyled,
  MoonPhaseStyled,
  ImageStyled,
  MoonPhaseTitleStyled,
  UserDetailsStyled,
  UserDetailsItemStyled,
  UserDetailsValueStyled,
  UserDetailsTitleStyled,
  MoonSignTextStyled,
  WrapperStyled,
} from './styled';

type UserDetailsProps = {
  title: string;
  moonPhase: string;
  birthDate: {
    title: string;
    value: string;
  };
  birthTime: {
    title: string;
    value: string;
  };
  moonSign: {
    title: string;
    value: string;
  };
  moonImage: FunnelImage;
  moonSignImage: FunnelImage;
};

const notApplicableLabel = 'N/A';

const UserDetails = ({
  title,
  moonImage,
  moonPhase,
  birthDate,
  birthTime,
  moonSign,
  moonSignImage,
}: UserDetailsProps) => {
  const { language } = useLanguage();

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <MoonDetailsStyled>
        <MoonPhaseStyled>
          <ImageStyled src={moonImage.src} alt={moonImage.alt} width={120} height={120} />
          <MoonPhaseTitleStyled>{moonPhase}</MoonPhaseTitleStyled>
        </MoonPhaseStyled>
        <UserDetailsStyled>
          <UserDetailsItemStyled>
            <UserDetailsTitleStyled>{birthDate.title}</UserDetailsTitleStyled>
            <UserDetailsValueStyled>
              {birthDate.value ? formatDateByLocale(birthDate.value, language) : notApplicableLabel}
            </UserDetailsValueStyled>
          </UserDetailsItemStyled>
          <UserDetailsItemStyled>
            <UserDetailsTitleStyled>{birthTime.title}</UserDetailsTitleStyled>
            <UserDetailsValueStyled>{birthTime.value || notApplicableLabel}</UserDetailsValueStyled>
          </UserDetailsItemStyled>
          <UserDetailsItemStyled>
            <UserDetailsTitleStyled>{moonSign.title}</UserDetailsTitleStyled>
            <UserDetailsValueStyled>
              <Image
                src={moonSignImage.src}
                width={16}
                height={16}
                alt={moonSignImage.alt}
                priority
              />
              <MoonSignTextStyled>{moonSign.value}</MoonSignTextStyled>
            </UserDetailsValueStyled>
          </UserDetailsItemStyled>
        </UserDetailsStyled>
      </MoonDetailsStyled>
    </WrapperStyled>
  );
};

export default UserDetails;
