import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
`;

export const StyledPlusImage = styled(Image)`
  flex-shrink: 0;
  margin-right: 28px;
  margin-left: 28px;
`;

export const AspectRatio = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
