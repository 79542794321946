import { useEffect } from 'react';
import OnboardingAskNatalChartScreen from 'core/common/components/QuizScreens/OnboardingAskNatalChart';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { OnboardingAskNatalChartScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type OnboardingAskNatalChartProps = {
  id: string;
};

const OnboardingAskNatalChart = ({ id }: OnboardingAskNatalChartProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onIntroPageOpened();
  }, [analyticsService]);

  return (
    <OnboardingAskNatalChartScreen
      {...(screenAttributes as OnboardingAskNatalChartScreenAttributes)}
    />
  );
};

export default OnboardingAskNatalChart;
