import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import UpsellsHeaderExperiment from 'core/common/components/UpsellsHeader/UpsellsHeaderExperiment';
import { TrialCreditsPalmistryScreenProps } from 'core/funnel/entities';
import SectionFooter from './SectionFooter';
import SectionPayment from './SectionPayment';
import { Button, ButtonWrapper, Content, Root, RouteLink } from './styled';

const TrialCreditsPalmistryScreen = ({
  content,
  plan,
  price,
  onBuyCredits,
  isLoading,
  onSkip,
  resignFormLoaded,
  paymentError,
  onClearPaymentStatus,
}: TrialCreditsPalmistryScreenProps) => {
  const { title, subtitle, offer, button, link, footer, banner } = content;

  return (
    <Root>
      <Header buttonBack={false} />
      <Content>
        <UpsellsHeaderExperiment title={title} subtitle={subtitle} banner={banner} />
        <SectionPayment plan={plan} price={price} content={offer} />
        <ButtonWrapper>
          <Button onClick={onBuyCredits} disabled={isLoading} loading={isLoading}>
            {button}
          </Button>
          <RouteLink disabled={isLoading} onClick={onSkip}>
            {link}
          </RouteLink>
        </ButtonWrapper>
        <SectionFooter content={footer} />
        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onBuyCredits}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </Content>
    </Root>
  );
};

export default TrialCreditsPalmistryScreen;
