import styled from 'styled-components';
import Emoji from 'core/common/components/Emoji';

export const BenefitsBlockStyled = styled.div`
  width: 100%;
  margin-bottom: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 20px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`;

export const ListStyled = styled.ul``;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 12px;
  }
`;

export const EmojiCircleStyled = styled.div<{ isLight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ isLight }) =>
    isLight ? 'rgba(106, 58, 162, 0.1)' : 'rgba(228, 131, 45, 0.1)'};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 50px;
    height: 50px;
  }
`;

export const ListItemTextStyled = styled.p`
  margin-left: 16px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 120%;
  }
`;

export const EmojiStyled = styled(Emoji)`
  font-size: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;
