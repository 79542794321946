import { InputHTMLAttributes, memo, ReactNode } from 'react';
import { ColorVariants } from './types';
import { Input, Label } from './styled';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | ReactNode;
  className?: string;
  variant?: ColorVariants;
};

const Checkbox = ({
  label,
  className = '',
  variant = 'primary',
  children,
  ...inputAttributes
}: CheckboxProps) => {
  const { name } = inputAttributes;

  return (
    <>
      <Input type="checkbox" id={name} name={name} variant={variant} {...inputAttributes} />
      <Label htmlFor={name} className={className} variant={variant}>
        {label || children}
      </Label>
    </>
  );
};

export default memo(Checkbox);
