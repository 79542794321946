import { useRef, useState } from 'react';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentPalmistryScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import Guarantee from './Guarantee';
import PalmistryDescription from './PalmistryDescription';
import PalmistryHeaderInfo from './PalmistryHeaderInfo';
import SalesScreenHeader from './SalesScreenHeader';
import SectionPartners from './SectionPartners';
import SectionPayment from './SectionPayment';
import SectionReviews from './SectionReviews';
import SectionSpecialOffer from './SectionSpecialOffer';
import SectionUserDetails from './SectionUserDetails';
import {
  ColoredBackgroundWrapperStyled,
  CommonContainerStyled,
  EmailAvatarStyled,
  FooterTextStyled,
  HeaderSpaceStyled,
  MainContainerWideStyled,
  SectionSubscriptionDisclaimerStyled,
  TitleStyled,
  UserEmailStyled,
} from './styled';

const offerScrollOffset = -130;

const offerBlockId = 'disclaimer-block';

const TrialPaymentPalmistryScreen = ({
  content,
  zodiacImage,
  email,
  timeLeft,
  currency,
  currencySymbol,
  fullPlan,
  discountPlan,
  discountAmount,
  trialPrice,
  trialPriceWithoutVAT,
  vat,
  productPlan,
  getReport,
  onSucceed,
  onPaymentDialogClose,
}: TrialPaymentPalmistryScreenProps) => {
  const {
    header,
    hero,
    title,
    specialOffer,
    disclaimer,
    moneyBackGuarantee,
    palmGuide,
    userDetails,
    reviews,
    featuredIn,
    footer,
  } = content;

  const firstButtonRef = useRef<HTMLDivElement>(null);

  const onMoveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const [isOpen, setIsOpen] = useState(false);

  const isTimeUp = timeLeft <= 0;

  const onGetReport = () => {
    setIsOpen(true);
    getReport();
  };

  const displayPriceWithCurrency = isTimeUp
    ? `${fullPlan} ${currency}`
    : `${discountPlan} ${currency}`;

  return (
    <>
      <SalesScreenHeader content={header} onScrollToBlock={onMoveToOffer} timeLeft={timeLeft} />
      <MainContainerWideStyled>
        <ColoredBackgroundWrapperStyled>
          <HeaderSpaceStyled />
          <UserEmailStyled>
            {email}
            <EmailAvatarStyled>{email[0]}</EmailAvatarStyled>
          </UserEmailStyled>
        </ColoredBackgroundWrapperStyled>
        <PalmistryHeaderInfo title={hero.title} />
        <CommonContainerStyled>
          <TitleStyled>{title}</TitleStyled>
          <SectionSpecialOffer
            content={specialOffer}
            id={offerBlockId}
            currencySymbol={currencySymbol}
            discountAmount={discountAmount}
            fullPlan={fullPlan}
            discountPlan={discountPlan}
            trialPrice={trialPrice}
            trialPriceWithoutVAT={trialPriceWithoutVAT}
            onGetReport={onGetReport}
            timeLeft={timeLeft}
            ref={firstButtonRef}
          />
          <SectionSubscriptionDisclaimerStyled
            content={disclaimer}
            displayPrice={displayPriceWithCurrency}
          />
          <Guarantee
            title={moneyBackGuarantee.title}
            description={moneyBackGuarantee.description}
          />
          <PalmistryDescription content={palmGuide} />
          <SectionUserDetails zodiacImage={zodiacImage} content={userDetails} />
          <SectionReviews content={reviews} />
          <SectionPartners title={featuredIn.title} />
          <Location>
            <LocationOption option={Locales.US}>
              <FooterTextStyled>{footer.addressUS}</FooterTextStyled>
            </LocationOption>
            <LocationOption option={Locales.AE}>
              <FooterTextStyled>{footer.addressAE}</FooterTextStyled>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <FooterTextStyled>{footer.address}</FooterTextStyled>
            </LocationOption>
          </Location>
        </CommonContainerStyled>
      </MainContainerWideStyled>
      <SectionPayment
        productPlan={productPlan.productId}
        onPaymentSuccess={onSucceed}
        isTimeUp={isTimeUp}
        isOpen={isOpen}
        vat={vat}
        currencySymbol={currencySymbol}
        price={trialPrice}
        currency={currency}
        onClose={onPaymentDialogClose}
      />
    </>
  );
};

export default TrialPaymentPalmistryScreen;
