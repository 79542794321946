import styled from 'styled-components';
import AspectRatio from '../AspectRatio';
import Image from '../Image';
import Modal from '../Modal';

export const ModalStyled = styled(Modal)`
  width: 100%;
  max-width: 343px;
  padding: 24px 20px;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 400px;
  }
`;

export const AspectRatioStyled = styled(AspectRatio)`
  margin-bottom: 16px;
`;

export const ImageStyled = styled(Image)`
  object-fit: cover;
`;

export const QuestionStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 135%;
  text-align: center;
`;

// Default "Button" will be replaced with these styles
export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border-radius: 100px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.themeBackgroundSemiLight};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #dedede;
  }

  &:active {
    background-color: #c2c2c2;
  }

  &:first-child {
    margin-right: 8px;
  }
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
`;
