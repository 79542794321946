import Steps from './Steps';
import {
  BannerDescriptionStyled,
  BannerExperimentStyled,
  BannerTextStyled,
  BannerTitleStyled,
  EmojiStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

type UpsellsHeaderExperimentProps = {
  title?: string;
  subtitle?: string;
  banner?: {
    title: string;
    text: string;
    emoji: string;
  };
};

const UpsellsHeaderExperiment = ({ title, subtitle, banner }: UpsellsHeaderExperimentProps) => {
  return (
    <>
      <Steps />
      {!!banner && (
        <BannerExperimentStyled>
          <EmojiStyled>{banner.emoji}</EmojiStyled>
          <BannerDescriptionStyled>
            <BannerTitleStyled>{banner.title}</BannerTitleStyled>
            <BannerTextStyled>{banner.text}</BannerTextStyled>
          </BannerDescriptionStyled>
        </BannerExperimentStyled>
      )}
      {title && <TitleStyled>{title}</TitleStyled>}
      {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
    </>
  );
};

export default UpsellsHeaderExperiment;
