import { useCallback, useEffect, useState } from 'react';
import TrialChoiceScreen from 'core/common/components/QuizScreens/TrialChoice';
import { useRouter, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialChoiceScreenAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';

type TrialChoiceProps = {
  id: string;
};

const TrialChoice = ({ id }: TrialChoiceProps) => {
  const { navigate } = useRouter();
  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);
  const { updateOffer, defaultOffers: offers, loadingStatus } = useOffers();
  const { orderId } = useOrder();

  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);

  useEffect(() => {
    analyticsService.landingPageOpened('trial_choice');
  }, [analyticsService]);

  const submitChoice = useCallback(() => {
    if (!selectedOffer) return;

    updateOffer(selectedOffer);

    analyticsService.landingPageClick('trial_choice', selectedOffer.offerId);
    analyticsService.paymentPlanChosen(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, navigate, selectedOffer]);

  const onSubmit = () => {
    submitChoice();
    next();
  };

  const changeSelectedOffer = (offer: Offer) => {
    setSelectedOffer(offer);
  };

  return (
    <TrialChoiceScreen
      selectedOffer={selectedOffer}
      offers={offers}
      loadingStatus={loadingStatus}
      onSubmit={onSubmit}
      changeSelectedOffer={changeSelectedOffer}
      {...(screenAttributes as TrialChoiceScreenAttributes)}
    />
  );
};

export default TrialChoice;
