import ticketMedal from 'core/common/assets/images/ticketMedal.png';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionDiscountTicketAttributes } from 'core/funnel/entities';
import {
  TicketCoreStyled,
  DiscountLabelStyled,
  DiscountTextStyled,
  DiscountTextTipWrapperStyled,
  DiscountTextTipStyled,
  DiscountImageWrapperStyled,
} from './styled';

export type SectionDiscountTicketProps = {
  content: SectionDiscountTicketAttributes['content'];
  className?: string;
  onClick: () => void;
};

const SectionDiscountTicket = ({ content, className, onClick }: SectionDiscountTicketProps) => {
  const { discountValue, discountText } = content;

  return (
    <TicketCoreStyled onClick={onClick} role="button" className={className}>
      <DiscountLabelStyled>
        <DiscountImageWrapperStyled>
          <Image width={72} height={72} alt="" src={ticketMedal} />
        </DiscountImageWrapperStyled>
        <DiscountTextStyled dangerouslySetInnerHTML={prepareHtml(discountValue)} />
      </DiscountLabelStyled>
      <DiscountTextTipWrapperStyled>
        <DiscountTextTipStyled dangerouslySetInnerHTML={prepareHtml(discountText)} />
      </DiscountTextTipWrapperStyled>
    </TicketCoreStyled>
  );
};

export default SectionDiscountTicket;
