import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';

export const BenefitsBlock = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  margin-top: 8px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
    font-size: 32px;
    line-height: 48px;
  }
`;

export const List = styled.ul`
  margin-top: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 24px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 12px;
  }
`;

export const EmojiCircle = styled.div<{ ligth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ ligth }) =>
    ligth ? 'rgba(106, 58, 162, 0.1)' : 'rgba(228, 131, 45, 0.1)'};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 50px;
    height: 50px;
  }
`;

export const ListItemText = styled.p`
  margin-left: 16px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Emoji = styled(EmojiComponent)`
  width: 16px;
  height: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 24px;
    height: 24px;
  }
`;
