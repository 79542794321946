import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import TrialPaymentWithDiscountPalmistryScreen from 'core/common/components/QuizScreens/TrialPaymentWithDiscountPalmistry';
import { LoadingStatuses } from 'core/common/entities';
import { useDispatch, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialPaymentWithDiscountPalmistryScreenAttributes } from 'core/funnel/entities';
import { useGetActualPaymentPlans, useProduct } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { europeanCountries, firstTierCountries, Locales } from 'core/user/entities';
import { useUserLocation } from 'core/user/hooks';
import { getEmail, getPlan, updateUserInformation } from 'core/user/store';

type TrialPaymentWithDiscountPalmistryProps = {
  id: string;
};

const TrialPaymentWithDiscountPalmistry = ({ id }: TrialPaymentWithDiscountPalmistryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { next, screenAttributes } = useQuizStep(id);
  const { locale } = useUserLocation();
  const { analyticsService } = useServices();
  const { currencySymbol, currency } = useGetActualPaymentPlans();

  const email = useSelector(getEmail);
  const { orderId } = useOrder();
  const selectedSubscription = useSelector(getPlan);

  const attributes = screenAttributes as TrialPaymentWithDiscountPalmistryScreenAttributes;

  const { planUSBucket, planEuropeanBucket, planFirstTierBucket, planSecondTierBucket } =
    attributes.content;

  const getProductPlan = () => {
    if (locale === Locales.US) {
      return planUSBucket;
    }

    if (europeanCountries.includes(locale)) {
      return planEuropeanBucket;
    }

    if (firstTierCountries.includes(locale)) {
      return planFirstTierBucket;
    }

    return planSecondTierBucket;
  };

  const productPlan = getProductPlan();

  const { price, loadingStatus, vat, priceWithoutVAT, period, secretPrice } = useProduct(
    productPlan.productId,
  );

  const isPriceLoaded = loadingStatus === LoadingStatuses.FULFILLED;

  useEffect(() => {
    if (productPlan && isPriceLoaded) {
      analyticsService.landingPageOpened('trial_billing');
      analyticsService.addToCart({
        price,
        productId: productPlan.productId,
        email,
        orderId,
        currency,
      });
    }
  }, [analyticsService, productPlan, price, isPriceLoaded, email, orderId, currency]);

  const getReport = useCallback(() => {
    analyticsService.initiateCheckout({
      price,
      productId: productPlan.productId,
      email,
      orderId,
      currency,
    });

    analyticsService.landingPageClick('trial_billing', productPlan.productId);
    dispatch(updateUserInformation('plan', productPlan));
  }, [analyticsService, dispatch, price, productPlan, email, orderId, currency]);

  const paymentSucceed = useCallback(() => {
    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next, productPlan, analyticsService]);

  const onGetReport = () => {
    getReport();
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <TrialPaymentWithDiscountPalmistryScreen
      price={price}
      currencySymbol={currencySymbol}
      currency={currency}
      onGetReport={onGetReport}
      productPlan={productPlan}
      paymentSucceed={paymentSucceed}
      onModalClose={onModalClose}
      vat={vat}
      priceWithoutVAT={priceWithoutVAT}
      period={period}
      secretPrice={secretPrice}
      oldPrice={selectedSubscription?.oldPrice ?? 19}
      isModalOpen={isModalOpen}
      {...attributes}
    />
  );
};

export default TrialPaymentWithDiscountPalmistry;
