import steps from 'core/common/assets/images/upsells/steps_3.0.svg';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { UpsellsNatalChartScreenAttributes } from 'core/funnel/entities';
import {
  Banner,
  BannerContent,
  BannerText,
  BannerTitle,
  FireEmoji,
  Progress,
  SmileEmoji,
  Step1,
  Step2,
  Step3,
  StepsImage,
  Subtitle,
  Title,
} from './styled';

type SectionHeaderProps = {
  content: UpsellsNatalChartScreenAttributes['content']['header'];
};

const SectionHeader = ({ content }: SectionHeaderProps) => {
  const {
    buyStep,
    consultationStep,
    accessProductStep,
    bannerEmoji,
    bannerTitle,
    bannerText,
    title,
    subtitle,
  } = content;

  return (
    <>
      <Progress>
        <Step1 dangerouslySetInnerHTML={prepareHtml(buyStep)} />
        <Step2 dangerouslySetInnerHTML={prepareHtml(consultationStep)} />
        <Step3 dangerouslySetInnerHTML={prepareHtml(accessProductStep)} />
      </Progress>
      <StepsImage src={steps} alt="step" height={31} width={100} />
      <Banner>
        <SmileEmoji emoji={bannerEmoji} />
        <BannerContent>
          <BannerTitle>{bannerTitle}</BannerTitle>
          <BannerText>{bannerText}</BannerText>
        </BannerContent>
      </Banner>
      <Title>
        {title.text}
        <FireEmoji emoji={title.emoji} />
      </Title>
      <Subtitle>{subtitle}</Subtitle>
    </>
  );
};

export default SectionHeader;
