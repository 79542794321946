import { ReactNode } from 'react';
import { ButtonStyled, WrapperStyled } from './styled';

type SectionStickyPaymentButtonProps = {
  children: ReactNode;
  onGetReport: () => void;
};

// Experiment NEB-11163
const SectionStickyPaymentButton = ({
  children,
  onGetReport,
  ...props
}: SectionStickyPaymentButtonProps) => {
  return (
    <WrapperStyled {...props}>
      <ButtonStyled onClick={onGetReport}>{children}</ButtonStyled>
    </WrapperStyled>
  );
};

export default SectionStickyPaymentButton;
