import { forwardRef } from 'react';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';

import { Offer } from 'core/offers/entities';
import { notPaidUtmSources, paidUtmSources } from 'core/user/entities';
import {
  ActionButton,
  BodyHeader,
  BodyHeaderPrice,
  BodyHeaderTitle,
  ButtonWrapper,
  CardBody,
  CardHeader,
  CardWrapper,
  DiscountBlock,
  GuaranteeBlock,
  GuaranteeIconStyled,
  GuaranteeText,
  HeaderTitle,
  HorizontalLine,
  SubscriptionActualPrice,
  SubscriptionBlock,
  SubscriptionDescription,
  SubscriptionOldPrice,
  SubscriptionPricesBlock,
  TotalBlock,
  TotalPrice,
  TotalTitle,
} from './styled';

export type SpecialOfferProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['specialOffer'];
  id?: string;
  timeLeft: number;
  onGetReport: () => void;
  offer: Offer;
  timeUpOffer: Offer;
};

const SectionSpecialOffer = forwardRef<HTMLDivElement, SpecialOfferProps>(
  ({ content, id, timeLeft, offer, timeUpOffer, onGetReport }: SpecialOfferProps, ref) => {
    const { header, title, totalText, costText, costTextOrganic, button, guranteeText } = content;

    const isTimeUp = timeLeft <= 0;
    const offerZeroPrice = 0;

    return (
      <>
        <CardWrapper id={id}>
          <CardHeader>
            <HeaderTitle>{header}</HeaderTitle>
          </CardHeader>
          <CardBody>
            <BodyHeader>
              <BodyHeaderTitle>{title}</BodyHeaderTitle>
              <BodyHeaderPrice>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</BodyHeaderPrice>
            </BodyHeader>
            <TotalBlock>
              <TotalTitle>{totalText}</TotalTitle>
              <TotalPrice>{offer.pricesWithCurrencySymbol.trialPriceWithVAT}</TotalPrice>
            </TotalBlock>
            <HorizontalLine />
            <DiscountBlock></DiscountBlock>
            <SubscriptionBlock>
              <MediaSourceVariant name={paidUtmSources}>
                <SubscriptionDescription>{costText}</SubscriptionDescription>
                <SubscriptionPricesBlock>
                  <SubscriptionOldPrice>
                    {isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT}
                  </SubscriptionOldPrice>
                  <SubscriptionActualPrice>
                    {offer.currencySymbol}
                    {offerZeroPrice}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
              <MediaSourceVariant name={notPaidUtmSources}>
                <SubscriptionDescription dangerouslySetInnerHTML={prepareHtml(costTextOrganic)} />
                <SubscriptionPricesBlock>
                  <SubscriptionOldPrice>
                    {!isTimeUp && timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}
                  </SubscriptionOldPrice>
                  <SubscriptionActualPrice>
                    {isTimeUp
                      ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
                      : offer.pricesWithCurrencySymbol.priceWithVAT}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
            </SubscriptionBlock>
          </CardBody>
        </CardWrapper>
        <ButtonWrapper ref={ref}>
          <ActionButton impulseScale onClick={onGetReport}>
            {button}
          </ActionButton>
        </ButtonWrapper>
        <GuaranteeBlock>
          <GuaranteeIconStyled name="guarantee" width={24} height={24} />
          <GuaranteeText>{guranteeText}</GuaranteeText>
        </GuaranteeBlock>
      </>
    );
  },
);

export default SectionSpecialOffer;
