import styled from 'styled-components';
import Image from '../Image';

export const CardStyled = styled.div`
  max-width: 450px;
  height: 100%;
  padding: 16px 16px 8px;
  border: 1px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 16px;
  background-color: #fafafa;
`;

export const AvatarStyled = styled(Image)`
  flex-shrink: 0;
  border-radius: 50%;
  object-position: top;
`;

export const BioWrapperStyled = styled.header`
  display: flex;
  align-items: flex-start;
`;

export const NameStyled = styled.h3`
  font-size: 20px;
  line-height: 28px;
`;

export const ExpertBioStyled = styled.div`
  margin-left: 8px;
`;

export const DetailsListStyled = styled.ul`
  display: flex;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const DetailsItemStyled = styled.li`
  width: calc(100% / 3);
`;

export const FieldsTitleStyled = styled.h4`
  font-weight: 600;
`;

export const DetailsTitleStyled = styled.h5`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const DetailsSubtitleStyled = styled.p`
  font-size: 14px;
  line-height: 21px;
`;

export const TitleIconStyled = styled(Image)`
  margin-left: 4px;
`;

export const FieldsListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const FieldsListItemStyled = styled.li`
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 12px;
  border: 1px solid ${({ theme }) => theme.colors.info};
  border-radius: 200px;
  font-size: 12px;
  line-height: 18px;
`;
