import { useEffect, useState } from 'react';
import { CarouselSettings } from 'core/common/components/Carousel';
import Header from 'core/common/components/Header';
import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SummaryV2ScreenProps as SummaryV2Props } from 'core/funnel/entities';
import { BreakpointValues } from 'core/theme/entities';
import PointCard from './PointCard';
import { mapPointsToValues } from './utils';
import {
  ContainerStyled,
  HeadingStyled,
  TitleStyled,
  CircularProgressV2Styled,
  AspectRatioStyled,
  ImageStyled,
  FooterStyled,
  ButtonStyled,
  CarouselStyled,
  SliderPrevButtonStyled,
  ArrowIconStyled,
  SliderNextButtonStyled,
} from './styled';

const progressAnimationDurationMs = 15;
const defaultCarouselSettings: CarouselSettings = {
  speed: 600,
  slidesToShow: 1,
  dots: true,
  arrows: true,
  infinite: false,
  prevArrow: (
    <SliderPrevButtonStyled>
      <ArrowIconStyled name="arrow-simple" />
    </SliderPrevButtonStyled>
  ),
  nextArrow: (
    <SliderNextButtonStyled>
      <ArrowIconStyled name="arrow-simple" />
    </SliderNextButtonStyled>
  ),
  responsive: [
    {
      breakpoint: BreakpointValues.MAX_MOBILE_WIDTH,
      settings: {
        slidesToShow: 1.1,
        dots: false,
        arrows: false,
      },
    },
  ],
};

const SummaryV2 = ({ content, hasButtonBack, goBack, goNext }: SummaryV2Props) => {
  const { title, image, buttonText, points } = content;

  const [progress, setProgress] = useState(0);
  const [mappedPoints] = useState(() => mapPointsToValues(points));
  const [toxicRelationshipValue] = useState(() => getRandomNumberWithinRange(60, 95));

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const animateProgress = () => {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 0.5;

          if (newProgress >= toxicRelationshipValue) {
            clearInterval(intervalId);
            return toxicRelationshipValue;
          }

          return newProgress;
        });
      }, progressAnimationDurationMs);
    };

    animateProgress();

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={goBack} />
      <ContainerStyled>
        <HeadingStyled>
          <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
          <CircularProgressV2Styled progress={progress} size={70} />
        </HeadingStyled>
        <AspectRatioStyled width={400} height={386}>
          <ImageStyled src={image.src} alt={image.alt} fill />
        </AspectRatioStyled>
        <FooterStyled>
          <CarouselStyled {...defaultCarouselSettings}>
            {mappedPoints.map((point) => (
              <PointCard
                key={point.value}
                text={point.text}
                icon={point.icon}
                title={point.title}
                value={point.value}
              />
            ))}
          </CarouselStyled>
          <ButtonStyled fullWidth onClick={goNext}>
            {buttonText}
          </ButtonStyled>
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default SummaryV2;
