import { useEffect } from 'react';
import AdditionalDiscountScreen from 'core/common/components/QuizScreens/AdditionalDiscount';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AdditionalDiscountScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';

type AdditionalDiscountProps = {
  id: string;
};

const AdditionalDiscount = ({ id }: AdditionalDiscountProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountOffer, timeUpOffer } = useOffers();

  const onGetReport = () => {
    analyticsService.discountLpGetReport();
  };

  const onContinue = () => {
    onGetReport();
    next();
  };

  useEffect(() => {
    analyticsService.discountLpOpened();
  }, [analyticsService]);

  return (
    <AdditionalDiscountScreen
      discountOffer={discountOffer}
      timeUpOffer={timeUpOffer}
      onContinue={onContinue}
      {...(screenAttributes as AdditionalDiscountScreenAttributes)}
    />
  );
};

export default AdditionalDiscount;
