import { useIsMobile } from 'core/theme/hooks';
import Timer from './Timer';
import { ActionButton, ButtonContainer, Header, HeaderCell, Title } from './styled';

export type SalesScreenHeaderProps = {
  title: string;
  button: string;
  timerMinTitle: string;
  timerSecTitle: string;
  timeLeft: number;
  hide: boolean;
  onScrollToBlock: () => void;
};

const SalesScreenHeader = ({
  timeLeft,
  onScrollToBlock,
  hide,
  title,
  button,
  timerMinTitle,
  timerSecTitle,
}: SalesScreenHeaderProps) => {
  const { isMobile } = useIsMobile();

  return (
    <Header hide={hide}>
      {!isMobile && (
        <HeaderCell>
          <Title>{title}</Title>
        </HeaderCell>
      )}
      <HeaderCell justify={isMobile ? 'flex-start' : 'center'}>
        <Timer minTitle={timerMinTitle} secTitle={timerSecTitle} timeLeft={timeLeft} />
      </HeaderCell>
      <HeaderCell justify="flex-end">
        <ButtonContainer>
          <ActionButton impulseScale onClick={onScrollToBlock}>
            {button}
          </ActionButton>
        </ButtonContainer>
      </HeaderCell>
    </Header>
  );
};

export default SalesScreenHeader;
