import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import QuizBackground from 'core/common/components/QuizBackground';
import UpsellsStepper from 'core/common/components/UpsellsStepper';
import { UpsellBlackMoonScreenProps } from 'core/funnel/entities';
import {
  ActionButtonStyled,
  AddressStyled,
  BackgroundImageStyled,
  BackgroundImageWrapperStyled,
  ContentStyled,
  DescriptionStyled,
  DisclaimerStyled,
  DiscountStyled,
  ListItemImageStyled,
  ListItemStyled,
  ListStyled,
  OfferStyled,
  OfferTitleStyled,
  OldPriceStyled,
  PriceStyled,
  SkipButtonStyled,
  ThinContainerStyled,
  TitleStyled,
} from './styled';

const UpsellBlackMoonScreen = ({
  content,
  isLoading,
  resignFormLoaded,
  onGetReport,
  onSkip,
  paymentError,
  onClearPaymentStatus,
}: UpsellBlackMoonScreenProps) => {
  const { title, description, list, button, skipButton, upsellsSteps, disclaimer, offer, image } =
    content;

  return (
    <>
      <QuizBackground variant="dark" />
      <Header buttonBack={false} variant="secondary" />
      <ContentStyled>
        <UpsellsStepper steps={upsellsSteps} variant="light" />
        <BackgroundImageWrapperStyled>
          <BackgroundImageStyled src={image.src} alt={image.alt} fill />
        </BackgroundImageWrapperStyled>
        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
        <ListStyled>
          {list.map((item) => (
            <ListItemStyled key={item.title}>
              <ListItemImageStyled src={item.icon.src} alt="" width={20} height={20} />
              {item.title}
            </ListItemStyled>
          ))}
        </ListStyled>
        <ThinContainerStyled>
          <OfferStyled>
            <OfferTitleStyled>{offer.title}</OfferTitleStyled>
            <OldPriceStyled>{offer.oldPrice}</OldPriceStyled>
            <PriceStyled>{offer.price}</PriceStyled>
            <DiscountStyled>{offer.discount}</DiscountStyled>
          </OfferStyled>
          <ActionButtonStyled onClick={onGetReport} disabled={isLoading} loading={isLoading}>
            {button}
          </ActionButtonStyled>
          <SkipButtonStyled onClick={onSkip} disabled={isLoading}>
            {skipButton}
          </SkipButtonStyled>
          <DisclaimerStyled>{disclaimer}</DisclaimerStyled>
          <AddressStyled />
        </ThinContainerStyled>
      </ContentStyled>
      <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
      <PaymentDialogFailed
        paymentError={null}
        onTryAgain={onGetReport}
        onClose={onClearPaymentStatus}
        isOpen={!!paymentError}
      />
    </>
  );
};

export default UpsellBlackMoonScreen;
