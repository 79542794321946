import SingleZodiacInfoScreen from 'core/common/components/QuizScreens/SingleZodiacInfo';
import { useQuizStep } from 'core/funnel/contexts';
import { SingleZodiacInfoScreenAttributes } from 'core/funnel/entities';
import { getZodiacPersonImage } from 'core/zodiac/utils/getZodiacPersonImage';

export interface SingleZodiacInfoProps {
  id: string;
}

const SingleZodiacInfo = ({ id }: SingleZodiacInfoProps) => {
  const { back, screenAttributes, next, userPreferences } = useQuizStep(id);

  const onBack = () => back();

  const zodiacImage = getZodiacPersonImage(userPreferences.zodiac, userPreferences.gender);

  return (
    <SingleZodiacInfoScreen
      onBack={onBack}
      goNext={next}
      zodiacImage={zodiacImage}
      {...(screenAttributes as SingleZodiacInfoScreenAttributes)}
    />
  );
};

export default SingleZodiacInfo;
