import accept from 'core/common/assets/images/acceptMark.svg';
import checkmark from 'core/common/assets/images/thankYou/checkmark-background.png';
import AnimatedImage from 'core/common/components/AnimatedImages';
import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { AnimatedImages, ThankYouWithRedirectScreenProps } from 'core/funnel/entities';
import {
  StyledBackground,
  StyledConfettiWrapper,
  StyledContainer,
  StyledDescription,
  StyledImage,
  StyledImageDone,
  StyledImageWrapper,
  StyledTitle,
} from './styled';

const ThankYouWithRedirectScreen = ({ content }: ThankYouWithRedirectScreenProps) => {
  return (
    <>
      <Header variant="secondary" />
      <StyledBackground />
      {content.confetti && (
        <>
          <StyledConfettiWrapper>
            <AnimatedImage variant={AnimatedImages.CONFETTI} />
          </StyledConfettiWrapper>
          <StyledConfettiWrapper>
            <AnimatedImage variant={AnimatedImages.CONFETTI_WITH_FIREWORK} />
          </StyledConfettiWrapper>
        </>
      )}
      <StyledContainer>
        <StyledImageWrapper>
          <StyledImage src={checkmark} width={100} height={100} alt="" />
          <StyledImageDone src={accept} width={30} height={22} alt="accept" />
        </StyledImageWrapper>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledDescription dangerouslySetInnerHTML={prepareHtml(content.description)} />
      </StyledContainer>
    </>
  );
};

export default ThankYouWithRedirectScreen;
