import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { OnboardingAskV2ScreenProps } from 'core/funnel/entities';
import { DescriptionStyled, MessageStyled, MessageWrapperStyled } from './styled';

type IntroProps = OnboardingAskV2ScreenProps['content']['intro'];

const Intro = ({ description, image, message }: IntroProps) => {
  return (
    <>
      <MessageWrapperStyled>
        <MessageStyled src={message.src} alt={message.alt} width={343} height={286} />
        <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(description)} />
      </MessageWrapperStyled>
      <Image src={image.src} width={150} height={150} alt={image.alt} />
    </>
  );
};

export default Intro;
