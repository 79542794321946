import { useAnimatedVisibleItems } from 'core/common/hooks';
import { EmailsBlockWithAutoAddAnimation } from './styled';

type SectionAnimatedInformationProps = {
  list: string[];
  title: string;
};

const SectionAnimatedInformation = ({ list, title }: SectionAnimatedInformationProps) => {
  const { animateIndex, visibleItems, users } = useAnimatedVisibleItems({
    list,
    storageKey: 'users-joined',
  });

  return (
    <EmailsBlockWithAutoAddAnimation
      title={title}
      list={visibleItems}
      counter={users}
      animateIndex={animateIndex}
    />
  );
};

export default SectionAnimatedInformation;
