import Image from 'core/common/components/Image';
import { formatDateByLocale } from 'core/common/utils/date';
import { useLanguage } from 'core/localization/hooks';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled,
  IframeStyled,
  BirthDataListStyled,
  BirthDataItemStyled,
  BirthDataTitle,
  BirthDataValue,
  IframeWrapperStyled,
} from './styled';

type BirthData = {
  title: string;
  value: string;
};

type HumanDesignDetailsProps = {
  title: string;
  subtitle: string;
  birthDate: BirthData;
  birthTime: BirthData;
  birthPlace: BirthData;
  chartUrl: string;
  hasReport: boolean;
  onChartLoaded: () => void;
};

const birthdateUnavaliablePlaceholder = 'Unknown';

const HumanDesignDetails = ({
  title,
  subtitle,
  birthDate,
  birthTime,
  birthPlace,
  chartUrl,
  hasReport,
  onChartLoaded,
}: HumanDesignDetailsProps) => {
  const { language } = useLanguage();

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
      {hasReport ? (
        <IframeWrapperStyled>
          <IframeStyled src={chartUrl} scrolling="no" onLoad={onChartLoaded} />
        </IframeWrapperStyled>
      ) : (
        <Image
          src={chartUrl}
          width={400}
          height={300}
          onLoad={onChartLoaded}
          alt="human design schema"
        />
      )}
      <BirthDataListStyled>
        <BirthDataItemStyled>
          <BirthDataTitle>{birthDate.title}</BirthDataTitle>
          <BirthDataValue>{formatDateByLocale(birthDate.value, language)}</BirthDataValue>
        </BirthDataItemStyled>
        <BirthDataItemStyled>
          <BirthDataTitle>{birthTime.title}</BirthDataTitle>
          <BirthDataValue>{birthTime.value || birthdateUnavaliablePlaceholder}</BirthDataValue>
        </BirthDataItemStyled>
        <BirthDataItemStyled>
          <BirthDataTitle>{birthPlace.title}</BirthDataTitle>
          <BirthDataValue>{birthPlace.value}</BirthDataValue>
        </BirthDataItemStyled>
      </BirthDataListStyled>
    </WrapperStyled>
  );
};

export default HumanDesignDetails;
