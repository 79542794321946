import { RelationshipTypes, TrialPaymentScreenAttributes } from 'core/funnel/entities';
import { ZodiacBlock, ZodiacContainer, ZodiacImage, ZodiacLabel } from './styled';

export type ZodiacImagesBlockProps = {
  relationshipType: RelationshipTypes;
  zodiacImage: string;
  partnerZodiacImage: string;
  userDetails: TrialPaymentScreenAttributes['content']['userDetails'];
};

const ZodiacImagesBlock = ({
  relationshipType,
  userDetails,
  zodiacImage,
  partnerZodiacImage,
}: ZodiacImagesBlockProps) => {
  const { zodiacSign, partnerZodiacSign, youImageText, partnerImageText } = userDetails;

  if (relationshipType === RelationshipTypes.SINGLE) {
    return (
      <ZodiacContainer>
        <ZodiacImage height={220} src={zodiacImage} alt={zodiacSign} />
      </ZodiacContainer>
    );
  }

  return (
    <ZodiacContainer>
      <ZodiacBlock>
        <ZodiacImage src={zodiacImage} alt={zodiacSign} />
        <ZodiacLabel>{youImageText}</ZodiacLabel>
      </ZodiacBlock>
      <ZodiacBlock>
        <ZodiacImage src={partnerZodiacImage} alt={partnerZodiacSign} />
        <ZodiacLabel>{partnerImageText}</ZodiacLabel>
      </ZodiacBlock>
    </ZodiacContainer>
  );
};

export default ZodiacImagesBlock;
