import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const salesScreenHeaderHeight = 62;
export const Header = styled.header`
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
  height: ${salesScreenHeaderHeight}px;
  padding: 8px 15px;
  background-color: ${({ theme }) => theme.colors.typography100};
`;

export const ButtonContainer = styled.div`
  width: 140px;
  height: 32px;
  background: #fff;
  backface-visibility: hidden;
`;

export const ActionButton = styled(Button)`
  font-weight: 700;
`;
