import { ReactNode } from 'react';
import { TooltipContainer, TooltipText } from './styled';

interface TooltipProps {
  className?: string;
  children: ReactNode;
  text: string;
}

const Tooltip = ({ className = '', children, text }: TooltipProps) => (
  <TooltipContainer className={className}>
    <TooltipText>{text}</TooltipText>
    {children}
  </TooltipContainer>
);

export default Tooltip;
