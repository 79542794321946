import { useFormik } from 'formik';
import arrowImage from 'core/common/assets/images/arrowPointer.svg';
import EmailSuggestionsList from 'core/common/components/EmailSuggestionsList';
import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { isEmailValid } from 'core/common/utils/string';
import { EmailBlurredScreenProps } from 'core/funnel/entities';
import {
  ArrowImageStyled,
  ButtonStyled,
  ContainerStyled,
  EmailCaptionIconStyled,
  EmailCaptionStyled,
  FooterStyled,
  FormStyled,
  InputStyled,
  InputWrapperStyled,
  MainImageInnerWrapperStyled,
  MainImageOuterWrapperStyled,
  MainImageStyled,
  PolicyStyled,
  ReportTypeIconStyled,
  ReportTypeIconWrapperStyled,
  TextStyled,
  TitleStyled,
} from './styled';

const EmailBlurredScreen = ({ content, initialValue, onSubmit }: EmailBlurredScreenProps) => {
  const { getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue } =
    useFormik({
      initialValues: {
        email: initialValue,
      },
      validate: ({ email }) => {
        if (!email || !isEmailValid(email)) {
          return { email: content.error };
        }

        return {};
      },
      onSubmit: async (submitValues, helpers) => {
        await onSubmit(submitValues.email, helpers);
      },
    });

  const error = touched.email ? errors.email : '';

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ContainerStyled>
        <MainImageOuterWrapperStyled>
          <MainImageInnerWrapperStyled blurred={content.blurred}>
            <MainImageStyled src={content.image.src} alt={content.image.alt} fill />
            <ReportTypeIconWrapperStyled>
              <ReportTypeIconStyled
                src={content.reportTypeIcon.src}
                alt={content.reportTypeIcon.alt}
                width={20}
                height={20}
              />
            </ReportTypeIconWrapperStyled>
          </MainImageInnerWrapperStyled>
          <ArrowImageStyled src={arrowImage} alt="Arrow" width={60} height={75} />
        </MainImageOuterWrapperStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <TextStyled dangerouslySetInnerHTML={prepareHtml(content.text)} />
        <FormStyled onSubmit={handleSubmit}>
          <InputWrapperStyled>
            <InputStyled
              {...getFieldProps('email')}
              error={!!error}
              errorText={error}
              placeholder={content.placeholder}
            />
            <EmailSuggestionsList
              input={values.email}
              onSelect={(value) => setFieldValue('email', value)}
            />
          </InputWrapperStyled>
          <EmailCaptionStyled>
            {content.emailCaption.icon && (
              <EmailCaptionIconStyled
                src={content.emailCaption.icon.src}
                alt={content.emailCaption.icon.alt}
                width={12}
                height={12}
              />
            )}
            {content.emailCaption.text}
          </EmailCaptionStyled>
          <FooterStyled>
            <ButtonStyled fullWidth type="submit" disabled={isSubmitting}>
              {content.button}
            </ButtonStyled>
            <PolicyStyled />
          </FooterStyled>
        </FormStyled>
      </ContainerStyled>
    </>
  );
};

export default EmailBlurredScreen;
