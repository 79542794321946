import { useEffect } from 'react';
import QuestionsNatalChartScreen from 'core/common/components/QuizScreens/QuestionsNatalChart';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { QuestionsNatalChartScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type QuestionsNatalChartProps = {
  id: string;
};

const QuestionsNatalChart = ({ id }: QuestionsNatalChartProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onQuestionsPageOpened();
  }, [analyticsService]);

  return (
    <QuestionsNatalChartScreen {...(screenAttributes as QuestionsNatalChartScreenAttributes)} />
  );
};

export default QuestionsNatalChart;
