import { useState } from 'react';
import Header from 'core/common/components/Header';
import { PaymentDialog } from 'core/common/components/Payment';
import Banner from 'core/common/components/QuizScreensSections/SectionBanner';
import SectionBannerWithTimer from 'core/common/components/QuizScreensSections/SectionBannerWithTimer';
import SectionBannerWithTimerAndOffer from 'core/common/components/QuizScreensSections/SectionBannerWithTimerAndOffer';
import SectionBlurredContent from 'core/common/components/QuizScreensSections/SectionBlurredContent';
import SectionFaq from 'core/common/components/QuizScreensSections/SectionFaq';
import SectionInfo from 'core/common/components/QuizScreensSections/SectionInfo';
import SectionInsights from 'core/common/components/QuizScreensSections/SectionInsights';
import SectionMentionedIn from 'core/common/components/QuizScreensSections/SectionMentionedIn';
import SectionMoneyBackGuarantee from 'core/common/components/QuizScreensSections/SectionMoneyBackGuarantee';
import SectionQuote from 'core/common/components/QuizScreensSections/SectionQuote';
import SectionStickyPaymentButton from 'core/common/components/QuizScreensSections/SectionStickyButton';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { SECOND } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailMarketingNatalChartScreenProps, FunnelName } from 'core/funnel/entities';
import { SectionType, SectionAttributesUnionType } from 'core/funnel/entities/Section';
import {
  BackgroundStyled,
  ContentStyled,
  DescriptionStyled,
  SectionBenefitsStyled,
  SectionOfferStyled,
  SectionReviewsStyled,
} from './styled';

const EmailMarketingNatalChartScreen = ({
  offer,
  sectionEnd,
  sections,
  onGetReport,
  paymentSucceed,
}: EmailMarketingNatalChartScreenProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const togglePaymentModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const getReport = (analyticsKey?: string) => {
    setIsModalOpen(true);

    if (analyticsKey) {
      return onGetReport(analyticsKey);
    }
  };

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.BANNER:
        return (
          <Banner
            key={section.sectionType + index}
            offer={offer}
            content={section.content}
            onClick={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.BANNER_WITH_TIMER:
        return (
          <SectionBannerWithTimer
            key={section.sectionType + index}
            timerStorageKey={FunnelName.NATAL_CHART}
            timerStop={SECOND}
            content={section.content}
            onClick={() => getReport()}
          />
        );
      case SectionType.BANNER_WITH_TIMER_AND_OFFER:
        return (
          <SectionBannerWithTimerAndOffer
            key={section.sectionType + index}
            timerStorageKey={FunnelName.NATAL_CHART}
            timerStop={SECOND}
            offer={offer}
            content={section.content}
          />
        );
      case SectionType.INFO:
        return <SectionInfo key={section.sectionType + index} content={section.content} />;
      case SectionType.BLURRED_CONTENT:
        return (
          <SectionBlurredContent
            key={section.sectionType + index}
            content={section.content}
            onGetReport={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.BENEFITS:
        return (
          <SectionBenefitsStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.OFFER:
        return (
          <SectionOfferStyled
            key={section.sectionType + index}
            offer={offer}
            content={section.content}
            onGetReport={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.REVIEWS:
        return <SectionReviewsStyled key={section.sectionType + index} content={section.content} />;
      case SectionType.QUOTE:
        return <SectionQuote key={section.sectionType + index} content={section.content} />;
      case SectionType.MENTIONED_IN:
        return <SectionMentionedIn key={section.sectionType + index} content={section.content} />;
      case SectionType.INSIDES:
        return <SectionInsights key={section.sectionType + index} content={section.content} />;
      case SectionType.FAQ: {
        return <SectionFaq key={section.sectionType + index} content={section.content} />;
      }
      case SectionType.MONEY_BACK_GUARANTEE: {
        return (
          <SectionMoneyBackGuarantee key={section.sectionType + index} content={section.content} />
        );
      }
      case SectionType.PAYMENT:
        return (
          offer?.offerId && (
            <PaymentDialog
              key={section.sectionType + index}
              isOpen={isModalOpen}
              productId={offer.offerId}
              onClose={togglePaymentModal}
              currency={offer.currency}
              onPaymentSuccess={paymentSucceed}
            >
              <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(section.content.text)} />
            </PaymentDialog>
          )
        );
      case SectionType.STICKY_PAYMENT_BUTTON: {
        return (
          <SectionStickyPaymentButton key={section.sectionType + index} onGetReport={getReport}>
            {section.content.button}
          </SectionStickyPaymentButton>
        );
      }

      default:
        return null;
    }
  };

  return (
    <RenderOnlyOnClient>
      <BackgroundStyled />
      <Header buttonBack={false} />
      <ContentStyled>{sections.map((item, index) => renderSection(item, index))}</ContentStyled>
      {sectionEnd}
    </RenderOnlyOnClient>
  );
};

export default EmailMarketingNatalChartScreen;
