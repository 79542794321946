import AnswerImageScreen from 'core/common/components/QuizScreens/AnswerImage';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerImageScreenAttributes } from 'core/funnel/entities';

type AnswerImageProps = {
  id: string;
};

const AnswerImage = ({ id }: AnswerImageProps) => {
  const { next, back, updateUserPreferences, screenAttributes, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerImageScreenAttributes;

  const onAnswerClick = (value: string) => {
    updateUserPreferences(dataKey, value);

    next({ value });
  };

  return (
    <AnswerImageScreen
      step={step}
      onAnswerClick={onAnswerClick}
      goBack={back}
      {...(screenAttributes as AnswerImageScreenAttributes)}
    />
  );
};

export default AnswerImage;
