import MobileStepperLib from '@mui/material/MobileStepper';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

// TODO add wrapper to lib
export const MobileStepper = styled(MobileStepperLib)`
  width: 100%;
  padding: 0 !important;
  background: none !important;

  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.primary500};
  }

  & .MuiMobileStepper-progress {
    width: 100%;
    height: 6px;
    background: ${(props) => props.theme.colors.primary200};
  }
`;

export const Circle = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.colors.primary500};
  border-radius: 50%;
`;

export const CircleActive = styled(Circle)`
  background: ${(props) => props.theme.colors.primary500};
`;
