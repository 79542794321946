import ProblemScreen from 'core/common/components/QuizScreens/Problem';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ProblemScreenAttributes } from 'core/funnel/entities';

type ProblemProps = {
  id: string;
};

const Problem = ({ id }: ProblemProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as ProblemScreenAttributes;

  const onBack = () => back();
  const onProblemClick = async (value: string) => {
    updateUserPreferences(dataKey, value);
    await next({ value });
  };

  return (
    <ProblemScreen
      onBack={onBack}
      onProblemClick={onProblemClick}
      step={step}
      {...(screenAttributes as ProblemScreenAttributes)}
    />
  );
};

export default Problem;
