import styled from 'styled-components';
import { BackgroundVariant } from './types';

export const AbsoluteBackgroundContainer = styled.div<{ variant: BackgroundVariant }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: ${({ variant, theme }) => {
    switch (variant) {
      case 'dark':
        return theme.colors.black;
      case 'darkGradient':
        return theme.gradients.primary;
      case 'semiLight':
        return theme.colors.themeBackgroundSemiLight;
      default:
        return theme.colors.white;
    }
  }};
`;
