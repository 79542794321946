import Address from 'core/common/components/Address';
import AspectRatio from 'core/common/components/AspectRatio';
import Header from 'core/common/components/Header/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import Policy from 'core/common/components/Policy';
import PolicyExtended from 'core/common/components/PolicyExtended';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { DateSimpleScreenProps } from 'core/funnel/entities';
import { Locales, notPaidUtmSources, paidUtmSources, Regions } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  ContainerStyled,
  TitleStyled,
  SubtitleStyled,
  CaptionStyled,
  ListStyled,
  ListItemStyled,
  ImageStyled,
  AgeWrapperStyled,
  FooterStyled,
  ProgressBarStyled,
} from './styled';

const DateSimpleScreen = ({
  content,
  hasButtonBack,
  isPolicyAccepted,
  displayPolicyError,
  progressBar,
  step,
  onAgeRangeSelect,
  onPolicyClick,
  onBack,
}: DateSimpleScreenProps) => {
  const { title, subtitle, buttonsCaption, ageList } = content;

  return (
    <>
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      <ContainerStyled applyTopGutter={!progressBar}>
        {progressBar && <ProgressBarStyled step={step} config={progressBar} onBack={onBack} />}
        <TitleStyled variant="h1" dangerouslySetInnerHTML={prepareHtml(title)} />
        <SubtitleStyled variant="body-medium" dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        <CaptionStyled
          variant="body-large-bold"
          dangerouslySetInnerHTML={prepareHtml(buttonsCaption)}
        />
        <ListStyled>
          {ageList.map(({ value, text, image }) => (
            <ListItemStyled key={value} role="button" onClick={() => onAgeRangeSelect(value)}>
              <AspectRatio width={154} height={114}>
                <ImageStyled src={image.src} alt={image.alt} fill />
              </AspectRatio>
              <AgeWrapperStyled>{text}</AgeWrapperStyled>
            </ListItemStyled>
          ))}
        </ListStyled>
        <FooterStyled>
          <MediaSourceVariant name={paidUtmSources}>
            <LocationRegion>
              <LocationRegionOption option={Regions.CALIFORNIA}>
                <PolicyExtended
                  onPolicyClick={onPolicyClick}
                  isPolicyAccepted={isPolicyAccepted}
                  withoutCheckbox
                />
              </LocationRegionOption>
              <LocationRegionOption option={Regions.OTHER}>
                <Policy />
              </LocationRegionOption>
            </LocationRegion>
          </MediaSourceVariant>
          <MediaSourceVariant name={notPaidUtmSources}>
            <Location>
              <LocationOption option={Locales.US}>
                <PolicyExtended
                  onPolicyClick={onPolicyClick}
                  isPolicyAccepted={isPolicyAccepted}
                  isError={displayPolicyError}
                />
              </LocationOption>
            </Location>
          </MediaSourceVariant>
          <Address />
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default DateSimpleScreen;
