import styled from 'styled-components';
import Emoji from 'core/common/components/Emoji';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.section`
  width: 100%;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  text-align: center;
`;

export const ListStyled = styled.ul`
  position: relative;
  padding: 16px 12px;
  border-radius: 16px;
  text-align: left;
  background-color: #f7f7f7;
`;

export const ListItemStyled = styled.li`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const IconStyled = styled(Icon)`
  fill: #3c1c7b;
  margin-top: 1px;
`;

export const TextStyled = styled.p`
  margin-left: 8px;
`;

export const EmojiStyled = styled(Emoji)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 62px;
  transform: translateY(-50%) translateX(-50%);
`;
