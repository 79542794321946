import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';
import Icon from 'core/svg-sprites/ui/Icon';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
`;

export const ListStyled = styled.ul``;

export const ItemStyled = styled.li`
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 140%;
`;

export const ItemTextStyled = styled.span``;

export const IconStyled = styled(Icon)`
  flex-shrink: 0;
  margin-right: 7px;
  fill: ${({ theme }) => theme.colors.primary500};
`;
