import styled from 'styled-components';
import Image from '../../Image';

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 472px;
  margin-right: auto;
  margin-left: auto;
`;

export const NatalChartLogoStyled = styled(Image)`
  margin-top: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
    margin-top: 0;
  }
`;

export const FooterTextStyled = styled.p`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;
