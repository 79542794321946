import { useEffect, useState } from 'react';
import Header from 'core/common/components/Header';
import { PaymentDialog } from 'core/common/components/Payment';
import Banner from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionBanner/SectionBanner';
import SectionFooter from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionFooter';
import SectionList from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionList';
import SectionMediaPanel from 'core/common/components/QuizScreensSections/SectionsLegacy/SectionMediaPanel';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { useInView, useServices } from 'core/common/hooks';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailMarketingCompatibilityScreenProps } from 'core/funnel/entities';
import { SectionAttributesUnionType, SectionType } from 'core/funnel/entities/Section';
import {
  ContainerStyled,
  ContentStyled,
  DescriptionStyled,
  SectionEndStyled,
  SectionInfoPanelStyled,
  SectionMoneyBackStyled,
  SectionPricePanelStyled,
  SectionReviewsStyled,
} from './styled';

const EmailMarketingCompatibilityScreen = ({
  offer,
  sections,
  onGetReport,
  paymentSucceed,
  analyticEmailMarketing,
}: EmailMarketingCompatibilityScreenProps) => {
  const { analyticsService } = useServices();
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const togglePaymentModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const getReport = (analyticsKey: string) => {
    setIsModalOpen(true);
    onGetReport(analyticsKey);
  };

  useEffect(() => {
    if (inView) {
      analyticsService.emailMarketingLpScrolledToEnd({
        page: analyticEmailMarketing?.page ?? 'compatibility',
        view: analyticEmailMarketing?.view ?? 'general_landing',
        source: analyticEmailMarketing?.scrolledSource ?? 'compatibility_email_land_updated',
      });
    }
  }, [inView, analyticsService, analyticEmailMarketing]);

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.LEGACY_BANNER:
        return <Banner key={section.sectionType + index} content={section.content} />;
      case SectionType.LEGACY_INFO_PANEL:
        return (
          <ContainerStyled>
            <SectionInfoPanelStyled key={section.sectionType + index} content={section.content} />
          </ContainerStyled>
        );
      case SectionType.LEGACY_MEDIA_PANEL:
        return <SectionMediaPanel key={section.sectionType + index} content={section.content} />;
      case SectionType.LEGACY_REVIEWS:
        return (
          <ContainerStyled>
            <SectionReviewsStyled key={section.sectionType + index} content={section.content} />
          </ContainerStyled>
        );
      case SectionType.LEGACY_LIST:
        return <SectionList key={section.sectionType + index} content={section.content} />;
      case SectionType.LEGACY_MONEY_BACK_GUARANTEE:
        return (
          <ContainerStyled>
            <SectionMoneyBackStyled key={section.sectionType + index} content={section.content} />
          </ContainerStyled>
        );
      case SectionType.LEGACY_PRICE_PANEL:
        return (
          <SectionPricePanelStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.LEGACY_FOOTER:
        return (
          <SectionFooter
            key={section.sectionType + index}
            content={section.content}
            onClick={() => getReport('continue')}
          />
        );
      case SectionType.PAYMENT:
        return (
          offer?.offerId && (
            <PaymentDialog
              key={section.sectionType + index}
              isOpen={isModalOpen}
              productId={offer.offerId}
              currency={offer.currency}
              onClose={togglePaymentModal}
              onPaymentSuccess={paymentSucceed}
            >
              <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(section.content.text)} />
            </PaymentDialog>
          )
        );
      default:
        return null;
    }
  };

  return (
    <RenderOnlyOnClient>
      <Header buttonBack={false} />
      <ContentStyled>{sections.map(renderSection)}</ContentStyled>
      <SectionEndStyled ref={ref} />
    </RenderOnlyOnClient>
  );
};

export default EmailMarketingCompatibilityScreen;
