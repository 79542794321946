import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 102px;

  &::before {
    position: absolute;
    top: 16px;
    right: 18px;
    width: 0;
    height: 0;
    border-top: 5px solid ${(props) => props.theme.colors.typography700};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: '';
  }
`;

export const HintContainer = styled(Container)`
  width: 220px;
`;

export const SelectBlock = styled.select`
  width: 100%;
  height: 40px;
  padding: 0 18px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${(props) => props.theme.colors.typography700};
  background-color: transparent;
  transition: all 0.2s ease;
  cursor: pointer;
  appearance: none;
  outline: none;

  :invalid {
    text-transform: capitalize;
    color: grey;
  }
`;

export const Option = styled.option`
  background-color: ${(props) => props.theme.colors.typography100};
`;
