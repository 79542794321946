import { useSelector } from 'react-redux';
import { useDispatch } from 'core/common/hooks';
import {
  closePopUp,
  getActivePopUpType,
  openCameraAccessAlertPopUp,
  openScanProcessPopUp,
} from '../store';

export const usePopUps = () => {
  const dispatch = useDispatch();

  const activePopUpType = useSelector(getActivePopUpType);

  const doOpenCameraAccessAlertPopUp = () => {
    dispatch(openCameraAccessAlertPopUp());
  };

  const doOpenScanProcessPopUp = () => {
    dispatch(openScanProcessPopUp());
  };

  const doClosePopUp = () => {
    dispatch(closePopUp());
  };

  return {
    activePopUpType,
    closePopUp: doClosePopUp,
    openCameraAccessAlertPopUp: doOpenCameraAccessAlertPopUp,
    openScanProcessPopUp: doOpenScanProcessPopUp,
  };
};
