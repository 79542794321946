import { FunnelImage } from 'core/funnel/entities';
import {
  ModalStyled,
  AspectRatioStyled,
  ImageStyled,
  QuestionStyled,
  ButtonsWrapperStyled,
  ButtonStyled,
} from './styled';

type QuestionModalProps = {
  isOpen: boolean;
  image: FunnelImage;
  question: string;
  confirmButtonText: string;
  denyButtonText: string;
  onDeny: () => void;
  onConfirm: () => void;
};

const QuestionModal = ({
  isOpen,
  image,
  question,
  confirmButtonText,
  denyButtonText,
  onDeny,
  onConfirm,
}: QuestionModalProps) => {
  return (
    <ModalStyled open={isOpen}>
      <>
        <AspectRatioStyled width={303} height={144}>
          <ImageStyled src={image.src} alt={image.alt} fill />
        </AspectRatioStyled>
        <QuestionStyled>{question}</QuestionStyled>
        <ButtonsWrapperStyled>
          <ButtonStyled onClick={onDeny}>{denyButtonText}</ButtonStyled>
          <ButtonStyled onClick={onConfirm}>{confirmButtonText}</ButtonStyled>
        </ButtonsWrapperStyled>
      </>
    </ModalStyled>
  );
};

export default QuestionModal;
