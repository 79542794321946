import { SectionLegacyPricePanelAttributes } from 'core/funnel/entities';
import { OldPricePanelStyled, WrapperStyled, LightStyledPriceBlockStyled } from './styled';

type SectionPriceProps = {
  content: SectionLegacyPricePanelAttributes['content'];
  className?: string;
};

const SectionPrice = ({ content, className }: SectionPriceProps) => (
  <WrapperStyled className={className}>
    <OldPricePanelStyled price={content.pricePrev} titleContent={content.titlePrev} />
    <LightStyledPriceBlockStyled
      price={content.priceNew}
      discount={content.badgeNew}
      titleContent={content.titleNew}
    />
  </WrapperStyled>
);

export default SectionPrice;
