import { useCallback } from 'react';
import { useServices } from 'core/common/hooks';

export default function useHintProcessingLoaderExperiment() {
  const { analyticsService } = useServices();

  const sendAnalytic = useCallback(
    (popupName: string, answer: string) => {
      analyticsService.flowProcessingPagePopupOpen(popupName);
      analyticsService.flowProcessingPagePopupClicked(answer);
    },
    [analyticsService],
  );

  return {
    sendAnalytic,
  };
}
