import maleImage from 'core/common/assets/images/hintQuiz/man.png';
import femaleImage from 'core/common/assets/images/hintQuiz/woman.png';
import Header from 'core/common/components/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import QuizBackground from 'core/common/components/QuizBackground';
import { GenderMoonCompatibilityScreenProps } from 'core/funnel/entities';
import {
  StyledArrow,
  StyledContainer,
  StyledFemaleButton,
  StyledGenderList,
  StyledGenderListItem,
  StyledGenderSection,
  StyledMaleButton,
  StyledImage,
  StyledSubtitle,
  StyledTitle,
} from './styled';

const GenderMoonCompatibilityScreen = ({
  content,
  step,
  progressBarName,
  progressBarTitle,
  totalPersonalitySteps,
  totalProfileSteps,
  totalRelationshipSteps,
  hasButtonBack,
  onGenderClick,
  onBack,
}: GenderMoonCompatibilityScreenProps) => {
  const { title, subtitle, male, female } = content;

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header onBack={onBack} buttonBack={hasButtonBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <StyledContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <StyledGenderSection>
          <StyledGenderList>
            <StyledGenderListItem onClick={() => onGenderClick(male.value)}>
              <StyledImage src={maleImage} alt="Man" width={150} height={174} />
              <StyledMaleButton>
                {male.text} <StyledArrow />
              </StyledMaleButton>
            </StyledGenderListItem>
            <StyledGenderListItem onClick={() => onGenderClick(female.value)}>
              <StyledImage src={femaleImage} alt="Woman" width={150} height={174} />
              <StyledFemaleButton>
                {female.text} <StyledArrow />
              </StyledFemaleButton>
            </StyledGenderListItem>
          </StyledGenderList>
        </StyledGenderSection>
      </StyledContainer>
    </>
  );
};

export default GenderMoonCompatibilityScreen;
