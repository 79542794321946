import { alpha } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as CameraImage } from 'core/common/assets/images/palmistryQuiz/camera.svg';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Modal from 'core/common/components/Modal';

export const StyledModal = styled(Modal)`
  position: relative;
  width: 100%;
  max-width: 90%;
  padding: 40px 15px 32px;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.typography900};
  background: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 520px;
    padding: 40px 45px 56px;
    color: ${(props) => props.theme.colors.typography100};
    background: ${(props) => props.theme.colors.infoLight};
  }
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  right: 20px;

  ${CrossIconStyled} {
    fill: ${({ theme }) => theme.colors.typography900};

    @media ${({ theme }) => theme.breakpoints.desktop} {
      fill: ${({ theme }) => theme.colors.typography100};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 18px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const StyledImage = styled(Image)`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
`;

export const StyledButton = styled(Button)`
  margin-top: 32px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);
  }
`;

export const StyledCameraImage = styled(CameraImage)`
  @media ${(props) => props.theme.breakpoints.mobile} {
    stroke: ${(props) => props.theme.colors.info};
  }
`;

export const BorderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 20px;
  border-radius: 14px;
  overflow: hidden;
  background-color: ${(props) => alpha(props.theme.colors.primary100, 0.5)};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 285px;
    padding: 0;
    border: 1px solid ${(props) => props.theme.colors.typography300};
    border-radius: 4px;
  }
`;

export const PointerStyled = styled(Image)`
  position: absolute;
  right: 15px;
  bottom: -5px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const AspectRatio = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 51%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 45%;
  }
`;
