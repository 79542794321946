import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px;
`;
