import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

export const TextStyled = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
`;
