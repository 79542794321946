import styled from 'styled-components';
import checkbox from 'core/common/assets/images/checkbox.svg';
import checkboxPurple from 'core/common/assets/images/checkbox_purple.svg';
import { ColorVariants } from './types';

export const Label = styled.label<{ variant: ColorVariants }>`
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &::before {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-top: ${(props) => (props.variant === 'tertiary' ? '2px' : '4px')};
    margin-right: 8px;
    border: ${(props) => (props.variant === 'tertiary' ? '2px' : '1px')} solid
      ${(props) =>
        props.variant === 'tertiary' ? props.theme.colors.info : props.theme.colors.typography700};
    border-radius: 3px;
    background-color: transparent;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
  }
`;

export const Input = styled.input<{ variant: ColorVariants }>`
  display: none;

  &:checked + ${Label}::before {
    border-color: ${(props) =>
      props.value === 'primary' ? props.theme.colors.typography700 : props.theme.colors.infoLight};
    border-radius: 3px;
    background-color: ${(props) =>
      props.variant === 'primary'
        ? props.theme.colors.typography700
        : props.theme.colors.infoLight};
    background-image: ${(props) =>
      props.variant === 'primary' ? `url(${checkbox})` : `url(${checkboxPurple})`};
  }
`;
