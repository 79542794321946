import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Carousel from 'core/common/components/Carousel';
import Image from 'core/common/components/Image';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 708px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 23px;
`;

export const Slide = styled.div<{ withBackground?: boolean }>`
  display: flex !important;
  justify-content: space-between;
  padding: 12px 8px;
  border-radius: 4px;
  background-color: ${({ theme, withBackground }) =>
    withBackground ? theme.colors.tertiary100 : 'transparent'};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 20px 16px;
  }
`;

export const StyledCarousel = styled(Carousel)`
  width: 100%;
  max-width: 330px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 500px;
    margin-top: 48px;
  }
`;

export const NextImageStyled = styled(Image)`
  right: -12px;
  z-index: 2;
  width: 24px;
  height: 24px;

  &.slick-disabled {
    opacity: 0.5;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    right: -28px;
  }
`;

export const BackImageStyled = styled(Image)`
  left: -12px;
  z-index: 2;
  width: 24px;
  height: 24px;
  transform: translate(0, -50%) scale(-1);

  &.slick-disabled {
    opacity: 0.5;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    left: -28px;
  }
`;

export const ActionButton = styled(Button)`
  position: sticky;
  bottom: 10px;
  max-width: 400px;
  height: 53px;
  margin-top: 20px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const RouteLink = styled.button`
  z-index: 5;
  width: 310px;
  width: 100%;
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const Disclaimer = styled.p`
  max-width: 474px;
  margin-top: 24px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

export const Footer = styled.footer`
  max-width: 360px;
  margin-top: 9px;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 28px;
    font-size: 12px;
  }
`;

export const Title = styled.h2`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 44px;
  }
`;
