import InformationalPalmistryScreen from 'core/common/components/QuizScreens/InformationalPalmistry';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { InformationalScreenPalmistryAttributes } from 'core/funnel/entities';

type InformationalPalmistryProps = {
  id: string;
};

const InformationalPalmistry = ({ id }: InformationalPalmistryProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <InformationalPalmistryScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as InformationalScreenPalmistryAttributes)}
    />
  );
};

export default InformationalPalmistry;
