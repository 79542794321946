import EmailScreen from 'core/common/components/QuizScreens/Email';
import { ApiError, normalizeApiError } from 'core/common/errors';
import { useLogger, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { EmailFormHelpers, EmailScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type EmailProps = {
  id: string;
};

const Email = ({ id }: EmailProps) => {
  const logger = useLogger();
  const { userService } = useServices();

  const { screenAttributes, back, next, userPreferences, step, completeQuiz } = useQuizStep(id);

  const { dataKey, productName } = screenAttributes as EmailScreenAttributes;

  const initialValue =
    typeof userPreferences[dataKey as keyof UserProfile] === 'string'
      ? (userPreferences[dataKey as keyof UserProfile] as string)
      : '';

  const handleSubmit = async (email: string, { setErrors }: EmailFormHelpers) => {
    try {
      const isValidEmail = await userService.isValidEmail(email);
      if (!isValidEmail) {
        setErrors({ email: 'Incorrect email address' });
        return;
      }
      await completeQuiz(email, productName);
      await next();
    } catch (err) {
      const error = normalizeApiError(err);
      logger.error(error);
      setErrors(
        err instanceof ApiError
          ? { email: err.originalError.message }
          : { email: 'Something went wrong. Try again later' },
      );
    }
  };

  return (
    <EmailScreen
      onBack={back}
      onSubmit={handleSubmit}
      initialValue={initialValue}
      step={step}
      {...(screenAttributes as EmailScreenAttributes)}
    />
  );
};

export default Email;
