import styled from 'styled-components';
import { ColorVariants } from 'core/theme/entities';

export const TitleText = styled.h2<{ variant?: ColorVariants }>`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${(props) =>
    (props.variant === 'secondary' && props.theme.colors.typography100) ||
    props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 708px;
    font-size: 32px;
    line-height: 44px;
  }
`;
