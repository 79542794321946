import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

export const OfferBlock = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 460px;
  }
`;

export const OfferTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.secondary600};
`;

export const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background: #d4c6e5;
`;

export const OfferDescriptionWrapper = styled.div`
  padding: 20px;
`;

export const ConsultationWrapper = styled.div`
  display: flex;
`;

export const HighlightText = styled.span`
  font-weight: 400;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(90.6deg, #6a4dbc 0.47%, #f2994a 137.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ImageStyled = styled(Image)`
  width: 44px;
  height: 44px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 60px;
    height: 60px;
  }
`;

export const ConsultationText = styled.p`
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  color: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 145%;
  }
`;

export const Description = styled.p`
  margin-top: 12px;
  font-size: 14px;
  line-height: 135%;
  color: #343434;
`;

export const PriceWrapper = styled.div`
  padding: 20px;
`;

export const OfferPriceTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.theme.colors.secondary600};
`;

export const OfferPrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: ${(props) => props.theme.colors.secondary600};
`;

export const OriginalPriceTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const OriginalPrice = styled.span`
  text-decoration: line-through ${({ theme }) => theme.colors.typography500};
`;

export const Discount = styled.span`
  font-weight: 700;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(90.6deg, #6a4dbc 0.47%, #f2994a 137.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
