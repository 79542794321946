import styled from 'styled-components';

export const PartnersBlockStyled = styled.div``;

export const TitleStyled = styled.h3`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;
