import { useEffect, useMemo, useState } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellsPalmistryScreen from 'core/common/components/QuizScreens/UpsellsPalmistryV2';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellsPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import { Upsell } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { getCalculatedUpsell } from 'core/offers/mappers';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellsPalmistryV2Props = {
  id: string;
};

const UpsellsPalmistryV2 = ({ id }: UpsellsPalmistryV2Props) => {
  const [selectedProduct, setSelectedProduct] = useState('');

  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const {
    numerologyUpsellsOffer,
    tarotUpsellsOffer,
    ultraPackOffer,
    selectedOffer,
    cheapestOffer,
  } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const { createAccount } = useCreateAskNebulaAccount();

  const isCheapestOfferBought =
    cheapestOffer.prices.trialPriceWithVAT === selectedOffer?.prices.trialPriceWithVAT;

  const attributes = screenAttributes as UpsellsPalmistryV2ScreenAttributes;

  const upsellsBasedOnSelectedPlan = useMemo(() => {
    // TODO: use 1 map of upsells in config
    const { tarotFirstUpsells, ultraPackFirstUpsells } = attributes.content;

    if (isCheapestOfferBought) {
      return tarotFirstUpsells;
    }

    return ultraPackFirstUpsells;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheapestOfferBought]);

  // TODO think how to move to mappers
  const numerologyAnalysis = getCalculatedUpsell(
    upsellsBasedOnSelectedPlan.numerologyAnalysis,
    numerologyUpsellsOffer,
  );

  const tarotReading = getCalculatedUpsell(
    upsellsBasedOnSelectedPlan.tarotReading,
    tarotUpsellsOffer,
  );

  const ultraPack = getCalculatedUpsell(upsellsBasedOnSelectedPlan.ultraPack, ultraPackOffer);

  const upsells: Upsell[] = [numerologyAnalysis, tarotReading, ultraPack];
  const sortedUpsells = [...upsells].sort((a, b) => a.order - b.order);

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    recurringPaymentRequest(selectedProduct);
  };

  const goNext = () => {
    createAccount();
    next();
  };

  const skip = () => {
    analyticsService.upsellLpSkip();
    goNext();
  };

  const selectProduct = (product: string) => {
    setSelectedProduct(product);
  };

  useEffect(() => {
    const [firstUpsell] = upsells.filter(({ order }) => order === 1);

    if (!firstUpsell.offerId) {
      return;
    }

    setSelectedProduct(firstUpsell.offerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsells.length]);

  useEffect(() => {
    analyticsService.upsellLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellLpGetReport(selectedProduct);
      goNext();
    }
  }, [paymentProcessStep, analyticsService]);

  return (
    <UpsellsPalmistryScreen
      isPurchaseLoading={isPurchaseLoading}
      selectedProduct={selectedProduct}
      upsells={sortedUpsells}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      onGetReport={getReport}
      onSkip={skip}
      selectProduct={selectProduct}
      paymentError={error}
      {...(screenAttributes as UpsellsPalmistryV2ScreenAttributes)}
    />
  );
};

export default UpsellsPalmistryV2;
