import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { createDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { DateNatalChartScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerStyled,
  ContentStyled,
  ImageDesktopWrapperStyled,
  ImageMobileWrapperStyled,
  SelectContainerStyled,
  SelectStyled,
  TitleStyled,
} from './styled';

const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const yearsList = generateDates(1940, greatestBirthYear, 'decrement');

const DateNatalChartScreen = ({
  step,
  onBack,
  getInitialDate,
  onSubmit,
  content,
  totalSteps,
}: DateNatalChartScreenProps) => {
  const { day, month, year } = getInitialDate();
  const { title, next, backgroundImage, placeholderDay, placeholderMonth, placeholderYear } =
    content;

  const [date, setDate] = useState({
    month: month ? shortMonthsListEn[month] : '',
    day: day ? String(day) : '',
    year: year ? String(year) : '',
  });

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const daysList = generateMonthDays(date.month, date.year);

  const handleClick = () => {
    const dateOfBirth = createDate(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid Date') {
      const newDateOfBirth = createDate(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      return onSubmit(newDateOfBirth);
    }
    return onSubmit(dateOfBirth);
  };

  const isNextButtonDisabled = !date.day || !date.year || !date.month;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} onBack={onBack} />
      <ContainerStyled>
        <ContentStyled>
          <TitleStyled>{title}</TitleStyled>
          <SelectContainerStyled>
            <SelectStyled
              options={monthsList}
              name="month"
              value={date.month}
              onChange={handleDateChange}
              placeholder={placeholderMonth ?? 'Month'}
            />
            <SelectStyled
              options={daysList}
              name="day"
              value={date.day}
              onChange={handleDateChange}
              placeholder={placeholderDay ?? 'Day'}
            />
            <SelectStyled
              options={yearsList}
              name="year"
              value={date.year}
              onChange={handleDateChange}
              placeholder={placeholderYear ?? 'Year'}
            />
          </SelectContainerStyled>
          <ButtonStyled onClick={handleClick} disabled={isNextButtonDisabled}>
            {next}
          </ButtonStyled>
        </ContentStyled>
      </ContainerStyled>
      {backgroundImage && (
        <>
          <ImageMobileWrapperStyled>
            <Image
              src={backgroundImage.mobileImage.src}
              alt={backgroundImage.mobileImage.alt}
              width={196}
              height={180}
            />
          </ImageMobileWrapperStyled>
          <ImageDesktopWrapperStyled>
            <Image
              src={backgroundImage.desktopImage.src}
              alt={backgroundImage.desktopImage.alt}
              width={382}
              height={352}
            />
          </ImageDesktopWrapperStyled>
        </>
      )}
    </>
  );
};

export default DateNatalChartScreen;
