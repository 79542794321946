import Image from 'core/common/components/Image';
import ReviewsReal from 'core/common/components/ReviewsReal';
import { SectionLegacyReviewsAttributes } from 'core/funnel/entities';
import { ContainerStyled, TitleStyled } from './styled';

type SectionReviewProps = {
  content: SectionLegacyReviewsAttributes['content'];
  className?: string;
};

const SectionReview = ({ content, className }: SectionReviewProps) => {
  const list = content.list.map((item) => {
    return {
      date: item.date,
      img: <Image width={44} height={44} src={item.image.src} alt={item.image.alt} />,
      text: item.text,
      name: item.nickname,
    };
  });

  return (
    <ContainerStyled className={className}>
      <TitleStyled>{content.title}</TitleStyled>
      <ReviewsReal list={list} />
    </ContainerStyled>
  );
};

export default SectionReview;
