import { CurrencySymbol } from 'core/user/entities';
import { Bold, Break, Description } from './styled';

export type SectionPaymentContentOtherLocationProps = {
  price: number;
  currencySymbol: CurrencySymbol;
  trialPeriod: string;
};

const SectionPaymentContentOtherLocation = ({
  price,
  currencySymbol,
  trialPeriod,
}: SectionPaymentContentOtherLocationProps) => {
  return (
    <Description>
      You will be charge only{' '}
      <Bold>
        {currencySymbol}
        {price} for your {trialPeriod} trial.
      </Bold>
      <Break /> We’ll <Bold>email you a reminder</Bold> before your trial period ends.{' '}
      <Bold>Cancel anytime.</Bold>
    </Description>
  );
};

export default SectionPaymentContentOtherLocation;
