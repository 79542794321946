import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const FAQBlock = styled.div`
  margin-top: 32px;
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const List = styled.ul`
  margin-top: 16px;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  margin-top: 12px;
`;

export const ItemImageStyled = styled(Image)`
  flex-shrink: 0;
`;

export const ItemContentBlock = styled.div`
  margin-left: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 16px;
  }
`;

export const QuestionText = styled.span`
  font-size: 13px;
  font-weight: 600;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const AnswerText = styled.p`
  margin-top: 4px;
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;
