import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Image from '../../Image';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 434px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 78px;
  }
`;

export const Title = styled.h2`
  margin-top: 38px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  text-transform: lowercase;
  color: ${({ theme }) => theme.colors.typography100};

  &::first-letter {
    text-transform: capitalize;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 62px;
    margin-bottom: 16px;
    font-size: 32px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  height: 50px;
  margin-top: 48px;
  margin-top: auto;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${({ theme }) => theme.colors.infoLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
  }
`;

export const StyledImage = styled(Image)`
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
  }
`;
