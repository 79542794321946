import { ScanWithScreenError as ScanScreen } from 'core/common/components/QuizScreens/Scan';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ScanWithSkipErrorScreenAttributes } from 'core/funnel/entities';

export type ScanWithSkipErrorScreenProps = {
  id: string;
};

const ScanWithSkipErrorScreen = ({ id }: ScanWithSkipErrorScreenProps) => {
  const { back, screenAttributes, next, step, extraNext } = useQuizStep(id);

  return (
    <ScanScreen
      step={step}
      onBack={back}
      goNext={next}
      onSkipError={extraNext}
      {...(screenAttributes as ScanWithSkipErrorScreenAttributes)}
    />
  );
};

export default ScanWithSkipErrorScreen;
