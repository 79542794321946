import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentWitchPowerScreen from 'core/common/components/QuizScreens/TrialPaymentWitchPower';
import { BASE_ROUTES, LANDINGS } from 'core/common/constants';
import { useCountdown, useDispatch, useRouter, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentWitchPowerScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';
import { getEmail } from 'core/user/store';

type TrialPaymentWitchPowerProps = {
  id: string;
};

const TrialPaymentWitchPower = ({ id }: TrialPaymentWitchPowerProps) => {
  const { next, extraNext, getScreenAttributes, userPreferences } = useQuizStep(id);

  const { specialPlanCountdown } = useCountdown({ key: FunnelName.WITCH_POWER });

  const { navigate } = useRouter();
  const dispatch = useDispatch();

  const { analyticsService } = useServices();

  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  const { updateOffer, selectedOffer, timeUpOffer, defaultOffers } = useOffers();
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const defaultOffer = selectedOffer ?? defaultOffers[0];

  const isTimeUp = !+specialPlanCountdown;
  const offer = isTimeUp ? timeUpOffer : defaultOffer;

  useEffect(() => {
    if (offer) {
      analyticsService.landingPageOpened('trial_billing');
      analyticsService.addToCart({
        price: offer.prices.priceWithVAT,
        productId: offer.offerId,
        email,
        orderId,
        currency: offer.currency,
      });
    }
  }, [analyticsService, offer, email, orderId]);

  const getReport = useCallback(() => {
    if (!offer) return;

    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);

    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, dispatch, offer, email, orderId]);

  const paymentSucceed = useCallback(() => {
    next();
  }, [next]);

  const onPaymentDialogClose = () => {
    if (!screenAttributes.extraNext) {
      return navigate(joinUrls(BASE_ROUTES.WITCH_POWER, LANDINGS.ADDITIONAL_DISCOUNT));
    }

    extraNext();
  };

  return (
    <TrialPaymentWitchPowerScreen
      offer={offer}
      timeUpOffer={timeUpOffer}
      email={userPreferences.email}
      timeLeft={specialPlanCountdown}
      getReport={getReport}
      onSucceed={paymentSucceed}
      onPaymentDialogClose={onPaymentDialogClose}
      {...(screenAttributes as TrialPaymentWitchPowerScreenAttributes)}
    />
  );
};

export default TrialPaymentWitchPower;
