import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { FooterTextStyled } from './styled';

type FooterProps = {
  addressUS: string;
  addressAE: string;
  address: string;
};

const Footer = ({ addressUS, addressAE, address }: FooterProps) => {
  return (
    <Location>
      <LocationOption option={Locales.US}>
        <FooterTextStyled>{addressUS}</FooterTextStyled>
      </LocationOption>
      <LocationOption option={Locales.AE}>
        <FooterTextStyled>{addressAE}</FooterTextStyled>
      </LocationOption>
      <LocationOption option={Locales.OTHER}>
        <FooterTextStyled>{address}</FooterTextStyled>
      </LocationOption>
    </Location>
  );
};

export default Footer;
