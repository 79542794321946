import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Title from 'core/common/components/Title';

export const ContainerMainStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 328px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const TextStyled = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ContentStyled = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  height: 157px;
  margin: 0 auto 50px;
`;

export const SkipStyled = styled.button`
  margin-bottom: 44px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.typography500};
  cursor: pointer;
`;

export const TitleStyled = styled(Title)`
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const ButtonStyled = styled(Button)`
  height: 49px;
  margin: auto auto 30px;
  font-size: 18px;
  line-height: 21px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: auto auto 50px;
  }
`;
