import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const ListStyled = styled.ul`
  display: flex;
  justify-content: center;
`;

export const ItemStyled = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  max-width: 160px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.infoLight};
  border-radius: 8px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-items: flex-start;
    max-width: 220px;
  }

  &:not(:last-child) {
    margin-right: 10px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin-right: 20px;
    }
  }
`;

export const TextWrapperStyled = styled.div`
  flex-grow: 1;
  line-height: 10px;
`;

export const TitleStyled = styled.p`
  font-size: 8px;
  text-transform: uppercase;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const TextStyled = styled.p`
  font-size: 10px;
  font-weight: 600;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const IconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 4px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: 8px;
  }
`;
