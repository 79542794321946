import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import {
  ContainerStyled,
  DetailStyled,
  DetailTitleStyled,
  DetailContentStyled,
  IconWrapperStyled,
  TitleStyled,
  DetailDescriptionStyled,
} from './styled';

type SectionDetailsProps = {
  title: string;
  details: Array<{
    title: string;
    text: string;
    image: FunnelImage;
  }>;
};

const SectionDetails = ({ title, details }: SectionDetailsProps) => {
  return (
    <ContainerStyled>
      <TitleStyled dangerouslyInnerHTML={title} />
      {details.map((item) => (
        <DetailStyled key={item.title}>
          <IconWrapperStyled>
            <Image src={item.image.src} alt={item.image.alt} width={48} height={48} />
          </IconWrapperStyled>
          <DetailContentStyled>
            <DetailTitleStyled dangerouslySetInnerHTML={prepareHtml(item.title)} />
            <DetailDescriptionStyled dangerouslySetInnerHTML={prepareHtml(item.text)} />
          </DetailContentStyled>
        </DetailStyled>
      ))}
    </ContainerStyled>
  );
};

export default SectionDetails;
