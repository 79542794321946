import OnboardingReadingScreen from 'core/common/components/QuizScreens/OnboardingReading';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { OnboardingReadingScreenAttributes } from 'core/funnel/entities';

type OnboardingReadingProps = {
  id: string;
};

const OnboardingReading = ({ id }: OnboardingReadingProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  return (
    <OnboardingReadingScreen
      goNext={next}
      {...(screenAttributes as OnboardingReadingScreenAttributes)}
    />
  );
};

export default OnboardingReading;
