import AnimatedCards from 'core/common/components/AnimatedCards';
import { useInView } from 'core/common/hooks';
import { SectionUpsellConsultationInsightsAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  BenefitsListStyled,
  BenefitsListItemStyled,
  BenefitIconStyled,
  BenefitInfoWrapperStyled,
  BenefitTitleStyled,
  BenefitDescriptionStyled,
} from './styled';

export type SectionUpsellConsultationInsightsProps = {
  content: SectionUpsellConsultationInsightsAttributes['content'];
  className?: string;
};

const SectionUpsellConsultationInsights = ({
  content,
  className,
}: SectionUpsellConsultationInsightsProps) => {
  const { title, cards, benefits } = content;

  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <WrapperStyled className={className} ref={ref}>
      <TitleStyled>{title}</TitleStyled>
      <AnimatedCards cards={cards} skipAnimation={!inView} />
      <BenefitsListStyled>
        {benefits.map((benefit) => (
          <BenefitsListItemStyled key={benefit.title}>
            <BenefitIconStyled
              src={benefit.icon.src}
              alt={benefit.icon.alt}
              width={24}
              height={24}
            />
            <BenefitInfoWrapperStyled>
              <BenefitTitleStyled>{benefit.title}</BenefitTitleStyled>
              <BenefitDescriptionStyled>{benefit.description}</BenefitDescriptionStyled>
            </BenefitInfoWrapperStyled>
          </BenefitsListItemStyled>
        ))}
      </BenefitsListStyled>
    </WrapperStyled>
  );
};

export default SectionUpsellConsultationInsights;
