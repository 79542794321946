import { pulseLoader } from 'core/common/assets/animations/pulseLoader';
import Lottie from 'core/common/components/Lottie';
import { LottieWrapperStyled, PageLoaderStyled, TextStyled } from './styled';

type PageLoaderProps = {
  text: string;
  animationDurationInSec: number;
  hideLoaderDelayInSec: number;
};

const PageLoader = ({ text, animationDurationInSec, hideLoaderDelayInSec }: PageLoaderProps) => {
  return (
    <PageLoaderStyled
      animationDurationInSec={animationDurationInSec}
      hideLoaderDelayInSec={hideLoaderDelayInSec}
    >
      <LottieWrapperStyled>
        <Lottie animationData={pulseLoader} />
      </LottieWrapperStyled>
      <TextStyled>{text}</TextStyled>
    </PageLoaderStyled>
  );
};

export default PageLoader;
