import styled from 'styled-components';
import CircularProgressV2 from 'core/common/components/CircularProgressV2';
import ReviewsCarousel from 'core/common/components/QuizScreensSections/SectionReviewsCarousel';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 48px;
    padding-bottom: 120px;
  }
`;

export const CircularProgressV2Styled = styled(CircularProgressV2)`
  margin-bottom: 16px;
`;

export const ListWrapperStyled = styled.div`
  height: 60px;
  margin-bottom: auto;
  overflow: hidden;
`;

export const ListStyled = styled.ul<{ translateFraction: number; activeTitleIndex: number }>`
  transition: transform 0.3s ease;
  transform: ${({ translateFraction, activeTitleIndex }) =>
    `translateY(-${activeTitleIndex * translateFraction}%)`};
`;

export const ListItemStyled = styled.li<{ isActive: boolean }>`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #a8a8a8;
  transition: color 0.3s ease, font-size 0.3s ease;

  ${({ theme, isActive }) =>
    isActive &&
    `
    font-size: 16px;
    color: ${theme.colors.black};
  `}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;

    ${({ isActive }) =>
      isActive &&
      `
    font-size: 18px;
  `}
  }
`;

export const ReviewsCarouselStyled = styled(ReviewsCarousel)`
  margin-top: 0;
  margin-bottom: 0;
`;
