import { useEffect } from 'react';
import TalkWithExpertPalmistryScreen from 'core/common/components/QuizScreens/TalkWithExpertPalmistry';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TalkWithExpertPalmistryScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type TalkWithExpertPalmistryProps = {
  id: string;
};

const TalkWithExpertPalmistry = ({ id }: TalkWithExpertPalmistryProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onExpertPageOpened();
  }, [analyticsService]);

  return (
    <TalkWithExpertPalmistryScreen
      {...(screenAttributes as TalkWithExpertPalmistryScreenAttributes)}
    />
  );
};

export default TalkWithExpertPalmistry;
