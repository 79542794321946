import styled from 'styled-components';

export const FaqSectionStyled = styled.div`
  margin-bottom: 56px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 32px;
    font-size: 26px;
    line-height: 35px;
  }
`;
