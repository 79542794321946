import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const List = styled.ul`
  margin-top: -20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 40px;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  margin-top: 20px;
`;

export const ItemImageStyled = styled(Image)`
  flex-shrink: 0;
`;

export const HeaderBlock = styled.div`
  display: flex;
`;

export const QuestionText = styled.h5`
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const AnswerText = styled.h5`
  margin-top: 8px;
  margin-left: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography700};
`;
