import { Trans } from 'react-i18next';
import QuizBackground from 'core/common/components/QuizBackground';
import { OnboardingScreenProps } from 'core/funnel/entities';
import { BreakStyled, ContainerStyled, IconStyled, TextStyled } from './styled';

const OnboardingScreen = ({ content }: OnboardingScreenProps) => {
  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} variant="darkGradient" />
      <ContainerStyled>
        <IconStyled name="nebula" width={219} height={31} />
        {content.list.map((tKeyPrefix, idx) => (
          <TextStyled key={tKeyPrefix} value={idx}>
            <Trans i18nKey={tKeyPrefix} components={{ br: <BreakStyled /> }} />
          </TextStyled>
        ))}
      </ContainerStyled>
    </>
  );
};

export default OnboardingScreen;
