import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.ul`
  margin-bottom: 56px;
  padding: 32px 12px;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 0 10px 0 #00000026;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
    padding-right: 24px;
    padding-left: 24px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const DescriptionStyled = styled.p`
  margin-bottom: 20px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ImageStyled = styled(Image)`
  height: auto;
`;
