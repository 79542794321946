import {
  Menu as MaterialMenu,
  MenuProps as MaterialMenuProps,
  MenuClassKey as MaterialMenuClassKey,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

export type DropdownMenuProps = {
  className?: string;
} & MaterialMenuProps;

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    borderRadius: 0,
    padding: '4px 12px',
  },
  list: {
    padding: 0,
  },
});

const DropdownMenu = ({ children, classes = {}, className, ...otherProps }: DropdownMenuProps) => {
  const initialClasses = useStyles();

  const compoundClasses: Partial<Record<MaterialMenuClassKey, string>> = {
    list: cn(initialClasses.list, classes.list, className),
    paper: cn(initialClasses.paper, classes.paper),
  };

  return (
    <MaterialMenu {...otherProps} classes={compoundClasses}>
      {children}
    </MaterialMenu>
  );
};

export default DropdownMenu;
