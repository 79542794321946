import DynamicAnswerScreen from 'core/common/components/QuizScreens/DynamicAnswer';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DynamicAnswerScreenAttributes } from 'core/funnel/entities';

type DynamicAnswerProps = {
  id: string;
};

const DynamicAnswer = ({ id }: DynamicAnswerProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as DynamicAnswerScreenAttributes;

  const onContinue = (answer: string) => {
    updateUserPreferences(dataKey, answer);
    next();
  };

  return (
    <DynamicAnswerScreen
      onContinue={onContinue}
      onBack={back}
      step={step}
      {...(screenAttributes as DynamicAnswerScreenAttributes)}
    />
  );
};

export default DynamicAnswer;
