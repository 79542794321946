import MoneyBack from 'core/common/components/MoneyBack';
import { SectionLegacyMoneyBackGuaranteeAttributes } from 'core/funnel/entities';

type SectionMoneyBackGuaranteeProps = {
  content: SectionLegacyMoneyBackGuaranteeAttributes['content'];
  className?: string;
};

const SectionMoneyBackGuarantee = ({ content, className }: SectionMoneyBackGuaranteeProps) => {
  return (
    <MoneyBack
      text={content.text}
      title={content.title}
      linkText={content.link.text}
      linkHref={content.link.href}
      className={className}
      textInscription={content.textInscription}
    />
  );
};

export default SectionMoneyBackGuarantee;
