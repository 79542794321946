import DateSimpleScreen from 'core/common/components/QuizScreens/DateSimple';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DateSimpleScreenAttributes } from 'core/funnel/entities';

type DateSimpleProps = {
  id: string;
};

const DateSimple = ({ id }: DateSimpleProps) => {
  const { screenAttributes, step, back, next, updateUserPreferences } = useQuizStep(id);
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { dataKey } = screenAttributes as DateSimpleScreenAttributes;

  const onAgeRangeSelect = (value: string) => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    next();
  };

  return (
    <DateSimpleScreen
      onBack={back}
      onPolicyClick={handlePolicyClick}
      onAgeRangeSelect={onAgeRangeSelect}
      step={step}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      {...(screenAttributes as DateSimpleScreenAttributes)}
    />
  );
};
export default DateSimple;
