import BannerInfo from 'core/common/components/BannerInfo';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionLegacyInfoPanelAttributes } from 'core/funnel/entities';
import { TitleStyled, TextStyled } from './styled';

type SectionInfoPanelProps = {
  content: SectionLegacyInfoPanelAttributes['content'];
  className?: string;
};

const SectionInfoPanel = ({ content, className }: SectionInfoPanelProps) => (
  <div className={className}>
    {content.title && <TitleStyled dangerouslyInnerHTML={content.title} />}
    <BannerInfo>
      <TextStyled dangerouslySetInnerHTML={prepareHtml(content.panel.text)} />
    </BannerInfo>
  </div>
);

export default SectionInfoPanel;
