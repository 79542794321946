import ReactLottie from 'react-lottie';

const defaultOptions = {
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type LottieProps = {
  animationData: Record<string, unknown>;
  width?: number | string;
  height?: number | string;
  loop?: boolean;
  className?: string;
};

const Lottie = ({
  animationData,
  loop = false,
  width = '100%',
  height = '100%',
  className,
}: LottieProps) => {
  const options = {
    ...defaultOptions,
    animationData,
    loop,
    rendererSettings: {
      ...defaultOptions.rendererSettings,
      className,
    },
  };

  return <ReactLottie options={options} width={width} height={height} />;
};

export default Lottie;
