import { InputHTMLAttributes, ReactNode } from 'react';
import { LabelStyled, RadioInputStyled } from './styled';

type RadioButtonProps = {
  children?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton = ({ children, ...otherProps }: RadioButtonProps) => {
  return (
    <>
      <RadioInputStyled type="radio" {...otherProps} />
      <LabelStyled htmlFor={otherProps.id}>{children}</LabelStyled>
    </>
  );
};

export default RadioButton;
