import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { WhichFigureScreenProps } from 'core/funnel/entities';
import Emoji from '../../Emoji/DeprecatedEmoji';
import {
  ButtonsWrapper,
  Container,
  DesktopImageWrapper,
  MobileImageWrapper,
  StyledButton,
  Text,
  Title,
  StyledImage,
} from './styled';

const WhichFigureScreen = ({ onBack, content, goNext }: WhichFigureScreenProps) => {
  const { backgroundImage } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <BackgroundGradient />
      {backgroundImage && (
        <>
          <MobileImageWrapper>
            <Image
              width={163}
              height={489}
              src={backgroundImage.mobileImage.src}
              alt={backgroundImage.mobileImage.alt || 'background'}
            />
          </MobileImageWrapper>
          <DesktopImageWrapper>
            <Image
              width={642}
              height={856}
              src={backgroundImage.desktopImage.src}
              alt={backgroundImage.desktopImage.alt || 'background'}
            />
          </DesktopImageWrapper>
        </>
      )}
      <Container>
        <StyledImage src={content.image.src} alt={content.image.alt} width={150} height={150} />
        <Title variant="secondary">{content.title}</Title>
        <Text>{content.text}</Text>
        <ButtonsWrapper>
          {content.answers.map(({ text, value, emoji }) => (
            <StyledButton
              key={value}
              onClick={() => goNext(value)}
              icon={<Emoji emoji={emoji} width={18} height={18} desktopLarge={false} />}
            >
              {text}
            </StyledButton>
          ))}
        </ButtonsWrapper>
      </Container>
    </>
  );
};

export default WhichFigureScreen;
