import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Emoji from 'core/common/components/Emoji';
import { ContainerMain } from 'core/common/components/Quiz/styled';
import RadioButton from 'core/common/components/RadioButton';

const descIconFullSizePx = '30px';
const fullHeightNextButtonPx = '76px';

export const ContainerMainStyled = styled(ContainerMain)`
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  position: relative;
  flex-grow: initial;
  text-align: left;
`;

export const TitleStyled = styled.h2`
  margin-top: 38px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 130%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 24px;
  }
`;

export const ListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${fullHeightNextButtonPx};
`;

export const ItemStyled = styled.li`
  margin-bottom: 16px;
`;

export const RadioButtonTitleStyled = styled.p``;

export const RadioButtonStyled = styled(RadioButton)`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  & + label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 135%;
    color: ${(props) => props.theme.colors.typography800};
    background: #eaeef7;
    box-shadow: 2px 2px 6px 0 rgba(84, 60, 151, 0.25);
    transition: all 0.1s;
    cursor: pointer;
  }

  &:checked + label {
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.typography100};
    background: linear-gradient(
      166deg,
      #141333 -33.39%,
      #202261 15.89%,
      #543c97 55.84%,
      #6939a1 74.96%
    );
  }

  &:checked + label + div {
    display: flex;
    animation: fadeIn 0.3s cubic-bezier(0.82, 0.04, 0.65, 0.88);
  }
`;

export const AnswerDescriptionContainerStyled = styled.div`
  position: relative;
  z-index: -1;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -25px;
  padding: 20px 16px 12px;
  border-radius: 0 0 16px 16px;
  background: #f5f5f5;
`;

export const AnswerDescriptionTitleStyled = styled.h3`
  width: calc(100% - ${descIconFullSizePx});
  font-size: 14px;
  font-weight: 600;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const AnswerDescriptionEmojiStyled = styled(Emoji)`
  flex-shrink: 0;
  width: auto;
  margin-right: 8px;
  font-size: 16px;
`;

export const AnswerDescriptionTextStyled = styled.p`
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const EmojiStyled = styled(Emoji)`
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 24px;
`;

export const StyledButton = styled(Button)`
  height: 56px;
  margin-bottom: 20px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
`;

export const ButtonWrapperStyled = styled.div<{ isButtonVisible: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  display: ${({ isButtonVisible }) => (isButtonVisible ? 'flex' : 'none')};
  justify-content: center;
  width: 100%;
  height: ${fullHeightNextButtonPx};
  background: ${({ theme }) => theme.colors.themeBackground};
`;
