import { useEffect } from 'react';
import { useCreateAskNebulaAccount } from 'core/auth/hooks';
import UpsellNatalChartSingleScreen from 'core/common/components/QuizScreens/UpsellNatalChartSingle';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellNatalChartSingleScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellNatalChartSingleProps = {
  id: string;
};

const UpsellNatalChartSingle = ({ id }: UpsellNatalChartSingleProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { natalChartUpsellOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const { createAccount } = useCreateAskNebulaAccount();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const goNext = () => {
    createAccount();
    next();
  };

  const getReport = () => {
    if (!natalChartUpsellOffer) return;

    analyticsService.upsellPremiumScreenClicked();
    recurringPaymentRequest(natalChartUpsellOffer.offerId);
  };

  const skip = async () => {
    goNext();
  };

  useEffect(() => {
    analyticsService.natalChartUpsellScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.natalChartUpsellGetReport(
        natalChartUpsellOffer ? natalChartUpsellOffer.offerId : '',
      );
      next();
    }
  }, [paymentProcessStep]);

  return (
    <UpsellNatalChartSingleScreen
      onGetReport={getReport}
      onSkip={skip}
      isLoading={isPurchaseLoading}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellNatalChartSingleScreenAttributes)}
    />
  );
};

export default UpsellNatalChartSingle;
