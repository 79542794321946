import starImage from 'core/common/assets/images/star.svg';
import Image from 'core/common/components/Image';
import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  HeaderStyled,
  ExpertImageWrapperStyled,
  ExpertProfileStyled,
  TitleStyled,
  SubtitleStyled,
  ExpertRatingStyled,
  StarImageStyled,
  MessageStyled,
} from './styled';

type ExpertMessageProps = {
  image: FunnelImage;
  title: string;
  subtitle: string;
  message: string;
  rating: number;
};

const ExpertMessage = ({ image, title, subtitle, message, rating }: ExpertMessageProps) => {
  return (
    <WrapperStyled>
      <HeaderStyled>
        <ExpertImageWrapperStyled>
          <Image src={image.src} alt={image.alt} width={90} height={90} />
        </ExpertImageWrapperStyled>
        <ExpertProfileStyled>
          <TitleStyled>{title}</TitleStyled>
          <SubtitleStyled>{subtitle}</SubtitleStyled>
        </ExpertProfileStyled>
        <ExpertRatingStyled>
          {rating}
          <StarImageStyled src={starImage} alt="Yellow star" width={16} height={16} />
        </ExpertRatingStyled>
      </HeaderStyled>
      <MessageStyled>{message}</MessageStyled>
    </WrapperStyled>
  );
};

export default ExpertMessage;
