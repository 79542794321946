import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const RootStyled = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.themeBackgroundLightSecondary};
    content: '';
  }
`;

export const ContentStyled = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-bottom: 130px;
  margin-left: auto;
`;

export const ButtonWrapperStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  transform: translateX(-50%);

  &::before {
    position: absolute;
    top: -10px;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 240, 240, 0) 0%,
      rgba(255, 240, 240, 0.95) 53.81%
    );
    content: '';
  }
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;
  max-width: 360px;
  height: 50px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

export const RouteLinkStyled = styled.button`
  z-index: 5;
  width: 310px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;
