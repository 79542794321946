import styled from 'styled-components';

export const CircularProgressStyled = styled.svg`
  transform: rotate(-90deg);
`;

export const ProgressTrackStyled = styled.circle`
  stroke: #ededed;
  fill: transparent;
`;

export const ProgressBarStyled = styled.circle`
  stroke: #6b3aa2;
  fill: transparent;
`;

export const TextStyled = styled.text`
  font-size: 20px;
  font-weight: 700;
`;
