import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import SquareButton from 'core/common/components/SquareButton';
import { LimitedChoiceScreenProps } from 'core/funnel/entities';
import {
  ButtonBlockSpace,
  Container,
  Content,
  FixedButtonBlock,
  IconImage,
  Item,
  List,
  StyledButton,
  StyledTitle,
  Subtitle,
} from './styled';

const LimitedChoiceScreen = ({
  onBack,
  onAnswerClick,
  onContinue,
  getIsAnswerChoosen,
  chosenList,
  step,
  content,
  totalSteps,
}: LimitedChoiceScreenProps) => {
  const isButtonDisabled = !chosenList.length;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} onBack={onBack} totalSteps={totalSteps} />
      <Container>
        <StyledTitle>{content.title}</StyledTitle>
        <Subtitle>{content.subtitle}</Subtitle>
        <Content>
          <List>
            {content.answers.map((answer) => (
              <Item key={answer.text}>
                <SquareButton
                  onClick={() => onAnswerClick(answer.value)}
                  icon={
                    <IconImage
                      src={answer.icon.src}
                      alt={answer.icon.alt || 'icon'}
                      width={40}
                      height={40}
                    />
                  }
                  isChoosen={getIsAnswerChoosen(answer.value)}
                >
                  {answer.text}
                </SquareButton>
              </Item>
            ))}
          </List>
        </Content>
      </Container>
      <ButtonBlockSpace />
      <FixedButtonBlock>
        <StyledButton onClick={onContinue} disabled={isButtonDisabled}>
          {content.next}
        </StyledButton>
      </FixedButtonBlock>
    </>
  );
};

export default LimitedChoiceScreen;
