import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import { Underline } from 'core/common/components/QuizScreens/TrialPayment/styled';
import { BASE_ROUTES } from 'core/common/constants';
import { useExtendedPolicy } from 'core/common/hooks';
import { formatPrice } from 'core/common/utils/formatPrice';
import { TrialPaymentWithDiscountScreenProps } from 'core/funnel/entities';
import { Locales, notPaidUtmSources, paidUtmSources } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import SectionOffer from './SectionOffer';
import SectionPayment from './SectionPayment';
import {
  BackgroundStyled,
  BoldStyled,
  ButtonStyled,
  CheckboxStyled,
  EmojiStyled,
  LinkStyled,
  MainContainerStyled,
  SubscriptionDescriptionStyled,
  TrialTitle,
} from './styled';

const TrialPaymentWithDiscountScreen = ({
  content,
  timeUpOffer,
  selectedOffer,
  discountOffer,
  goNext,
  getReport,
}: TrialPaymentWithDiscountScreenProps) => {
  const { title, offerContent, button } = content;

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.trialPaymentWithDiscount';

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { handlePolicyClick, setDisplayPolicyError, isPolicyAccepted, displayPolicyError } =
    useExtendedPolicy();

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  const onGetReport = () => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);

      return;
    }

    setIsPaymentModalOpen(true);
    getReport();
  };

  const onPaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
    handlePolicyClick(event.target.checked);
  };

  if (!discountOffer || !timeUpOffer || !selectedOffer) return null;

  const saveAmount = `${timeUpOffer.currencySymbol}${formatPrice(
    timeUpOffer.prices.priceWithVAT - discountOffer.prices.priceWithVAT,
  )}`;

  return (
    <>
      <BackgroundStyled />
      <Header buttonBack={false} />
      <MainContainerStyled>
        <EmojiStyled emoji={title.emoji} />
        <TrialTitle>{title.text}</TrialTitle>
        <SectionOffer
          content={offerContent}
          fullPrice={timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}
          discountPrice={discountOffer.pricesWithCurrencySymbol.priceWithVAT}
          discountTrialPrice={discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT}
          saveAmount={saveAmount}
        />
        <ButtonStyled onClick={onGetReport} variant="success">
          {button}
        </ButtonStyled>
        <Location>
          <LocationOption option={Locales.US}>
            <MediaSourceVariant name={paidUtmSources}>
              <SubscriptionDescriptionStyled>
                <Trans
                  i18nKey={`${tKeyPrefix}.descriptionExperiment`}
                  components={{ bold: <BoldStyled />, lnk: <Underline /> }}
                  values={{
                    fullPrice: selectedOffer.pricesWithCurrencySymbol.priceWithVAT,
                    discountPrice: discountOffer.pricesWithCurrencySymbol.priceWithVAT,
                    trialPrice: discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT,
                  }}
                />
                <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
              </SubscriptionDescriptionStyled>
            </MediaSourceVariant>
            <MediaSourceVariant name={notPaidUtmSources}>
              <CheckboxStyled
                name="consent"
                checked={isPolicyAccepted}
                onChange={onCheckboxClick}
                isConsentErrorOccured={displayPolicyError}
              >
                <SubscriptionDescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.descriptionExperiment`}
                    components={{ bold: <BoldStyled />, lnk: <Underline /> }}
                    values={{
                      fullPrice: selectedOffer.pricesWithCurrencySymbol.priceWithVAT,
                      discountPrice: discountOffer.pricesWithCurrencySymbol.priceWithVAT,
                      trialPrice: discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT,
                    }}
                  />
                  <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
                </SubscriptionDescriptionStyled>
              </CheckboxStyled>
            </MediaSourceVariant>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <SubscriptionDescriptionStyled>
              <Trans
                i18nKey={`${tKeyPrefix}.descriptionExperiment`}
                components={{ bold: <BoldStyled />, lnk: <Underline /> }}
                values={{
                  fullPrice: selectedOffer.pricesWithCurrencySymbol.priceWithVAT,
                  discountPrice: discountOffer.pricesWithCurrencySymbol.priceWithVAT,
                  trialPrice: discountOffer.pricesWithCurrencySymbol.trialPriceWithVAT,
                }}
              />
              <LinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('terms')}</LinkStyled>.
            </SubscriptionDescriptionStyled>
          </LocationOption>
        </Location>
      </MainContainerStyled>
      {!!discountOffer && (
        <SectionPayment
          onPaymentSuccess={goNext}
          isOpen={isPaymentModalOpen}
          offer={discountOffer}
          onClose={onPaymentModalClose}
        />
      )}
    </>
  );
};

export default TrialPaymentWithDiscountScreen;
