import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import Rating from 'core/common/components/Rating';
import Icon from 'core/svg-sprites/ui/Icon';
import Reviews from './Reviews';

export const BackgroundStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const HeaderStyled = styled(Header)`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 48px;
  }
`;

export const ReadingTopStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 52px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #e8eaf3;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 500px;
    margin-bottom: 140px;
  }
`;

export const IconStyled = styled(Icon)`
  width: 28px;
  height: 28px;
  margin-right: 8px;
  fill: #6469ac;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 36px;
    height: 36px;
  }
`;

export const ReadingWrapperStyled = styled.div`
  overflow: hidden;
`;

export const ReadingTitleStyled = styled.p`
  margin-bottom: 2px;
  font-weight: 600;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ReadingTextStyled = styled.p`
  font-size: 12px;

  b {
    font-weight: 600;
    word-wrap: break-word;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const DescriptionStyled = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 60px;
  }
`;

export const DescriptionTextStyled = styled.span`
  position: relative;
  top: -10px;
  z-index: 1;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const ImageStyled = styled(Image)``;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
    font-size: 42px;
    line-height: 50px;
  }
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 28px;
  line-height: 24px;
  text-align: center;

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.info};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 48px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ImageAdvisorsStyled = styled(Image)`
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-bottom: 52px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 500px;
    height: 96px;
    margin-bottom: 120px;
  }
`;

export const QuestionTitleStyled = styled.h3`
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 48px;
    font-size: 32px;
  }
`;

export const QuestionImageStyled = styled(Image)`
  margin-right: auto;
  margin-bottom: 52px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 120px;
  }
`;

export const BonusWrapperStyled = styled.div`
  margin-bottom: 52px;
  padding: 2px;
  border-radius: 8px;
  background: ${({ theme }) => theme.gradients.gradientPinkDropDown};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 500px;
    margin-bottom: 120px;
  }
`;

export const BonusStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const BonusIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 16px;
`;

export const BonusTitleStyled = styled(ReadingTitleStyled)``;

export const BonusTextStyled = styled(ReadingTextStyled)`
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.infoLight};
  }
`;

export const AppRatingWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 64px;
  }
`;

export const AppRatingLeftWingStyled = styled(Image)`
  margin-right: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 48px;
    height: 88px;
    margin-right: 20px;
  }
`;

export const AppRatingRightWingStyled = styled(Image)`
  margin-left: 12px;
  transform: scaleX(-1);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 48px;
    height: 88px;
    margin-left: 20px;
  }
`;

export const AppRatingContentStyled = styled.div``;

export const AppRatingReviewsStyled = styled.span`
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 7px;
    font-size: 27px;
    line-height: 40px;
  }
`;

export const RatingWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RatingStyled = styled(Rating)`
  margin-right: 6px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const RatingTextStyled = styled.span`
  font-size: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const ReviewsTitleStyled = styled(QuestionTitleStyled)`
  margin-bottom: 16px;

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.infoLight};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 36px;
  }
`;

export const ReviewsStyled = styled(Reviews)`
  margin-bottom: 16px;
`;

export const FooterStyled = styled.footer`
  margin-bottom: 72px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0f0f0f99;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 180px;
  }
`;

export const ButtonStyled = styled(Button)`
  position: fixed;
  bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  text-transform: initial;
  background: ${({ theme }) => theme.colors.infoLight};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    bottom: 100px;
    width: 535px;
  }
`;
