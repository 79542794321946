import { useRef, useState, useEffect, useMemo } from 'react';
import {
  useKeenSlider,
  KeenSliderInstance,
  KeenSliderOptions,
  TrackDetails,
} from 'keen-slider/react';
import { debounce } from '../../utils/debounce';
import {
  ContainerInnerStyled,
  ContainerStyled,
  ShadowStyled,
  SlidesStyled,
  SlideStyled,
  SlideValueStyled,
} from './styled';

const fullCircleDeg = 360;
const halfCircleDeg = 180;

type SlideValuesOptions = {
  slidesPerView: number;
  wheelSize: number;
  radius: number;
  sliderState: TrackDetails | null;
};

const getSlideValues = (
  data: Array<number | string>,
  option: SlideValuesOptions,
): Array<{ style: Record<string, string>; value: string | number }> => {
  const { slidesPerView, wheelSize, radius, sliderState } = option;

  if (!sliderState) return [];

  const half = 1 / 2;
  const slideOffset = 1 / slidesPerView;
  const halfSlideOffset = half * slideOffset;
  const offset = half - halfSlideOffset;

  return data.map((value, i) => {
    const distance = sliderState ? (sliderState.slides[i]?.distance - offset) * slidesPerView : 0;

    const rotate =
      Math.abs(distance) > wheelSize / 2
        ? halfCircleDeg
        : distance * (fullCircleDeg / wheelSize) * -1;

    const style = {
      transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
      WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
    };

    return { style, value };
  });
};

type DragSpeedOptions = {
  height: number;
  wheelSize: number;
  slidesPerView: number;
  speed: number;
  speedMultiplier: number;
};

const getDragSpeed = ({
  height,
  wheelSize,
  slidesPerView,
  speed,
  speedMultiplier,
}: DragSpeedOptions): number => {
  const slideDegree = fullCircleDeg / wheelSize;

  const adjustedDistance =
    height / ((height / 2) * Math.tan(slideDegree * (Math.PI / halfCircleDeg))) / slidesPerView;

  return speed * adjustedDistance * speedMultiplier;
};

export type WheelPickerProps = {
  initIdx?: number;
  loop?: boolean;
  values: string[];
  slidesPerView?: number;
  wheelSize?: number;
  onChange: (value: string) => void;
  speedMultiplier?: number;
};

const WheelPicker = ({
  initIdx = 0,
  slidesPerView = 9,
  speedMultiplier = 1,
  wheelSize = 20,
  loop = true,
  values,
  onChange,
}: WheelPickerProps) => {
  const [sliderState, setSliderState] = useState<TrackDetails | null>(null);
  const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance | null>(null);
  const [radius, setRadius] = useState(0);
  const size = useRef(0);
  const activeSlideIndex = sliderState ? sliderState.rel : 0;

  const changeSliderState = debounce((details: TrackDetails) => {
    setSliderState(details);
    const currentSlide = details.rel;
    const value = values[currentSlide];
    onChange(value);
  }, 30);

  const options: Partial<KeenSliderOptions> = useMemo(
    () => ({
      slides: {
        origin: 'center',
        number: values.length,
        perView: slidesPerView,
      },
      vertical: true,
      loop,
      initial: initIdx,
      rubberband: false,
      mode: 'free-snap',
      dragSpeed: (speed) => {
        return getDragSpeed({
          height: size.current,
          wheelSize,
          slidesPerView,
          speed,
          speedMultiplier,
        });
      },
      created: (s) => {
        size.current = s.size;
        setSliderInstance(s);
      },
      updated: (s) => {
        size.current = s.size;
      },
      detailsChanged: (s) => {
        setSliderState(s.track.details);
        changeSliderState(s.track.details);
      },
    }),
    [values.length, slidesPerView, loop, initIdx, wheelSize, speedMultiplier, changeSliderState],
  );

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options);

  useEffect(() => {
    if (sliderInstance === null) return;

    sliderInstance.moveToIdx(initIdx);
  }, [sliderInstance, initIdx]);

  useEffect(() => {
    if (slider.current) setRadius(slider.current.size / 2);
  }, [slider]);

  return (
    <ContainerStyled ref={sliderRef}>
      <ShadowStyled />
      <ContainerInnerStyled>
        <SlidesStyled>
          {getSlideValues(values, { sliderState, slidesPerView, wheelSize, radius }).map(
            ({ style, value }, idx) => (
              <SlideStyled active={activeSlideIndex === idx} style={style} key={value}>
                <SlideValueStyled>{value}</SlideValueStyled>
              </SlideStyled>
            ),
          )}
        </SlidesStyled>
      </ContainerInnerStyled>
      <ShadowStyled />
    </ContainerStyled>
  );
};

export default WheelPicker;
