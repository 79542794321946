import { TrialPaymentMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import SectionDisclaimer from '../SectionDisclaimer';
import SectionSpecialOffer from '../SectionSpecialOffer';
import {
  WrapperStyled,
  TitleStyled,
  ReadingContainerStyled,
  MainImageStyled,
  SubtitleStyled,
  BlurredWrapperStyled,
  ReadingDetailsStyled,
  ReadingDetailsItemStyled,
  ItemTitleStyled,
  ItemDescriptionStyled,
  ReadingDetailsIconStyled,
  ReadingDetailsItemContentStyled,
} from './styled';

type MoonCompatibilityReadingProps = {
  content: TrialPaymentMoonCompatibilityScreenAttributes['content']['reading'];
  offerBlockId: string;
  offer: Offer;
  timeUpOffer: Offer;
  isTimeUp: boolean;
  timeLeft: number;
  onGetReport: () => void;
};

const MoonCompatibilityReading = ({
  content,
  offerBlockId,
  offer,
  timeUpOffer,
  isTimeUp,
  timeLeft,
  onGetReport,
}: MoonCompatibilityReadingProps) => {
  const { title, mainImage, match, offer: offerContent } = content;

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <ReadingContainerStyled>
        <MainImageStyled src={mainImage.src} alt={mainImage.alt} width={160} height={160} />
        <SubtitleStyled>
          {match.title} <BlurredWrapperStyled>96</BlurredWrapperStyled>%
        </SubtitleStyled>
        <ReadingDetailsStyled>
          {match.list.map((item) => (
            <ReadingDetailsItemStyled key={item.title.text}>
              <ReadingDetailsIconStyled
                src={item.icon.src}
                alt={item.icon.alt}
                width={24}
                height={24}
              />
              <ReadingDetailsItemContentStyled>
                <ItemTitleStyled>
                  {item.title.text}{' '}
                  {item.title.blurred ? (
                    <BlurredWrapperStyled>{item.title.percents}</BlurredWrapperStyled>
                  ) : (
                    item.title.percents
                  )}
                  %
                </ItemTitleStyled>
                <ItemDescriptionStyled>{item.description}</ItemDescriptionStyled>
              </ReadingDetailsItemContentStyled>
            </ReadingDetailsItemStyled>
          ))}
        </ReadingDetailsStyled>
        <SubtitleStyled>{offerContent.sectionTitle}</SubtitleStyled>
        <SectionSpecialOffer
          id={offerBlockId}
          content={offerContent}
          offer={offer}
          timeUpOffer={timeUpOffer}
          onGetReport={onGetReport}
          timeLeft={timeLeft}
        />
        <SectionDisclaimer
          displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
          displayPlanPrice={
            isTimeUp
              ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
              : offer.pricesWithCurrencySymbol.priceWithVAT
          }
        />
      </ReadingContainerStyled>
    </WrapperStyled>
  );
};

export default MoonCompatibilityReading;
