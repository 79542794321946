import styled from 'styled-components';
import Image from 'core/common/components/Image';
import SectionDisclaimer from './SectionDisclaimer';

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 472px;
  margin-right: auto;
  margin-left: auto;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 145%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 40px;
    font-size: 32px;
  }
`;

export const NatalChartLogoStyled = styled(Image)`
  margin-bottom: 8px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
    margin-bottom: 32px;
  }
`;

export const FooterTextStyled = styled.p`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const SectionDisclaimerStyled = styled(SectionDisclaimer)`
  margin-bottom: 32px;
`;
