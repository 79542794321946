import { FunnelImage } from 'core/funnel/entities';
import { IconNameOrString } from 'core/svg-sprites/entities';
import UserData from './UserData';
import UserTraumaScore from './UserTraumaScore';
import { WrapperStyled, TitleStyled, ContentStyled } from './styled';

export type ListItemContent = {
  icon: IconNameOrString;
  title: string;
  value: string;
};

type ProfileShortProps = {
  title: string;
  genderContent: ListItemContent;
  birthDate?: ListItemContent;
  trauma: ListItemContent & {
    image: FunnelImage;
  };
  traumaScore: number;
};

const ProfileShort = ({
  title,
  genderContent,
  birthDate,
  trauma,
  traumaScore,
}: ProfileShortProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <ContentStyled>
        <UserData
          birthDate={birthDate}
          traumaListItem={trauma}
          gender={genderContent}
          traumaScore={traumaScore}
        />
        <UserTraumaScore {...trauma} traumaScore={traumaScore} />
      </ContentStyled>
    </WrapperStyled>
  );
};

export default ProfileShort;
