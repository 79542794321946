import styled from 'styled-components';
import { Align, ColorVariants } from './types';

export const TimerContainerStyled = styled.div``;

export const TimerTitleStyled = styled.h6<{ hideOnDesktop?: boolean; align?: Align }>`
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  text-align: ${({ align }) => {
    if (align === 'left') return 'left';
    if (align === 'center') return 'center';
    if (align === 'right') return 'right';
    return 'left';
  }};
  color: ${({ theme }) => theme.colors.typography700};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    ${({ hideOnDesktop }) => (hideOnDesktop ? 'display: none;' : '')};
  }
`;

export const TimerBodyStyled = styled.div<{ align?: Align }>`
  display: flex;
  justify-content: ${({ align }) => {
    if (align === 'left') return 'start';
    if (align === 'center') return 'center';
    if (align === 'right') return 'end';
    return 'start';
  }};
  margin-top: 3px;
`;

export const TimerUnitCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerUnitTextStyled = styled.p<{
  variant?: ColorVariants;
  isFontSizeSmallOnMobile?: boolean;
}>`
  font-size: ${({ isFontSizeSmallOnMobile }) => (isFontSizeSmallOnMobile ? '20px' : '24px')};
  font-weight: 700;
  line-height: 110%;
  color: ${({ theme, variant }) =>
    variant === 'tertiary' ? theme.colors.infoLight : theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const TimerDividerStyled = styled(TimerUnitTextStyled)`
  margin-right: 4px;
  margin-left: 4px;
`;

export const TimerUnitLabelStyled = styled.span`
  font-size: 8px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;
