import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import RatingComponent from 'core/common/components/Rating';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -10px;
`;

export const Item = styled.li`
  width: 330px;
  padding: 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    &:nth-child(1) {
      margin-right: 20px;
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Avatar = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  border-radius: 50%;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary700};
  background-color: ${(props) => props.theme.colors.primary200};
`;

export const DateWrapper = styled.div`
  flex: 1;
`;

export const Nickname = styled.p`
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.info};
`;

export const Date = styled.p`
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.typography400};
`;

export const Rating = styled(RatingComponent)``;

export const TextWrapper = styled.div`
  display: flex;
`;

export const Image = styled(ImageComponent)`
  flex-shrink: 0;
  margin-right: 10px;
`;

export const Text = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const ReviewPolicy = styled.a`
  margin-top: 20px;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 40px;
  }
`;
