import UserTypeHumanDesignScreen from 'core/common/components/QuizScreens/UserTypeHumanDesign';
import { useQuizStep } from 'core/funnel/contexts';
import { UserTypeHumanDesignScreenAttributes } from 'core/funnel/entities';

type UserTypeHumanDesignProps = {
  id: string;
};

const UserTypeHumanDesign = ({ id }: UserTypeHumanDesignProps) => {
  const { next, back, screenAttributes } = useQuizStep(id);

  return (
    <UserTypeHumanDesignScreen
      goNext={next}
      goBack={back}
      {...(screenAttributes as UserTypeHumanDesignScreenAttributes)}
    />
  );
};

export default UserTypeHumanDesign;
