import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { AddressStyled, ContainerStyled, ReportDescriptionStyled, AddressUSStyled } from './styled';

type SectionFooterProps = {
  disclaimer: string;
  addressUS: string;
};

const SectionFooter = ({ disclaimer, addressUS }: SectionFooterProps) => {
  return (
    <ContainerStyled>
      <ReportDescriptionStyled>{disclaimer}</ReportDescriptionStyled>
      <Location>
        <LocationOption option={Locales.US}>
          <AddressUSStyled>{addressUS}</AddressUSStyled>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <AddressStyled />
        </LocationOption>
      </Location>
    </ContainerStyled>
  );
};

export default SectionFooter;
