import { isValidPhoneNumber as libIsValidPhoneNumber, isSupportedCountry } from 'libphonenumber-js';

export const isValidPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;

  return libIsValidPhoneNumber(phoneNumber);
};

export const isSupportedPhoneNumberCountry = (country: string) => {
  return isSupportedCountry(country);
};
