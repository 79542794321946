import styled from 'styled-components';
import wing from 'core/common/assets/images/purpleWing.png';
import Icon from 'core/svg-sprites/ui/Icon';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 15px 40px;
  background: linear-gradient(
    168deg,
    #14133315 -32.12%,
    #3639a215 4.19%,
    #6d4bcd15 49.79%,
    #9d5be915 81.72%
  );

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 10%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 0.01%,
      rgba(255, 255, 255, 0.51) 49.5%,
      rgba(255, 255, 255, 0.81) 74.58%,
      #fff 100%
    );
    content: '';
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h4`
  margin-top: 38px;
  font-size: 24px;
  line-height: 135%;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;

  &::after,
  &::before {
    display: inline-block;
    width: 10px;
    height: 24px;
    background-image: url(${wing.src});
    background-size: 10px 24px;
    content: '';
  }

  &::after {
    margin-left: 4px;
  }

  &::before {
    transform: scaleX(-1);
  }
`;

export const StarStyled = styled(Icon)`
  margin-right: 2px;
  fill: ${({ theme }) => theme.colors.info};
`;

export const List = styled.ul`
  display: flex;
  margin-top: 20px;
`;

export const ListItem = styled.li`
  width: 50%;

  &:nth-child(odd) {
    margin-right: 12px;
  }
`;

export const ListTitle = styled.h4`
  margin-top: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
`;

export const ListDescription = styled.p`
  margin-top: 2px;
  font-size: 12px;
  line-height: 130%;
`;
