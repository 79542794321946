import { useCallback, useEffect } from 'react';
import EmailMarketingWitchPower from 'core/common/components/QuizScreens/EmailMarketingWitchPower';
import { useRouter, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { EmailMarketingWitchPowerScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder } from 'core/payments/hooks';
import { updateOrderId } from 'core/payments/store/actions';
import { useUserLocation } from 'core/user/hooks';

type WitchPowerEmailMarketingProps = {
  id: string;
};

const WitchPowerEmailMarketing = ({ id }: WitchPowerEmailMarketingProps) => {
  const { navigate } = useRouter();
  const { next, getScreenAttributes, updateUserPreferences } = useQuizStep(id);
  const { order } = useGetOrder();
  const { analyticsService } = useServices();
  const { defaultOffers, fetchOffersByCategory, updateOffer } = useOffers();
  const { isEuropean } = useUserLocation();

  const offer = defaultOffers[0];
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)
  const { analyticEmailMarketing } = screenAttributes as EmailMarketingWitchPowerScreenAttributes;

  const getReport = useCallback(
    (eventName: string) => {
      analyticsService.emailMarketingLpGetProduct({
        page: analyticEmailMarketing?.page ?? 'witch_power',
        button: eventName,
        source: analyticEmailMarketing?.source ?? 'witch_power_email_land_first',
        productId: offer.id,
      });

      if (order) {
        updateOrderId(order.order_id);
      }

      updateUserPreferences('plan', { productId: offer.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsService, navigate, order],
  );

  useEffect(() => {
    fetchOffersByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEuropean]);

  useEffect(() => {
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEuropean, offer]);

  useEffect(() => {
    analyticsService.emailMarketingLpOpened({
      page: analyticEmailMarketing?.page ?? 'witch_power',
      view: analyticEmailMarketing?.view ?? 'general_landing',
      source: analyticEmailMarketing?.source ?? 'witch_power_email_land_first',
    });
  }, [analyticsService, analyticEmailMarketing]);

  if (!('sections' in screenAttributes)) return null;

  return (
    <EmailMarketingWitchPower
      offer={offer}
      onGetReport={getReport}
      paymentSucceed={next}
      order={order}
      {...(screenAttributes as EmailMarketingWitchPowerScreenAttributes)}
    />
  );
};

export default WitchPowerEmailMarketing;
