import { useState } from 'react';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentMoonCompatibilityScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import Challenges from './Challenges';
import Guarantee from './Guarantee';
import ImportantQuestions from './ImportantQuestions';
import MoonCompatibilityPreview from './MoonCompatibilityPreview';
import MoonCompatibilityReading from './MoonCompatibilityReading';
import SalesScreenHeader from './SalesScreenHeader';
import SectionPartners from './SectionPartners';
import SectionPayment from './SectionPayment';
import SectionReviews from './SectionReviews';
import { FooterText, ContainerStyled } from './styled';

const offerScrollOffset = -130;
const offerBlockId = 'disclaimer-block';

const TrialPaymentMoonCompatibilityScreen = ({
  content,
  timeLeft,
  offer,
  timeUpOffer,
  moonImage,
  moonSignImage,
  partnerMoonImage,
  partnerMoonSignImage,
  getReport,
  onSucceed,
  onPaymentDialogClose,
}: TrialPaymentMoonCompatibilityScreenProps) => {
  const {
    header,
    reviews,
    featuredIn,
    footer,
    preview,
    reading,
    questions,
    challenges,
    moneyBackGuarantee,
  } = content;

  const onMoveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const isTimeUp = timeLeft <= 0;

  const onGetReport = () => {
    setIsPaymentModalOpen(true);
    getReport();
  };

  if (!timeUpOffer || !offer) {
    return null;
  }

  return (
    <>
      <SalesScreenHeader content={header} onScrollToBlock={onMoveToOffer} timeLeft={timeLeft} />
      <ContainerStyled>
        <MoonCompatibilityPreview
          content={preview}
          moonImage={moonImage}
          moonSignImage={moonSignImage}
          partnerMoonImage={partnerMoonImage}
          partnerMoonSignImage={partnerMoonSignImage}
          onMoveToOffer={onMoveToOffer}
        />
        <MoonCompatibilityReading
          content={reading}
          offerBlockId={offerBlockId}
          offer={offer}
          timeUpOffer={timeUpOffer}
          isTimeUp={isTimeUp}
          timeLeft={timeLeft}
          onGetReport={onGetReport}
        />
        <ImportantQuestions content={questions} onMoveToOffer={onMoveToOffer} />
        <Challenges content={challenges} onMoveToOffer={onMoveToOffer} />
        <SectionReviews content={reviews} />
        <SectionPartners title={featuredIn.title} />
        <Guarantee title={moneyBackGuarantee.title} description={moneyBackGuarantee.description} />
        <Location>
          <LocationOption option={Locales.US}>
            <FooterText>{footer.addressUS}</FooterText>
          </LocationOption>
          <LocationOption option={Locales.AE}>
            <FooterText>{footer.addressAE}</FooterText>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            <FooterText>{footer.address}</FooterText>
          </LocationOption>
        </Location>
      </ContainerStyled>
      <SectionPayment
        onPaymentSuccess={onSucceed}
        isTimeUp={isTimeUp}
        isOpen={isPaymentModalOpen}
        offer={offer}
        onClose={onPaymentDialogClose}
      />
    </>
  );
};

export default TrialPaymentMoonCompatibilityScreen;
