import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-top: 4px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledTitle = styled.h2`
  width: 100%;
  margin-bottom: 8px;
  font-size: 20px;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.desktop} {
    line-height: 28px;
  }
`;

export const StyledSubtitle = styled.p`
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const StyledGenderSection = styled.div`
  margin-bottom: 48px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 60px;
  }
`;

export const StyledGenderSectionTitle = styled.h4`
  margin-bottom: 40px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const StyledGenderList = styled.ul`
  display: flex;
  margin-right: -20px;
`;

export const StyledGenderListItem = styled.li`
  padding-right: 20px;
`;

export const StyledMaleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 52px;
  margin-top: -6px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.typography100};
  background: ${({ theme }) => theme.colors.lunarViolet};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const StyledFemaleButton = styled(StyledMaleButton)`
  background: ${({ theme }) => theme.gradients.geminiGradient};
`;

export const StyledArrow = styled.div`
  display: inline-block;
  padding: 5px;
  border-right: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  border-bottom: ${(props) => `2px solid ${props.theme.colors.typography100}`};
  transform: rotate(-45deg);
`;

export const StyledImage = styled(ImageComponent)`
  object-fit: contain;
  object-position: bottom;
`;
