import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  position: relative;
  margin-right: auto;
  margin-bottom: 56px;
  margin-left: auto;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  background: #d4c6e566;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
    padding-right: 80px;
    padding-left: 80px;
  }

  &::before,
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 51%;
    content: '';

    @media ${({ theme }) => theme.breakpoints.desktop} {
      height: 46%;
    }
  }

  &::before {
    background: linear-gradient(
      180deg,
      rgba(251, 251, 255, 0) 3.34%,
      rgba(251, 251, 255, 0.8) 24.99%,
      rgba(251, 251, 255, 0.9) 45.63%,
      rgba(255, 251, 251, 0.9) 69.74%,
      rgba(231, 222, 255, 0.7) 89.93%
    );
  }

  &::after {
    backdrop-filter: blur(2px);
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const ImageStyled = styled(ImageComponent)`
  display: block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 150px;
    height: 150px;
  }
`;

export const ContentTitleStyled = styled.h3`
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
`;

export const ListStyled = styled.ol`
  margin-bottom: 10px;
  counter-reset: item;
`;

export const ItemStyled = styled.li`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  &::before {
    margin-right: 5px;
    content: counters(item, '.') '. ';
    counter-increment: item;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const OverlayWrapperStyled = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OverlayTextStyled = styled.p`
  margin-bottom: 24px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.info};
`;

export const OverlayButtonStyled = styled(ButtonComponent)`
  position: relative;
  width: 100%;
  max-width: 360px;
  height: 56px;
  font-size: 18px;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.infoLight};
`;
