import styled from 'styled-components';

export const WrapperStyled = styled.section`
  margin-top: -140px;
  padding: 20px 12px;
  border-radius: 12px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.typography100};
`;

export const TitleStyled = styled.h2`
  font-size: 20px;
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 16px;
`;

export const IframeWrapperStyled = styled.div`
  position: relative;
  margin-bottom: 12px;
  padding-top: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 85%;
  }
`;

export const IframeStyled = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BirthDataListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`;

export const BirthDataItemStyled = styled.li`
  min-width: 50%;
`;

export const BirthDataTitle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const BirthDataValue = styled.p`
  font-size: 14px;
`;

export const DesignDataListStyled = styled.ul`
  margin-top: 20px;
  margin-bottom: 56px;
`;

export const DesignDataListItemStyled = styled.li`
  margin-bottom: 20px;
`;
