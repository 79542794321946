import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailConfirmPalmistryScreenProps } from 'core/funnel/entities';
import { ContainerMain, Button, Image, ImageContainer, Skip, Subtitle, Title } from './styled';

const EmailConfirmPalmistryScreen = ({
  content,
  onUserDisagree,
  onUserAgree,
  onBack,
}: EmailConfirmPalmistryScreenProps) => {
  return (
    <>
      <Header onBack={onBack} />
      <ContainerMain>
        <ImageContainer>
          <Image src={content.image} alt="confirm" width={180} height={180} />
        </ImageContainer>
        <Title dangerouslySetInnerHTML={prepareHtml(content.title)} />
        <Subtitle>{content.subtitle}</Subtitle>
        <Button onClick={onUserAgree}>{content.button}</Button>
        <Skip onClick={onUserDisagree}>{content.skip}</Skip>
      </ContainerMain>
    </>
  );
};

export default EmailConfirmPalmistryScreen;
