import styled from 'styled-components';
import Image from 'core/common/components/Image';
import TitleComponent from 'core/common/components/Title';

export const ContainerStyled = styled.div`
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  text-align: center;
`;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 180%;
`;

export const ImageStyled = styled(Image)`
  width: 330px;
  height: auto;
`;
