import { TrialPaymentPalmistryV2ScreenAttributes } from 'core/funnel/entities';
import Timer from './Timer';
import { ActionButton, ButtonContainer, Header } from './styled';

export type SalesScreenHeaderProps = {
  content: TrialPaymentPalmistryV2ScreenAttributes['content']['header'];
  timeLeft: number;
  onScrollToBlock: () => void;
};

const SalesScreenHeader = ({ content, timeLeft, onScrollToBlock }: SalesScreenHeaderProps) => {
  const { title, button } = content;

  return (
    <Header>
      <Timer title={title} timeLeft={timeLeft} />
      <ButtonContainer>
        <ActionButton impulseScale onClick={onScrollToBlock}>
          {button}
        </ActionButton>
      </ButtonContainer>
    </Header>
  );
};

export default SalesScreenHeader;
