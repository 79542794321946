import { useEffect } from 'react';
import W2APromotionScreen from 'core/common/components/QuizScreens/W2APromotion';
import { LoadingStatuses } from 'core/common/entities';
import { useAppDownloadLink, useServices } from 'core/common/hooks';
import { redirectToUrl } from 'core/common/utils/redirectToUrl';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { W2APromotionScreenAttributes } from 'core/funnel/entities';

type W2APromotionProps = {
  id: string;
};

const W2APromotion = ({ id }: W2APromotionProps) => {
  const { analyticsService } = useServices();
  const { screenAttributes } = useQuizStep(id);

  const { downloadAppLinkLoadingStatus, fetchAppDownloadLink, downloadAppLink } =
    useAppDownloadLink();

  const decoratedNext = () => {
    analyticsService.earlyW2APromoScreenClick('download');

    setTimeout(() => {
      redirectToUrl(downloadAppLink);
    }, 0);
  };

  useEffect(() => {
    analyticsService.earlyW2APromoScreenOpen();
    fetchAppDownloadLink();
  }, [analyticsService, fetchAppDownloadLink]);

  const appDownloadLinkIsLoading = downloadAppLinkLoadingStatus !== LoadingStatuses.FULFILLED;

  return (
    <W2APromotionScreen
      goNext={decoratedNext}
      downloadDisabled={appDownloadLinkIsLoading}
      {...(screenAttributes as W2APromotionScreenAttributes)}
    />
  );
};

export default W2APromotion;
