import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  ChartImageStyled,
  ChartAspectRatioWrapperStyled,
  CurrentMonthLabelStyled,
  FutureMonthLabelStyled,
  CaptionStyled,
} from './styled';

type RelationshipChartProps = {
  title: string;
  caption: string;
  image: FunnelImage;
  currentMonth: string;
  futureMonth: string;
};

const RelationshipChart = ({
  title,
  image,
  caption,
  currentMonth,
  futureMonth,
}: RelationshipChartProps) => {
  return (
    <>
      <WrapperStyled>
        <TitleStyled>{title}</TitleStyled>
        <ChartAspectRatioWrapperStyled>
          <ChartImageStyled src={image.src} alt={image.alt} fill priority />
          <CurrentMonthLabelStyled>{currentMonth}</CurrentMonthLabelStyled>
          <FutureMonthLabelStyled>{futureMonth}</FutureMonthLabelStyled>
        </ChartAspectRatioWrapperStyled>
      </WrapperStyled>
      <CaptionStyled>{caption}</CaptionStyled>
    </>
  );
};

export default RelationshipChart;
