import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 43px;
  }
`;

export const ImageStyled = styled(Image)`
  border-radius: 32px;
  object-fit: contain;
`;

export const ListImageStyled = styled(Image)`
  margin-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 40px;
    height: 40px;
  }
`;

export const AspectRatioStyled = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 20px;
  padding-top: 54%;
`;

export const ListStyled = styled.ul`
  display: flex;
`;

export const ListItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding-right: 4px;
  padding-left: 4px;
`;

export const BenefitTitleStyled = styled.h4`
  margin-bottom: 2px;
  font-size: 18px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 22px;
  }
`;

export const BenefitTextStyled = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 60%;
  }
`;
