import natalChart from 'core/common/assets/images/natal-chart/natalChart.png';
import Image from 'core/common/components/Image';
import { TrialPaymentNatalChartScreenAttributes } from 'core/funnel/entities';
import {
  BlurredReportWrapper,
  ContentBlock,
  ContentText,
  ContentTitle,
  Item,
  ItemText,
  NumberedList,
  ReportTitle,
  BlurredReportBody,
  BlurOuterContainer,
  BlurInnerContainer,
  AccessDescription,
  Button,
  LogoImageBlock,
} from './styled';

type SectionBlurredReportProps = {
  content: TrialPaymentNatalChartScreenAttributes['content']['report'];
  onScrollToBlock: () => void;
};

const SectionBlurredReport = ({ content, onScrollToBlock }: SectionBlurredReportProps) => {
  const {
    title,
    contentTitle,
    contentPoints,
    personalityTitle,
    personalityPoints,
    accessDescription,
    button,
  } = content;

  return (
    <BlurredReportWrapper>
      <BlurredReportBody>
        <ReportTitle>{title}</ReportTitle>
        <LogoImageBlock>
          <Image alt="natal chart" src={natalChart.src} layout="fill" objectFit="contain" />
        </LogoImageBlock>
        <ContentBlock>
          <ContentTitle>{contentTitle}</ContentTitle>
          <NumberedList>
            {contentPoints.map((text) => (
              <Item key={text}>
                <ItemText>{text}</ItemText>
              </Item>
            ))}
          </NumberedList>
          <ContentTitle>{personalityTitle}</ContentTitle>
          <ContentText>{personalityPoints}</ContentText>
        </ContentBlock>
        <BlurOuterContainer>
          <BlurInnerContainer>
            <AccessDescription>{accessDescription}</AccessDescription>
            <Button onClick={onScrollToBlock}>{button}</Button>
          </BlurInnerContainer>
        </BlurOuterContainer>
      </BlurredReportBody>
    </BlurredReportWrapper>
  );
};

export default SectionBlurredReport;
