import female from 'core/common/assets/images/hintQuiz/female.png';
import male from 'core/common/assets/images/hintQuiz/male.png';
import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import PolicyExtended from 'core/common/components/PolicyExtended';
import QuizBackground from 'core/common/components/QuizBackground';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { GenderScreenProps } from 'core/funnel/entities';
import { Locales, Regions, paidUtmSources, notPaidUtmSources } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  Address,
  AddressUS,
  Arrow,
  Container,
  Disclaimer,
  FemaleButton,
  GenderList,
  GenderListItem,
  GenderSection,
  GenderSectionTitle,
  Image,
  MaleButton,
  Policy,
  SignIn,
  Subtitle,
  Title,
  DataSecuredBadge,
} from './styled';

const GenderScreen = ({
  content,
  isPolicyAccepted,
  displayPolicyError,
  onGenderClick,
  onPolicyClick,
  signIn,
}: GenderScreenProps) => {
  return (
    // TODO: to run experiment on the first screen, remove after experiment
    <RenderOnlyOnClient>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <Container>
        <Title>{content.title}</Title>
        <Subtitle>{content.subtitle}</Subtitle>
        <GenderSection>
          <GenderSectionTitle>{content.actionTitle}</GenderSectionTitle>
          <GenderList>
            <GenderListItem onClick={() => onGenderClick(content.male.value)}>
              <Image src={male} placeholder="blur" alt="a man" width={150} height={174} />
              <MaleButton>
                {content.male.text} <Arrow />
              </MaleButton>
            </GenderListItem>
            <GenderListItem onClick={() => onGenderClick(content.female.value)}>
              <Image src={female} placeholder="blur" alt="a woman" width={150} height={174} />
              <FemaleButton>
                {content.female.text} <Arrow />
              </FemaleButton>
            </GenderListItem>
          </GenderList>
        </GenderSection>
        {content.signIn && <SignIn onClick={signIn}>{content.signIn}</SignIn>}
        {content.dataSecuredBadge && (
          <DataSecuredBadge
            title={content.dataSecuredBadge.title}
            icon={content.dataSecuredBadge.icon}
          />
        )}
        <Disclaimer>{content.disclaimer}</Disclaimer>
        {/* Terms for paid trafic (start) */}
        <MediaSourceVariant name={paidUtmSources}>
          <LocationRegion>
            <LocationRegionOption option={Regions.CALIFORNIA}>
              <PolicyExtended
                onPolicyClick={onPolicyClick}
                isPolicyAccepted={isPolicyAccepted}
                withoutCheckbox
              />
            </LocationRegionOption>
            <LocationRegionOption option={Regions.OTHER}>
              <Policy />
            </LocationRegionOption>
          </LocationRegion>
        </MediaSourceVariant>
        {/* Terms for paid trafic (end) */}
        {/* Terms for organic trafic (start) */}
        <MediaSourceVariant name={notPaidUtmSources}>
          <Location>
            <LocationOption option={Locales.US}>
              <PolicyExtended
                onPolicyClick={onPolicyClick}
                isPolicyAccepted={isPolicyAccepted}
                isError={displayPolicyError}
              />
            </LocationOption>
          </Location>
        </MediaSourceVariant>
        {/* Terms for organic trafic (end) */}
        <Location>
          <LocationOption option={Locales.US}>
            <AddressUS>Nevada, USA</AddressUS>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>{!!content.address && <Address />}</LocationOption>
        </Location>
      </Container>
    </RenderOnlyOnClient>
  );
};

export default GenderScreen;
