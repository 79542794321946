import { ChangeEvent, useCallback, useState } from 'react';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import { ContainerMain, Image } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { useServices } from 'core/common/hooks';
import { debounce } from 'core/common/utils/debounce';
import { PlaceListData, PlaceScreenProps } from 'core/funnel/entities';
import PlaceList from './PlaceList';
import {
  AutocompleteContainer,
  Content,
  HideButton,
  InputBlock,
  Skip,
  StyledButton,
  StyledText,
  StyledTitle,
} from './styled';

const PlaceScreen = ({
  content,
  skipData,
  progressBarTitle,
  progressBarName,
  totalProfileSteps,
  totalPersonalitySteps,
  totalRelationshipSteps,
  initialCoordinates,
  initialLocation,
  onSubmit,
  onBack,
  step,
}: PlaceScreenProps) => {
  const { autocompleteService } = useServices();
  const [location, setLocation] = useState(initialLocation);
  const [place, setPlace] = useState(initialCoordinates);
  const [citiesList, setPlaceListData] = useState<Array<PlaceListData>>([]);
  const [isVisibleContent, setVisibleContent] = useState(true);

  const handleInputClick = () => setVisibleContent(false);

  const handleCancelClick = () => {
    if (!location) setPlace([]);
    closePlaceListData();
  };

  const closePlaceListData = () => {
    setPlaceListData([]);
    setVisibleContent(true);
  };

  const handleButtonClick = () => {
    onSubmit(place, location);
  };

  const handleSkipClick = (defaultPlace: number[], defaultLocation: string) => {
    onSubmit(defaultPlace, defaultLocation);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    await debouncedGeolocation(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGeolocation = useCallback(
    debounce(async (value) => {
      if (value) {
        const features = await autocompleteService.getFeatures(value);
        return setPlaceListData(features);
      }

      return setPlaceListData([]);
    }, 500),
    [],
  );

  const handleSelectedItem = useCallback((cityPlace: number[], cityLocation: string) => {
    // TODO: Think maybe create one state object for all this states
    setPlace(cityPlace);
    setLocation(cityLocation);
    setPlaceListData([]);
    closePlaceListData();
  }, []);

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header onBack={onBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <ContainerMain>
        <Content>
          {!!content.image && (
            <Image
              src={content.image.src}
              alt={content.image.alt || 'place'}
              width={203}
              height={203}
            />
          )}
          <StyledTitle>{content.title}</StyledTitle>
          <StyledText>{content.text}</StyledText>
          <AutocompleteContainer>
            <InputBlock>
              <Input
                placeholder={content.placeholder}
                onChange={handleInputChange}
                value={location}
                onClick={handleInputClick}
              />
              {!isVisibleContent && (
                <HideButton onClick={handleCancelClick}>{content.cancel}</HideButton>
              )}
            </InputBlock>
            <PlaceList onSelected={handleSelectedItem} citiesList={citiesList} />
          </AutocompleteContainer>
          {isVisibleContent && (
            <StyledButton onClick={handleButtonClick} disabled={!place.length}>
              {content.next}
            </StyledButton>
          )}
        </Content>
        {!!skipData && (
          <Skip
            role="button"
            onClick={() => handleSkipClick(skipData.defaultCoordinates, skipData.defaultPlace)}
          >
            {skipData.text}
          </Skip>
        )}
      </ContainerMain>
    </>
  );
};
export default PlaceScreen;
