import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  padding: 32px 12px;
  border-radius: 16px;
  box-shadow: 0 0 10px 0 #00000026;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const ListStyled = styled.ul``;

export const ItemStyled = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const IconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 6px;
`;
