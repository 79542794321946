import styled, { keyframes } from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const fadeInUp = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
    transform: none
  }
`;

export const ContentWrapperStyled = styled.section`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
  color: ${(props) => props.theme.colors.typography100};
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 230px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 300px;
  }
`;

export const ButtonStyled = styled(Button)`
  position: sticky;
  bottom: 20px;
  width: 100%;
  height: 50px;
  margin-top: auto;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 60px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 4px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 48px;
  }
`;

export const DescriptionStyled = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ImageStyled = styled(Image)`
  object-fit: contain;
  animation: ${fadeInUp} 1.5s;
`;
