import Timer from './Timer';
import { ButtonContainer, GetReadingButton, Header, HeaderCell, Title } from './styled';

export type SalesScreenHeaderProps = {
  hide: boolean;
  onScrollToBlock: () => void;
  title: string;
  button: string;
  timerMinTitle: string;
  timerSecTitle: string;
  timeLeft: number;
};

const SalesScreenHeader = ({
  onScrollToBlock,
  hide,
  title,
  button,
  timerMinTitle,
  timerSecTitle,
  timeLeft,
}: SalesScreenHeaderProps) => {
  return (
    <Header hide={hide}>
      <HeaderCell className="hidden-on-mobile">
        <Title>{title}</Title>
      </HeaderCell>
      <HeaderCell className="justify">
        <Timer timeLeft={timeLeft} minTitle={timerMinTitle} secTitle={timerSecTitle} />
      </HeaderCell>
      <HeaderCell className="justify-end">
        <ButtonContainer>
          <GetReadingButton impulseScale onClick={onScrollToBlock}>
            {button}
          </GetReadingButton>
        </ButtonContainer>
      </HeaderCell>
    </Header>
  );
};

export default SalesScreenHeader;
