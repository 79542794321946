import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import Image from 'core/common/components/Image';
import TitleComponent from 'core/common/components/Title';

export const StyledImage = styled(Image)`
  width: 150px;
  height: 150px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 472px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 48px;
  }
`;

export const Title = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 44px;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 4px;
    font-size: 18px;
  }
`;

export const ImageContainer = styled.div`
  margin-bottom: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 10px;
  }
`;

export const ImageBackgroundContainer = styled.div`
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 666px;
  transform: translateX(-50%);
`;

export const List = styled.ul`
  max-width: 300px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 48px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Emoji = styled(EmojiComponent)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 24px;
    height: 24px;
  }
`;

export const EmojiBackground = styled.div<{ variant: 'orange' | 'gray' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.variant === 'orange' ? props.theme.colors.warning100 : props.theme.colors.primary100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 50px;
    height: 50px;
  }
`;

export const Text = styled.p`
  margin-left: 8px;
  font-size: 14px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 20px;
    font-size: 18px;
  }
`;

export const Price = styled.p`
  margin-bottom: 16px;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

export const Highlight = styled.span`
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.typography100};
  background-color: ${(props) => props.theme.colors.error};
`;

export const Button = styled(ButtonComponent)`
  height: unset;
  padding: 15px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const Background = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${(props) => props.theme.colors.typography100};
`;

export const CrossedPrice = styled.span`
  text-decoration: line-through;
  color: ${(props) => props.theme.colors.typography500};
`;

export const BoldText = styled.strong`
  font-weight: 700;
`;
