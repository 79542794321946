import styled from 'styled-components';
import ProcessingLoader, { Progress } from 'core/common/components/ProcessingLoader';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  max-width: 330px;
  margin: 20px auto 10px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};
`;

export const StyledTitle = styled(Title)`
  z-index: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 160%;

  & > b {
    text-transform: capitalize;
    color: ${(props) => props.theme.colors.warning};
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
`;

export const StyledProcessingLoader = styled(ProcessingLoader)`
  margin-bottom: 40px;

  ${Progress} {
    stroke: #6f4cc5;
  }
`;

export const Description = styled.p``;
