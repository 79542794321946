import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import GuaranteeComponent from 'core/common/components/Guarantee';
import Image from 'core/common/components/Image';
import NavLink from 'core/common/components/Link';

export const WrapperStyled = styled.div`
  padding: 32px 10px;
  border: 3px solid ${({ theme }) => theme.colors.lunarViolet};
  border-radius: 20px;
  background: #9974f60d;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
    line-height: 38px;
  }
`;

export const GoalWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const GoalImageStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;
`;

export const GoalStyled = styled.div`
  text-align: left;
`;

export const GoalTitleStyled = styled.span`
  font-size: 12px;
  line-height: 16px;
`;

export const GoalTextStyled = styled.p`
  font-weight: 600;
  line-height: 21px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const OfferWrapperStyled = styled.div`
  margin-bottom: 28px;
  border-radius: 20px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const OfferHeaderStyled = styled.p`
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  background: ${({ theme }) => theme.colors.lunarViolet};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
  }
`;

export const OfferContentStyled = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

export const OfferTitleStyled = styled.h3`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const HighlightStyled = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.info};
`;

export const OfferPriceWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.typography200};
`;

export const OfferPriceTitleStyled = styled.span`
  font-size: 14px;
  line-height: 24px;
`;

export const OfferPriceStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const OfferOldPriceStyled = styled.span`
  margin-right: 4px;
  font-size: 12px;
  line-height: 18px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const OfferNewPriceStyled = styled.span`
  font-weight: 600;
  line-height: 20px;
`;

export const OfferPriceDescriptionStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoldStyled = styled.b`
  font-weight: 600;
`;

export const ActionButtonStyled = styled(Button)`
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;
  font-size: 18px;
  font-weight: 700;
`;

export const GuaranteeStyled = styled(GuaranteeComponent)`
  justify-content: center;
  margin-bottom: 28px;
`;

export const DisclaimerStyled = styled.span`
  font-size: 12px;
  line-height: 1;
`;

export const LinkStyled = styled(NavLink)`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
`;
