import Header from 'core/common/components/Header';
import { HelloMoonCompatibilityScreenProps } from 'core/funnel/entities';
import {
  TitleStyled,
  ContentStyled,
  TextStyled,
  ButtonStyled,
  AnimatedImageStyled,
} from './styled';

const HelloMoonCompatibilityScreen = ({
  content,
  goNext,
  onBack,
}: HelloMoonCompatibilityScreenProps) => {
  const { animatedImage, title, firstText, secondText, button } = content;

  return (
    <>
      <Header onBack={onBack} />
      <ContentStyled>
        <AnimatedImageStyled variant={animatedImage} />
        <TitleStyled>{title}</TitleStyled>
        <TextStyled>{firstText}</TextStyled>
        <TextStyled>{secondText}</TextStyled>
        <ButtonStyled onClick={goNext}>{button}</ButtonStyled>
      </ContentStyled>
    </>
  );
};

export default HelloMoonCompatibilityScreen;
