import BeebomLogo from 'core/common/assets/images/productOverview/beebom.png';
import BustleLogo from 'core/common/assets/images/productOverview/bustle.png';
import HypebaeLogo from 'core/common/assets/images/productOverview/hypebae.png';
import LifewireLogo from 'core/common/assets/images/productOverview/lifewire.png';
import NewYorkTimesLogo from 'core/common/assets/images/productOverview/new-york-times.png';
import RefineryLogo from 'core/common/assets/images/productOverview/refinery.png';
import UrbanLogo from 'core/common/assets/images/productOverview/urban-list.png';
import WellGoodLogo from 'core/common/assets/images/productOverview/well-good.png';
import Image from 'core/common/components/Image';
import { List, ListItem } from './styled';

const partners = [
  { img: NewYorkTimesLogo, alt: 'New Tork Times', size: { width: 212, height: 30 } },
  { img: LifewireLogo, alt: 'Lifewire', size: { width: 90, height: 20 } },
  { img: RefineryLogo, alt: 'Refinery', size: { width: 162, height: 32 } },
  { img: BustleLogo, alt: 'Bustle', size: { width: 89, height: 24 } },
  { img: UrbanLogo, alt: 'Urban', size: { width: 104, height: 36 } },
  { img: BeebomLogo, alt: 'Beebom', size: { width: 112, height: 22 } },
  { img: HypebaeLogo, alt: 'Hypebae', size: { width: 90, height: 24 } },
  { img: WellGoodLogo, alt: 'WellGood', size: { width: 288, height: 16 } },
];

const Partners = () => {
  return (
    <List>
      {partners.map(({ img, alt, size }) => (
        <ListItem key={alt}>
          <Image src={img} alt={alt} width={size.width} height={size.height} />
        </ListItem>
      ))}
    </List>
  );
};

export default Partners;
