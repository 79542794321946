import SummaryScreen from 'core/common/components/QuizScreens/Summary';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { SummaryScreenAttributes } from 'core/funnel/entities';

type SummaryProps = {
  id: string;
};

const Summary = ({ id }: SummaryProps) => {
  const { back, next, screenAttributes } = useQuizStep(id);

  return (
    <SummaryScreen goNext={next} goBack={back} {...(screenAttributes as SummaryScreenAttributes)} />
  );
};

export default Summary;
