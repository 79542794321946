import TimelineScreen from 'core/common/components/QuizScreens/Timeline';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { TimelineScreenAttributes } from 'core/funnel/entities';

export type TimelineProps = {
  id: string;
};

const Timeline = ({ id }: TimelineProps) => {
  const { next, back, screenAttributes } = useQuizStep(id);

  return (
    <TimelineScreen
      goNext={next}
      goBack={back}
      {...(screenAttributes as TimelineScreenAttributes)}
    />
  );
};

export default Timeline;
