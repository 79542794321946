import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TrialChoiceNatalChartScreen from 'core/common/components/QuizScreens/TrialChoiceNatalChart';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialChoiceNatalChartScreenAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';

type TrialChoiceNatalChartProps = {
  id: string;
};

const TrialChoiceNatalChart = ({ id }: TrialChoiceNatalChartProps) => {
  const dispatch = useDispatch();
  const { analyticsService } = useServices();
  const { next, screenAttributes } = useQuizStep(id);
  const { updateOffer, defaultOffers: offers, loadingStatus } = useOffers();

  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);

  const { orderId } = useOrder();

  useEffect(() => {
    analyticsService.landingPageOpened('trial_choice');
  }, [analyticsService]);

  const changeSelectedOffer = (value: Offer) => {
    setSelectedOffer(value);
  };

  const onSubmit = useCallback(() => {
    if (!selectedOffer) return;

    updateOffer(selectedOffer);

    analyticsService.landingPageClick('trial_choice', selectedOffer.offerId);
    analyticsService.paymentPlanChosen(orderId);

    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, analyticsService, next, selectedOffer]);

  return (
    <TrialChoiceNatalChartScreen
      offers={offers}
      selectedOffer={selectedOffer}
      loadingStatus={loadingStatus}
      onSubmit={onSubmit}
      changeSelectedOffer={changeSelectedOffer}
      {...(screenAttributes as TrialChoiceNatalChartScreenAttributes)}
    />
  );
};

export default TrialChoiceNatalChart;
