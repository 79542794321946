import Reviews from 'core/common/components/Reviews';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelReview } from 'core/funnel/entities';
import { ReviewsBlockStyled, TitleStyled } from './styled';

type SectionReviewsProps = {
  title: string;
  reviews: Array<FunnelReview>;
};

const SectionReviews = ({ title, reviews }: SectionReviewsProps) => {
  return (
    <ReviewsBlockStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <Reviews list={reviews} />
    </ReviewsBlockStyled>
  );
};

export default SectionReviews;
