import styled from 'styled-components';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';

export const Wrapper = styled.div`
  margin-bottom: 32px;
  padding: 16px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.typography100};
  box-shadow: 0 4px 8px 0 rgba(57, 71, 92, 0.15);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 44px;
    padding: 24px 20px;
  }
`;

export const PriceTitle = styled.h3`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

export const PriceSubtitle = styled.h4`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 24px;
  }
`;

export const Discount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 5px 10px;
  border-radius: 8px;
  background: ${({ theme }) => theme.gradients.primary};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    background: linear-gradient(95deg, #cf8bf3 -16.49%, #a770ef -15.14%, #fdb99b 115.23%);
  }
`;

export const DiscountIcon = styled(EmojiComponent)`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 5px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;

export const DiscountTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscountText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const DiscountPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DiscountOldPrice = styled.span`
  position: relative;
  margin-right: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography300};

  &::before {
    position: absolute;
    bottom: 9px;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.error300};
    transform: rotate(16deg);
    content: '';
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.typography700};
  }
`;

export const DiscountPrice = styled.span`
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.typography100};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.typography900};
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 5px;
    font-size: 16px;
  }
`;

export const PriceText = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const PriceWrapper = styled.div``;

export const OldPrice = styled(DiscountOldPrice)`
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const NewPrice = styled.span`
  color: ${({ theme }) => theme.colors.typography800};
`;

export const PriceDescription = styled.p`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.infoLight};
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalText = styled.span`
  color: ${({ theme }) => theme.colors.typography900};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const TotalPrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.infoLight};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;
