import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const FooterStyled = styled.div`
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin-left: -15px;
  background: linear-gradient(90.6deg, #c1f0ff 0.47%, #cca1ff 137.94%);
  background: rgba(255, 240, 240, 0.3);
  backdrop-filter: blur(4px);

  @media ${(props) => props.theme.breakpoints.desktop} {
    left: 0;
    width: calc(100% + 100px);
    margin-left: 0;
    border-width: 3px;
  }
`;

export const ButtonStyled = styled(ButtonComponent)`
  display: flex;
  width: 280px;
  height: 49px;
  margin-bottom: 0;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
    font-size: 16px;
  }
`;
