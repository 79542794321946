import { SectionUpsellReadingsPackOfferAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  DiscountStyled,
  PriceStyled,
  OldPriceStyled,
  DisclaimerStyled,
  ButtonStyled,
  SkipButtonStyled,
} from './styled';

type SectionReadingsPackOfferProps = {
  content: SectionUpsellReadingsPackOfferAttributes['content'];
  className?: string;
  price: string;
  trialPrice: string;
  isLoading: boolean;
  onGetOffer: () => void;
  onSkipOffer: () => void;
};

const SectionReadingsPackOffer = ({
  content,
  className,
  price,
  trialPrice,
  isLoading,
  onGetOffer,
  onSkipOffer,
}: SectionReadingsPackOfferProps) => {
  const { discount, disclaimer, buttonText, skipButtonText } = content;

  return (
    <WrapperStyled className={className}>
      <DiscountStyled>{discount}</DiscountStyled>
      <PriceStyled>
        <OldPriceStyled>{price}</OldPriceStyled>
        {trialPrice}
      </PriceStyled>
      <DisclaimerStyled>{disclaimer}</DisclaimerStyled>
      <ButtonStyled fullWidth loading={isLoading} disabled={isLoading} onClick={onGetOffer}>
        {buttonText}
      </ButtonStyled>
      <SkipButtonStyled onClick={onSkipOffer}>{skipButtonText}</SkipButtonStyled>
    </WrapperStyled>
  );
};

export default SectionReadingsPackOffer;
