import { useEffect, useState } from 'react';
import { SECOND } from 'core/common/constants';
import { floorPercentByThreePercentStep, floorPercent } from 'core/common/utils/floorPercents';
import ProcessingLoaderCircle from './ProcessingLoaderCircle';
import { Block, Container, Value } from './styled';

export type HintProcessingLoaderProps = {
  onFinished: () => void;
  speedRatio?: LoaderSpeedRatio;
  className?: string;
};

export enum LoaderSpeedRatio {
  FAST = 0.005,
  DEFAULT = 0.015,
}

const HintProcessingLoader = ({
  onFinished,
  className,
  speedRatio = LoaderSpeedRatio.DEFAULT,
}: HintProcessingLoaderProps) => {
  const [value, setValue] = useState(0);

  const timeout = SECOND * speedRatio;
  const threeDecimialPercents = 0.3;
  const oneDecimialPercent = 0.1;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const setStepValue = (step: number) =>
      setTimeout(() => {
        setValue((prev) => {
          let next = prev + step;
          next = next <= 100 ? next : 100;
          return next;
        });
      }, timeout);

    if (value < 100) {
      timer = value >= 67 ? setStepValue(threeDecimialPercents) : setStepValue(oneDecimialPercent);
    } else {
      onFinished();
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFinished, value]);

  const displayValue = value <= 67 ? floorPercent(value) : floorPercentByThreePercentStep(value);

  return (
    <Container className={className}>
      <ProcessingLoaderCircle value={value} />
      <Block>
        <Value>{displayValue}%</Value>
      </Block>
    </Container>
  );
};

export default HintProcessingLoader;
