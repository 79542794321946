import RelationshipProgressScreen from 'core/common/components/QuizScreens/RelationshipProgress';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { RelationshipProgressScreenAttributes } from 'core/funnel/entities';

type RelationshipProgressProps = {
  id: string;
};

const RelationshipProgress = ({ id }: RelationshipProgressProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <RelationshipProgressScreen
      goNext={next}
      goBack={back}
      {...(screenAttributes as RelationshipProgressScreenAttributes)}
    />
  );
};

export default RelationshipProgress;
