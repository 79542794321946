import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section`
  margin-top: 52px;
`;

export const TitleStyled = styled.h2`
  font-size: 24px;
  text-align: center;
`;

export const ReadingContainerStyled = styled.div`
  margin-top: 20px;
  padding: 20px 12px;
  border: 2px solid ${({ theme }) => theme.colors.typography200};
  border-radius: 20px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.1);
`;

export const MainImageStyled = styled(Image)`
  display: block;
  margin-right: auto;
  margin-left: auto;
`;

export const SubtitleStyled = styled.h3`
  margin-top: 20px;
  margin-bottom: 28px;
  text-align: center;
`;

export const BlurredWrapperStyled = styled.span`
  filter: blur(6px);
`;

export const ReadingDetailsStyled = styled.ul`
  position: relative;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20%;
    background: linear-gradient(
      0deg,
      #fefefe 0.4%,
      rgba(254, 254, 254, 0.96) 27.78%,
      rgba(254, 254, 254, 0) 99.59%,
      #fefefe 99.6%
    );
    content: '';
    backdrop-filter: blur(2px);
  }
`;

export const ReadingDetailsItemStyled = styled.li`
  display: flex;
  margin-bottom: 8px;
`;

export const ItemTitleStyled = styled.h4`
  margin-bottom: 2px;
  font-weight: 600;
`;

export const ItemDescriptionStyled = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const ReadingDetailsIconStyled = styled(Image)`
  margin-top: 2px;
  margin-right: 8px;
`;

export const ReadingDetailsItemContentStyled = styled.div``;
