import styled from 'styled-components';
import SectionReadingsPackOffer from 'core/common/components/QuizScreensSections/SectionReadingsPackOffer';
import SectionReadingsPackSlider from 'core/common/components/QuizScreensSections/SectionReadingsPackSlider';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
`;

export const SectionReadingsPackSliderStyled = styled(SectionReadingsPackSlider)`
  margin-top: 16px;
`;

export const SectionReadingsPackOfferStyled = styled(SectionReadingsPackOffer)`
  margin-top: 28px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 68px;
  }
`;
