import aquariusFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/aquarius.png';
import ariesFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/aries.png';
import cancerFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/cancer.png';
import capricornFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/capricorn.png';
import geminiFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/gemini.png';
import leoFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/leo.png';
import libraFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/libra.png';
import piscesFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/pisces.png';
import sagittariusFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/sagittarius.png';
import scorpioFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/scorpio.png';
import taurusFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/taurus.png';
import virgoFemale from 'core/common/assets/images/trialPayment/zodiacPersons/females/virgo.png';
import aquariusMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/aquarius.png';
import ariesMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/aries.png';
import cancerMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/cancer.png';
import capricornMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/capricorn.png';
import geminiMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/gemini.png';
import leoMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/leo.png';
import libraMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/libra.png';
import piscesMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/pisces.png';
import sagittariusMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/sagittarius.png';
import scorpioMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/scorpio.png';
import taurusMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/taurus.png';
import virgoMale from 'core/common/assets/images/trialPayment/zodiacPersons/males/virgo.png';
import { Gender } from 'core/funnel/entities';
import { ZodiacTypes } from 'core/zodiac/entities';

const maleZodiacImages = {
  [ZodiacTypes.CAPRICORN]: capricornMale,
  [ZodiacTypes.AQUARIUS]: aquariusMale,
  [ZodiacTypes.PISCES]: piscesMale,
  [ZodiacTypes.ARIES]: ariesMale,
  [ZodiacTypes.TAURUS]: taurusMale,
  [ZodiacTypes.GEMINI]: geminiMale,
  [ZodiacTypes.CANCER]: cancerMale,
  [ZodiacTypes.LEO]: leoMale,
  [ZodiacTypes.VIRGO]: virgoMale,
  [ZodiacTypes.LIBRA]: libraMale,
  [ZodiacTypes.SCORPIO]: scorpioMale,
  [ZodiacTypes.SAGITTARIUS]: sagittariusMale,
};

const femaleZodiacImages = {
  [ZodiacTypes.CAPRICORN]: capricornFemale,
  [ZodiacTypes.AQUARIUS]: aquariusFemale,
  [ZodiacTypes.PISCES]: piscesFemale,
  [ZodiacTypes.ARIES]: ariesFemale,
  [ZodiacTypes.TAURUS]: taurusFemale,
  [ZodiacTypes.GEMINI]: geminiFemale,
  [ZodiacTypes.CANCER]: cancerFemale,
  [ZodiacTypes.LEO]: leoFemale,
  [ZodiacTypes.VIRGO]: virgoFemale,
  [ZodiacTypes.LIBRA]: libraFemale,
  [ZodiacTypes.SCORPIO]: scorpioFemale,
  [ZodiacTypes.SAGITTARIUS]: sagittariusFemale,
};

export const getZodiacPersonImage = (zodiacSign: ZodiacTypes, gender: Gender) => {
  if (!zodiacSign) return '';

  if (gender === 'male') {
    return maleZodiacImages[zodiacSign].src;
  }
  return femaleZodiacImages[zodiacSign].src;
};
