import heart from 'core/common/assets/images/heart-with-circle.svg';
import message from 'core/common/assets/images/message.svg';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { CreditsPalmistryScreenProps } from 'core/funnel/entities';
import { Background, Container, Content, ImageStyled, Text } from './styled';

const CreditsPalmistryScreen = ({ content }: CreditsPalmistryScreenProps) => {
  const { text } = content;

  return (
    <>
      <Background />
      <Container>
        <Image src={heart} alt="heart" width={195} height={184} />
        <Content>
          <ImageStyled src={message} alt="message" width={334} height={300} />
          <Text dangerouslySetInnerHTML={prepareHtml(text)} />
        </Content>
      </Container>
    </>
  );
};

export default CreditsPalmistryScreen;
