import styled, { keyframes } from 'styled-components';
import Image from '../Image';

const cardBgColor = '#3c1c7b';
const cardZIndex = 3;

const fadeInAnimation = keyframes`
  0% {
    transform: translateY(40%) scale(0.8);
    opacity: 0;
  } 100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  } 100% {
    transform: translateY(-80%) scale(0.8);
    opacity: 0;
  }
`;

export const ListStyled = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  min-height: 78px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
`;

export const ListItemStyled = styled.li<{
  isInitial: boolean;
  isActive: boolean;
  isHiding: boolean;
}>`
  position: absolute;
  top: 0;
  z-index: ${cardZIndex};
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;
  padding: 8px 10px;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${cardBgColor};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  animation-name: ${({ isInitial, isActive, isHiding }) =>
    isActive && !isInitial ? fadeInAnimation : isHiding ? fadeOutAnimation : ''};
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  transform: translateY(0) scale(1);
`;

export const ListItemOverlayStyled = styled.li`
  position: absolute;
  top: 12px;
  z-index: ${cardZIndex - 1};
  width: 95%;
  min-height: 54px;
  padding: 8px 10px;
  border-radius: 16px;
  background-color: ${cardBgColor};
  opacity: 0.6;

  &:last-child {
    top: 24px;
    z-index: ${cardZIndex - 2};
    width: 90%;
  }
`;

export const CardImageStyled = styled(Image)`
  border-radius: 8px;
  object-fit: cover;
`;

export const CardTitleStyled = styled.h4`
  font-size: 14px;
  line-height: 19px;
`;

export const CardTextStyled = styled.p`
  font-size: 14px;
  line-height: 19px;
`;

export const CardInfoWrapperStyled = styled.div`
  margin-left: 12px;
`;
