import { useCallback } from 'react';
import LoadingNatalChartScreen from 'core/common/components/QuizScreens/LoadingNatalChart';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ProcessingNatalChartScreenAttributes } from 'core/funnel/entities';

type LoadingNatalChartProps = {
  id: string;
};

const LoadingNatalChart = ({ id }: LoadingNatalChartProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  const onLoadingFinished = useCallback(async () => {
    await next({ force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingNatalChartScreen
      onLoadingFinished={onLoadingFinished}
      {...(screenAttributes as ProcessingNatalChartScreenAttributes)}
    />
  );
};

export default LoadingNatalChart;
