import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { createDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { DateNatalChartV2ScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerStyled,
  ContentStyled,
  ImageDesktopWrapperStyled,
  ImageMobileWrapperStyled,
  SelectContainerStyled,
  TitleStyled,
  TextStyled,
  SelectStyled,
} from './styled';

const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const yearsList = generateDates(1940, greatestBirthYear, 'decrement');

const DateNatalChartV2Screen = ({
  step,
  onBack,
  getInitialDate,
  onSubmit,
  content,
  totalSteps,
}: DateNatalChartV2ScreenProps) => {
  const { day, month, year } = getInitialDate();
  const { title, next, progressBarTitle, text } = content;

  const [date, setDate] = useState({
    month: month ? shortMonthsListEn[month] : '',
    day: day ? String(day) : '',
    year: year ? String(year) : '',
  });

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const daysList = generateMonthDays(date.month, date.year);

  const handleClick = () => {
    const dateOfBirth = createDate(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid Date') {
      const newDateOfBirth = createDate(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      return onSubmit(newDateOfBirth);
    }
    return onSubmit(dateOfBirth);
  };

  const isNextButtonDisabled = !date.day || !date.year || !date.month;

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <ContainerStyled>
        <ContentStyled>
          <TitleStyled>{title}</TitleStyled>
          {text && <TextStyled>{text}</TextStyled>}
          <SelectContainerStyled>
            <SelectStyled
              options={monthsList}
              name="month"
              value={date.month}
              onChange={handleDateChange}
              placeholder="Month"
            />
            <SelectStyled
              options={daysList}
              name="day"
              value={date.day}
              onChange={handleDateChange}
              placeholder="Day"
            />
            <SelectStyled
              options={yearsList}
              name="year"
              value={date.year}
              onChange={handleDateChange}
              placeholder="Year"
            />
          </SelectContainerStyled>
          <ButtonStyled onClick={handleClick} disabled={isNextButtonDisabled}>
            {next}
          </ButtonStyled>
        </ContentStyled>
      </ContainerStyled>
      {content.backgroundImage && (
        <>
          <ImageMobileWrapperStyled>
            <Image src={content.backgroundImage.mobileImage.src} alt="" width={196} height={180} />
          </ImageMobileWrapperStyled>
          <ImageDesktopWrapperStyled>
            <Image src={content.backgroundImage.desktopImage.src} alt="" width={382} height={352} />
          </ImageDesktopWrapperStyled>
        </>
      )}
    </>
  );
};

export default DateNatalChartV2Screen;
