import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TimelineScreenProps as TimelineProps } from 'core/funnel/entities';
import {
  HeaderStyled,
  ContentWrapperStyled,
  ImageStyled,
  TitleStyled,
  SubtitleStyled,
  ListStyled,
  ListItemStyled,
  StepTitleStyled,
  StepValueStyled,
  CheckmarkStyled,
  IconStyled,
  CaptionStyled,
  ButtonStyled,
  FooterStyled,
} from './styled';

const Timeline = ({ content, hasButtonBack, goNext, goBack }: TimelineProps) => {
  const { image, title, subtitle, steps, caption, buttonText } = content;

  return (
    <>
      <HeaderStyled buttonBack={hasButtonBack} onBack={goBack} />
      <ContentWrapperStyled>
        <ImageStyled src={image.src} alt={image.alt} width={113} height={113} />
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        <ListStyled>
          {steps.map((item, index) => (
            <ListItemStyled
              key={item.title}
              isChecked={item.checked}
              isLast={index === steps.length - 1}
            >
              <CheckmarkStyled>{item.checked && <IconStyled name="check" />}</CheckmarkStyled>
              <StepTitleStyled dangerouslySetInnerHTML={prepareHtml(item.title)} />
              <StepValueStyled
                dangerouslySetInnerHTML={prepareHtml(item.checked ? item.value : '')}
              />
            </ListItemStyled>
          ))}
        </ListStyled>
        <CaptionStyled dangerouslySetInnerHTML={prepareHtml(caption)} />
        <FooterStyled>
          <ButtonStyled fullWidth onClick={goNext}>
            {buttonText}
          </ButtonStyled>
        </FooterStyled>
      </ContentWrapperStyled>
    </>
  );
};

export default Timeline;
