import NameScreen from 'core/common/components/QuizScreens/Name';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { NameScreenAttributes } from 'core/funnel/entities';

type NameProps = {
  id: string;
};

const Name = ({ id }: NameProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as NameScreenAttributes;

  const handleSubmit = async (name: string) => {
    updateUserPreferences(dataKey, name);
    await next();
  };

  return (
    <NameScreen
      onBack={back}
      onSubmit={handleSubmit}
      initialName={userPreferences.name}
      step={step}
      {...(screenAttributes as NameScreenAttributes)}
    />
  );
};

export default Name;
