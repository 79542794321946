import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.section`
  position: relative;
  margin-right: -16px;
  margin-left: -16px;
  padding: 26px 16px;
  background-color: #fafafa;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: 0;
    margin-left: 0;
    padding: 32px 20px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 28px;
    font-size: 32px;
    line-height: 45px;
  }
`;

export const TextStyled = styled.p`
  margin-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 27px;
  }
`;

export const FooterTextStyled = styled.p`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const ImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100px;
    height: 100px;
  }
`;

export const ListStyled = styled.ul`
  margin-bottom: 12px;
  padding-left: 30px;
  list-style: disc;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 20px;
  }
`;

export const ListItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;
