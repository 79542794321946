import { useEffect, useState } from 'react';
import { SECOND } from 'core/common/constants';
import { FunnelImage } from 'core/funnel/entities';
import {
  ListStyled,
  ListItemStyled,
  ListItemOverlayStyled,
  CardImageStyled,
  CardTitleStyled,
  CardTextStyled,
  CardInfoWrapperStyled,
} from './styled';

type AnimatedCardsProps = {
  cards: Array<{
    image: FunnelImage;
    title: string;
    text: string;
  }>;
  skipAnimation?: boolean;
};

const AnimatedCards = ({ cards, skipAnimation = false }: AnimatedCardsProps) => {
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    if (skipAnimation) return;

    const intervalId = setInterval(() => {
      setActiveCard((prev) => {
        const nextActiveCardIdx = prev + 1;
        const lastCardIdx = cards.length - 1;

        if (nextActiveCardIdx > lastCardIdx) {
          clearInterval(intervalId);
          return lastCardIdx;
        }

        return nextActiveCardIdx;
      });
    }, SECOND * 2);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipAnimation]);

  return (
    <ListStyled>
      {cards.map((card, idx) => (
        <ListItemStyled
          key={card.title}
          isInitial={idx === 0}
          isActive={idx === activeCard}
          isHiding={activeCard - idx === 1}
        >
          <CardImageStyled src={card.image.src} alt={card.image.alt} width={34} height={34} />
          <CardInfoWrapperStyled>
            <CardTitleStyled>{card.title}</CardTitleStyled>
            <CardTextStyled>{card.text}</CardTextStyled>
          </CardInfoWrapperStyled>
        </ListItemStyled>
      ))}
      <ListItemOverlayStyled />
      <ListItemOverlayStyled />
    </ListStyled>
  );
};

export default AnimatedCards;
