import { useTranslation } from 'react-i18next';

import { addLeadingZero } from 'core/common/utils/addLeadingZero';

import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';

import {
  TimerBody,
  TimerContainer,
  TimerDevider,
  TimerHeader,
  TimerUnitCell,
  TimerUnitLabel,
  TimerUnitText,
} from './styled';

export type TimerProps = {
  timeLeft: number;
  minTitle: string;
  secTitle: string;
};

const Timer = ({ timeLeft, minTitle, secTitle }: TimerProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.salesScreen.fixedHeader';

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  return (
    <TimerContainer>
      <TimerHeader>{t('title')}</TimerHeader>
      <TimerBody>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(minutes)}</TimerUnitText>
          <TimerUnitLabel>{minTitle}</TimerUnitLabel>
        </TimerUnitCell>
        <TimerDevider>:</TimerDevider>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(seconds)}</TimerUnitText>
          <TimerUnitLabel>{secTitle}</TimerUnitLabel>
        </TimerUnitCell>
      </TimerBody>
    </TimerContainer>
  );
};

export default Timer;
