import { useCallback, useEffect } from 'react';
import TrialPaymentHumanDesignScreen from 'core/common/components/QuizScreens/TrialPaymentHumanDesign';
import { useCountdown, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentHumanDesignScreenAttributes } from 'core/funnel/entities';
import { useHumanDesign } from 'core/human-design/hooks';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';

type TrialPaymentHumanDesignProps = {
  id: string;
};

const TrialPaymentHumanDesign = ({ id }: TrialPaymentHumanDesignProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.HUMAN_DESIGN });

  const { report } = useHumanDesign();
  const { orderId } = useOrder();
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const { email } = userPreferences;

  const { updateOffer, defaultOffers, timeUpOffer } = useOffers();

  const isTimeUp = !+specialPlanCountdown;
  const offer = isTimeUp ? timeUpOffer : defaultOffers[0];

  const getReport = useCallback(() => {
    if (!offer) return;
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    if (!offer) return;
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  useEffect(() => {
    if (!report) {
      analyticsService.humanDesignReportMissed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <TrialPaymentHumanDesignScreen
      getReport={getReport}
      goNext={next}
      offer={offer}
      timeUpOffer={timeUpOffer}
      timeLeft={specialPlanCountdown}
      report={report}
      {...(screenAttributes as TrialPaymentHumanDesignScreenAttributes)}
    />
  );
};

export default TrialPaymentHumanDesign;
