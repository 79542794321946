import { useEffect } from 'react';
import OnboardingPalmistryScreen from 'core/common/components/QuizScreens/OnboardingPalmistry';
import { SECOND } from 'core/common/constants/';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { OnboardingPalmistryScreenAttributes } from 'core/funnel/entities';

type OnboardingPalmistryProps = {
  id: string;
};

const defaultAnimationDuration = 5 * SECOND;

const OnboardingPalmistry = ({ id }: OnboardingPalmistryProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  const { content, animationDuration } = screenAttributes as OnboardingPalmistryScreenAttributes;

  const timer = content.list.length * (animationDuration ?? defaultAnimationDuration);

  useEffect(() => {
    const redirectWithTimer = setTimeout(next, timer);

    return () => {
      clearTimeout(redirectWithTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next]);

  return (
    <OnboardingPalmistryScreen {...(screenAttributes as OnboardingPalmistryScreenAttributes)} />
  );
};

export default OnboardingPalmistry;
