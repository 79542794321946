import Timer from 'core/common/components/Timer';
import { HeaderDiscountStyled, HeaderDiscountValueStyled, HeaderStyled } from './styled';

type HeaderProps = {
  height: number;
  discountValue: string;
  discountExpiresIn: string;
  timeLeft: number;
};

const Header = ({ height, discountValue, discountExpiresIn, timeLeft }: HeaderProps) => {
  return (
    <HeaderStyled height={height}>
      <HeaderDiscountStyled>
        <HeaderDiscountValueStyled>{discountValue}</HeaderDiscountValueStyled> {discountExpiresIn}{' '}
        <Timer timeLeft={timeLeft} />
      </HeaderDiscountStyled>
    </HeaderStyled>
  );
};

export default Header;
