import styled from 'styled-components';

export const WrapperStyled = styled.div`
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 8px;
  background: #9974f626;
`;

export const TitleStyled = styled.p`
  font-size: 18px;
  text-align: center;
`;
