import { useTranslation } from 'react-i18next';
import { ButtonBackStyled, Control, Counter, MobileStepper, Title, Wrapper } from './styled';

type ProgressBarNatalChartProps = {
  currentStep?: number;
  totalSteps?: number;
  onBack: () => void;
};

const ProgressBarNatalChart = ({
  currentStep = 1,
  totalSteps = 17,
  onBack,
}: ProgressBarNatalChartProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'natal-chart',
  });

  const initialStep = 1;

  const activeStep = currentStep > totalSteps ? totalSteps : currentStep;

  const isInitialStep = initialStep === currentStep;

  return (
    <Wrapper>
      <Control>
        {!isInitialStep && <ButtonBackStyled onClick={onBack} />}
        <Title>{t('progressBar')}</Title>
        <Counter>
          {activeStep}/{totalSteps}
        </Counter>
      </Control>
      <MobileStepper
        variant="progress"
        steps={totalSteps + initialStep}
        activeStep={activeStep}
        position="static"
        backButton={null}
        nextButton={null}
      />
    </Wrapper>
  );
};

export default ProgressBarNatalChart;
