import Image from 'core/common/components/Image';
import { SectionLegacyBannerAttributes } from 'core/funnel/entities';
import { ContainerStyled, ContentStyled, TextStyled, TitleStyled } from './styled';

interface SectionHeaderProps {
  className?: string;
  content: SectionLegacyBannerAttributes['content'];
}

const SectionHeader = ({ content, className }: SectionHeaderProps) => (
  <ContainerStyled className={className}>
    <Image width={50} height={50} src={content.image.src} alt={content.image.alt} />
    <ContentStyled>
      <TitleStyled>{content.title}</TitleStyled>
      <TextStyled>{content.subTitle}</TextStyled>
    </ContentStyled>
  </ContainerStyled>
);

export default SectionHeader;
