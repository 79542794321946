import { useProgress } from 'core/common/hooks';
import { OnboardingAskV2ScreenProps } from 'core/funnel/entities';
import ChatTopics from './ChatTopics';
import Credits from './Credits';
import Intro from './Intro';
import Reading from './Reading';
import {
  BackgroundStyled,
  ContainerStyled,
  ContentStyled,
  ProgressStyled,
  ProgressTextStyled,
  WrapperStyled,
} from './styled';

const defaultTimeoutMs = 200;

const OnboardingAskV2Screen = ({ content, goNext }: OnboardingAskV2ScreenProps) => {
  const { reading, progressBarText, chatTopics, intro, credits } = content;

  const { progress } = useProgress({
    timeout: defaultTimeoutMs,
    callback: goNext,
  });

  return (
    <>
      <BackgroundStyled />
      <ContainerStyled>
        <ProgressTextStyled>{progressBarText}</ProgressTextStyled>
        <ProgressStyled step={progress} />
        <WrapperStyled>
          <ContentStyled value={reading.animationIndex}>
            <Reading {...reading} />
          </ContentStyled>
          <ContentStyled value={intro.animationIndex}>
            <Intro {...intro} />
          </ContentStyled>
          <ContentStyled value={chatTopics.animationIndex}>
            <ChatTopics {...chatTopics} />
          </ContentStyled>
          <ContentStyled value={credits.animationIndex}>
            <Credits {...credits} />
          </ContentStyled>
        </WrapperStyled>
      </ContainerStyled>
    </>
  );
};

export default OnboardingAskV2Screen;
