import { RadioButtonWrapper, Text } from './styled';

export type AnswerRadioButtonProps = {
  emoji?: string;
  className?: string;
  isChosen: boolean;
  text: string;
  onClick: () => void;
};

const AnswerRadioButton = ({
  className,
  emoji,
  isChosen,
  text,
  onClick,
}: AnswerRadioButtonProps) => {
  return (
    <RadioButtonWrapper
      isChosen={isChosen}
      withEmoji={!!emoji}
      className={className}
      onClick={onClick}
    >
      <Text>{text}</Text>
    </RadioButtonWrapper>
  );
};

export default AnswerRadioButton;
