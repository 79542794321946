import { SectionDiscountBannerAttributes } from 'core/funnel/entities';
import {
  BannerStyled,
  DiscountStyled,
  OldDiscountStyled,
  CaptionStyled,
  OverlayImageStyled,
} from './styled';

type SectionDiscountBannerProps = {
  content: SectionDiscountBannerAttributes['content'];
  className?: string;
};

const SectionDiscountBanner = ({ content, className }: SectionDiscountBannerProps) => {
  const { discount, oldDiscount, caption, image } = content;

  return (
    <BannerStyled className={className}>
      <DiscountStyled>
        {oldDiscount && <OldDiscountStyled>{oldDiscount}</OldDiscountStyled>}
        {discount}
      </DiscountStyled>
      <CaptionStyled>{caption}</CaptionStyled>
      {image && <OverlayImageStyled src={image.src} alt={image.alt} fill />}
    </BannerStyled>
  );
};

export default SectionDiscountBanner;
