import { ReactNode } from 'react';
import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import NavLink from 'core/common/components/Link';
import PriceResolvedTimer from 'core/common/components/PriceResolvedTimer';
import TitleComponent from 'core/common/components/Title';
import { salesScreenHeaderHeight } from './SalesScreenHeader/styled';

const horizontalPadding = 15;

export const MainContainerStyled = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const MainContainerWideStyled = styled(MainContainerStyled)`
  align-self: center;
  width: calc(100% + ${horizontalPadding * 2}px);
  max-width: 800px;
  margin-right: -${horizontalPadding}px;
  margin-left: -${horizontalPadding}px;
`;

export const SpecialOffer = styled.div`
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.colors.success500};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.success500};
`;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 145%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 40px;
    font-size: 32px;
  }
`;

export const TextStyled = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

export const Subtitle = styled(TextStyled)`
  margin-bottom: 30px;
`;

export const Total = styled.div`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
`;

export const TotalText = styled(TextStyled)`
  font-weight: 700;
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
`;

export const TimerBlock = styled.div`
  position: relative;
  width: 167px;
  height: 24px;
  background: linear-gradient(298.45deg, #54b1dd 1.02%, #9974f6 84.57%);
`;

export const Timer = styled(PriceResolvedTimer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: ${(props) => props.theme.colors.typography100};
`;

export const TimerText = styled.span`
  width: max-content;
  margin-right: -10px;
`;

export const SpecialCode = styled.p`
  align-self: flex-start;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.success500};
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const OldPrice = styled.span`
  font-size: 18px;
  font-weight: normal;
  text-decoration: line-through;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Price = styled.span`
  margin-left: 20px;
  font-size: 18px;
`;

export const Description = styled.p`
  margin-top: 20px;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledDescription = styled(Description)`
  margin-top: 0;
  margin-bottom: 20px;
`;

export const Button = styled(ButtonComponent)`
  height: 49px;
  border-radius: 30px;
  font-size: 18px;
  background: #69b13f;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const Link = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;
export const Underline = styled.span`
  font-weight: 600;
`;

export const Address = styled(AddressComponent)`
  margin-top: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
  }
`;

export const AddressUS = styled.p`
  max-width: 330px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const ColoredBackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 15px;
  background-color: #eaeef7;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 20px 30px;
  }
`;

export const FooterTextStyled = styled.p`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const HeaderSpaceStyled = styled.div`
  height: ${salesScreenHeaderHeight}px;
`;

export const PageSectionContainerStyled = styled.section<{
  children?: ReactNode;
  noGutters?: boolean;
  maxWidth?: number;
  offset?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'unset')};
  margin-bottom: ${(props) => (props.offset ? `${props.offset}` : '40')}px;
  padding: 0 ${(props) => (props.noGutters ? '0' : '15')}px;
  box-sizing: border-box;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: ${(props) => (props.offset ? `${props.offset}` : '80')}px;
    padding: 0;
  }
`;
