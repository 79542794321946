import DatePickerScreen from 'core/common/components/QuizScreens/DatePicker';
import { useServices } from 'core/common/hooks';
import { calculateAges } from 'core/common/utils/calculateAges';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DatePickerScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type DatePickerPageProps = {
  id: string;
};

const DatePickerPage = ({ id }: DatePickerPageProps) => {
  const { back, screenAttributes, next, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);
  const { analyticsService } = useServices();

  const { dataKey } = screenAttributes as DatePickerScreenAttributes;
  const userDate = userPreferences[dataKey as keyof UserProfile] as string;

  const onSubmit = async (date: string) => {
    const userAge = calculateAges(date);
    analyticsService.setUserAge(userAge);
    updateUserPreferences(dataKey, date);
    next();
  };

  return (
    <DatePickerScreen
      userDate={userDate}
      onBack={back}
      onSubmit={onSubmit}
      step={step}
      {...(screenAttributes as DatePickerScreenAttributes)}
    />
  );
};

export default DatePickerPage;
