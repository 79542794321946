import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import { TrialPaymentMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import {
  ContentStyled,
  TitleStyled,
  WrapperStyled,
  ChallengesListStyled,
  ChallengesListItemStyled,
  ChallangeTitleStyled,
  ProgressStyled,
  ActiveProgressStyled,
  ProgressLabelStyled,
  ProgressLablesContainerStyled,
  ProgressQuestionMarkStyled,
  ButtonStyled,
} from './styled';

type ChallengesProps = {
  content: TrialPaymentMoonCompatibilityScreenAttributes['content']['challenges'];
  onMoveToOffer: () => void;
};

const Challenges = ({ content, onMoveToOffer }: ChallengesProps) => {
  const { title, list, progressBarLabels, button } = content;

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <ContentStyled>
        <ChallengesListStyled>
          {list.map((item, idx) => {
            const isFirstItem = idx === 0;

            return (
              <ChallengesListItemStyled key={item}>
                <ChallangeTitleStyled variant={isFirstItem ? 'active' : 'disabled'}>
                  {item}
                </ChallangeTitleStyled>
                <ProgressLablesContainerStyled>
                  <ProgressLabelStyled>{progressBarLabels.start}</ProgressLabelStyled>
                  {!isFirstItem && <ProgressQuestionMarkStyled>?</ProgressQuestionMarkStyled>}
                  <ProgressLabelStyled>{progressBarLabels.end}</ProgressLabelStyled>
                </ProgressLablesContainerStyled>
                <ProgressStyled>
                  {isFirstItem && (
                    <ActiveProgressStyled progress={getRandomNumberWithinRange(92, 100)} />
                  )}
                </ProgressStyled>
              </ChallengesListItemStyled>
            );
          })}
        </ChallengesListStyled>
      </ContentStyled>
      <ButtonStyled impulseScale onClick={onMoveToOffer}>
        {button}
      </ButtonStyled>
    </WrapperStyled>
  );
};

export default Challenges;
