import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 12px;
`;

export const TitleStyled = styled.h2`
  margin-top: 32px;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 27px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 48px;
    margin-bottom: 8px;
    font-size: 26px;
    line-height: 35px;
  }
`;

export const DescriptionStyled = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const AnswersListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-right: -12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 32px;
    margin-right: -16px;
  }
`;

export const AnswersListItemStyled = styled.li`
  width: calc(50% - 12px);
  margin-right: 12px;
  margin-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 16px;
  }
`;

export const AspectRatioStyled = styled.div`
  position: relative;
  padding-bottom: 57.5%;
`;

export const AnswerImageStyled = styled(Image)`
  border-radius: 12px;
  object-fit: cover;
`;

export const AnswerTextStyled = styled.span`
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const AnswerButtonStyled = styled.button`
  display: block;
  width: 100%;
  text-align: left;
`;
