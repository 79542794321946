import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import Image from 'core/common/components/Image';
import TitleComponent from 'core/common/components/Title';
import Icon from 'core/svg-sprites/ui/Icon';

export const Content = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const Title = styled(TitleComponent)`
  max-width: 330px;
  min-height: 56px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
`;

export const ProgressProfile = styled(LinearProgress)`
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.primary500};
  }

  /* TODO Refactor this selector */

  && {
    height: 8px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #e6e6e6;
  }
`;

export const ProgressPersonality = styled(ProgressProfile)`
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.chart500};
  }

  /* TODO Refactor this selector */

  && {
    background-color: #e6e6e6;
  }
`;

export const ProgressRelationship = styled(ProgressProfile)`
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.tertiary500};
  }

  /* TODO Refactor this selector */

  && {
    background-color: #e6e6e6;
  }
`;

export const ProgressWrapper = styled.div`
  width: 330px;
`;

export const ProfileBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.primary500};
`;

export const ProfileText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
`;

export const PersonalityBlock = styled(ProfileBlock)`
  color: #56ccf2;
`;

export const RelationshipBlock = styled(ProfileBlock)`
  color: ${(props) => props.theme.colors.tertiary500};
`;

export const WrapperDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
`;

export const Dot = styled.p`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ecc8bc;
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.primary500};
  width: 23px;
  height: 23px;
`;

export const PersonalityDone = styled(IconStyled)`
  fill: #56ccf2;
`;

export const RelationshipDone = styled(IconStyled)`
  fill: ${(props) => props.theme.colors.tertiary500};
`;

export const Text = styled.p`
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
`;

export const Logo = styled(Image)`
  align-self: center;
  margin-bottom: 10px;
`;

export const Background = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    99.42deg,
    rgba(255, 161, 0, 0.3) 36.23%,
    rgba(250, 124, 18, 0.2401) 54.33%,
    rgba(245, 87, 36, 0.27) 75.61%,
    rgba(242, 61, 48, 0.27) 95.48%,
    rgba(240, 45, 56, 0.27) 113.18%,
    rgba(239, 39, 59, 0.27) 126.89%
  );
`;
