import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-right: -15px;
  margin-left: -15px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.typography100};
  background: linear-gradient(
    180deg,
    #1d1e55 0%,
    #272463 25%,
    #3b2f7b 50%,
    #4f358d 75%,
    #6939a2 100%
  );

  &::before {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 80px;
    background: linear-gradient(
      180deg,
      #1d1e55 0%,
      #272463 25%,
      #3b2f7b 50%,
      #4f358d 75%,
      #6939a2 100%
    );
    content: '';
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-right: -50px;
    margin-left: -50px;
  }
`;

export const DiscountWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiscountTextStyled = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  white-space: nowrap;
  color: #f3b913;
`;

export const GetPlanButtonStyled = styled(Button)`
  width: 240px;
  margin-right: initial;
  margin-left: 24px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typography900};
  background: ${({ theme }) => theme.colors.typography100};
`;
