import { useEffect } from 'react';
import { useServices } from 'core/common/hooks';
import { FunnelImage } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import {
  AspectRatioStyled,
  CloseButtonStyled,
  ContentStyled,
  ButtonStyled,
  MainImageStyled,
  ModalStyled,
  TextStyled,
  TitleStyled,
  TopImageStyled,
  CaptionStyled,
} from './styled';

type CameraPermissionsAlertProps = {
  isOpen: boolean;
  title: string;
  subtitle: string;
  caption: string;
  buttonText: string;
  topImage: FunnelImage;
  mainImage: FunnelImage;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

const CameraPermissionsAlert = ({
  isOpen,
  title,
  subtitle,
  caption,
  buttonText,
  mainImage,
  topImage,
  onClose,
  onConfirm,
}: CameraPermissionsAlertProps) => {
  const { isMobile } = useIsMobile();
  const { analyticsService } = useServices();

  useEffect(() => {
    if (isOpen) {
      analyticsService.cameraErrorPopUpOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleConfirm = () => {
    onConfirm();
    analyticsService.cameraErrorPopUpClicked();
  };

  return (
    <ModalStyled open={isOpen}>
      <ContentStyled>
        <CloseButtonStyled onClick={onClose} />
        <TopImageStyled src={topImage.src} alt={topImage.alt} width={95} height={75} />
        <TitleStyled>{title}</TitleStyled>
        <TextStyled>{subtitle}</TextStyled>
        <AspectRatioStyled width={280} height={143}>
          <MainImageStyled src={mainImage.src} alt={mainImage.alt} fill />
        </AspectRatioStyled>
        <CaptionStyled>{caption}</CaptionStyled>
        <ButtonStyled
          variant={isMobile ? 'primary' : 'secondary'}
          onClick={handleConfirm}
          fullWidth
        >
          {buttonText}
        </ButtonStyled>
      </ContentStyled>
    </ModalStyled>
  );
};

export default CameraPermissionsAlert;
