import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import NavLink from 'core/common/components/Link/Link';

export const PurchaseBlockStyled = styled.div`
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 32px;
  }
`;

export const TotalBlockStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
  }
`;

export const TotalTextStyled = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const SpecialOfferBlockStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  line-height: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
    font-size: 24px;
  }
`;

export const SpecialOfferPriceBlockTextStyled = styled.span`
  font-size: 14px;
  font-weight: 400;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const SpecialOfferPriceWrapperStyled = styled.span``;

export const SpecialOfferPriceBlockStyled = styled.span`
  margin-left: 4px;
  font-size: 16px;
  font-weight: 700;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 8px;
    font-size: 18px;
  }
`;

export const SpecialOfferOldPriceStyled = styled.span`
  font-size: 14px;
  text-decoration: line-through;
  color: ${(props) => props.theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ButtonWrapperStyled = styled.div`
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
  }
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;
  height: 56px;
  font-size: 18px;
  font-weight: 600;
  background: ${(props) => props.theme.colors.infoLight};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;

export const BoldStyled = styled.b``;

export const LinkStyled = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;

export const DescriptionStyled = styled.p`
  font-size: 12px;
  line-height: 166%;
  color: ${({ theme }) => theme.colors.typography500};

  ${BoldStyled} {
    font-weight: 600;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
    line-height: 145%;
  }
`;
