import styled from 'styled-components';

export const DisclaimerText = styled.p`
  margin-top: 16px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.typography700};

  span {
    font-weight: 600;
  }
`;
