import { QuizStepBackground } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import Image from '../Image';
import { BackgroundVariant } from './types';
import { AbsoluteBackgroundContainer } from './styled';

export type QuizBackgroundProps = {
  backgroundImage?: QuizStepBackground;
  variant?: BackgroundVariant;
};

export const QuizBackground = ({ backgroundImage, variant = 'light' }: QuizBackgroundProps) => {
  const { isMobile } = useIsMobile();

  if (!backgroundImage) {
    return <AbsoluteBackgroundContainer variant={variant} />;
  }

  return (
    <AbsoluteBackgroundContainer variant={variant}>
      <Image
        layout="fill"
        objectFit="cover"
        objectPosition="top"
        quality={90}
        src={isMobile ? backgroundImage.mobileImage.src : backgroundImage.desktopImage.src}
        alt={isMobile ? backgroundImage.mobileImage.alt : backgroundImage.desktopImage.alt}
        priority
      />
    </AbsoluteBackgroundContainer>
  );
};

export default QuizBackground;
