import styled from 'styled-components';

export const Bold = styled.b`
  font-weight: 700;
`;

export const Break = styled.br``;

export const Description = styled.p`
  font-size: 12px;
  text-align: center;
`;
