import { ChangeEvent, useMemo, useState } from 'react';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { extractDateParts, normalizeDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { ButtonStyled, SelectStyled, SelectContainerStyled } from './styled';

const yearsList = generateDates(1940, greatestBirthYear, 'decrement');

type SelectDatePickerProps = {
  labels: {
    next: string;
    day: string;
    month: string;
    year: string;
  };
  userDate?: string;
  onSubmit: (date: string) => void;
};

export const SelectDatePicker = ({ labels, onSubmit, userDate }: SelectDatePickerProps) => {
  const initialDate = useMemo(() => extractDateParts(userDate), [userDate]);

  const [date, setDate] = useState({ ...initialDate });

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const daysList = useMemo(() => {
    return generateMonthDays(date.month, date.year).map(Number).map(addLeadingZero);
  }, [date.month, date.year]);

  const onClick = () => {
    onSubmit(normalizeDate(date, 'MMM D YYYY'));
  };

  const isNextButtonDisabled = !date.day || !date.year || !date.month;

  return (
    <>
      <SelectContainerStyled>
        <SelectStyled
          options={shortMonthsListEn}
          name="month"
          value={date.month}
          onChange={handleDateChange}
          placeholder={labels.month}
        />
        <SelectStyled
          options={daysList}
          name="day"
          value={date.day}
          onChange={handleDateChange}
          placeholder={labels.day}
        />
        <SelectStyled
          options={yearsList}
          name="year"
          value={date.year}
          onChange={handleDateChange}
          placeholder={labels.year}
        />
      </SelectContainerStyled>
      <ButtonStyled onClick={onClick} disabled={isNextButtonDisabled}>
        {labels.next}
      </ButtonStyled>
    </>
  );
};
