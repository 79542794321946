import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionRelationshipPlanAttributes } from 'core/funnel/entities';
import PlanSubject from './PlanSubject';
import { WrapperStyled, TitleStyled, SubtitleStyled, ContentWrapperStyled } from './styled';

type SectionRelationshipPlanProps = {
  content: SectionRelationshipPlanAttributes['content'];
  className?: string;
};

const SectionRelationshipPlan = ({ content, className }: SectionRelationshipPlanProps) => {
  const { title, subtitle, withoutPlan, withPlan } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(subtitle)} />
      <ContentWrapperStyled>
        <PlanSubject {...withoutPlan} />
        <PlanSubject {...withPlan} variant="gradient" />
      </ContentWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionRelationshipPlan;
