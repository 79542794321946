import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';

export const Address = styled(AddressComponent)`
  margin-top: auto;
`;

export const ContentStyled = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const BoldStyled = styled.b`
  font-weight: 700;
`;

export const DescriptionStyled = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const BreakStyled = styled.br``;
