import styled from 'styled-components';
import AnimatedImage from 'core/common/components/AnimatedImages/AnimatedImage';
import BackgroundGradient from 'core/common/components/BackgroundGradient';
import { InfoButton } from 'core/common/components/Quiz/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  max-width: 330px;
  margin-bottom: 50px;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};
`;

export const Tip = styled(Text)`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 14px;
`;

export const StyledTitle = styled.h2`
  max-width: 328px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  line-height: 21px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};
`;

export const ButtonsWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const NextButton = styled(InfoButton)`
  flex-grow: 1;
  height: 49px;
  margin: 0 0 0 10px;
  color: ${(props) => props.theme.colors.info};
  background: ${(props) => props.theme.colors.typography100};
`;

export const PrevButton = styled(InfoButton)`
  height: 49px;
  margin: 0;
  border: ${(props) => `1px solid ${props.theme.colors.dark}`};
  color: ${(props) => props.theme.colors.dark};
  background: transparent;
`;

export const Background = styled(BackgroundGradient)`
  background: ${(props) => props.theme.colors.lunarViolet};
`;

export const StyledAnimatedImage = styled(AnimatedImage)`
  width: 250px;
  height: 250px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
