import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useServices } from 'core/common/hooks';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { getEmail } from 'core/user/store';

export function useTrialPaymentWithDiscountIntroOffer(goNext: () => void) {
  const { analyticsService } = useServices();

  const { discountOffer, updateOffer } = useOffers();

  const email = useSelector(getEmail);
  const { orderId } = useOrder();

  useEffect(() => {
    if (!discountOffer) return;
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, discountOffer]);

  const getReport = useCallback(() => {
    if (!discountOffer) return;
    analyticsService.initiateCheckout({
      price: discountOffer.prices.priceWithVAT,
      productId: discountOffer.offerId,
      email,
      orderId,
      currency: discountOffer.currency,
    });
    analyticsService.landingPageClick('trial_billing', discountOffer.offerId);
    updateOffer(discountOffer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, discountOffer]);

  const paymentSucceed = useCallback(() => {
    goNext();
  }, [goNext]);

  return {
    getReport,
    paymentSucceed,
    discountOffer,
  };
}
