import styled, { keyframes } from 'styled-components';
import HintProcessingLoader from 'core/common/components/ProcessingLoader/ProcessingLoader';
import Icon from 'core/svg-sprites/ui/Icon';

export const Background = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 320px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: white;
`;

export const Title = styled.h2`
  margin-bottom: 37px;
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;

  span {
    background: ${(props) => props.theme.gradients.secondaryLight};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;
  max-width: 226px;
  margin-right: auto;
  margin-left: auto;
`;

export const LoaderBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: linear-gradient(
    125.02deg,
    rgba(100, 43, 115, 0.5) 18.39%,
    rgba(198, 66, 110, 0.5) 81.83%
  );
  filter: blur(40px);
`;

export const Loader = styled(HintProcessingLoader)``;

export const List = styled.ul`
  z-index: 5;
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.typography100};
`;

const fadeInUpList = keyframes`
  to {
    opacity: 1;
    color: #e8e8fc;
    background: rgb(142, 140, 240, .3);
  }
`;

export const ListItem = styled.li<{ value: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.primary700};
  background: rgba(85, 84, 144, 0.3);
  animation-name: ${fadeInUpList};
  animation-duration: 1.5s;
  animation-delay: ${(props) => props.value * 4}s;
  animation-fill-mode: both;
`;

const fadeInUpCircle = keyframes`
  to {
    background: rgba(165, 163, 243, 0.3);
  }
`;

export const Circle = styled.div<{ value: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 6px;
  border-radius: 50%;
  background: rgba(85, 84, 144, 0.3);
  animation-name: ${fadeInUpCircle};
  animation-duration: 1.5s;
  animation-delay: ${(props) => props.value * 4}s;
  animation-fill-mode: both;
`;

export const Text = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  text-align: start;
`;

const fadeInUp = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
`;

export const IconStyled = styled(Icon)<{ value: number }>`
  width: 28px;
  height: 28px;
  animation-name: ${fadeInUp};
  animation-duration: 1.5s;
  animation-delay: ${(props) => props.value * 4}s;
  animation-fill-mode: both;
  fill: ${({ theme }) => theme.colors.typography100};
`;
