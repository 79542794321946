import { useFormik } from 'formik';
import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { isEmailValid } from 'core/common/utils/string';
import { EmailSecuredScreenProps } from 'core/funnel/entities';
import Image from '../../Image';
import {
  Button,
  ContainerMain,
  Content,
  Form,
  Input,
  Policy,
  StyledEmailSuggestionsList,
  Title,
  InputCaption,
  SubtitleWrapper,
  Subtitle,
} from './styled';

const EmailSecuredScreen = ({ content, initialValue, onSubmit }: EmailSecuredScreenProps) => {
  const { getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue } =
    useFormik({
      initialValues: {
        email: initialValue,
      },
      validate: ({ email }) => {
        if (!email || !isEmailValid(email)) {
          return { email: content.error };
        }

        return {};
      },
      onSubmit: async (submitValues, helpers) => {
        await onSubmit(submitValues.email, helpers);
      },
    });

  const error = touched.email ? errors.email : '';

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ContainerMain>
        <Title>{content.title}</Title>
        <Form onSubmit={handleSubmit}>
          <Content>
            <SubtitleWrapper>
              <Image
                src={content.subtitle.icon.src}
                width={16}
                height={16}
                alt={content.subtitle.icon.alt}
              />
              <Subtitle>{content.subtitle.text}</Subtitle>
            </SubtitleWrapper>
            <Input
              {...getFieldProps('email')}
              error={!!error}
              errorText={error}
              placeholder={content.placeholder}
            />
            <InputCaption>{content.inputCaption}</InputCaption>
            <StyledEmailSuggestionsList
              input={values.email}
              onSelect={(value) => setFieldValue('email', value)}
            />
            <Policy />
            <Button type="submit" disabled={isSubmitting}>
              {content.button}
            </Button>
          </Content>
        </Form>
      </ContainerMain>
    </>
  );
};

export default EmailSecuredScreen;
