import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionDiscountModalAttributes } from 'core/funnel/entities';
import { IconNames } from 'core/svg-sprites/entities/sprite';
import Icon from 'core/svg-sprites/ui/Icon';
import {
  BenefitsColumnStyled,
  BenefitsLargeColumnStyled,
  BenefitsListItemStyled,
  BenefitsListStyled,
  BenefitsStyled,
  ButtonStyled,
  CloseButtonStyled,
  ColumnTitleStyled,
  FooterCaptionStyled,
  FooterStyled,
  IconsListItemStyled,
  IconsListStyled,
  ModalStyled,
  TitleStyled,
} from './styled';

type SectionDiscountModalProps = {
  content: SectionDiscountModalAttributes['content'];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const SectionDiscountModal = ({
  isOpen,
  content,
  onClose,
  onConfirm,
}: SectionDiscountModalProps) => {
  const {
    title,
    benefitList,
    withNebulaTitle,
    withNebulaIcons,
    withoutNebulaTitle,
    withoutNebulaIcons,
    footer,
    ctaButtonText,
  } = content;

  return (
    <ModalStyled open={isOpen} onClose={onClose}>
      <>
        <CloseButtonStyled onClick={onClose} />
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <BenefitsStyled>
          <BenefitsLargeColumnStyled>
            <BenefitsListStyled>
              {benefitList.map((benefit) => (
                <BenefitsListItemStyled
                  key={benefit}
                  dangerouslySetInnerHTML={prepareHtml(benefit)}
                />
              ))}
            </BenefitsListStyled>
          </BenefitsLargeColumnStyled>
          <BenefitsColumnStyled variant="gradient">
            <ColumnTitleStyled dangerouslySetInnerHTML={prepareHtml(withNebulaTitle)} />
            <IconsListStyled>
              {withNebulaIcons.map((icon) => (
                <IconsListItemStyled key={icon} variant="benefit">
                  <Icon name={icon as IconNames} width={18} height={18} />
                </IconsListItemStyled>
              ))}
            </IconsListStyled>
          </BenefitsColumnStyled>
          <BenefitsColumnStyled>
            <ColumnTitleStyled dangerouslySetInnerHTML={prepareHtml(withoutNebulaTitle)} />
            <IconsListStyled>
              {withoutNebulaIcons.map((icon) => (
                <IconsListItemStyled key={icon} variant="drawback">
                  <Icon name={icon as IconNames} width={14} height={14} />
                </IconsListItemStyled>
              ))}
            </IconsListStyled>
          </BenefitsColumnStyled>
        </BenefitsStyled>
        <FooterStyled>
          <FooterCaptionStyled dangerouslySetInnerHTML={prepareHtml(footer)} />
          <ButtonStyled fullWidth onClick={onConfirm}>
            {ctaButtonText}
          </ButtonStyled>
        </FooterStyled>
      </>
    </ModalStyled>
  );
};

export default SectionDiscountModal;
