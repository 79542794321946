import GenderMoonCompatibilityScreen from 'core/common/components/QuizScreens/GenderMoonCompatibility';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import {
  Gender as GenderTypes,
  GenderMoonCompatibilityScreenAttributes,
} from 'core/funnel/entities';

export type GenderMoonCompatibilityProps = {
  id: string;
};

const GenderMoonCompatibility = ({ id }: GenderMoonCompatibilityProps) => {
  const { back, next, screenAttributes, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as GenderMoonCompatibilityScreenAttributes;

  const onGenderClick = (value: GenderTypes) => {
    updateUserPreferences(dataKey, value);
    next({ value });
  };

  return (
    <GenderMoonCompatibilityScreen
      onBack={back}
      onGenderClick={onGenderClick}
      step={step}
      {...(screenAttributes as GenderMoonCompatibilityScreenAttributes)}
    />
  );
};

export default GenderMoonCompatibility;
