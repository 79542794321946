import { TouchEvent } from 'react';
import { SECOND } from 'core/common/constants';
import { StoriesScreenProps as StoriesProps, StoryVariant } from 'core/funnel/entities';
import Icon from 'core/svg-sprites/ui/Icon';
import Basic from './Basic';
import Intro from './Intro';
import Media from './Media';
import { useStories } from './useStories';
import {
  ContainerStyled,
  DurationProgressStyled,
  HeaderStyled,
  CloseButtonStyled,
  LayoutStyled,
  NavigationPrevButtonStyled,
  NavigationNextButtonStyled,
  ContentStyled,
  BackgroundStyled,
} from './styled';

const Stories = ({ content, goNext }: StoriesProps) => {
  const { stories, storyDurationSec } = content;

  const {
    activeStoryIndex,
    storiesContainerRef,
    screenPressListeners,
    getStoryProgress,
    goToNextStory,
    goToPreviousStory,
  } = useStories({ stories, storyDurationMs: storyDurationSec * SECOND, onLastStoryEnd: goNext });

  const preventTouchPropagation = (e: TouchEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const renderStory = () => {
    const activeStory = stories[activeStoryIndex];

    switch (activeStory.variant) {
      case StoryVariant.BASIC:
        return <Basic {...activeStory} />;
      case StoryVariant.MEDIA:
        return <Media {...activeStory} />;
      default:
        return <Intro {...activeStory} />;
    }
  };

  return (
    <LayoutStyled>
      <BackgroundStyled />
      <ContainerStyled {...screenPressListeners} ref={storiesContainerRef}>
        <HeaderStyled>
          {stories.map((item, index) => (
            <DurationProgressStyled
              key={item.title + index}
              value={getStoryProgress(index)}
              colorVariant={stories[activeStoryIndex].colorVariant}
            />
          ))}
          <CloseButtonStyled
            onClick={goNext}
            onTouchEnd={preventTouchPropagation}
            colorVariant={stories[activeStoryIndex].colorVariant}
          />
        </HeaderStyled>
        <ContentStyled>{renderStory()}</ContentStyled>
        <NavigationPrevButtonStyled onClick={goToPreviousStory}>
          <Icon name="arrow-simple" width={44} height={44} />
        </NavigationPrevButtonStyled>
        <NavigationNextButtonStyled onClick={goToNextStory}>
          <Icon name="arrow-simple" width={44} height={44} />
        </NavigationNextButtonStyled>
      </ContainerStyled>
    </LayoutStyled>
  );
};

export default Stories;
