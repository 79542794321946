import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';

export const Address = styled(AddressComponent)`
  margin-top: auto;
`;

export const Content = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 12px;
  text-align: center;
`;
