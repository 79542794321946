import { createDate } from 'core/common/utils/date';
import { RelationshipTypes, TrialPaymentIntroOfferScreenAttributes } from 'core/funnel/entities';
import { Languages } from 'core/localization/entities';
import { useLanguage } from 'core/localization/hooks';
import ZodiacImagesBlock from './ZodiacImagesBlock';
import { DetailsListContainer, ItemText, ItemTitle, List, ListItem, SingleList } from './styled';

export type SectionUserDetailsProps = {
  relationshipType: RelationshipTypes;
  userDetails: TrialPaymentIntroOfferScreenAttributes['content']['userDetails'];
  zodiacImage: string;
  partnerZodiacImage: string;
};

const SectionUserDetails = ({
  userDetails,
  zodiacImage,
  partnerZodiacImage,
  relationshipType,
}: SectionUserDetailsProps) => {
  const {
    zodiacTitle,
    genderTitle,
    birthDateTitle,
    birthPlaceTitle,
    zodiacSign,
    gender,
    birthDate,
    birthPlace,
    partnerZodiacSign,
    partnerGender,
    partnerBirthDate,
    partnerBirthPlace,
  } = userDetails;

  const { language } = useLanguage();

  const dateTemplate = language === Languages.EN ? 'MMM D YYYY' : 'D MMMM YYYY';

  const getFormattedCity = (targetLocation: string) => {
    return targetLocation.split(',')[0];
  };

  const getFormattedBirthDate = (date: string) => {
    return createDate(date).locale(language).format(dateTemplate);
  };

  if (relationshipType === RelationshipTypes.SINGLE) {
    return (
      <>
        <ZodiacImagesBlock
          relationshipType={relationshipType}
          zodiacImage={zodiacImage}
          partnerZodiacImage={partnerZodiacImage}
          userDetails={userDetails}
        />
        <DetailsListContainer>
          <SingleList>
            <ListItem>
              <ItemTitle>{zodiacTitle}</ItemTitle>
              <ItemText>{zodiacSign}</ItemText>
            </ListItem>
            <ListItem>
              <ItemTitle>{genderTitle}</ItemTitle>
              <ItemText>{gender}</ItemText>
            </ListItem>
          </SingleList>
          <SingleList>
            <ListItem>
              <ItemTitle>{birthDateTitle}</ItemTitle>
              <ItemText>{getFormattedBirthDate(birthDate)}</ItemText>
            </ListItem>
            <ListItem>
              <ItemTitle>{birthPlaceTitle}</ItemTitle>
              <ItemText>{getFormattedCity(birthPlace)}</ItemText>
            </ListItem>
          </SingleList>
        </DetailsListContainer>
      </>
    );
  }

  return (
    <>
      <ZodiacImagesBlock
        relationshipType={relationshipType}
        zodiacImage={zodiacImage}
        partnerZodiacImage={partnerZodiacImage}
        userDetails={userDetails}
      />
      <DetailsListContainer>
        <List>
          <ListItem>
            <ItemTitle>{zodiacTitle}</ItemTitle>
            <ItemText>{zodiacSign}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{genderTitle}</ItemTitle>
            <ItemText>{gender}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{birthDateTitle}</ItemTitle>
            <ItemText>{getFormattedBirthDate(birthDate)}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{birthPlaceTitle}</ItemTitle>
            <ItemText>{getFormattedCity(birthPlace)}</ItemText>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ItemTitle>{zodiacTitle}</ItemTitle>
            <ItemText>{partnerZodiacSign}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{genderTitle}</ItemTitle>
            <ItemText>{partnerGender}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{birthDateTitle}</ItemTitle>
            <ItemText>{getFormattedBirthDate(partnerBirthDate ?? '')}</ItemText>
          </ListItem>
          <ListItem>
            <ItemTitle>{birthPlaceTitle}</ItemTitle>
            <ItemText>{getFormattedCity(partnerBirthPlace ?? '')}</ItemText>
          </ListItem>
        </List>
      </DetailsListContainer>
    </>
  );
};

export default SectionUserDetails;
