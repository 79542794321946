import styled from 'styled-components';

export const ContainerStyled = styled.div`
  margin-top: 24px;
  margin-bottom: 110px;
`;

export const ReportDescriptionStyled = styled.p`
  max-width: 330px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 520px;
    margin-bottom: 50px;
  }
`;

export const AddressStyled = styled.p`
  max-width: 330px;
  margin: 20px auto 8px;
  font-size: 11px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;
