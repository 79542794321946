import styled from 'styled-components';
import Emoji from '../Emoji/DeprecatedEmoji';

export const TitleStyled = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const SubtitleStyled = styled.h2`
  max-width: 460px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 16px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const BannerStyled = styled.div`
  display: flex;
  align-items: center;
  max-width: 708px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  padding: 6px 20px;
  border-radius: 10px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.typography100};
  background: ${(props) => props.theme.colors.primary400};

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: center;
    margin-bottom: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const BannerExperimentStyled = styled(BannerStyled)`
  color: ${({ theme }) => theme.colors.typography900};
  background: linear-gradient(
    83deg,
    rgba(85, 220, 139, 0.2) -15.87%,
    rgba(122, 213, 244, 0.2) 96.3%
  );

  @media ${({ theme }) => theme.breakpoints.desktop} {
    justify-content: flex-start;
    padding: 16px 32px;
  }
`;

export const BannerDescriptionStyled = styled.div``;

export const SmileEmojiStyled = styled(Emoji)`
  width: 35px;
  height: 35px;
  margin-right: 15px;
`;

export const EmojiStyled = styled.span`
  margin-right: 15px;
  font-size: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 35px;
  }
`;

export const BannerTitleStyled = styled.p`
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 700;
  line-height: 135%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const BannerTextStyled = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline;
    font-size: inherit;
  }
`;
