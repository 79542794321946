import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';
import Title from 'core/common/components/Title';
import Icon from 'core/svg-sprites/ui/Icon';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 12px;
`;

export const StyledTitle = styled(Title)`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 22px;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.desktop} {
    line-height: 28px;
  }
`;

export const Text = styled.p`
  padding-bottom: 6px;
  font-size: 14px;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const Image = styled(ImageComponent)``;

export const WrongScanImage = styled(ImageComponent)`
  margin-top: 6px;
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  margin-top: 56px;
  margin-bottom: 28px;
  border-radius: 12px;
  font-size: 18px;
  line-height: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const UploadButton = styled(Button)`
  margin-bottom: 12px;
  border: ${(props) => `2px solid ${props.theme.colors.infoLight}`};
  color: ${(props) => props.theme.colors.infoLight};
  background: transparent;
`;

export const Guarantee = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const GuaranteeContent = styled.p`
  flex: 1;
  margin-left: 8px;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;
