import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

export const ContainerInnerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  user-select: none;
  transform-style: preserve-3d;
`;

export const SlidesStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
`;

export const SlideStyled = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: ${({ active, theme }) =>
    active ? theme.colors.typography900 : theme.colors.typography500};
  backface-visibility: hidden;
  will-change: transform;
`;

export const ShadowStyled = styled.div`
  position: relative;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 43%;
  margin-top: -2px;
  background: ${({ theme }) => theme.colors.typography100};
  opacity: 0.6;
  transform: translateX(-10%);
  filter: blur(15px);
`;

export const SlideValueStyled = styled.span``;
