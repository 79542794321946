import styled from 'styled-components';

export const DetailsListContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 0 0 20px 20px;
  background: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 20px;
    padding: 18px 44px;
  }
`;

export const List = styled.ul`
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 8px;

  &:not(:first-child) {
    margin-left: 25px;
    padding-left: 25px;
  }

  &:not(:first-child)::before {
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    border: 1px solid #d4c6e5;
    content: '';
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    gap: 20px;

    &:not(:first-child) {
      margin-left: 44px;
      padding-left: 44px;
    }
  }
`;

export const SingleList = styled(List)`
  &:not(:first-child) {
    margin-left: 0;
    padding-left: 0;
  }

  &:not(:first-child)::before {
    display: none;
  }
`;

export const ListItem = styled.li`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: calc(50% - 20px);
  }
`;

export const ItemTitle = styled.h6`
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  color: #454895;
`;

export const ItemText = styled.p`
  margin-top: 4px;
  font-size: 14px;
  line-height: 130%;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.typography900};
`;
