import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 708px;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
`;

export const StepsImageStyled = styled(Image)`
  width: 100%;
  height: 31px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

const Step = styled.p`
  font-size: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const Step1 = styled(Step)`
  color: ${(props) => props.theme.colors.typography600};
`;

export const Step2 = styled(Step)`
  margin-left: 10px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};
`;

export const Step3 = styled(Step)`
  text-align: right;
  color: ${(props) => props.theme.colors.typography600};
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 44px;
  }
`;

export const Subtitle = styled.h4`
  max-width: 460px;
  margin: 16px auto 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  color: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
    font-size: 24px;
  }
`;
