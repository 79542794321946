import { ChangeEvent, useCallback, useState } from 'react';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { Image } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { debounce } from 'core/common/utils/debounce';
import { PlaceHumanDesignScreenProps, PlaceListData } from 'core/funnel/entities';
import PlaceList from './PlaceList';
import {
  AutocompleteContainer,
  Container,
  Content,
  HideButton,
  InputBlock,
  Skip,
  ButtonStyled,
  TextStyled,
  TitleStyled,
} from './styled';

const PlaceHumanDesignScreen = ({
  content,
  skipData,
  initialCoordinates,
  initialLocation,
  totalSteps,
  progressBarTitle,
  onSubmit,
  onBack,
  getAvailableLocations,
  step,
}: PlaceHumanDesignScreenProps) => {
  const [location, setLocation] = useState(initialLocation);
  const [place, setPlace] = useState(initialCoordinates);
  const [citiesList, setPlaceListData] = useState<Array<PlaceListData>>([]);
  const [isVisibleContent, setVisibleContent] = useState(true);

  const handleInputClick = () => setVisibleContent(false);

  const handleCancelClick = () => {
    if (!location) setPlace([]);
    closePlaceListData();
  };

  const closePlaceListData = () => {
    setPlaceListData([]);
    setVisibleContent(true);
  };

  const handleButtonClick = () => {
    onSubmit(place, location);
  };

  const handleSkipClick = (defaultPlace: number[], defaultLocation: string) => {
    onSubmit(defaultPlace, defaultLocation);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    await debouncedGeolocation(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGeolocation = useCallback(
    debounce(async (value) => {
      if (value) {
        const features = await getAvailableLocations(value);
        return setPlaceListData(features);
      }

      return setPlaceListData([]);
    }, 500),
    [],
  );

  const handleSelectedItem = useCallback((cityPlace: number[], cityLocation: string) => {
    // TODO: Think maybe create one state object for all this states
    setPlace(cityPlace);
    setLocation(cityLocation);
    setPlaceListData([]);
    closePlaceListData();
  }, []);

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <Container>
        <Content>
          {!!content.image && (
            <Image
              src={content.image.src}
              alt={content.image.alt || 'place'}
              width={203}
              height={203}
            />
          )}
          <TitleStyled>{content.title}</TitleStyled>
          <TextStyled>{content.text}</TextStyled>
          <AutocompleteContainer>
            <InputBlock>
              <Input
                placeholder="Enter place of birth"
                onChange={handleInputChange}
                value={location}
                onClick={handleInputClick}
              />
              {!isVisibleContent && (
                <HideButton onClick={handleCancelClick}>{content.cancel}</HideButton>
              )}
            </InputBlock>
            <PlaceList onSelected={handleSelectedItem} citiesList={citiesList} />
          </AutocompleteContainer>
          {isVisibleContent && (
            <ButtonStyled onClick={handleButtonClick} disabled={!place.length}>
              {content.next}
            </ButtonStyled>
          )}
        </Content>
        {!!skipData && (
          <Skip
            role="button"
            onClick={() => handleSkipClick(skipData.defaultCoordinates, skipData.defaultPlace)}
          >
            {skipData.text}
          </Skip>
        )}
      </Container>
    </>
  );
};
export default PlaceHumanDesignScreen;
