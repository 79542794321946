import { EmojiBlock, StyledEmoji, Text } from '../AnswerButton/styled';
import { Circle, IconStyled, RadioButtonWrapper } from './styled';

type AnswerRadioButtonProps = {
  emoji?: string;
  className?: string;
  isChosen: boolean;
  text: string;
  onClick: () => void;
};

const AnswerRadioButton = ({
  className,
  emoji,
  isChosen,
  text,
  onClick,
}: AnswerRadioButtonProps) => {
  return (
    <RadioButtonWrapper
      isChosen={isChosen}
      withEmoji={!!emoji}
      className={className}
      onClick={onClick}
    >
      {!!emoji && (
        <EmojiBlock>
          <StyledEmoji height={38} width={38} emoji={emoji} />{' '}
        </EmojiBlock>
      )}
      <Text>{text}</Text>
      <Circle>
        <IconStyled name="check" width={22} height={22} />
      </Circle>
    </RadioButtonWrapper>
  );
};

export default AnswerRadioButton;
