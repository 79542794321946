import Header from 'core/common/components/Header';
import QuizBackground from 'core/common/components/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { CompoundedTitleInformationalScreenProps } from 'core/funnel/entities';
import {
  ButtonWrapper,
  Container,
  NextButton,
  PrevButton,
  StarWrapper,
  StyledAnimatedImage,
  StyledTitle,
  Text,
} from './styled';

const CompoundedTitleInformationalScreen = ({
  content,
  onBack,
  goNext,
}: CompoundedTitleInformationalScreenProps) => {
  const {
    animatedImage,
    subtitle,
    title,
    backgroundImage,
    image,
    next: buttonNext,
    back: buttonBack,
  } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <QuizBackground backgroundImage={backgroundImage} variant="darkGradient" />
      <Container>
        {!!animatedImage && <StyledAnimatedImage variant={animatedImage} />}
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <StarWrapper>{image}</StarWrapper>
        <StyledTitle dangerouslySetInnerHTML={prepareHtml(title)} />
        <Text>{subtitle}</Text>
      </Container>
      <ButtonWrapper>
        <PrevButton onClick={onBack}>{buttonBack}</PrevButton>
        <NextButton onClick={goNext} variant="secondary">
          {buttonNext}
        </NextButton>
      </ButtonWrapper>
    </>
  );
};

export default CompoundedTitleInformationalScreen;
