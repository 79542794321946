import styled, { keyframes } from 'styled-components';
import Progress from 'core/common/components/Progress';

export const BackgroundStyled = styled.div`
  &::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.gradients.secondary};
    content: '';
  }
`;

export const ContainerStyled = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 360px;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const ProgressTextStyled = styled.p`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography300};
`;

export const ProgressStyled = styled(Progress)`
  height: 8px;
  margin-bottom: 40px;
  background: ${({ theme }) => theme.colors.secondary600} !important;
`;

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const animationDurationSeconds = 5.8;

export const ContentStyled = styled.div<{ value: number }>`
  position: absolute;
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: ${animationDurationSeconds}s;
  animation-delay: ${({ value }) => value * animationDurationSeconds}s;
`;

export const WrapperStyled = styled.div`
  position: relative;
`;
