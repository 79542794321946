import { SectionLegacyFooterAttributes } from 'core/funnel/entities';
import { FooterStyled, ButtonStyled } from './styled';

type SectionFooterProps = {
  content: SectionLegacyFooterAttributes['content'];
  className?: string;
  onClick: () => void;
};

const SectionFooter = ({ content, className, onClick }: SectionFooterProps) => {
  return (
    <FooterStyled className={className}>
      <ButtonStyled onClick={onClick}>{content.button}</ButtonStyled>
    </FooterStyled>
  );
};

export default SectionFooter;
