import TimerV2 from 'core/common/components/TimerV2';
import { SectionBannerWithTimerAndOfferAttributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import Discount from './Discount';
import { WrapperStyled, IconStyled } from './styled';

type SectionBannerWithTimerAndOfferProps = {
  content: SectionBannerWithTimerAndOfferAttributes['content'];
  offer?: Offer;
  timerStorageKey: string;
  timerDuration?: number;
  timerStop?: number;
};

// Experiment NEB-11163
const SectionBannerWithTimerAndOffer = ({
  content,
  offer,
  timerStorageKey,
  timerDuration,
  timerStop,
}: SectionBannerWithTimerAndOfferProps) => {
  return (
    <WrapperStyled>
      {content.specialOffer && offer ? (
        <Discount offer={offer} specialOffer={content.specialOffer} />
      ) : (
        <IconStyled name="nebula" width={110} />
      )}

      <TimerV2
        storageKey={timerStorageKey}
        duration={timerDuration}
        stop={timerStop}
        title={content.title}
        secLabel={content.secLabel}
        minLabel={content.minLabel}
        timerAlign="right"
      />
    </WrapperStyled>
  );
};

export default SectionBannerWithTimerAndOffer;
