import TimeV3Screen from 'core/common/components/QuizScreens/TimeV3';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { TimeV3ScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type TimeV3PageProps = {
  id: string;
};

const TimeV3Page = ({ id }: TimeV3PageProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as TimeV3ScreenAttributes;

  const userTime = (userPreferences[dataKey as keyof UserProfile] as string) || '';

  const onSubmit = (time: string) => {
    updateUserPreferences(dataKey, time);
    next();
  };

  return (
    <TimeV3Screen
      onBack={back}
      onSubmit={onSubmit}
      userTime={userTime}
      step={step}
      {...(screenAttributes as TimeV3ScreenAttributes)}
    />
  );
};

export default TimeV3Page;
