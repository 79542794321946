import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { ScanPreviewScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Guarantee,
  GuaranteeContent,
  GuaranteeIconStyled,
  Image,
  Text,
  Title,
} from './styled';

const ScanPreviewScreen = ({
  onBack,
  tryScan,
  step,
  content,
  totalSteps,
}: ScanPreviewScreenProps) => {
  const { title, subtitle, next, text, guarantee, image } = content;

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <Container>
        <Image src={image} width={330} height={214} alt="palm scanner" />
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
        {text && <Text>{content.text}</Text>}
        <Button onClick={tryScan}>{next}</Button>
        <Guarantee>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeContent>{guarantee}</GuaranteeContent>
        </Guarantee>
      </Container>
    </>
  );
};

export default ScanPreviewScreen;
