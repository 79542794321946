import styled from 'styled-components';
import AddressComponent from 'core/common/components/Address';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import EmojiComponent from 'core/common/components/Emoji/DeprecatedEmoji';
import NavLink from 'core/common/components/Link/Link';
import TitleComponent from 'core/common/components/Title';

export const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const MainContainerStyled = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 456px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  padding-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const EmojiStyled = styled(EmojiComponent)`
  width: 28px;
  height: 28px;
  margin-right: 12px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin-right: 8px;
  }
`;

export const PartyEmojiStyled = styled(EmojiComponent)`
  width: 60px;
  height: 60px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 23px;
    height: 23px;
  }
`;

export const SpecialOffer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.colors.infoLight};
  border-radius: 8px;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 28px;
`;

export const DiscountTitleStyled = styled(TitleComponent)`
  margin-top: 16px;
  margin-bottom: 48px;
  font-size: 32px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.mobile} {
    max-width: 200px;
    font-size: 24px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

export const SubtitleStyled = styled(Text)`
  margin-bottom: 30px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.typography600};
`;

export const TotalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
  font-weight: 600;

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

export const TotalTextStyled = styled(Text)`
  font-size: 18px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

export const HighLightPriceStyled = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.infoLight};

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 18px;
  }
`;

export const BorderStyled = styled.p`
  width: 100%;
  height: 1px;
  margin-top: 0;
  margin-bottom: 16px;
  background: ${(props) => props.theme.colors.infoLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const SpecialCodeStyled = styled.p`
  display: flex;
  align-items: center;
  color: currentColor;
`;

export const PriceBlockStyled = styled.div<{ withGradient?: boolean; offset?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ offset = 4 }) => offset}px;
  padding: ${({ withGradient }) => (withGradient ? '6px 16px' : '0')};
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  color: ${({ withGradient, theme }) => (withGradient ? theme.colors.typography100 : 'initial')};
  background: ${({ withGradient }) =>
    withGradient
      ? 'linear-gradient(95deg, #cf8bf3 -16.49%, #a770ef -15.14%, #fdb99b 115.23%)'
      : 'transparent'};

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const PriceBlockTextStyled = styled(Text)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const OldPriceStyled = styled.span`
  font-weight: normal;
  text-decoration: line-through;
  color: ${(props) => props.theme.colors.typography500};
`;

export const InsteadStyled = styled.p`
  align-self: flex-start;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const PriceStyled = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const DescriptionStyled = styled.p`
  margin-top: 20px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 20px;
`;

export const ButtonStyled = styled(ButtonComponent)`
  height: 49px;
  height: unset;
  padding: 0.85em;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.success500};
`;

export const LinkStyled = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;

export const Address = styled(AddressComponent)`
  margin-top: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
  }
`;

export const AddressUS = styled.p`
  max-width: 330px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;
