import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header/Header';
import ProgressBarHint from 'core/common/components/ProgressBarHint';
import QuizBackground from 'core/common/components/QuizBackground/QuizBackground';
import { Select } from 'core/common/components/Select';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { createDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { DateScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerMainStyled,
  ContentStyled,
  SelectContainerStyled,
  TextStyled,
  TitleStyled,
} from './styled';

const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const yearsList = generateDates(1940, greatestBirthYear, 'decrement');

const DateScreen = ({
  getInitialDate,
  onSubmit,
  content,
  onBack,
  step,
  progressBarTitle,
  progressBarName,
  totalProfileSteps,
  totalPersonalitySteps,
  totalRelationshipSteps,
}: DateScreenProps) => {
  const { day, month, year } = getInitialDate();
  const {
    title,
    text,
    next,
    backgroundImage,
    labelMonth,
    labelYear,
    labelDay,
    placeholderDay,
    placeholderMonth,
    placeholderYear,
  } = content;

  const [date, setDate] = useState({
    month: month ? shortMonthsListEn[month] : '',
    day: day ? String(day) : '',
    year: year ? String(year) : '',
  });

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const daysList = generateMonthDays(date.month, date.year);

  const handleClick = () => {
    const dateOfBirth = createDate(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid Date') {
      const newDateOfBirth = createDate(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      return onSubmit(newDateOfBirth);
    }
    return onSubmit(dateOfBirth);
  };

  const isNextButtonDisabled = !date.day || !date.year || !date.month;

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header buttonBack onBack={onBack} />
      <ProgressBarHint
        currentStep={step}
        currentName={progressBarName}
        title={progressBarTitle}
        totalPersonalitySteps={totalPersonalitySteps}
        totalProfileSteps={totalProfileSteps}
        totalRelationshipSteps={totalRelationshipSteps}
      />
      <ContainerMainStyled>
        <ContentStyled>
          <TitleStyled>{title}</TitleStyled>
          {text && <TextStyled>{text}</TextStyled>}
          <SelectContainerStyled>
            <Select
              options={monthsList}
              name="month"
              value={date.month}
              onChange={handleDateChange}
              placeholder={placeholderMonth ?? labelMonth ?? 'Month'}
            />
            <Select
              options={daysList}
              name="day"
              value={date.day}
              onChange={handleDateChange}
              placeholder={placeholderDay ?? labelDay ?? 'Day'}
            />
            <Select
              options={yearsList}
              name="year"
              value={date.year}
              onChange={handleDateChange}
              placeholder={placeholderYear ?? labelYear ?? 'Year'}
            />
          </SelectContainerStyled>
          <ButtonStyled onClick={handleClick} disabled={isNextButtonDisabled}>
            {next}
          </ButtonStyled>
        </ContentStyled>
      </ContainerMainStyled>
    </>
  );
};

export default DateScreen;
