import cameraAccessDialogImage from 'core/common/assets/images/palmistryQuiz/camera_access_dialog.png';
import cameraAccessDialogAndroidImage from 'core/common/assets/images/palmistryQuiz/camera_access_dialog_android.png';
import cameraAccessDialogIosImage from 'core/common/assets/images/palmistryQuiz/camera_access_dialog_ios.png';
import pointer from 'core/common/assets/images/palmistryQuiz/pointer.svg';
import { useServices } from 'core/common/hooks';
import { useIsMobile } from 'core/theme/hooks';
import useCameraPermissionsAlert from './useCameraPermissionsAlert';
import {
  AspectRatio,
  BorderWrapper,
  CloseButtonStyled,
  Content,
  PointerStyled,
  StyledButton,
  StyledCameraImage,
  StyledImage,
  StyledModal,
  Text,
  Title,
} from './styled';

type CameraPermissionsAlertProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  hidePermissionImage?: boolean;
};

const CameraPermissionsAlert = ({
  isOpen,
  onClose,
  onConfirm,
  hidePermissionImage,
}: CameraPermissionsAlertProps) => {
  const { isMobile } = useIsMobile();
  const { appEnvironment } = useServices();
  const { cameraErrorPopUpClicked } = useCameraPermissionsAlert(isOpen);

  const getDialogImage = () => {
    const isAppleMobile = appEnvironment.isAppleMobile();

    if (isAppleMobile) {
      return cameraAccessDialogIosImage;
    }

    if (isMobile) {
      return cameraAccessDialogAndroidImage;
    }

    return cameraAccessDialogImage;
  };

  const handleConfirm = () => {
    onConfirm();
    cameraErrorPopUpClicked();
  };

  return (
    <StyledModal open={isOpen}>
      <Content>
        <CloseButtonStyled onClick={onClose} />
        <StyledCameraImage />
        <Title>Oops, camera access required</Title>
        <Text>Please make sure to allow camera access on the onscreen instruction.</Text>
        {!hidePermissionImage && (
          <BorderWrapper>
            <AspectRatio>
              <StyledImage src={getDialogImage()} alt="Camera access dialog" />
              <PointerStyled src={pointer} alt="Colored arrow to the top" width={20} height={24} />
            </AspectRatio>
          </BorderWrapper>
        )}
        <Text>
          If you deny camera access, you won’t be able to take pictures and complete your palm
          analysis.
        </Text>
        <StyledButton
          variant={isMobile ? 'primary' : 'secondary'}
          onClick={handleConfirm}
          fullWidth
        >
          Got it!
        </StyledButton>
      </Content>
    </StyledModal>
  );
};

export default CameraPermissionsAlert;
