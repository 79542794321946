import MuiAccordionSummary, {
  AccordionSummaryClassKey,
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ClassNameMap } from '@mui/styles';
import cn from 'classnames';

const summaryClasses: Partial<ClassNameMap<AccordionSummaryClassKey>> = {
  content: 'accordion-summary-content',
};

export const AccordionSummary = (props: AccordionSummaryProps) => {
  const { classes: propsClasses } = props;

  const classes = {
    ...propsClasses,
    content: cn(propsClasses?.content, summaryClasses.content),
  };

  return <MuiAccordionSummary {...props} classes={classes} />;
};
