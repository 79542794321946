export const ZodiacWheelHighlighter = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 147" className={className}>
      <path
        d="M0.460083 1.23508L39.1748 147.915L79.4313 0.724003L0.460083 1.23508Z"
        fill="url(#paint_linear)"
      />
      <defs>
        <linearGradient
          id="paint_linear"
          x1="39.4329"
          y1="0.724001"
          x2="39.4329"
          y2="147.915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFC2E3" stopOpacity="0" />
          <stop offset="0.5" stopColor="#CFC2E3" stopOpacity="0.3" />
          <stop offset="1" stopColor="#322A70" stopOpacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
