import { ReactNode } from 'react';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { ColorVariants } from 'core/theme/entities';
import { TitleText } from './styled';

interface TitleProps {
  children?: ReactNode;
  className?: string;
  variant?: ColorVariants;
  dangerouslyInnerHTML?: string;
}

const Title = ({ children, className, variant = 'primary', dangerouslyInnerHTML }: TitleProps) =>
  dangerouslyInnerHTML ? (
    <TitleText
      className={className}
      variant={variant}
      dangerouslySetInnerHTML={prepareHtml(dangerouslyInnerHTML)}
    />
  ) : (
    <TitleText className={className} variant={variant}>
      {children}
    </TitleText>
  );

export default Title;
