import ImageInformationalScreen from 'core/common/components/QuizScreens/ImageInformational';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ImageInformationalScreenAttributes } from 'core/funnel/entities';

type ImageInformationalProps = {
  id: string;
};

const ImageInformational = ({ id }: ImageInformationalProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <ImageInformationalScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as ImageInformationalScreenAttributes)}
    />
  );
};

export default ImageInformational;
