import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';

import { LoaderSpeedRatio } from 'core/common/components/ProcessingLoader';

import { LoadingPalmistryScreenProps } from 'core/funnel/entities';

import { Container, Description, StyledProcessingLoader, StyledTitle, Subtitle } from './styled';

const LoadingPalmistryScreen = ({ onLoadingFinished, content }: LoadingPalmistryScreenProps) => {
  return (
    <>
      <BackgroundGradient />
      <Header buttonBack={false} variant="secondary" />
      <Container>
        <StyledTitle variant="secondary" dangerouslyInnerHTML={content.title} />
        <Subtitle>{content.subtitle}</Subtitle>
        <StyledProcessingLoader speedRatio={LoaderSpeedRatio.FAST} onFinished={onLoadingFinished} />
        <Description>{content.description}</Description>
      </Container>
    </>
  );
};

export default LoadingPalmistryScreen;
