import natalChart from 'core/common/assets/images/natal-chart/natalChart.png';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialPaymentNatalChartScreenAttributes } from 'core/funnel/entities';
import {
  BlurredReportWrapperStyled,
  ContentBlockStyled,
  ContentTextStyled,
  ContentTitleStyled,
  ItemStyled,
  ImageStyled,
  ItemTextStyled,
  NumberedListStyled,
  ReportTitleStyled,
  BlurredReportBodyStyled,
  BlurOuterContainerStyled,
  BlurInnerContainerStyled,
  AccessDescriptionStyled,
  ButtonStyled,
  LogoImageBlockStyled,
} from './styled';

type SectionBlurredReportProps = {
  content: TrialPaymentNatalChartScreenAttributes['content']['report'];
  onScrollToBlock: () => void;
};

const SectionBlurredReport = ({ content, onScrollToBlock }: SectionBlurredReportProps) => {
  const {
    title,
    contentTitle,
    contentPoints,
    personalityTitle,
    personalityPoints,
    accessDescription,
    button,
  } = content;

  return (
    <BlurredReportWrapperStyled>
      <BlurredReportBodyStyled>
        <ReportTitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <LogoImageBlockStyled>
          <ImageStyled alt="natal chart" src={natalChart.src} fill />
        </LogoImageBlockStyled>
        <ContentBlockStyled>
          <ContentTitleStyled dangerouslySetInnerHTML={prepareHtml(contentTitle)} />
          <NumberedListStyled>
            {contentPoints.map((text) => (
              <ItemStyled key={text}>
                <ItemTextStyled dangerouslySetInnerHTML={prepareHtml(text)} />
              </ItemStyled>
            ))}
          </NumberedListStyled>
          <ContentTitleStyled dangerouslySetInnerHTML={prepareHtml(personalityTitle)} />
          <ContentTextStyled dangerouslySetInnerHTML={prepareHtml(personalityPoints)} />
        </ContentBlockStyled>
        <BlurOuterContainerStyled>
          <BlurInnerContainerStyled>
            <AccessDescriptionStyled dangerouslySetInnerHTML={prepareHtml(accessDescription)} />
            <ButtonStyled onClick={onScrollToBlock}>{button}</ButtonStyled>
          </BlurInnerContainerStyled>
        </BlurOuterContainerStyled>
      </BlurredReportBodyStyled>
    </BlurredReportWrapperStyled>
  );
};

export default SectionBlurredReport;
