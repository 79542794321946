import styled from 'styled-components';

export const ReviewsBlockStyled = styled.div`
  margin-bottom: 32px;
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const TitleStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;
