import { ReactNode } from 'react';
import { IconWrapper, SquareButton, Text } from './styled';

type SquareButtonComponentProps = {
  className?: string;
  children: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  isChoosen?: boolean;
};

const SquareButtonComponent = ({
  className,
  icon,
  isChoosen = false,
  children,
  onClick,
}: SquareButtonComponentProps) => {
  return (
    <SquareButton onClick={onClick} className={className} isChoosen={isChoosen}>
      <IconWrapper>{icon}</IconWrapper>
      <Text isChoosen={isChoosen}>{children}</Text>
    </SquareButton>
  );
};

export default SquareButtonComponent;
