/* eslint max-len: 0 */
export const ZodiacWheelShape = () => {
  return (
    <>
      <path d="M348.659 217.337c-2.327 19.779-8.468 38.511-18.214 55.748-4.701 8.243-10.189 16.15-16.43 23.39a150.09 150.09 0 0 1-20.397 19.675c-7.317 5.824-15.29 11.058-23.677 15.413-8.446 4.394-17.318 8.023-26.446 10.73-18.892 5.568-38.476 7.317-58.216 5.096-19.738-2.212-38.407-8.263-55.559-17.918-8.208-4.66-16.066-10.12-23.263-16.326a147.563 147.563 0 0 1-19.527-20.311 147.52 147.52 0 0 1-15.253-23.628c-4.345-8.431-7.915-17.301-10.571-26.419-5.447-18.893-7.08-38.497-4.753-58.277 2.327-19.779 8.468-38.511 18.215-55.748 4.701-8.244 10.189-16.15 16.43-23.39a150.123 150.123 0 0 1 20.397-19.675 149.282 149.282 0 0 1 23.736-15.452c8.443-4.404 17.315-8.033 26.443-10.74 18.898-5.62 38.534-7.356 58.272-5.145 19.741 2.222 38.408 8.263 55.562 17.928 8.208 4.66 16.063 10.11 23.261 16.316a147.507 147.507 0 0 1 19.528 20.321 147.515 147.515 0 0 1 15.298 23.682c4.345 8.431 7.914 17.291 10.571 26.419 5.338 18.93 6.92 38.531 4.593 58.311ZM57.223 184.603c-2.315 19.687-.721 39.175 4.737 57.966 2.617 9.012 6.193 17.831 10.493 26.208 4.312 8.274 9.388 16.212 15.213 23.513a148.675 148.675 0 0 0 19.387 20.2c7.203 6.154 14.951 11.641 23.115 16.247 17.006 9.595 35.578 15.581 55.215 17.787 19.636 2.197 39.112.494 57.902-5.08a145.664 145.664 0 0 0 26.28-10.654c8.284-4.37 16.246-9.491 23.569-15.366a150.955 150.955 0 0 0 20.276-19.536 144.769 144.769 0 0 0 16.309-23.251c9.679-17.085 15.756-35.716 18.071-55.393 2.314-19.687.722-39.176-4.737-57.966a144.682 144.682 0 0 0-10.488-26.26c-4.312-8.274-9.387-16.212-15.212-23.512a148.581 148.581 0 0 0-19.388-20.2 142.112 142.112 0 0 0-23.119-16.196c-17.007-9.596-35.579-15.58-55.216-17.787-19.638-2.207-39.112-.494-57.903 5.08a145.676 145.676 0 0 0-26.278 10.654c-8.286 4.36-16.247 9.491-23.571 15.366a150.946 150.946 0 0 0-20.276 19.536c-6.132 7.204-11.665 15.046-16.308 23.251-9.68 17.085-15.757 35.716-18.071 55.393Z" />
      <path d="M269.956 219.363a70.092 70.092 0 0 1-12.65 24.842c-5.622 7.13-12.498 13.002-20.437 17.526-7.939 4.515-16.496 7.415-25.48 8.592a69.325 69.325 0 0 1-27.743-1.865c-9.083-2.484-17.419-6.683-24.773-12.495-7.098-5.58-12.936-12.421-17.406-20.332-4.471-7.902-7.323-16.442-8.451-25.42-1.194-9.309-.511-18.645 2.028-27.747a70.064 70.064 0 0 1 12.65-24.832c5.623-7.13 12.498-13.012 20.437-17.526 7.939-4.515 16.496-7.415 25.48-8.592a69.503 69.503 0 0 1 27.743 1.855c9.084 2.484 17.419 6.693 24.773 12.505 7.098 5.58 12.936 12.421 17.406 20.322 4.471 7.912 7.323 16.452 8.451 25.431 1.208 9.258.525 18.594-2.028 27.736ZM135.887 182.7c-2.511 8.993-3.215 18.218-2.029 27.367 1.156 8.878 3.937 17.288 8.372 25.139a68.723 68.723 0 0 0 17.199 20.101c7.219 5.721 15.469 9.849 24.453 12.312 8.984 2.453 18.207 3.108 27.368 1.863a69.596 69.596 0 0 0 25.19-8.514 70.127 70.127 0 0 0 20.219-17.308c5.763-7.26 9.942-15.531 12.454-24.523 2.511-8.993 3.215-18.218 2.03-27.367-1.156-8.878-3.938-17.288-8.372-25.139a68.893 68.893 0 0 0-17.2-20.111c-7.219-5.711-15.469-9.849-24.453-12.302-8.984-2.453-18.206-3.108-27.366-1.873a69.422 69.422 0 0 0-25.192 8.524 70.127 70.127 0 0 0-20.219 17.308c-5.714 7.27-9.942 15.53-12.454 24.523Z" />
      <path d="M61.2105 161.795L60.9735 162.643L135.044 182.464L135.489 182.057L61.2105 161.795Z" />
      <path d="M60.1417 237.819L60.3588 238.671L134.729 219.053L134.733 218.553L60.1417 237.819Z" />
      <path d="M97.2331 303.892L97.8468 304.516L153.011 250.182L152.514 249.678L97.2331 303.892Z" />
      <path d="M183.388 267.896L162.579 342.411L163.423 342.637L183.884 268.399L183.388 267.896Z" />
      <path d="M219.383 268.649L238.569 343.025L239.418 342.801L219.887 268.152L219.383 268.649Z" />
      <path d="M251.012 250.371L304.918 305.531L305.545 304.915L251.515 249.874L251.012 250.371Z" />
      <path d="M269.733 219.001L269.229 219.498L343.854 240.002L344.09 239.164L269.733 219.001Z" />
      <path d="M270.466 183.506L270.684 184.357L344.861 164.028L344.643 163.186L270.466 183.506Z" />
      <path d="M252.208 151.376L252.705 151.77L307.76 97.9251L307.147 97.3009L252.208 151.376Z" />
      <path d="M241.642 59.1601L220.047 133.93L221.354 133.369L242.485 59.386L241.642 59.1601Z" />
      <path d="M165.582 59.0456L184.589 134.221L185.521 133.968L166.43 58.8316L165.582 59.0456Z" />
      <path d="M99.5083 96.8924L153.051 151.59L153.708 150.944L100.135 96.2767L99.5083 96.8924Z" />
    </>
  );
};
