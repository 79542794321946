import { ReactNode } from 'react';
import {
  HeaderDiscount,
  HeaderDiscountText,
  HeaderDiscountTextWrapper,
  OldPrice,
  PriceHeader,
  PriceMain,
  PriceTitle,
  PriceWrapper,
  StyledPrice,
} from './styled';

export type PriceBlockProps = {
  className?: string;
  titleContent?: string;
  isHeaderExist?: boolean;
  price: string | number;
  discount?: string | null;
  oldPrice?: string;
  discountIcon?: ReactNode;
};

const PriceBlock = ({
  className,
  titleContent,
  isHeaderExist = true,
  price,
  discount,
  discountIcon,
  oldPrice,
}: PriceBlockProps) => {
  const choosePriceView = (oldPriceValue?: string) => {
    if (oldPriceValue) {
      return (
        <>
          <OldPrice>{oldPriceValue}</OldPrice>
          <StyledPrice>{price}</StyledPrice>
        </>
      );
    }

    return <StyledPrice>{price}</StyledPrice>;
  };

  return (
    <PriceWrapper className={className}>
      {isHeaderExist && (
        <PriceHeader>
          {!!discount && (
            <HeaderDiscount>
              <HeaderDiscountTextWrapper>
                <HeaderDiscountText>{discount}</HeaderDiscountText>
                {discountIcon}
              </HeaderDiscountTextWrapper>
            </HeaderDiscount>
          )}
          {!!titleContent && <PriceTitle>{titleContent}</PriceTitle>}
        </PriceHeader>
      )}
      <PriceMain>{choosePriceView(oldPrice)}</PriceMain>
    </PriceWrapper>
  );
};

export default PriceBlock;
