import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

const answerCardBorderRadiusPx = 12;
const accentColor = '#3c1c7b';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 12px;
`;

export const TitleStyled = styled.h2`
  margin-top: 32px;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 27px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 48px;
    margin-bottom: 8px;
    font-size: 26px;
    line-height: 35px;
  }
`;

export const DescriptionStyled = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const AnswersListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-right: -12px;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 32px;
    margin-right: -16px;
  }
`;

export const AnswersListItemStyled = styled.li`
  width: calc(50% - 12px);
  margin-right: 12px;
  margin-bottom: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 16px;
  }
`;

export const AspectRatioStyled = styled.div`
  position: relative;
  padding-bottom: 57.5%;
`;

export const AnswerImageStyled = styled(Image)`
  border-radius: ${answerCardBorderRadiusPx}px;
  object-fit: cover;
`;

export const AnswerTextStyled = styled.span`
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const AnswerButtonStyled = styled.label`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
`;

export const CustomCheckboxStyled = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
`;

export const CheckmarkStyled = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.typography300};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const IconStyled = styled(Icon)`
  display: none;
  fill: ${({ theme }) => theme.colors.white};
`;

export const DefaultInputStyled = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:focus ~ ${CustomCheckboxStyled} {
    border-radius: ${answerCardBorderRadiusPx}px;
    outline: 1px solid ${({ theme }) => theme.colors.primary500};
  }

  &:checked ~ ${CustomCheckboxStyled} {
    border: 2px solid ${accentColor};
    border-radius: ${answerCardBorderRadiusPx}px;
  }

  &:checked + ${CheckmarkStyled} {
    background-color: ${accentColor};

    ${IconStyled} {
      display: inline;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  position: sticky;
  bottom: 20px;
  height: 50px;
  margin-top: auto;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
