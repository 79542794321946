import styled from 'styled-components';
import Button from '../ButtonDeprecated';

export const ContainerMain = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 328px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const StyledContainerMain = styled(ContainerMain)`
  flex-grow: 0;
`;

export const TextMain = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const Image = styled.img`
  margin-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 300px;
    height: 300px;
  }
`;

export const QuizButton = styled(Button)`
  margin: auto auto 30px;
  font-size: 18px;
  line-height: 21px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: auto auto 50px;
  }
`;

export const HintButton = styled(QuizButton)`
  border-radius: 16px;
`;

export const InfoButton = styled(QuizButton)`
  width: 160px;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.gradients.primary};
`;

export const Skip = styled.button`
  margin-bottom: 44px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.typography500};
  cursor: pointer;
`;
