import ReportScreen from 'core/common/components/QuizScreens/Report';
import { useServices } from 'core/common/hooks';
import { config } from 'core/config';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ReportScreenAttributes } from 'core/funnel/entities';
import { useGetPalmCoordinates } from 'core/palmistry/hooks';

export type ReportProps = {
  id: string;
};

const Report = ({ id }: ReportProps) => {
  const { next, back, screenAttributes, userPreferences } = useQuizStep(id);
  const { appEnvironment } = useServices();

  const { scan } = userPreferences;

  const { linesCoordinates, fingersCoordinates } = useGetPalmCoordinates(scan.coordinates);

  const { palmPhotoLongSide, palmPhotoShortSide } = config;

  const isMobileDevice = appEnvironment.isMobileDevice();

  const width = isMobileDevice ? palmPhotoShortSide : palmPhotoLongSide;

  const height = isMobileDevice ? palmPhotoLongSide : palmPhotoShortSide;

  const goNext = () => next({ force: true });

  return (
    <ReportScreen
      goNext={goNext}
      onBack={back}
      scan={scan}
      width={width}
      height={height}
      linesCoordinates={linesCoordinates}
      fingersCoordinates={fingersCoordinates}
      isMobileDevice={isMobileDevice}
      {...(screenAttributes as ReportScreenAttributes)}
    />
  );
};

export default Report;
