import { City, Country, Item, Region } from './styled';

export type PlaceListItemProps = {
  country: string;
  region: string;
  city: string;
  coordinates: number[];
  onClick: (coordinates: number[], value: string) => void;
};

const PlaceListItem = ({ country, region, city, onClick, coordinates }: PlaceListItemProps) => {
  const handleClick = () => {
    const cityName = city ? `${city}, ` : '';
    const regionName = region ? `${region}, ` : '';
    const countryName = country || '';
    const location = `${cityName}${regionName}${countryName}`;
    onClick(coordinates, location);
  };

  return (
    <Item onClick={handleClick}>
      <City>{city}</City>
      <Region>
        {region && `${region}, `}
        <Country>{country}</Country>
      </Region>
    </Item>
  );
};

export default PlaceListItem;
