import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
`;

export const MinWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4px;
`;

export const SecWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 4px;
`;

export const ColonStyled = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
`;

export const TimeValueStyled = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
`;

export const TimeLabelStyled = styled.span`
  display: block;
  font-size: 8px;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
`;
