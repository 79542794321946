import AnswerImageMultipleScreen from 'core/common/components/QuizScreens/AnswerImageMultiple';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerImageMultipleScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type AnswerImageMultipleProps = {
  id: string;
};

const AnswerImageMultiple = ({ id }: AnswerImageMultipleProps) => {
  const { next, back, updateUserPreferences, userPreferences, screenAttributes, step } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerImageMultipleScreenAttributes;

  const defautlAnswers = (userPreferences[dataKey as keyof UserProfile] as Array<string>) ?? [];

  const onContinue = (checkedAnswers: Array<string>) => {
    updateUserPreferences(dataKey, checkedAnswers);
    next({ value: checkedAnswers });
  };

  return (
    <AnswerImageMultipleScreen
      step={step}
      defaultAnswers={defautlAnswers}
      goBack={back}
      onContinue={onContinue}
      {...(screenAttributes as AnswerImageMultipleScreenAttributes)}
    />
  );
};

export default AnswerImageMultiple;
