import styled from 'styled-components';
import AnimatedImage from 'core/common/components/AnimatedImages';
import Button from 'core/common/components/ButtonDeprecated';

export const TitleStyled = styled.h1`
  margin-top: 35px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 60px;
    font-size: 32px;
  }
`;

export const TextStyled = styled.p`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const ContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 445px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 35px;
  padding-bottom: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 90px;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 56px;
  margin-top: auto;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 150px;
  }
`;

export const AnimatedImageStyled = styled(AnimatedImage)`
  max-width: 100%;
`;
