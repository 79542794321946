import styled from 'styled-components';
import { ReactComponent as QuestionSvg } from 'core/common/assets/images/trialPayment/question.svg';

export const FAQBlockStyled = styled.div`
  margin-bottom: 32px;
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const TitleStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const ListStyled = styled.ul``;

export const ListItemStyled = styled.li`
  display: flex;
  width: 100%;
  margin-top: 12px;
`;

export const ItemIconBlockStyled = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
`;

export const ItemContentBlockStyled = styled.div`
  margin-left: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 16px;
  }
`;

export const QuestionTextStyled = styled.span`
  font-size: 13px;
  font-weight: 600;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.typography900};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const AnswerTextStyled = styled.p`
  margin-top: 4px;
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const QuestionIconStyled = styled(QuestionSvg)``;
