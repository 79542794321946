import { FunnelImage } from 'core/funnel/entities';
import ReviewsCardBase from '../ReviewsCardBase';
import ReviewsCardBody from '../ReviewsCardBody';
import ReviewsCardHeader from '../ReviewsCardHeader';
import {
  RatingStarsStyled,
  DateStyled,
  TextStyled,
  ReviewsCardFooterStyled,
  UserNameStyled,
  UserInfoWrapperStyled,
  VerifiedLabelStyled,
  VerifiedIconStyled,
} from './styled';

type ReviewsCardVerifiedProps = {
  date: string;
  review: string;
  userName: string;
  verified: {
    icon: FunnelImage;
    text: string;
  };
};

const ReviewsCardVerified = ({ date, review, verified, userName }: ReviewsCardVerifiedProps) => {
  return (
    <ReviewsCardBase>
      <ReviewsCardHeader>
        <RatingStarsStyled />
      </ReviewsCardHeader>
      <ReviewsCardBody>
        <TextStyled>{review}</TextStyled>
      </ReviewsCardBody>
      <ReviewsCardFooterStyled>
        <UserInfoWrapperStyled>
          <UserNameStyled>{userName}</UserNameStyled>
          <VerifiedLabelStyled>
            <VerifiedIconStyled
              src={verified.icon.src}
              alt={verified.icon.alt}
              width={16}
              height={16}
            />
            {verified.text}
          </VerifiedLabelStyled>
        </UserInfoWrapperStyled>
        <DateStyled>{date}</DateStyled>
      </ReviewsCardFooterStyled>
    </ReviewsCardBase>
  );
};

export default ReviewsCardVerified;
