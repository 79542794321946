import PrelandingMoonCompatibilityScreen from 'core/common/components/QuizScreens/PrelandingMoonCompatibility';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { PrelandingMoonCompatibilityScreenAttributes } from 'core/funnel/entities';

export type PrelandingMoonCompatibilityProps = {
  id: string;
};

const PrelandingMoonCompatibility = ({ id }: PrelandingMoonCompatibilityProps) => {
  const { next, signIn, screenAttributes } = useQuizStep(id);

  return (
    <PrelandingMoonCompatibilityScreen
      signIn={signIn}
      onStart={next}
      {...(screenAttributes as PrelandingMoonCompatibilityScreenAttributes)}
    />
  );
};

export default PrelandingMoonCompatibility;
