import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionFaqV2Attributes } from 'core/funnel/entities';
import Icon from 'core/svg-sprites/ui/Icon';
import {
  WrapperStyled,
  TitleStyled,
  AccordionWrapperStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
} from './styled';

type SectionFaqV2Props = {
  content: SectionFaqV2Attributes['content'];
  className?: string;
};

const SectionFaqV2 = ({ content, className }: SectionFaqV2Props) => {
  const { title, questions } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <AccordionWrapperStyled>
        {questions.map((question) => (
          <AccordionStyled key={question.title}>
            <AccordionSummaryStyled expandIcon={<Icon name="arrow-simple" />}>
              {question.title}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>{question.answer}</AccordionDetailsStyled>
          </AccordionStyled>
        ))}
      </AccordionWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionFaqV2;
