import Image from 'core/common/components/Image';
import { FunnelImage } from 'core/funnel/entities';
import {
  Container,
  Detail,
  DetailTitle,
  DetailContent,
  IconWrapper,
  StyledTitle,
  DetailDescription,
} from './styled';

type SectionDetailsProps = {
  title: string;
  details: Array<{
    title: string;
    text: string;
    image: FunnelImage;
  }>;
};

const SectionDetails = ({ title, details }: SectionDetailsProps) => {
  return (
    <Container>
      <StyledTitle>{title}</StyledTitle>
      {details.map((item) => (
        <Detail key={item.title}>
          <IconWrapper>
            <Image src={item.image.src} alt={item.image.alt} width={48} height={48} />
          </IconWrapper>
          <DetailContent>
            <DetailTitle>{item.title}</DetailTitle>
            <DetailDescription>{item.text}</DetailDescription>
          </DetailContent>
        </Detail>
      ))}
    </Container>
  );
};

export default SectionDetails;
