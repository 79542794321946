import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-bottom: 30px;
  margin-left: -15px;
  padding: 10px 15px;
  background: ${({ theme }) => theme.colors.typography100};
  box-shadow: 0 4px 5px 0 #00000026;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding: 12px 25px;
  }
`;

export const IconStyled = styled(Icon)``;
