import styled, { keyframes } from 'styled-components';
import Image from 'core/common/components/Image';

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Background = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 334px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  animation-name: ${hiddenText};
  animation-duration: 6s;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  margin-top: 55px;
`;

export const ImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: scaleX(-1);
`;

export const Text = styled.p`
  margin-bottom: 100px;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;

  span {
    display: block;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 20px;
    color: ${(props) => props.theme.colors.primary400};
  }
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${({ theme }) => theme.colors.typography200};
  background-color: ${({ theme }) => theme.colors.secondary800};
`;
