import ThankYouScreen from 'core/common/components/QuizScreens/ThankYou';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { ThankYouScreenAttributes } from 'core/funnel/entities';

type ThankYouProps = {
  id: string;
};

const ThankYou = ({ id }: ThankYouProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  return <ThankYouScreen goNext={next} {...(screenAttributes as ThankYouScreenAttributes)} />;
};

export default ThankYou;
