import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line max-len
import TrialPaymentAttachmentStyleWithDiscountV2Screen from 'core/common/components/QuizScreens/TrialPaymentAttachmentStyleWithDiscountV2';
import { useCountdown, useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import {
  FunnelName,
  TrialPaymentAttachmentStyleWithDiscountV2ScreenAttributes,
} from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useOrder } from 'core/payments/hooks';
import { ZodiacService } from 'core/zodiac/services';

type TrialPaymentAttachmentStyleWithDiscountV2Props = {
  id: string;
};

const TrialPaymentAttachmentStyleWithDiscountV2 = ({
  id,
}: TrialPaymentAttachmentStyleWithDiscountV2Props) => {
  const { next, screenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.ATTACHMENT_STYLE_WITH_DISCOUNT });

  const email = userPreferences.email;
  const { orderId } = useOrder();
  const isTimeUp = !+specialPlanCountdown;

  const { updateOffer, timeUpOffers, discountDefaultOffers, selectedOffer } = useOffers();

  const [offer, setOffer] = useState<Offer | null>(null);

  const onOfferChanged = (plan: Offer) => {
    setOffer(plan);
    updateOffer(plan);
  };

  const getReport = useCallback(() => {
    if (!offer) {
      return;
    }
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing_secret_discount', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  const zodiac = userPreferences.zodiac;
  const zodiacIcon = ZodiacService.getZodiacSignIcon(zodiac);

  useEffect(() => {
    const initialOffer = isTimeUp ? timeUpOffers[1] : discountDefaultOffers[1];

    if (initialOffer) {
      onOfferChanged(initialOffer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountDefaultOffers, timeUpOffers, isTimeUp]);

  useEffect(() => {
    if (!offer) {
      return;
    }
    analyticsService.landingPageOpened('trial_billing_secret_discount');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentAttachmentStyleWithDiscountV2Screen
      getReport={getReport}
      goNext={next}
      timeLeft={specialPlanCountdown}
      defaultOffers={discountDefaultOffers}
      timeUpOffers={timeUpOffers}
      selectedOffer={selectedOffer}
      offer={offer}
      traumaScore={userPreferences.traumaScore}
      isTimeUp={isTimeUp}
      onOfferChanged={onOfferChanged}
      zodiacIcon={zodiacIcon}
      {...(screenAttributes as TrialPaymentAttachmentStyleWithDiscountV2ScreenAttributes)}
    />
  );
};

export default TrialPaymentAttachmentStyleWithDiscountV2;
