import { TrialPaymentMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import {
  ContentStyled,
  TitleStyled,
  WrapperStyled,
  QuestionsListStyled,
  QuestionsListItemStyled,
  QuestionsListIconStyled,
  QuestionsListItemContentStyled,
  ItemTitleStyled,
  ItemDescriptionStyled,
  ActionButtomStyled,
} from './styled';

type ImportantQuestionsProps = {
  content: TrialPaymentMoonCompatibilityScreenAttributes['content']['questions'];
  onMoveToOffer: () => void;
};

const ImportantQuestions = ({ content, onMoveToOffer }: ImportantQuestionsProps) => {
  const { title, list, button } = content;

  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <ContentStyled>
        <QuestionsListStyled>
          {list.map((item) => (
            <QuestionsListItemStyled key={item.title}>
              <QuestionsListIconStyled
                src={item.icon.src}
                alt={item.icon.alt}
                width={24}
                height={24}
              />
              <QuestionsListItemContentStyled>
                <ItemTitleStyled>{item.title}</ItemTitleStyled>
                <ItemDescriptionStyled>{item.description}</ItemDescriptionStyled>
              </QuestionsListItemContentStyled>
            </QuestionsListItemStyled>
          ))}
        </QuestionsListStyled>
      </ContentStyled>
      <ActionButtomStyled impulseScale onClick={onMoveToOffer}>
        {button}
      </ActionButtomStyled>
    </WrapperStyled>
  );
};

export default ImportantQuestions;
