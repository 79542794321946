import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBar';
import QuizBackground from 'core/common/components/QuizBackground';
import { SelectTimePicker } from 'core/common/components/SelectTimePicker';
import { PickerVariants } from 'core/common/components/WheelTimePicker';
import { useIsApple } from 'core/common/hooks';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TimeV2ScreenProps } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import ScrollPicker from './ScrollPicker';
import { ContainerMainStyled, TitleStyled, SubtitleStyled } from './styled';

const TimeV2Screen = ({
  hasButtonBack = false,
  onSubmit,
  userTime,
  step,
  content,
  onBack,
  progressBar,
}: TimeV2ScreenProps) => {
  const { isMobile } = useIsMobile();
  const { isAppleMobile } = useIsApple();
  const { next, subtitle, title, backgroundImage } = content;

  return (
    <>
      {!isMobile && <QuizBackground backgroundImage={backgroundImage} />}
      <Header buttonBack={hasButtonBack} onBack={onBack} />
      {progressBar && <ProgressBar step={step} config={progressBar} onBack={onBack} />}
      <ContainerMainStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        {subtitle && <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(subtitle)} />}
        {isMobile ? (
          <ScrollPicker
            onSubmit={onSubmit}
            userTime={userTime}
            nextLabel={next}
            variant={isAppleMobile ? PickerVariants.CYLINDER : PickerVariants.DEFAULT}
          />
        ) : (
          <SelectTimePicker onSubmit={onSubmit} userTime={userTime} labels={{ next }} />
        )}
      </ContainerMainStyled>
    </>
  );
};

export default TimeV2Screen;
