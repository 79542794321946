import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Rating from 'core/common/components/Rating';

export const WingImageStyled = styled(Image)`
  position: absolute;
  top: -6px;
  left: -36px;
`;

export const WingImageReveresedStyled = styled(Image)`
  position: absolute;
  top: -6px;
  right: -36px;
  transform: scaleX(-1);
`;

export const RatingWrapperStyled = styled.div<{ variant?: 'light' | 'dark' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.typography100};

  ${({ variant, theme }) =>
    variant === 'dark' &&
    `
    color: ${theme.colors.typography900};
  `}
`;

export const TitleStyled = styled.span`
  font-weight: 600;
`;

export const RatingStyled = styled(Rating)`
  margin-top: 6px;
  margin-right: 4px;
  font-size: 14px;
`;

export const StarsWrapperStyled = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
`;
