import { useEffect } from 'react';
import TrialCreditsScreen from 'core/common/components/QuizScreens/TrialCreditsV2';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { TrialCreditsV2ScreenAttributes } from 'core/funnel/entities';
import { Upsell } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { getCalculatedUpsell } from 'core/offers/mappers';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

const initTrialCreditsUpsell: Omit<Upsell, 'offerId'> = {
  price: '-',
  oldPrice: '-',
  discountPercentage: 0.5,
  discount: '50%',
  isPrimary: false,
  order: 1,
};

type TrialCreditsV2Props = {
  id: string;
};

const TrialCreditsV2 = ({ id }: TrialCreditsV2Props) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { trialCreditsOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  // TODO think how to move to mappers
  const trialCreditsUpsell: Upsell = getCalculatedUpsell(initTrialCreditsUpsell, trialCreditsOffer);

  const buyCredits = () => {
    if (!trialCreditsOffer) return;

    recurringPaymentRequest(trialCreditsOffer.offerId);
    analyticsService.upsellCreditsLpGetReport(trialCreditsOffer.offerId);
  };

  const skip = async () => {
    analyticsService.upsellCreditsLpSkip();
    next();
  };

  useEffect(() => {
    analyticsService.upsellCreditsLpOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellCreditsLpGetReport(trialCreditsOffer ? trialCreditsOffer.offerId : '');
      next();
    }
  }, [paymentProcessStep]);

  return (
    <TrialCreditsScreen
      isLoading={isPurchaseLoading}
      resignFormLoaded={isResignFormLoaded}
      trialCreditsUpsell={trialCreditsUpsell}
      onBuyCredits={buyCredits}
      onSkip={skip}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as TrialCreditsV2ScreenAttributes)}
    />
  );
};

export default TrialCreditsV2;
