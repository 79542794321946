import { useRef } from 'react';
import { useInView } from 'core/common/hooks';

export const useObservers = () => {
  const { ref: reviewCarouselRef, inView: isCarouselInView } = useInView({ triggerOnce: true });
  const { ref: firstOfferRef, inView: isFirstOfferInView } = useInView({
    threshold: 0.2,
  });
  const { ref: secondOfferRef, inView: isSecondOfferInView } = useInView({
    threshold: 0.2,
  });

  const originalFirstOfferRef = useRef<HTMLDivElement | null>(null);
  const originalSecondOfferRef = useRef<HTMLDivElement | null>(null);

  const setOffersRef = (node: HTMLDivElement) => {
    if (!node) return;

    if (!originalFirstOfferRef.current) {
      originalFirstOfferRef.current = node;
      firstOfferRef(node);

      return;
    }

    originalSecondOfferRef.current = node;
    secondOfferRef(node);
  };

  return {
    setOffersRef,
    firstOfferRef: {
      inView: isFirstOfferInView,
    },
    secondOfferRef: {
      inView: isSecondOfferInView,
    },
    reviewCarouselRef: {
      intersection: reviewCarouselRef,
      inView: isCarouselInView,
    },
  };
};
