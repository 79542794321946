import AnswerHorizontalOrientationScreen from 'core/common/components/QuizScreens/AnswerHorizontalOrientation';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerHorizontalOrientationScreenAttributes } from 'core/funnel/entities';

type AnswerHorizontalOrientationProps = {
  id: string;
};

const AnswerHorizontalOrientation = ({ id }: AnswerHorizontalOrientationProps) => {
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey, hasPolicy } = screenAttributes as AnswerHorizontalOrientationScreenAttributes;

  const onAnswerClick = async (value: string) => {
    if (hasPolicy && !isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    await next({ value });
  };

  return (
    <AnswerHorizontalOrientationScreen
      onBack={back}
      onAnswerClick={onAnswerClick}
      onPolicyClick={handlePolicyClick}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      step={step}
      {...(screenAttributes as AnswerHorizontalOrientationScreenAttributes)}
    />
  );
};

export default AnswerHorizontalOrientation;
