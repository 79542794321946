import { TrialPaymentWithDiscountScreenAttributes } from 'core/funnel/entities';
import {
  ActualDiscountPercentage,
  ActualPrice,
  Annotation,
  DiscountBlock,
  DiscountEmoji,
  DiscountPercentagesBlock,
  DiscountText,
  DividerLine,
  MainBlock,
  OldDiscountPercentage,
  OldPrice,
  PricesBlock,
  SaveMoneyText,
  SubscriptionDetailsBlock,
  SubscriptionDetailsText,
  Title,
  TotalBlock,
  TotalText,
  TrialPrice,
} from './styled';

export type SectionOfferProps = {
  fullPrice: string;
  discountPrice: string;
  discountTrialPrice: string;
  saveAmount: string;
  content: TrialPaymentWithDiscountScreenAttributes['content']['offerContent'];
};

const SectionOffer = ({
  fullPrice,
  discountPrice,
  discountTrialPrice,
  saveAmount,
  content,
}: SectionOfferProps) => {
  const { title, subtitle, discount, perMonthCostText, moneySaveText, totalText } = content;

  return (
    <MainBlock>
      <Title>{title}</Title>
      <Annotation>{subtitle}</Annotation>
      <DiscountBlock>
        <DiscountEmoji emoji={discount.emoji} />
        <DiscountText>{discount.text}</DiscountText>
        <DiscountPercentagesBlock>
          <OldDiscountPercentage>{discount.oldDiscountPercent}</OldDiscountPercentage>
          <ActualDiscountPercentage>{discount.discountPercent}</ActualDiscountPercentage>
        </DiscountPercentagesBlock>
      </DiscountBlock>
      <SubscriptionDetailsBlock>
        <SubscriptionDetailsText>{perMonthCostText}</SubscriptionDetailsText>
        <PricesBlock>
          <OldPrice>{fullPrice}</OldPrice>
          <ActualPrice>{discountPrice}</ActualPrice>
        </PricesBlock>
      </SubscriptionDetailsBlock>
      <SaveMoneyText>
        {moneySaveText} {saveAmount}
      </SaveMoneyText>
      <DividerLine />
      <TotalBlock>
        <TotalText>{totalText}</TotalText>
        <TrialPrice>{discountTrialPrice}</TrialPrice>
      </TotalBlock>
    </MainBlock>
  );
};

export default SectionOffer;
