import { useState } from 'react';
import MobileScrollPicker, { PickerVariants } from 'core/common/components/MobileScrollPicker';
import { ZodiacWheel } from 'core/common/components/ZodiacWheel';
import { greatestBirthYear, shortMonthsListEn } from 'core/common/constants';
import { useDisabledScroll } from 'core/common/hooks';
import { createDate } from 'core/common/utils/date';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { PickerWrapperStyled, ButtonStyled, BlockWrapperStyled } from './styled';

const yearsList = generateDates(1940, greatestBirthYear, 'increment');
const formatDayValue = (day: string) => (day.length <= 1 ? '0' + day : day);

const convertStringToDate = (userDate?: string) => {
  const formattedDate = createDate(userDate, 'MM-DD-YYYY');
  const { day, month, year } = {
    day: formattedDate.get('date'),
    month: formattedDate.get('month'),
    year: formattedDate.get('year'),
  };

  return {
    month: month >= 0 ? shortMonthsListEn[month] : '',
    day: formatDayValue(String(day)),
    year: year ? String(year) : '',
  };
};

type ScrollPickerProps = {
  nextLabel: string;
  userDate?: string;
  hasZodiacWheel: boolean;
  onSubmit: (date: string) => void;
  variant: PickerVariants;
};

const ScrollPicker = ({
  userDate,
  nextLabel,
  onSubmit,
  variant,
  hasZodiacWheel,
}: ScrollPickerProps) => {
  // native behavior IOS "overscroll" on the mobile make worse UX for usage scroll picker
  // this hack disable "overscroll"
  useDisabledScroll();
  const { day, month, year } = convertStringToDate(userDate || '1990-01-01');

  const [date, setDate] = useState({ month, day, year });

  const daysList = generateMonthDays(date.month, date.year).map(formatDayValue);

  const selections = {
    month: shortMonthsListEn,
    day: daysList,
    year: yearsList,
  } as const;

  const handleClick = () => {
    const dateOfBirth = createDate(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );
    if (dateOfBirth === 'Invalid Date') {
      const newDateOfBirth = createDate(`Jan 01 1990`, 'MMM D YYYY').format('YYYY-MM-DD');
      return onSubmit(newDateOfBirth);
    }
    return onSubmit(dateOfBirth);
  };

  const onChange = (value: Record<string, string | number>) => {
    const dateOfBirth = createDate(
      `${value.month} ${value.day} ${value.year}`,
      'MMM D YYYY',
    ).format('MMM D YYYY');

    setDate({ ...convertStringToDate(dateOfBirth), month: String(value.month) });
  };

  return (
    <BlockWrapperStyled>
      {hasZodiacWheel && <ZodiacWheel date={date} />}
      <PickerWrapperStyled>
        <MobileScrollPicker
          variant={variant}
          value={date}
          onChange={onChange}
          selections={selections}
        />
        <ButtonStyled onClick={handleClick}>{nextLabel}</ButtonStyled>
      </PickerWrapperStyled>
    </BlockWrapperStyled>
  );
};

export default ScrollPicker;
