import BackgroundGradient from 'core/common/components/BackgroundGradient';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { SingleZodiacInfoScreenProps } from 'core/funnel/entities';
import ZodiacInfo from './ZodiacInfo';
import { ContentWrapper, CustomButton, SingleZodiacImageWrapper } from './styled';

const SingleZodiacInfoScreen = ({
  onBack,
  goNext,
  content,
  zodiacImage,
}: SingleZodiacInfoScreenProps) => {
  const { title, description, next } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <BackgroundGradient />
      <ContentWrapper>
        <SingleZodiacImageWrapper>
          <Image src={zodiacImage} alt="Zodiac" layout="fill" objectFit="contain" />
        </SingleZodiacImageWrapper>
        <ZodiacInfo title={title} description={description} />
        <CustomButton variant="secondary" onClick={goNext}>
          {next}
        </CustomButton>
      </ContentWrapper>
    </>
  );
};

export default SingleZodiacInfoScreen;
