import LoadingMoonCompatibilityScreen from 'core/common/components/QuizScreens/LoadingMoonCompatibility';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { LoadingMoonCompatibilityScreenAttributes } from 'core/funnel/entities';

type LoadingMoonCompatibilityProps = {
  id: string;
};

const LoadingMoonCompatibility = ({ id }: LoadingMoonCompatibilityProps) => {
  const { screenAttributes, next } = useQuizStep(id);

  return (
    <LoadingMoonCompatibilityScreen
      onLoadingFinished={next}
      {...(screenAttributes as LoadingMoonCompatibilityScreenAttributes)}
    />
  );
};

export default LoadingMoonCompatibility;
