import { SectionFeaturedInAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  PartnersListStyled,
  PartnersListItemStyled,
  ImageStyled,
  ImageWrapperStyled,
} from './styled';

type SectionFeaturedInProps = {
  content: SectionFeaturedInAttributes['content'];
  className?: string;
};

const SectionFeaturedIn = ({ content, className }: SectionFeaturedInProps) => {
  const { title, images } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <PartnersListStyled>
        {images.map((image) => (
          <PartnersListItemStyled key={image.alt}>
            <ImageWrapperStyled>
              <ImageStyled src={image.src} alt={image.alt} fill />
            </ImageWrapperStyled>
          </PartnersListItemStyled>
        ))}
      </PartnersListStyled>
    </WrapperStyled>
  );
};

export default SectionFeaturedIn;
