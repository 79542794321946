import { useEffect } from 'react';
import OnboardingAskPalmistryScreen from 'core/common/components/QuizScreens/OnboardingAskPalmistry';
import { SECOND } from 'core/common/constants';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { OnboardingAskPalmistryScreenAttributes } from 'core/funnel/entities';

const timer = 5 * SECOND;

type OnboardingAskPalmistryProps = {
  id: string;
};

const OnboardingAskPalmistry = ({ id }: OnboardingAskPalmistryProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      next();
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [next]);

  useEffect(() => {
    analyticsService.onIntroPageOpened();
  }, [analyticsService]);

  return (
    <OnboardingAskPalmistryScreen
      {...(screenAttributes as OnboardingAskPalmistryScreenAttributes)}
    />
  );
};

export default OnboardingAskPalmistry;
