import styled from 'styled-components';
import PriceBlock, {
  HeaderDiscount,
  HeaderDiscountText,
  HeaderDiscountTextWrapper,
  PriceHeader,
  PriceMain,
  StyledPrice,
} from 'core/common/components/PriceBlock';

export const LightStyledPriceBlockStyled = styled(PriceBlock)<{ isTimeUp?: boolean }>`
  width: 160px;
  margin-left: ${(props) => (props.isTimeUp ? '0' : '18px')};
  padding: 4px;
  border: none;
  background: ${(props) => props.theme.colors.primary500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 257px;
    margin-left: ${(props) => (props.isTimeUp ? '0' : '50px')};
  }

  ${PriceHeader} {
    margin-top: -4px;
    margin-right: -4px;
    margin-left: -4px;
    padding-top: 13px;
    padding-bottom: 7px;
    border: none;
    color: ${(props) => props.theme.colors.typography100};
    background: ${(props) => props.theme.colors.primary500};

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    ${HeaderDiscount} {
      padding: 1px;
      background: #56ccf2;
    }

    ${HeaderDiscountTextWrapper} {
      background: #56ccf2;
    }

    ${HeaderDiscountText} {
      color: ${(props) => props.theme.colors.typography100};
      background: #56ccf2;
    }
  }

  ${PriceMain} {
    background: ${(props) => props.theme.colors.white};
  }

  ${StyledPrice} {
    font-size: 48px;

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: 64px;
    }
  }
`;

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const OldPricePanelStyled = styled(PriceBlock)`
  text-align: center;

  ${StyledPrice} {
    text-decoration-line: none;
  }
`;
