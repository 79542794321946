import styled, { keyframes } from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const ContainerStyled = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.typography100};
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: 5s;
  transform: translateY(-60px);
`;

export const BreakStyled = styled.br``;

export const TextStyled = styled.p<{ value: number }>`
  position: absolute;
  width: 331px;
  margin-top: 25px;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  color: white;
  opacity: 0;
  animation-name: ${hiddenText};
  animation-duration: 5s;
  animation-delay: ${(props) => props.value * 5}s;
`;
