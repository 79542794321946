import styled from 'styled-components';
import Carousel, { carouselClasses } from 'core/common/components/Carousel';

export const WrapperStyled = styled.section`
  width: 100%;
  margin-top: 56px;
  margin-bottom: 56px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 32px;
  font-size: 22px;
  text-align: center;
`;

export const CarouselStyled = styled(Carousel)`
  margin-right: -15px;

  ${carouselClasses.track} {
    display: flex;
  }

  ${carouselClasses.slide} {
    height: inherit;
    padding-right: 16px;

    & > div {
      height: 100%;
    }
  }
`;

export const CarouselSlideStyled = styled.div`
  height: 100%;
`;
