import Image from 'core/common/components/Image';
import { BASE_ROUTES } from 'core/common/constants';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionIntroOfferV2Attributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import SubscriptionRadioGroup from './SubscriptionRadioGroup';
import {
  Button,
  CaptionIconStyled,
  CaptionMainTextStyled,
  CaptionSecondaryTextStyled,
  CaptionTextWrapperStyled,
  CaptionWrapperStyled,
  ContentWrapper,
  Emphasize,
  GuaranteeBlock,
  GuaranteeIconStyled,
  GuaranteeTitle,
  Link,
  PaymentMethodsListItemStyled,
  PaymentMethodsListStyled,
  SubscriptionDisclaimer,
  TitleStyled,
  WrapperStyled,
} from './styled';

type SectionIntroOfferV2Props = {
  content: SectionIntroOfferV2Attributes['content'];
  isTimeUp: boolean;
  onGetReport: () => void;
  onPlanChanged: (plan: Offer) => void;
  chosenPlan: Offer;
  selectedPlan: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  className?: string;
};

const trialPeriodMap = {
  '7 days': '1 week',
  '28 days': '4 weeks',
  '84 days': '12 weeks',
} as const;

type TrialPeriod = (typeof trialPeriodMap)[keyof typeof trialPeriodMap];

const trialWordMap: Record<TrialPeriod, string> = {
  '1 week': 'trial',
  '4 weeks': 'introductory plan',
  '12 weeks': 'introductory plan',
};

const SectionIntroOfferV2 = ({
  content,
  isTimeUp,
  chosenPlan,
  selectedPlan,
  defaultOffers,
  timeUpOffers,
  className,
  onGetReport,
  onPlanChanged,
}: SectionIntroOfferV2Props) => {
  const trialPeriod =
    trialPeriodMap[(selectedPlan?.trialPeriod as keyof typeof trialPeriodMap) ?? '28 days'];
  const trialWord = trialWordMap[trialPeriod];

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{content.title}</TitleStyled>
      <SubscriptionRadioGroup
        isTimeUp={isTimeUp}
        choosedPlan={chosenPlan}
        onPlanChanged={onPlanChanged}
        defaultOffers={defaultOffers}
        timeUpOffers={timeUpOffers}
      />
      <ContentWrapper>
        {content.caption && (
          <CaptionWrapperStyled>
            <CaptionIconStyled
              src={content.caption.icon.src}
              alt={content.caption.icon.alt}
              width={18}
              height={18}
            />
            <CaptionTextWrapperStyled>
              <CaptionMainTextStyled
                dangerouslySetInnerHTML={prepareHtml(content.caption.mainText)}
              />
              <CaptionSecondaryTextStyled
                dangerouslySetInnerHTML={prepareHtml(content.caption.secondaryText)}
              />
            </CaptionTextWrapperStyled>
          </CaptionWrapperStyled>
        )}
        <Button fullWidth onClick={onGetReport}>
          {content.purchaseButtonText}
        </Button>
        <SubscriptionDisclaimer>
          By clicking Get My Plan you agree that if you don&apos;t cancel prior to the end of the{' '}
          <Emphasize>{trialPeriod}</Emphasize> {trialWord} to <Emphasize>NEBULA</Emphasize> service
          for <Emphasize>{selectedPlan?.pricesWithCurrency.trialPriceWithVAT}</Emphasize>, you will
          automatically be charged{' '}
          <Emphasize>{selectedPlan?.pricesWithCurrency.priceWithVAT}</Emphasize> every{' '}
          {selectedPlan?.period} until you cancel in settings. Learn more about cancellation and
          refund policy in{' '}
          <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>Subscription terms</Link>.
        </SubscriptionDisclaimer>
        <GuaranteeBlock>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeTitle>{content.guaranteeText}</GuaranteeTitle>
        </GuaranteeBlock>
      </ContentWrapper>
      {content.paymentMethods && (
        <PaymentMethodsListStyled>
          {content.paymentMethods.map((image) => (
            <PaymentMethodsListItemStyled key={image.alt}>
              <Image src={image.src} alt={image.alt} width={23} height={16} />
            </PaymentMethodsListItemStyled>
          ))}
        </PaymentMethodsListStyled>
      )}
    </WrapperStyled>
  );
};

export default SectionIntroOfferV2;
