import styled from 'styled-components';

export const TicketCoreStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 343px;
  height: 99px;
  margin-top: 48px;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    #1d1e55 0%,
    #272463 25%,
    #3b2f7b 50%,
    #4f358d 75%,
    #6939a2 100%
  );

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    transform: translateY(-50%);
    content: '';
  }

  &::before {
    left: -16px;
  }

  &::after {
    right: -16px;
  }
`;

export const DiscountLabelStyled = styled.div`
  position: relative;
  width: 88px;
  height: 100%;
  padding-right: 4px;
  border-right: 1px dashed rgba(234, 238, 247, 0.2);
`;

export const DiscountImageWrapperStyled = styled.div`
  position: relative;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const DiscountTextStyled = styled.p`
  position: absolute;
  top: 50%;
  left: 36px;
  width: 33px;
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  line-height: 114%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  transform: translateY(-50%) rotate(-12.219deg);
`;

export const DiscountTextTipWrapperStyled = styled.div`
  width: 255px;
  height: 100%;
  padding: 30px 32px 30px 16px;
`;

export const DiscountTextTipStyled = styled.p`
  font-size: 14px;
  line-height: 143%;
  text-align: left;
  color: ${({ theme }) => theme.colors.typography100};
`;
