import { useState } from 'react';
import MultipleAnswersNatalChartScreen from 'core/common/components/QuizScreens/MultipleAnswersNatalChartV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { MultipleAnswerNatalChartV2ScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type MultipleAnswersNatalChartV2Props = {
  id: string;
};

const MultipleAnswersNatalChartV2 = ({ id }: MultipleAnswersNatalChartV2Props) => {
  const { back, screenAttributes, next, updateUserPreferences, step, userPreferences } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as MultipleAnswerNatalChartV2ScreenAttributes;

  const answers = userPreferences[id as keyof UserProfile] as string[];

  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(answers || []);

  const onAnswerClick = (answer: string) => {
    if (selectedAnswers.includes(answer)) {
      setSelectedAnswers((value: string[]) => value.filter((item) => item !== answer));
      return;
    }

    setSelectedAnswers((value: string[]) => [...value, answer]);
  };

  const onContinue = async () => {
    updateUserPreferences(dataKey, selectedAnswers);
    await next({ value: selectedAnswers });
  };

  return (
    <MultipleAnswersNatalChartScreen
      onAnswerClick={onAnswerClick}
      onContinue={onContinue}
      onBack={back}
      chosenList={selectedAnswers}
      step={step}
      {...(screenAttributes as MultipleAnswerNatalChartV2ScreenAttributes)}
    />
  );
};

export default MultipleAnswersNatalChartV2;
