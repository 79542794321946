import Header from 'core/common/components/Header';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { AnswerPalmistryScreenProps } from 'core/funnel/entities';
import {
  Container,
  Content,
  Emoji,
  HintEmojiBorder,
  Item,
  Overtitle,
  StyledList,
  StyledSubtitle,
  StyledTitle,
  Text,
} from './styled';

const AnswerPalmistryScreen = ({
  content,
  onAnswerClick,
  onBack,
  step,
  totalSteps,
  hasProgressBar,
}: AnswerPalmistryScreenProps) => {
  const { title, overtitle, subtitle, answers } = content;

  return (
    <>
      <Header onBack={onBack} />
      {hasProgressBar && <ProgressBar currentStep={step} totalSteps={totalSteps} />}
      <Container>
        {!!overtitle && <Overtitle>{overtitle}</Overtitle>}
        <StyledTitle dangerouslySetInnerHTML={prepareHtml(title)} />
        {!!subtitle && <StyledSubtitle dangerouslySetInnerHTML={prepareHtml(subtitle)} />}
        <Content>
          <StyledList>
            {answers.map(({ text, emoji, value }) => (
              <Item key={text} onClick={() => onAnswerClick(value)}>
                <HintEmojiBorder>
                  <Text key={text}>{text}</Text>
                  {!!emoji && <Emoji emoji={emoji} />}
                </HintEmojiBorder>
              </Item>
            ))}
          </StyledList>
        </Content>
      </Container>
    </>
  );
};

export default AnswerPalmistryScreen;
