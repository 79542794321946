import styled from 'styled-components';
import {
  ProcessingLoaderCircle as ProcessingLoaderCircleComponent,
  Ring,
} from 'core/common/components/ProcessingLoader';
import Title from 'core/common/components/Title';
import Icon from 'core/svg-sprites/ui/Icon';

const animationDuration = '1.5s';
const animationDelay = '3s';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledTitle = styled(Title)``;

export const CircleContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.info};
  transform: translate(-50%, -50%);
`;

export const List = styled.ul`
  width: 100%;
  max-width: 330px;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.typography100};
`;

export const ListItem = styled.li<{ isLoaded: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.primary700};
  background: ${(props) => `${props.theme.colors.primaryLeo}4D`};
  animation: ${(props) =>
    props.isLoaded ? `${animationDuration} animationListItem ${animationDelay} both` : 'none'};

  @keyframes animationListItem {
    to {
      color: ${(props) => props.theme.colors.primary100};
      background: ${(props) => props.theme.colors.infoLight};
      opacity: 1;
    }
  }
`;

export const Circle = styled.div<{ isLoaded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 6px;
  border-radius: 50%;
  background: ${(props) => `${props.theme.colors.primaryLeo}4D`};
  animation: ${(props) =>
    props.isLoaded ? `${animationDuration} animationCircle ${animationDelay} both` : 'none'};

  @keyframes animationCircle {
    to {
      background: ${(props) => props.theme.colors.info};
    }
  }
`;

export const Text = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  text-align: start;
`;

export const IconStyled = styled(Icon)<{ isLoaded: boolean }>`
  width: 28px;
  height: 28px;
  opacity: 0;
  animation: ${(props) =>
    props.isLoaded ? `${animationDuration} animationSvg ${animationDelay} both` : 'none'};
  fill: ${(props) => (props.isLoaded ? '#e8e8fc' : 'transparent')};

  @keyframes animationSvg {
    to {
      opacity: 1;
    }
  }
`;

export const ProcessingLoaderCircle = styled(ProcessingLoaderCircleComponent)`
  ${Ring} {
    stroke: ${(props) => props.theme.colors.primary200};
  }
`;
