import { CarouselSettings } from 'core/common/components/Carousel';
import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import UpsellsStepper from 'core/common/components/UpsellsStepper';
import { SECOND } from 'core/common/constants';
import { UpsellNatalChartSingleScreenProps } from 'core/funnel/entities';
import NatalChartPreviewItem from './NatalChartPreviewItem';
import {
  ActionButtonStyled,
  AddressStyled,
  CarouselStyled,
  ContentStyled,
  DescriptionStyled,
  DisclaimerStyled,
  ListItemImageStyled,
  ListItemStyled,
  ListStyled,
  SkipButtonStyled,
  TitleStyled,
} from './styled';

const settings: CarouselSettings = {
  speed: 600,
  autoplay: true,
  slidesToShow: 1,
  arrows: false,
  draggable: false,
  pauseOnHover: false,
  dots: true,
  autoplaySpeed: 5 * SECOND,
};

const UpsellNatalChartSingleScreen = ({
  content,
  isLoading,
  resignFormLoaded,
  onGetReport,
  onSkip,
  paymentError,
  onClearPaymentStatus,
}: UpsellNatalChartSingleScreenProps) => {
  const { slides, title, description, list, button, skipButton, upsellsSteps, disclaimer } =
    content;

  return (
    <>
      <Header buttonBack={false} />
      <UpsellsStepper steps={upsellsSteps} />
      <ContentStyled>
        <CarouselStyled {...settings}>
          {slides.map((slide, idx) => (
            <NatalChartPreviewItem key={slide.caption} {...slide} index={idx} />
          ))}
        </CarouselStyled>
        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
        <ListStyled>
          {list.map((item) => (
            <ListItemStyled key={item.title}>
              <ListItemImageStyled src={item.icon.src} alt="" width={20} height={20} />
              {item.title}
            </ListItemStyled>
          ))}
        </ListStyled>
        <ActionButtonStyled onClick={onGetReport} disabled={isLoading} loading={isLoading}>
          {button}
        </ActionButtonStyled>
        <SkipButtonStyled onClick={onSkip} disabled={isLoading}>
          {skipButton}
        </SkipButtonStyled>
        <DisclaimerStyled>{disclaimer}</DisclaimerStyled>
        <AddressStyled />
      </ContentStyled>
      <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
      <PaymentDialogFailed
        paymentError={null}
        onTryAgain={onGetReport}
        onClose={onClearPaymentStatus}
        isOpen={!!paymentError}
      />
    </>
  );
};

export default UpsellNatalChartSingleScreen;
