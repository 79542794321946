import { useEffect } from 'react';

import { Offer } from 'core/offers/entities';
import SubscriptionRadio from './SubscriptionRadio';

import { RadioGroup, ListItem } from './styled';

type SubscriptionRadioGroupProps = {
  isTimeUp: boolean;
  choosedPlan: Offer | null;
  onPlanChanged: (plan: Offer) => void;
  onAllPlansLoaded: VoidFunction;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
};

enum PlanTitle {
  '1-WEEK READING',
  '4-WEEK READING',
  '12-WEEK READING',
}

const SubscriptionRadioGroup = ({
  isTimeUp,
  choosedPlan,
  onPlanChanged,
  onAllPlansLoaded,
  defaultOffers,
  timeUpOffers,
}: SubscriptionRadioGroupProps) => {
  const offersToDisplay = isTimeUp ? timeUpOffers : defaultOffers;
  const sortedOrders = [...offersToDisplay].sort(
    (a, b) => a.prices.trialPriceWithVAT - b.prices.trialPriceWithVAT,
  );

  useEffect(() => {
    if (offersToDisplay) {
      onPlanChanged(offersToDisplay[1]);
      onAllPlansLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersToDisplay.length, isTimeUp]);

  const getPriceWithoutDiscount = (period: string) => {
    if (isTimeUp) return '';

    const plan = timeUpOffers.find(({ trialPeriod }) => trialPeriod === period);

    return plan?.pricesWithCurrency.trialPriceWithVAT ?? '';
  };

  return (
    <RadioGroup>
      {sortedOrders.map((offer, idx) => (
        <ListItem key={offer.id}>
          <SubscriptionRadio
            value={offer.offerId}
            checked={choosedPlan?.id === offer.id}
            onPlanChanged={onPlanChanged}
            planTitle={PlanTitle[idx]}
            priceWithoutDiscount={getPriceWithoutDiscount(offer.trialPeriod!)}
            offer={offer}
            isMostPopular={idx === 1}
          />
        </ListItem>
      ))}
    </RadioGroup>
  );
};

export default SubscriptionRadioGroup;
