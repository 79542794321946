import styled from 'styled-components';
import NavLink from 'core/common/components/Link';

export const DisclaimerText = styled.p`
  margin-top: 26px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const Link = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;
