import { Fragment } from 'react';
import { Upsell } from 'core/offers/entities';
import {
  Circle,
  Container,
  Description,
  Discount,
  DiscountText,
  Emoji,
  IconStyled,
  Input,
  OldPrice,
  Price,
  PriceBlock,
  PrimaryDiscountText,
  PrimaryText,
  RadioLabel,
  TextContent,
  Title,
  TitleBlock,
} from './styled';

export type SectionPaymentProps = {
  selectedProduct: string;
  selectProduct: (value: string) => void;
  upsells: Upsell[];
};

const SectionPayment = ({ selectedProduct, selectProduct, upsells }: SectionPaymentProps) => {
  const isLoading = upsells[0] && !upsells[0].offerId;

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      {upsells.map((upsell, i) => {
        return (
          <Fragment key={i}>
            <Input
              type="radio"
              name="advisory-plan"
              id={upsell.offerId}
              value={upsell.offerId}
              checked={upsell.offerId === selectedProduct}
              onChange={(e) => {
                selectProduct(e.target.value);
              }}
            />
            <RadioLabel htmlFor={upsell.offerId} isChosen={selectedProduct === upsell.offerId}>
              <Circle>
                <IconStyled name="check" width={20} height={20} />
              </Circle>
              <TextContent>
                <TitleBlock>
                  <Title>{upsell.title}</Title>
                  {upsell.isPrimary && <PrimaryDiscountText>{upsell.discount}</PrimaryDiscountText>}
                </TitleBlock>
                {!!upsell.description && <Description>{upsell.description}</Description>}
                <PriceBlock>
                  <Price>{upsell.price}</Price> ( {upsell.oldPriceDescription}{' '}
                  <PrimaryText>
                    <OldPrice>{upsell.oldPrice}</OldPrice>
                  </PrimaryText>{' '}
                  )
                  {!upsell.isPrimary && (
                    <Discount>
                      <DiscountText>{upsell.discount}</DiscountText>
                    </Discount>
                  )}
                </PriceBlock>
              </TextContent>
              {!!upsell.emoji && <Emoji emoji={upsell.emoji} />}
            </RadioLabel>
          </Fragment>
        );
      })}
    </Container>
  );
};

export default SectionPayment;
