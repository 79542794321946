import styled from 'styled-components';

const floatingButtonWrapperHeight = 88;

export const RootStyled = styled.div``;

export const ContentStyled = styled.div<{ offsetTop: number }>`
  position: relative;
  z-index: 1;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: ${({ offsetTop }) => offsetTop}px;
  padding-bottom: ${floatingButtonWrapperHeight}px;
`;

export const FloatingButtonWrapperStyled = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${floatingButtonWrapperHeight}px;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const FloatingCTAButtonStyled = styled.button`
  height: 48px;
  padding: 12px 38px;
  border-radius: 1000px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${({ theme }) => theme.colors.lunarViolet};
`;
