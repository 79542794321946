import { capitalize } from 'core/common/utils/capitalize';
import { formatTraumaScore } from 'core/common/utils/formatTraumaScore';
import { FunnelImage } from 'core/funnel/entities';
import { ListItemContent } from '../ProfileShort';
import {
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  ListItemTitleStyled,
  ListItemValueStyled,
  ListItemTextWrapperStyled,
  ListItemValueTraumaStyled,
} from './styled';

type UserDataProps = {
  birthDate?: ListItemContent;
  traumaListItem: ListItemContent & { image: FunnelImage };
  gender: ListItemContent;
  traumaScore: number;
};

const UserData = ({ birthDate, traumaListItem, gender, traumaScore }: UserDataProps) => {
  const traumaValue = formatTraumaScore(traumaScore);

  return (
    <ListStyled>
      <ListItemStyled>
        <ListIconStyled name={traumaListItem.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled>{traumaListItem.title}</ListItemTitleStyled>
          <ListItemValueTraumaStyled>
            {traumaListItem.value} {traumaValue}/10
          </ListItemValueTraumaStyled>
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      <ListItemStyled>
        <ListIconStyled name={gender.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled>{gender.title}</ListItemTitleStyled>
          <ListItemValueStyled>{capitalize(gender.value)}</ListItemValueStyled>
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      {birthDate && (
        <ListItemStyled>
          <ListIconStyled name={birthDate.icon} width={24} height={24} />
          <ListItemTextWrapperStyled>
            <ListItemTitleStyled>{birthDate.title}</ListItemTitleStyled>
            <ListItemValueStyled>{birthDate.value}</ListItemValueStyled>
          </ListItemTextWrapperStyled>
        </ListItemStyled>
      )}
    </ListStyled>
  );
};

export default UserData;
