import { SectionGuideAttributes } from 'core/funnel/entities';
import {
  BlurredTextStyled,
  ContentStyled,
  GuideListItemStyled,
  GuideListStyled,
  IconStyled,
  ImageStyled,
  ListTextStyled,
  ListTitleStyled,
  TitleStyled,
  UserDetailsListItemStyled,
  UserDetailsListStyled,
  WrapperIconStyled,
  WrapperStyled,
} from './styled';

type SectionGuideProps = {
  content: SectionGuideAttributes['content'];
  birthdate: string;
};

const SectionGuide = ({ content, birthdate }: SectionGuideProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{content.title}</TitleStyled>
      <ContentStyled>
        <ImageStyled src={content.image.src} alt={content.image.alt} width={612} height={532} />
        <UserDetailsListStyled>
          <UserDetailsListItemStyled>
            <ListTitleStyled>{content.userDetails.title}</ListTitleStyled>
            <ListTextStyled>
              <BlurredTextStyled>{content.userDetails.subTitle}</BlurredTextStyled>
            </ListTextStyled>
          </UserDetailsListItemStyled>
          <UserDetailsListItemStyled>
            <ListTitleStyled>{content.userDetails.dateOfBirthTitle}</ListTitleStyled>
            <ListTextStyled>{birthdate}</ListTextStyled>
          </UserDetailsListItemStyled>
        </UserDetailsListStyled>
        <GuideListStyled>
          {content.list.map(({ text, title }) => (
            <GuideListItemStyled key={text}>
              <ListTitleStyled>{title}</ListTitleStyled>
              <ListTextStyled>{text}</ListTextStyled>
            </GuideListItemStyled>
          ))}
        </GuideListStyled>
        <WrapperIconStyled>
          <IconStyled name="lock" />
        </WrapperIconStyled>
      </ContentStyled>
    </WrapperStyled>
  );
};

export default SectionGuide;
