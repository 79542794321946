import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const Root = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.themeBackground};
    content: '';
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-bottom: 130px;
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  text-align: center;
  transform: translateX(-50%);

  &::before {
    position: absolute;
    top: -10px;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.themeBackground};
    content: '';
  }
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  max-width: 450px;
  height: 50px;
  margin-bottom: 10px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

export const RouteLink = styled.button`
  z-index: 5;
  width: 310px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;
