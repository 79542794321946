import styled from 'styled-components';
import Accordion, { AccordionDetails, AccordionSummary } from 'core/common/components/Accordion';

export const Wrapper = styled.div``;

export const ListStyled = styled.ul``;

export const ItemStyled = styled.li``;

export const AccordionStyled = styled(Accordion)`
  background: transparent;
  box-shadow: none;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding-right: 0;
  padding-left: 0;
  font-weight: 600;
`;

export const TextStyled = styled.p``;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
  font-size: 14px;
`;
