import { isEven } from 'core/common/utils/number';
import { OnboardingAskV2ScreenProps } from 'core/funnel/entities';
import {
  AvatarStyled,
  ItemStyled,
  ListStyled,
  MessageStyled,
  MessageWrapperStyled,
  TextStyled,
  TipStyled,
  TitleStyled,
} from './styled';

type ChatTopicsProps = OnboardingAskV2ScreenProps['content']['chatTopics'];

const ChatTopics = ({ title, tip, list, message }: ChatTopicsProps) => {
  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <ListStyled>
        {list.map(({ text, avatar }, index) => {
          return (
            <ItemStyled key={text}>
              <MessageWrapperStyled isEven={isEven(index)}>
                <MessageStyled
                  isEven={isEven(index)}
                  src={message.src}
                  alt="message"
                  width={206}
                  height={103}
                />
                <TextStyled>{text}</TextStyled>
              </MessageWrapperStyled>
              <AvatarStyled
                isEven={isEven(index)}
                src={avatar.src}
                alt={avatar.alt}
                width={44}
                height={44}
              />
            </ItemStyled>
          );
        })}
      </ListStyled>
      <TipStyled>{tip}</TipStyled>
    </>
  );
};

export default ChatTopics;
