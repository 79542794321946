import { PickerRootProps } from 'react-mobile-picker/dist/components/Picker';
import { PickerVariants } from './types';
import { PickerItemLabelStyled, PickerStyled } from './styled';

type MobileScrollPickerProps = PickerRootProps<Record<string, string | number>> & {
  variant?: PickerVariants;
  selections: Record<string, Array<string>>;
};

const MobileScrollPicker = ({
  value,
  onChange,
  selections,
  variant = PickerVariants.DEFAULT,
}: MobileScrollPickerProps) => {
  return (
    <PickerStyled
      wheelMode="natural"
      height={variant === PickerVariants.CYLINDER ? 150 : 180}
      itemHeight={variant === PickerVariants.CYLINDER ? 30 : 57}
      value={value}
      onChange={onChange}
    >
      {Object.keys(selections).map((name) => (
        <PickerStyled.Column key={name} name={name}>
          {selections[name as keyof typeof selections].map((option) => (
            <PickerStyled.Item key={option} value={option}>
              {({ selected }) => (
                <PickerItemLabelStyled selected={selected} variant={variant}>
                  {option}
                </PickerItemLabelStyled>
              )}
            </PickerStyled.Item>
          ))}
        </PickerStyled.Column>
      ))}
    </PickerStyled>
  );
};

export default MobileScrollPicker;
