import { InputHTMLAttributes } from 'react';
import AspectRatio from 'core/common/components/AspectRatio';
import { FunnelImage } from 'core/funnel/entities';
import {
  RadioButtonStyled,
  ImageStyled,
  CardBodyStyled,
  TitleStyled,
  CheckmarkStyled,
  IconStyled,
  SubtitleStyled,
  DividerStyled,
  BillingBlockStyled,
  PriceStyled,
  OldPriceStyled,
  BillingCaptionStyled,
  DiscountStyled,
  PricesWrapperStyled,
  ListStyled,
  ListItemStyled,
} from './styled';

type ProductCardRadioButtonDesktopProps = InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  price: string;
  subtitle: string;
  oldPrice: string;
  discount: string;
  billingCaption: string;
  details: Array<string>;
  backgroundImage?: FunnelImage;
};

const ProductCardRadioButtonDesktop = ({
  title,
  price,
  details,
  subtitle,
  oldPrice,
  discount,
  billingCaption,
  backgroundImage,
  ...inputProps
}: ProductCardRadioButtonDesktopProps) => {
  return (
    <RadioButtonStyled {...inputProps}>
      {backgroundImage && (
        <AspectRatio width={273} height={100}>
          <ImageStyled src={backgroundImage.src} alt={backgroundImage.alt} fill />
        </AspectRatio>
      )}
      <CardBodyStyled>
        <TitleStyled>
          <CheckmarkStyled>
            <IconStyled name="check" />
          </CheckmarkStyled>
          {title}
        </TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <DividerStyled />
        <BillingBlockStyled>
          <PricesWrapperStyled>
            <PriceStyled>{price}</PriceStyled>
            <OldPriceStyled>{oldPrice}</OldPriceStyled>
            <DiscountStyled>{discount}</DiscountStyled>
          </PricesWrapperStyled>
          <BillingCaptionStyled>{billingCaption}</BillingCaptionStyled>
        </BillingBlockStyled>
        <ListStyled>
          {details.map((item) => (
            <ListItemStyled key={item}>{item}</ListItemStyled>
          ))}
        </ListStyled>
      </CardBodyStyled>
    </RadioButtonStyled>
  );
};

export default ProductCardRadioButtonDesktop;
