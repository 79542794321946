import { forwardRef } from 'react';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';
import {
  CurrencySymbol,
  europeanCountries,
  Locales,
  notPaidUtmSources,
  paidUtmSources,
} from 'core/user/entities';
import { LocationOption, Location } from 'core/user/ui';
import {
  ActionButton,
  BodyHeader,
  BodyHeaderPrice,
  BodyHeaderTitle,
  ButtonWrapper,
  CardBody,
  CardHeader,
  CardWrapper,
  DiscountBlock,
  GuaranteeBlock,
  GuaranteeIconStyled,
  GuaranteeText,
  HeaderTitle,
  HorizontalLine,
  SubscriptionActualPrice,
  SubscriptionBlock,
  SubscriptionDescription,
  SubscriptionOldPrice,
  SubscriptionPricesBlock,
  TotalBlock,
  TotalPrice,
  TotalTitle,
} from './styled';

export type SpecialOfferProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['specialOffer'];
  id?: string;
  timeLeft: number;
  onGetReport: () => void;
  currencySymbol: CurrencySymbol;
  discountAmount: number;
  fullPlan: number;
  discountPlan: number;
  trialPrice: number;
  trialPriceWithoutVAT: number;
};

const SectionSpecialOffer = forwardRef<HTMLDivElement, SpecialOfferProps>(
  (
    {
      content,
      id,
      timeLeft,
      currencySymbol,
      onGetReport,
      fullPlan,
      discountPlan,
      trialPrice,
    }: SpecialOfferProps,
    ref,
  ) => {
    const { header, title, totalText, costText, costTextOrganic, button, guranteeText } = content;

    const isTimeUp = timeLeft <= 0;
    const offerZeroPrice = 0;

    return (
      <>
        <CardWrapper id={id}>
          <CardHeader>
            <HeaderTitle>{header}</HeaderTitle>
          </CardHeader>
          <CardBody>
            <BodyHeader>
              <BodyHeaderTitle>{title}</BodyHeaderTitle>
              <Location>
                <LocationOption option={[...europeanCountries]}>
                  <BodyHeaderPrice>
                    {currencySymbol}
                    {trialPrice}
                  </BodyHeaderPrice>
                </LocationOption>
                <LocationOption option={Locales.OTHER}>
                  <BodyHeaderPrice>
                    {currencySymbol}
                    {trialPrice}
                  </BodyHeaderPrice>
                </LocationOption>
              </Location>
            </BodyHeader>
            <TotalBlock>
              <TotalTitle>{totalText}</TotalTitle>
              <Location>
                <LocationOption option={[...europeanCountries]}>
                  <TotalPrice>
                    {currencySymbol}
                    {trialPrice}
                  </TotalPrice>
                </LocationOption>
                <LocationOption option={Locales.OTHER}>
                  <TotalPrice>
                    {currencySymbol}
                    {trialPrice}
                  </TotalPrice>
                </LocationOption>
              </Location>
            </TotalBlock>
            <HorizontalLine />
            <DiscountBlock></DiscountBlock>
            <SubscriptionBlock>
              <MediaSourceVariant name={paidUtmSources}>
                <SubscriptionDescription>{costText}</SubscriptionDescription>
                <SubscriptionPricesBlock>
                  <SubscriptionOldPrice>
                    {isTimeUp ? (
                      <>
                        {currencySymbol}
                        {fullPlan}
                      </>
                    ) : (
                      <>
                        {currencySymbol}
                        {discountPlan}
                      </>
                    )}
                  </SubscriptionOldPrice>
                  <SubscriptionActualPrice>
                    {currencySymbol}
                    {offerZeroPrice}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
              <MediaSourceVariant name={notPaidUtmSources}>
                <SubscriptionDescription dangerouslySetInnerHTML={prepareHtml(costTextOrganic)} />
                <SubscriptionPricesBlock>
                  <SubscriptionOldPrice>
                    {!isTimeUp && (
                      <>
                        {currencySymbol}
                        {fullPlan}
                      </>
                    )}
                  </SubscriptionOldPrice>
                  <SubscriptionActualPrice>
                    {isTimeUp ? (
                      <>
                        {currencySymbol}
                        {fullPlan}
                      </>
                    ) : (
                      <>
                        {currencySymbol}
                        {discountPlan}
                      </>
                    )}
                  </SubscriptionActualPrice>
                </SubscriptionPricesBlock>
              </MediaSourceVariant>
            </SubscriptionBlock>
          </CardBody>
        </CardWrapper>
        <ButtonWrapper ref={ref}>
          <ActionButton impulseScale onClick={onGetReport}>
            {button}
          </ActionButton>
        </ButtonWrapper>
        <GuaranteeBlock>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeText>{guranteeText}</GuaranteeText>
        </GuaranteeBlock>
      </>
    );
  },
);

export default SectionSpecialOffer;
