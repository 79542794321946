import UserAnswerScreen from 'core/common/components/QuizScreens/UserAnswer';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { UserAnswerScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

export type UserAnswerProps = {
  id: string;
};

const UserAnswer = ({ id }: UserAnswerProps) => {
  const { screenAttributes, back, next, userPreferences, step, updateUserPreferences } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as UserAnswerScreenAttributes;

  const initialValue = (userPreferences[dataKey as keyof UserProfile] as string) ?? '';

  const handleSubmit = async (userAnswer: string) => {
    updateUserPreferences(dataKey, userAnswer);
    next();
  };

  return (
    <UserAnswerScreen
      onBack={back}
      onSubmit={handleSubmit}
      onSkip={next}
      initialValue={initialValue}
      step={step}
      {...(screenAttributes as UserAnswerScreenAttributes)}
    />
  );
};

export default UserAnswer;
