import nebulaLight from 'core/common/assets/images/nebulaLight.png';
import BackgroundGradient from 'core/common/components/BackgroundGradient';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { OnboardingPalmistryScreenProps } from 'core/funnel/entities';
import { Container, Logo, LogoWrapper, Text } from './styled';

const OnboardingPalmistryScreen = ({ content }: OnboardingPalmistryScreenProps) => {
  return (
    <>
      <BackgroundGradient />
      <Container>
        <LogoWrapper>
          <Logo src={nebulaLight} quality={100} height={187} width={187} priority alt="" />
        </LogoWrapper>
        {content.list.map((item, idx) => (
          <Text key={item} value={idx} dangerouslySetInnerHTML={prepareHtml(item)} />
        ))}
      </Container>
    </>
  );
};

export default OnboardingPalmistryScreen;
