import correctScan from 'core/common/assets/images/palmistryQuiz/correct_scan.png';
import wrongScan from 'core/common/assets/images/palmistryQuiz/wrong_scan.png';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import ProgressBar from 'core/common/components/ProgressBarPalmistry';
import { ScanScreenProps } from 'core/funnel/entities';
import { PopUpTypes } from 'core/popUp/entities';
import { usePopUps } from 'core/popUp/hooks';
import CameraPermissionsAlert from '../../components/CameraPermissionsAlert';
import ScanProcessPopUps from '../../components/ScanProcessPopUps';
import {
  Button,
  Container,
  Guarantee,
  GuaranteeContent,
  GuaranteeIconStyled,
  StyledTitle,
  WrongScanImage,
} from './styled';

const ScanScreen = ({ onBack, goNext, step, content, totalSteps }: ScanScreenProps) => {
  const { activePopUpType, openScanProcessPopUp, closePopUp } = usePopUps();

  const { title, take, guarantee, hidePermissionImage } = content;

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      <Container>
        <StyledTitle>{title}</StyledTitle>
        <Image src={correctScan} width={320} height={190} alt="example of correctly scanned palm" />
        <WrongScanImage
          src={wrongScan}
          width={320}
          height={110}
          alt="example of incorrectly scanned palm"
        />
        <Button onClick={openScanProcessPopUp}>{take}</Button>
        <Guarantee>
          <GuaranteeIconStyled height={24} width={24} name="guarantee" />
          <GuaranteeContent>{guarantee}</GuaranteeContent>
        </Guarantee>
        <ScanProcessPopUps
          isOpen={activePopUpType === PopUpTypes.SCAN_PROCESS}
          goNext={goNext}
          content={content}
          onClosePopUp={closePopUp}
        />
        <CameraPermissionsAlert
          isOpen={activePopUpType === PopUpTypes.CAMERA_ACCESS_ALERT}
          onClose={closePopUp}
          onConfirm={closePopUp}
          hidePermissionImage={hidePermissionImage}
        />
      </Container>
    </>
  );
};

export default ScanScreen;
