import { prepareHtml } from 'core/common/utils/prepareHtml';
import { Offer } from 'core/offers/entities';
import {
  OldPriceStyled,
  PriceStyled,
  TextStyled,
  WrapperStyled,
  TitleStyled,
  TitleWrapperStyled,
} from './styled';

type DiscountProps = {
  offer: Offer;
  specialOffer: {
    title: string;
    subTitle: string;
    prevPrice: string;
  };
};

const Discount = ({ offer, specialOffer }: DiscountProps) => {
  if (!specialOffer || !offer) return null;

  const price = offer.pricesWithCurrencySymbol?.trialPrice;
  const currency = offer.currencySymbol;

  return (
    <WrapperStyled>
      <TitleWrapperStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(specialOffer.title)} />
        <PriceStyled>{price}</PriceStyled>
      </TitleWrapperStyled>
      <TextStyled>
        {specialOffer.subTitle}{' '}
        <OldPriceStyled>
          {currency}
          {specialOffer.prevPrice}
        </OldPriceStyled>
      </TextStyled>
    </WrapperStyled>
  );
};

export default Discount;
