import { useState } from 'react';
import fallingStarImage from 'core/common/assets/images/humanDesign/fallingStar.png';
import lightningImage from 'core/common/assets/images/humanDesign/lightning.png';
import scalesImage from 'core/common/assets/images/humanDesign/scales.png';
import starsImage from 'core/common/assets/images/humanDesign/stars.png';
import sunImage from 'core/common/assets/images/humanDesign/sun.png';
import tornadoImage from 'core/common/assets/images/humanDesign/tornado.png';
import QuizBackground from 'core/common/components/QuizBackground';
import { SECOND } from 'core/common/constants';
import { useInView } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentHumanDesignScreenProps } from 'core/funnel/entities';
import RenderOnlyOnClient from '../../RenderOnlyOnClient';
import CollapsibleSection from './CollapsibleSection';
import Disclaimer from './Disclaimer';
import Footer from './Footer';
import Guarantee from './Guarantee';
import Header from './Header';
import HeroSection from './HeroSection';
import HumanDesignDetails from './HumanDesignDetails';
import { DesignDataListItemStyled, DesignDataListStyled } from './HumanDesignDetails/styled';
import LifeStrategy from './LifeStrategy';
import PageLoader from './PageLoader';
import Partners from './Partners';
import Reviews from './Reviews';
import SectionPayment from './SectionPayment';
import SpecialOffer from './SpecialOffer';
import {
  ContentStyled,
  FloatingButtonWrapperStyled,
  FloatingCTAButtonStyled,
  RootStyled,
} from './styled';

const headerHeight = 48;
const offerScrollOffset = -140;
const animationDurationInSec = 2;
const hideLoaderDelayInSec = 3.5;
const offerBlockId = 'offer-block';
const topPageBlockId = 'top-page-block';

const collapsibleSectionsImages = [
  sunImage,
  starsImage,
  scalesImage,
  lightningImage,
  tornadoImage,
  fallingStarImage,
];

const TrialPaymentHumanDesignScreen = ({
  content,
  timeLeft,
  offer,
  report,
  timeUpOffer,
  getReport,
  goNext,
}: TrialPaymentHumanDesignScreenProps) => {
  const {
    header,
    heroSection,
    userProfile,
    ctaButtonText,
    specialOffer,
    lifeStrategy,
    reviews,
    partners,
    moneyBackGuarantee,
    footer,
    loaderText,
  } = content;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(true);

  const { ref: paymentButtonRef, inView: isPaymentButtonInView } = useInView({
    threshold: 0,
  });

  const onGetReport = () => {
    setIsPaymentModalOpen(true);
    getReport();
  };

  const moveToOffer = () => {
    scrollToBlock(offerBlockId, offerScrollOffset);
  };

  const onPaymentDialogClose = () => {
    setIsPaymentModalOpen(false);
  };

  // Iframe scrolling bug fix
  const onChartLoaded = () => {
    setTimeout(() => {
      scrollToBlock(topPageBlockId);
    }, animationDurationInSec * SECOND);

    setTimeout(() => {
      setShouldDisplayLoader(false);
    }, (animationDurationInSec + hideLoaderDelayInSec) * SECOND);
  };

  const isTimeUp = timeLeft <= 0;

  if (!timeUpOffer || !offer || !report) {
    return null;
  }

  return (
    <RootStyled id={topPageBlockId}>
      <QuizBackground variant="semiLight" />
      {shouldDisplayLoader && (
        <PageLoader
          animationDurationInSec={animationDurationInSec}
          hideLoaderDelayInSec={hideLoaderDelayInSec}
          text={loaderText}
        />
      )}
      <Header
        height={headerHeight}
        discountValue={header.discountValue}
        discountExpiresIn={header.discountExpiresIn}
        timeLeft={timeLeft}
      />
      <ContentStyled offsetTop={headerHeight}>
        <HeroSection
          title={heroSection.title}
          text={heroSection.text}
          rating={heroSection.rating}
        />
        <HumanDesignDetails
          {...userProfile}
          chartUrl={report.chartUrl}
          subtitle={report.chartData.type.option}
          onChartLoaded={onChartLoaded}
        />
        <DesignDataListStyled>
          {Object.values(report.chartData).map(({ id, name, option, description }, idx) => (
            <DesignDataListItemStyled key={id}>
              <CollapsibleSection
                caption={name}
                title={option}
                text={description}
                button={ctaButtonText}
                onMoveToOffer={moveToOffer}
                image={collapsibleSectionsImages[idx]}
              />
            </DesignDataListItemStyled>
          ))}
        </DesignDataListStyled>
        <SpecialOffer
          specialOffer={specialOffer}
          id={offerBlockId}
          offer={offer}
          timeUpOffer={timeUpOffer}
          onGetReport={onGetReport}
          timeLeft={timeLeft}
          ref={paymentButtonRef}
        />
        <Disclaimer
          displayTrialPrice={offer.pricesWithCurrencySymbol.trialPriceWithVAT}
          displayPlanPrice={
            isTimeUp
              ? timeUpOffer.pricesWithCurrencySymbol.priceWithVAT
              : offer.pricesWithCurrencySymbol.priceWithVAT
          }
        />
        <LifeStrategy
          title={lifeStrategy.title}
          withStrategy={lifeStrategy.withStrategy}
          withoutStrategy={lifeStrategy.withoutStrategy}
          button={lifeStrategy.button}
          onMoveToOffer={moveToOffer}
        />
        <Reviews title={reviews.title} list={reviews.list} />
        <Partners title={partners.title} />
        <Guarantee title={moneyBackGuarantee.title} description={moneyBackGuarantee.description} />
        <Footer {...footer} />
        {!isPaymentButtonInView && (
          <FloatingButtonWrapperStyled>
            <FloatingCTAButtonStyled onClick={moveToOffer}>{ctaButtonText}</FloatingCTAButtonStyled>
          </FloatingButtonWrapperStyled>
        )}
      </ContentStyled>
      <RenderOnlyOnClient>
        <SectionPayment
          onClose={onPaymentDialogClose}
          onPaymentSuccess={goNext}
          isTimeUp={isTimeUp}
          isOpen={isPaymentModalOpen}
          offer={offer}
        />
      </RenderOnlyOnClient>
    </RootStyled>
  );
};

export default TrialPaymentHumanDesignScreen;
