import guarantee from 'core/common/assets/images/money-back-guarantee-large.svg';
import { BASE_ROUTES } from 'core/common/constants';
import {
  GuaranteeBadgeStyled,
  GuaranteeIconStyled,
  GuaranteeImageStyled,
  LinkStyled,
  TextStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

type MoneyBackProps = {
  text: string;
  title: string;
  linkText: string;
  linkHref?: string;
  className?: string;
  textInscription?: string;
};

const MoneyBack = ({
  text,
  title,
  linkText,
  linkHref = BASE_ROUTES.TECH_MONEY_BACK_POLICY,
  className,
  textInscription,
}: MoneyBackProps) => (
  <div className={className}>
    <WrapperStyled>
      <GuaranteeImageStyled alt="guarantee" src={guarantee} width={55} height={70} />
      <TitleStyled>{title}</TitleStyled>
      <TextStyled>
        {text} <LinkStyled href={linkHref}>{linkText}</LinkStyled>.
      </TextStyled>
    </WrapperStyled>

    {textInscription && (
      <GuaranteeBadgeStyled>
        <GuaranteeIconStyled name="guarantee" width={24} height={24} />
        {textInscription}
      </GuaranteeBadgeStyled>
    )}
  </div>
);

export default MoneyBack;
