import PlaceHumanDesignScreen from 'core/common/components/QuizScreens/PlaceHumanDesign';
import { useServices } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { PlaceHumanDesignScreenAttributes } from 'core/funnel/entities';
import { useHumanDesign } from 'core/human-design/hooks';
import { UserProfile } from 'core/user/entities';

type PlaceHumanDesignProps = {
  id: string;
};

const PlaceHumanDesign = ({ id }: PlaceHumanDesignProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);
  const { autocompleteService } = useServices();
  const { fetchHumanDesignReport } = useHumanDesign();

  const { locationDataKey, dataKey } = screenAttributes as PlaceHumanDesignScreenAttributes;

  const initialLocation =
    typeof userPreferences[locationDataKey as keyof UserProfile] === 'string'
      ? (userPreferences[locationDataKey as keyof UserProfile] as string)
      : '';

  const initialCoordinates = Array.isArray(userPreferences[dataKey as keyof UserProfile])
    ? (userPreferences[dataKey as keyof UserProfile] as Array<number>)
    : [];

  const handleSubmit = async (coordinates: number[], location: string) => {
    updateUserPreferences(locationDataKey, location);
    updateUserPreferences(dataKey, coordinates);

    fetchHumanDesignReport();

    next();
  };

  const getAvailableLocations = async (value: string) => {
    return await autocompleteService.getFeatures(value);
  };

  return (
    <PlaceHumanDesignScreen
      onBack={back}
      onSubmit={handleSubmit}
      getAvailableLocations={getAvailableLocations}
      initialLocation={initialLocation}
      initialCoordinates={initialCoordinates}
      step={step}
      {...(screenAttributes as PlaceHumanDesignScreenAttributes)}
    />
  );
};

export default PlaceHumanDesign;
