import { memo, useMemo } from 'react';
import { greatestBirthYear } from 'core/common/constants';
import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { generateDates, generateMonthDays } from 'core/common/utils/generateDates';
import { CylinderDatePicker } from './CylinderDatePicker';
import { DefaultDatePicker } from './DefaultDatePicker';
import { PickerVariants, DateObject } from './types';

export type WheelDatePickerProps = {
  onChange: (value: Partial<DateObject>) => void;
  className?: string;
  variant: PickerVariants;
  date: DateObject;
};

const yearsList = generateDates(1940, greatestBirthYear, 'increment');

export const WheelDatePicker = memo(
  ({ onChange, className, variant, date }: WheelDatePickerProps) => {
    const { month, year } = date;

    const daysList = useMemo(() => {
      return generateMonthDays(month, year).map(Number).map(addLeadingZero);
    }, [month, year]);

    const DatePicker = variant === PickerVariants.DEFAULT ? DefaultDatePicker : CylinderDatePicker;

    return (
      <DatePicker
        onChange={onChange}
        className={className}
        daysList={daysList}
        yearsList={yearsList}
        date={date}
      />
    );
  },
);
