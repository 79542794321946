import { useMemo } from 'react';
import wingDefaultImage from 'core/common/assets/images/quiz/greyWing.png';
import wingGradientImage from 'core/common/assets/images/wing-gradient.png';
import { getAppRatingPercents } from 'core/common/utils/getAppRatingPercents';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionNebulaRatingAttributes } from 'core/funnel/entities';
import {
  RatingStyled,
  RatingWrapperStyled,
  StarsWrapperStyled,
  TitleStyled,
  WingImageReveresedStyled,
  WingImageStyled,
} from './styled';

type SectionNebulaRatingProps = {
  content: SectionNebulaRatingAttributes['content'];
  variant?: 'light' | 'dark';
  className?: string;
};

const wingImageMap: Record<'light' | 'dark', string> = {
  dark: wingGradientImage,
  light: wingDefaultImage,
};

const SectionNebulaRating = ({
  content,
  variant = 'light',
  className,
}: SectionNebulaRatingProps) => {
  const { rating, text } = content;

  const ratingPercents = useMemo(() => getAppRatingPercents(rating), [rating]);

  const wingImage = wingImageMap[variant];

  return (
    <RatingWrapperStyled variant={variant} className={className}>
      <WingImageStyled
        src={wingImage}
        width={30}
        height={68}
        alt="left part of the laurel wreath"
      />
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(text)} />
      <StarsWrapperStyled>
        <RatingStyled percent={ratingPercents} />
        {rating}
      </StarsWrapperStyled>
      <WingImageReveresedStyled
        src={wingImage}
        width={30}
        height={68}
        alt="right part of the laurel wreath"
      />
    </RatingWrapperStyled>
  );
};

export default SectionNebulaRating;
