import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';
import TitleComponent from 'core/common/components/Title';
import Icon from 'core/svg-sprites/ui/Icon';

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const Image = styled(ImageComponent)`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const Title = styled(TitleComponent)`
  margin-bottom: 8px;
  font-size: 22px;
  text-align: left;
`;

export const Text = styled.p`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 135%;
  color: ${(props) => props.theme.colors.typography600};
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  margin-top: 52px;
  margin-bottom: 32px;
  border-radius: 12px;
  font-size: 18px;
  line-height: 21px;
  box-shadow: 0 12px 23px rgba(84, 60, 151, 0.31);
`;

export const Guarantee = styled.div`
  display: flex;
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;

export const GuaranteeContent = styled.p`
  margin-left: 8px;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.typography600};
`;
