import { floorPercent } from 'core/common/utils/floorPercents';
import {
  CircularProgressStyled,
  TextStyled,
  ProgressTrackStyled,
  ProgressBarStyled,
} from './styled';

type CircularProgressV2Props = {
  progress: number;
  size?: number;
  className?: string;
};

const defaultSizePx = 160;
const defaultStrokeWidthPx = 12;

const CircularProgressV2 = ({
  progress,
  size = defaultSizePx,
  className,
}: CircularProgressV2Props) => {
  const strokeWidth = (size / defaultSizePx) * defaultStrokeWidthPx;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <CircularProgressStyled
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <ProgressTrackStyled
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeWidth={`${strokeWidth}px`}
        strokeDasharray={circumference}
        strokeDashoffset="0"
      />
      <ProgressBarStyled
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeWidth={`${strokeWidth}px`}
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      <TextStyled
        x="50%"
        y="50%"
        textAnchor="middle"
        alignmentBaseline="middle"
        transform={`rotate(90 ${size / 2} ${size / 2})`}
      >
        {floorPercent(progress)}%
      </TextStyled>
    </CircularProgressStyled>
  );
};

export default CircularProgressV2;
