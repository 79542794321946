import styled from 'styled-components';

export const HeaderStyled = styled.header<{ height: number }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height}px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${({ theme }) => theme.colors.vibrant900};
`;

export const HeaderDiscountStyled = styled.p`
  font-size: 12px;
`;

export const HeaderDiscountValueStyled = styled.span`
  font-weight: 700;
`;
