import { useState } from 'react';
import LimitedChoiceScreen from 'core/common/components/QuizScreens/LimitedChoice';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { LimitedChoiceScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type LimitedChoiceProps = {
  id: string;
};

const LimitedChoice = ({ id }: LimitedChoiceProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step, userPreferences } =
    useQuizStep(id);

  const { dataKey } = screenAttributes as LimitedChoiceScreenAttributes;

  const answers = userPreferences[id as keyof UserProfile] as string[];

  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(answers || []);

  const maximalAnswersAmount = 3;
  const isMaximalAnswersAmount = selectedAnswers.length === maximalAnswersAmount;

  const getIsAnswerChoosen = (option: string) => {
    return selectedAnswers.some((item) => item === option);
  };

  const onAnswerClick = (answer: string) => {
    if (isMaximalAnswersAmount && !getIsAnswerChoosen(answer)) return;

    if (getIsAnswerChoosen(answer)) {
      setSelectedAnswers((value: string[]) => value.filter((item) => item !== answer));
    } else {
      setSelectedAnswers((value: string[]) => [...value, answer]);
    }
  };

  const onContinue = async () => {
    updateUserPreferences(dataKey, selectedAnswers);
    const events = selectedAnswers.map((item) => item || item);
    await next({ value: events });
  };

  return (
    <LimitedChoiceScreen
      onAnswerClick={onAnswerClick}
      onContinue={onContinue}
      onBack={back}
      chosenList={selectedAnswers}
      getIsAnswerChoosen={getIsAnswerChoosen}
      step={step}
      {...(screenAttributes as LimitedChoiceScreenAttributes)}
    />
  );
};

export default LimitedChoice;
