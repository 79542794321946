import { HTMLAttributes, useMemo, useRef, useState, MouseEvent } from 'react';
import cn from 'classnames';
import { Country, getCountryCallingCode } from 'react-phone-number-input';
import internationalIconSrc from 'core/common/assets/images/international.svg';
import DropdownMenu, { DropdownMenuProps } from 'core/common/components/DropdownMenu';
import Image from 'core/common/components/Image';
import { useUpdateEffect } from 'core/common/hooks';
import PhoneCountryFlag from '../PhoneCountryFlag/PhoneCountryFlag';
import { CountryCallingCode, CountryMenuItem, CountryOptionWrapper, FlagWrapper } from './styled';

export type PhoneFieldSelectProps = {
  value: Country;
  options: Array<{ value: Country; label: string }>;
  onChange: (value: Country) => void;
  menuProps?: Partial<DropdownMenuProps>;
  toggleProps?: HTMLAttributes<HTMLDivElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
};

const PhoneFieldSelect = (props: PhoneFieldSelectProps) => {
  const {
    value,
    options,
    onChange,
    menuProps = {},
    className,
    onFocus,
    onBlur,
    readOnly,
    disabled,
    toggleProps = {},
  } = props;

  const toggleRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const getFormattedCountryCode = (code?: Country) => {
    if (!code) return '';

    return `+${getCountryCallingCode(code)}`;
  };

  const countryOptions = useMemo(() => {
    return options.reduce(
      (
        acc: Array<{
          code: Country;
          callingCode: string;
          name: string;
        }>,
        option,
      ) => {
        if (!option || !option.value) return acc;

        acc.push({
          code: option.value,
          callingCode: getFormattedCountryCode(option.value),
          name: option.label,
        });

        return acc;
      },
      [],
    );
  }, [options]);

  const selectedOption = useMemo(() => {
    return countryOptions.find((option) => option.code === value);
  }, [value, countryOptions]);

  useUpdateEffect(() => {
    if (isDropdownVisible && onFocus) {
      onFocus();
      return;
    }

    if (onBlur) {
      onBlur();
    }
  }, [isDropdownVisible]);

  const onToggle = () => {
    if (readOnly || disabled) return;

    setIsDropdownVisible((v) => !v);
  };

  const onClose = () => {
    setIsDropdownVisible(false);
  };

  const handleSelect = (event: MouseEvent, countryCode: Country) => {
    event.stopPropagation();

    setIsDropdownVisible(false);
    onChange(countryCode);
  };

  return (
    <>
      <FlagWrapper
        {...toggleProps}
        className={cn(toggleProps.className, className)}
        ref={toggleRef}
        role="button"
        onClick={onToggle}
      >
        {selectedOption ? (
          <>
            <PhoneCountryFlag className="countryFlag" code={selectedOption.code} />
            {getFormattedCountryCode(value)}
          </>
        ) : (
          <Image width={24} height={24} src={internationalIconSrc} alt={`International flag`} />
        )}
      </FlagWrapper>
      <DropdownMenu
        open={isDropdownVisible}
        anchorEl={toggleRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={onClose}
        classes={{
          list: cn('countryMenu', menuProps.className),
          paper: cn('countryMenuWrapper', menuProps.PaperProps?.className),
        }}
      >
        {countryOptions.map((option) => (
          <CountryMenuItem key={option.code} onClick={(event) => handleSelect(event, option.code)}>
            <CountryOptionWrapper>
              <PhoneCountryFlag className="countryFlag" code={option.code} />
              <CountryCallingCode>{option.callingCode}</CountryCallingCode>
              <span>{option.name}</span>
            </CountryOptionWrapper>
          </CountryMenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};

export default PhoneFieldSelect;
