import { useState } from 'react';
import Address from 'core/common/components/Address';
import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import PolicyExtended from 'core/common/components/PolicyExtended';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import SquareButton from 'core/common/components/SquareButton';
import { AnswerHorizontalOrientationV2ScreenProps } from 'core/funnel/entities';
import { Locales, Regions, paidUtmSources, notPaidUtmSources } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  ContainerStyled,
  ContentStyled,
  IconImageStyled,
  BackgroundImageStyled,
  BackgroundImageDesktopStyled,
  ListStyled,
  ListItemStyled,
  TitleStyled,
} from './styled';

const AnswerHorizontalOrientationV2Screen = ({
  onAnswerClick,
  onBack,
  onPolicyClick,
  isPolicyAccepted,
  displayPolicyError,
  step,
  content,
  totalSteps,
  itemsInRow,
  hasPolicy,
}: AnswerHorizontalOrientationV2ScreenProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState('');

  const getIsAnswerChosen = (option: string) => {
    return option === selectedAnswer;
  };

  const handleClickOnAnswer = (value: string) => {
    setSelectedAnswer(value);
    onAnswerClick(value);
  };

  return (
    <>
      <Header buttonBack={false} />
      <ProgressBar
        title={content.progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <ContainerStyled>
        <TitleStyled dangerouslyInnerHTML={content.title} />
        <ContentStyled>
          <ListStyled itemsInRow={itemsInRow}>
            {content.answers.map((answer) => (
              <ListItemStyled key={answer.value}>
                <SquareButton
                  onClick={() => handleClickOnAnswer(answer.value)}
                  icon={
                    answer.icon && (
                      <IconImageStyled
                        src={answer.icon.src}
                        alt={answer.icon.alt}
                        width={48}
                        height={48}
                      />
                    )
                  }
                  isChoosen={getIsAnswerChosen(answer.text)}
                >
                  {answer.text}
                </SquareButton>
              </ListItemStyled>
            ))}
          </ListStyled>
          {hasPolicy && (
            <>
              {/* Terms for paid trafic (start) */}
              <MediaSourceVariant name={paidUtmSources}>
                <LocationRegion>
                  <LocationRegionOption option={Regions.CALIFORNIA}>
                    <PolicyExtended
                      isPolicyAccepted={isPolicyAccepted}
                      onPolicyClick={onPolicyClick}
                      withoutCheckbox
                    />
                    <Address />
                  </LocationRegionOption>
                </LocationRegion>
              </MediaSourceVariant>
              {/* Terms for paid trafic (end) */}
              {/* Terms for organic trafic (start) */}
              <MediaSourceVariant name={notPaidUtmSources}>
                <Location>
                  <LocationOption option={Locales.US}>
                    <PolicyExtended
                      isPolicyAccepted={isPolicyAccepted}
                      onPolicyClick={onPolicyClick}
                      isError={displayPolicyError}
                    />
                    <Address />
                  </LocationOption>
                </Location>
              </MediaSourceVariant>
              {/* Terms for organic trafic (end) */}
            </>
          )}
        </ContentStyled>
      </ContainerStyled>
      {content.backgroundImage && (
        <>
          <BackgroundImageStyled
            src={content.backgroundImage.mobileImage.src}
            alt={content.backgroundImage.mobileImage.alt}
            width={297}
            height={297}
            priority
          />
          <BackgroundImageDesktopStyled
            src={content.backgroundImage.desktopImage.src}
            alt={content.backgroundImage.desktopImage.alt}
            width={599}
            height={599}
            priority
          />
        </>
      )}
    </>
  );
};

export default AnswerHorizontalOrientationV2Screen;
