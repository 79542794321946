import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

export const ScreenBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: ${({ theme }) => theme.colors.secondary800};
`;

export const ContainerStyled = styled.article`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ImageStyled = styled(Image)`
  width: 100%;
  margin-bottom: 8px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const PromotionListWrapperStyled = styled.div`
  width: 100%;
`;

export const PromotionListTitleStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const PromotionListStyled = styled.ul`
  position: relative;
  width: 100%;
  padding: 16px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.secondary700};
`;

export const PromotionListItemStyled = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const PromotionListItemBubbleStyled = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary600};
`;

export const PromotionListItemTextStyled = styled.p`
  font-size: 16px;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const EmojiStyled = styled.li`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 48px;
  line-height: 33%;
`;

export const PromotionButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 10px 50px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  background: ${({ theme }) => theme.gradients.secondaryLight};
`;
