import { ListItemContent } from '../Profile';
import {
  ListStyled,
  ListItemStyled,
  ListIconStyled,
  ListItemTitleStyled,
  ListItemValueStyled,
  ListItemTextWrapperStyled,
} from './styled';

type UserDataProps = {
  birthDate?: ListItemContent;
  birthTime: ListItemContent;
  birthPlace: ListItemContent;
};

const UserData = ({ birthDate, birthTime, birthPlace }: UserDataProps) => {
  const getFormattedCity = (location: string) => {
    return location.split(',')[0];
  };

  const birthTimeValue = birthTime.value || '00:00:00';

  return (
    <ListStyled>
      {birthDate && (
        <ListItemStyled>
          <ListIconStyled name={birthDate.icon} width={24} height={24} />
          <ListItemTextWrapperStyled>
            <ListItemTitleStyled>{birthDate.title}</ListItemTitleStyled>
            <ListItemValueStyled>{birthDate.value}</ListItemValueStyled>
          </ListItemTextWrapperStyled>
        </ListItemStyled>
      )}
      <ListItemStyled>
        <ListIconStyled name={birthTime.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled>{birthTime.title}</ListItemTitleStyled>
          <ListItemValueStyled>{birthTimeValue}</ListItemValueStyled>
        </ListItemTextWrapperStyled>
      </ListItemStyled>
      <ListItemStyled>
        <ListIconStyled name={birthPlace.icon} width={24} height={24} />
        <ListItemTextWrapperStyled>
          <ListItemTitleStyled>{birthPlace.title}</ListItemTitleStyled>
          <ListItemValueStyled>{getFormattedCity(birthPlace.value)}</ListItemValueStyled>
        </ListItemTextWrapperStyled>
      </ListItemStyled>
    </ListStyled>
  );
};

export default UserData;
