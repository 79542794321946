import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { BoldStyled, BreakStyled, ContentStyled, DescriptionStyled } from './styled';

export type SectionPaymentProps = {
  isTimeUp: boolean;
  isOpen: boolean;
  offer: Offer;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const trialPeriod = '7-day';

const SectionPayment = (props: SectionPaymentProps) => {
  const { onClose, isTimeUp, onPaymentSuccess, offer, isOpen } = props;
  const { domainUrl } = useConfig();

  if (!offer) {
    return null;
  }

  return (
    <>
      <PaymentDialog
        productId={offer.offerId}
        currency={offer.currency}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
      >
        <ContentStyled>
          {isTimeUp ? (
            <Location>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {trialPeriod} trial.
                  </BoldStyled>{' '}
                  The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {trialPeriod} trial.
                  </BoldStyled>
                </DescriptionStyled>
              </LocationOption>
            </Location>
          ) : (
            <Location>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {offer.trialPeriod}{' '}
                    trial.
                  </BoldStyled>
                  <BreakStyled />
                  We’ll <BoldStyled>email you a reminder</BoldStyled> before your trial period ends.
                  Cancel anytime. The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  You will be charged only{' '}
                  <BoldStyled>
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT} for your {offer.trialPeriod}{' '}
                    trial.
                  </BoldStyled>
                  <BreakStyled />
                  We’ll <BoldStyled>email you a reminder</BoldStyled> before your trial period ends.
                  Cancel anytime.
                </DescriptionStyled>
              </LocationOption>
            </Location>
          )}
        </ContentStyled>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
