import styled from 'styled-components';
import SectionDeckSlider from 'core/common/components/QuizScreensSections/SectionDeckSlider';
import SectionFaqV2 from 'core/common/components/QuizScreensSections/SectionFaqV2';
import SectionGuarantee from 'core/common/components/QuizScreensSections/SectionGuarantee';
import SectionPalmAnalysis from 'core/common/components/QuizScreensSections/SectionPalmAnalysis';
import SectionPaywallHeader from 'core/common/components/QuizScreensSections/SectionPaywallHeader';
import SectionPlansOverview from 'core/common/components/QuizScreensSections/SectionPlansOverview';

const pageContainerMaxWidth = 450;

export const ContainerStyled = styled.main`
  width: 100%;
  max-width: ${pageContainerMaxWidth}px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 68px;
`;

export const SectionPaywallHeaderStyled = styled(SectionPaywallHeader)<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${pageContainerMaxWidth}px;
  transition: transform 0.3s ease;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(0) translateX(-50%)' : 'translateY(-100%) translateX(-50%)'};
`;

export const SectionDeckSliderStyled = styled(SectionDeckSlider)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const SectionPlansOverviewStyled = styled(SectionPlansOverview)`
  margin-top: 64px;
`;

export const SectionPalmAnalysisStyled = styled(SectionPalmAnalysis)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const IntroOfferWrapperStyled = styled.div`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const EmptyBlockStyled = styled.div``;

export const SectionFaqV2Styled = styled(SectionFaqV2)`
  margin-top: 64px;
  margin-bottom: 64px;
`;

export const SectionGuaranteeStyled = styled(SectionGuarantee)`
  background-color: #fafafa;
`;

export const DeckSliderWrapperStyled = styled.div``;

export const ReviewsCarouselWrapperStyled = styled.div``;
