import PlaceNatalChartScreen from 'core/common/components/QuizScreens/PlaceNatalChart';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { PlaceNatalChartScreenAttributes } from 'core/funnel/entities';
import { UserProfile } from 'core/user/entities';

type PlaceNatalChartProps = {
  id: string;
};

const PlaceNatalChart = ({ id }: PlaceNatalChartProps) => {
  const { back, next, screenAttributes, updateUserPreferences, userPreferences, step } =
    useQuizStep(id);

  const { locationDataKey, dataKey } = screenAttributes as PlaceNatalChartScreenAttributes;

  const initialLocation =
    typeof userPreferences[locationDataKey as keyof UserProfile] === 'string'
      ? (userPreferences[locationDataKey as keyof UserProfile] as string)
      : '';

  const initialCoordinates = Array.isArray(userPreferences[dataKey as keyof UserProfile])
    ? (userPreferences[dataKey as keyof UserProfile] as Array<number>)
    : [];

  const handleSubmit = async (coordinates: number[], location: string) => {
    updateUserPreferences(locationDataKey, location);
    updateUserPreferences(dataKey, coordinates);
    await next();
  };

  return (
    <PlaceNatalChartScreen
      onBack={back}
      onSubmit={handleSubmit}
      initialLocation={initialLocation}
      initialCoordinates={initialCoordinates}
      step={step}
      {...(screenAttributes as PlaceNatalChartScreenAttributes)}
    />
  );
};

export default PlaceNatalChart;
