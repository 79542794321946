import { useEffect, useState } from 'react';
import fullMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/fullMoon.png';
import lastQuarterMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/lastQuarterMoon.png';
import quarterMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/quarterMoon.png';
import QuizBackground from 'core/common/components/QuizBackground';
import { SECOND } from 'core/common/constants';
import { LoadingMoonCompatibilityScreenProps } from 'core/funnel/entities';
import {
  CaptionStyled,
  ContainerStyled,
  TextStyled,
  MoonImagesWrapperStyled,
  LastQuarterMoonImageStyled,
  QuarterMoonImageStyled,
  FullMoonImageStyled,
  AnimatedTextListStyled,
  AnimatedTextListItemStyled,
  CircularProgressStyled,
  LoaderWrapperStyled,
  LoaderProgressStyled,
} from './styled';

const textAnimationDurationInSec = 3;

const LoadingMoonCompatibilityScreen = ({
  content,
  onLoadingFinished,
}: LoadingMoonCompatibilityScreenProps) => {
  const { backgroundImage, animatedText, caption } = content;

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingFinished, setLoadingFinished] = useState(false);

  useEffect(() => {
    const totalAnimationDuration =
      content.animatedText.length * (textAnimationDurationInSec * SECOND);

    let startTime: number | null = null;

    const calcProgressPercents = (timestamp: number) => {
      if (!startTime) startTime = timestamp;

      const elapsedTime = timestamp - startTime;
      const currentProgress = Math.min((elapsedTime / totalAnimationDuration) * 100, 100);

      setLoadingProgress(currentProgress);

      if (elapsedTime < totalAnimationDuration) {
        requestAnimationFrame(calcProgressPercents);
      } else {
        setLoadingFinished(true);
      }
    };

    requestAnimationFrame(calcProgressPercents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingFinished) {
      onLoadingFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFinished]);

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} variant="dark" />
      <ContainerStyled>
        <MoonImagesWrapperStyled>
          <LastQuarterMoonImageStyled
            src={lastQuarterMoonImage}
            alt="Last quarter moon"
            width={166}
            height={166}
          />
          <FullMoonImageStyled src={fullMoonImage} alt="Full moon" width={166} height={166} />
          <QuarterMoonImageStyled
            src={quarterMoonImage}
            alt="Quarter moon"
            width={166}
            height={166}
          />
          <LoaderWrapperStyled>
            <LoaderProgressStyled>{Math.floor(loadingProgress)}%</LoaderProgressStyled>
            <CircularProgressStyled size={60} />
          </LoaderWrapperStyled>
        </MoonImagesWrapperStyled>
        <AnimatedTextListStyled>
          {animatedText.map((text, idx) => (
            <AnimatedTextListItemStyled key={text}>
              <TextStyled delayIndex={idx} animationDuration={textAnimationDurationInSec}>
                {text}
              </TextStyled>
            </AnimatedTextListItemStyled>
          ))}
        </AnimatedTextListStyled>
        <CaptionStyled>{caption}</CaptionStyled>
      </ContainerStyled>
    </>
  );
};

export default LoadingMoonCompatibilityScreen;
