import styled from 'styled-components';
import AnimatedImage from 'core/common/components/AnimatedImages/AnimatedImage';
import { InfoButton } from 'core/common/components/Quiz/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const Text = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  b {
    color: #55ccf2;
  }
`;

export const StarWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
  overflow: hidden;
  background: transparent;
  outline: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};

  span {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.warning};
  }
`;

export const Break = styled.br``;

export const Zodiac = styled.span`
  text-transform: capitalize;
  color: #f2c94c;
`;

export const LottieWrapper = styled.div`
  width: 250px;
  height: 250px;
  margin-right: auto;
  margin-left: auto;
`;

export const NextButton = styled(InfoButton)`
  height: 49px;
  margin: 0 0 0 10px;
  border-radius: 16px;
  color: ${(props) => props.theme.colors.info};
  background: ${(props) => props.theme.colors.typography100};
`;

export const PrevButton = styled(InfoButton)`
  height: 49px;
  margin: 0;
  border: ${(props) => `1px solid ${props.theme.colors.dark}`};
  border-radius: 16px;
  color: ${(props) => props.theme.colors.dark};
  background: transparent;
`;

export const StyledAnimatedImage = styled(AnimatedImage)`
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
`;
