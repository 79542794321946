import styled, { css, keyframes } from 'styled-components';
import { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import { SwipeAnimationDirection } from './useSectionDeckSlider';

const swipeRightAnimation = keyframes`
    0% {
        transform: perspective(1000px) translate3d(0, 0, 0);
    }
    50% {
        transform: perspective(1000px) translate3d(150%, 0, -0);
    }
    90% {
        z-index: 0;
        transform: perspective(1000px) translate3d(70%, 0, -100px);
    }
    100% {
        z-index: 0;
        transform: perspective(1000px) translate3d(0, 0, -200px);
    }
`;

const swipeLeftAnimation = keyframes`
    0% {
        transform: perspective(1000px) translate3d(0, 0, 0);
    }
    50% {
        transform: perspective(1000px) translate3d(-150%, 0, -0);
    }
    90% {
        z-index: 0;
        transform: perspective(1000px) translate3d(-70%, 0, -100px);
    }
    100% {
        z-index: 0;
        transform: perspective(1000px) translate3d(0, 0, -200px);
    }
`;

const swipeTutorialAnimation = keyframes`
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-50px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(0);
    }
`;

export const WrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

export const CardsListStyled = styled.ul<{ blurred: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 440px;
  transition: filter ease 0.3s;
  filter: ${({ blurred }) => (blurred ? 'blur(4px)' : 'none')};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    filter: none;
  }
`;

export const CardsListItemStyled = styled.li<{
  idx: number;
  isTop: boolean;
  innerCardPosition: number;
  swipeAnimationDirection: SwipeAnimationDirection;
}>`
  position: absolute;
  height: 100%;
  transition: all 0.3s ease;
  transform: ${({ innerCardPosition, isTop }) =>
    isTop
      ? 'rotate(0) perspective(1000px) translate3d(0, 0, 0)'
      : innerCardPosition % 2 === 0
      ? 'rotate(-8deg) perspective(1000px) translate3d(0, 0, 0)'
      : 'rotate(8deg) perspective(1000px) translate3d(0, 0, 0)'};

  &:nth-child(${({ idx }) => idx + 1}) {
    z-index: ${({ innerCardPosition }) => innerCardPosition};
  }

  ${({ isTop, swipeAnimationDirection }) =>
    isTop &&
    css`
      animation-name: ${swipeAnimationDirection === SwipeAnimationDirection.LEFT
        ? swipeLeftAnimation
        : swipeAnimationDirection === SwipeAnimationDirection.RIGHT
        ? swipeRightAnimation
        : ''};
      animation-duration: 1s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
    `}
`;

export const CardsNavigationDotsStyled = styled.ul`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 38px;
`;

export const SwipeFingerStyled = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  margin-top: -46.5px;
  margin-left: -46.5px;
  object-fit: contain;
  animation-name: ${swipeTutorialAnimation};
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: 2;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ListWrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SwipeDisclaimerStyled = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 38px;
  font-size: 12px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

export const SwipeDisclaimerIconStyled = styled(Image)`
  margin-right: 4px;
`;

export const NextCardButtonStyled = styled(TextButton)`
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: inline-block;
    margin-top: 38px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.typography400};
  }
`;
