import { createDate } from 'core/common/utils/date';
import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';
import { Languages } from 'core/localization/entities';
import ZodiacImagesBlock from './ZodiacImagesBlock';
import {
  ContainerLightStyled,
  DetailsListContainerStyled,
  ItemTextStyled,
  ItemTitleStyled,
  ListItemStyled,
  SingleListStyled,
  TitleStyled,
} from './styled';

export type SectionUserDetailsProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['userDetails'];
  zodiacImage: string;
};

const SectionUserDetails = ({ content, zodiacImage }: SectionUserDetailsProps) => {
  const { title, gender, genderTitle, zodiac, zodiacTitle, birthDate, birthDateTitle } = content;
  const language = Languages.EN;

  const dateTemplate = language === Languages.EN ? 'MMM D YYYY' : 'D MMMM YYYY';

  const dateOfBirth = createDate(birthDate).locale(language).format(dateTemplate);

  return (
    <>
      <TitleStyled>{title}</TitleStyled>
      <ContainerLightStyled>
        <ZodiacImagesBlock image={zodiacImage} zodiac={zodiac} />
        <DetailsListContainerStyled>
          <SingleListStyled>
            <ListItemStyled>
              <ItemTitleStyled>{zodiacTitle}</ItemTitleStyled>
              <ItemTextStyled>{zodiac}</ItemTextStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ItemTitleStyled>{genderTitle}</ItemTitleStyled>
              <ItemTextStyled>{gender}</ItemTextStyled>
            </ListItemStyled>
          </SingleListStyled>
          <SingleListStyled>
            <ListItemStyled>
              <ItemTitleStyled>{birthDateTitle}</ItemTitleStyled>
              <ItemTextStyled>{dateOfBirth}</ItemTextStyled>
            </ListItemStyled>
          </SingleListStyled>
        </DetailsListContainerStyled>
      </ContainerLightStyled>
    </>
  );
};

export default SectionUserDetails;
