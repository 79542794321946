import Header from 'core/common/components/Header';
import { ContainerMain } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground/QuizBackground';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailConfirmScreenProps } from 'core/funnel/entities';
import { Button, Image, ImageContainer, Skip, Subtitle, Title } from './styled';

const EmailConfirmScreen = ({
  content,
  onUserDisagree,
  onUserAgree,
  onBack,
}: EmailConfirmScreenProps) => {
  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header onBack={onBack} />
      <ContainerMain>
        <ImageContainer>
          {content.image && (
            <Image
              src={content.image.src}
              alt={content.image.alt || 'confirm'}
              width={180}
              height={180}
            />
          )}
        </ImageContainer>
        <Title dangerouslySetInnerHTML={prepareHtml(content.title)} />
        <Subtitle>{content.subtitle}</Subtitle>
        <Button onClick={onUserAgree}>{content.button}</Button>
        <Skip onClick={onUserDisagree}>{content.skip}</Skip>
      </ContainerMain>
    </>
  );
};

export default EmailConfirmScreen;
