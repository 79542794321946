import { ChangeEvent, useState } from 'react';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBar from 'core/common/components/ProgressBarNatalChartV2';
import { Image } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { NameHumanDesignScreenProps } from 'core/funnel/entities';
import { Content, InputBlock, StyledButton, StyledText, StyledTitle, Container } from './styled';

const NameHumanDesignScreen = ({
  content,
  progressBarTitle,
  initialName,
  totalSteps,
  onSubmit,
  onBack,
  step,
}: NameHumanDesignScreenProps) => {
  const [name, setName] = useState(initialName);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleButtonClick = () => {
    if (!name) return;

    onSubmit(name);
  };

  return (
    <>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ProgressBar
        title={progressBarTitle}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={onBack}
      />
      <Container>
        <Content>
          {!!content.image && (
            <Image
              src={content.image.src}
              alt={content.image.alt || 'name'}
              width={203}
              height={203}
            />
          )}
          <StyledTitle>{content.title}</StyledTitle>
          <StyledText>{content.text}</StyledText>

          <InputBlock>
            <Input placeholder="Enter your full name" onChange={handleInputChange} value={name} />
          </InputBlock>
          <StyledButton onClick={handleButtonClick} disabled={!name}>
            {content.next}
          </StyledButton>
        </Content>
      </Container>
    </>
  );
};

export default NameHumanDesignScreen;
