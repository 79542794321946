import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

const reviewCardPaddingPx = 16;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 43px;
  }
`;

export const ReviewStyled = styled.div`
  padding: ${reviewCardPaddingPx}px;
  border-radius: 20px;
  background-color: #f7f7f7;
`;

export const ReviewTextStyled = styled.p`
  margin-top: 8px;
  line-height: 24px;

  mark {
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 6px;
    font-weight: 600;
    background-color: #d1c3e3;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const ReviewFooterStyled = styled.div`
  display: flex;
  margin-top: ${reviewCardPaddingPx}px;
  padding-top: ${reviewCardPaddingPx}px;
  border-top: 1px solid ${({ theme }) => theme.colors.typography200};
`;

export const UserInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

export const AvatarStyled = styled(Image)`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 44px;
    height: 44px;
  }
`;

export const UserNameStyled = styled.span`
  font-size: 14px;
  font-weight: 600;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const CaptionStyled = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const PublishedAtStyled = styled.span`
  margin-left: auto;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
