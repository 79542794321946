import { memo } from 'react';
import { Background } from './styled';

type BackgroundGradientProps = {
  className?: string;
};

const BackgroundGradient = ({ className = '' }: BackgroundGradientProps) => (
  <Background className={className} />
);

export default memo(BackgroundGradient);
