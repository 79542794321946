import { FunnelImage, TrialPaymentMoonCompatibilityScreenAttributes } from 'core/funnel/entities';
import UserDetails from './UserDetails';
import {
  WrapperStyled,
  PreviewTextStyled,
  PreviewSecondaryTextStyled,
  ButtonStyled,
  ButtonTextStyled,
} from './styled';

type MoonCompatibilityPreviewProps = {
  content: TrialPaymentMoonCompatibilityScreenAttributes['content']['preview'];
  moonImage: FunnelImage;
  moonSignImage: FunnelImage;
  partnerMoonImage: FunnelImage;
  partnerMoonSignImage: FunnelImage;
  onMoveToOffer: () => void;
};

const MoonCompatibilityPreview = ({
  content,
  moonImage,
  moonSignImage,
  partnerMoonImage,
  partnerMoonSignImage,
  onMoveToOffer,
}: MoonCompatibilityPreviewProps) => {
  const {
    title,
    moonPhase,
    moonSign,
    birthDate,
    birthTime,
    partner,
    mainText,
    secondaryText,
    button,
  } = content;

  const displayFallbackPartnerImage = !partner.birthDate.value;

  return (
    <WrapperStyled>
      <UserDetails
        title={title}
        moonPhase={moonPhase.title}
        moonSign={moonSign}
        birthDate={birthDate}
        birthTime={birthTime}
        moonImage={moonImage}
        moonSignImage={moonSignImage}
      />
      <UserDetails
        title={partner.title}
        moonPhase={partner.moonPhase.title}
        moonSign={partner.moonSign}
        birthDate={partner.birthDate}
        birthTime={partner.birthTime}
        moonImage={displayFallbackPartnerImage ? partner.moonPhase.fallbackImage : partnerMoonImage}
        moonSignImage={
          displayFallbackPartnerImage ? partner.moonSign.fallbackImage : partnerMoonSignImage
        }
      />
      <PreviewTextStyled>{mainText}</PreviewTextStyled>
      <PreviewSecondaryTextStyled>{secondaryText}</PreviewSecondaryTextStyled>
      <ButtonStyled impulseScale>
        <ButtonTextStyled onClick={onMoveToOffer}>{button}</ButtonTextStyled>
      </ButtonStyled>
    </WrapperStyled>
  );
};

export default MoonCompatibilityPreview;
