import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const GuaranteeBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  padding: 16px 8px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const TitleStyled = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const GuaranteeContentStyled = styled.div``;

export const MedalImageStyled = styled(Image)`
  margin: auto auto 24px;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;
