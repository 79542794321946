import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.p`
  margin-bottom: 6px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #454895;
`;

export const List = styled.ul`
  position: relative;
  display: flex;
  margin-right: -8px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const slideInAnimation = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

export const ListItem = styled.li<{ animate: boolean }>`
  margin-right: 6px;
  padding: 5px 7px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.typography100};
  ${(props) =>
    props.animate &&
    css`
      animation: ${slideInAnimation} 0.5s ease;
    `};
`;

export const ListItemEmail = styled.span`
  display: flex;
  width: max-content;
  margin-right: 4px;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.typography600};

  b {
    margin-left: 5px;
  }
`;
