import styled from 'styled-components';
import NavLink from 'core/common/components/Link';

export const Bold = styled.b`
  font-weight: 600;
`;

export const Link = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;
export const Underline = styled.span`
  font-weight: 600;
  text-decoration: underline;
`;

export const SubscriptionDisclaimer = styled.p`
  max-width: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.typography700};
`;
