import styled from 'styled-components';

export const ReviewsBlock = styled.div`
  margin-top: 32px;
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(212, 198, 229, 0.4);
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;
