import { useEffect, useState } from 'react';
import { PaymentDialog } from 'core/common/components/Payment';
import SectionAdvantages from 'core/common/components/QuizScreensSections/SectionAdvantages';
import Banner from 'core/common/components/QuizScreensSections/SectionBanner';
import SectionFaq from 'core/common/components/QuizScreensSections/SectionFaq';
import SectionGuide from 'core/common/components/QuizScreensSections/SectionGuide';
import SectionInsights from 'core/common/components/QuizScreensSections/SectionInsights';
import SectionMentionedIn from 'core/common/components/QuizScreensSections/SectionMentionedIn';
import SectionMoneyBackGuarantee from 'core/common/components/QuizScreensSections/SectionMoneyBackGuarantee';
import SectionQuote from 'core/common/components/QuizScreensSections/SectionQuote';
import SectionReviews from 'core/common/components/QuizScreensSections/SectionReviews';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { useInView, useServices } from 'core/common/hooks';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import {
  EmailMarketingWitchPowerScreenProps,
  SectionAttributesUnionType,
  SectionType,
} from 'core/funnel/entities';
import {
  BackgroundStyled,
  ContentStyled,
  DescriptionStyled,
  SectionBenefitsStyled,
  SectionEndStyled,
  SectionOfferStyled,
} from './styled';

const EmailMarketingWitchPowerScreen = ({
  offer,
  sections,
  onGetReport,
  paymentSucceed,
  order,
  analyticEmailMarketing,
}: EmailMarketingWitchPowerScreenProps) => {
  const { analyticsService } = useServices();
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getReport = (analyticsKey?: string) => {
    setIsModalOpen(true);

    if (analyticsKey) {
      return onGetReport(analyticsKey);
    }
  };

  const onPaymentSucceed = () => {
    setIsModalOpen(false);
    paymentSucceed();
  };

  useEffect(() => {
    if (inView) {
      analyticsService.emailMarketingLpScrolledToEnd({
        page: analyticEmailMarketing?.page ?? 'witch_power',
        view: analyticEmailMarketing?.view ?? 'general_landing',
        source: analyticEmailMarketing?.scrolledSource ?? 'witch_power_email_land_updated',
      });
    }
  }, [inView, analyticsService, analyticEmailMarketing]);

  const birthdate = (order && order.order_quiz_data.birth_date) ?? '';

  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.BANNER:
        return (
          <Banner
            key={section.sectionType + index}
            content={section.content}
            onClick={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.ADVANTAGES:
        return <SectionAdvantages key={section.sectionType + index} content={section.content} />;
      case SectionType.GUIDE:
        return (
          <SectionGuide
            key={section.sectionType + index}
            content={section.content}
            birthdate={birthdate}
          />
        );
      case SectionType.BENEFITS:
        return (
          <SectionBenefitsStyled key={section.sectionType + index} content={section.content} />
        );
      case SectionType.OFFER:
        return (
          <SectionOfferStyled
            key={section.sectionType + index}
            offer={offer}
            content={section.content}
            onGetReport={() => getReport(section.content?.analyticsKey)}
          />
        );
      case SectionType.REVIEWS:
        return <SectionReviews key={section.sectionType + index} content={section.content} />;
      case SectionType.QUOTE:
        return <SectionQuote key={section.sectionType + index} content={section.content} />;
      case SectionType.MENTIONED_IN:
        return <SectionMentionedIn key={section.sectionType + index} content={section.content} />;
      case SectionType.INSIDES:
        return <SectionInsights key={section.sectionType + index} content={section.content} />;
      case SectionType.FAQ: {
        return <SectionFaq key={section.sectionType + index} content={section.content} />;
      }
      case SectionType.MONEY_BACK_GUARANTEE: {
        return (
          <SectionMoneyBackGuarantee key={section.sectionType + index} content={section.content} />
        );
      }
      case SectionType.PAYMENT:
        return (
          offer?.offerId && (
            <PaymentDialog
              key={section.sectionType + index}
              isOpen={isModalOpen}
              productId={offer.offerId}
              currency={offer.currency}
              onClose={() => setIsModalOpen(false)}
              onPaymentSuccess={onPaymentSucceed}
            >
              <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(section.content.text)} />
            </PaymentDialog>
          )
        );

      default:
        return null;
    }
  };

  return (
    <RenderOnlyOnClient>
      <BackgroundStyled />
      <ContentStyled>{sections.map(renderSection)}</ContentStyled>
      <SectionEndStyled ref={ref} />
    </RenderOnlyOnClient>
  );
};

export default EmailMarketingWitchPowerScreen;
