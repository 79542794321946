import arrow from 'core/common/assets/images/billing/curlyArrow.svg';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { LoadingStatuses } from 'core/common/entities';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialChoicePalmistryV2ScreenProps } from 'core/funnel/entities';
import {
  Background,
  Button,
  Container,
  List,
  ListItem,
  Note,
  Price,
  PriceBlock,
  Subtitle,
  Text,
  Tip,
  TipText,
  Title,
} from './styled';

const initialPerPage = 4;
const initialSkeletonsArray = new Array(initialPerPage).fill('-');

const TrialChoicePalmistryV2Screen = ({
  content,
  offers,
  selectedOffer,
  loadingStatus,
  changeSelectedOffer,
  onSubmit,
}: TrialChoicePalmistryV2ScreenProps) => {
  const {
    title,
    satisfactionTitle,
    satisfactionText,
    savingsTitle,
    savingsText,
    tipText,
    button,
    note,
  } = content;

  const isLoading = loadingStatus !== LoadingStatuses.FULFILLED;

  return (
    <>
      <Background />
      <Header buttonBack={false} />
      <Container>
        <Title>{title}</Title>
        <List>
          <ListItem>
            <Subtitle>{satisfactionTitle}</Subtitle>
            <Text dangerouslySetInnerHTML={prepareHtml(satisfactionText)} />
          </ListItem>
          <ListItem>
            <Subtitle>{savingsTitle}</Subtitle>
            <Text>{savingsText}</Text>
          </ListItem>
        </List>

        <PriceBlock>
          {isLoading
            ? initialSkeletonsArray.map((item, index) => <Price key={index}>{item}</Price>)
            : offers.map((offer) => {
                return (
                  <Price
                    key={offer.offerId}
                    onClick={() => changeSelectedOffer(offer)}
                    isActive={!!selectedOffer && selectedOffer.offerId === offer.offerId}
                  >
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  </Price>
                );
              })}
        </PriceBlock>
        <Tip>
          <TipText>{tipText}</TipText>
          <Image src={arrow} alt="arrow" width={26} height={33} />
        </Tip>
        <Button onClick={onSubmit} disabled={!selectedOffer}>
          {button}
        </Button>
        <Note>{note}</Note>
      </Container>
    </>
  );
};

export default TrialChoicePalmistryV2Screen;
