import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 328px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Text = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  height: 157px;
  margin: 0 auto 50px;
`;

export const StyledTitle = styled(Title)`
  margin-top: 12px;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const Button = styled(ButtonComponent)`
  height: 49px;
  margin: auto auto 30px;
  font-size: 18px;
  line-height: 21px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: auto auto 50px;
  }
`;
