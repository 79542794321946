import TimerV2 from 'core/common/components/TimerV2';
import { SectionBannerWithTimerAttributes } from 'core/funnel/entities';
import { ButtonStyled, WrapperStyled } from './styled';

type SectionBannerWithTimerProps = {
  content: SectionBannerWithTimerAttributes['content'];
  onClick: () => void;
  timerStorageKey: string;
  timerDuration?: number;
  timerStop?: number;
};

// Experiment NEB-11163
const SectionBannerWithTimer = ({
  content,
  onClick,
  timerStorageKey,
  timerDuration,
  timerStop,
}: SectionBannerWithTimerProps) => {
  return (
    <WrapperStyled>
      <TimerV2
        storageKey={timerStorageKey}
        duration={timerDuration}
        stop={timerStop}
        title={content.title}
        secLabel={content.secLabel}
        minLabel={content.minLabel}
        timerVariant="tertiary"
      />
      <ButtonStyled impulseScale onClick={onClick}>
        {content.buttonText}
      </ButtonStyled>
    </WrapperStyled>
  );
};

export default SectionBannerWithTimer;
