import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const TitleStyled = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 145%;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const ListStyled = styled.ul``;

export const ItemStyled = styled.li`
  display: flex;
  flex-direction: column;
`;

export const MessageWrapperStyled = styled.div<{ isEven: boolean }>`
  position: relative;
  top: 30px;
  height: 150px;
  margin-left: 30px;

  ${({ isEven }) =>
    !isEven &&
    `
      margin-left: auto;
      margin-right: 30px;
  `}
`;

export const MessageStyled = styled(Image)<{ isEven: boolean }>`
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;

  ${({ isEven }) =>
    !isEven &&
    `
      transform: scaleX(-1);
  `}
`;

export const TextStyled = styled.p`
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.primary300};
`;

export const AvatarStyled = styled(Image)<{ isEven: boolean }>`
  width: 44px;
  height: 44px;

  ${({ isEven }) =>
    !isEven &&
    `
      align-self: end;
  `}
`;

export const TipStyled = styled.p`
  margin-top: 27px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary600};
`;
