import { addLeadingZero } from 'core/common/utils/addLeadingZero';
import { convertTimeToMinutesAndSeconds } from 'core/common/utils/convertTimeToMinutesAndSeconds';
import {
  TimerBody,
  TimerContainer,
  TimerDevider,
  TimerHeader,
  TimerUnitCell,
  TimerUnitLabel,
  TimerUnitText,
} from './styled';

export type TimerProps = {
  title: string;
  timeLeft: number;
};

const Timer = ({ title, timeLeft }: TimerProps) => {
  const { minutes, seconds } = convertTimeToMinutesAndSeconds(timeLeft);

  return (
    <TimerContainer>
      <TimerHeader>{title}</TimerHeader>
      <TimerBody>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(minutes)}</TimerUnitText>
          <TimerUnitLabel>min</TimerUnitLabel>
        </TimerUnitCell>
        <TimerDevider>:</TimerDevider>
        <TimerUnitCell>
          <TimerUnitText>{addLeadingZero(seconds)}</TimerUnitText>
          <TimerUnitLabel>sec</TimerUnitLabel>
        </TimerUnitCell>
      </TimerBody>
    </TimerContainer>
  );
};

export default Timer;
