import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';
import { QuizButton } from 'core/common/components/Quiz/styled';
import Title from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  max-width: 360px;
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 434px;
    font-size: 18px;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 328px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 150%;
  color: ${(props) => props.theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 540px;
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 32px;
  }
`;

export const Image = styled(ImageComponent)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 250px;
    height: 250px;
  }
`;

export const HorizontalImage = styled(ImageComponent)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 440px;
    height: 200px;
  }
`;

export const Button = styled(QuizButton)`
  flex-grow: 1;
  height: 49px;
  margin-bottom: 32px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.typography100};
  background: ${(props) => props.theme.colors.infoLight};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 48px;
  }
`;
