import InformationalScreen from 'core/common/components/QuizScreens/Informational';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { InformationalScreenAttributes } from 'core/funnel/entities';

type InformationalProps = {
  id: string;
};

const Informational = ({ id }: InformationalProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <InformationalScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as InformationalScreenAttributes)}
    />
  );
};

export default Informational;
