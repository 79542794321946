import GenderV2Screen from 'core/common/components/QuizScreens/GenderDynamicImages';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { Gender as GenderTypes, GenderV2ScreenAttributes } from 'core/funnel/entities';

type GenderV2Props = {
  id: string;
};

const GenderV2 = ({ id }: GenderV2Props) => {
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { back, next, screenAttributes, updateUserPreferences, step, signIn } = useQuizStep(id);

  const { dataKey } = screenAttributes as GenderV2ScreenAttributes;

  const onGenderClick = (value: GenderTypes) => {
    if (!isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    next({ value });
  };

  return (
    <GenderV2Screen
      onBack={back}
      onGenderClick={onGenderClick}
      onPolicyClick={handlePolicyClick}
      step={step}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      signIn={signIn}
      {...(screenAttributes as GenderV2ScreenAttributes)}
    />
  );
};

export default GenderV2;
