import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const TitleStyled = styled.h2`
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const SubtitleStyled = styled.h2`
  max-width: 460px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  line-height: 135%;
  text-align: center;
  color: ${(props) => props.theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 16px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const Root = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.themeBackground};
    content: '';
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
`;

export const Button = styled(ButtonComponent)`
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 30px;
  }
`;

export const RouteLink = styled.button`
  z-index: 5;
  width: 310px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  transform: translateX(-50%);

  &::before {
    position: absolute;
    top: -10px;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 240, 240, 0) 0%,
      rgba(255, 240, 240, 0.95) 53.81%
    );
    content: '';
  }
`;

export const FestiveBackgroundMobile = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const FestiveBackgroundDesktop = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;
