import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { EmailConfirmNatalChartScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Image,
  ImageContainerDesktop,
  ImageContainerMobile,
  Skip,
  Subtitle,
  Title,
} from './styled';

const EmailConfirmNatalChartScreen = ({
  content,
  onUserDisagree,
  onUserAgree,
  onBack,
}: EmailConfirmNatalChartScreenProps) => {
  return (
    <>
      <Header onBack={onBack} />
      <Container>
        <ImageContainerDesktop>
          <Image src={content.image} alt="email confirm" width={250} height={250} />
        </ImageContainerDesktop>
        <ImageContainerMobile>
          {content.image && (
            <Image src={content.image} alt="email confirm" width={150} height={150} />
          )}
        </ImageContainerMobile>
        <Title dangerouslySetInnerHTML={prepareHtml(content.title)} />
        <Subtitle>{content.subtitle}</Subtitle>
        <Button onClick={onUserAgree}>{content.button}</Button>
        <Skip onClick={onUserDisagree}>{content.skip}</Skip>
      </Container>
    </>
  );
};

export default EmailConfirmNatalChartScreen;
