import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import NavLink from 'core/common/components/Link/Link';

export const PurchaseBlock = styled.div`
  margin-top: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 32px;
  }
`;

export const TotalBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalText = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography800};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  height: 56px;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 16px;
  }
`;

export const Bold = styled.b``;

export const Link = styled(NavLink)`
  font-weight: 600;
  text-decoration: underline;
`;

export const Description = styled.p`
  margin-top: 12px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.typography500};

  ${Bold} {
    font-weight: 600;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
  }
`;
