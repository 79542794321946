import ExpertCard from 'core/common/components/ExpertCard';
import { SectionExpertsAttributes } from 'core/funnel/entities';
import { useIsMobile } from 'core/theme/hooks';
import {
  WrapperStyled,
  TitleStyled,
  ExpertsListStyled,
  ExpertsListItemStyled,
  ExpertsImageStyled,
} from './styled';

export type SectionExpertsProps = {
  content: SectionExpertsAttributes['content'];
  className?: string;
};

const SectionExperts = ({ content, className }: SectionExpertsProps) => {
  const { title, experts, image } = content;

  const { isMobile } = useIsMobile();

  const shouldSlice = !isMobile && experts.length > 2;

  const expertsToRender = shouldSlice ? experts.slice(0, 2) : experts;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <ExpertsListStyled>
        {expertsToRender.map((expert) => (
          <ExpertsListItemStyled key={expert.name}>
            <ExpertCard
              name={expert.name}
              avatar={expert.avatar}
              details={expert.details}
              description={expert.description}
              expertFields={expert.expertFields}
              readMoreButtonText={expert.readMoreButtonText}
              readLessButtonText={expert.readLessButtonText}
            />
          </ExpertsListItemStyled>
        ))}
      </ExpertsListStyled>
      <ExpertsImageStyled src={image.src} alt={image.alt} width={334} height={64} />
    </WrapperStyled>
  );
};

export default SectionExperts;
