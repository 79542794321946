import { formatTraumaScore } from 'core/common/utils/formatTraumaScore';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled,
  ImageStyled,
  ImageWrapperStyled,
  TraumaScoreStyled,
} from './styled';

type UserTraumaScoreProps = {
  title: string;
  value: string;
  image: FunnelImage;
  traumaScore: number;
};

const UserTraumaScore = ({ title, value, image, traumaScore }: UserTraumaScoreProps) => {
  const traumaValue = formatTraumaScore(traumaScore);

  return (
    <WrapperStyled>
      <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
      <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(value)} />
      <ImageWrapperStyled>
        <ImageStyled src={image.src} alt={image.alt} width={80} height={80} />
        <TraumaScoreStyled dangerouslySetInnerHTML={prepareHtml(`${traumaValue}/10`)} />
      </ImageWrapperStyled>
    </WrapperStyled>
  );
};

export default UserTraumaScore;
