import styled from 'styled-components';
import starIcon from 'core/common/assets/images/moonCompatibilityQuiz/star.svg';
import Button from 'core/common/components/ButtonDeprecated';

export const WrapperStyled = styled.section`
  margin-top: 52px;
`;

export const TitleStyled = styled.h2`
  font-size: 22px;
  font-weight: 700;
  text-align: center;
`;

export const ContentStyled = styled.div`
  margin-top: 20px;
  padding: 24px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.themeBackground};
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.1);
`;

export const ChallengesListStyled = styled.ul``;

export const ChallengesListItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 38px;
  }
`;

export const ChallangeTitleStyled = styled.h3<{ variant?: 'active' | 'disabled' }>`
  font-weight: 600;
  text-align: center;
  color: ${({ theme, variant }) =>
    variant === 'active' ? theme.colors.typography900 : theme.colors.typography400};
`;

export const ProgressStyled = styled.div`
  height: 2px;
  margin-top: 8px;
  background: linear-gradient(90deg, rgba(117, 117, 117, 0) 0%, rgba(117, 117, 117, 0.38) 100%);
`;

export const ActiveProgressStyled = styled.div<{ progress: number }>`
  position: relative;
  width: ${({ progress }) => progress}%;
  height: 2px;
  background: linear-gradient(90deg, rgba(84, 60, 151, 0) 0%, #543c97 100%);

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(${starIcon});
    transform: translateY(-50%) translateX(50%);
    content: '';
  }
`;

export const ProgressLablesContainerStyled = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography400};
`;

export const ProgressLabelStyled = styled.span``;

export const ProgressQuestionMarkStyled = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

export const ButtonStyled = styled(Button)`
  width: 95%;
  height: 48px;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: 700;
`;
