import { Trans, useTranslation } from 'react-i18next';
import { BASE_ROUTES } from 'core/common/constants';
import { useConfig } from 'core/common/hooks';
import { DisclaimerText, Link } from './styled';

export type SectionDisclaimerProps = {
  displayTrialPrice: string;
  displayPlanPrice: string;
};

const SectionDisclaimer = ({ displayTrialPrice, displayPlanPrice }: SectionDisclaimerProps) => {
  const { domainUrl } = useConfig();
  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.salesScreen';

  const { t } = useTranslation('translation', {
    keyPrefix: tKeyPrefix,
  });

  return (
    <DisclaimerText>
      <Trans
        i18nKey={`${tKeyPrefix}.charge.trialUS`}
        values={{
          trialPrice: displayTrialPrice,
          fullPrice: displayPlanPrice,
          domainUrl: domainUrl,
        }}
      />
      <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>{t('description.terms')}</Link>
    </DisclaimerText>
  );
};

export default SectionDisclaimer;
