import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { InformationalV2ScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerStyled,
  DescriptionStyled,
  IconStyled,
  ImageStyled,
  ImageWrapperStyled,
  ListImageStyled,
  ListItemStyled,
  ListStyled,
  ListTitleStyled,
  ListWrapperStyled,
  StickyButtonWrapperStyled,
  TitleStyled,
} from './styled';

const InformationalV2Screen = ({ content, goNext, goBack }: InformationalV2ScreenProps) => {
  const { title, description, image, list, caption, button } = content;

  return (
    <>
      <Header onBack={goBack} />
      <ContainerStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        {description && <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(description)} />}
        {image && (
          <ImageWrapperStyled>
            <ImageStyled src={image.src} alt={image.alt} fill />
          </ImageWrapperStyled>
        )}
        {list && (
          <ListWrapperStyled>
            <ListTitleStyled>{list.title}</ListTitleStyled>
            {list.image && (
              <ListImageStyled src={list.image.src} alt={list.image.alt} width={64} height={64} />
            )}
            <ListStyled>
              {list.items.map(({ icon, text }) => (
                <ListItemStyled key={text}>
                  <IconStyled src={icon.src} alt={icon.alt} width={24} height={24} />
                  {text}
                </ListItemStyled>
              ))}
            </ListStyled>
          </ListWrapperStyled>
        )}
        {caption && <DescriptionStyled dangerouslySetInnerHTML={prepareHtml(caption)} />}
        <StickyButtonWrapperStyled>
          <ButtonStyled fullWidth onClick={goNext}>
            {button}
          </ButtonStyled>
        </StickyButtonWrapperStyled>
      </ContainerStyled>
    </>
  );
};

export default InformationalV2Screen;
