import Header from 'core/common/components/Header';
import MediaSourceVariant from 'core/common/components/MediaSourceVariant';
import PolicyExtended from 'core/common/components/PolicyExtended';
import QuizBackground from 'core/common/components/QuizBackground';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { GenderV2ScreenProps } from 'core/funnel/entities';
import { Locales, Regions, paidUtmSources, notPaidUtmSources } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  AddressStyled,
  AddressUSStyled,
  ArrowStyled,
  ContainerStyled,
  DisclaimerStyled,
  FemaleButtonStyled,
  GenderListStyled,
  GenderListItemStyled,
  GenderSectionStyled,
  GenderSectionTitleStyled,
  ImageStyled,
  MaleButtonStyled,
  PolicyStyled,
  SignInStyled,
  SubtitleStyled,
  TitleStyled,
  DataSecuredBadgeStyled,
} from './styled';

const GenderV2Screen = ({
  content,
  isPolicyAccepted,
  displayPolicyError,
  onGenderClick,
  onPolicyClick,
  signIn,
}: GenderV2ScreenProps) => {
  return (
    <RenderOnlyOnClient>
      <QuizBackground backgroundImage={content.backgroundImage} />
      <Header buttonBack={false} />
      <ContainerStyled>
        <TitleStyled>{content.title}</TitleStyled>
        <SubtitleStyled>{content.subtitle}</SubtitleStyled>
        <GenderSectionStyled>
          <GenderSectionTitleStyled>{content.actionTitle}</GenderSectionTitleStyled>
          <GenderListStyled>
            <GenderListItemStyled onClick={() => onGenderClick(content.male.value)}>
              <ImageStyled src={content.male.image} alt="a man" width={150} height={174} />
              <MaleButtonStyled>
                {content.male.text} <ArrowStyled />
              </MaleButtonStyled>
            </GenderListItemStyled>
            <GenderListItemStyled onClick={() => onGenderClick(content.female.value)}>
              <ImageStyled src={content.female.image} alt="a woman" width={150} height={174} />
              <FemaleButtonStyled>
                {content.female.text} <ArrowStyled />
              </FemaleButtonStyled>
            </GenderListItemStyled>
          </GenderListStyled>
        </GenderSectionStyled>
        {content.signIn && <SignInStyled onClick={signIn}>{content.signIn}</SignInStyled>}
        {content.dataSecuredBadge && (
          <DataSecuredBadgeStyled
            title={content.dataSecuredBadge.title}
            icon={content.dataSecuredBadge.icon}
          />
        )}
        <DisclaimerStyled>{content.disclaimer}</DisclaimerStyled>
        {/* Terms for paid trafic (start) */}
        <MediaSourceVariant name={paidUtmSources}>
          <LocationRegion>
            <LocationRegionOption option={Regions.CALIFORNIA}>
              <PolicyExtended
                onPolicyClick={onPolicyClick}
                isPolicyAccepted={isPolicyAccepted}
                withoutCheckbox
              />
            </LocationRegionOption>
            <LocationRegionOption option={Regions.OTHER}>
              <PolicyStyled />
            </LocationRegionOption>
          </LocationRegion>
        </MediaSourceVariant>
        {/* Terms for paid trafic (end) */}
        {/* Terms for organic trafic (start) */}
        <MediaSourceVariant name={notPaidUtmSources}>
          <Location>
            <LocationOption option={Locales.US}>
              <PolicyExtended
                onPolicyClick={onPolicyClick}
                isPolicyAccepted={isPolicyAccepted}
                isError={displayPolicyError}
              />
            </LocationOption>
          </Location>
        </MediaSourceVariant>
        {/* Terms for organic trafic (end) */}
        <Location>
          <LocationOption option={Locales.US}>
            <AddressUSStyled>Nevada, USA</AddressUSStyled>
          </LocationOption>
          <LocationOption option={Locales.OTHER}>
            {!!content.address && <AddressStyled />}
          </LocationOption>
        </Location>
      </ContainerStyled>
    </RenderOnlyOnClient>
  );
};

export default GenderV2Screen;
