import { useMemo } from 'react';
import wingImage from 'core/common/assets/images/quiz/greyWing.png';
import { getAppRatingPercents } from 'core/common/utils/getAppRatingPercents';
import {
  RatingWrapperStyled,
  TitleStyled,
  RatingStyled,
  StarsWrapperStyled,
  WingImageStyled,
  WingImageReveresedStyled,
} from './styled';

type RatingProps = {
  rating: number;
  title: string;
};

const Rating = ({ rating, title }: RatingProps) => {
  const ratingPercents = useMemo(() => getAppRatingPercents(rating), [rating]);

  return (
    <RatingWrapperStyled>
      <WingImageStyled src={wingImage} width={30} height={68} alt="" />
      <TitleStyled>{title}</TitleStyled>
      <StarsWrapperStyled>
        <RatingStyled percent={ratingPercents} />
        {rating}
      </StarsWrapperStyled>
      <WingImageReveresedStyled src={wingImage} width={30} height={68} alt="" />
    </RatingWrapperStyled>
  );
};

export default Rating;
