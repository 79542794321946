import { ReactComponent as CurlyArrowIcon } from 'core/common/assets/images/billing/curlyArrow.svg';
import Header from 'core/common/components/Header';
import { LoadingStatuses } from 'core/common/entities';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { TrialChoiceNatalChartScreenProps } from 'core/funnel/entities';
import {
  ButtonStyled,
  ContainerStyled,
  ListStyled,
  ListItemStyled,
  NoteStyled,
  PriceStyled,
  PriceBlockStyled,
  SubtitleStyled,
  TextStyled,
  TipStyled,
  TipTextStyled,
  TitleStyled,
} from './styled';

const initialPerPage = 4;
const initialSkeletonsArray = new Array(initialPerPage).fill('-');

const TrialChoiceNatalChartScreen = ({
  content,
  offers,
  selectedOffer,
  loadingStatus,
  changeSelectedOffer,
  onSubmit,
}: TrialChoiceNatalChartScreenProps) => {
  const {
    title,
    satisfactionTitle,
    satisfactionText,
    savingsTitle,
    savingsText,
    tipText,
    button,
    note,
  } = content;

  const isLoading = loadingStatus !== LoadingStatuses.FULFILLED;

  return (
    <>
      <Header buttonBack={false} />
      <ContainerStyled>
        <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        <ListStyled>
          <ListItemStyled>
            <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(satisfactionTitle)} />
            <TextStyled dangerouslySetInnerHTML={prepareHtml(satisfactionText)} />
          </ListItemStyled>
          <ListItemStyled>
            <SubtitleStyled dangerouslySetInnerHTML={prepareHtml(savingsTitle)} />
            <TextStyled dangerouslySetInnerHTML={prepareHtml(savingsText)} />
          </ListItemStyled>
        </ListStyled>
        <PriceBlockStyled>
          {isLoading
            ? initialSkeletonsArray.map((item, index) => (
                <PriceStyled key={index}>{item}</PriceStyled>
              ))
            : offers.map((offer) => {
                return (
                  <PriceStyled
                    key={offer.offerId}
                    onClick={() => changeSelectedOffer(offer)}
                    isActive={!!selectedOffer && selectedOffer.offerId === offer.offerId}
                  >
                    {offer.pricesWithCurrencySymbol.trialPriceWithVAT}
                  </PriceStyled>
                );
              })}
        </PriceBlockStyled>
        <TipStyled>
          <TipTextStyled dangerouslySetInnerHTML={prepareHtml(tipText)} />
          <CurlyArrowIcon />
        </TipStyled>
        <ButtonStyled onClick={onSubmit} disabled={!selectedOffer}>
          {button}
        </ButtonStyled>
        <NoteStyled dangerouslySetInnerHTML={prepareHtml(note)} />
      </ContainerStyled>
    </>
  );
};

export default TrialChoiceNatalChartScreen;
