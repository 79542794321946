import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';
import RatingComponent from 'core/common/components/Rating';
import TooltipComponent from 'core/common/components/Tooltip';
import Icon from 'core/svg-sprites/ui/Icon';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 708px;
  margin-right: auto;
  margin-left: auto;
`;

export const Title = styled.h2`
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  > b {
    color: ${(props) => props.theme.colors.infoLight};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 140%;
  }
`;

export const HintTitle = styled(Title)`
  margin-bottom: 20px;
  font-weight: 400;

  b {
    font-weight: 600;
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0;
    font-size: 36px;
    font-weight: bold;
    line-height: 49px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Feedback = styled.div`
  align-self: center;
  width: 100%;
  max-width: 276px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const FeedbackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FeedbackName = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.infoLight};
`;

export const FeedbackRating = styled(RatingComponent)``;

export const FeedbackText = styled.p`
  font-size: 12px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const Text = styled.div`
  position: relative;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};
`;

export const Image = styled(ImageComponent)``;

export const Button = styled(ButtonComponent)`
  height: 50px;
  margin: 50px auto 30px;
  font-size: 18px;
  line-height: 21px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 70px;
  }
`;

export const Tooltip = styled(TooltipComponent)`
  display: inline-block;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.typography700};

  span {
    font-size: 12px;
    line-height: 140%;
  }
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.infoLight};
  cursor: pointer;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 20px;
    height: 20px;
  }
`;

export const Logo = styled(ImageComponent)`
  max-width: 100px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
`;
