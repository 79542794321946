import styled from 'styled-components';
import ButtonClose from 'core/common/components/ButtonClose';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 340px;
  max-width: 95vw;
  max-height: 95vh;
  border-radius: 20px;
  overflow: hidden;
  color: ${(props) => props.theme.colors.textPrimary};
  background: white;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 540px;
  }
`;

export const CloseButtonStyled = styled(ButtonClose)`
  margin-top: 20px;
  margin-right: 20px;
  margin-left: auto;
`;

export const Image = styled(ImageComponent)`
  margin-bottom: 32px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 55px;
    margin-bottom: 25px;
  }
`;

export const QuestionWrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 0.5px solid #d4c6e5;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 0;
    margin-left: 0;
    padding: 24px 40px;
    background: #d2d1f9;
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 44px;
    font-size: 24px;
    line-height: 145%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AgreeButton = styled(ButtonComponent)`
  font-size: 18px;
`;

export const DisagreeButton = styled(ButtonComponent)`
  margin-right: 11px;
  color: #1a1a1a;
  background: ${(props) => props.theme.colors.themeBackgroundLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 32px;
  }
`;

export const ImageContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
`;
