import { memo } from 'react';
import WheelPicker from 'core/common/components/WheelPicker';
import { hoursList, minutesList } from 'core/common/constants';
import { TimePart, Time } from 'core/common/entities';
import { CylinderWrapperStyled, PickerItemStyled } from './styled';

type CylinderTimePickerProps = {
  onChange: (value: Partial<Time>) => void;
  className?: string;
  time: Time;
};

const partList = [TimePart.AM, TimePart.PM];

export const CylinderTimePicker = memo(({ onChange, className, time }: CylinderTimePickerProps) => {
  const config = [
    {
      pickerProps: {
        name: 'hour',
        wheelSize: 20,
        values: hoursList,
        initIdx: hoursList.indexOf(time.hours),
        onChange: (value: string) => {
          onChange({ hours: value });
        },
      },
      ContainerStyled: PickerItemStyled,
    },
    {
      pickerProps: {
        name: 'minute',
        wheelSize: 20,
        values: minutesList,
        initIdx: minutesList.indexOf(time.minutes),
        onChange: (value: string) => onChange({ minutes: value }),
      },
      ContainerStyled: PickerItemStyled,
    },
    {
      pickerProps: {
        name: 'part',
        loop: false,
        wheelSize: 20,
        slidesPerView: 2,
        values: partList,
        initIdx: partList.indexOf(time.part),
        onChange: (value: string) => onChange({ part: value as TimePart }),
      },
      ContainerStyled: PickerItemStyled,
    },
  ];

  return (
    <CylinderWrapperStyled className={className}>
      {config.map(({ ContainerStyled, pickerProps }, index) => (
        <ContainerStyled key={index + pickerProps.name}>
          <WheelPicker {...pickerProps} />
        </ContainerStyled>
      ))}
    </CylinderWrapperStyled>
  );
});
