import styled from 'styled-components';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const IconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transition: transform 0.3s ease;
`;

export const TextStyled = styled.p`
  position: relative;
  width: 100%;
  margin-top: 12px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
`;

export const CollaspsibleSectionStyled = styled.div<{ height: number; expanded: boolean }>`
  position: relative;
  height: ${({ height }) => height}px;
  padding: 16px 12px;
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.typography100};
  transition: height 0.3s ease;
  cursor: pointer;

  ${TextStyled} {
    text-wrap: ${({ expanded }) => (expanded ? 'wrap' : 'nowrap')};
  }

  ${IconStyled} {
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const CaptionStyled = styled.span`
  width: 95%;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.typography500};
  text-wrap: nowrap;
`;

export const TitleStyled = styled.h3`
  font-size: 16px;
  font-weight: 600;
`;

export const ButtonStyled = styled.button`
  display: block;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  padding: 6px 16px;
  border: 1px solid ${({ theme }) => theme.colors.typography900};
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
`;

export const TextBlurredOverlayStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    ${({ theme }) => theme.colors.typography100} 100%
  );

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 70%;
  }
`;

export const ImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
`;
