import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';

export const BlurredReportWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
`;

export const BlurredReportBody = styled.div`
  position: relative;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  background: rgba(212, 198, 229, 0.4);

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-right: 26px;
    padding-left: 26px;
  }
`;

export const ReportTitle = styled.h3`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;
export const LogoImageBlock = styled.div`
  position: relative;
  height: 120px;
  margin-top: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 150px;
  }
`;

export const Item = styled.li`
  display: flex;
`;
export const NumberedList = styled.ol`
  margin-top: 12px;
  margin-bottom: 20px;
  counter-reset: item;
  list-style: none;

  & > ${Item}:before {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.typography700};
    content: counter(item) '. ';
    counter-increment: item;
  }
`;

export const ItemText = styled.p`
  display: inline;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const ContentBlock = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ContentTitle = styled.h5`
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const ContentText = styled.p`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography700};
`;

export const BlurOuterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 54%;
  padding-top: 32px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(251, 251, 255, 0) 3.34%,
    rgba(251, 251, 255, 0.8) 24.99%,
    rgba(251, 251, 255, 0.9) 45.63%,
    rgba(255, 251, 251, 0.9) 69.74%,
    rgba(231, 222, 255, 0.7) 89.93%
  );

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 48%;
    padding-top: 0;
  }
`;

export const BlurInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  padding-bottom: 6px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(251, 251, 255, 0) 0%,
    rgba(251, 251, 255, 0.18) 22.4%,
    rgba(251, 251, 255, 0.16) 43.75%,
    rgba(251, 251, 255, 0.14) 75%,
    rgba(251, 251, 255, 0.198) 89.58%
  );
  backdrop-filter: blur(1.5px);

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 20px;
  }
`;

export const AccessDescription = styled.p`
  width: 240px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.colors.info};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: auto;
  }
`;

export const Button = styled(ButtonComponent)`
  max-width: 360px;
  height: 56px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;
