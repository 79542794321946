import Rating from '../Rating';
import { HeroSectionStyled, TitleStyled } from './styled';

type HeroSectionProps = {
  title: string;
  text: string;
  rating: number;
};

const HeroSection = ({ title, text, rating }: HeroSectionProps) => {
  return (
    <HeroSectionStyled>
      <Rating title={text} rating={rating} />
      <TitleStyled>{title}</TitleStyled>
    </HeroSectionStyled>
  );
};

export default HeroSection;
