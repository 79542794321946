import DynamicAnswerV2Screen from 'core/common/components/QuizScreens/DynamicAnswerV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { DynamicAnswerV2ScreenAttributes } from 'core/funnel/entities';

type DynamicAnswerV2Props = {
  id: string;
};

const DynamicAnswerV2 = ({ id }: DynamicAnswerV2Props) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as DynamicAnswerV2ScreenAttributes;

  const onContinue = (answer: string) => {
    updateUserPreferences(dataKey, answer);
    next();
  };

  return (
    <DynamicAnswerV2Screen
      onContinue={onContinue}
      onBack={back}
      step={step}
      {...(screenAttributes as DynamicAnswerV2ScreenAttributes)}
    />
  );
};

export default DynamicAnswerV2;
