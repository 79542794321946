import { Trans } from 'react-i18next';
import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { SectionPaymentV2Attributes } from 'core/funnel/entities';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { europeanCountries, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { BoldStyled, ContentStyled, DescriptionStyled } from './styled';

export type SectionPaymentProps = {
  content: SectionPaymentV2Attributes['content'];
  isTimeUp: boolean;
  isOpen: boolean;
  offer: Offer;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const trialPeriod = '7-day';

const SectionPayment = (props: SectionPaymentProps) => {
  const { onClose, isTimeUp, onPaymentSuccess, offer, isOpen } = props;
  const { domainUrl } = useConfig();

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  if (!offer) {
    return null;
  }

  return (
    <>
      <PaymentDialog
        productId={offer.offerId}
        currency={offer.currency}
        isOpen={isOpen}
        onClose={onClose}
        onPaymentSuccess={onPaymentSuccess}
      >
        <ContentStyled>
          {isTimeUp ? (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.full`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.full`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />{' '}
                  The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.full`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />
                </DescriptionStyled>
              </LocationOption>
            </Location>
          ) : (
            <Location>
              <LocationOption option={[...europeanCountries]}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.US}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />{' '}
                  The charge will appear on your bill as {domainUrl}
                </DescriptionStyled>
              </LocationOption>
              <LocationOption option={Locales.OTHER}>
                <DescriptionStyled>
                  <Trans
                    i18nKey={`${tKeyPrefix}.charge.trial`}
                    values={{
                      price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                      trialPeriod: offer.trialPeriod,
                    }}
                    components={{
                      bold: <BoldStyled />,
                      br: <br />,
                    }}
                  />
                </DescriptionStyled>
              </LocationOption>
            </Location>
          )}
        </ContentStyled>
      </PaymentDialog>
    </>
  );
};

export default SectionPayment;
