import styled from 'styled-components';
import Image from 'core/common/components/Image';
import ProgressBar from 'core/common/components/ProgressBar';
import Typography from 'core/common/components/Typography';

const ageWrapperHeightPx = 40;

export const ContainerStyled = styled.div<{ applyTopGutter: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: ${({ applyTopGutter }) => (applyTopGutter ? '20px' : 0)};
  padding-bottom: 20px;
  text-align: center;
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 16px;
`;

export const SubtitleStyled = styled(Typography)`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const CaptionStyled = styled(Typography)`
  margin-bottom: 26px;
`;

export const ListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: auto;
`;

export const ListItemStyled = styled.li`
  position: relative;
  flex-grow: 1;
  width: calc(50% - 12px);
  margin-bottom: 12px;
  padding: 6px 6px ${ageWrapperHeightPx}px;
  border: 1px solid #c6c9e2;
  border-radius: 12px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:nth-child(odd) {
    margin-right: 12px;
  }

  &:hover {
    border-color: #63359c;
  }
`;

export const AgeWrapperStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${ageWrapperHeightPx}px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: #63359c;
`;

export const ImageStyled = styled(Image)`
  background-size: cover;
`;

export const FooterStyled = styled.footer`
  margin-top: 12px;
`;

export const ProgressBarStyled = styled(ProgressBar)`
  margin-bottom: 26px;
`;
