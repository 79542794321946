import discount from 'core/common/assets/images/additional-discount/palmistry-discount.png';
import { AdditionalDiscountPalmistryV2ScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Emoji,
  EmojiWrapper,
  Image,
  Item,
  List,
  OldPrice,
  Price,
  PriceWrapper,
  Text,
  Title,
} from './styled';

const AdditionalDiscountPalmistryV2Screen = ({
  content,
  discountOffer,
  timeUpOffer,
  onGetReport,
}: AdditionalDiscountPalmistryV2ScreenProps) => {
  const { title, list, button } = content;

  if (!discountOffer || !timeUpOffer) {
    return null;
  }

  return (
    <>
      <Container>
        <Image src={discount} alt="big discount percent" />
        <Title>{title}</Title>
        <PriceWrapper>
          <OldPrice>{timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}</OldPrice>
          <Price>{discountOffer.pricesWithCurrencySymbol.priceWithVAT}</Price>
        </PriceWrapper>
        <List>
          {list.map(({ text, emoji }) => {
            return (
              <Item key={text}>
                <EmojiWrapper>
                  <Emoji emoji={emoji} />
                </EmojiWrapper>
                <Text>{text}</Text>
              </Item>
            );
          })}
        </List>
        <Button onClick={onGetReport}>{button}</Button>
      </Container>
    </>
  );
};

export default AdditionalDiscountPalmistryV2Screen;
