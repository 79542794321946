import InformationalV2Screen from 'core/common/components/QuizScreens/InformationalV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { InformationalV2ScreenAttributes } from 'core/funnel/entities';

type InformationalV2Props = {
  id: string;
};

const InformationalV2 = ({ id }: InformationalV2Props) => {
  const { next, back, screenAttributes } = useQuizStep(id);

  return (
    <InformationalV2Screen
      goNext={next}
      goBack={back}
      {...(screenAttributes as InformationalV2ScreenAttributes)}
    />
  );
};

export default InformationalV2;
