import { useEffect } from 'react';
import UnlimitedReadingsScreen from 'core/common/components/QuizScreens/UnlimitedReadings';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UnlimitedReadingsScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UnlimitedReadingsProps = {
  id: string;
};

const UnlimitedReadings = ({ id }: UnlimitedReadingsProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { premiumUpsellOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    if (!premiumUpsellOffer) return;

    analyticsService.upsellPremiumScreenClicked();
    recurringPaymentRequest(premiumUpsellOffer.offerId);
  };

  const skip = () => {
    next();
  };

  useEffect(() => {
    analyticsService.upsellPremiumScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.upsellPremiumScreenGetReport(
        premiumUpsellOffer ? premiumUpsellOffer.offerId : '',
      );
      next();
    }
  }, [paymentProcessStep]);

  return (
    <UnlimitedReadingsScreen
      plan={premiumUpsellOffer}
      onGetReport={getReport}
      onSkip={skip}
      isLoading={isPurchaseLoading}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UnlimitedReadingsScreenAttributes)}
    />
  );
};

export default UnlimitedReadings;
