import { SectionUpsellConsultationBenefitsAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  AspectRatioStyled,
  ImageStyled,
  ListImageStyled,
  ListStyled,
  ListItemStyled,
  BenefitTitleStyled,
  BenefitTextStyled,
} from './styled';

export type SectionUpsellConsultationBenefitsProps = {
  content: SectionUpsellConsultationBenefitsAttributes['content'];
  className?: string;
};

const SectionUpsellConsultationBenefits = ({
  content,
  className,
}: SectionUpsellConsultationBenefitsProps) => {
  const { title, image, benefits } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <AspectRatioStyled>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </AspectRatioStyled>
      <ListStyled>
        {benefits.map((item) => (
          <ListItemStyled key={item.title}>
            <ListImageStyled src={item.icon.src} alt={item.icon.alt} width={32} height={32} />
            <BenefitTitleStyled>{item.title}</BenefitTitleStyled>
            <BenefitTextStyled>{item.text}</BenefitTextStyled>
          </ListItemStyled>
        ))}
      </ListStyled>
    </WrapperStyled>
  );
};

export default SectionUpsellConsultationBenefits;
