import styled, { keyframes } from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import RatingComponent from 'core/common/components/Rating';

const hiddenText = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const BackgroundStyled = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.gradients.secondary};
    content: '';
  }
`;

export const ContainerStyled = styled.div`
  position: relative;
  max-width: 334px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography100};
  animation-name: ${hiddenText};
  animation-duration: 6s;
`;

export const SkipBlockStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

export const IosContainerStyled = styled(ContainerStyled)`
  width: 100%;
  animation-name: unset;
  animation-duration: unset;
`;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 55px;
`;

export const IosContentStyled = styled(ContentStyled)`
  height: 210px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
`;

export const MobileAppImageStyled = styled(Image)`
  position: absolute;
  transform: translateY(-50%);
`;

export const TextStyled = styled.p`
  margin-bottom: 100px;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;

  span {
    display: block;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 20px;
    color: ${(props) => props.theme.colors.primary400};
  }
`;

export const CornerSkipIosTextStyled = styled.p`
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  font-size: 20px;
  font-weight: 700;
  text-align: start;

  span {
    color: ${(props) => props.theme.colors.warning};
  }
`;

export const SmallIosTextStyled = styled(CornerSkipIosTextStyled)`
  margin-top: 12px;
  padding-top: 0;
  font-size: 16px;
  font-weight: 400;
`;

export const FooterStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${({ theme }) => theme.colors.typography200};
  background-color: ${({ theme }) => theme.colors.secondary800};
`;

export const RatingBlockStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  margin-top: 40px;
`;

export const RatingContentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;
`;

export const RatingBodyStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RatingFooterBlockStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingFooterTextStyled = styled.span`
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
`;

export const AppstoreTextStyled = styled.span`
  margin-left: 2px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.typography100};
`;

export const RatingAmountStyled = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
`;
export const RatingStarsStyled = styled(RatingComponent)`
  margin-left: 6px;
`;

export const RightWingImageStyled = styled(Image)`
  transform: scaleX(-1);
`;

export const FooterTextStyled = styled.p`
  margin-top: 34px;
  font-size: 20px;
  font-weight: 700;

  span {
    color: ${(props) => props.theme.colors.warning};
  }
`;

export const DownloadButtonStyled = styled(Button)`
  height: 40px;
  margin-top: 16px;
  border-radius: 30px;
  font-weight: 700;
  background: ${({ theme }) => theme.gradients.secondaryLight};
`;

export const CornerSkipButtonStyled = styled(TextButton)`
  margin-right: 0;
  margin-left: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography300};
`;
