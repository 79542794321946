import SummaryV2Screen from 'core/common/components/QuizScreens/SummaryV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { SummaryV2ScreenAttributes } from 'core/funnel/entities';

type SummaryV2Props = {
  id: string;
};

const SummaryV2 = ({ id }: SummaryV2Props) => {
  const { back, next, screenAttributes } = useQuizStep(id);

  return (
    <SummaryV2Screen
      goNext={next}
      goBack={back}
      {...(screenAttributes as SummaryV2ScreenAttributes)}
    />
  );
};

export default SummaryV2;
