import styled from 'styled-components';
import ImageComponent from 'core/common/components/Image';

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
`;

export const Title = styled.h3`
  margin-bottom: 33px;
  font-size: 22px;
  line-height: 145%;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const Image = styled(ImageComponent)`
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  width: 50%;
`;

export const WrapperRightAlign = styled(Wrapper)`
  text-align: right;
`;

export const WrapperWellGood = styled.div`
  width: 100%;
  text-align: center;
`;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BustleWrapper = styled.div`
  padding-left: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
