import { ChangeEvent } from 'react';
import {
  ProductCardRadioButton,
  ProductCardRadioButtonDesktop,
} from 'core/common/components/ProductCardRadioButton';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionUpsellOffersAttributes } from 'core/funnel/entities';
import { UpsellV2 } from 'core/offers/entities';
import { useIsMobile } from 'core/theme/hooks';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled,
  ListStyled,
  ListItemStyled,
  FooterStyled,
  ButtonStyled,
  SkipButtonStyled,
  DisclaimerStyled,
} from './styled';

export type SectionUpsellOffersProps = {
  content: SectionUpsellOffersAttributes['content'];
  offers: Array<UpsellV2>;
  className?: string;
  selectedOfferId: string;
  isPurchaseLoading: boolean;
  onSkip: () => void;
  onSubmit: () => void;
  onOfferSelect: (id: string) => void;
};

const SectionUpsellOffers = ({
  content,
  offers,
  className,
  selectedOfferId,
  isPurchaseLoading,
  onSkip,
  onSubmit,
  onOfferSelect,
}: SectionUpsellOffersProps) => {
  const { title, subtitle, buttonText, skipButtonText, disclaimer } = content;

  const { isMobile } = useIsMobile();

  const handleCheckOffer = (e: ChangeEvent<HTMLInputElement>) => {
    onOfferSelect(e.target.id);
  };

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
      <ListStyled>
        {offers.map((offer) => (
          <ListItemStyled key={offer.offerId}>
            {isMobile ? (
              <ProductCardRadioButton
                name="upsell"
                id={offer.offerId}
                value={offer.offerId}
                checked={selectedOfferId === offer.offerId}
                title={offer.title}
                price={offer.price}
                details={offer.details}
                oldPrice={offer.oldPrice}
                discount={offer.discount.text}
                backgroundImage={offer.backgroundImage}
                onChange={handleCheckOffer}
              />
            ) : (
              <ProductCardRadioButtonDesktop
                name="upsell"
                id={offer.offerId}
                value={offer.offerId}
                checked={selectedOfferId === offer.offerId}
                title={offer.title}
                price={offer.price}
                details={offer.details}
                subtitle={offer.subtitle}
                oldPrice={offer.oldPrice}
                discount={offer.discount.text}
                billingCaption={offer.billingCaption}
                backgroundImage={offer.backgroundImage}
                onChange={handleCheckOffer}
              />
            )}
          </ListItemStyled>
        ))}
      </ListStyled>
      <FooterStyled>
        <ButtonStyled
          fullWidth
          onClick={onSubmit}
          loading={isPurchaseLoading}
          disabled={isPurchaseLoading}
        >
          {buttonText}
        </ButtonStyled>
        <SkipButtonStyled onClick={onSkip}>{skipButtonText}</SkipButtonStyled>
      </FooterStyled>
      {disclaimer && <DisclaimerStyled dangerouslySetInnerHTML={prepareHtml(disclaimer)} />}
    </WrapperStyled>
  );
};

export default SectionUpsellOffers;
