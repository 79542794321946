import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

export enum SwipeAnimationDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  IDLE = 'IDLE',
}

type UseSwipesParams = {
  onSwipe: () => void;
};

export const useSectionDeckSlider = ({ onSwipe }: UseSwipesParams) => {
  const [swipeAnimationDirection, setSwipeAnimationDirection] = useState<SwipeAnimationDirection>(
    SwipeAnimationDirection.IDLE,
  );

  const swipeHandlers = useSwipeable({
    preventScrollOnSwipe: true,
    onSwipedLeft: () => handleCardsReorder(SwipeAnimationDirection.LEFT),
    onSwipedRight: () => handleCardsReorder(SwipeAnimationDirection.RIGHT),
  });

  const handleCardsReorder = (direction: SwipeAnimationDirection) => {
    if (swipeAnimationDirection !== SwipeAnimationDirection.IDLE) return;

    setSwipeAnimationDirection(direction);

    setTimeout(() => {
      setSwipeAnimationDirection(SwipeAnimationDirection.IDLE);
      onSwipe();
    }, 900);
  };

  return { swipeHandlers, swipeAnimationDirection, handleCardsReorder };
};
