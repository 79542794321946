import styled from 'styled-components';
import TitleComponent from 'core/common/components/Title';

export const DetailsListContainerStyled = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  border-radius: 0 0 20px 20px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const List = styled.ul`
  flex: 1;
  padding: 8px 16px 12px;
`;

export const SingleList = styled(List)`
  padding: 8px 12px 12px;
`;

export const ListItemStyled = styled.li`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const ItemTitleStyled = styled.h6`
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  color: #454895;
`;

export const ItemTextStyled = styled.p`
  margin-top: 4px;
  font-size: 14px;
  line-height: 130%;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const VerticalLine = styled.div`
  width: 1px;
  margin: 0 13px;
  border: 1px solid #d4c6e5;
`;

export const TitleStyled = styled(TitleComponent)`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
`;

export const ContainerLightStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin: 10px auto 40px;
  padding: 0 15px;
  background: ${(props) => props.theme.colors.themeBackgroundLight};
`;
