import { SectionUpsellConsultationOfferAttributes } from 'core/funnel/entities';
import Offer from './Offer';
import {
  WrapperStyled,
  ImageStyled,
  TitleStyled,
  AspectRatioStyled,
  RatingStyled,
  RatingIconStyled,
  RatingTextStyled,
  FloatingPurchaseButtonStyled,
} from './styled';

export type SectionUpsellConsultationOfferProps = {
  content: SectionUpsellConsultationOfferAttributes['content'];
  isLoading: boolean;
  className?: string;
  price: string;
  trialPrice: string;
  isFloatingButtonVisible: boolean;
  onGetOffer: () => void;
  onSkipOffer: () => void;
};

const SectionUpsellConsultationOffer = ({
  content,
  className,
  isLoading,
  price,
  trialPrice,
  isFloatingButtonVisible,
  onGetOffer,
  onSkipOffer,
}: SectionUpsellConsultationOfferProps) => {
  const { title, image, rating, offer } = content;

  return (
    <WrapperStyled className={className}>
      <RatingStyled>
        <RatingIconStyled src={rating.icon.src} alt={rating.icon.alt} width={18} height={18} />
        <RatingTextStyled>{rating.text}</RatingTextStyled>
      </RatingStyled>
      <AspectRatioStyled>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </AspectRatioStyled>
      <TitleStyled>{title}</TitleStyled>
      <Offer
        list={offer.list}
        isLoading={isLoading}
        discount={offer.discount}
        price={price}
        priceWithDiscount={trialPrice}
        disclaimer={offer.disclaimer}
        buttonText={offer.buttonText}
        skipButtonText={offer.skipButtonText}
        onGetOffer={onGetOffer}
        onSkipOffer={onSkipOffer}
      />
      {isFloatingButtonVisible && (
        <FloatingPurchaseButtonStyled
          fullWidth
          loading={isLoading}
          disabled={isLoading}
          onClick={onGetOffer}
        >
          {offer.buttonText}
        </FloatingPurchaseButtonStyled>
      )}
    </WrapperStyled>
  );
};

export default SectionUpsellConsultationOffer;
