import styled from 'styled-components';
import Button, { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';

export const WrapperStyled = styled.div`
  position: relative;
  padding: 40px 16px 16px;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const ListStyled = styled.ul`
  margin-bottom: 16px;
`;

export const ListItemStyled = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ListIconStyled = styled(Image)`
  flex-shrink: 0;
  margin-right: 8px;
`;

export const ListTextStyled = styled.span`
  font-size: 14px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const DiscountStyled = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border-bottom-left-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  background-color: #f3ac0d;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const PriceWrapperStyled = styled.div`
  margin-bottom: 16px;
`;

export const PriceStyled = styled.span`
  margin-right: 8px;
  font-size: 14px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.error600};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const PriceWithDiscountStyled = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const DisclaimerStyled = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-top: 12px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const SkipButtonStyled = styled(TextButton)`
  width: 100%;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};
`;
