import styled from 'styled-components';
import AnimatedImage from 'core/common/components/AnimatedImages/AnimatedImage';
import { InfoButton } from 'core/common/components/Quiz/styled';
import TitleComponent from 'core/common/components/Title';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;

export const MobileImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const DesktopImageWrapper = styled.div`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
  }
`;

export const Title = styled(TitleComponent)`
  align-self: flex-start;
  font-size: 24px;
  line-height: 28px;
`;

export const Text = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography100};
`;

export const ButtonsWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const Button = styled(InfoButton)`
  height: 49px;
  margin: 0;
  border-radius: 16px;
`;

export const PrevButton = styled(Button)`
  margin-right: 10px;
  border: ${(props) => `1px solid ${props.theme.colors.dark}`};
  color: ${(props) => props.theme.colors.dark};
  background: transparent;
`;

export const NextButton = styled(Button)`
  color: ${(props) => props.theme.colors.info};
  background: ${(props) => props.theme.colors.typography100};
`;

export const StyledAnimatedImage = styled(AnimatedImage)`
  width: 250px;
  height: 250px;
  margin-bottom: 40px;
`;
