import Header from 'core/common/components/Header';
import { UserTypeHumanDesignScreenProps } from 'core/funnel/entities';
import {
  ImageStyled,
  ContentStyled,
  DescriptionStyled,
  TitleStyled,
  UserTypeStyled,
  FooterStyled,
  CTATextStyled,
  ButtonStyled,
  ImageWrapperStyled,
} from './styled';

const UserTypeHumanDesignScreen = ({ content, goNext, goBack }: UserTypeHumanDesignScreenProps) => {
  const { image, title, humanDesignType, humanDesignDescription, ctaText, button } = content;

  return (
    <>
      <Header onBack={goBack} />
      <ContentStyled>
        <ImageWrapperStyled>
          <ImageStyled src={image.src} alt={image.alt} fill />
        </ImageWrapperStyled>
        <TitleStyled>{title}</TitleStyled>
        <UserTypeStyled>{humanDesignType}</UserTypeStyled>
        <DescriptionStyled>{humanDesignDescription}</DescriptionStyled>
        <FooterStyled>
          <CTATextStyled>{ctaText}</CTATextStyled>
          <ButtonStyled fullWidth onClick={goNext}>
            {button}
          </ButtonStyled>
        </FooterStyled>
      </ContentStyled>
    </>
  );
};

export default UserTypeHumanDesignScreen;
