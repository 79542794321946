import styled from 'styled-components';
import Image from 'core/common/components/Image';

export const MessageWrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 286px;
  margin-bottom: 64px;
  padding-right: 16px;
  padding-left: 16px;
`;

export const MessageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(-1);
`;

export const DescriptionStyled = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography100};

  b {
    color: ${({ theme }) => theme.colors.primary400};
  }
`;
