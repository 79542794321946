import ellipse from 'core/common/assets/images/additional-discount/ellipse.png';
import Image from 'core/common/components/Image';
import { AdditionalDiscountScreenProps } from 'core/funnel/entities';
import {
  Button,
  Container,
  Emoji,
  Highlight,
  ImageBackgroundContainer,
  ImageContainer,
  Item,
  List,
  Price,
  Text,
  Title,
} from './styled';

const AdditionalDiscountScreen = ({
  content,
  discountOffer,
  timeUpOffer,
  onContinue,
}: AdditionalDiscountScreenProps) => {
  const { title, plan, trial, priceText, button, image } = content;

  if (!discountOffer || !timeUpOffer) {
    return null;
  }

  return (
    <>
      <ImageBackgroundContainer>
        <Image src={ellipse} alt="red sun" />
      </ImageBackgroundContainer>
      <Container>
        <Title>{title}</Title>
        <ImageContainer>
          <Image src={image.src} width={218} height={180} alt={image.alt} />
        </ImageContainer>
        <List>
          <Item>
            <Emoji emoji={plan.emoji} />
            <Text>{plan.text}</Text>
          </Item>
          <Item>
            <Emoji emoji={trial.emoji} />
            <Text>{trial.text}</Text>
          </Item>
        </List>
        <Price>
          <Highlight>{discountOffer.pricesWithCurrencySymbol.priceWithVAT}</Highlight> {priceText}{' '}
          {timeUpOffer.pricesWithCurrencySymbol.priceWithVAT}
        </Price>
        <Button onClick={onContinue}>{button}</Button>
      </Container>
    </>
  );
};

export default AdditionalDiscountScreen;
