import styled from 'styled-components';
import ProcessingLoader from 'core/common/components/ProcessingLoader';
import Title from 'core/common/components/Title';

export const StyledTitle = styled(Title)`
  z-index: 1;
  max-width: 330px;
  margin: 20px auto 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 160%;

  & > strong {
    display: inline;
    text-transform: capitalize;
    color: #f2c94c !important;
  }
`;

export const Zodiac = styled(StyledTitle)`
  display: inline;
  text-transform: capitalize;
  color: #f2c94c !important;
`;

export const Subtitle = styled.h2`
  z-index: 1;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.primary200};
`;

export const Divider = styled.div`
  z-index: 2;
  width: 318px;
  height: 1px;
  margin: 16px auto 25px;
  background: ${(props) => props.theme.colors.infoLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 40px;
  }
`;

export const StyledProcessingLoader = styled(ProcessingLoader)`
  margin-bottom: 40px;
`;
