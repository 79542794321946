import InformationalNatalChartScreen from 'core/common/components/QuizScreens/InformationalNatalChart';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { InformationalScreenNatalChartAttributes } from 'core/funnel/entities';

type InformationalNatalChartProps = {
  id: string;
};

const InformationalNatalChart = ({ id }: InformationalNatalChartProps) => {
  const { back, screenAttributes, next } = useQuizStep(id);

  return (
    <InformationalNatalChartScreen
      onBack={back}
      goNext={next}
      {...(screenAttributes as InformationalScreenNatalChartAttributes)}
    />
  );
};

export default InformationalNatalChart;
