import { FunnelImage } from 'core/funnel/entities';
import { IconNameOrString, IconNames } from 'core/svg-sprites/entities';
import UserData from './UserData';
import UserTraumaScore from './UserTraumaScore';
import {
  WrapperStyled,
  TitleStyled,
  SubtitleStyled,
  GenderStyled,
  ZodiacStyled,
  ZodiacIconStyled,
  ContentStyled,
} from './styled';

export type ListItemContent = {
  icon: IconNameOrString;
  title: string;
  value: string;
};

type ProfileProps = {
  title: string;
  gender: string;
  zodiac?: string;
  birthDate?: ListItemContent;
  birthTime: ListItemContent;
  birthPlace: ListItemContent;
  trauma: {
    title: string;
    value: string;
    image: FunnelImage;
  };
  traumaScore: number;
  zodiacIcon: IconNames;
};

const Profile = ({
  title,
  gender,
  zodiac,
  birthDate,
  birthTime,
  birthPlace,
  trauma,
  traumaScore,
  zodiacIcon,
}: ProfileProps) => {
  return (
    <WrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <SubtitleStyled>
        <GenderStyled>{gender}</GenderStyled>
        {zodiac && (
          <ZodiacStyled>
            <ZodiacIconStyled name={zodiacIcon} width={16} height={16} />
            {zodiac}
          </ZodiacStyled>
        )}
      </SubtitleStyled>
      <ContentStyled>
        <UserData birthDate={birthDate} birthTime={birthTime} birthPlace={birthPlace} />
        <UserTraumaScore {...trauma} traumaScore={traumaScore} />
      </ContentStyled>
    </WrapperStyled>
  );
};

export default Profile;
