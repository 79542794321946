import Header from 'core/common/components/Header';
import { MoonPhaseScreenProps } from 'core/funnel/entities';
import QuizBackground from '../../QuizBackground';
import {
  ContentWrapperStyled,
  ButtonStyled,
  ImageWrapperStyled,
  TitleStyled,
  DescriptionStyled,
  ImageStyled,
} from './styled';

const MoonPhaseScreen = ({ onBack, goNext, content, moonImage }: MoonPhaseScreenProps) => {
  const { title, description, backgroundImage, next } = content;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <QuizBackground variant="dark" backgroundImage={backgroundImage} />
      <ContentWrapperStyled>
        <ImageWrapperStyled>
          <ImageStyled src={moonImage.src} alt={moonImage.alt} fill priority />
        </ImageWrapperStyled>
        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
        <ButtonStyled variant="secondary" onClick={goNext}>
          {next}
        </ButtonStyled>
      </ContentWrapperStyled>
    </>
  );
};

export default MoonPhaseScreen;
