import { IconStyled, ItemStyled, ItemTextStyled, ListStyled } from './styled';

export type SectionBenefitsProps = {
  benefits: string[];
};

const SectionBenefits = ({ benefits }: SectionBenefitsProps) => {
  return (
    <ListStyled>
      {benefits.map((item) => (
        <ItemStyled key={item}>
          <IconStyled name="check" width={20} height={20} />
          <ItemTextStyled>{item}</ItemTextStyled>
        </ItemStyled>
      ))}
    </ListStyled>
  );
};

export default SectionBenefits;
