import { useState } from 'react';
import Header from 'core/common/components/Header/Header';
import QuizBackground from 'core/common/components/QuizBackground/QuizBackground';
import IntroOffer from 'core/common/components/QuizScreensSections/SectionIntroOffer';
import NebulaRating from 'core/common/components/QuizScreensSections/SectionNebulaRating';
import SectionReviewsCarousel from 'core/common/components/QuizScreensSections/SectionReviewsCarousel';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { ReviewsCardAvatar } from 'core/common/components/ReviewsCards';
import { useInView } from 'core/common/hooks';
import { scrollToBlock } from 'core/common/utils/scroll';
import { TrialPaymentAttachmentStyleScreenProps } from 'core/funnel/entities';
import DiscountHeader from './DiscountHeader';
import DiscountModal from './DiscountModal';
import Faq from './Faq';
import Plan from './Plan';
import Profile from './Profile';
import ProfileShort from './ProfileShort';
import SectionPayment from './SectionPayment';
import {
  ContainerMainStyled,
  TitleStyled,
  AdvantagesStyled,
  PartnersStyled,
  HighlightsStyled,
  ReviewsCarouselWrapperStyled,
  IntroOfferWrapperStyled,
  SectionGuaranteeStyled,
  SectionPaymentStyled,
} from './styled';

const subscriptionBlockId = 'subscription-block-attachment-style';
const scrollOffsetPx = -100;

const TrialPaymentAttachmentStyleScreen = ({
  content,
  timeLeft,
  traumaScore,
  defaultOffers,
  timeUpOffers,
  selectedOffer,
  offer,
  isTimeUp,
  goNext,
  onClosePaymentModal,
  onOfferChanged,
  getReport,
  onCloseDiscountModal,
  navigateToDiscountPage,
  zodiacIcon,
}: TrialPaymentAttachmentStyleScreenProps) => {
  const {
    title,
    rating,
    header,
    profile,
    plan,
    advantages,
    introOffer,
    highlights,
    featuredIn,
    faq,
    reviews,
    guarantee,
    discountModal,
    appRating,
  } = content;

  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [displayDiscountModal, setDisplayDiscountModal] = useState(false);
  const { ref: reviewsSectionRef, inView: reviewsSectionVisible } = useInView();

  const onPaymentModalClose = () => {
    setDisplayPaymentModal(false);
    setDisplayDiscountModal(true);
    onClosePaymentModal();
  };

  const onDiscountModalClose = () => {
    setDisplayDiscountModal(false);
    onCloseDiscountModal();
  };

  const onGetReport = () => {
    setDisplayPaymentModal(true);
    getReport();
  };

  if (!timeUpOffers || !defaultOffers || !offer) {
    return null;
  }

  const moveToOffers = () => {
    scrollToBlock(subscriptionBlockId, scrollOffsetPx);
  };

  return (
    <>
      <QuizBackground variant="light" />
      <DiscountHeader
        timeLeft={timeLeft}
        buttonText={header.buttonText}
        discountValue={header.discountValue}
        minLabel={header.minLabel}
        isTimeUp={isTimeUp}
        secLabel={header.secLabel}
        onGetPlan={moveToOffers}
      />
      <Header buttonBack={false} />
      <ContainerMainStyled>
        <NebulaRating content={rating} variant="dark" />
        <Plan {...plan} />
        <TitleStyled>{title}</TitleStyled>
        {content.profile.zodiac ? (
          <Profile {...profile} traumaScore={traumaScore} zodiacIcon={zodiacIcon} />
        ) : (
          <ProfileShort {...profile} traumaScore={traumaScore} />
        )}
        <AdvantagesStyled {...advantages} />
        <IntroOfferWrapperStyled id={subscriptionBlockId}>
          <IntroOffer
            content={introOffer}
            isTimeUp={isTimeUp}
            chosenPlan={offer}
            selectedPlan={selectedOffer}
            defaultOffers={defaultOffers}
            timeUpOffers={timeUpOffers}
            onGetReport={onGetReport}
            onPlanChanged={onOfferChanged}
          />
        </IntroOfferWrapperStyled>
        <HighlightsStyled content={highlights} />
        <PartnersStyled content={featuredIn} />
        <Faq {...faq} />
        <ReviewsCarouselWrapperStyled ref={reviewsSectionRef}>
          <SectionReviewsCarousel
            content={reviews}
            shouldPlayAnimation={reviewsSectionVisible}
            renderReviewCard={({ date, review, userName, userAvatar }) => {
              if (!userAvatar) {
                return null;
              }

              return (
                <ReviewsCardAvatar
                  date={date}
                  review={review}
                  userName={userName}
                  userAvatar={userAvatar}
                />
              );
            }}
          />
        </ReviewsCarouselWrapperStyled>
        <IntroOffer
          content={introOffer}
          isTimeUp={isTimeUp}
          chosenPlan={offer}
          selectedPlan={selectedOffer}
          defaultOffers={defaultOffers}
          timeUpOffers={timeUpOffers}
          onGetReport={onGetReport}
          onPlanChanged={onOfferChanged}
        />
        {appRating && <SectionPaymentStyled content={appRating} />}
        <SectionGuaranteeStyled content={guarantee} />
      </ContainerMainStyled>
      <DiscountModal
        isOpen={displayDiscountModal}
        onClose={onDiscountModalClose}
        onConfirm={navigateToDiscountPage}
        {...discountModal}
      />
      {!!offer && (
        <RenderOnlyOnClient>
          <SectionPayment
            onPaymentSuccess={goNext}
            isTimeUp={isTimeUp}
            isOpen={displayPaymentModal}
            offer={offer}
            onClose={onPaymentModalClose}
          />
        </RenderOnlyOnClient>
      )}
    </>
  );
};

export default TrialPaymentAttachmentStyleScreen;
