import { ChangeEvent, useCallback, useState } from 'react';
import Header from 'core/common/components/Header';
import Input from 'core/common/components/Input';
import ProgressBar from 'core/common/components/ProgressBarNatalChart';
import { Image } from 'core/common/components/Quiz/styled';
import QuizBackground from 'core/common/components/QuizBackground';
import { useServices } from 'core/common/hooks';
import { debounce } from 'core/common/utils/debounce';
import { PlaceListData, PlaceNatalChartScreenProps } from 'core/funnel/entities';
import PlaceList from './PlaceList';
import {
  AutocompleteContainerStyled,
  ButtonStyled,
  ContainerStyled,
  ContentStyled,
  HideButtonStyled,
  InputBlockStyled,
  SkipStyled,
  TextStyled,
  TitleStyled,
} from './styled';

const PlaceNatalChartScreen = ({
  content,
  skipData,
  initialCoordinates,
  initialLocation,
  totalSteps,
  onSubmit,
  onBack,
  step,
}: PlaceNatalChartScreenProps) => {
  const { placeholder, image, backgroundImage, title, text, next, cancel } = content;

  const { autocompleteService } = useServices();
  const [location, setLocation] = useState(initialLocation);
  const [place, setPlace] = useState(initialCoordinates);
  const [citiesList, setPlaceListData] = useState<Array<PlaceListData>>([]);
  const [isVisibleContent, setVisibleContent] = useState(true);

  const handleInputClick = () => setVisibleContent(false);

  const handleCancelClick = () => {
    if (!location) setPlace([]);
    closePlaceListData();
  };

  const closePlaceListData = () => {
    setPlaceListData([]);
    setVisibleContent(true);
  };

  const handleButtonClick = () => {
    onSubmit(place, location);
  };

  const handleSkipClick = (defaultPlace: number[], defaultLocation: string) => {
    onSubmit(defaultPlace, defaultLocation);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    await debouncedGeolocation(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGeolocation = useCallback(
    debounce(async (value) => {
      if (value) {
        const features = await autocompleteService.getFeatures(value);
        return setPlaceListData(features);
      }

      return setPlaceListData([]);
    }, 500),
    [],
  );

  const handleSelectedItem = useCallback((cityPlace: number[], cityLocation: string) => {
    // TODO: Think maybe create one state object for all this states
    setPlace(cityPlace);
    setLocation(cityLocation);
    setPlaceListData([]);
    closePlaceListData();
  }, []);

  return (
    <>
      <QuizBackground backgroundImage={backgroundImage} />
      <Header buttonBack={false} />
      <ProgressBar currentStep={step} totalSteps={totalSteps} onBack={onBack} />
      <ContainerStyled>
        <ContentStyled>
          {!!image && <Image src={image.src} alt={image.alt || 'place'} width={203} height={203} />}
          {title && <TitleStyled>{title}</TitleStyled>}
          <TextStyled>{text}</TextStyled>
          <AutocompleteContainerStyled>
            <InputBlockStyled>
              <Input
                placeholder={placeholder ?? 'Enter place of birth'}
                onChange={handleInputChange}
                value={location}
                onClick={handleInputClick}
              />
              {!isVisibleContent && (
                <HideButtonStyled onClick={handleCancelClick}>{cancel}</HideButtonStyled>
              )}
            </InputBlockStyled>
            <PlaceList onSelected={handleSelectedItem} citiesList={citiesList} />
          </AutocompleteContainerStyled>
          {isVisibleContent && (
            <ButtonStyled onClick={handleButtonClick} disabled={!place.length}>
              {next}
            </ButtonStyled>
          )}
        </ContentStyled>
        {!!skipData && (
          <SkipStyled
            role="button"
            onClick={() => handleSkipClick(skipData.defaultCoordinates, skipData.defaultPlace)}
          >
            {skipData.text}
          </SkipStyled>
        )}
      </ContainerStyled>
    </>
  );
};
export default PlaceNatalChartScreen;
