import styled from 'styled-components';
import image from 'core/common/assets/images/flags-sprite.png';

export const CountryFlagSpriteWrapper = styled.div`
  /* stylelint-disable number-max-precision */

  position: relative;
  display: inline-block;
  width: 1em;
  border-radius: 2px;
  overflow: hidden;
  font-size: 24px;
  line-height: 0;
  vertical-align: middle;

  .flagPlaceholder {
    display: inline-block;
    width: 100%;
    padding-top: 75%;
  }

  .flagImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    background-repeat: no-repeat;
  }

  .flag_ac {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 0.11976047904191617%;
  }

  .flag_ad {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 0.11976047904191617%;
  }

  .flag_ae {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 0.11976047904191617%;
  }

  .flag_af {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 0.11976047904191617%;
  }

  .flag_ag {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 0.11976047904191617%;
  }

  .flag_ai {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 0.11976047904191617%;
  }

  .flag_al {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 0.11976047904191617%;
  }

  .flag_am {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 0.11976047904191617%;
  }

  .flag_ao {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 0.11976047904191617%;
  }

  .flag_aq {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 0.11976047904191617%;
  }

  .flag_ar {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 0.11976047904191617%;
  }

  .flag_as {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 0.11976047904191617%;
  }

  .flag_at {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 0.11976047904191617%;
  }

  .flag_au {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 0.11976047904191617%;
  }

  .flag_aw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 0.11976047904191617%;
  }

  .flag_ax {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 5.9880239520958085%;
  }

  .flag_az {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 5.9880239520958085%;
  }

  .flag_ba {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 5.9880239520958085%;
  }

  .flag_bb {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 5.9880239520958085%;
  }

  .flag_bd {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 5.9880239520958085%;
  }

  .flag_be {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 5.9880239520958085%;
  }

  .flag_bf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 5.9880239520958085%;
  }

  .flag_bg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 5.9880239520958085%;
  }

  .flag_bh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 5.9880239520958085%;
  }

  .flag_bi {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 5.9880239520958085%;
  }

  .flag_bj {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 5.9880239520958085%;
  }

  .flag_bl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 5.9880239520958085%;
  }

  .flag_bm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 5.9880239520958085%;
  }

  .flag_bn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 5.9880239520958085%;
  }

  .flag_bo {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 5.9880239520958085%;
  }

  .flag_bq {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 11.8562874251497%;
  }

  .flag_br {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 11.8562874251497%;
  }

  .flag_bs {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 11.8562874251497%;
  }

  .flag_bt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 11.8562874251497%;
  }

  .flag_bv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 11.8562874251497%;
  }

  .flag_bw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 11.8562874251497%;
  }

  .flag_by {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 11.8562874251497%;
  }

  .flag_bz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 11.8562874251497%;
  }

  .flag_ca {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 11.8562874251497%;
  }

  .flag_cc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 11.8562874251497%;
  }

  .flag_cd {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 11.8562874251497%;
  }

  .flag_cefta {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 11.8562874251497%;
  }

  .flag_cf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 11.8562874251497%;
  }

  .flag_cg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 11.8562874251497%;
  }

  .flag_ch {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 11.8562874251497%;
  }

  .flag_ci {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 17.724550898203592%;
  }

  .flag_ck {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 17.724550898203592%;
  }

  .flag_cl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 17.724550898203592%;
  }

  .flag_cm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 17.724550898203592%;
  }

  .flag_cn,
  .flag_zh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 17.724550898203592%;
  }

  .flag_co {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 17.724550898203592%;
  }

  .flag_cp {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 17.724550898203592%;
  }

  .flag_cr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 17.724550898203592%;
  }

  .flag_cu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 17.724550898203592%;
  }

  .flag_cv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 17.724550898203592%;
  }

  .flag_cw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 17.724550898203592%;
  }

  .flag_cx {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 17.724550898203592%;
  }

  .flag_cy {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 17.724550898203592%;
  }

  .flag_cz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 17.724550898203592%;
  }

  .flag_de {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 17.724550898203592%;
  }

  .flag_dg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 23.592814371257486%;
  }

  .flag_dj {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 23.592814371257486%;
  }

  .flag_dk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 23.592814371257486%;
  }

  .flag_dm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 23.592814371257486%;
  }

  .flag_do {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 23.592814371257486%;
  }

  .flag_dz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 23.592814371257486%;
  }

  .flag_ea {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 23.592814371257486%;
  }

  .flag_ec {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 23.592814371257486%;
  }

  .flag_ee {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 23.592814371257486%;
  }

  .flag_eg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 23.592814371257486%;
  }

  .flag_eh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 23.592814371257486%;
  }

  .flag_er {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 23.592814371257486%;
  }

  .flag_es {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 29.461077844311376%;
  }

  .flag_es-ct {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 23.592814371257486%;
  }

  .flag_es-ga {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 23.592814371257486%;
  }

  .flag_es-pv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 23.592814371257486%;
  }

  .flag_et {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 29.461077844311376%;
  }

  .flag_eu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 29.461077844311376%;
  }

  .flag_fi {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 29.461077844311376%;
  }

  .flag_fj {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 29.461077844311376%;
  }

  .flag_fk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 29.461077844311376%;
  }

  .flag_fm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 29.461077844311376%;
  }

  .flag_fo {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 29.461077844311376%;
  }

  .flag_fr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 29.461077844311376%;
  }

  .flag_ga {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 29.461077844311376%;
  }

  .flag_gb {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 29.461077844311376%;
  }

  .flag_gb-eng {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 29.461077844311376%;
  }

  .flag_gb-nir {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 29.461077844311376%;
  }

  .flag_gb-sct {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 29.461077844311376%;
  }

  .flag_gb-wls {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 29.461077844311376%;
  }

  .flag_gd {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 35.32934131736527%;
  }

  .flag_ge {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 35.32934131736527%;
  }

  .flag_gf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 35.32934131736527%;
  }

  .flag_gg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 35.32934131736527%;
  }

  .flag_gh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 35.32934131736527%;
  }

  .flag_gi {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 35.32934131736527%;
  }

  .flag_gl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 35.32934131736527%;
  }

  .flag_gm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 35.32934131736527%;
  }

  .flag_gn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 35.32934131736527%;
  }

  .flag_gp {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 35.32934131736527%;
  }

  .flag_gq {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 35.32934131736527%;
  }

  .flag_gr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 35.32934131736527%;
  }

  .flag_gs {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 35.32934131736527%;
  }

  .flag_gt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 35.32934131736527%;
  }

  .flag_gu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 35.32934131736527%;
  }

  .flag_gw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 41.19760479041916%;
  }

  .flag_gy {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 41.19760479041916%;
  }

  .flag_hk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 41.19760479041916%;
  }

  .flag_hm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 41.19760479041916%;
  }

  .flag_hn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 41.19760479041916%;
  }

  .flag_hr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 41.19760479041916%;
  }

  .flag_ht {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 41.19760479041916%;
  }

  .flag_hu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 41.19760479041916%;
  }

  .flag_ic {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 41.19760479041916%;
  }

  .flag_id {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 41.19760479041916%;
  }

  .flag_ie {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 41.19760479041916%;
  }

  .flag_il {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 41.19760479041916%;
  }

  .flag_im {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 41.19760479041916%;
  }

  .flag_in {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 41.19760479041916%;
  }

  .flag_io {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 41.19760479041916%;
  }

  .flag_iq {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 47.06586826347306%;
  }

  .flag_ir {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 47.06586826347306%;
  }

  .flag_is {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 47.06586826347306%;
  }

  .flag_it {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 47.06586826347306%;
  }

  .flag_je {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 47.06586826347306%;
  }

  .flag_jm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 47.06586826347306%;
  }

  .flag_jo {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 47.06586826347306%;
  }

  .flag_jp,
  .flag_ja {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 47.06586826347306%;
  }

  .flag_ke {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 47.06586826347306%;
  }

  .flag_kg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 47.06586826347306%;
  }

  .flag_kh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 47.06586826347306%;
  }

  .flag_ki {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 47.06586826347306%;
  }

  .flag_km {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 47.06586826347306%;
  }

  .flag_kn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 47.06586826347306%;
  }

  .flag_kp {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 47.06586826347306%;
  }

  .flag_kr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 52.93413173652694%;
  }

  .flag_kw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 52.93413173652694%;
  }

  .flag_ky {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 52.93413173652694%;
  }

  .flag_kz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 52.93413173652694%;
  }

  .flag_la {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 52.93413173652694%;
  }

  .flag_lb {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 52.93413173652694%;
  }

  .flag_lc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 52.93413173652694%;
  }

  .flag_li {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 52.93413173652694%;
  }

  .flag_lk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 52.93413173652694%;
  }

  .flag_lr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 52.93413173652694%;
  }

  .flag_ls {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 52.93413173652694%;
  }

  .flag_lt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 52.93413173652694%;
  }

  .flag_lu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 52.93413173652694%;
  }

  .flag_lv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 52.93413173652694%;
  }

  .flag_ly {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 52.93413173652694%;
  }

  .flag_ma {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 58.80239520958084%;
  }

  .flag_mc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 58.80239520958084%;
  }

  .flag_md {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 58.80239520958084%;
  }

  .flag_me {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 58.80239520958084%;
  }

  .flag_mf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 58.80239520958084%;
  }

  .flag_mg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 58.80239520958084%;
  }

  .flag_mh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 58.80239520958084%;
  }

  .flag_mk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 58.80239520958084%;
  }

  .flag_ml {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 58.80239520958084%;
  }

  .flag_mm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 58.80239520958084%;
  }

  .flag_mn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 58.80239520958084%;
  }

  .flag_mo {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 58.80239520958084%;
  }

  .flag_mp {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 58.80239520958084%;
  }

  .flag_mq {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 58.80239520958084%;
  }

  .flag_mr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 58.80239520958084%;
  }

  .flag_ms {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 64.67065868263474%;
  }

  .flag_mt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 64.67065868263474%;
  }

  .flag_mu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 64.67065868263474%;
  }

  .flag_mv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 64.67065868263474%;
  }

  .flag_mw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 64.67065868263474%;
  }

  .flag_mx {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 64.67065868263474%;
  }

  .flag_my {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 64.67065868263474%;
  }

  .flag_mz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 64.67065868263474%;
  }

  .flag_na {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 64.67065868263474%;
  }

  .flag_nc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 64.67065868263474%;
  }

  .flag_ne {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 64.67065868263474%;
  }

  .flag_nf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 64.67065868263474%;
  }

  .flag_ng {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 64.67065868263474%;
  }

  .flag_ni {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 64.67065868263474%;
  }

  .flag_nl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 64.67065868263474%;
  }

  .flag_no {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 70.53892215568862%;
  }

  .flag_np {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 70.53892215568862%;
  }

  .flag_nr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 70.53892215568862%;
  }

  .flag_nu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 70.53892215568862%;
  }

  .flag_nz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 70.53892215568862%;
  }

  .flag_om {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 70.53892215568862%;
  }

  .flag_pa {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 70.53892215568862%;
  }

  .flag_pe {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 70.53892215568862%;
  }

  .flag_pf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 70.53892215568862%;
  }

  .flag_pg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 70.53892215568862%;
  }

  .flag_ph {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 70.53892215568862%;
  }

  .flag_pk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 70.53892215568862%;
  }

  .flag_pl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 70.53892215568862%;
  }

  .flag_pm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 70.53892215568862%;
  }

  .flag_pn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 70.53892215568862%;
  }

  .flag_pr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 76.40718562874251%;
  }

  .flag_ps {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 76.40718562874251%;
  }

  .flag_pt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 76.40718562874251%;
  }

  .flag_pw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 76.40718562874251%;
  }

  .flag_py {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 76.40718562874251%;
  }

  .flag_qa {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 76.40718562874251%;
  }

  .flag_re {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 76.40718562874251%;
  }

  .flag_ro {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 76.40718562874251%;
  }

  .flag_rs {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 76.40718562874251%;
  }

  .flag_ru {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 76.40718562874251%;
  }

  .flag_rw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 76.40718562874251%;
  }

  .flag_sa {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 76.40718562874251%;
  }

  .flag_sb {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 76.40718562874251%;
  }

  .flag_sc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 76.40718562874251%;
  }

  .flag_sd {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 76.40718562874251%;
  }

  .flag_se {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 82.27544910179641%;
  }

  .flag_sg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 82.27544910179641%;
  }

  .flag_sh {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 82.27544910179641%;
  }

  .flag_si {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 82.27544910179641%;
  }

  .flag_sj {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 82.27544910179641%;
  }

  .flag_sk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 82.27544910179641%;
  }

  .flag_sl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 82.27544910179641%;
  }

  .flag_sm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 82.27544910179641%;
  }

  .flag_sn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 82.27544910179641%;
  }

  .flag_so {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 82.27544910179641%;
  }

  .flag_sr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 82.27544910179641%;
  }

  .flag_ss {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 82.27544910179641%;
  }

  .flag_st {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 82.27544910179641%;
  }

  .flag_sv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 82.27544910179641%;
  }

  .flag_sx {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 82.27544910179641%;
  }

  .flag_sy {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 88.1437125748503%;
  }

  .flag_sz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 88.1437125748503%;
  }

  .flag_ta {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 88.1437125748503%;
  }

  .flag_tc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 88.1437125748503%;
  }

  .flag_td {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 88.1437125748503%;
  }

  .flag_tf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 88.1437125748503%;
  }

  .flag_tg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 88.1437125748503%;
  }

  .flag_th {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 88.1437125748503%;
  }

  .flag_tj {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 88.1437125748503%;
  }

  .flag_tk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 88.1437125748503%;
  }

  .flag_tl {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 88.1437125748503%;
  }

  .flag_tm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 88.1437125748503%;
  }

  .flag_tn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 88.1437125748503%;
  }

  .flag_to {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 88.1437125748503%;
  }

  .flag_tr {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 88.1437125748503%;
  }

  .flag_tt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 94.0119760479042%;
  }

  .flag_tv {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 94.0119760479042%;
  }

  .flag_tw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 94.0119760479042%;
  }

  .flag_tz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 94.0119760479042%;
  }

  .flag_ua {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 94.0119760479042%;
  }

  .flag_ug {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 94.0119760479042%;
  }

  .flag_um {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 94.0119760479042%;
  }

  .flag_un {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 94.0119760479042%;
  }

  .flag_us,
  .flag_en {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 94.0119760479042%;
  }

  .flag_uy {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 94.0119760479042%;
  }

  .flag_uz {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 94.0119760479042%;
  }

  .flag_va {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 94.0119760479042%;
  }

  .flag_vc {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 85.6359649122807% 94.0119760479042%;
  }

  .flag_ve {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 92.76315789473684% 94.0119760479042%;
  }

  .flag_vg {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 99.89035087719299% 94.0119760479042%;
  }

  .flag_vi {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 0.10964912280701754% 99.88023952095809%;
  }

  .flag_vn {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 7.2368421052631575% 99.88023952095809%;
  }

  .flag_vu {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 14.364035087719298% 99.88023952095809%;
  }

  .flag_wf {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 21.49122807017544% 99.88023952095809%;
  }

  .flag_ws {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 28.61842105263158% 99.88023952095809%;
  }

  .flag_xk {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 35.74561403508772% 99.88023952095809%;
  }

  .flag_xx {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 42.87280701754386% 99.88023952095809%;
  }

  .flag_ye {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 50% 99.88023952095809%;
  }

  .flag_yt {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 57.12719298245614% 99.88023952095809%;
  }

  .flag_za {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 64.25438596491227% 99.88023952095809%;
  }

  .flag_zm {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 71.38157894736842% 99.88023952095809%;
  }

  .flag_zw {
    background-image: url(${image.src});
    background-size: 1525% 1839.5833333333333%;
    background-position: 78.50877192982456% 99.88023952095809%;
  }
`;
