import Header from 'core/common/components/Header';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { InformationalNatalChartScreenProps } from 'core/funnel/entities';
import {
  Container,
  NextButton,
  PrevButton,
  StyledTitle,
  ButtonsWrapper,
  Text,
  Background,
  Tip,
  StyledAnimatedImage,
} from './styled';

const InformationalNatalChartScreen = ({
  onBack,
  goNext,
  content,
}: InformationalNatalChartScreenProps) => {
  const { title, text, optionalText, animatedImage, next: buttonNext, back: buttonBack } = content;

  return (
    <>
      <Background />
      <Header onBack={onBack} variant="secondary" />
      <Container>
        {!!animatedImage && <StyledAnimatedImage variant={animatedImage} />}
        <StyledTitle dangerouslySetInnerHTML={prepareHtml(title)} />
        {!!text && <Text dangerouslySetInnerHTML={prepareHtml(text)} />}
        <ButtonsWrapper>
          {!!buttonBack && <PrevButton onClick={onBack}>{buttonBack}</PrevButton>}
          <NextButton onClick={goNext} variant="secondary">
            {buttonNext}
          </NextButton>
        </ButtonsWrapper>
        {!!optionalText && <Tip dangerouslySetInnerHTML={prepareHtml(optionalText)} />}
      </Container>
    </>
  );
};

export default InformationalNatalChartScreen;
