import { useEffect, useState } from 'react';
import ProcessingLoaderScreen from 'core/common/components/QuizScreens/ProcessingLoader';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { LoaderContentData, ProcessingLoaderScreenAttributes } from 'core/funnel/entities';
import useHintProcessingLoader from './useHintProcessingLoader';

type ProcessingLoaderProps = {
  id: string;
};

const ProcessingLoader = ({ id }: ProcessingLoaderProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { content } = screenAttributes as ProcessingLoaderScreenAttributes;

  const { sendAnalytic } = useHintProcessingLoader();

  const [title, setTitle] = useState('');

  const [profileValue, setProfileValue] = useState(0);
  const [personalityValue, setPersonalityValue] = useState(0);
  const [relationshipValue, setRelationshipValue] = useState(0);
  const [question, setQuestion] = useState('');
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [popupName, setPopupName] = useState('');

  const handleModalClose = (value: string) => {
    sendAnalytic(popupName, value);
    setShouldShowModal(false);
  };

  const onPercentChange = (percent: number, data: LoaderContentData) => {
    switch (percent) {
      case 0:
        setTitle(data.start);
        break;
      case 40:
        setTitle(data.middle);
        break;
      case 70:
        setTitle(data.finish);
        break;
      case 99:
        setShouldShowModal(true);
        setPopupName(data.popupName);
        setQuestion(data.question);
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const setTimeoutValue = (func: Function, time = 100) => {
    return setTimeout(() => {
      func((prev: number) => prev + 1);
    }, time);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    onPercentChange(profileValue, content.profile);

    timer = setTimeoutValue(setProfileValue);

    if (profileValue === 100) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileValue]);

  useEffect(() => {
    if (profileValue !== 100) return;
    let timer: ReturnType<typeof setTimeout> | null = null;
    onPercentChange(personalityValue, content.personality);

    timer = setTimeoutValue(setPersonalityValue);

    if (timer && personalityValue === 100) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalityValue, shouldShowModal]);

  useEffect(() => {
    if (profileValue !== 100 || personalityValue !== 100) return;
    let timer: ReturnType<typeof setTimeout> | null = null;
    onPercentChange(relationshipValue, content.relationship);
    timer = setTimeoutValue(setRelationshipValue);

    if (relationshipValue === 100 && !shouldShowModal) {
      next();
    }

    if (timer && relationshipValue === 100) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipValue, shouldShowModal]);

  return (
    <ProcessingLoaderScreen
      title={title}
      question={question}
      onClose={handleModalClose}
      shouldShowModal={shouldShowModal}
      personalityValue={personalityValue}
      profileValue={profileValue}
      relationshipValue={relationshipValue}
      {...(screenAttributes as ProcessingLoaderScreenAttributes)}
    />
  );
};

export default ProcessingLoader;
