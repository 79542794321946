import styled from 'styled-components';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';
import Button from 'core/common/components/ButtonDeprecated';
import Modal from 'core/common/components/Modal';

const listItemsGapPx = 16;

export const ModalStyled = styled(Modal)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93%;
  margin-top: auto;
  padding: 68px 16px 16px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 375px;
    height: 60%;
    margin-top: unset;
    padding-bottom: 26px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
  }
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  left: 20px;

  ${CrossIconStyled} {
    fill: ${({ theme }) => theme.colors.typography600};
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 28px;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
`;

export const BenefitsStyled = styled.div`
  display: flex;
`;

export const BenefitsColumnStyled = styled.div<{ variant?: 'default' | 'gradient' }>`
  width: 25%;
  max-width: 82px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
  color: ${({ theme, variant = 'default' }) =>
    variant === 'gradient' ? theme.colors.white : theme.colors.typography500};
  background: ${({ variant = 'default' }) =>
    variant === 'gradient'
      ? 'linear-gradient(180deg, #1d1e55 0%, #272463 25%, #3b2f7b 50%, #4f358d 75%, #6939a2 100% )'
      : 'transparent'};
`;

export const BenefitsLargeColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: auto;
`;

export const ColumnTitleStyled = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
`;

export const IconsListStyled = styled.ul`
  margin-top: 20px;
`;

export const IconsListItemStyled = styled.li<{ variant?: 'benefit' | 'drawback' }>`
  text-align: center;

  &:not(:last-child) {
    margin-bottom: ${listItemsGapPx}px;
  }

  svg {
    fill: ${({ theme, variant = 'benefit' }) =>
      variant === 'benefit' ? theme.colors.typography100 : '#0f0f0f99'};
  }
`;

export const BenefitsListStyled = styled.ul`
  margin-top: auto;
  padding-bottom: 24px;
`;

export const BenefitsListItemStyled = styled.li`
  font-size: 14px;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: ${listItemsGapPx}px;
  }
`;

export const FooterStyled = styled.footer`
  margin-top: auto;
`;

export const FooterCaptionStyled = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const ButtonStyled = styled(Button)`
  height: 56px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
