import Steps from './Steps';

import {
  BannerStyled,
  BannerTextStyled,
  BannerTitleStyled,
  SmileEmojiStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

type UpsellsHeaderProps = {
  title?: string;
  subtitle?: string;
  bannerTitle?: string;
  bannerText?: string;
  bannerEmoji?: string;
  shouldDisplayBanner?: boolean;
};

const UpsellsHeader = ({
  title,
  subtitle,
  bannerTitle,
  bannerText,
  bannerEmoji,
  shouldDisplayBanner = false,
}: UpsellsHeaderProps) => {
  return (
    <>
      <Steps />

      {shouldDisplayBanner && (
        <BannerStyled>
          {bannerEmoji && <SmileEmojiStyled emoji={bannerEmoji} />}
          <BannerTitleStyled>
            {bannerTitle} <BannerTextStyled>{bannerText}</BannerTextStyled>
          </BannerTitleStyled>
        </BannerStyled>
      )}

      {title && <TitleStyled>{title}</TitleStyled>}
      {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
    </>
  );
};

export default UpsellsHeader;
