import styled from 'styled-components';
import Advantages from './Advantages';

export const AdvantagesStyled = styled(Advantages)`
  margin-bottom: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 70px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 36px;
    line-height: 47px;
  }
`;

export const DescriptionStyled = styled.p`
  margin-bottom: 40px;
  line-height: 24px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 44px;
  }
`;

export const BreakStyled = styled.br``;
