import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SectionComparisonAttributes } from 'core/funnel/entities';
import {
  WrapperStyled,
  TitleStyled,
  ImageStyled,
  AspectRatioImageWrapper,
  OptionsListStyled,
  OptionsListItemStyled,
  CardsWrapperStyled,
  CardStyled,
  CardTitleStyled,
  ListIconStyled,
  ListOptionStyled,
} from './styled';

export type SectionComparisonProps = {
  content: SectionComparisonAttributes['content'];
  className?: string;
};

const SectionComparison = ({ content, className }: SectionComparisonProps) => {
  const { title, image, before, after } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <AspectRatioImageWrapper>
        <ImageStyled src={image.src} alt={image.alt} fill />
      </AspectRatioImageWrapper>
      <CardsWrapperStyled>
        <CardStyled>
          <CardTitleStyled>{before.title}</CardTitleStyled>
          <OptionsListStyled>
            {before.list.map(({ text, icon }) => (
              <OptionsListItemStyled key={text}>
                <ListIconStyled name={icon} />
                <ListOptionStyled dangerouslySetInnerHTML={prepareHtml(text)} />
              </OptionsListItemStyled>
            ))}
          </OptionsListStyled>
        </CardStyled>
        <CardStyled variant="gradient">
          <CardTitleStyled>{after.title}</CardTitleStyled>
          <OptionsListStyled>
            {after.list.map(({ text, icon }) => (
              <OptionsListItemStyled key={text}>
                <ListIconStyled name={icon} />
                <ListOptionStyled dangerouslySetInnerHTML={prepareHtml(text)} />
              </OptionsListItemStyled>
            ))}
          </OptionsListStyled>
        </CardStyled>
      </CardsWrapperStyled>
    </WrapperStyled>
  );
};

export default SectionComparison;
