import styled from 'styled-components';

export const TooltipText = styled.span`
  position: absolute;
  right: -17px;
  bottom: 25px;
  z-index: 10;
  display: none;
  width: 200px;
  padding: 4px 10px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 6px;
  font-size: 9px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  background: white;

  @media ${(props) => props.theme.breakpoints.desktop} {
    bottom: 30px;
  }

  &::after {
    position: absolute;
    top: 88%;
    left: 85%;
    width: 10px;
    height: 10px;
    border-right: 1px solid ${(props) => props.theme.colors.typography200};
    border-bottom: 1px solid ${(props) => props.theme.colors.typography200};
    background: white;
    transform: rotate(45deg);
    content: '';
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  &:hover ${TooltipText} {
    display: block;
  }
`;
