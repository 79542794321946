import { TrialPaymentPalmistryScreenAttributes } from 'core/funnel/entities';

import { Avatar, StyledRating, StyledReviewsReal, Title, Wrapper } from './styled';

type SectionReviewsProps = {
  content: TrialPaymentPalmistryScreenAttributes['content']['reviews'];
};

const SectionReviews = ({ content }: SectionReviewsProps) => {
  const { title, list } = content;

  const formattedList = list.map((review) => ({
    img: <Avatar>{review.initials}</Avatar>,
    name: review.nickname,
    date: review.date,
    text: review.text,
    label: <StyledRating percent={review.percent ?? 100} />,
  }));

  return (
    <Wrapper>
      <Title>{title}</Title>
      <StyledReviewsReal list={formattedList} />
    </Wrapper>
  );
};

export default SectionReviews;
