import { memo, SelectHTMLAttributes } from 'react';
import { Container, Option, SelectBlock } from './styled';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: string[];
}

const Select = ({ name, value, onChange, options, className }: SelectProps) => (
  <Container>
    <SelectBlock className={className} name={name} id={name} value={value} onChange={onChange}>
      {options.map((item) => (
        <Option value={item} key={item}>
          {item}
        </Option>
      ))}
    </SelectBlock>
  </Container>
);

export default memo(Select);
