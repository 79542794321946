import { useEffect } from 'react';
import UpsellConsultationWithDiscountScreen from 'core/common/components/QuizScreens/UpsellConsultationWithDiscount';
import { useServices, useUpdateEffect } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts';
import { UpsellConsultationWithDiscountScreenAttributes } from 'core/funnel/entities';
import { useOffers } from 'core/offers/hooks';
import { PaymentProcessSteps } from 'core/payments/entities';
import { useRecurringPayment } from 'core/payments/hooks';

type UpsellConsultationWithDiscountSingleProps = {
  id: string;
};

const UpsellConsultationWithDiscountSingle = ({
  id,
}: UpsellConsultationWithDiscountSingleProps) => {
  const { next, screenAttributes } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { discountConsultationUpsellOffer, consultationUpsellOffer } = useOffers();

  const { recurringPaymentRequest, paymentProcessStep, error, clearState } = useRecurringPayment();

  const isPurchaseLoading = paymentProcessStep === PaymentProcessSteps.IN_PROCESS;
  const isResignFormLoaded = paymentProcessStep === PaymentProcessSteps.RESIGN_INITIALIZED;

  const getReport = () => {
    if (!discountConsultationUpsellOffer) return;

    analyticsService.consultationUpsellWithDiscountScreenClicked();
    recurringPaymentRequest(discountConsultationUpsellOffer.offerId);
  };

  const skip = () => {
    next();
  };

  useEffect(() => {
    analyticsService.consultationUpsellWithDiscountScreenOpened();
  }, [analyticsService]);

  useUpdateEffect(() => {
    if (paymentProcessStep === PaymentProcessSteps.SUCCESS) {
      analyticsService.consultationUpsellWithDiscountGetOffer(
        discountConsultationUpsellOffer ? discountConsultationUpsellOffer.offerId : '',
      );
      next();
    }
  }, [paymentProcessStep]);

  const offerPrice = consultationUpsellOffer?.pricesWithCurrencySymbol.trialPriceWithVAT ?? '';
  const offerTrialPrice =
    discountConsultationUpsellOffer?.pricesWithCurrencySymbol.trialPriceWithVAT ?? '';

  return (
    <UpsellConsultationWithDiscountScreen
      price={offerPrice}
      isLoading={isPurchaseLoading}
      trialPrice={offerTrialPrice}
      onGetOffer={getReport}
      onSkipOffer={skip}
      resignFormLoaded={isResignFormLoaded}
      onClearPaymentStatus={clearState}
      paymentError={error}
      {...(screenAttributes as UpsellConsultationWithDiscountScreenAttributes)}
    />
  );
};

export default UpsellConsultationWithDiscountSingle;
