import { LinearProgressProps } from '@mui/material';
import { LinearProgressStyled } from './styled';

export type ProgressProps = {
  step: number;
  totalSteps?: number;
  className?: string;
} & LinearProgressProps;

const Progress = ({
  step,
  totalSteps = 100,
  className,
  variant = 'determinate',
  ...otherProps
}: ProgressProps) => {
  const progress = (step * 100) / totalSteps;

  return (
    <LinearProgressStyled
      variant={variant}
      value={progress}
      className={className}
      {...otherProps}
    />
  );
};

export default Progress;
