import MuiLinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const LinearProgressStyled = styled(MuiLinearProgress)`
  width: 100%;
  height: 8px !important;
  border-radius: 100px;
  background-color: #d9d9d9 !important;

  .MuiLinearProgress-bar {
    border-radius: 100px;
    background: linear-gradient(
      180deg,
      #1d1e55 0%,
      #272463 25%,
      #3b2f7b 50%,
      #4f358d 75%,
      #6939a2 100%
    );
  }
`;

export const ValueStyled = styled.span`
  margin-top: -4px;
  margin-left: 9px;
  font-size: 14px;
  font-weight: 700;
  color: #3c1c7b;
`;
