import { prepareHtml } from 'core/common/utils/prepareHtml';
import { List, ListItem, ListItemEmail, Title, Wrapper } from './styled';

type EmailsBlockWithAutoAddAnimationProps = {
  list: string[];
  counter: number;
  className?: string;
  title: string;
  animateIndex: number | null;
};

const EmailsBlockWithAutoAddAnimation = ({
  list,
  className,
  title,
  counter,
  animateIndex,
}: EmailsBlockWithAutoAddAnimationProps) => {
  return (
    <Wrapper className={className}>
      <Title>
        {counter} {title}
      </Title>
      <List>
        {list.map((text, index) => (
          <ListItem key={index} animate={animateIndex === index}>
            <ListItemEmail dangerouslySetInnerHTML={prepareHtml(text)} />
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default EmailsBlockWithAutoAddAnimation;
