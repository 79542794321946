import { useCallback, useState } from 'react';
import { LoadingStatuses } from 'core/common/entities';
import { normalizeError } from 'core/common/errors';
import { useDispatch, useLogger, useServices } from 'core/common/hooks';
import { updateUserInformation } from 'core/user/store';

export function useDetectPalm() {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.IDLE);
  const [error, setError] = useState('');

  const { palmDetectionService } = useServices();
  const logger = useLogger();
  const dispatch = useDispatch();

  const clearState = () => {
    setLoadingStatus(LoadingStatuses.IDLE);
    setError('');
  };

  const detectPalm = useCallback(
    async (imageBlob: Blob) => {
      if (loadingStatus === LoadingStatuses.PENDING) return;

      clearState();

      try {
        setLoadingStatus(LoadingStatuses.PENDING);
        const palmPhotoData = await palmDetectionService.detectPalm(imageBlob);
        setLoadingStatus(LoadingStatuses.FULFILLED);
        dispatch(updateUserInformation('scan', palmPhotoData));
      } catch (e) {
        setLoadingStatus(LoadingStatuses.FAILED);
        const err = normalizeError(e);
        setError(err.message);
        logger.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingStatus, palmDetectionService, logger],
  );

  return {
    error,
    loadingStatus,
    detectPalm,
  };
}
