import { ChangeEvent } from 'react';
import { BASE_ROUTES } from 'core/common/constants';

import { CheckboxText, Container, NavLink, StyledCheckbox, StyledError } from './styled';

type PolicyExtendedProps = {
  className?: string;
  withoutCheckbox?: boolean;
  isError?: boolean;
  isPolicyAccepted: boolean;
  onPolicyClick: (checked: boolean) => void;
};

const PolicyExtended = ({
  onPolicyClick,
  isPolicyAccepted,
  isError = false,
  withoutCheckbox = false,
  className = '',
}: PolicyExtendedProps) => {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    onPolicyClick(checked);
  };

  if (withoutCheckbox) {
    return (
      <Container className={className}>
        <CheckboxText centered>
          By selecting your gender you agree to the{' '}
          <NavLink href={BASE_ROUTES.TECH_PRIVACY_POLICY}>Privacy policy</NavLink>,{' '}
          <NavLink href={BASE_ROUTES.TECH_TERMS}>Terms of use</NavLink> and to the use of cookies
          and tracking techonologies, that require your consent (e.g. Meta Pixel)
        </CheckboxText>
      </Container>
    );
  }

  return (
    <Container className={className}>
      <StyledCheckbox name="agreement" checked={isPolicyAccepted} onChange={handleCheckboxChange}>
        <CheckboxText>
          I agree to the <NavLink href={BASE_ROUTES.TECH_PRIVACY_POLICY}>Privacy policy</NavLink>,{' '}
          <NavLink href={BASE_ROUTES.TECH_TERMS}>Terms of use</NavLink> and to the use of cookies
          and tracking techonologies, that require your consent (e.g. Meta Pixel)
        </CheckboxText>
      </StyledCheckbox>
      {isError && <StyledError>To continue, please accept our terms and policies</StyledError>}
    </Container>
  );
};

export default PolicyExtended;
