import styled from 'styled-components';

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerHeader = styled.h6`
  font-size: 10px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const TimerBody = styled.div`
  display: flex;
  margin-top: 3px;
`;

export const TimerUnitCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerUnitText = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.typography700};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 28px;
  }
`;

export const TimerDevider = styled(TimerUnitText)`
  margin: 0 4px;
`;

export const TimerUnitLabel = styled.span`
  font-size: 8px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;
