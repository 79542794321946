import { useState } from 'react';
import { Country } from 'react-phone-number-input';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import PhoneField from 'core/common/components/PhoneField';
import { PhoneNumberScreenProps } from 'core/funnel/entities';
import usePhoneNumber from './usePhoneNumber';
import {
  ButtonsWrapperStyled,
  CompatibilityHeaderStyled,
  ConfirmationCheckboxStyled,
  ConfirmationLabelWrapperStyled,
  DesktopImageStyled,
  DoNotMissTextStyled,
  GetPersonalizedUpdatesButtonStyled,
  GuaranteeTextStyled,
  GuaranteeWrapperStyled,
  MainContentWrapperStyled,
  MobileImageStyled,
  NoUpdatesNeededButtonStyled,
  PageContentStyled,
  PageWrapperStyled,
  PhoneInputErrorStyled,
  PhoneInputWrapperStyled,
} from './styled';

const PhoneNumberScreen = ({ goNext, content }: PhoneNumberScreenProps) => {
  const { handleGetPersonalizedUpdatesClick, skip, country } = usePhoneNumber(goNext);

  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState<string | null>(null);
  const [approvedReceivingMessages, setApprovedReceivingMessages] = useState(false);

  const handlePhoneNumberChange = (phone: string) => {
    setEnteredPhoneNumber(phone);
    setPhoneValidationError(null);
  };

  const toggleUserMessageReceivingApproval = () => {
    setApprovedReceivingMessages((approved) => !approved);
  };

  return (
    <PageWrapperStyled>
      <PageContentStyled>
        <Header buttonBack={false} />
        <MobileImageStyled>
          <Image
            src={content.image.mobile.src}
            alt={content.image.mobile.alt}
            width={328}
            height={100}
          />
        </MobileImageStyled>
        <DesktopImageStyled>
          <Image
            src={content.image.desktop.src}
            alt={content.image.desktop.alt}
            width={382}
            height={238}
          />
        </DesktopImageStyled>
        <MainContentWrapperStyled>
          <CompatibilityHeaderStyled>{content.title}</CompatibilityHeaderStyled>
          <DoNotMissTextStyled>{content.subtitle}</DoNotMissTextStyled>
          <GuaranteeWrapperStyled>
            <Image
              src={content.secureIcon.src}
              alt={content.secureIcon.alt}
              width={16}
              height={16}
            />
            <GuaranteeTextStyled>{content.guarantee}</GuaranteeTextStyled>
          </GuaranteeWrapperStyled>
          <PhoneInputWrapperStyled error={phoneValidationError}>
            <PhoneField
              onChange={handlePhoneNumberChange}
              defaultCountry={country as Country}
              placeholder={content.placeholder}
              value={enteredPhoneNumber}
            />
            {phoneValidationError && (
              <PhoneInputErrorStyled>{phoneValidationError}</PhoneInputErrorStyled>
            )}
          </PhoneInputWrapperStyled>
          <ConfirmationLabelWrapperStyled>
            <ConfirmationCheckboxStyled
              name="User Receiving Message Approval"
              variant="tertiary"
              checked={approvedReceivingMessages}
              onChange={toggleUserMessageReceivingApproval}
            >
              {content.approve}
            </ConfirmationCheckboxStyled>
          </ConfirmationLabelWrapperStyled>
        </MainContentWrapperStyled>
        <ButtonsWrapperStyled>
          <GetPersonalizedUpdatesButtonStyled
            disabled={!enteredPhoneNumber || !approvedReceivingMessages}
            onClick={() =>
              handleGetPersonalizedUpdatesClick(enteredPhoneNumber, {
                validationError: () => setPhoneValidationError(content.notValidNumberMessage),
              })
            }
          >
            {content.accept}
          </GetPersonalizedUpdatesButtonStyled>
          <NoUpdatesNeededButtonStyled onClick={skip}>
            {content.decline}
          </NoUpdatesNeededButtonStyled>
        </ButtonsWrapperStyled>
      </PageContentStyled>
    </PageWrapperStyled>
  );
};

export default PhoneNumberScreen;
