import PhoneNumberPalmistryScreen from 'core/common/components/QuizScreens/PhoneNumberPalmistry';
import { useQuizStep } from 'core/funnel/contexts';
import { PhoneNumberPalmistryScreenAttributes } from 'core/funnel/entities';

type PhoneNumberPalmistryProps = {
  id: string;
};

const PhoneNumberPalmistry = ({ id }: PhoneNumberPalmistryProps) => {
  const { next, screenAttributes } = useQuizStep(id);

  return (
    <PhoneNumberPalmistryScreen
      goNext={next}
      {...(screenAttributes as PhoneNumberPalmistryScreenAttributes)}
    />
  );
};

export default PhoneNumberPalmistry;
