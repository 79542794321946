import arrow from 'core/common/assets/images/arrow_right.svg';
import { CarouselSettings } from 'core/common/components/Carousel';
import Header from 'core/common/components/Header';
import Link from 'core/common/components/Link';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import UpsellsHeader from 'core/common/components/UpsellsHeader';
import { BASE_ROUTES } from 'core/common/constants';
import { UnlimitedReadingsPalmistryV2ScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import Benefits from './Benefits';
import MainIntroSlide from './MainIntroSlide';
import { HalfRowImage } from './MainIntroSlide/styled';
import {
  ActionButton,
  BackImageStyled,
  Container,
  Disclaimer,
  Footer,
  NextImageStyled,
  RouteLink,
  Slide,
  StyledCarousel,
  Title,
} from './styled';

const UnlimitedReadingsPalmistryV2Screen = ({
  content,
  plan,
  isLoading,
  resignFormLoaded,
  onGetReport,
  onSkip,
  paymentError,
  onClearPaymentStatus,
}: UnlimitedReadingsPalmistryV2ScreenProps) => {
  const { title, infoSlide, benefits, imageSlides, button, skipButton, footer } = content;

  const displayPriceWithCurrencySymbol = plan?.pricesWithCurrencySymbol.priceWithVAT ?? '-';
  const planPeriod = plan?.period ?? '-';

  const settings: CarouselSettings = {
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <BackImageStyled src={arrow} alt="arrow" width={24} height={24} />,
    nextArrow: <NextImageStyled src={arrow} alt="arrow" width={24} height={24} />,
  };

  return (
    <>
      <Header buttonBack={false} />
      <Container>
        <UpsellsHeader />

        <StyledCarousel {...settings}>
          <Slide withBackground>
            <MainIntroSlide displayPrice={displayPriceWithCurrencySymbol} content={infoSlide} />
          </Slide>
          {imageSlides.map((images) => {
            return (
              <Slide key={images[0].alt}>
                <HalfRowImage
                  src={images[0].src}
                  alt={images[0].alt}
                  width={239}
                  height={252}
                  priority
                />
                <HalfRowImage
                  src={images[1].src}
                  alt={images[1].alt}
                  width={239}
                  height={252}
                  priority
                />
              </Slide>
            );
          })}
        </StyledCarousel>

        <Title>{title}</Title>

        <Benefits benefits={benefits} />

        <ActionButton onClick={onGetReport} disabled={isLoading} loading={isLoading} fullWidth>
          {button}
        </ActionButton>
        <RouteLink onClick={onSkip} disabled={isLoading}>
          {skipButton}
        </RouteLink>

        <Disclaimer>
          Please note: In addition to your subscription, your account will be charged{' '}
          {displayPriceWithCurrencySymbol} for the selected add-ons as you click Add unlimited
          readings. Items on this page are {planPeriod} subscriptions. Unless you cancel it in your
          profile before the end of the then-current period, you agree that the subscription will
          renew automatically at the end of each period. If you need help with how to cancel, visit
          our <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>Subscription terms.</Link>
        </Disclaimer>

        <Footer>
          <Location>
            <LocationOption option={Locales.AE}>{footer.addressAE}</LocationOption>
            <LocationOption option={Locales.US}>{footer.addressUS}</LocationOption>
            <LocationOption option={Locales.OTHER}>{footer.address}</LocationOption>
          </Location>
        </Footer>

        <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
        <PaymentDialogFailed
          paymentError={null}
          onTryAgain={onGetReport}
          onClose={onClearPaymentStatus}
          isOpen={!!paymentError}
        />
      </Container>
    </>
  );
};

export default UnlimitedReadingsPalmistryV2Screen;
