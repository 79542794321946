import { ReactNode } from 'react';
import { WrapperStyled } from './styled';

type AspectRatioProps = {
  width: number;
  height: number;
  children: ReactNode;
  className?: string;
};

const AspectRatio = ({ children, ...restProps }: AspectRatioProps) => {
  return <WrapperStyled {...restProps}>{children}</WrapperStyled>;
};

export default AspectRatio;
