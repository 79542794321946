import styled from 'styled-components';

export const PriceWrapper = styled.div`
  position: relative;
  width: 135px;
  border: ${(props) => `1px solid ${props.theme.colors.info}`};
  border-radius: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 214px;
  }
`;

export const PriceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.info}`};
  border-radius: 16px 16px 0 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const PriceTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 20px;
  }
`;

export const PriceMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 16px 16px;
`;

export const StyledPrice = styled.span`
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  color: #343434;
`;

export const OldPrice = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #343434;
`;

export const HeaderDiscount = styled.div`
  position: absolute;
  top: calc(-0.5em - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  border-radius: 20px;
  background: ${(props) => props.theme.gradients.primary};

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: calc(-0.5em - 1px);
  }
`;

export const HeaderDiscountTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border-radius: 20px;
  background: #fff;
`;

export const HeaderDiscountText = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography700};
  background: #fff;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    font-weight: normal;
  }
`;
