import Carousel, { CarouselSettings } from 'core/common/components/Carousel';
import Header from 'core/common/components/Header';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { config } from 'core/config';
import { PrelandingPalmistryScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import {
  Address,
  AddressUS,
  AdvantagesList,
  AdvantagesListItem,
  AdvantagesText,
  AdvantagesTextWrapper,
  AdvantagesTitle,
  AnimatedTitle,
  Button,
  Container,
  MainImage,
  Policy,
  QuizInformationList,
  QuizInformationListIcon,
  QuizInformationListItem,
  QuizInformationListText,
  Title,
  DataSecuredBadge,
} from './styled';

const settings: CarouselSettings = {
  vertical: true,
  speed: 600,
  autoplay: true,
  slidesToShow: 1,
  arrows: false,
  draggable: false,
  pauseOnHover: false,
  swipe: false,
};

const PrelandingPalmistryScreen = ({
  onBack,
  onStart,
  content,
  hasButtonBack,
}: PrelandingPalmistryScreenProps) => {
  return (
    <RenderOnlyOnClient>
      <Header onBack={onBack} buttonBack={hasButtonBack} />
      <Container>
        <AdvantagesList>
          {content.advantagesList.map(({ title, text, icon }) => (
            <AdvantagesListItem key={title}>
              <QuizInformationListIcon src={icon} width={20} height={20} alt={text} />
              <AdvantagesTextWrapper>
                <AdvantagesTitle>{title}</AdvantagesTitle>
                <AdvantagesText>{text}</AdvantagesText>
              </AdvantagesTextWrapper>
            </AdvantagesListItem>
          ))}
        </AdvantagesList>
        <MainImage src={content.image} priority width={330} height={214} alt="plam scaner" />
        {content.animatedTitle && (
          <Carousel {...settings}>
            {content.animatedTitle.map((item) => (
              <AnimatedTitle key={item}>{item}</AnimatedTitle>
            ))}
          </Carousel>
        )}
        <Title>{content.title}</Title>
        <QuizInformationList>
          {content.list.map(({ text, icon }) => (
            <QuizInformationListItem key={text}>
              <QuizInformationListIcon src={icon} width={16} height={16} alt={text} />
              <QuizInformationListText>{text}</QuizInformationListText>
            </QuizInformationListItem>
          ))}
        </QuizInformationList>
        <Button onClick={onStart}>{content.button}</Button>
        {content.dataSecuredBadge && (
          <DataSecuredBadge
            title={content.dataSecuredBadge.title}
            icon={content.dataSecuredBadge.icon}
          />
        )}
        <Policy />
        <RenderOnlyOnClient>
          <Location>
            <LocationOption option={Locales.US}>
              <AddressUS>{config.addressUS}</AddressUS>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <Address />
            </LocationOption>
          </Location>
        </RenderOnlyOnClient>
      </Container>
    </RenderOnlyOnClient>
  );
};

export default PrelandingPalmistryScreen;
