import { Trans } from 'react-i18next';
import { PaymentDialog } from 'core/common/components/Payment';
import { useConfig } from 'core/common/hooks';
import { Offer } from 'core/offers/entities';
import { PaymentOrderDescription } from 'core/payments/entities';
import { europeanCountries, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { Bold, Content, Description } from './styled';

export type SectionPaymentProps = {
  isOpen: boolean;
  offer: Offer;
  onClose: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
};

const Payment = (props: SectionPaymentProps) => {
  const { onPaymentSuccess, offer, onClose, isOpen = false } = props;
  const { domainUrl } = useConfig();

  const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.payment';

  const onPaymentDialogClose = () => {
    onClose();
  };

  return (
    <>
      <PaymentDialog
        isOpen={isOpen}
        productId={offer.offerId}
        onClose={onPaymentDialogClose}
        onPaymentSuccess={onPaymentSuccess}
        currency={offer.currency}
      >
        <Content>
          <Location>
            <LocationOption option={[...europeanCountries]}>
              <Description>
                <Trans
                  i18nKey={`${tKeyPrefix}.charge.trial`}
                  values={{
                    price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                    trialPeriod: offer.trialPeriod,
                  }}
                  components={{
                    bold: <Bold />,
                    br: <br />,
                  }}
                />
                <Location>
                  <LocationOption option={Locales.US}>
                    {' '}
                    The charge will appear on your bill as {domainUrl}
                  </LocationOption>
                </Location>
              </Description>
            </LocationOption>
            <LocationOption option={Locales.US}>
              <Description>
                <Trans
                  i18nKey={`${tKeyPrefix}.charge.trial`}
                  values={{
                    price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                    trialPeriod: offer.trialPeriod,
                  }}
                  components={{
                    bold: <Bold />,
                    br: <br />,
                  }}
                />
                The charge will appear on your bill as {domainUrl}
              </Description>
            </LocationOption>
            <LocationOption option={Locales.OTHER}>
              <Description>
                <Trans
                  i18nKey={`${tKeyPrefix}.charge.trial`}
                  values={{
                    price: offer.pricesWithCurrencySymbol.trialPriceWithVAT,
                    trialPeriod: offer.trialPeriod,
                  }}
                  components={{
                    bold: <Bold />,
                    br: <br />,
                  }}
                />
                <Location>
                  <LocationOption option={Locales.US}>
                    {' '}
                    The charge will appear on your bill as {domainUrl}
                  </LocationOption>
                </Location>
              </Description>
            </LocationOption>
          </Location>
        </Content>
      </PaymentDialog>
    </>
  );
};

export default Payment;
