import { useCallback, useEffect, useState } from 'react';
import TrialPaymentIntroOfferScreen from 'core/common/components/QuizScreens/TrialPaymentIntroOffer';
import { LANDINGS } from 'core/common/constants';
import { useCountdown, useRouter, useSelector, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { FunnelName, TrialPaymentIntroOfferScreenAttributes } from 'core/funnel/entities';
import { useQuizData } from 'core/funnel/hooks';
import { Offer } from 'core/offers/entities';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';
import { getEmail, getUserInformation } from 'core/user/store';
import { getZodiacPersonImage } from 'core/zodiac/utils/getZodiacPersonImage';

type TrialPaymentIntroOfferProps = {
  id: string;
};

const TrialPaymentIntroOffer = ({ id }: TrialPaymentIntroOfferProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { link } = useQuizData();
  const { navigate } = useRouter();
  const { specialPlanCountdown } = useCountdown({ key: FunnelName.APP_SUBSCRIPTION_INTRO });
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)

  const email = useSelector(getEmail);
  const { orderId } = useOrder();
  const userDetails = useSelector(getUserInformation);

  const { updateOffer, timeUpOffer, timeUpOffers, defaultOffers, selectedOffer } = useOffers();

  const isTimeUp = !+specialPlanCountdown;
  const initialOffer = isTimeUp ? timeUpOffer : defaultOffers[0];
  const [offer, setOffer] = useState(initialOffer);
  const [isAllOffersLoaded, setIsAllOffersLoaded] = useState(false);

  const onAllOffersLoaded = () => {
    setIsAllOffersLoaded(true);
  };

  const zodiacImage = getZodiacPersonImage(userPreferences.zodiac, userPreferences.gender);
  const partnerZodiacImage = getZodiacPersonImage(
    userPreferences.partnerZodiac,
    userPreferences.partnerGender,
  );

  const onOfferChanged = (plan: Offer) => {
    setOffer(plan);
    updateOffer(plan);
  };

  const getReport = useCallback(() => {
    if (!offer) return;
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  const paymentDialogClose = () => {
    navigate(joinUrls(link, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  useEffect(() => {
    if (!offer) return;
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentIntroOfferScreen
      getReport={getReport}
      goNext={next}
      paymentDialogClose={paymentDialogClose}
      relationshipType={userDetails.relationshipType}
      offer={offer}
      timeUpOffer={timeUpOffer}
      timeLeft={specialPlanCountdown}
      zodiacImage={zodiacImage}
      partnerZodiacImage={partnerZodiacImage}
      onOfferChanged={onOfferChanged}
      defaultOffers={defaultOffers}
      timeUpOffers={timeUpOffers}
      selectedOffer={selectedOffer}
      isAllOffersLoaded={isAllOffersLoaded}
      onAllOffersLoaded={onAllOffersLoaded}
      {...(screenAttributes as TrialPaymentIntroOfferScreenAttributes)}
    />
  );
};

export default TrialPaymentIntroOffer;
