import { useState, useRef, useEffect, TouchEvent, MouseEvent } from 'react';
import { useScreenPress } from 'core/common/hooks';
import { isTouchEvent } from 'core/common/utils/isTouchEvent';
import { Story } from 'core/funnel/entities';

const animationIntervalMs = 15;
const minProgressValue = 0;
const maxProgressValue = 100;
const initialStoryIndex = 0;

type Params = { storyDurationMs: number; stories: Array<Story>; onLastStoryEnd: () => void };

export const useStories = ({ stories, storyDurationMs, onLastStoryEnd }: Params) => {
  const [isStoryPaused, setIsStoryPaused] = useState(false);
  const [activeStoryIndex, setActiveStoryIndex] = useState(initialStoryIndex);
  const [activeStoryProgress, setActiveStoryProgress] = useState(minProgressValue);

  const storiesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isStoryPaused) return;

    const progressStep = (animationIntervalMs / storyDurationMs) * maxProgressValue;

    const updateProgress = () => {
      setActiveStoryProgress((prevProgress) => {
        const newProgress = prevProgress + progressStep;

        if (newProgress >= maxProgressValue) {
          if (activeStoryIndex === stories.length - 1) {
            onLastStoryEnd();
            return maxProgressValue;
          }

          setActiveStoryIndex((prev) => prev + 1);

          return minProgressValue;
        }

        return newProgress;
      });
    };

    const intervalId = setInterval(updateProgress, animationIntervalMs);

    return () => {
      clearInterval(intervalId);
    };
  }, [storyDurationMs, activeStoryIndex, stories.length, isStoryPaused, onLastStoryEnd]);

  const goToPreviousStory = () => {
    if (activeStoryIndex === initialStoryIndex) {
      return;
    }

    setActiveStoryProgress(minProgressValue);
    setActiveStoryIndex((prevIndex) => prevIndex - 1);
  };

  const goToNextStory = () => {
    if (activeStoryIndex === stories.length - 1) {
      return;
    }

    setActiveStoryProgress(minProgressValue);
    setActiveStoryIndex((prevIndex) => {
      return prevIndex + 1;
    });
  };

  const pauseStory = () => setIsStoryPaused(true);

  const playStory = () => setIsStoryPaused(false);

  const switchStoryByTap = (event: TouchEvent | MouseEvent) => {
    if (!storiesContainerRef.current || !isTouchEvent(event)) return;

    const screenCenterPx = storiesContainerRef.current.offsetWidth / 2;
    const rightSideClickOccured = event.changedTouches.item(0).clientX >= screenCenterPx;

    if (rightSideClickOccured) {
      goToNextStory();
      return;
    }

    goToPreviousStory();
  };

  const getStoryProgress = (storyIndex: number) => {
    if (activeStoryIndex > storyIndex) {
      return maxProgressValue;
    }

    if (activeStoryIndex === storyIndex) {
      return activeStoryProgress;
    }

    return minProgressValue;
  };

  const screenPressListeners = useScreenPress({
    onLongPressStart: pauseStory,
    onLongPressEnd: playStory,
    onShortPress: switchStoryByTap,
  });

  return {
    isStoryPaused,
    activeStoryIndex,
    activeStoryProgress,
    storiesContainerRef,
    screenPressListeners,
    goToPreviousStory,
    goToNextStory,
    getStoryProgress,
  };
};
