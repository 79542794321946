import AnswerHorizontalOrientationScreenV3 from 'core/common/components/QuizScreens/AnswerHorizontalOrientationV3';
import { useExtendedPolicy } from 'core/common/hooks';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerHorizontalOrientationV3ScreenAttributes } from 'core/funnel/entities';

type AnswerHorizontalOrientationV3Props = {
  id: string;
};

const AnswerHorizontalOrientationV3 = ({ id }: AnswerHorizontalOrientationV3Props) => {
  const { isPolicyAccepted, displayPolicyError, setDisplayPolicyError, handlePolicyClick } =
    useExtendedPolicy();

  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey, hasPolicy } = screenAttributes as AnswerHorizontalOrientationV3ScreenAttributes;

  const onAnswerClick = async (value: string) => {
    if (hasPolicy && !isPolicyAccepted) {
      setDisplayPolicyError(true);
      return;
    }

    updateUserPreferences(dataKey, value);
    await next({ value });
  };

  return (
    <AnswerHorizontalOrientationScreenV3
      onBack={back}
      onAnswerClick={onAnswerClick}
      onPolicyClick={handlePolicyClick}
      isPolicyAccepted={isPolicyAccepted}
      displayPolicyError={displayPolicyError}
      step={step}
      {...(screenAttributes as AnswerHorizontalOrientationV3ScreenAttributes)}
    />
  );
};

export default AnswerHorizontalOrientationV3;
