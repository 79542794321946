import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

const checkmarkSizePx = {
  md: 12,
  lg: 20,
};

export const HeaderStyled = styled(Header)`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 47px;
  }
`;

export const ContentWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;

export const ImageStyled = styled(Image)`
  margin-right: auto;
  margin-bottom: 28px;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 32px;
  }
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 135%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 32px;
  }
`;

export const SubtitleStyled = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 28px;
    font-size: 18px;
  }
`;

export const ListStyled = styled.ul`
  margin-bottom: 16px;
  padding: 16px 16px 16px 48px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: linear-gradient(180deg, #f7f7f7 0%, rgba(255, 255, 255, 0) 100%);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 24px;
  }
`;

export const StepValueStyled = styled.p`
  position: relative;
  min-height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    min-height: 50px;
    font-size: 18px;
  }
`;

export const CheckmarkStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const ListItemStyled = styled.li<{ isChecked: boolean; isLast: boolean }>`
  position: relative;
  margin-bottom: 4px;

  ${StepValueStyled} {
    &::before {
      position: absolute;
      top: 2px;
      left: -20px;
      display: inline-block;
      width: 4px;
      height: 100%;
      border-radius: 100px;
      background: ${({ isChecked, isLast, theme }) => {
        if (isChecked && isLast) {
          return 'linear-gradient(180deg, #3C1C7B 0%, #FFF 100%)';
        }

        if (!isChecked && isLast) {
          return 'linear-gradient(180deg, #DEDEDE 0%, #FFF 100%)';
        }

        if (isChecked) {
          return '#3C1C7B';
        }

        return theme.colors.typography200;
      }};
      content: '';
    }
  }

  &:last-child ${StepValueStyled} {
    min-height: 20px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      min-height: 25px;
    }
  }

  ${CheckmarkStyled} {
    position: absolute;
    top: 4px;
    left: -24px;
    width: ${checkmarkSizePx.md}px;
    height: ${checkmarkSizePx.md}px;
    background-color: ${({ theme }) => theme.colors.typography200};

    ${({ isChecked }) => {
      if (isChecked) {
        return `
          top: 0;
          left: -28px;
          width: ${checkmarkSizePx.lg}px;
          height: ${checkmarkSizePx.lg}px;
          background-color: #3C1C7B;
        `;
      }
    }};

    @media ${({ theme }) => theme.breakpoints.desktop} {
      top: ${({ isChecked }) => (isChecked ? '2px' : '5px')};
    }
  }
`;

export const StepTitleStyled = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const IconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
`;

export const CaptionStyled = styled.p`
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const FooterStyled = styled.footer`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  margin-right: -16px;
  margin-left: -16px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonStyled = styled(Button)`
  height: 50px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
