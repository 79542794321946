import NebulaRating from 'core/common/components/QuizScreensSections/SectionNebulaRating';
import { HeroSectionStyled, TitleStyled } from './styled';

type HeroSectionProps = {
  title: string;
  text: string;
  rating: number;
};

const HeroSection = ({ title, text, rating }: HeroSectionProps) => {
  return (
    <HeroSectionStyled>
      <NebulaRating content={{ rating, text }} />
      <TitleStyled>{title}</TitleStyled>
    </HeroSectionStyled>
  );
};

export default HeroSection;
