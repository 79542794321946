import AnswerNatalChartScreen from 'core/common/components/QuizScreens/AnswerNatalChartV2';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerNatalChartV2ScreenAttributes } from 'core/funnel/entities';

type AnswerNatalChartV2Props = {
  id: string;
};

const AnswerNatalChartV2 = ({ id }: AnswerNatalChartV2Props) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerNatalChartV2ScreenAttributes;

  const onAnswerClick = async (value: string) => {
    updateUserPreferences(dataKey, value);

    await next({ value });
  };

  return (
    <AnswerNatalChartScreen
      onBack={back}
      onAnswerClick={onAnswerClick}
      step={step}
      {...(screenAttributes as AnswerNatalChartV2ScreenAttributes)}
    />
  );
};

export default AnswerNatalChartV2;
