import beebom from 'core/common/assets/images/productOverview/beebom.png';
import bustle from 'core/common/assets/images/productOverview/bustle.png';
import hypebae from 'core/common/assets/images/productOverview/hypebae.png';
import lifewire from 'core/common/assets/images/productOverview/lifewire.png';
import refinery from 'core/common/assets/images/productOverview/refinery.png';
import urbanList from 'core/common/assets/images/productOverview/urban-list.png';
import wellGood from 'core/common/assets/images/productOverview/well-good.png';
import Image from '../Image';
import { Container, ImageWrapper, Wrapper, WrapperRightAlign, WrapperWellGood } from './styled';

type PartnersProps = {
  className?: string;
};

const Partners = ({ className }: PartnersProps) => (
  <Container className={className}>
    <Wrapper>
      <ImageWrapper>
        <Image src={hypebae} alt="hypebae" width={90} height={24} />
      </ImageWrapper>
    </Wrapper>
    <WrapperRightAlign>
      <ImageWrapper>
        <Image src={refinery} alt="refinery" width={182} height={36} />
      </ImageWrapper>
    </WrapperRightAlign>
    <Wrapper>
      <ImageWrapper>
        <Image src={bustle} alt="bustle" width={89} height={24} />
      </ImageWrapper>
    </Wrapper>
    <WrapperRightAlign>
      <ImageWrapper>
        <Image src={beebom} alt="beebom" width={112} height={22} />
      </ImageWrapper>
    </WrapperRightAlign>
    <WrapperWellGood>
      <ImageWrapper>
        <Image src={wellGood} alt="well-good" width={231} height={16} />
      </ImageWrapper>
    </WrapperWellGood>
    <Wrapper>
      <Image src={urbanList} alt="urban-list" width={104} height={36} />
    </Wrapper>
    <WrapperRightAlign>
      <ImageWrapper>
        <Image src={lifewire} alt="lifewire" width={107} height={24} />
      </ImageWrapper>
    </WrapperRightAlign>
  </Container>
);

export default Partners;
