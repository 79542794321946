import styled from 'styled-components';
import Link from '../Link';

export const Container = styled.p`
  max-width: 214px;
  margin: 0 auto 20px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 284px;
  }
`;

export const NavLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;
