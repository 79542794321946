import { useCallback, useMemo, useState } from 'react';
import { PickerVariants, WheelDatePicker } from 'core/common/components/WheelDatePicker';
import { ZodiacWheel } from 'core/common/components/ZodiacWheel';
import { extractDateParts, normalizeDate } from 'core/common/utils/date';
import { PickerWrapperStyled, ButtonStyled, BlockWrapperStyled } from './styled';

type ScrollPickerProps = {
  nextLabel: string;
  userDate?: string;
  hasZodiacWheel: boolean;
  onSubmit: (date: string) => void;
  variant?: PickerVariants;
};

const ScrollPicker = ({
  nextLabel,
  onSubmit,
  userDate,
  hasZodiacWheel,
  variant = PickerVariants.DEFAULT,
}: ScrollPickerProps) => {
  const initialDate = useMemo(() => extractDateParts(userDate), [userDate]);

  const [date, setDate] = useState({ ...initialDate });

  const onClick = () => {
    onSubmit(normalizeDate(date, 'MMM D YYYY'));
  };

  const onChange = useCallback((value: Record<string, string | number>) => {
    setDate((prev) => ({ ...prev, ...value }));
  }, []);

  return (
    <BlockWrapperStyled>
      {hasZodiacWheel && <ZodiacWheel date={date} />}
      <PickerWrapperStyled>
        <WheelDatePicker variant={variant} onChange={onChange} date={date} />
        <ButtonStyled onClick={onClick}>{nextLabel}</ButtonStyled>
      </PickerWrapperStyled>
    </BlockWrapperStyled>
  );
};

export default ScrollPicker;
