import Header from 'core/common/components/Header';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import SectionSuccessOrder from 'core/common/components/QuizScreensSections/SectionSuccessOrder';
import UpsellsStepper from 'core/common/components/UpsellsStepper';
import {
  SectionAttributesUnionType,
  SectionType,
  UpsellsV3ScreenProps,
} from 'core/funnel/entities';
import { ContainerStyled, SectionUpsellOffersStyled } from './styled';

const UpsellsV3Screen = ({
  upsells,
  sections,
  upsellsSteps,
  selectedOfferId,
  resignFormLoaded,
  isPurchaseLoading,
  onSkip,
  onGetReport,
  onOfferSelect,
  paymentError,
  onClearPaymentStatus,
}: UpsellsV3ScreenProps) => {
  const renderSection = (section: SectionAttributesUnionType, index: number) => {
    switch (section.sectionType) {
      case SectionType.SUCCESS_ORDER:
        return <SectionSuccessOrder key={section.sectionType + index} content={section.content} />;
      case SectionType.UPSELL_OFFERS:
        return (
          <SectionUpsellOffersStyled
            key={section.sectionType + index}
            offers={upsells}
            content={section.content}
            selectedOfferId={selectedOfferId}
            isPurchaseLoading={isPurchaseLoading}
            onSkip={onSkip}
            onSubmit={onGetReport}
            onOfferSelect={onOfferSelect}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContainerStyled>
      <Header buttonBack={false} />
      {upsellsSteps && <UpsellsStepper steps={upsellsSteps} />}
      {sections.map(renderSection)}
      <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
      <PaymentDialogFailed
        paymentError={null}
        onTryAgain={onGetReport}
        onClose={onClearPaymentStatus}
        isOpen={!!paymentError}
      />
    </ContainerStyled>
  );
};

export default UpsellsV3Screen;
