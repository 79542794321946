import styled from 'styled-components';

export const ZodiacContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  min-height: 100px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 6px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: ${({ theme }) => theme.colors.typography100};
`;

export const ZodiacBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ZodiacLabel = styled.p`
  margin-top: 6px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #d5c4c4;
`;
