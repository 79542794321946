import { Theme } from '@mui/material';
import styled from 'styled-components';
import { ButtonProps } from './types';

// TODO: add "text" button variation
// TODO: try to move hardcoded colors from theme to css variables

const applyDisabledStyles = () => ({
  '&:disabled': {
    opacity: '0.6',
  },
});

const applyEffectsStyles = (color: ButtonProps['color']) => {
  switch (color) {
    case 'primary':
      return {
        '&:hover': {
          filter: 'brightness(1.1)',
          boxShadow: '2px 4px 12px 0 rgba(28, 28, 62, 0.3)',
        },
        '&:active': {
          filter: 'brightness(0.9)',
        },
      };
    case 'secondary':
      return {
        '&:hover': {
          boxShadow: '0px 2px 20px 0px rgba(255, 255, 255, 0.60)',
        },
        '&:active': {
          filter: 'brightness(0.9)',
        },
      };
    case 'tertiary':
      return {
        '&:hover': {
          filter: 'brightness(0.9)',
          boxShadow: '2px 4px 12px 0 rgba(28, 28, 62, 0.3)',
        },
        '&:active': {
          filter: 'brightness(0.8)',
        },
      };
    default:
      return {};
  }
};

const applyOutlinedEffectsStyles = (color: ButtonProps['color'], theme: Theme) => {
  switch (color) {
    case 'primary':
      return {
        '&:hover': {
          background: theme.colors.buttonPrimaryOutlinedHover,
          boxShadow: '2px 4px 12px 0 rgba(28, 28, 62, 0.3)',
        },
        '&:active': {
          background: theme.colors.buttonPrimaryOutlinedHover,
        },
      };
    case 'secondary':
      return {
        '&:hover': {
          background: theme.colors.buttonSecondaryOutlinedHover,
          boxShadow: '0px 2px 20px 0px rgba(255, 255, 255, 0.60)',
        },
        '&:active': {
          background: theme.colors.buttonSecondaryOutlinedHover,
        },
      };
    default:
      return {};
  }
};

const applyColorStyles = (color: ButtonProps['color'], theme: Theme) => {
  if (color === 'tertiary') {
    return {
      color: theme.colors.typography900,
      background: theme.colors.buttonTertiary,
      ...applyEffectsStyles(color),
    };
  }

  if (color === 'secondary') {
    return {
      color: theme.colors.typography900,
      background: theme.colors.buttonSecondary,
      ...applyEffectsStyles(color),
    };
  }

  return {
    background: theme.colors.buttonPrimary,
    color: theme.colors.typography100,
    ...applyEffectsStyles(color),
  };
};

const applySizeStyles = (size: ButtonProps['size'], theme: Theme) => {
  if (size === 'small') {
    return {
      height: '32px',
      fontSize: '12px',
      padding: '7px 12px',
      [theme.breakpoints.up('desktop')]: {
        fontSize: '14px',
      },
    };
  }

  if (size === 'medium') {
    return {
      height: '40px',
      fontSize: '14px',
      padding: '14px 16px',
      [theme.breakpoints.up('desktop')]: {
        fontSize: '16px',
      },
    };
  }

  return {
    height: '52px',
    fontSize: '16px',
    padding: '10px 16px',
    [theme.breakpoints.up('desktop')]: {
      fontSize: '18px',
    },
  };
};

const applyOutlinedStyles = (color: ButtonProps['color'], theme: Theme) => {
  const baseStyles = {
    borderWidth: '1px',
    borderStyle: 'solid',
    background: 'transparent',
  };

  switch (color) {
    case 'primary':
      return {
        ...baseStyles,
        color: theme.colors.buttonPrimary,
        borderColor: theme.colors.buttonPrimary,
        ...applyOutlinedEffectsStyles(color, theme),
      };
    case 'secondary':
      return {
        ...baseStyles,
        color: theme.colors.buttonSecondary,
        borderColor: theme.colors.buttonSecondary,
        ...applyOutlinedEffectsStyles(color, theme),
      };
    default:
      return {};
  }
};

const applyShimmerAnimationStyles = (enabled: boolean) => {
  return {
    '&::after': {
      position: 'absolute' as const,
      top: 0,
      left: 0,
      display: enabled ? 'inline-block' : 'none',
      width: '50%',
      height: '100%',
      background: `linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%)`,
      animation: 'shimmerAnimation 2s infinite linear',
      content: "''",
    },
    '@keyframes shimmerAnimation': {
      '0%': {
        transform: 'translateX(-300%)',
      },
      '100%': {
        transform: 'translateX(300%)',
      },
    },
  };
};

export const ButtonStyled = styled.button<ButtonProps>(
  ({
    theme,
    size = 'large',
    color = 'primary',
    variant = 'contained',
    disabled = false,
    withShimmerEffect = false,
  }) => {
    const disabledStyles = disabled ? applyDisabledStyles() : {};
    const outlinedStyles = variant === 'outlined' ? applyOutlinedStyles(color, theme) : {};

    return {
      width: '100%',
      fontWeight: 600,
      overflow: 'hidden',
      lineHeight: '150%',
      position: 'relative',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'box-shadow .3s ease, filter .3s ease, background .3s ease',
      ...applyShimmerAnimationStyles(withShimmerEffect),
      ...applyColorStyles(color, theme),
      ...applySizeStyles(size, theme),
      ...disabledStyles,
      ...outlinedStyles,
    };
  },
);
