import styled from 'styled-components';
import AspectRatio from 'core/common/components/AspectRatio';
import Button from 'core/common/components/Button';
import { TextButton } from 'core/common/components/ButtonDeprecated';
import Image from 'core/common/components/Image';
import PolicyComponent, { NavLink } from 'core/common/components/Policy';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 8px;
  padding-bottom: 28px;
`;

export const AspectRatioStyled = styled(AspectRatio)`
  margin-bottom: 16px;
`;

export const TitleStyled = styled.h2`
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 135%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 26px;
  }
`;

export const SubtitleStyled = styled.p`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography600};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;

export const SelectGenderCaptionStyled = styled.p`
  margin-top: 52px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const MaleButtonStyled = styled(Button)`
  margin-right: 12px;
  background: ${({ theme }) => theme.colors.vibrant500} !important;
`;

export const FemaleButtonStyled = styled(Button)`
  background: linear-gradient(180deg, #642b73 0%, #993770 50%, #c6426e 100%) !important;
`;

export const TextButtonStyled = styled(TextButton)`
  display: block;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  font-weight: 600;
  line-height: 150%;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typography500};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
  }
`;

export const PolicyStyled = styled(PolicyComponent)`
  z-index: 1;
  max-width: 330px;
  margin: 0;
  font-size: 12px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.typography600};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 330px;
  }

  ${NavLink} {
    font-weight: normal;
  }
`;

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
`;

export const GenderIconStyled = styled(Image)`
  margin-right: 4px;
`;
