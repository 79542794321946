import styled, { keyframes } from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import Icon from 'core/svg-sprites/ui/Icon';

export const TitleStyled = styled.h2`
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 145%;
  text-align: center;
`;

export const CardWrapperStyled = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const CardHeaderStyled = styled.div`
  padding: 4px 0;
  border-radius: 12px 12px 0 0;
  background: linear-gradient(90deg, #f6cf53 0%, #f3ac0d 100%);
`;

export const HeaderTitleStyled = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: #2f2f2f;
`;

export const CardBodyStyled = styled.div`
  padding: 16px 20px 8px;
  border-radius: 0 0 20px 20px;
  background: ${({ theme }) => theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 24px 90px;
  }
`;

export const BodyHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BodyHeaderTitleStyled = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const HorizontalLineStyled = styled.hr`
  margin-top: 12px;
  margin-bottom: 0;
  border: 0.5px solid ${({ theme }) => theme.colors.typography200};
`;

export const OneTimeOfferBlockStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export const OneTimeOfferDescriptionStyled = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
`;

export const PriceWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const OldPriceStyled = styled.span`
  position: relative;
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 171%;
  color: #818181;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #b32425;
    transform: translateY(-50%);
    content: '';
  }
`;

export const ActualPriceStyled = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
  color: ${({ theme }) => theme.colors.typography900};
`;

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 330px;
  }
`;

export const ActionButtonStyled = styled(ButtonStyled)`
  width: 100%;
  height: 48px;
  border-radius: 1000px;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  background-color: ${({ theme }) => theme.colors.lunarViolet};
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const pulse = keyframes`
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 4px rgba(153, 116, 246, 0.2);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(153, 116, 246, 0.2);
  }
`;

export const ButtonContainerStyled = styled.div`
  width: 140px;
  height: 32px;
  background: #fff;
  perspective: 1000px;
  backface-visibility: hidden;
`;

export const GuaranteeBlockStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export const GuaranteeTitleStyled = styled.h4`
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;
