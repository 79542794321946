import { CarouselSettings } from 'core/common/components/Carousel';
import { SectionReadingsPackSliderAttributes } from 'core/funnel/entities';
import { BreakpointValues } from 'core/theme/entities';
import {
  WrapperStyled,
  TitleStyled,
  CarouselStyled,
  CardStyled,
  CardHeaderStyled,
  CardBodyStyled,
  CardImageStyled,
  LabelStyled,
  CardTitleStyled,
  DetailsListStyled,
  DetailsListItemStyled,
  DetailsIconStyled,
  SliderPrevButtonStyled,
  SliderNextButtonStyled,
  ArrowIconStyled,
} from './styled';

type SectionReadingsPackSliderProps = {
  content: SectionReadingsPackSliderAttributes['content'];
  className?: string;
};

const defaultSlidesToShow = 2.2;

const carouselSettings: CarouselSettings = {
  speed: 600,
  infinite: false,
  slidesToShow: defaultSlidesToShow,
  dots: true,
  arrows: true,
  waitForAnimate: false,
  prevArrow: (
    <SliderPrevButtonStyled>
      <ArrowIconStyled name="arrow-simple" />
    </SliderPrevButtonStyled>
  ),
  nextArrow: (
    <SliderNextButtonStyled>
      <ArrowIconStyled name="arrow-simple" />
    </SliderNextButtonStyled>
  ),
  responsive: [
    {
      breakpoint: BreakpointValues.MAX_MOBILE_WIDTH,
      settings: {
        slidesToShow: 1.1,
        dots: false,
        arrows: false,
      },
    },
  ],
};

const SectionReadingsPackSlider = ({ content, className }: SectionReadingsPackSliderProps) => {
  const { title, readings } = content;

  return (
    <WrapperStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      <CarouselStyled {...carouselSettings}>
        {readings.map((reading, idx) => (
          <CardStyled key={reading.title}>
            <CardHeaderStyled>
              <LabelStyled>
                {idx + 1} / {readings.length}
              </LabelStyled>
              <CardImageStyled src={reading.image.src} alt={reading.image.alt} fill />
            </CardHeaderStyled>
            <CardBodyStyled>
              <CardTitleStyled>{reading.title}</CardTitleStyled>
              <DetailsListStyled>
                {reading.details.map(({ icon, text }) => (
                  <DetailsListItemStyled key={text}>
                    <DetailsIconStyled src={icon.src} alt={icon.alt} width={18} height={18} />
                    {text}
                  </DetailsListItemStyled>
                ))}
              </DetailsListStyled>
            </CardBodyStyled>
          </CardStyled>
        ))}
      </CarouselStyled>
    </WrapperStyled>
  );
};

export default SectionReadingsPackSlider;
