import { Trans } from 'react-i18next';
import arrow from 'core/common/assets/images/arrow_right.svg';
import { CarouselSettings } from 'core/common/components/Carousel';
import Header from 'core/common/components/Header';
import Link from 'core/common/components/Link';
import { PaymentDialogFailed, PaymentResignDialog } from 'core/common/components/Payment';
import UpsellsHeader from 'core/common/components/UpsellsHeader';
import { BASE_ROUTES } from 'core/common/constants';
import { UnlimitedReadingsScreenProps } from 'core/funnel/entities';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import Benefits from './Benefits';
import MainIntroSlide from './MainIntroSlide';
import { HalfRowImage } from './MainIntroSlide/styled';
import {
  ActionButtonStyled,
  BackImageStyled,
  CarouselStyled,
  ContainerStyled,
  ContentWrapperStyled,
  DisclaimerStyled,
  FooterStyled,
  NextImageStyled,
  RootStyled,
  RouteLinkStyled,
  SlideStyled,
  TitleStyled,
} from './styled';

const tKeyPrefix = 'app-subscription-4-7d-trial-per-day.unlimitedReadings';

const UnlimitedReadingsScreen = ({
  content,
  plan,
  isLoading,
  resignFormLoaded,
  onGetReport,
  onSkip,
  paymentError,
  onClearPaymentStatus,
}: UnlimitedReadingsScreenProps) => {
  const { infoSlide, imageSlides, title, benefits, button, skipButton, footer } = content;

  const displayPriceWithCurrencySymbol = plan?.pricesWithCurrencySymbol.priceWithVAT ?? '-';
  const planPeriod = plan?.period ?? '-';

  const settings: CarouselSettings = {
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <BackImageStyled src={arrow} alt="arrow" width={24} height={24} />,
    nextArrow: <NextImageStyled src={arrow} alt="arrow" width={24} height={24} />,
  };

  return (
    <RootStyled>
      <ContentWrapperStyled>
        <Header buttonBack={false} />
        <ContainerStyled>
          <UpsellsHeader />

          <CarouselStyled {...settings}>
            <SlideStyled withBackground>
              <MainIntroSlide displayPrice={displayPriceWithCurrencySymbol} content={infoSlide} />
            </SlideStyled>
            {imageSlides.map((images) => {
              return (
                <SlideStyled key={images[0].alt}>
                  <HalfRowImage
                    src={images[0].src}
                    alt={images[0].alt}
                    width={239}
                    height={252}
                    priority
                  />
                  <HalfRowImage
                    src={images[1].src}
                    alt={images[1].alt}
                    width={239}
                    height={252}
                    priority
                  />
                </SlideStyled>
              );
            })}
          </CarouselStyled>

          <TitleStyled>{title}</TitleStyled>

          <Benefits benefits={benefits} />

          <ActionButtonStyled
            onClick={onGetReport}
            disabled={isLoading}
            loading={isLoading}
            fullWidth
          >
            {button}
          </ActionButtonStyled>
          <RouteLinkStyled onClick={onSkip} disabled={isLoading}>
            {skipButton}
          </RouteLinkStyled>

          <DisclaimerStyled>
            <Trans
              i18nKey={`${tKeyPrefix}.disclaimer`}
              values={{
                price: displayPriceWithCurrencySymbol,
                period: planPeriod,
              }}
            />
            <Link href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>Subscription terms.</Link>
          </DisclaimerStyled>

          <FooterStyled>
            <Location>
              <LocationOption option={Locales.AE}>{footer.addressAE}</LocationOption>
              <LocationOption option={Locales.US}>{footer.addressUS}</LocationOption>
              <LocationOption option={Locales.OTHER}>{footer.address}</LocationOption>
            </Location>
          </FooterStyled>
          <PaymentResignDialog onClose={onClearPaymentStatus} isOpen={resignFormLoaded} />
          <PaymentDialogFailed
            paymentError={null}
            onTryAgain={onGetReport}
            onClose={onClearPaymentStatus}
            isOpen={!!paymentError}
          />
        </ContainerStyled>
      </ContentWrapperStyled>
    </RootStyled>
  );
};

export default UnlimitedReadingsScreen;
