import { config } from 'core/config';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { Address, AddressUS, Container, ReportDescription } from './styled';

type SectionFooterProps = {
  description: string;
};

const SectionFooter = ({ description }: SectionFooterProps) => {
  return (
    <Container>
      <ReportDescription>{description}</ReportDescription>
      <Location>
        <LocationOption option={Locales.US}>
          <AddressUS>{config.addressUS}</AddressUS>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <Address />
        </LocationOption>
      </Location>
    </Container>
  );
};

export default SectionFooter;
