import { PlanSubjectContent } from '../Plan';
import { PlanVariant } from './types';
import {
  AspectRatioImageWrapper,
  ContentWrapperStyled,
  HeaderStyled,
  IconStyled,
  ImageStyled,
  KeyPointImageStyled,
  KeyPointItemStyled,
  KeyPointsStyled,
  LabelStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

type PlanSubjectProps = {
  variant?: PlanVariant;
} & PlanSubjectContent;

const PlanSubject = ({
  label,
  title,
  icon,
  image,
  keyPoints,
  variant = 'default',
}: PlanSubjectProps) => {
  return (
    <WrapperStyled variant={variant}>
      <LabelStyled>{label}</LabelStyled>
      <ContentWrapperStyled>
        <HeaderStyled>
          <IconStyled src={icon.src} alt={icon.alt} width={36} height={36} />
          <TitleStyled>{title}</TitleStyled>
        </HeaderStyled>
        <AspectRatioImageWrapper>
          <ImageStyled src={image.src} alt={image.alt} fill priority />
        </AspectRatioImageWrapper>
      </ContentWrapperStyled>
      <KeyPointsStyled>
        {keyPoints.map((item) => (
          <KeyPointItemStyled key={item.text} variant={variant}>
            <KeyPointImageStyled name={item.icon} width={18} height={18} />
            {item.text}
          </KeyPointItemStyled>
        ))}
      </KeyPointsStyled>
    </WrapperStyled>
  );
};

export default PlanSubject;
