import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelImage } from 'core/funnel/entities';
import { PlanVariant } from './types';
import {
  AspectRatioImageWrapper,
  ContentWrapperStyled,
  HeaderStyled,
  IconStyled,
  ImageStyled,
  KeyPointImageStyled,
  KeyPointItemStyled,
  KeyPointsStyled,
  LabelStyled,
  TitleStyled,
  WrapperStyled,
} from './styled';

type PlanSubjectProps = {
  variant?: PlanVariant;
  label: string;
  title: string;
  icon: FunnelImage;
  image: FunnelImage;
  keyPoints: Array<{
    text: string;
    icon: string;
  }>;
};

const PlanSubject = ({
  label,
  title,
  icon,
  image,
  keyPoints,
  variant = 'default',
}: PlanSubjectProps) => {
  return (
    <WrapperStyled variant={variant}>
      <LabelStyled dangerouslySetInnerHTML={prepareHtml(label)} />
      <ContentWrapperStyled>
        <HeaderStyled>
          <IconStyled src={icon.src} alt={icon.alt} width={36} height={36} />
          <TitleStyled dangerouslySetInnerHTML={prepareHtml(title)} />
        </HeaderStyled>
        <AspectRatioImageWrapper>
          <ImageStyled src={image.src} alt={image.alt} fill priority />
        </AspectRatioImageWrapper>
      </ContentWrapperStyled>
      <KeyPointsStyled>
        {keyPoints.map((item) => (
          <KeyPointItemStyled key={item.text} variant={variant}>
            <KeyPointImageStyled name={item.icon} width={18} height={18} />
            {item.text}
          </KeyPointItemStyled>
        ))}
      </KeyPointsStyled>
    </WrapperStyled>
  );
};

export default PlanSubject;
