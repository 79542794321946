import { useEffect } from 'react';

import { useServices } from 'core/common/hooks';

const useCameraPermissionsAlert = (isOpen: boolean) => {
  const { analyticsService } = useServices();

  useEffect(() => {
    if (isOpen) {
      analyticsService.cameraErrorPopUpOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const cameraErrorPopUpClicked = () => {
    analyticsService.cameraErrorPopUpClicked();
  };

  return {
    cameraErrorPopUpClicked,
  };
};

export default useCameraPermissionsAlert;
