import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import { Select } from 'core/common/components/Select';

export const SelectStyled = styled(Select)`
  width: 100%;
  min-width: auto;
`;

export const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 330px;
  height: 157px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
`;

export const ButtonStyled = styled(ButtonComponent)`
  height: 49px;
  margin: auto auto 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:disabled {
    box-shadow: none;
  }
`;
