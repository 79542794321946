import styled from 'styled-components';

import Button from 'core/common/components/ButtonDeprecated';

export const ContentWrapper = styled.section`
  z-index: 1;
  max-width: 340px;
  margin: 0 auto;
  padding-bottom: 20px;
`;

export const SingleZodiacImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 285px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const CustomButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 28px;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(216, 214, 214, 0.25);
`;
