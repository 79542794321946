import Header from 'core/common/components/Header';
import Image from 'core/common/components/Image';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { SocialProofScreenProps } from 'core/funnel/entities';
import {
  AvatarStyled,
  ButtonStyled,
  CaptionStyled,
  ContainerStyled,
  FooterStyled,
  PublishedAtStyled,
  ReviewFooterStyled,
  ReviewStyled,
  ReviewTextStyled,
  TitleStyled,
  UserInfoStyled,
  UserNameStyled,
} from './styled';

const SocialProofScreen = ({ content, goBack, goNext }: SocialProofScreenProps) => {
  const { title, review, user, ratingImage, button } = content;

  return (
    <>
      <Header onBack={goBack} />
      <ContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        <ReviewStyled>
          {review.image && (
            <Image src={review.image.src} alt={review.image.alt} width={40} height={40} />
          )}
          <ReviewTextStyled dangerouslySetInnerHTML={prepareHtml(review.text)} />
          <ReviewFooterStyled>
            <AvatarStyled src={user.avatar.src} alt={user.avatar.alt} width={38} height={38} />
            <UserInfoStyled>
              <UserNameStyled>{user.name}</UserNameStyled>
              <CaptionStyled>{user.caption}</CaptionStyled>
            </UserInfoStyled>
            <PublishedAtStyled>{review.publishedAt}</PublishedAtStyled>
          </ReviewFooterStyled>
        </ReviewStyled>
        <FooterStyled>
          <Image src={ratingImage.src} alt={ratingImage.alt} width={167} height={50} />
          <ButtonStyled fullWidth onClick={goNext}>
            {button}
          </ButtonStyled>
        </FooterStyled>
      </ContainerStyled>
    </>
  );
};

export default SocialProofScreen;
