import QuizBackground from 'core/common/components/QuizBackground';
import { QuizStepBackground, ScreenAttributes } from 'core/funnel/entities';
import { StyledQuizBackground } from './styled';

export type BackgroundsProps = {
  attributes: ScreenAttributes[];
};

type Background = {
  mobile: string;
  desktop: string;
};

const Backgrounds = ({ attributes }: BackgroundsProps) => {
  const urls: Background[] = [];

  attributes.forEach((attr) => {
    if ('content' in attr) {
      const background = attr.content?.backgroundImage as QuizStepBackground;
      const mobile = background?.mobileImage?.src || '';
      const desktop = background?.desktopImage?.src || '';

      if (mobile || desktop) {
        urls.push({ desktop, mobile });
      }
    }
  });

  const backgrounds = urls?.map((url, i) => {
    if (url.mobile || url.desktop) {
      return (
        <StyledQuizBackground key={url.mobile + i}>
          <QuizBackground
            backgroundImage={{
              mobileImage: { src: `${url.mobile || ''}`, alt: '' },
              desktopImage: { src: `${url.desktop || ''}`, alt: '' },
            }}
          />
        </StyledQuizBackground>
      );
    }

    return null;
  });

  return <>{backgrounds}</>;
};

export default Backgrounds;
