import MobileStepperLib from '@mui/material/MobileStepper';
import styled from 'styled-components';
import ButtonBack from '../ButtonBack';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
`;
// TODO: After MUI update remove MobileStepperWithMUIStyles, use only MobileStepperLib
const MobileStepperWithMUIStyles = styled(MobileStepperLib)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-box-pack: justify;
  -webkit-box-align: center;

  .MuiLinearProgress-root {
    position: relative;
    z-index: 0;
    display: block;
    height: 4px;
    overflow: hidden;
  }

  .MuiLinearProgress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgb(153, 116, 246);
    transition: transform 0.4s linear 0s;
    transform-origin: left center;
  }
`;

export const MobileStepper = styled(MobileStepperWithMUIStyles)`
  width: 100%;
  padding: 0 !important;
  background: none !important;

  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.infoLight};
  }

  & .MuiMobileStepper-progress {
    width: 100%;
    height: 6px;
    border-radius: 12px;
    background: ${(props) => props.theme.colors.typography200};
  }
`;

export const Control = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const ButtonBackStyled = styled(ButtonBack)`
  position: absolute;
  fill: ${({ theme }) => theme.colors.infoLight};
`;

export const Title = styled.h4`
  flex: 1 0 0;
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Counter = styled.div`
  position: absolute;
  right: 0;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${(props) => props.theme.colors.infoLight};
  background: ${(props) => `${props.theme.colors.primary100}50`};
`;
