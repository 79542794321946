import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TrialPaymentScreen from 'core/common/components/QuizScreens/TrialPayment';
import { LANDINGS } from 'core/common/constants';
import { useCountdown, useRouter, useServices } from 'core/common/hooks';
import { joinUrls } from 'core/common/utils/joinUrls';
import { useQuizStep } from 'core/funnel/contexts';
import { FunnelName, TrialPaymentScreenAttributes } from 'core/funnel/entities';
import { useQuizData } from 'core/funnel/hooks';
import { useOffers } from 'core/offers/hooks';
import { useGetOrder, useOrder } from 'core/payments/hooks';
import { getEmail, getUserInformation } from 'core/user/store';
import { getZodiacPersonImage } from 'core/zodiac/utils/getZodiacPersonImage';

type TrialPaymentProps = {
  id: string;
};

const TrialPayment = ({ id }: TrialPaymentProps) => {
  const { next, getScreenAttributes, userPreferences } = useQuizStep(id);
  const { analyticsService } = useServices();
  const { link } = useQuizData();
  const { navigate } = useRouter();
  const { specialPlanCountdown } = useCountdown({
    key: FunnelName.APP_SUBSCRIPTION_4_7D_TRIAL_PER_DAY,
  });
  useGetOrder();
  const screenAttributes = getScreenAttributes(id); // TODO:: For prevent memorizing (make later better solution)
  const email = useSelector(getEmail);
  const { orderId } = useOrder();
  const userDetails = useSelector(getUserInformation);

  const { updateOffer, selectedOffer, timeUpOffer, defaultOffers } = useOffers();

  const defaultOffer = selectedOffer ?? defaultOffers[0];

  const isTimeUp = !+specialPlanCountdown;
  const offer = isTimeUp ? timeUpOffer : defaultOffer;

  const zodiacImage = getZodiacPersonImage(userPreferences.zodiac, userPreferences.gender);
  const partnerZodiacImage = getZodiacPersonImage(
    userPreferences.partnerZodiac,
    userPreferences.partnerGender,
  );

  const getReport = useCallback(() => {
    if (!offer) return;
    analyticsService.initiateCheckout({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
    analyticsService.landingPageClick('trial_billing', offer.offerId);
    updateOffer(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsService, offer, email, orderId]);

  const paymentDialogClose = () => {
    navigate(joinUrls(link, LANDINGS.ADDITIONAL_DISCOUNT));
  };

  useEffect(() => {
    if (!offer) return;
    analyticsService.landingPageOpened('trial_billing');
    analyticsService.addToCart({
      price: offer.prices.priceWithVAT,
      productId: offer.offerId,
      email,
      orderId,
      currency: offer.currency,
    });
  }, [analyticsService, offer, email, orderId]);

  return (
    <TrialPaymentScreen
      getReport={getReport}
      goNext={next}
      paymentDialogClose={paymentDialogClose}
      relationshipType={userDetails.relationshipType}
      offer={offer}
      timeUpOffer={timeUpOffer}
      timeLeft={specialPlanCountdown}
      zodiacImage={zodiacImage}
      partnerZodiacImage={partnerZodiacImage}
      {...(screenAttributes as TrialPaymentScreenAttributes)}
    />
  );
};

export default TrialPayment;
