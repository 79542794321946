import { Fragment } from 'react';
import { Upsell } from 'core/offers/entities';
import {
  Circle,
  Container,
  Description,
  Discount,
  DiscountText,
  Emoji,
  IconStyled,
  Input,
  OldPrice,
  Price,
  PriceBlock,
  PrimaryText,
  RadioLabel,
  TextContent,
  Title,
} from './styled';

export type SectionPaymentProps = {
  selectedProduct: string;
  selectProduct: (value: string) => void;
  upsells: Array<Upsell>;
};

const SectionPayment = ({ selectedProduct, selectProduct, upsells }: SectionPaymentProps) => {
  return (
    <Container>
      {upsells.map((upsell, index) => (
        <Fragment key={index}>
          <Input
            type="radio"
            name="advisory-upsell"
            id={upsell.offerId}
            value={upsell.offerId}
            checked={selectedProduct === upsell.offerId}
            onChange={(e) => selectProduct(e.target.value)}
          />
          <RadioLabel htmlFor={upsell.offerId} isChosen={selectedProduct === upsell.offerId}>
            <Circle>
              <IconStyled name="check" width={20} height={20} />
            </Circle>
            <TextContent>
              <Title>{upsell.title}</Title>
              {!!upsell.description && <Description>{upsell.description}</Description>}
              <PriceBlock>
                <Price>{upsell.price}</Price> ( {upsell.oldPriceDescription}{' '}
                <PrimaryText>
                  <OldPrice>{upsell.oldPrice}</OldPrice>)
                </PrimaryText>
                <Discount>
                  <DiscountText>{upsell.discount}</DiscountText>
                </Discount>
              </PriceBlock>
            </TextContent>
            {upsell.emoji && <Emoji emoji={upsell.emoji} />}
          </RadioLabel>
        </Fragment>
      ))}
    </Container>
  );
};

export default SectionPayment;
