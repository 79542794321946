import styled from 'styled-components';
import { PalmLine } from 'core/palmistry/entities';

const lineColor: Record<PalmLine, string> = {
  fate: '#572D93',
  life: '#1F9F55',
  head: '#F28907',
  love: '#D84188',
};

export const ListStyled = styled.ul``;

export const ListItemStyled = styled.li<{ line: PalmLine }>`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;

  &::before {
    content: '• ';
  }

  span {
    font-weight: 700;
    color: ${({ theme, line }) => lineColor[line] ?? theme.colors.black};
  }
`;
