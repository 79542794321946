import styled from 'styled-components';

export const WrapperStyled = styled.section``;

export const TitleStyled = styled.h2`
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 136%;
  text-align: center;
`;
