import AnswerPalmistryScreen from 'core/common/components/QuizScreens/AnswerPalmistry';
import { useQuizStep } from 'core/funnel/contexts/FlowContext';
import { AnswerPalmistryScreenAttributes } from 'core/funnel/entities';

type AnswerPalmistryProps = {
  id: string;
};

const AnswerPalmistry = ({ id }: AnswerPalmistryProps) => {
  const { back, screenAttributes, next, updateUserPreferences, step } = useQuizStep(id);

  const { dataKey } = screenAttributes as AnswerPalmistryScreenAttributes;

  const onAnswerClick = async (value: string) => {
    updateUserPreferences(dataKey, value);

    await next({ value });
  };

  return (
    <AnswerPalmistryScreen
      onBack={back}
      onAnswerClick={onAnswerClick}
      step={step}
      {...(screenAttributes as AnswerPalmistryScreenAttributes)}
    />
  );
};

export default AnswerPalmistry;
