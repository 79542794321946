import { InputHTMLAttributes, memo } from 'react';
import { ColorVariants } from 'core/theme/entities';
import { ErrorContainer, ErrorText, InputContainer } from './styled';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  errorText?: string;
  variant?: ColorVariants;
};

const Input = ({ error, errorText, variant = 'primary', ...rest }: InputProps) => (
  <>
    <InputContainer {...rest} error={error} variant={variant} />
    <ErrorContainer>{error && <ErrorText>{errorText}</ErrorText>}</ErrorContainer>
  </>
);

export default memo(Input);
