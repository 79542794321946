import styled from 'styled-components';
import ButtonComponent from 'core/common/components/ButtonDeprecated';
import ImageComponent from 'core/common/components/Image';
import Typography from 'core/common/components/Typography';
import Icon from 'core/svg-sprites/ui/Icon';

export const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 12px;
`;

export const TitleStyled = styled(Typography)`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 22px;
`;

export const WrongScanImageStyled = styled(ImageComponent)`
  margin-top: 6px;
`;

export const ButtonStyled = styled(ButtonComponent)`
  height: 50px;
  margin-top: 56px;
  margin-bottom: 28px;
  border-radius: 12px;
  font-size: 18px;
  line-height: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const GuaranteeStyled = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const GuaranteeContentStyled = styled.p`
  flex: 1;
  margin-left: 8px;
  font-size: 12px;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography600};
`;

export const GuaranteeIconStyledStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
`;
